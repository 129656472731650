import React, { useContext } from 'react';
import queryString from 'query-string';
import { __RouterContext } from 'react-router';
import { useLocation } from 'react-router-dom';

const useSearchQuery = () => {
  // Checking if the router context exists is a method of seeing if we are inside of the SPA.
  // https://stackoverflow.com/questions/59992359/how-can-i-check-if-react-router-is-in-context
  const router = useContext(__RouterContext);

  if (router) {
    const { search } = useLocation();
    return React.useMemo(() => queryString.parse(search), [search]);
  } else if (typeof window !== 'undefined') {
    const search = window.location.search;
    return React.useMemo(() => queryString.parse(search), [search]);
  } else {
    return {};
  }
}

export default useSearchQuery;
