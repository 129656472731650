import { connect } from 'react-redux';
import { RootStore } from '@magoosh/store';
import { setItemCompleted } from '../actions';
import Template from './template';

function mapStateToProps(state: RootStore, ownProps) {
  return { ...state.lessonModuleData, ...ownProps };
}

const mapDispatchToProps = {
  setItemCompleted
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
