import React from 'react';
import cx from 'classnames';

import style from './style.module.scss';

interface Props {
  legendPosition?: 'bottom' | 'left' | 'right' | 'top';
}

const ChartContainer: React.FC<Props> = (props) => {
  const flexDirection = () => {
    if (props.legendPosition === 'bottom')
      return 'column';
    else if (props.legendPosition === 'top')
      return 'column-reverse';
    else if (props.legendPosition === 'left')
      return 'row-reverse';
    else if (props.legendPosition === 'right')
      return 'row';
    else
      return 'column';
  };

  return (
    <div
      className={cx(style.chartContainer, 'align-items-center d-flex gap-m')}
      style={{ flexDirection: flexDirection() }}
    >
      {props.children}
    </div>
  );
};

export default ChartContainer;
