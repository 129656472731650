/*
  A hook for standardizing queries used to populate and update the data in the DataTable component.

  Parameters:
    - fetchData
        A function that should fetch data based on the current values in queryParameters provided by
        this hook. Data should be returned from the server in the form of
        { data: [], totalRows: <number> }


  - defaultQueryParams
      The initial values of the query.

  Return values:
    - queryParameters
        A hash of the current query parameters. By default, this would be:
          {
            searchTerm: '',
            filters: {},
            sortColumn: '',
            sortDirection: 'asc',
            page: 1,
            perPage: 25,
            endDate: new Date(),
          }

        These values can be overridden, or expanded, by defaultQueryParams.

    - runQuery
        A function that will run the query, by calling the provided fetchData function, and update
        state as needed. Optionally, newly updated query parameters can be provided to this function
        to adjust the results. Call this method without any parameters to refresh the data, in the
        event that an action has been performed that may have affected results.

    - dataQueryTableProps
        The props managed by this hook that can be passed directly to the DataTable component.
 */

import { useCallback, useMemo, useState } from 'react';
import { DataTableQueryParameters } from 'utilities/data_table_queries';
import moment from 'moment';
import { pickBy } from 'lodash';
import { useQuery } from 'react-query';
import fetch from 'utilities/fetch';

export default function useDataQuery(fetchDataUrl: (params: DataTableQueryParameters) => string, defaultQueryParams) {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [aggregateData, setAggregateData] = useState(null);
  const [fetchPending, setFetchPending] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [queryParameters, setQueryParameters] = useState<DataTableQueryParameters>({
    searchTerm: '',
    filters: {},
    sortColumn: '',
    sortDirection: 'asc',
    page: 1,
    perPage: 25,
    endDate: moment(new Date()).endOf('day'),
    ...defaultQueryParams
  });

  const runQuery = (newQuerySettings?) => {
    setFetchPending(true);
    setQueryParameters((prevValues) => {
      let newValues = { ...prevValues, ...newQuerySettings };
      newValues.filters = pickBy(newValues.filters); // removes null filters.
      return newValues;
    })
  }

  const { isError } = useQuery(
    ['dataTableQuery', { queryParameters, refresh }],
    ({ queryKey }) => {
      const [_key, { queryParameters, refresh }] = (queryKey as any);

      const params: DataTableQueryParameters = {
        refresh: refresh, ...(queryParameters)
      };

      const url = fetchDataUrl(params);

      return fetch(url, { returnFullResponse: true });
    },
    {
      enabled: fetchPending,
      onError: () => {
        setData([]);
        setFetchPending(false);
      },
      onSuccess: (response) => {
        if (response.status == 200) {
          response.json().then(responseJson => {
            setData(responseJson.data);
            setAggregateData(responseJson.aggregateData);
            setTotalRows(responseJson.totalRows);
            setFetchPending(false);
          });
        }
      },
      onSettled: () => {
        // If refresh is true after a request, set it back to false, so that the "refresh" flag is
        // only sent to the server once.
        if (refresh) { setRefresh(false); }
      },
      refetchInterval: () => {
        return (!isError && fetchPending) ? 500 : false;
      },
      refetchIntervalInBackground: true,
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  )

  const handleSort = useMemo(() => {
    return (column, direction) => {
      runQuery({
        sortColumn: column.selector,
        sortDirection: direction
      });
    };
  }, []);

  const handleChangePage = useCallback((page) => {
    runQuery({
      page: page
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newPerPage, page) => {
    runQuery({
      page: page,
      perPage: newPerPage
    });
  }, []);

  return {
    queryParameters,
    runQuery,
    aggregateData,
    dataQueryTableProps: {
      data,
      totalRows,
      perPage: queryParameters.perPage,
      fetchPending,
      selectedRows,
      setSelectedRows,
      clearSelectedRows,
      handleSort,
      handleChangePage,
      handleChangeRowsPerPage,
      refresh: () => {
        setRefresh(true);
        setFetchPending(true);
      },
    }
  };
}
