import React, { useCallback, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery, useQueryClient } from 'react-query';
import { DataTableQueryParameters } from 'utilities/data_table_queries';
import fetch from 'utilities/fetch';
import { DefaultButton, LinkButton, SecondaryButton } from '@magoosh/lib/button';
import Modal from '@magoosh/lib/modal';
import { Spinner } from '@magoosh/b2b/app/lib/spinner';
import styles from './style.module.scss';

interface FilterToggleProps {
  queryParameters: DataTableQueryParameters;
  fetchUrl: (params) => string;
  fileName: string;
  disabled?: boolean;
}

interface DownloadModalProps {
  queryParameters: DataTableQueryParameters;
  fetchUrl: (params) => string;
  fileName: string;
  dialogOpen: boolean;
  onDialogClose: () => any;
}

const DownloadModal: React.FC<DownloadModalProps> = (props) => {
  const { queryParameters, fetchUrl, fileName } = props;
  const [data, setData] = useState();
  const [downloadPending, setDownloadPending] = useState(true);
  const [showDownloadMessage, setShowDownloadMessage] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { isError } = useQuery(
    ['downloadQuery', { queryParameters, refresh }],
    ({ queryKey }) => {
      const [_key, { queryParameters, refresh }] = (queryKey as any);

      const params: DataTableQueryParameters = {
        download: true, refresh: refresh, ...(queryParameters)
      };

      const url = fetchUrl(params);

      return fetch(url, { returnFullResponse: true });
    },
    {
      enabled: props.dialogOpen,
      onError: () => {
        setDownloadPending(false);
      },
      onSuccess: (response) => {
        if (response.status == 202) { // HTTP status code of "Accepted"
          setShowDownloadMessage(true);
        } else {
          response.text().then(text => {
            setData(text);
            setShowDownloadMessage(false);
            setDownloadPending(false);
          });
        }
      },
      onSettled: () => {
        // If refresh is true after a request, set it back to false, so that the "refresh" flag is
        // only sent to the server once.
        if (refresh) { setRefresh(false); }
      },
      refetchInterval: () => {
        return (!isError && props.dialogOpen && downloadPending) ? 5000 : false;
      },
      refetchIntervalInBackground: true,
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  )

  const renderDownloadPending = () => (
    <>
      {showDownloadMessage && (
        <div>
          <p>
            Your report is being generated.
          </p>
          <p>
            Once it&apos;s ready, you&apos;ll be able to download it here.
          </p>
        </div>
      )}
      <Spinner />
    </>
  );

  const renderDownloadReady = () => (
    <>
      <div>
        Your report is ready to download!
      </div>
      <div>
        <SecondaryButton
          icon={'download'}
          onClick={() => {
            const blob = new Blob([data], { type: 'text/csv' });
            const a = document.createElement('a');
            a.download = fileName;
            a.href = window.URL.createObjectURL(blob);
            a.click();
            props.onDialogClose();
            setDownloadPending(true);
          }}
        >
          Download report
        </SecondaryButton>
      </div>
      <div>
        <small>Reports are cached for 30 minutes.</small>
      </div>
      <div>
        <small>
         Want to make sure you have the latest data? Click&nbsp;
          <a onClick={refetchData}>here</a>
          &nbsp;to refresh the data.
        </small>
      </div>
    </>
  );

  const renderContent = () => {
    if (downloadPending) {
      return renderDownloadPending();
    } else if (isError) {
      return <>
        An error occurred while generating your report.
        <SecondaryButton icon={'download'} onClick={refetchData}>
          Retry
        </SecondaryButton>
      </>
    } else {
      return renderDownloadReady();
    }
  }

  const refetchData = () => {
    setDownloadPending(true);
    setRefresh(true);
  }

  return <Modal
    title={'Download Report'}
    show={props.dialogOpen}
    showCloseButton={true}
    onHide={() => {
      setDownloadPending(true);
      props.onDialogClose();
    }}
  >
    <div className={styles.downloadModalContent}>
      {renderContent()}
    </div>
  </Modal>
}

export const DownloadButton: React.FC<FilterToggleProps> = (props) => {

  const [dialogOpen, setDialogOpen] = useState(false);

  return  <>
    <DefaultButton
      title={'Download report'}
      icon={'download'}
      size={'xsmall'}
      disabled={props.disabled}
      onClick={props.disabled ? null : () => setDialogOpen(true)}
    />
    <DownloadModal
      {...props}
      dialogOpen={dialogOpen}
      onDialogClose={() => setDialogOpen(false)}
    />
  </>
}
