import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import moment from 'moment';
import { ErrorBoundary } from 'react-error-boundary';
import DropdownList from 'react-widgets/lib/DropdownList';
import { v4 as uuidv4 } from 'uuid';

import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { bugsnagNotify } from 'utilities/bugsnag';

import * as api from '@test_prep/app/api';
import { Interaction, Skill, TutoringSession } from '@test_prep/app/ai_tutor/types';

import ErrorMessage from '../error_message';
import GradientPill from '../gradient_pill';
import InteractionsList from '../interactions_list';
import RatingCollection from '../rating_collection';
import UserInput from '../user_input';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  active: boolean;
  activity: Interaction;
  addOrUpdateUserInteractions: (interactions: Interaction[]) => void;
  initialSkill?: string;
  onHide: () => void;
  setCurrentActivity: (interaction: Interaction) => void;
  setCurrentTutoringSession: (tutoringSession: TutoringSession) => void;
  tutoringSession: TutoringSession;
}

const TutoringPanel: React.FC<Props> = (props) => {
  const [askForRating, setAskForRating] = useState(false);
  const [availableSkills, setAvailableSkills] = useState<Skill[]>(null);
  const [error, setError] = useState(null);

  // Start the tutoring session when the tutor becomes active
  useEffect(() => {
    if (!props.active) return;
    if (props.tutoringSession && props.tutoringSession.skill === props.initialSkill) return;

    fetchAvailableSkillsAndStartTutoringSession();
  }, [props.active, props.initialSkill]);

  useEffect(() => {
    if (!props.tutoringSession) return;

    setAskForRating(!props.tutoringSession.rating);
  }, [props.tutoringSession?.id]);

  const currentSkill = find(availableSkills, (skill) => (
    props.tutoringSession?.skill === skill.klass
  ));

  const fetchAvailableSkillsAndStartTutoringSession = () => {
    setError(null);
    props.setCurrentTutoringSession(null);

    return api.fetchAvailableTutoringSessionSkills().then((skills) => {
      setAvailableSkills(skills);
      startNewTutoringSession(props.initialSkill || skills[0], true);
    }).catch((error) => {
      setError(true);
      logError(error);
    });
  };

  const logError = (error: Error, info?: { componentStack: string }) => {
    bugsnagNotify(error);
  };

  const resetTutoringSession = () => {
    setError(null);

    if (currentSkill) {
      startNewTutoringSession(currentSkill, false);
    } else {
      fetchAvailableSkillsAndStartTutoringSession();
    }
  };

  const shouldCollectRating = () => {
    if (!props.tutoringSession) return false;

    const hasUserInteractions = props.tutoringSession.interactions.some((interaction) => (
      interaction.role === 'assistant' && interaction.status === 'finished' && interaction.contentProducer?.identifier !== 'initial'
    ));
    if (!hasUserInteractions) return false;

    return askForRating;
  };

  const startNewTutoringSession = (skill: Skill, resume: boolean) => {
    props.setCurrentTutoringSession(null);

    return api.createTutoringSession(skill.klass, resume).then((tutoringSession) => (
      props.setCurrentTutoringSession(tutoringSession)
    )).catch((error) => {
      setError(true);
      logError(error);
    });;
  };

  const submitUserInput = (text: string) => {
    // Immediately add the user's input to the interaction list for quick UI feedback
    props.addOrUpdateUserInteractions([
      {
        content: { text },
        contentProducer: null,
        createdAt: moment.utc().valueOf() / 1000,
        finishedAt: moment.utc().valueOf() / 1000,
        id: uuidv4(),
        isActivity: false,
        name: 'Chat',
        provisional: true,
        role: 'user',
        status: 'finished',
        tutoringSessionId: props.tutoringSession.id,
        type: 'chat'
      },
      {
        content: {},
        contentProducer: null,
        createdAt: moment.utc().valueOf() / 1000,
        finishedAt: null,
        id: uuidv4(),
        isActivity: false,
        name: 'Chat',
        provisional: true,
        role: 'assistant',
        status: 'waiting',
        tutoringSessionId: props.tutoringSession.id,
        type: 'chat'
      }
    ]);

    // When this returns it overwrites what we added above with the real thing
    return api.createTutoringSessionChat(
      props.tutoringSession.id,
      {
        text,
        activeActivityId: props.activity?.id
      }
    ).then((interactions) => {
      props.addOrUpdateUserInteractions(interactions);
    });
  };

  const waitingOnInteraction = () => {
    return props.tutoringSession.interactions.some((interaction) => (
      interaction.status !== 'finished' && interaction.status !== 'error'
    ));
  };

  return (
    <>
      <div className="align-items-center d-flex gap-s">
        <GradientPill size="sm">
          <FontAwesomeIcon icon="asterisk" /> AI Tutor
        </GradientPill>

        <div className="d-flex flex-1 gap-m justify-content-end">
          <a
            className="small text-bold text-gray-light text-no-decoration"
            onClick={resetTutoringSession}
          >
            Reset
          </a>

          <a
            className="small text-bold text-gray-light text-no-decoration"
            onClick={props.onHide}
          >
            Hide
          </a>
        </div>
      </div>

      {
        props.tutoringSession && (
          <div className="d-flex flex-column">
            <DropdownList
              className={style.skillSelect}
              data={availableSkills}
              onChange={(skill) => {
                startNewTutoringSession(skill, true);
              }}
              textField="name"
              value={currentSkill.name}
              valueField="klass"
              dataKey="klass"
            />

            {
              shouldCollectRating() && (
                <RatingCollection
                  rating={{
                    ...props.tutoringSession.rating || {},
                    rateableId: props.tutoringSession.id,
                    rateableName: 'tutoring session',
                    rateableType: 'TestPrep::TutoringSessions::TutoringSession'
                  }}
                  tutoringSessionId={props.tutoringSession.id}
                />
              )
            }
          </div>
        )
      }

      <hr className="u-margin-A-n" />

      {
        props.tutoringSession ? (
          <ErrorBoundary fallback={<ErrorMessage />} onError={logError}>
            <div className="flex-1" style={{ overflowY: 'scroll', overscrollBehavior: 'contain' }}>
              <InteractionsList
                activity={props.activity}
                addOrUpdateUserInteractions={props.addOrUpdateUserInteractions}
                setCurrentActivity={props.setCurrentActivity}
                submitUserInput={submitUserInput}
                tutoringSession={props.tutoringSession}
              />
            </div>

            <UserInput disabled={waitingOnInteraction()} onSubmit={submitUserInput} />
          </ErrorBoundary>
        ) : (
          error ? (
            <ErrorMessage onRetry={fetchAvailableSkillsAndStartTutoringSession} />
          ) : (
            <div className="d-flex justify-content-center">
              <LoadingIndicator />
            </div>
          )
        )
      }
    </>
  );
};

export default TutoringPanel;
