import React, { useEffect, useState } from 'react';
import { Selectable, ItemId, ListSelectorFieldProps } from './types';
import style from './style.module.scss';
import cx from 'classnames';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { ErrorMessage, useField } from 'formik';
import { Checkbox, FormControl, FormGroup } from 'react-bootstrap';

const ListSelectorField = <ListItem extends ItemId>(props: ListSelectorFieldProps<ListItem>) => {
  const [list, setList] = useState<(ListItem & Selectable)[]>(props.list);
  const [filter, setFilter] = useState('');
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    if (field.value.length === 0) {
      setFilter('');
      setList(
        props.list.map((item) => {
          return {
            ...item,
            isHidden: false,
            isSelected: false
          };
        })
      );
    }
  }, [field.value.length]);

  const filterExistingItems = (e) => {
    setFilter(e.target.value);
    const values = e.target.value.toLocaleLowerCase().split(' ');
    const updatedList = list.map((item) => {
      const hideItem = props.filterFunction(values, item);
      return {
        ...item,
        isHidden: hideItem
      };
    });
    setList(updatedList);
  };

  const selectItem = (id): void => {
    const updatedList = list.map((item) => {
      return {
        ...item,
        isSelected: item.id === id ? !item.isSelected : item.isSelected
      };
    });
    setList(updatedList);
    helpers.setValue(updatedList.filter((item) => item.isSelected));
  };

  return (
    <>
      <div className={style.listSelector}>
        <FormGroup className={style.formGroup}>
          <FormControl
            type="text"
            placeholder="Search"
            value={filter}
            onChange={filterExistingItems}
            bsSize={'small'}
          />
          <FormControl.Feedback>
            <FontAwesomeIcon icon="search" />
          </FormControl.Feedback>
          {list.length !== 0 ? (
            <div className={style.selector}>
              {list.map((item, index) => (
                <div
                  className={cx(style.item, item.isHidden && 'hidden', 'u-margin-V-xs', 'u-margin-H-s')}
                  key={index}>
                  <Checkbox checked={item.isSelected} onChange={() => selectItem(item.id)}>
                    <div className="u-margin-L-xxs">{props.children(item)}</div>
                  </Checkbox>
                </div>
              ))}
            </div>
          ) : (
            <div className={style.selector}>
              <p className="text-center u-margin-A-xl">
                <small>{props.emptyMessage}</small>
              </p>
            </div>
          )}
        </FormGroup>
      </div>
      <div className={cx(style.errorMessage, 'u-margin-B-xs')}>
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
};

export default ListSelectorField;
