import * as React from 'react';
import style from './style.module.scss';
import { humanizedBlockType } from '../helpers';
import { ContentBlock, Lesson, Prompt, Question } from '../types';

interface Props extends ContentBlock {
  onEdit: () => void;
}
export default function ChildBlock({ name, slug, type, id, contents, onEdit }: Props) {
  return (
    <div className={style.childBlock} tabIndex={0} onClick={onEdit}>
      <p>
        <b>{name} </b>
        <i>{humanizedBlockType(type)}</i>
      </p>

      {slug && <p>Slug: {slug}</p>}

      {contents.lessons && renderLessons(contents.lessons)}
      {contents.prompts && renderPrompts(contents.prompts)}
      {contents.questions && renderQuestions(contents.questions)}
    </div>
  );
}

const renderLessons = (lessons: Lesson[]) => {
  return lessons.map(lesson => (
    <p key={lesson.id}>
      <b>Lesson #{lesson.id}:</b> {lesson.title}
    </p>
  ));
};

const renderPrompts = (prompts: Prompt[]) => {
  return prompts.map(p => (
    <p key={p.id}>
      <b>Prompt #{p.id}:</b> {p.title}
    </p>
  ));
};

const renderQuestions = (questions: Question[]) => {
  return questions.map(q => (
    <p key={q.id}>
      <b>Question #{q.id}:</b> {q.title}
    </p>
  ));
};
