import * as React from 'react';
import { Annotation, EssayResponse, EssayReviewData } from './types';
import Essay from './essay/template';
import AnnotationSidebar from './annotation_sidebar/template';
import style from './style.module.scss';
import cx from 'classnames';
import Modal from '@magoosh/lib/modal';

interface EssayReviewState {
  currentResponse: EssayResponse;
  currentAnnotation?: Annotation;
  mobileMode: boolean;
}

export default class EssayReview extends React.Component<EssayReviewData> {
  state: EssayReviewState = {
    currentResponse: this.props.responses[0],
    currentAnnotation: null,
    mobileMode: window.innerWidth < 992
  };

  componentDidMount(): void {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 992 && !this.state.mobileMode) {
        this.setState({ mobileMode: true });
      } else if (window.innerWidth > 992 && this.state.mobileMode) {
        this.setState({ mobileMode: false });
      }
    });
  }

  render() {
    return (
      <div className={this.state.mobileMode ? '' : style.essayReviewBackground}>
        <div className={style.essayReviewContainer}>
          <div className={style.flexRow}>
            <div className={style.leftColumn}>
              <div>
                <h2 className={'u-margin-B-l'}>
                  {this.props.examSection} {`>`} <strong>Sample Responses</strong>
                </h2>
                <h4 className={'font-weight-bold'}>{this.props.prompt.promptType} Prompt:</h4>
                {this.props.prompt.content.map(this.renderPrompt)}
                <h4 className={'font-weight-bold u-margin-T-l'}>Sample Responses:</h4>
                {this.props.responses.map(this.renderResponseLink.bind(this))}
              </div>
            </div>
            {!this.state.mobileMode && <div className={style.rightColumn}></div>}
          </div>
          <div className={style.flexRow}>
            <div className={style.leftColumn}>
              <div>
                <h3 className={'u-margin-B-n'}>
                  Sample Response #{this.props.responses.indexOf(this.state.currentResponse) + 1} - Score{' '}
                  {this.state.currentResponse.evaluation.score}
                </h3>
                {this.props.prompt.learnMoreLink && this.renderLearnMoreLink()}
                <Essay
                  onClickHighlight={this.updateCurrentAnnotation.bind(this)}
                  mobileMode={this.state.mobileMode}
                  {...this.state.currentResponse}
                />
                <h4 className={cx(style.commentaryHeader, 'u-margin-T-m u-padding-T-m')}>
                  Additional Commentary
                </h4>
                <div className={style.evaluation}>
                  <p className={style.evaluationCredit}>by {this.authorTitle()}</p>
                  {this.state.currentResponse.evaluation.text}
                </div>
              </div>
            </div>
            {this.renderAnnotations()}
          </div>
        </div>
      </div>
    );
  }

  renderAnnotations() {
    if (this.state.mobileMode) {
      return this.renderAnnotationModal();
    } else {
      return (
        <div className={style.rightColumn}>
          <div>
            <AnnotationSidebar
              evaluation={this.state.currentResponse.evaluation}
              currentAnnotation={this.state.currentAnnotation}
            />
          </div>
        </div>
      );
    }
  }

  renderAnnotationModal() {
    if (!this.state.currentAnnotation) {
      return null;
    }

    const highlight = this.state.currentResponse.text.slice(
      this.state.currentAnnotation.startIndex,
      this.state.currentAnnotation.endIndex
    );
    const author = this.state.currentResponse.evaluation.author;

    return (
      <Modal
        show={!!this.state.currentAnnotation}
        backdrop={true}
        showCloseButton={true}
        onHide={() => this.setState({ currentAnnotation: null })}>
        <div className={style.modalAnnotation}>
          <h3>Essay Evaluation & Commentary</h3>
          <small>
            by {author.name}
            {author.name && ', '}
            {author.description}
          </small>
          <div className={style.modalHighlight}>
            <mark>{highlight}</mark>
          </div>
          <div className={cx(style.preSpace, 'u-margin-B-l')}>{this.state.currentAnnotation.text}</div>
        </div>
      </Modal>
    );
  }

  renderLearnMoreLink() {
    const scoreName = this.etsScoreNames[this.state.currentResponse.evaluation.score];
    return (
      <a href={this.props.prompt.learnMoreLink.url} className={style.learnMoreLink} target={'_blank'}>
        Learn more about how ETS defines {this.suffixedA(scoreName)} {scoreName}{' '}
        {this.props.prompt.promptType} response.
      </a>
    );
  }

  renderPrompt(content: string) {
    return <div key={content} dangerouslySetInnerHTML={{ __html: content }} />;
  }

  renderResponseLink(response: EssayResponse, index) {
    if (response == this.state.currentResponse) {
      return (
        <p key={response.title}>
          #{index + 1} - {response.title}
        </p>
      );
    } else {
      return (
        <a
          key={response.title}
          onClick={() => this.setState({ currentResponse: response, currentAnnotation: null })}>
          <p>
            #{index + 1} - {response.title}
          </p>
        </a>
      );
    }
  }

  updateCurrentAnnotation(annotation: Annotation) {
    this.setState({ currentAnnotation: annotation });
  }

  authorTitle() {
    const author = this.state.currentResponse.evaluation.author;
    if (author.name) {
      return `${author.name}, ${author.description}`;
    } else {
      return author.description;
    }
  }

  suffixedA(word) {
    if (['a', 'e', 'i', 'o', 'u'].indexOf(word[0]) >= 0) {
      return 'an';
    } else {
      return 'a';
    }
  }

  etsScoreNames = {
    '6': 'outstanding',
    '5': 'strong',
    '4': 'adequate',
    '3': 'limited',
    '2': 'seriously flawed',
    '1': 'fundamentally deficient'
  };
}
