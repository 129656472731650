import React from 'react';
import cx from 'classnames';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  className?: string;
  onClick: () => void;
}

const PastDueItemsAlert: React.FC<Props> = (props) => {
  return (
    <div
      className={cx(props.className, 'd-flex small u-padding-A-xs')}
      style={{
        backgroundColor: colors.yellowLight,
        borderColor: colors.yellow,
        borderWidth: '0 0 2px 0',
        borderStyle: 'solid'
      }}
    >
      <div className="flex-1">
        You have past due tasks that need to be completed now!
      </div>

      <div>
        <a className="text-body" onClick={props.onClick}>
          <strong>Show All Past Due Tasks</strong>
        </a>
      </div>
    </div>
  );
};

export default PastDueItemsAlert;
