import React, { useState } from 'react';
import cx from 'classnames';

import { ModalProvider, useModalContext } from '@magoosh/context/modal_context';
import { paths } from 'config/path_helpers';
import { formatPrice } from 'utilities/money';

import { Col, Collapse, Grid, Row } from 'react-bootstrap';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from "@magoosh/lib/icons";

import PurchaseConfirmation from '@payments/components/purchase_confirmation';
import { Offer } from '@payments/types';

import GradSchoolLogos from './assets/admissions/grad_school_logos.png';
import HeadshotBrittny from './assets/admissions/headshot_brittny.jpg';
import HeadshotDerrick from './assets/admissions/headshot_derrick.jpg';
import HeadshotJen from './assets/admissions/headshot_jen.jpg';
import HeadshotLinnea from './assets/admissions/headshot_linnea.jpg';
import HeadshotTaryn from './assets/admissions/headshot_taryn.jpg';
import TestimonialAshley from './assets/admissions/testimonial_ashley.jpg'
import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

const CLASS_DETAILS = [
  {
    description: "Our Application Tracker takes you through the entire process from start to finish with a comprehensive set of tasks that are necessary to submit a strong application.",
    name: "A Central Hub for Managing Applications"
  },
  {
    description: "Go from blank page to well-written draft with our Guided Writing experience.",
    name: "Step-by-Step Guided Essay Writing"
  },
  {
    description: "Learn how to put your best foot forward as a diverse team of admissions experts use video walkthroughs, workshops, and explainers to guide you through the entire application process.",
    name: "40+ Always Available Graduate Application Videos"
  },
  {
    description: "Each week you have the opportunity to watch a new content from our Admissions experts answering frequently asked questions about the application process and graduate school. Submit your own questions for our experts or hear what other students just like you are asking.",
    name: "New Content Added Weekly"
  },
  {
    description: "An Admissions expert will guide you through the entire application process in 8 one-hour classes available to watch on your schedule.   Combined with our 40+ workshops, walkthroughs, and explainers you will be able to engage with expert content in a way that works best for your admissions journey.",
    name: "8 Engaging On-Demand Classes"
  },
  {
    description: "Use our examples to see exactly what admissions committees are looking for while learning to tell your story in the most effective way possible.",
    name: "Real SOP and Personal Statement samples"
  },
  {
    description: "Get a proven structure for the critical components of your application.",
    name: "Templates for writing CVs, requesting Letters of Rec, and more"
  },
  {
    description: "Quick email support with an Admissions expert",
    name: "Ask an expert"
  },
  {
    description: "Full access to Magoosh Admissions for 12 months. Pause your plan at any time if you application plans change.",
    name: "12 months of access"
  }
];

const AdmissionsAddOn: React.FC<Offer & { compact?: boolean }> = (props) => {
  const [expanded, setExpanded] = useState(!props.compact);

  const { setModal } = useModalContext();

  const totalExcludingTax = props.order.pricing.totalExcludingTax;
  const planPrice = props.order.subscriptionPlan.priceCents;
  const isDiscounted = totalExcludingTax !== planPrice;

  const handleAddOnPurchaseClicked = () => {
    if (props.paymentDetails) {
      setModal({
        header: 'Add Admissions Support',
        body: (
          <PurchaseConfirmation
            orderName="Admissions Support"
            handleClose={() => setModal(null)}
            successMessage='You can access your Admissions videos using the "Admissions" button in your GRE dashboard.'
            {...props}
          />
        )
      });
    }
  };

  return (
    <div className={cx(style.container, 'd-flex flex-column', { 'u-padding-T-m u-padding-B-xl': !props.compact })}>
      {
        !props.compact && (
          <div>
            <h4 className="text-gray">One-Time Offer</h4>
            <h2 className="u-margin-T-n">
              Add Admissions Support for{' '}
              {
                isDiscounted && (
                  <>
                    <span style={{textDecoration: 'line-through'}}>
                      {formatPrice(planPrice, 'usd', true)}
                    </span>
                    {' '}
                  </>
                )
              }
              {formatPrice(totalExcludingTax, 'usd', true)}!
            </h2>

            <hr className="u-margin-T-n" />
          </div>
        )
      }

      <div>
        <p>
          Our Admissions experts will walk you through the entire application process for Master's and PhD programs.
        </p>

        {
          isDiscounted && (
            <p>
              <strong>Plus - if you sign up now, you’ll get a big discount</strong> (regular price
              is {formatPrice(planPrice, 'usd', true)})!
            </p>
          )
        }

        <div className={cx('d-flex u-margin-V-l', style.ctas)}>
          <SecondaryButton
            href={props.paymentDetails ? null : props.orderUrl}
            onClick={props.paymentDetails ? handleAddOnPurchaseClicked : null}
            target="_blank"
          >
            Yes, Add <span className="hidden-xs">Admissions Support</span> for {formatPrice(totalExcludingTax, 'usd', true)}
          </SecondaryButton>

          {props.nextPath && <DefaultButton className="u-margin-L-s" href={props.nextPath}>
            No Thanks
          </DefaultButton>}
        </div>

        <div className="border-radius-all u-margin-V-l u-padding-A-m" style={{backgroundColor: '#F5EEFC'}}>
          <p className="text-uppercase"><strong>Admissions Support includes:</strong></p>

          {
            CLASS_DETAILS.map((classDetail, idx) => (
              <ClassDetail key={idx} classNumber={idx + 1} {...classDetail} />
            ))
          }
        </div>

        {!expanded && <a className="text-gray-light" onClick={() => setExpanded(true)}>See More</a>}

        {
          expanded && (
            <>
              <div className="u-margin-T-xl">
                <div className="u-margin-B-s">
                  <strong><u>Here’s where Magoosh Admissions students have been accepted</u></strong>
                </div>

                <img
                  alt="School logos for Harvard, UCLA, Tufts, Duke, and The London School of Economics and Political Science"
                  src={GradSchoolLogos} />
              </div>

              <div className="d-flex u-margin-T-xl">
                <img
                  alt="Photo of Ashley, a real student"
                  src={TestimonialAshley}
                  style={{objectFit: 'cover', width: 150, height: 150}} />

                <div className="u-margin-L-s">
                  <span style={{color: colors.statusYellow}}>
                    <FontAwesomeIcon icon="star" />
                    <FontAwesomeIcon icon="star" />
                    <FontAwesomeIcon icon="star" />
                    <FontAwesomeIcon icon="star" />
                    <FontAwesomeIcon icon="star" />
                  </span>

                  <p>
                    "The course was well-organized and presented in a friendly, professional manner.
                    I got insights into the admissions process that I otherwise wouldn't have known!"
                  </p>
                  <p>
                    - Ashley, applying for a Masters in Computer Science
                  </p>
                </div>
              </div>

              <div className="u-margin-T-xl">
                <div className="u-margin-B-s">
                  <strong><u>Meet the Magoosh Admissions Teaching Team</u></strong>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <img
                      alt="Derrick Photo"
                      className="img-circle"
                      src={HeadshotDerrick}
                      style={{objectFit: 'cover', height: 100, width: 100}} />
                    <div className="u-margin-T-s small text-center">Derrick Haynes</div>
                  </div>

                  <div>
                    <img
                      alt="Taryn Photo"
                      className="img-circle"
                      src={HeadshotTaryn}
                      style={{objectFit: 'cover', height: 100, width: 100}} />
                    <div className="u-margin-T-s small text-center">Taryn Conyers</div>
                  </div>

                  <div>
                    <img
                      alt="Linnea Photo"
                      className="img-circle"
                      src={HeadshotLinnea}
                      style={{objectFit: 'cover', height: 100, width: 100}} />
                    <div className="u-margin-T-s small text-center">Linnea Newman</div>
                  </div>

                  <div>
                    <img
                      alt="Brittny Photo"
                      className="img-circle"
                      src={HeadshotBrittny}
                      style={{objectFit: 'cover', height: 100, width: 100}} />
                    <div className="u-margin-T-s small text-center">Brittny Stringfield</div>
                  </div>

                  <div>
                    <img
                      alt="Jen Photo"
                      className="img-circle"
                      src={HeadshotJen}
                      style={{objectFit: 'cover', height: 100, width: 100}} />
                    <div className="u-margin-T-s small text-center">Jen Jurgens</div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

interface ClassDetailProps {
  classNumber: number;
  description: string;
  name: string;
}

const ClassDetail: React.FC<ClassDetailProps> = ({ classNumber, description, name }) => {
  const [isExpanded, setIsExpanded] = React.useState(classNumber === 1);

  return (
    <div className="u-margin-T-s">
      <div onClick={() => setIsExpanded(!isExpanded)} style={{cursor: 'pointer'}}>
        <strong>
          <span className="u-margin-R-xs" style={{color: colors.brandFuchsia}}>
            <span className="u-margin-R-xxs">
              <FontAwesomeIcon icon={isExpanded ? 'minus' : 'plus'} />
            </span>
            <span>
              {name}
            </span>
          </span>
        </strong>
      </div>

      <Collapse in={isExpanded}>
        <div>{description}</div>
      </Collapse>
    </div>
  );
};

const AdmissionsAddOnWithProvider: React.FC<Offer> = (props) => {
  return (
    <ModalProvider>
      <AdmissionsAddOn {...props} />
    </ModalProvider>
  );
};

export default AdmissionsAddOnWithProvider;
