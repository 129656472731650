import React from 'react';

import { Select } from '@magoosh/lib/forms';

import { Template, TemplateSectionOption } from '../types';

import style from '../style.module.scss';

interface Props {
  currentSectionOption: TemplateSectionOption;
  currentTemplate: Template;
  onChange: (TemplateSectionOption) => void;
}

const SectionOptionsSelect: React.FC<Props> = (props) => {
  return (
    <Select
      className={style.select}
      label="Section Options"
      onChange={(e) => {
        const sectionOptionIdx = parseInt((e.target as any).value)
        const sectionOption = props.currentTemplate.sectionOptions[sectionOptionIdx]
        props.onChange(sectionOption)
      }}
      value={props.currentTemplate.sectionOptions.indexOf(props.currentSectionOption)}
    >
      {
        props.currentTemplate.sectionOptions.map((sectionOption, idx) => (
          <option key={idx} value={idx}>{sectionOption.name}</option>
        ))
      }
    </Select>
  );
};

export default SectionOptionsSelect;
