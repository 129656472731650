import * as React from 'react';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';

import { ModalProvider } from '@magoosh/context/modal_context';
import configureStore from '@magoosh/store';

import { ActionTypes } from './actions';
import Container from './container';
import { StudyScheduleStore } from './types';

class StudySchedule extends React.Component<StudyScheduleStore> {
  store = configureStore();

  constructor(props) {
    super(props);
    this.store.dispatch({ type: ActionTypes.INITIALIZE, data: props });
  }

  render() {
    return (
      <Provider store={this.store}>
        <ModalProvider>
          <Container />
        </ModalProvider>
      </Provider>
    );
  }
}

export default hot(StudySchedule);
