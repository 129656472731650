import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { flatten, omitBy, pickBy } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';

import CardLayout from '@magoosh/layouts/card';
import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { FilterConfig } from '@magoosh/organisms/filters/types';
import PracticeActivity from '@magoosh/organisms/practice_activity';
import { paths } from 'config/path_helpers';
import { titleize } from 'utilities/formatting';

import { useAppContext } from '@test_prep/app/context';
import { useQueries } from '@test_prep/app/queries';
import ContextualLink from '@test_prep/app/components/contextual_link';

import FilterManager from '../components/filter_manager';
import PaceStat from '../components/pace_stat';
import PerformanceByDifficultyChart from '../components/performance_by_difficulty_chart';
import PerformanceByTypeChart from '../components/performance_by_type_chart';
import PercentCorrectChart from '../components/percent_correct_chart';
import QuestionsStat from '../components/questions_stat';
import StudyTimeChart from '../components/study_time_chart';

import Lesson from './components/lesson';

import style from '../style.module.scss';

interface Props {}

const AnalyticsDetail: React.FC<Props> = (props) => {
  const { branding, filters } = useAppContext().data;
  const history = useHistory();
  const { section, unit } = useParams();
  const q = useQueries();

  const page = q.pageQuery(paths.analyticsDetail(section, unit)).data;

  const sectionName = titleize(section);
  const unitName = titleize(unit).replace('And', 'and');

   const breadcrumbs = [
    { name: 'Home', path: paths.dashboard() },
    { name: 'Analytics', path: paths.analytics(omitBy(filters, (value, key) => key === 'units' || !value)) },
    { name: unitName }
  ];

  const filtersConfig = [
    {
      all: true,
      allowWhileFocused: true,
      className: 'flex-1',
      default: 'all',
      multiple: true,
      key: 'units',
      name: 'Unit',
      options: []
    },
    {
      default: 'all_time',
      key: 'period',
      name: 'Period',
      options: [
        { name: 'All Time', value: 'all_time' },
        { name: 'Custom', value: 'custom' },
        { name: 'Since Last Practice Test', value: 'since_last_practice_test' }
      ]
    }
  ] as FilterConfig[];

  const handleFiltersChanged = (updatedFilters) => {
    history.replace(paths.analyticsDetail(section, unit, omitBy(updatedFilters, (value) => !value)));
  };

  const lessonsCount = () => {
    if (!page.lessons) return 0;

    return flatten(page.lessons.map((group) => group.lessons)).length;
  };

  const lessonsWatchedCount = () => {
    if (!page.lessons) return 0;

    const lessons = flatten(page.lessons.map((group) => group.lessons));
    return lessons.filter((lesson) => (
      (lesson as any).status.video === 'watched' || (lesson as any).status.video === 'completed'
    )).length;
  };

  const relevantFilters = () => {
    if (!filters) return null;

    const relevantKeys = filtersConfig.map((filterConfig) => filterConfig.key).concat('focus');
    return {
      ...pickBy(filters, (_, key) => relevantKeys.indexOf(key) !== -1),
      sections: section,
      units: unit
    };
  };

  return (
    <>
      <Helmet>
        <title>{unitName} Analytics - Magoosh GRE</title>
      </Helmet>

      <CardLayout breadcrumbs={breadcrumbs} linkComponent={ContextualLink}>
        <div className="d-flex flex-column gap-l">

          <div className="d-flex flex-column gap-m">
            <h3 className="u-margin-A-n"><strong>{sectionName} {'>'} {unitName}</strong></h3>

            <div className="d-flex flex-column gap-s gap-lg-m">
              <FilterManager
                filtersConfig={filtersConfig}
                onChange={handleFiltersChanged}
              />

              <hr className="u-margin-A-n" />
            </div>

            <div className="d-flex flex-column flex-lg-row gap-m">
              <div className="d-flex flex-1 flex-column flex-lg-row gap-m">
                <div className="d-flex flex-column gap-m">
                  <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                    <h3 className="u-margin-A-n">Questions</h3>

                    <QuestionsStat filters={relevantFilters()} />
                  </CardLayout.Card>

                   <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                    <h3 className="u-margin-A-n">Pace</h3>

                    <PaceStat filters={relevantFilters()} />
                  </CardLayout.Card>
                </div>

                <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                  <h3 className="u-margin-A-n">Percent Correct</h3>
                  <PercentCorrectChart filters={relevantFilters()} />
                </CardLayout.Card>

                <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                  <h3 className="u-margin-A-n">Study Time</h3>
                  <StudyTimeChart filters={relevantFilters()} />
                </CardLayout.Card>
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row gap-m">
              <CardLayout.Card className={cx(style.card2Col, 'd-flex flex-1 flex-column gap-m')}>
                <h3 className="u-margin-A-n">Performance by Question Difficulty</h3>

                <PerformanceByDifficultyChart filters={relevantFilters()} />
              </CardLayout.Card>

              <CardLayout.Card className={cx(style.card2Col, 'd-flex flex-1 flex-column gap-m')}>
                <h3 className="u-margin-A-n">Performance by Question Type</h3>

                <PerformanceByTypeChart filters={relevantFilters()} sections={section} />
              </CardLayout.Card>
            </div>
          </div>

          <div className="d-flex flex-column gap-m">
            <div className="d-flex flex-column gap-s gap-lg-m">
              <h3 className="u-margin-A-n"><strong>Practice</strong></h3>

              <hr className="u-margin-A-n" />
            </div>

            <div style={{ minHeight: 100, position: 'relative' }}>
              {
                page ? (
                  <div className="d-flex flex-column gap-s">
                    <div className="d-flex flex-column flex-lg-row gap-m">
                      {
                        page.practiceActivities.map((activity, idx) => (
                          <div key={idx} className={cx(style.card4Col, 'd-flex flex-1')}>
                            <PracticeActivity key={idx} activity={activity} source="analytics" />
                          </div>
                        ))
                      }
                    </div>

                    {
                      branding.accessLevel !== 'basic' && (
                        <div className="small text-center text-gray-light">
                          or
                          {' '}
                          <ContextualLink
                            className="text-gray-light"
                            href={
                              paths.startPractice({
                                'practice_session[source]': 'analytics',
                                'practice_session[tag_ids]': page.subjectTagIds.join(','),
                                section: page.examSectionId
                              })
                            }
                          >
                            practice with custom settings
                          </ContextualLink>
                        </div>
                      )
                    }
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <LoadingIndicator />
                  </div>
                )
              }
            </div>
          </div>

          <div className="d-flex flex-column gap-m">
            <div className="d-flex flex-column gap-s gap-lg-m">

              <div className="align-items-center d-flex gap-s">
                <h3 className="u-margin-A-n"><strong>Lessons</strong></h3>

                {
                  page && (
                    <div style={{ fontSize: 18}}>
                      ({lessonsWatchedCount()} of {lessonsCount()} watched)
                    </div>
                  )
                }
              </div>

              <hr className="u-margin-A-n" />
            </div>

            <div style={{ minHeight: 100, position: 'relative' }}>
              {
                page ? (
                  page.lessons.map((lessonGroup, idx) => (
                    <div key={idx} className="d-flex flex-column gap-m">
                      {lessonGroup.name && <h4 className="u-margin-A-n"><strong>{lessonGroup.name}</strong></h4>}

                      {
                        lessonGroup.lessons.map((lesson) => (
                          <ContextualLink key={lesson.slug} className={style.cardLink} href={`${paths.lesson(lesson.slug)}?source=analytics`}>
                            <CardLayout.Card>
                              <Lesson {...lesson} />
                            </CardLayout.Card>
                          </ContextualLink>
                        ))
                      }
                    </div>
                  ))
                ) : (
                  <div className="d-flex justify-content-center">
                    <LoadingIndicator />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </CardLayout>
    </>
  );
};

export default AnalyticsDetail;
