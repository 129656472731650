import * as React from 'react';
import style from './style.module.scss';
import { SeriesData, SeriesRecommendationsData } from './types';
import { paths } from 'config/path_helpers';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

interface Props {
  recommendations: SeriesRecommendationsData[];
}

const SeriesRecommendations: React.FC<Props> = (props) => (
  <div className="u-margin-B-s u-padding-A-s bg-gray-lightest border-radius-all">
    <div className="page-header page-header-hack clearfix">
      <h3>Suggested Lessons</h3>
    </div>
    {props.recommendations.map((recs, index) => (
      <div key={index}>
        <h5>{recs.section}</h5>
        <ul className="list-unstyled">
          {recs.series.map((series, index) => (
            <li key={index}>
              <a href={paths.lessonSeries(series.slug)}>{series.name}</a>
              {renderLockIcon(series)}
            </li>
          ))}
        </ul>
      </div>
    ))}

    <p className="text-center text-gray-light">
      <small>Suggestions are based on your lesson progress</small>
    </p>
  </div>
);

const renderLockIcon = (series: SeriesData) => {
  if (series.locked) {
    return <>
      {' '}<FontAwesomeIcon icon={'lock'} className={style.lock} />
    </>;
  }

  return null;
};

export default SeriesRecommendations;
