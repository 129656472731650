import * as React from 'react';
import fetch from 'utilities/fetch';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import { PrimaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import cx from 'classnames';
import style from './style.module.scss';
import TextFileUpload from './text_file_upload';
import ClipboardUpload from './clipboard_upload';
import { Branding } from 'types/branding';
import { ClassSection } from 'types/class_section';
import DropdownList from 'react-widgets/lib/DropdownList';

interface Props {
  authenticityToken: string;
  brandings: Branding[];
  classSections: ClassSection[];
}

export default class ProvisionForm extends React.Component<Props> {
  state = {
    classSectionRequiredError: false,
    selectedClassSectionId: null,
    clipboardUpload: null,
    brandingRequiredError: false,
    selectedBrandingId: null,
    selectedTab: 'clipboardUpload',
    showSubmissionError: false,
    submissionError: '',
    submitting: false,
    textFileUpload: null
  };

  render() {
    return (
      <div className={style.form}>
        <h1>Assign Student Accounts</h1>
        <p className='u-margin-T-m'>
          Paste or upload email addresses below. Each address must be separated by a comma, space,
          tab, or linebreak.
        </p>
        <p>
          An account will be created for each email address, using available accounts from your
          organization&apos;s bulk purchase. Emails will be sent to the students with instructions
          for setting up their account passwords.
        </p>
        <p>
          Duplicate emails will be filtered out, such that only one account per email will created.
          Emails that have already been used for an account will also be filtered out.
        </p>
        <Row className={style.fieldRow}>
          <Col md={5} sm={8}>
            {this.renderBrandingSelect()}
          </Col>
          <Col md={5} className={style.fieldError}>
            {this.renderBrandingRequiredError()}
          </Col>
        </Row>
        <Row className={style.fieldRow}>
          <Col md={5} sm={8}>
            {this.renderClassSectionSelect()}
          </Col>
          <Col md={5} className={style.fieldError}>
            {this.renderClassSectionRequiredError()}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Tabs
              className={cx(style.tabs, 'u-margin-T-m')}
              onSelect={tab => this.setState({ selectedTab: tab })}
              id='provision-form-tabs'>
              <Tab
                eventKey='clipboardUpload'
                title='Paste From Clipboard'
                className={cx(style.tab, 'u-margin-B-m u-margin-T-m')}>
                <ClipboardUpload
                  onAddEmails={emails => {
                    this.setState({ clipboardUpload: emails });
                  }}
                />
              </Tab>
              <Tab
                eventKey='textFileUpload'
                title='Text File Upload'
                className={cx(style.tab, 'u-margin-B-m u-margin-T-m')}>
                <TextFileUpload
                  onAddFile={file => {
                    this.setState({ textFileUpload: file });
                  }}
                />
              </Tab>
            </Tabs>
            {this.renderSubmissionError()}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <PrimaryButton
              onClick={this.onSubmit}
              submitting={this.state.submitting}
              className='u-margin-T-m'>
              Assign Student Accounts
            </PrimaryButton>
          </Col>
        </Row>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.brandings.length === 1) {
      this.setState({ selectedBrandingId: this.props.brandings[0].id });
    }
  }

  readFileAsText = file => {
    // Note: FileReader won't work on IE <10, but it does work on virtually every other browser
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      // This event fires after the reader has successfully finished with readAsText
      reader.onload = () => {
        const result = String(reader.result).replace(/\r/g, '');
        resolve(result);
      };

      reader.onerror = () => {
        reader.abort();
        reject(reader.error);
      };

      reader.readAsText(file);
    });
  }

  onSubmit = async () => {
    this.setState({
      brandingRequiredError: false,
      showSubmissionError: false,
      classSectionRequiredError: false,
      submitting: true
    });

    if (!this.state.selectedBrandingId) {
      this.setState({ brandingRequiredError: true, submitting: false });
      return;
    }

    if (!this.state.selectedClassSectionId) {
      this.setState({ classSectionRequiredError: true, submitting: false });
      return;
    }

    let emailData;

    if (this.state.selectedTab === 'textFileUpload') {
      if (this.state.textFileUpload) {
        emailData = await this.readFileAsText(this.state.textFileUpload);
      }
    } else {
      emailData = this.state.clipboardUpload;
    }

    fetch(paths.api.classSectionsProvision(), {
      method: 'POST',
      body: JSON.stringify({
        provision: {
          emails: emailData,
          brandingId: this.state.selectedBrandingId,
          classSectionId: this.state.selectedClassSectionId
        },
        authenticityToken: this.props.authenticityToken
      })
    })
      .then(() => {
        window.location.assign(paths.classes());
      })
      .catch((response) => {
        this.setState({
          showSubmissionError: true,
          submissionError: response.errors,
          submitting: false
        });
      });
  }

  renderBrandingSelect = () => {
    if (this.props.brandings.length === 1) {
      const branding = this.props.brandings[0];
      return `${branding.accountsAvailable} ${branding.name} accounts available.`;
    }

    return (
      <DropdownList
        data={this.props.brandings}
        onChange={value => this.setState({ selectedBrandingId: value.id })}
        placeholder='Select an exam'
        textField={branding => branding && `${branding.name} (${branding.accountsAvailable} accounts available)`}
        disabled={this.props.brandings.filter(branding => branding.accountsAvailable <= 0)}
        valueField="id"
      />
    );
  }

  renderBrandingRequiredError = () => {
    if (!this.state.submitting && this.state.brandingRequiredError) {
      return <div>Please select an exam</div>;
    }

    return null;
  }

  renderSubmissionError = () => {
    if (!this.state.submitting && this.state.showSubmissionError) {
      return (
        <React.Fragment>
          <p className={cx(style.submissionError, 'u-margin-T-m')}>
            An error occurred while provisioning accounts:
          </p>
          <p className={cx(style.submissionError, 'u-margin-T-m')}>{this.state.submissionError}</p>
          <p className={cx(style.submissionError, 'u-margin-T-m')}>
            No accounts were provisioned. Please address the errors and try again.
          </p>
        </React.Fragment>
      );
    }

    return null;
  }

  renderClassSectionSelect = () => {
    return (
      <DropdownList
        filter='contains'
        data={this.props.classSections}
        textField='name'
        valueField='id'
        onChange={value => this.setState({ selectedClassSectionId: value.id })}
        placeholder='Select a class section'
      />
    );
  }

  renderClassSectionRequiredError = () => {
    if (!this.state.submitting && this.state.classSectionRequiredError) {
      return <span>Please select a class section</span>;
    }

    return null;
  }
}
