import React from 'react';

interface Props {
  color: string;
  size: number;
}

const NoteIcon = (props) => {
  return (
    <svg width={props.size} height={props.size} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
      <g fill={props.color}>
        <path d="M55.172 36.882l-9.14-24.08a3.886 3.886 0 00-5.006-2.24L29.12 15.12a1.394 1.394 0 00.991 2.604l11.89-4.542a1.12 1.12 0 011.41.633l9.14 24.08a1.12 1.12 0 01-.633 1.411l-9.61 3.635V25.67a3.887 3.887 0 00-3.88-3.881H32.58a1.4 1.4 0 000 2.8h5.846a1.12 1.12 0 011.12 1.12v25.693a1.12 1.12 0 01-1.12 1.12h-18.72v-8.916a3.858 3.858 0 00-3.853-3.847H6.967v-11.1a1.4 1.4 0 10-2.8 0V41.15c0 .37.147.724.408.985L17.332 54.88c.262.262.616.409.986.409h20.11a3.887 3.887 0 003.88-3.88V45.92l10.64-4.032a3.881 3.881 0 002.24-5.006h-.017zm-38.248 6.72v6.927L8.96 42.56h6.894a1.07 1.07 0 011.07 1.047v-.005z"/><path d="M4.884 14.269h.56a14.28 14.28 0 011.338 7.437A3.416 3.416 0 009.8 25.418c.24.026.481.04.723.04 2.083 0 4.659-.868 7.201-2.425l4.027 5.762a1.394 1.394 0 002.284-1.596l-4.037-5.785c2.615-2.072 4.435-4.48 4.967-6.619a3.416 3.416 0 00-2.447-4.11 14.28 14.28 0 01-6.524-3.82c.806-1.724.773-3.315-.062-4.513C14.213-.106 9.75.241 5.556 3.175a13.44 13.44 0 00-4.178 4.48C.364 9.56.308 11.33 1.216 12.628c.767 1.103 2.077 1.64 3.668 1.64zm16.956-.89a.622.622 0 01.448.739c-.43 1.741-2.385 4.032-4.978 5.84-2.593 1.81-5.443 2.862-7.23 2.672a.621.621 0 01-.56-.678 16.962 16.962 0 00-1.338-8.338 15.12 15.12 0 003.36-1.798 15.118 15.118 0 002.85-2.565 16.963 16.963 0 007.448 4.127zM3.836 8.96a10.802 10.802 0 013.316-3.511 9.71 9.71 0 015.168-1.943 1.636 1.636 0 011.344.442c.27.38.135 1.176-.341 2.066A10.796 10.796 0 019.996 9.52c-3.052 2.128-5.93 2.318-6.496 1.506-.268-.386-.14-1.164.336-2.066z" />
      </g>
    </svg>
  );
};

export default NoteIcon;
