import * as React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FieldInput, FieldSelect, Form } from '@magoosh/lib/forms/template';

import 'react-widgets/dist/css/react-widgets.css';
import { Assignment, ClassSection } from '../types';
import { ContentBlock } from '@magoosh/admin/content_block_editor/types';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import { FieldDateTime } from '@magoosh/lib/forms/datetime_picker';
import { futureDate, required } from '@magoosh/lib/forms/validations';
import Modal from '@magoosh/lib/modal';
import { groupBy, partition, flatten } from 'lodash';
import { AssignmentFormSubmissionSection } from './assignmentFormSubmissionSection';
import { AssignmentPreviewData } from './types';
import Collapsible from './collapsible';
import { pluralize } from 'utilities/pluralize';
import style from './style.module.scss';

export interface AssignmentFormDialogProps {
  assignment?: Assignment;
  title?: string;
  isOpen?: boolean;
  onHide: () => any;
  onSuccess: (assignment: Assignment) => any;
}

export interface AssignmentFormDialogState {
  classSections?: ClassSection[];
  contentBlocks?: ContentBlock[];
  currentAssignment: AssignmentPreviewData;
}

interface AssignmentFormProps {
  classSections: ClassSection[];
  contentBlocks: ContentBlock[];
  onPickAssignment: (id: any) => any;
  currentAssignment?: AssignmentPreviewData;
}

function renderContentBlockOptions(contentBlocks: ContentBlock[]) {
  if (!contentBlocks) {
    return null;
  }

  const examSectionGroups = groupBy(contentBlocks, 'examSectionDisplayName');
  const orderedGroupNames = flatten(
    partition(Object.keys(examSectionGroups), (groupName) => groupName === 'ACT' || groupName === 'SAT')
  );

  return orderedGroupNames.map((examSectionDisplayName) => {
    const examSectionContentBlocks = examSectionGroups[examSectionDisplayName];
    return (
      <optgroup label={examSectionDisplayName} key={examSectionDisplayName}>
        {examSectionContentBlocks.map((contentBlock) => {
          return (
            <option key={contentBlock.id} value={contentBlock.id}>
              {contentBlock.name}
            </option>
          );
        })}
      </optgroup>
    );
  });
}

const AssignmentForm = (props: InjectedFormProps<Assignment> & AssignmentFormProps) => {
  const { handleSubmit, classSections, contentBlocks, error } = props;

  return (
    <Form onSubmit={handleSubmit} error={error}>
      <FieldInput label="Title" name="title" type="text" validate={required} />
      <FieldSelect label="Assign to class" name="classSectionId" validate={required}>
        <option />
        {(classSections || []).map((classSection) => {
          return (
            <option key={classSection.id} value={classSection.id}>
              {classSection.ensuredName}
            </option>
          );
        })}
      </FieldSelect>
      <FieldDateTime
        name="dueDate"
        label="Due Date (optional)"
        min={new Date()}
        validate={futureDate}
        defaultTime="11:59 PM"
      />
      <FieldSelect
        label="Select content"
        name="contentBlockId"
        validate={required}
        onChange={props.onPickAssignment}>
        <option />
        {renderContentBlockOptions(contentBlocks)}
      </FieldSelect>
      <AssignmentFormSubmissionSection {...props} />
      {props.currentAssignment && renderAssignmentPreview(props.currentAssignment)}
    </Form>
  );
};

const AssignmentReduxForm = reduxForm<Assignment, AssignmentFormProps>({
  form: 'assignment'
})(AssignmentForm);

const renderAssignmentPreview = (props: AssignmentPreviewData) => {
  return (
    <div>
      <h3>{props.name}</h3>
      <h4>Estimated time: {props.estimatedTime} minutes</h4>
      <Collapsible header={pluralize(props.lessons.length, 'Lesson')} startOpen={true}>
        <ol>
          {props.lessons.map((lesson) => {
            return (
              <li key={lesson.id}>
                <a className={style.visitable} href={paths.lesson(String(lesson.id))} target="_blank">
                  {lesson.name}
                </a>
              </li>
            );
          })}
        </ol>
      </Collapsible>
      {renderQuestionList(props.quizQuestionIds, 'Quiz Question')}
      {renderQuestionList(props.practiceQuestionIds, 'Practice Question')}
    </div>
  );
};

const renderQuestionList = (questions, label) => {
  return (
    <Collapsible header={pluralize(questions.length, label)}>
      <ul>
        {questions.map((questionId, idx) => {
          return (
            <li key={questionId}>
              <a className={style.visitable} href={paths.prompt(questionId)} target="_blank">
                Question {idx + 1}
              </a>
            </li>
          );
        })}
      </ul>
    </Collapsible>
  );
};

export default class AssignmentDialog extends React.Component<
  AssignmentFormDialogProps,
  AssignmentFormDialogState
> {
  state = {
    classSections: [],
    contentBlocks: [],
    currentAssignment: null
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== true && this.props.isOpen === true) {
      fetch(paths.api.assignmentOptions()).then((data) => {
        this.setState(data);
      });
    }
  }

  render() {
    return (
      <Modal show={this.props.isOpen} title={this.props.title} onHide={this.props.onHide}>
        <AssignmentReduxForm
          classSections={this.state.classSections}
          contentBlocks={this.state.contentBlocks}
          initialValues={this.props.assignment}
          onPickAssignment={this.onPickAssignment}
          currentAssignment={this.state.currentAssignment}
          onSubmit={this.saveAssignment}
        />
      </Modal>
    );
  }

  saveAssignment = (values) => {
    return fetch(paths.api.saveNewAssignment(), {
      body: JSON.stringify({ assignment: values }),
      method: 'POST'
    }).then((assignment) => {
      this.props.onSuccess(assignment);
    });
  };

  onPickAssignment = (event) => {
    const id = event.currentTarget.value;
    if (id) {
      fetch(paths.api.assignmentPreview(id)).then((response) => {
        this.setState({ currentAssignment: response });
      });
    }
  };
}
