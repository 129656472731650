import * as React from 'react';
import { AssignmentDetailProps, StudyItem } from './types';
import styles from './style.module.scss';
import moment from 'moment';
import { Checkmark, FontAwesomeIcon } from '@magoosh/lib/icons';
import { PrimaryButton, SecondaryButton } from '@magoosh/lib/button';
import Pencil from './pencil.svg';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import StatusBadge from '@magoosh/pages/sectioned_assignments/organisms/status_badge';
import Desk from '@magoosh/assets/desk.svg';
import Gradcap from '../assets/gradcap.png';
import { pluralize } from 'utilities/pluralize';

export const Detail: React.FC<AssignmentDetailProps> = (props: AssignmentDetailProps) => {
  return (
    <>
      <div className={styles.breadcrumbs}>
        <a href={'/'}>Dashboard</a>
        &nbsp;&gt;&nbsp;
        <a href={'/assignments'}>Assignments</a>
        <span className={styles.currentBreadcrumb}>
          &nbsp;&gt;&nbsp;
          {props.title}
        </span>
      </div>
      <div className={styles.assignmentDetail}>
        <div className={styles.icon}>
          <img alt="Desk" src={Desk} />
        </div>
        <div className={styles.dueDate}>
          {props.dueDate && (
            <div>
              Due {moment(props.dueDate).format('l')} by {moment(props.dueDate).format('h:mma')} (Pacific)
            </div>
          )}
          {moment(props.dueDate).isBefore(moment().add(1, 'week')) && (
            <div>
              <StatusBadge {...props} />
            </div>
          )}
        </div>
        <div className={styles.title}>
          <h2>{props.title}</h2>
        </div>
        <div className={styles.estimatedTime}>
          Estimated Time <strong>{props.estimatedTimeMinutes} minutes</strong>
        </div>
        {props.instructions && (
          <div className={styles.instructions}>
            <strong>Instructions</strong>
            <br />
            {props.instructions}
          </div>
        )}
        <div className={styles.session}>{props.session?.name}</div>
        <div className={styles.howTo}>
          <p className="u-margin-V-s">
            Use the links below to access the activities in your assignment. When you’ve finished, click the
            Complete Assignment button.
          </p>
        </div>

        <div className={styles.sections}>
          {props.sections.map((section, index) => (
            <div className={styles.section} key={index}>
              {props.sections?.length > 1 && <div className={styles.number}>{index + 1}</div>}
              <div className={styles.name}>{section.name}</div>
              <div className={styles.sectionEstimatedTime}>{section.estimatedTimeMinutes} minutes</div>
              <div className={styles.items}>
                {section.studyItems?.map((studyItem) => {
                  const Tag = studyItemTag(studyItem);

                  return (
                    <div key={studyItem.name} className={styles.itemContainer}>
                      <Tag
                        className={styles.itemLabel}
                        href={studyItemUrl(studyItem, props.id)}
                        onClick={() => {
                          studyItemOnClick(studyItem);
                        }}>
                        <div>{renderStudyItemIcon(studyItem)}</div>
                        <div>{studyItemName(studyItem)}</div>
                      </Tag>
                      {studyItem.completed && studyItem.type == 'Practice' && !studyItem.quizMode ?
                        <div>
                          <SecondaryButton
                            href={studyItemUrl(studyItem, props.id, true)}
                            size={'xs'}
                          >
                            Redo Practice Questions
                          </SecondaryButton>
                        </div>
                        : null}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.submitInstructions}>
          Let your instructor know that you did this work by marking it as complete. You can still review
          lessons or retake practice sets after completing the assignment.
        </div>
        <div className={styles.submitButton}>
          <PrimaryButton
            disabled={props.submitted}
            onClick={() => {
              fetch(paths.api.submitAssignment(props.id), { method: 'put' }).then(() => {
                window.location.assign(paths.assignments());
              });
            }}>
            {props.submitted ? 'Assignment Completed' : 'Complete Assignment'}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

const studyItemTag = (studyItem: StudyItem) => {
  if (studyItem.url) {
    return `a`;
  }

  return `div`;
};

const studyItemUrl = (studyItem: StudyItem, assignmentId, retake: boolean = false) => {
  const url = (retake ? studyItem.retakeUrl : studyItem.url) || studyItem.url;

  if (url && studyItem.type != 'Review') {
    let prefix;

    if (url.includes('?')) {
      prefix = '&';
    } else {
      prefix = '?';
    }

    return `${url}${prefix}assignment_id=${assignmentId}`;
  } else {
    return '#';
  }
};

const studyItemOnClick = (studyItem: StudyItem) => {
  if (studyItem.type == 'Review') {
    fetch(paths.api.userContentBlockProgressMarkComplete(studyItem.id), { method: 'POST' }).then(() => {
      window.location.assign(paths.answers());
    });
  } else {
    return true;
  }
};

const studyItemName = (studyItem: StudyItem) => {
  switch (studyItem.type) {
    case 'Lesson':
    case 'Practice':
    case 'Review':
    case 'Misc':
    case 'Drill':
      return `${studyItem.name} ${
        studyItem.estimatedTimeMinutes ? `(${pluralize(studyItem.estimatedTimeMinutes, 'minute')})` : ''
      }`;
    case 'RichText':
      return (
        <>
          <div>{studyItem.name}</div>
          <div dangerouslySetInnerHTML={{ __html: studyItem.richText }} />
        </>
      );
  }
};

const renderStudyItemIcon = (studyItem: StudyItem) => {
  switch (studyItem.type) {
    case 'Lesson':
      return <Checkmark completed={studyItem.completed} />;
    case 'Practice':
    case 'Drill':
      if (studyItem.completed) {
        return <Checkmark completed={studyItem.completed} />;
      }

      return <img alt="Pencil" src={Pencil} />;
    case 'Review':
      if (studyItem.completed) {
        return <Checkmark completed={studyItem.completed} />;
      }

      return <img src={Gradcap} />;
  }
};

export default Detail;
