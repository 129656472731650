import React, { useState, useLayoutEffect } from 'react';
import { isNull, max } from 'lodash';

import Legend from '@magoosh/lib/charts/legend';

interface Props {
  colorPalette: {
    dark: string;
    light: string;
  };
  data: {
    name?: string;
    value: number;
  }[];
  hideLabel?: boolean;
  hideLegend?: boolean;
  labelWidth?: number;
  seriesNames?: string[];
}

const SimpleBarChart: React.FC<Props> = (props) => {
  const [labelWidth, setLabelWidth] = useState(0);

  useLayoutEffect(() => {
    if (props.hideLabel) return;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = "14px 'Tondo', Arial, 'Helvetica Neue', Helvetica, sans-serif";

    const names = props.data.map((d) => d.name);
    const width = max(names.map((name) => context.measureText(name).width));

    setLabelWidth(width);
  }, [props.data]);

  return (
    <div className="d-flex flex-column gap-m">
      <div className="d-flex flex-column gap-s">
        {
          props.data.map((datum, idx) => (
            <div key={idx} className="align-items-center d-flex gap-s">
              {
                !props.hideLabel && datum.name && (
                  <div className="small text-right" style={{ width: props.labelWidth || labelWidth }}>
                    {datum.name}
                  </div>
                )
              }

              <div className="flex-1">
                <SectionedBar colorPalette={props.colorPalette} value={datum.value} />
              </div>
            </div>
          ))
        }
      </div>

      {
        !props.hideLegend && props.seriesNames?.length === 2 && (
          <Legend
            groups={[
              { colors: [props.colorPalette.dark], name: props.seriesNames[0] },
              { colors: [props.colorPalette.light], name: props.seriesNames[1] }
            ]}
          />
        )
      }
    </div>
  );
};

interface SectionedBarProps {
  colorPalette: {
    dark: string;
    light: string;
  };
  value: number;
}

const SectionedBar: React.FC<SectionedBarProps> = (props) => {
  const noActivity = () => {
    return isNull(props.value);
  };

  const renderLabel = () => {
    if (noActivity()) {
      return 'No activity';
    } else {
      return `${props.value}%`;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {
        noActivity() ? (
          <div
            style={{
              backgroundColor: props.colorPalette.light,
              borderRadius: 8,
              height: 20,
              width: '100%'
            }}
          />
        ) : (
          <div className="d-flex">
            <div
              style={{
                backgroundColor: props.colorPalette.dark,
                borderRadius: '9px 0 0 9px',
                height: 18,
                width: 35
              }}
            />

            <div className="d-flex flex-1 gap-xxs">
              <div
                style={{
                  backgroundColor: props.colorPalette.dark,
                  borderRadius: props.value === 100 ? '0 9px 9px 0' : 0,
                  height: 18,
                  width: `${props.value}%`
                }}
              />

              <div
                style={{
                  backgroundColor: props.colorPalette.light,
                  borderRadius: '0 9px 9px 0',
                  height: 18,
                  width: `${100 - props.value}%`
                }}
              />
            </div>
          </div>
        )
      }

      <div
        style={{
          color: noActivity() ? props.colorPalette.dark : '#ffffff',
          fontSize: 12,
          fontWeight: 'bold',
          left: 10,
          top: '50%',
          position: 'absolute',
          transform: 'translateY(-50%)',
          zIndex: 10
        }}
      >
        {renderLabel()}
      </div>
    </div>
  );
};

export default SimpleBarChart;
