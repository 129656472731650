import React, { useEffect, useState } from 'react'
import { DataTable } from '@magoosh/lib/data_table'
import { paths } from 'config/path_helpers'
import fetch from 'utilities/fetch'
import useDataQuery from '@magoosh/lib/data_table/use_data_query'
import { Search } from '@magoosh/lib/data_table/search'
import Multiselect from 'react-widgets/lib/Multiselect'
import useReportingFilterOptions from '@magoosh/b2b/app/pages/reporting/reporting_filter_options'
import colors from '@magoosh/b2b/app/assets/style.module.scss'
import { Icon } from '@magoosh/b2b/app/assets/icons'
import cx from 'classnames'
import dataTableStyles from '../style.module.scss'
import styles from './style.module.scss'
import { DownloadButton } from '@magoosh/lib/data_table/download_button';

export const Modules: React.FC<SubjectsProps> = () => {
  const [modules, setModules] = useState([])
  const [selectedModulesIDs, setSelectedModulesIDs] = useState([])

  const modulesToShow = () => {
    if (!selectedModulesIDs.length) return modules

    return modules.filter(({ id }) => selectedModulesIDs.includes(id))
  }

  const columns = modulesToShow().map(module => {
    return {
      name: <div className={styles.columnHeader}>
        <div className={styles.moduleName}>{module.name} {module.parentName.includes('Digital') && '(Digital)'}</div>
        <div className={styles.completed}>Completed</div>
        <div className={styles.quizzes}>Quizzes</div>
      </div>,
      selector: `${module.parameterizedName}Performance`,
      cell: (student) => {
        const percentCompleted = student[`${module.parameterizedName}Performance`] ? `${Math.round(student[`${module.parameterizedName}Performance`] * 100)}%` : '-'

        return <div className={styles.dataCell}>
          <div>{percentCompleted}</div>
          <div>
            {student[`${module.parameterizedName}StartedQuizzesCount`]} started
            <br/>
            {student[`${module.parameterizedName}PassedQuizzesCount`]} passed
          </div>
        </div>
      },
      sortable: true,
      sortField: `${module.parameterizedName}Performance`
    }
  })

  useEffect(() => {
    fetch(paths.api.partner.modules())
      .then(data => setModules(data))
      .catch(() => setModules([]))
  }, [])

  const { queryParameters, dataQueryTableProps, runQuery } = useDataQuery(
    paths.api.partner.modulesStudents,
    { sortColumn: 'full_name' }
  )

  const { reportingFiltersDropdown } = useReportingFilterOptions(runQuery);

  const studentIcon = <Icon iconType='Student' fill={colors.supplementaryRainforest} size='m' />

  return (
    <DataTable
      {...dataQueryTableProps}
      primaryAttribute={{ selector: 'fullName', name: 'Student', sortable: true }}
      secondaryAttributeSelector="email"
      linkBaseUrl="/students/"
      columns={columns}
      wrapColumnHeaders>
      <DataTable.Filters>
        <div className={cx(dataTableStyles.filters, dataTableStyles.container)}>
          <div className={dataTableStyles.filters}>
            <Search runQuery={runQuery} />
            {reportingFiltersDropdown}
          </div>
          <div className={cx(dataTableStyles.filters, dataTableStyles.wideWrapper)}>
            <Multiselect
              data={modules}
              filter="contains"
              valueField="id"
              textField="name"
              groupBy="parentName"
              containerClassName={dataTableStyles.filters}
              onChange={(value) => setSelectedModulesIDs(value.map(({ id }) => id))}
              placeholder={"Modules"}
            />
          </div>
        </div>
      </DataTable.Filters>
      <DataTable.HeaderActions>
        <DownloadButton
          queryParameters={queryParameters}
          fetchUrl={paths.api.partner.modulesStudents}
          fileName={'modules_data.csv'}
        />
      </DataTable.HeaderActions>
      <DataTable.ItemIcon>{() => studentIcon}</DataTable.ItemIcon>
    </DataTable>
  );
}
