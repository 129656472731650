import React from 'react';

import MagooshLogo from '@magoosh/assets/magoosh-logo-purple.png';
import { paths } from 'config/path_helpers';

import EmoryGoizuetaLogo from './assets/emory_goizueta_logo.png';
import NYUSternLogo from './assets/nyu_stern_logo.png';
import style from './style.module.scss';

interface Props {
  brand: string;
}

const BrandLogo: React.FC<Props> = (props) => {
  if (props.brand === 'emory-goizueta') {
    return (
      <a href="https://goizueta.emory.edu/">
        <img alt="Emory Goizueta Business School" className={style.emoryGoizuetaLogo} src={EmoryGoizuetaLogo} />
      </a>
    );
  } else if (props.brand === 'nyu-stern') {
    return (
      <a href="https://www.stern.nyu.edu/">
        <img alt="NYU Stern School of Business" className={style.nyuSternLogo} src={NYUSternLogo} />
      </a>
    );
  } else {
    return (
      <a href={paths.plans()}>
        <img alt="Magoosh" className={style.magooshLogo} src={MagooshLogo} />
      </a>
    );
  }
};

export default BrandLogo;
