import React, { useState } from 'react'

interface Props {
  children: React.ReactNode;
  onDrop: (fromIndex: number, toIndex: number) => void;
}

const Draggable: React.FC<Props> = (props) => {
  const [currentDiv, setCurrentDiv] = useState(null);
  const [divs, setDivs] = useState(props.children);
  const [toDiv, setToDiv] = useState(null);

  const dragDrop = () => {
    if (currentDiv !== toDiv) {
      props.onDrop(currentDiv, toDiv);
    }

    setCurrentDiv(null);
    setToDiv(null);
  };

  const dragEnter = (index: number) => {
    setToDiv(index);
  };

  const dragStart = (index: number) => {
    setCurrentDiv(index);
  };

  return (
    <>
      {
        React.Children.map(props.children, (child, idx) => {
          const shouldDisplayDragTarget = idx === toDiv && toDiv !== currentDiv;

          return (
            <DraggableChildComponent
              key={idx}
              onDragEnd={dragDrop}
              onDragEnter={() => dragEnter(idx)}
              onDragStart={() => dragStart(idx)}
            >
              {
                shouldDisplayDragTarget && currentDiv < idx && (
                  <>
                    {child}
                    <div className="bg-info u-margin-T-s" style={{ height: 50 }} />
                  </>
                )
              }

              {
                shouldDisplayDragTarget && currentDiv > idx && (
                  <>
                    <div className="bg-info u-margin-B-s" style={{ height: 50 }} />
                    {child}
                  </>
                )
              }

              { !shouldDisplayDragTarget && child }
            </DraggableChildComponent>
          )
        })
      }
    </>
  );
};

interface ChildProps {
  children: React.ReactNode;
  onDragStart: () => void;
  onDragEnter: () => void;
  onDragEnd: () => void;
}

const DraggableChildComponent: React.FC<ChildProps> = (props) => {
  const dragEnd = (event) => {
    props.onDragEnd();
  };

  const dragEnter = (event) => {
    props.onDragEnter();
  };

  const dragOver = (event) => {
    // Disables the snap-back animation when drag ends, which is a more responsive UI experience.
    event.preventDefault();
  };

  const dragStart = (event) => {
    props.onDragStart();
  }

  return (
    <div
      draggable
      onDragEnd={dragEnd}
      onDragEnter={dragEnter}
      onDragOver={dragOver}
      onDragStart={dragStart}
    >
      {props.children}
    </div>
  );
};

export default Draggable;
