import React, { useState } from 'react';

import { PrimaryButton } from '@magoosh/lib/button';
import { Select } from '@magoosh/lib/forms';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import { PromptRequest } from './types';

interface Props {
  onSuccess: (promptRequest: PromptRequest) => void;
  promptRequest: PromptRequest;
}

const PersistPromptsAction: React.FC<Props> = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accessLevel, setAccessLevel] = useState(100);

  const persist = () => {
    setIsSubmitting(true);

    fetch(adminPaths.api.promptRequestPersist(props.promptRequest.id), {
      method: 'POST',
      body: JSON.stringify({
        accessLevel
      })
    }).then((response) => {
      setIsSubmitting(false);
      props.onSuccess(response.promptRequest);
    }).catch((error) => {
      alert(error.errors);
      setIsSubmitting(false);
    });
  };

  return (
    <div className="align-items-center d-flex gap-s">
      <Select
        groupClassName="u-margin-A-n"
        onChange={(e) => setAccessLevel(e.target.value)}
        value={accessLevel}
      >
        <option value={25}>Trial</option>
        <option value={30}>Super Trial</option>
        <option value={60}>Premium</option>
        <option value={100}>Draft</option>
      </Select>

      <PrimaryButton onClick={persist} submitting={isSubmitting}>Create Prompts</PrimaryButton>
    </div>
  );
};

export default PersistPromptsAction;
