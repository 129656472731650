import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { without } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { DefaultFilledButton, PrimaryButton, ResponsiveButtonGroup } from '@magoosh/lib/button';
import Draggable from '@magoosh/lib/draggable';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { Form } from '@magoosh/lib/forms';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

import * as api from '@admin/app/api';

import { ContentAudit } from '../../types';

interface Props {
  draftPromptId: number;
  fieldName: 'answerChoiceGroups' | 'explanation' | 'prompt';
  onClose: () => void;
  onSuccess: () => void;
}

const EditDraftPromptModal: React.FC<Props> = (props) => {
  const [draftPrompt, setDraftPrompt] = useState(null);

  useEffect(() => {
    api.fetchTestPrepAIDraftPrompt(props.draftPromptId).then(
      (result) => (
        setDraftPrompt(result)
      )
    );
  }, []);

  const handleOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const draftPromptValues = {
      id: draftPrompt.id,
      ...values
    };

    api.updateTestPrepAIDraftPrompt(draftPromptValues).then((updatedDraftPrompt) => {
      props.onSuccess();
      props.onClose();
    });
  };

  if (draftPrompt) {
    if (props.fieldName === 'answerChoiceGroups') {
      return (
        <AnswerChoiceGroupsForm
          initialValue={draftPrompt.answerChoiceGroups}
          onClose={props.onClose}
          onSubmit={handleOnSubmit}
        />
      );
    } else if (props.fieldName === 'explanation') {
      return (
        <ExplanationForm
          initialValue={draftPrompt.explanationText}
          onClose={props.onClose}
          onSubmit={handleOnSubmit}
        />
      );
    } else if (props.fieldName === 'prompt') {
      return (
        <PromptForm
          initialValue={draftPrompt.text}
          onClose={props.onClose}
          onSubmit={handleOnSubmit}
        />
      );
    }
  } else {
    return (
      <div className="d-flex justify-content-center u-margin-T-l">
        <LoadingIndicator />
      </div>
    );
  }
};

interface FormProps {
  onSubmit: (values: any, any) => void;
  initialValue: any;
  onClose: () => void;
}

const AnswerChoiceGroupsForm: React.FC<FormProps> = (props) => {
  const initialValues = {
    answerChoiceGroups: props.initialValue as string
  };

  const handleAnswerChoiceAdded = (groupIndex: number, setFieldValue, values) => {
    const updatedAnswerChoiceGroup = [
      ...values.answerChoiceGroups[groupIndex],
      { correct: false, value: ''}
    ];

    const result = [...values.answerChoiceGroups];
    result[groupIndex] = updatedAnswerChoiceGroup;

    setFieldValue('answerChoiceGroups', result);
  };

  const handleAnswerChoiceOrderChanged = (groupIndex: number, fromIndex: number, toIndex: number, setFieldValue, values) => {
    const answerChoiceGroup = values.answerChoiceGroups[groupIndex];
    const answerChoice = answerChoiceGroup[fromIndex];
    const updatedAnswerChoices = without(answerChoiceGroup, answerChoice);
    const updatedAnswerChoiceGroup = [
      ...updatedAnswerChoices.slice(0, toIndex),
      answerChoice,
      ...updatedAnswerChoices.slice(toIndex)
    ];

    const result = [...values.answerChoiceGroups];
    result[groupIndex] = updatedAnswerChoiceGroup;

    setFieldValue('answerChoiceGroups', result);
  };

  const handleAnswerChoiceRemoved = (groupIndex: number, index: number, setFieldValue, values) => {
    const answerChoiceGroup = values.answerChoiceGroups[groupIndex];
    const answerChoice = answerChoiceGroup[index];
    const updatedAnswerChoices = without(answerChoiceGroup, answerChoice);

    const result = [...values.answerChoiceGroups];
    result[groupIndex] = updatedAnswerChoices;

    setFieldValue('answerChoiceGroups', result);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit}>
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form>
            <div className="d-flex flex-column gap-xs small">
              <TooltipLaTeXFormatting />
            </div>

            <div className="form-group u-margin-T-s">
              <strong>Answer Choice Groups</strong>

              <div className="d-flex flex-1 gap-m">
                {
                  values.answerChoiceGroups.map((answerChoiceGroup, groupIdx) => (
                    <div key={groupIdx} className="d-flex flex-1 flex-column gap-xs">
                      <Draggable
                        onDrop={(fromIndex, toIndex) => {
                          handleAnswerChoiceOrderChanged(groupIdx, fromIndex, toIndex, setFieldValue, values)
                        }}
                      >
                        {
                          answerChoiceGroup.map((answerChoice, idx) => (
                            <div key={idx}>
                              <div className="align-items-center d-flex gap-xs">
                                <FontAwesomeIcon
                                  className="text-gray-light"
                                  icon="bars"
                                  style={{ cursor: 'grab' }} />

                                <div className="align-items-center d-flex flex-1 gap-xs">
                                  <div style={{ minWidth: 10 }}>
                                    <FieldInput
                                      hideLabel
                                      name={`answerChoiceGroups[${groupIdx}][${idx}].correct`}
                                      type="checkbox"
                                    />
                                  </div>
                                  <div className="flex-1">
                                    <FieldInput
                                      hideLabel
                                      name={`answerChoiceGroups[${groupIdx}][${idx}].value`}
                                      type="text"
                                    />
                                  </div>
                                  <a onClick={() => handleAnswerChoiceRemoved(groupIdx, idx, setFieldValue, values)}>
                                    <FontAwesomeIcon className="text-danger" icon="minus-circle" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))
                        }

                        <a onClick={() => handleAnswerChoiceAdded(groupIdx, setFieldValue, values)}>
                          <FontAwesomeIcon className="text-success" icon="plus-circle" /> Add
                        </a>
                      </Draggable>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="u-margin-T-s">
              <ResponsiveButtonGroup>
                <PrimaryButton submitting={isSubmitting} type="submit">
                  Save
                </PrimaryButton>

                <DefaultFilledButton onClick={props.onClose}>
                  Cancel
                </DefaultFilledButton>
              </ResponsiveButtonGroup>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const ExplanationForm: React.FC<FormProps> = (props) => {
  const initialValues = {
    explanationText: props.initialValue as string
  };

  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit}>
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <div className="d-flex flex-column gap-xs small">
              <TooltipLaTeXFormatting />
            </div>

            <FieldInput
              label="Explanation"
              name="explanationText"
              style={{ height: 400 }}
              type="textarea"
            />

            <div className="u-margin-T-s">
              <ResponsiveButtonGroup>
                <PrimaryButton submitting={isSubmitting} type="submit">
                  Save
                </PrimaryButton>

                <DefaultFilledButton onClick={props.onClose}>
                  Cancel
                </DefaultFilledButton>
              </ResponsiveButtonGroup>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const PromptForm: React.FC<FormProps> = (props) => {
  const initialValues = {
    text: props.initialValue as string
  };

  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit}>
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <div className="d-flex flex-column gap-xs small">
              <TooltipLaTeXFormatting />
              <TooltipQCFormatting />
            </div>

            <FieldInput label="Prompt" name="text" type="textarea" style={{ height: 200 }} />

            <div className="u-margin-T-s">
              <ResponsiveButtonGroup>
                <PrimaryButton submitting={isSubmitting} type="submit">
                  Save
                </PrimaryButton>

                <DefaultFilledButton onClick={props.onClose}>
                  Cancel
                </DefaultFilledButton>
              </ResponsiveButtonGroup>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const TooltipLaTeXFormatting = () => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="latex-formatting-tooltip">
          <div className="text-left">
            LaTeX syntax should begin with \( and end with \).
            <br /><br />
            For example: \(\dfrac{'{'}1{'}'}{'{'}4{'}'}\)
          </div>
        </Tooltip>
      }>
      <span>LaTeX formatting <FontAwesomeIcon icon="question-circle" /></span>
    </OverlayTrigger>
  );
};

const TooltipQCFormatting = () => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="qc-formatting-tooltip">
          <div className="text-left">
            Start with question setup if needed, then Quantity A: value, and Quantity B: value.
            <br /><br />
            For example:
            <br /><br />
            This year, Bonnie’s annual salary was increased by 15% to $68,425
            <br /><br />
            Quantity A: Bonnie's annual salary last year
            <br /><br />
            Quantity B: $60,000
          </div>
        </Tooltip>
      }>
      <span>Quantitative comparison formatting <FontAwesomeIcon icon="question-circle" /></span>
    </OverlayTrigger>
  );
};

export default EditDraftPromptModal;
