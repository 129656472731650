// Format prices
export const formatPrice = (cents, currency = 'usd', truncateEmptyCents = false) => {
  const dollars = cents / 100;

  if (truncateEmptyCents && cents % 100 === 0) {
    return dollars.toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  } else {
    return dollars.toLocaleString('en-US', { style: 'currency', currency });
  }
};
