import * as React from 'react';
import WritingExampleDocument from '@magoosh/pages/writing_examples/show/document';
import { WritingExample } from '@magoosh/admin/writing_examples/types';
import { Evaluation } from '@magoosh/pages/essay_review/types';

interface Props {
  writingExample: WritingExample;
}

const WritingExampleIndex: React.FC<Props> = (props) => {
  return (
    <div>
      <WritingExampleDocument
        writingExample={props.writingExample}
        evaluation={props.writingExample.evaluation}
      />
    </div>
  );
};

export default WritingExampleIndex;
