import { AssignmentActionTypes } from './actions';
import { without } from 'lodash';
import { AssignmentsStore } from './types';
import { Reducer } from 'redux';

export const assignmentsReducer: Reducer<AssignmentsStore> = (
  state = { assignments: [], teacherMode: false },
  action
) => {
  switch (action.type) {
    case AssignmentActionTypes.SET_TEACHER_MODE:
      return { ...state, teacherMode: action.teacherMode };
    case AssignmentActionTypes.SET_ASSIGNMENTS:
      return { ...state, assignments: action.assignments };
    case AssignmentActionTypes.SET_DETAILS_ASSIGNMENT:
      return { ...state, detailsAssignment: action.assignment };
    case AssignmentActionTypes.ASSIGNMENT_CREATED:
      return { ...state, assignments: state.assignments.concat(action.assignment) };
    case AssignmentActionTypes.ASSIGNMENT_DELETED:
      const assignments = without(state.assignments, action.assignment);
      return { ...state, assignments };
    case AssignmentActionTypes.HIDE_ASSIGNMENT_DIALOG:
      return { ...state, assignmentDialogOpen: false };
    case AssignmentActionTypes.SHOW_ASSIGNMENT_DIALOG:
      return { ...state, assignmentDialogOpen: true };
    case AssignmentActionTypes.HIDE_DELETE_ASSIGNMENT_DIALOG:
      return { ...state, deleteAssignmentDialogOpen: false };
    case AssignmentActionTypes.SHOW_DELETE_ASSIGNMENT_DIALOG:
      return { ...state, deleteAssignmentDialogOpen: true };
    default:
      return state;
  }
};
