import * as React from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import style from './style.module.scss';
import capitalize from 'react-bootstrap/lib/utils/capitalize';
import cx from 'classnames';
import { get } from 'lodash';

interface FieldInputProps {
  autoComplete?: string;
  name: string;
  type: string;
  id?: string;
  label?: string | React.ReactNode;
  optional?: boolean;
  hint?: string;
  hintHtml?: string;
  errorName?: string;
  className?: string;
  onKeyUp?: (e: any) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  hideLabel?: boolean;
  readOnly?: boolean;
  style?: any;
}

export function FieldInput(props: FieldInputProps) {
  const { errors } = useFormikContext();
  const hasError = !!get(errors, props.name);

  return (
    <div className={cx('form-group u-margin-T-s', { 'has-error': hasError })}>
      {!props.hideLabel && <label className="control-label" htmlFor={id(props)}>
        {
          (!props.label || typeof props.label === 'string') ? (
            capitalize((props.label as string) || props.name)
          ) : (
            props.label
          )
        }
        {' '}
        {props.optional && (
          <span className={style.optional}>
            <small>(Optional)</small>
          </span>
        )}
      </label>}

      <div>
        <Field
          autoComplete={props.autoComplete}
          name={props.name}
          id={id(props)}
          type={props.type}
          className={cx('form-control', props.className)}
          component={props.type === 'textarea' ? 'textarea' : 'input'}
          onKeyUp={props.onKeyUp}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          style={props.style}
          readOnly={props.readOnly}
        />
      </div>

      <div>
        <span className="text-danger">
          <small><ErrorMessage name={props.name} /></small>
        </span>

        {errors[props.name] && (props.hint || props.hintHtml) && <span><small> • </small></span>}

        {props.hint && <span><small>{props.hint}</small></span>}
        {props.hintHtml && <span><small dangerouslySetInnerHTML={{ __html: props.hintHtml }}></small></span>}
      </div>
    </div>
  );
}

function id(props: FieldInputProps) {
  return props.id || `${props.name}-input`;
}
