import * as React from 'react';
import { renderLatexInRawHtml } from 'utilities/latex';
import style from './style.module.scss';

interface RadioWithLabel {
  id: string;
  name: string;
  onSelect: () => void;
  isChecked: boolean;
  isDisabled: boolean;
  showGrade: boolean;
  isCorrect: boolean;
  text: string;
}

export default function RadioWithLabel(props: RadioWithLabel) {
  const { id, showGrade, isChecked, isCorrect, onSelect, isDisabled, text, name } = props;

  return (
    <div>
      <label htmlFor={id} className={style.label}>
        <span style={{ visibility: showGrade ? 'visible' : 'hidden', marginRight: '.5rem' }}>
          {isCorrect ? <i className="fa correct-sprite" /> : <i className="fa incorrect-sprite" />}
        </span>
        <input
          type="radio"
          name={name}
          id={id}
          onChange={onSelect}
          checked={isChecked}
          disabled={isDisabled}
          className={style.input}
        />
        <div
          style={{ display: 'inline' }}
          dangerouslySetInnerHTML={{ __html: renderLatexInRawHtml(text) }}
        />
      </label>
    </div>
  );
}
