import React, { useEffect, useState } from 'react';
import { AccordionProps } from './types';
import style from './style.module.scss';
import cx from 'classnames';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { Panel } from 'react-bootstrap';

export const Accordion: React.FC<AccordionProps> = (props) => {
  const { title, accordionClassName, panelClassName, children, isOpen, onOpen } = props;

  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    if (open && onOpen) onOpen();
  }, [open]);

  return (
    <div className={cx(style.accordion, accordionClassName, 'u-margin-T-s', 'u-margin-B-l')}>
      <hr />
      <h4 className={style.title} onClick={() => setOpen(!open)}>
        {title} <FontAwesomeIcon icon={open ? 'chevron-up' : 'chevron-down'} />
      </h4>
      <Panel
        id="overlay"
        expanded={open}
        onToggle={() => setOpen(!open)}
        className={cx(style.panel, panelClassName)}>
        <Panel.Collapse>
          <Panel.Body className="u-padding-A-n">{children}</Panel.Body>
        </Panel.Collapse>
      </Panel>
    </div>
  );
};

export default Accordion;
