import * as React from 'react';
import Modal from '@magoosh/lib/modal';
import { Alert } from 'react-bootstrap';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import { values } from 'lodash';

import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import { Assignment } from '../types';

interface DeleteAssignmentDialogProps {
  show: boolean;
  assignment?: Assignment;
  onHide: () => any;
  onSuccess: (assignment: Assignment) => any;
}

interface DeleteAssignmentDialogState {
  showError: boolean;
  errorText: string;
}

export default class DeleteAssignmentDialog extends React.Component<
DeleteAssignmentDialogProps,
DeleteAssignmentDialogState
> {
  state = {
    showError: false,
    errorText: ''
  };

  render() {
    const assignment = this.props.assignment;

    return (
      <Modal
        title={`Delete Assignment: ${assignment ? assignment.title : ''}`}
        show={this.props.show}
        onHide={this.props.onHide}>
        {this.renderErrorAlert()}
        <div>
          Deleting this assignment will:
          <ul>
            <li>remove it from each student's assignment list</li>
            <li>erase class progress for this assignment</li>
          </ul>
          <p>
            <strong>Are you sure you want to delete this assignment?</strong>
          </p>
        </div>
        <PrimaryButton onClick={() => this.deleteAssignment(assignment)}>Yes, delete</PrimaryButton>
        <DefaultButton className="pull-right" onClick={this.props.onHide}>
          Cancel
        </DefaultButton>
      </Modal>
    );
  }

  deleteAssignment = (assignment: Assignment) => {
    fetch(paths.api.assignment(assignment.id), { method: 'DELETE' })
      .then(() => this.props.onSuccess(assignment))
      .catch((error) => {
        this.setState({
          showError: true,
          errorText: `${values(error.errors).join('. ')}. Please try again.`
        });
      });
  };

  renderErrorAlert = () => {
    if (this.state.showError) {
      return <Alert>{this.state.errorText}</Alert>;
    }
  };
}
