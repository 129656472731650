import React from 'react';

import DiffableContent from '../diffable_content';
import { Version } from '../../types';

interface Props {
  answerChoiceGroups: {
    choices: {
      current: {
        isCorrect: boolean;
        text: string;
      };
      previous: {
        isCorrect: boolean;
        text: string;
      };
    }[];
  }[];
  previousVersion: Version;
  showDiff: boolean;
  type: 'rendered' | 'text';
}

const DiffableAnswerChoices: React.FC<Props> = (props) => {
  return (
    <div className="d-flex flex-column gap-m">
      {
        (props.answerChoiceGroups || []).map((group, groupIdx) => (
          <React.Fragment key={groupIdx}>
            <div className="d-flex flex-column gap-s">
              {
                group.choices.map((choice, choiceIdx) => {
                  let currentChoice = choice.current.text;
                  if (choice.current.isCorrect) {
                    currentChoice = '(Correct) ' + currentChoice
                  }

                  let previousChoice = choice.previous?.text;
                  if (choice.previous?.isCorrect) {
                    previousChoice = '(Correct) ' + previousChoice
                  }

                  return (
                    <DiffableContent
                      key={choiceIdx}
                      currentVersion={{
                        content: currentChoice,
                        name: 'This Version'
                      }}
                      previousVersion={{
                        content: previousChoice,
                        name: props.previousVersion?.name
                      }}
                      showDiff={props.showDiff}
                      showVersionNames={choiceIdx === 0 && groupIdx === 0}
                      type={props.type}
                    />
                  );
                })
              }
            </div>

            {groupIdx < props.answerChoiceGroups.length - 1 && <hr className="u-margin-A-n" />}
          </React.Fragment>
        ))
      }
    </div>
  );
};

export default DiffableAnswerChoices;
