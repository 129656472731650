import React from 'react';

import StatisticData from '@test_prep/app/pages/analytics/components/statistic_data';

import SubjectHeader from './subject_header';
import PerformanceBySubject from './performance_by_subject';

interface Props {
  filters: any;
  hideSubjectsWithNoActivity: boolean;
}

const SectionSubjects: React.FC<Props> = (props) => {
  return (
    <div className="d-flex flex-column gap-s">
      <StatisticData
        filters={props.filters}
        name="performance_by_subject"
      >
        <StatisticData.Loading>
          {/* @ts-ignore */}
          <SubjectHeader section={props.filters.sections} />
          <div style={{ minHeight: 150 }} />
        </StatisticData.Loading>

        {/* @ts-ignore */}
        <SubjectHeader section={props.filters.sections} />
        {/* @ts-ignore */}
        <PerformanceBySubject
          filters={props.filters}
          hideSubjectsWithNoActivity={props.hideSubjectsWithNoActivity}
          section={props.filters.sections}
        />
      </StatisticData>
    </div>
  );
};

export default SectionSubjects;
