import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import useAuthToken from '@magoosh/utils/use_authenticity_token';
import { paths } from 'config/path_helpers';
import { minutesFormattedasHoursAndMinutesString } from 'utilities/time';

import { CheckpointCardProps } from '@magoosh/gre/organisms/checkpoint_card/types';
import { Checkpoint } from './types';

import CheckpointCard from '@magoosh/gre/organisms/checkpoint_card';
import PremiumBadge from '@magoosh/lib/info_badges';
import { secondsFormattedAsMinutes } from 'utilities/time';

import AssessmentIncompleteIllustration1 from './assets/assessment_incomplete_1.svg';
import AssessmentIncompleteIllustration2 from './assets/assessment_incomplete_2.svg';
import AssessmentIncompleteIllustration3 from './assets/assessment_incomplete_3.svg';
import AssessmentIncompleteIllustration4 from './assets/assessment_incomplete_4.svg';
import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  allowDefer?: boolean;
  checkpoint: Checkpoint;
  deferAssessment?: () => void;
}

const PrioritizedAssessmentCheckpoint: React.FC<Props> = (props) => {
  const {
    allowDefer,
    checkpoint,
    deferAssessment
  } = props;

  const authToken = useAuthToken();
  const totalTimeInSeconds = props.checkpoint.sections.reduce((sum, section) => sum + section.timeLimitSeconds, 0);

  const checkpointProps: CheckpointCardProps = {
    children: <Children assessmentNumber={checkpoint.number} sections={checkpoint.sections} />,
    estimatedTime: `${secondsFormattedAsMinutes(totalTimeInSeconds)} Minutes`,
    footer: <Footer minutesLeftToTake={checkpoint.minutesLeftToTake} />,
    headline: <Headline assessmentNumber={checkpoint.number} displayName={checkpoint.displayName} inProgress={checkpoint.running} />,
    iconType: 'SingleStar',
    illustration: <Illustration assessmentNumber={checkpoint.number} />,
    isWrapped: true,
    primaryCTA: null,
    running: checkpoint.running,
    scored: checkpoint.number === 1 ? '' : checkpoint.notScored ? '(Not Scored)' : '(Scored)'
  };

  if (checkpoint.locked) {
    checkpointProps.primaryCTA = {
      text: 'Upgrade',
      variant: 'secondary',
      href: paths.plans()
    };

    checkpointProps.secondaryCTA = <PremiumBadge />;

  } else if (checkpoint.running) {
    checkpointProps.primaryCTA = {
      text: 'Continue',
      variant: 'secondary',
      href: paths.practiceTest(checkpoint.mockTestId)
    };
  } else {
    checkpointProps.primaryCTA = {
      text: 'Start',
      variant: 'secondary',
      type: 'submit'
    };
  }

  if (allowDefer) {
    checkpointProps.secondaryCTA = (
      <a className="u-margin-H-xs" style={{ color: colors.grayDarker }} onClick={deferAssessment}>
        Not Right Now
      </a>
    );
  }

  return (
    <form action={paths.practiceTests()} method="POST">
      <input
        type="hidden"
        name="mock_test[template_id]"
        value={checkpoint.mockTestTemplateId}
      />
      <input type="hidden" name="authenticity_token" value={authToken} />
      <CheckpointCard {...checkpointProps} />
    </form>
  );
};

const Children = ({ assessmentNumber, sections }) => {
  const bodyTextOptions = [
    'You’ll get a baseline score estimate and a detailed report of your strengths and weaknesses to help guide your studies.',
    'You’ve studied at least 5 hours! Now, practice your pacing. It’s too early to get a new estimated score, but you’ll get updated study recommendations.',
    'You’ve studied at least 10 hours! Now is an ideal time to practice your pacing and get new recommendations. You’ll also see your score.',
    'You’ve studied at least 15 hours! Now, practice your pacing. It’s too early to get a new score, but you’ll get fresh study recommendations.'
  ];

  return (
    <>
      <p>{bodyTextOptions[assessmentNumber - 1]}</p>
      <div>
        <ul className="u-padding-L-n" style={{listStyle: 'inside'}}>
          {
            sections.map((section, idx) => (
              <li key={idx}>
                {' '}
                {section.name}
                {' '}
                <span style={{ color: colors.gray }}>
                  {section.questionLimit} questions, {secondsFormattedAsMinutes(section.timeLimitSeconds)} minutes
                </span>
              </li>
            ))
          }
        </ul>
      </div>
    </>
  );
};

const Footer = ({ minutesLeftToTake }) => {
  return (
    <p>
      {/* They're in the grace period and have otherwise phased out of the assessment time */}
      {minutesLeftToTake < 0 ? (
        <span>Complete this within one day of when you started it. </span>
      ) : (
        <>
          <span>
            Only available during your next{' '}
            <strong>
              {minutesLeftToTake == 0
                ? 'few minutes'
                : minutesFormattedasHoursAndMinutesString(minutesLeftToTake)}
            </strong>{' '}
            of studying.{' '}
          </span>
          <OverlayTrigger
            trigger={['click']}
            placement="top"
            rootClose
            overlay={
              <Popover id="assessment-card-popover">
                This only counts timed study activities such as watching lessons and answer explanation videos
                or answering questions.
              </Popover>
            }>
            {<span className="text-underline">Learn More</span>}
          </OverlayTrigger>
        </>
      )}
    </p>
  );
};

const Headline = ({ assessmentNumber, displayName, inProgress }) => {
  if (inProgress) {
    return (
      <>
        <span style={{ color: colors.brandPrimary }}>Almost There!</span> Complete Your{' '}
        {displayName}
      </>
    );
  } else {
    const headlineOptions = [
      <><span style={{ color: colors.brandPrimary }}>Welcome!</span> Start by Taking a {displayName}</>,
      <><span style={{ color: colors.brandPrimary }}>Way to Go!</span> It&apos;s Time for {displayName}</>,
      <><span style={{ color: colors.brandPrimary }}>Excellent!</span> It&apos;s Time for {displayName}</>,
      <><span style={{ color: colors.brandPrimary }}>Nice Work!</span> It&apos;s Time for {displayName}</>
    ];

    return headlineOptions[assessmentNumber - 1];
  }
};

const Illustration = ({ assessmentNumber }) => {
  const illustrationOptions = [
    <img src={AssessmentIncompleteIllustration1} />,
    <img src={AssessmentIncompleteIllustration2} />,
    <img src={AssessmentIncompleteIllustration3} />,
    <img src={AssessmentIncompleteIllustration4} />
  ];

  return illustrationOptions[assessmentNumber - 1];
};

export default PrioritizedAssessmentCheckpoint;
