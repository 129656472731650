import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { bugsnagNotify } from 'utilities/bugsnag';

import { Interaction } from '@test_prep/app/ai_tutor/types';

import Activity from '../activity';
import ErrorMessage from '../error_message';
import GradientPill from '../gradient_pill';

interface Props {
  activity: Interaction;
  addOrUpdateUserInteractions: (interactions: Interaction[]) => void;
  onHide: () => void;
  onHideTutor: () => void;
}

const ActivityPanel: React.FC<Props> = (props) => {
  const logError = (error: Error, info?: { componentStack: string }) => {
    bugsnagNotify(error);
  };

  return (
    <>
      <div className="visible-xs">
        <div className="align-items-center d-flex gap-s">
          <GradientPill size="sm">
            <FontAwesomeIcon icon="asterisk" /> AI Tutor
          </GradientPill>

          <div className="d-flex flex-1 gap-m justify-content-end">
            <a
              className="small text-bold text-gray-light text-no-decoration"
              onClick={props.onHide}
            >
              Show Tutor Chat
            </a>

            <a
              className="small text-bold text-gray-light text-no-decoration"
              onClick={props.onHideTutor}
            >
              Hide
            </a>
          </div>
        </div>
      </div>

      <div style={{ height: '100%', overflowY: 'scroll', overscrollBehavior: 'contain' }}>
        <div className="d-flex flex-column gap-m">
          {/* Min height makes the controls match those in tutoring panel */}
          <div className="align-items-center d-flex gap-m hidden-xs" style={{ minHeight: 29 }}>
            <div
              className="flex-1"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <Activity.Title activity={props.activity} />
            </div>

            <div className="align-items-center d-flex flex-none gap-m justify-content-end">
              <a
                className="small text-bold text-gray-light text-no-decoration"
                onClick={props.onHide}
              >
                Hide Activity
              </a>
            </div>
          </div>

          <ErrorBoundary
            fallback={<ErrorMessage message="Oops! Something went wrong with this activity." />}
            onError={logError}
          >
            <Activity
              activity={props.activity}
              addOrUpdateUserInteractions={props.addOrUpdateUserInteractions}
            />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default ActivityPanel;
