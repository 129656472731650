import { createContext } from 'react';

export const AppContext = createContext({
  admin: false,
  externalSourceUser: false,
  supervisor: false,
  currentUserEmail: null,
  showModulesReport: false,
  setLastTab: (tabName) => {},
  trialAccess: false
});
