import React from 'react';
import { find, sumBy } from 'lodash';

import { DonutChart } from '@magoosh/lib/charts';
import {
  minutesFormattedasHoursAndMinutesString,
  minutesFormattedAsShortString
} from 'utilities/time';

import StatisticData from '../statistic_data';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  filters: any;
}

const StudyTimeChart: React.FC<Props> = (props) => {
  // TODO - Make laceholder labels below exam agnostic
  const loadingData = () => {
    let result = [];

    // TODO - take defaults from page.sections or page.subject_tags
    if (!props.filters.sections || props.filters.sections === 'all') {
      result = [
        { color: colors.gray85, label: 'Math', value: 60 },
        { color: colors.grayLighter, label: 'Verbal', value: 30 }
      ];
    } else {
      if (props.filters.sections.indexOf('math') !== -1){
        result.push({ color: colors.gray85, label: 'Math', value: 30 });
      }
      if (props.filters.sections.indexOf('verbal') !== -1) {
        result.push({ color: colors.grayLighter, label: 'Verbal', value: 30 });
      }
    }

    return result;
  };

  const loadingPlaceholder = (
    <DonutChart
      chartProps={{ animate: false }}
      data={loadingData()}
      legendPosition="bottom"
      size={150}
    />
  );

  const formatLegendGroupName = (label) => {
    const potentialGroupNames = [];

    const groupName = find(potentialGroupNames, (groupName) => (
      label.startsWith(groupName)
    ));

    return groupName || label;
  };

  const formatStatisticData = (statisticData) => {
    const predeterminedColors = {
      'Math Lessons': colors.dataLightPurple,
      'Math Questions': colors.dataDarkPurple,
      'Verbal Lessons': colors.dataLightBlue,
      'Verbal Questions': colors.dataDarkBlue
    };

    return statisticData.map((datum) => {
      const formattedData = {
        label: datum.name,
        value: datum.value
      } as any;

      if (predeterminedColors[datum.name]) {
        formattedData.color = predeterminedColors[datum.name];
      }

      return formattedData;
    });
  };

  const formatValue = (value) => {
    return minutesFormattedasHoursAndMinutesString(value);
  };

  if (!props.filters) {
    return loadingPlaceholder;
  } else {
    return (
      <StatisticData
        filters={props.filters}
        formatDataFn={formatStatisticData}
        name="study_time"
      >
        <StatisticData.Loading>
          {loadingPlaceholder}
        </StatisticData.Loading>

        {/* @ts-ignore */}
        <DonutChart
          chartProps={{ animate: false }}
          interior={(data) => (
            <strong>
              <span style={{ color: colors.dataDarkPurple, fontSize: 150 * 0.25 }}>
                {minutesFormattedAsShortString(sumBy(data, 'value'))}
              </span>
            </strong>
          )}
          formatValueFn={formatValue}
          legendGroupNameFn={formatLegendGroupName}
          legendPosition="bottom"
          size={150}
        />
      </StatisticData>
    );
  }
};

export default StudyTimeChart;
