import * as React from 'react';
import style from './style.module.scss';

export interface AuthorProps {
  id?: number | string;
  imagePath: string;
  name?: string;
  description: string;
}

const AuthorIcon = ({ imagePath, name, description }: AuthorProps) => (
  <div className={style.authorDetails}>
    <img alt={name || description} className={style.authorPhoto} src={imagePath} />
    <p>
      {name}
      {name && ', '}
      {description}
    </p>
  </div>
);

export default AuthorIcon;
