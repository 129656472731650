import moment from "moment-timezone";

export const durationFormatter = durationSeconds => {
  const duration = moment.duration(durationSeconds, 's');
  const segments = [];
  const hours = Math.trunc(duration.asHours());
  if (hours > 0) {
    segments.push(`${hours}h`);
  }
  segments.push(`${duration.minutes()}m`);
  return segments.join(' ');
};

export const dateFormatter = dateString => {
  const momentDate = moment(dateString);
  if (momentDate.isValid()) { return momentDate.format('l'); }
  return '';
}

