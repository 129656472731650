import * as React from 'react';
import { filter } from 'lodash';

import { DefaultButton } from '@magoosh/lib/button';
import { StudyScheduleData, StudyScheduleDetailData, UserProfile } from '../types';

interface Props {
  currentPlan?: StudyScheduleDetailData;
  onCancel: (any) => void;
  onOptOut: (any) => void;
  onComplete: (id: number) => void;
  onReloadPlan: () => void;
  onUpdateUserProfile: (any) => Promise<any>;
  plans: StudyScheduleData[];
  userProfile: UserProfile
}

export default ({ currentPlan, onCancel, onOptOut, onComplete, onReloadPlan, onUpdateUserProfile, plans, userProfile }: Props) => {
  const alternativePlans = filter(plans, (plan) => plan.id !== currentPlan?.id);

  const handleUserProfileChanged = (userProfile) => {
    onUpdateUserProfile(userProfile).then(() => (
      onReloadPlan()
    ));
  };

  return (
    <div>
      <h4 className="u-margin-V-n">
        <strong>Change Your Study Plan</strong>
      </h4>

      {currentPlan && (
        <div className="u-margin-T-m">You are currently using our {currentPlan.name} Plan.</div>
      )}

      {
        (userProfile.examName === 'GRE' || userProfile.examName === 'GMAT') && (
          <div className="form-check u-margin-T-xs">
            <input
              className="form-check-input"
              defaultChecked={userProfile.includeAdmissionsItemsInStudySchedule}
              type="checkbox"
              id="includeAdmissionsItemsInStudySchedule"
              onChange={(e) => handleUserProfileChanged({ includeAdmissionsItemsInStudySchedule: e.target.checked})}
            />
            <label className="u-margin-L-xs" htmlFor="includeAdmissionsItemsInStudySchedule" style={{ fontWeight: 400 }}>
              Include Admissions Content
            </label>
          </div>
        )
      }

      <hr />

      <div className="u-margin-T-s">
        <p>
          {currentPlan ?
            'Not quite right? Choose from one of our other study plans below.' :
            'Choose from one of our study plans below.'}
        </p>
        {alternativePlans.map((plan) => (
          <DefaultButton block className="u-margin-V-s" data={plan.id} key={plan.id} onClick={onComplete}>
            {plan.name}
          </DefaultButton>
        ))}
        {currentPlan && (
          <DefaultButton block className="u-margin-V-s" onClick={onOptOut}>
            I'll study on my own instead
          </DefaultButton>
        )}
      </div>

      <div className=" text-center u-margin-T-m">
        <a className="text-gray-light" onClick={onCancel}>
          Go Back
        </a>
      </div>
    </div>
  );
};
