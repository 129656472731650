
export function recommendedHoursPerWeek(numberOfDaysUntilTest) {
  // 30 hours total
  // Round to nearest .5
  const numberOfWeeksUntilTest = Math.round(numberOfDaysUntilTest / 7);

  if (numberOfWeeksUntilTest < 10) {
    return 3;
  }
  else {
    return Math.round((30 / numberOfWeeksUntilTest) * 2) / 2;
  }
}
