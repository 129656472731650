import React from 'react';

import { DangerAlert } from '@magoosh/lib/alerts';

interface Props {
  message?: string;
  onRetry?: () => void;
}

const ErrorMessage: React.FC<Props> = (props) => {
  const message = props.message || 'Oops! Something went wrong with this tutoring session.';
  return (
    <DangerAlert>
      <small>
        {message} {props.onRetry && <><a className="text-bold text-danger" onClick={props.onRetry}>Try again</a>.</>}
      </small>
    </DangerAlert>
  );
};

export default ErrorMessage;
