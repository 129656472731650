import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { debounce } from 'lodash';

import { InfoButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

import * as api from '@test_prep/app/api';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  rating: {
    rateableId: number;
    rateableName: string;
    rateableType: string;
    rating?: number;
    text?: string;
  };
  tutoringSessionId: number;
}

const RatingCollection: React.FC<Props> = (props) => {
  const textAreaRef = useRef(null);
  const [rating, setRating] = useState<number>(props.rating?.rating);
  const [ratingText, setRatingText] = useState<string>(props.rating?.text);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(!!props.rating?.rating);

  const debouncedSaveRating = useRef(
    debounce((values) => {
      saveRating(values);
    }, 2000, { trailing: true })
  );

  useEffect(() => {
    if (!rating) return;

    textAreaRef.current?.focus();
    saveRating({ rating });
  }, [rating]);

  const handleRatingTextChanged = (e) => {
    setRatingText(e.target.value);
    debouncedSaveRating.current({ rating, text: e.target.value });
  };

  const handleSubmit = () => {
    setHasSubmitted(true);
    saveRating({ rating, text: ratingText });
  };

  const saveRating = (values) => {
    api.createOrUpdateRating({
      rateableId: props.rating.rateableId,
      rateableType: props.rating.rateableType,
      rating: values
    });
  };

  if (hasSubmitted) {
    return (
      <div
        className={cx(style.slideUp, 'align-items-center d-flex flex-1 gap-s small u-margin-T-xs')}
        style={{ color: colors.brandInfo }}
      >
        Thanks for your feedback!
      </div>
    );
  } else {
    return (
      <div className={cx(style.slideDown, 'd-flex flex-column gap-s u-margin-T-xs')}>
        <div
          className="align-items-center d-flex gap-s small"
          style={{ color: colors.brandInfo }}
        >
          <div className="text-bold">
            <FontAwesomeIcon icon="question-circle" /> Did you like this {props.rating.rateableName}?
          </div>

          {
            (!rating || rating === 1) && (
              <div>
                <a className="text-bold text-info text-no-decoration" onClick={() => setRating(1)}>
                  <FontAwesomeIcon icon="thumbs-o-up" /> Yes
                </a>
              </div>
            )
          }

          {
            (!rating || rating === -1) && (
              <div>
                <a className="text-bold text-info text-no-decoration" onClick={() => setRating(-1)}>
                  <FontAwesomeIcon icon="thumbs-o-down" /> No
                </a>
              </div>
            )
          }
        </div>

        {
          rating && (
            <div className="d-flex flex-column gap-xs">
              <small>
                <strong>
                  {rating === 1 ? 'What did you like?' : 'How could we do better?'}
                </strong>
              </small>

              <textarea
                onChange={handleRatingTextChanged}
                ref={textAreaRef}
                style={{
                  backgroundColor: '#E6F7FF',
                  border: `1px dashed ${colors.brandInfo}`,
                  borderRadius: 8,
                  color: colors.brandInfo,
                  resize: 'none'
                }}
              />

              <InfoButton
                className="u-margin-T-s"
                onClick={handleSubmit}
                size="sm"
              >
                Submit Feedback
              </InfoButton>
            </div>
          )
        }
      </div>
    );
  }
};

export default RatingCollection;
