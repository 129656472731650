import React from 'react';
import cx from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  examName: string;
  previousTestResult: {
    id: number;
    score: string;
    scoredExamSections: {
      name: string;
      score: {
        reason: string;
        value: string;
      };
    }[];
    wasSingleSection:boolean;
  };
  scoredExamSections: string[];
}

const ScoreBadge: React.FC<Props> = (props) => {
  const sectionsWithScores = () => {
    if (props.previousTestResult) {
      const sections = props.previousTestResult.scoredExamSections;

      return sections.concat({
        name: totalScoreMoniker(),
        score: {
          reason: null,
          value: props.previousTestResult.score
        }
      });
    } else {
      return props.scoredExamSections.concat(totalScoreMoniker()).map((sectionName) => (
        {
          name: sectionName,
          score: {
            reason: null,
            value: null
          }
        }
      ));
    }
  };

  const totalScoreMoniker = () => {
    if (props.previousTestResult?.wasSingleSection) {
      return 'Total Questions';
    } else if (props.examName === 'ACT') {
      return 'Composite';
    } else {
      return 'Total Score';
    }
  };

  return (
    <ul className="mock-test-score-group vertical">
      {
        sectionsWithScores().map((sectionWithScore, idx) => (
          <li key={idx}>
            <div className={cx('mock-test-score', { disabled: !sectionWithScore.score.value, primary: idx === sectionsWithScores().length -1 })}>
              {
                !sectionWithScore.score.value && sectionWithScore.score.reason ? (
                  <OverlayTrigger overlay={<Tooltip id={`no-score-reason-tooltip-${sectionWithScore.name}`}>{sectionWithScore.score.reason}</Tooltip>} placement="top">
                    <span className="text-underdash">NS</span>
                  </OverlayTrigger>
                ) : (
                  <span className={cx({ 'text-underdash': !sectionWithScore.score.value })}>{sectionWithScore.score.value || 'NS'}</span>
                )
              }
            </div>
            <span className="mock-test-score-text">{sectionWithScore.name}</span>
          </li>
        ))
      }
    </ul>
  );
};

export default ScoreBadge;
