import React, { useState } from 'react';

import { InfoAlert } from '@magoosh/lib/alerts';
import { Form, Input } from '@magoosh/lib/forms';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import { paths } from 'config/path_helpers';

import { Button as BrandButton } from './brand';

interface TemplateOption {
  estimatedLength: string;
  name: string;
  sectionId?: number;
  templateId: number;
}

interface Props {
  brand: string;
  examName: string;
  examVersion: string;
  planSlug: string;
  templateOptions: TemplateOption[];
  user: {
    email: string;
    firstName: string;
  };
}

const TemplateOptionAndForm: React.FC<Props> = (props) => {
  const [selectedTemplateOption, setSelectedTemplateOption] = useState(
    // On the GRE we are experimenting with having the score assessment be the default. We think
    // it may have a better conversion rate to paid.
    props.examName === 'GRE' ? props.templateOptions[1] : props.templateOptions[0]
  );

  return (
    <div>
      {
        props.user && (
          <InfoAlert className="u-margin-B-m">
            <strong>Hi {props.user.firstName || props.user.email || 'there'}!</strong> Choose the practice test you'd like to take and click below to start.
          </InfoAlert>
        )
      }

      <div><strong>Choose Your Test</strong></div>

      <fieldset className="radio u-margin-A-n">
        {
          selectedTemplateOption && props.templateOptions.map((templateOption, idx) => {
            return (
              <div className="radio" key={idx}>
                <label>
                  <input
                    checked={selectedTemplateOption === templateOption}
                    name="templateOption"
                    onChange={() => { setSelectedTemplateOption(templateOption) }}
                    type="radio"
                    value={idx}
                  />
                  <span>
                    {templateOption.name}
                  </span>
                  <div className="text-gray-light" style={{ fontSize: 12 }}>Takes about {templateOption.estimatedLength}</div>
                </label>
              </div>
            )
          })
        }
      </fieldset>

      {
        props.user
          ? <LoggedInForm {...props} selectedTemplateOption={selectedTemplateOption} />
          : <LoggedOutForm {...props} selectedTemplateOption={selectedTemplateOption} />
      }
    </div>
  );
};

interface FormProps extends Props {
  selectedTemplateOption: TemplateOption;
}

const LoggedInForm: React.FC<FormProps> = (props) => {
  const authenticityToken = useAuthenticityToken();

  return (
    <div className="d-flex">
      <BrandButton
        brand={props.brand}
        className="flex-1 flex-md-none"
        href={paths.practiceTestsQuickStart(
          props.selectedTemplateOption.templateId,
          props.selectedTemplateOption.sectionId
        )}
      >
        Start Your Practice Test
      </BrandButton>
    </div>
  );
};

const LoggedOutForm: React.FC<FormProps> = (props) => {
  const authenticityToken = useAuthenticityToken();

  return (
    <form action={paths.guestAccess()} className="" method="get">
      <input type="hidden" name="authenticity_token" value={authenticityToken} />
      <input type="hidden" name="exam_version" value={props.examVersion} />
      <input type="hidden" name="slug" value={props.planSlug} />
      <input
        type="hidden"
        name="redirect_to"
        value={paths.practiceTestsQuickStart(
          props.selectedTemplateOption.templateId,
          props.selectedTemplateOption.sectionId
        )}
      />

      <div className="u-margin-B-m u-margin-T-xs">
        {
          props.brand && (
            <div className="d-flex flex-column flex-md-row gap-md-s" style={{ maxWidth: 400 }}>
              <div>
                <Input name="first_name" label="First Name" required />
              </div>

              <div>
                <Input name="last_name" label="Last Name" required />
              </div>
            </div>
          )
        }

        <div style={{ maxWidth: 400 }}>
          <Input name="email" label="Email Address" type="email" required />
        </div>
      </div>

      <div className="d-flex">
        <BrandButton
          brand={props.brand}
          className="flex-1 flex-md-none"
          type="submit"
        >
          Start Your Practice Test
        </BrandButton>
      </div>
    </form>
  );
};

export default TemplateOptionAndForm;
