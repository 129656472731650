import React, { useState, useRef } from 'react';
import { FileUploadFieldProps } from './types';
import style from './style.module.scss';
import cx from 'classnames';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { ErrorMessage, useField } from 'formik';

export const FileUploadField: React.FC<FileUploadFieldProps> = (props) => {
  const { label, fileType } = props;
  const inputFile = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [field, meta, helpers] = useField(props);

  const inputFileClick = () => {
    inputFile.current.click();
  };

  const inputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const files = Array.from(e.target.files);
    helpers.setValue(files);
    e.target.value = null;
  };

  const onDragEnter = () => {
    setIsDragging(true);
  };

  const onDragLeave = () => {
    setIsDragging(false);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    e.dataTransfer.files;
    const files = Array.from(e.dataTransfer.files);
    helpers.setValue(files);
  };

  return (
    <>
      <div
        className={cx(style.fileUploadField, isDragging ? style.isDragging : '', 'u-margin-B-s')}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}>
        <div className={style.content}>
          {field.value.length === 0 ? (
            <>
              <span className={style.icon}>
                <FontAwesomeIcon icon="upload" className="fa-2x" />
              </span>

              <p>
                <small style={{ color: style.grayDarker }}>{label}</small>
              </p>
              <a onClick={inputFileClick} style={{ color: style.grayDarker }}>
                <strong>Choose a file to upload</strong>
              </a>
            </>
          ) : (
            <>
              <p>Files Uploaded:</p>
              {field.value.map((file) => (
                <p key={file.name}>
                  <small>
                    <strong>{file.name}</strong>
                  </small>
                </p>
              ))}
            </>
          )}
        </div>
        <input
          type="file"
          id="file"
          ref={inputFile}
          accept={fileType}
          onChange={inputFileChange}
          style={{ display: 'none' }}
        />
      </div>
      <div className={cx(style.errorMessage, 'u-margin-B-xs')}>
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
};

export default FileUploadField;
