import * as React from 'react';
import { paths } from 'config/path_helpers';
import { SubjectBlockDashboardData } from 'types/content_blocks';
import { Col, Grid, Row } from 'react-bootstrap';
import style from './style.module.scss';
import LessonsCard from './content_blocks/lessons_card';
import QuizCard from './content_blocks/quiz_card';
import PracticeCard from './content_blocks/practice_card';
import { Assignment, UserAssignmentState } from '../types';
import { PrimaryButton } from '@magoosh/lib/button';
import ContentBlockBreadCrumbs from './content_blocks/breadcrumbs';
import * as moment from 'moment';
import fetch from 'utilities/fetch';
import Modal from '@magoosh/lib/modal';
import greenCheck2x from './assets/green_check_2x.png';

interface AssignmentSubjectBlockProps extends SubjectBlockDashboardData {
  assignment: Assignment;
}

interface AssignmentSubjectBlockState {
  showSubmittedDialog: boolean;
  assignment: Assignment;
}

export default class AssignmentSubjectBlock extends React.Component<
AssignmentSubjectBlockProps,
AssignmentSubjectBlockState
> {
  constructor(props) {
    super(props);
    this.state = {
      assignment: this.props.assignment,
      showSubmittedDialog: false
    };
  }

  render() {
    const { contentBlock } = this.props;
    const { assignment } = this.state;

    return (
      <div>
        <div className={style.backToAssignments}>
          <a className="text-gray-light" href={paths.assignments()}>
            Back to Assignments
          </a>
        </div>
        <Grid>
          <Row className={style.header}>
            <Col md={7}>
              <h3>{assignment.title}</h3>
            </Col>
            <Col md={2} className={style.dueDate}>
              {this.renderDueDate()}
            </Col>
          </Row>
          <Row>
            <Col md={9} className={style.instructions}>
              You will only be able to submit this assignment once. Complete all the tasks below before
              submitting.
            </Col>
          </Row>
          <Modal
            show={this.state.showSubmittedDialog}
            backdrop={true}
            onHide={this.handleCloseSubmittedDialog}>
            <div className={style.assignmentSubmittedModalContent}>
              <h3>Assignment submitted</h3>
              <div>
                <img src={greenCheck2x} />
              </div>
              <div>Your assignment has been submitted to your instructor for review!</div>
              <div>
                <PrimaryButton href="/assignments">Go to all Assignments</PrimaryButton>
              </div>
            </div>
          </Modal>
        </Grid>
        <ContentBlockBreadCrumbs {...this.props} />
        <Row>
          <Col md={9}>
            <LessonsCard
              {...this.props}
              title="Video Lessons"
              subtitle={`Watch the following video lessons on ${contentBlock.name}.`}
            />
            <QuizCard {...this.props} title="Quiz" />
            <PracticeCard {...this.props} title="Practice Questions" />
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <hr />
            <PrimaryButton onClick={this.submitAssignment} disabled={this.currentUserSubmitted()}>
              {this.currentUserSubmitted() ? 'Submitted' : 'Submit Assignment'}
            </PrimaryButton>
          </Col>
        </Row>
      </div>
    );
  }

  renderDueDate = () => {
    const assignment = this.state.assignment;
    if (!assignment.dueDate) {
      return null;
    }

    return <span>Due: {moment(assignment.dueDate).format('L')}</span>;
  };

  submitAssignment = () => {
    fetch(paths.api.submitAssignment(this.state.assignment.id), { method: 'PUT' })
      .then((currentUserAssignment) => {
        const assignment = this.state.assignment;
        assignment.currentUserAssignment = currentUserAssignment;
        this.setState({
          assignment,
          showSubmittedDialog: true
        });
      })
      .catch((error) => {
        window.alert(error);
      });
  };

  currentUserSubmitted = () => {
    return this.state.assignment.currentUserAssignment.state === UserAssignmentState.submitted;
  };

  handleCloseSubmittedDialog = () => {
    this.setState({ showSubmittedDialog: false });
  };
}
