export enum AssignmentActionTypes {
  SET_TEACHER_MODE = 'ASSIGNMENTS/SET_TEACHER_MODE',
  SET_ASSIGNMENTS = 'ASSIGNMENTS/SET_ASSIGNMENTS',
  SET_DETAILS_ASSIGNMENT = 'ASSIGNMENTS/SET_DETAILS_ASSIGNMENTS',
  ASSIGNMENT_CREATED = 'ASSIGNMENTS/ASSIGNMENT_CREATED',
  ASSIGNMENT_DELETED = 'ASSIGNMENTS/ASSIGNMENT_DELETED',
  SHOW_ASSIGNMENT_DIALOG = 'ASSIGNMENTS/SHOW_ASSIGNMENT_DIALOG',
  HIDE_ASSIGNMENT_DIALOG = 'ASSIGNMENTS/HIDE_ASSIGNMENT_DIALOG',
  SHOW_DELETE_ASSIGNMENT_DIALOG = 'ASSIGNMENTS/SHOW_DELETE_ASSIGNMENT_DIALOG',
  HIDE_DELETE_ASSIGNMENT_DIALOG = 'ASSIGNMENTS/HIDE_DELETE_ASSIGNMENT_DIALOG'
}

export const setTeacherMode = (teacherMode) => {
  return {
    type: AssignmentActionTypes.SET_TEACHER_MODE,
    teacherMode
  }
};

export const setAssignments = (assignments) => {
  return {
    type: AssignmentActionTypes.SET_ASSIGNMENTS,
    assignments
  }
};

export const setDetailsAssignment = (assignment) => {
  return {
    type: AssignmentActionTypes.SET_DETAILS_ASSIGNMENT,
    assignment
  }
};

export const assignmentCreated = (assignment) => {
  return {
    type: AssignmentActionTypes.ASSIGNMENT_CREATED,
    assignment
  }
};

export const assignmentDeleted = (assignment) => {
  return {
    type: AssignmentActionTypes.ASSIGNMENT_DELETED,
    assignment
  }
};

export const showAssignmentDialog = () => {
  return {
    type: AssignmentActionTypes.SHOW_ASSIGNMENT_DIALOG
  }
};

export const hideAssignmentDialog = () => {
  return {
    type: AssignmentActionTypes.HIDE_ASSIGNMENT_DIALOG
  }
};

export const showDeleteAssignmentDialog = () => {
  return {
    type: AssignmentActionTypes.SHOW_DELETE_ASSIGNMENT_DIALOG
  }
};

export const hideDeleteAssignmentDialog = () => {
  return {
    type: AssignmentActionTypes.HIDE_DELETE_ASSIGNMENT_DIALOG
  }
};
