import React from 'react';
import { paths } from 'config/path_helpers';
import { CheckpointCardProps } from '@magoosh/gre/organisms/checkpoint_card/types';
import CheckpointCard from '@magoosh/gre/organisms/checkpoint_card';
import TestDayIllustration from './assets/test_day.svg';
import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  testDate: string;
}

const PrioritizedTestDayCheckpoint: React.FC<Props> = (props) => {
  const children = (
    <>
      <h4>
        <strong>You made it!</strong>
      </h4>
      <p>
        You’ve put in a lot of hard work studying, so focus on making sure you have everything ready for
        your test. And get some rest before the big day. You got this!
      </p>
    </>
  );
  
  const illustration = (
    <img src={TestDayIllustration} alt="Test Day Illustration" />
  );

  const primaryCTA = {
    text: 'Read Our Tips',
    variant: 'secondary',
    href: paths.tips(),
    target: '_blank'
  };

  const secondaryCTA = (
    <a className="u-margin-H-xs" style={{ color: colors.grayDarker }} href={paths.profileEdit()}>
      Change Date in Profile
    </a>
  );

  const checkpointProps: CheckpointCardProps = {
    children,
    date: props.testDate,
    estimatedTime: '2 Hours',
    headline: 'Get Ready for Test Day',
    iconType: 'CalendarStar',
    illustration: illustration,
    isWrapped: true,
    primaryCTA,
    secondaryCTA
  };

  return (
    <CheckpointCard {...checkpointProps} />
  );
};

export default PrioritizedTestDayCheckpoint;
