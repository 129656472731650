import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

export const acceptTestPrepAIContentFix = (id: number) => {
  return fetch(adminPaths.api.testPrep.aiContentFixAccept(id), {
    method: 'POST'
  }).then((response) => {
    return response.contentFix;
  });
};

export const createTestPrepAIContentAudit = (contentAudit) => {
  return fetch(adminPaths.api.testPrep.aiContentAudits(), {
    method: 'POST',
    body: JSON.stringify({ contentAudit })
  }).then((response) => {
    return response.contentAudit;
  });
};

export const createTestPrepAIContentFix = (contentFix) => {
  return fetch(adminPaths.api.testPrep.aiContentFixes(), {
    method: 'POST',
    body: JSON.stringify({ contentFix })
  }).then((response) => {
    return response.contentFix;
  });
};

export const fetchRatings = (rateableId: number, rateableType: string, options = {}) => {
  return fetch(adminPaths.api.ratings(rateableId, rateableType, options)).then((response) => {
    return response.ratings;
  });
};

export const fetchRatingsSummary = (rateableId: number, rateableType: string, options = {}) => {
  return fetch(adminPaths.api.ratingsSummary(rateableId, rateableType, options)).then((response) => {
    return response.ratings.summary;
  });
};

export const fetchTestPrepAIContentAudit = (id: number) => {
  return fetch(adminPaths.api.testPrep.aiContentAudit(id)).then((response) => {
    return response.contentAudit;
  });
};

export const fetchTestPrepAIContentAuditsAvailableWorkflows = (contentId: number, contentType: string) => {
  return fetch(adminPaths.api.testPrep.aiContentAuditsAvailableWorkflows(contentId, contentType)).then(
    (response) => {
      return response.contentAuditWorkflows;
    }
  );
};

export const fetchTestPrepAIContentFix = (id: number) => {
  return fetch(adminPaths.api.testPrep.aiContentFix(id)).then((response) => {
    return response.contentFix;
  });
};

export const fetchTestPrepAIContentFixesAvailableEvaluators = (contentId: number, contentType: string) => {
  return fetch(adminPaths.api.testPrep.aiContentFixesAvailableEvaluators(contentId, contentType)).then(
    (response) => {
      return response.contentFixEvaluators;
    }
  );
};

export const fetchTestPrepAIContentFixesReferenceableContent = (contentId: number, contentType: string) => {
  return fetch(adminPaths.api.testPrep.aiContentFixesReferenceableContent(contentId, contentType)).then(
    (response) => {
      return response.referenceableContent;
    }
  );
};

export const fetchTestPrepAIDraftPrompt = (id: number) => {
  return fetch(adminPaths.api.testPrep.aiDraftPrompt(id)).then((response) => {
    return response.draftPrompt;
  });
};

export const fetchAIPromptVersion = (id: number, versionId: number, proposedFixId: number) => {
  return fetch(
    adminPaths.api.aiPromptVersion(id, versionId, proposedFixId)
  ).then((response) => {
    return response.version;
  });
};

export const fetchPage = (path) => {
  return fetch(`${path}.json`).then((response) => {
    return response;
  });
};

export const rejectTestPrepAIContentFix = (id: number) => {
  return fetch(adminPaths.api.testPrep.aiContentFixReject(id), {
    method: 'POST'
  }).then((response) => {
    return response.contentFix;
  });
};

export const updateTestPrepAIDraftPrompt = (draftPrompt) => {
  return fetch(adminPaths.api.testPrep.aiDraftPrompt(draftPrompt.id), {
    method: 'PUT',
    body: JSON.stringify({ draftPrompt })
  }).then((response) => {
    return response.draftPrompt;
  });
};
