import React from 'react';
import { NewStudentFormTabProps, NewStudentFormTabValues } from './types';
import style from '../../style.module.scss';
import cx from 'classnames';
import { DefaultButton } from '@magoosh/lib/button';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { FieldInput } from '@magoosh/lib/formik_inputs/field_input/template';

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  parentEmail: ''
};

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required.'),
  firstName: yup.string(),
  lastName: yup.string(),
  parentEmail: yup.string()
});

export const NewStudentFormTab: React.FC<NewStudentFormTabProps> = (props) => {
  const handleOnSubmit = (
    values: NewStudentFormTabValues,
    { resetForm }: FormikActions<NewStudentFormTabValues>
  ): void => {
    props.addNewStudent(values);
    resetForm();
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
        {({ submitForm }) => (
          <>
            <Row>
              <Col md={12}>
                <FieldInput name="email" type="email" label="Email" />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FieldInput name="firstName" type="text" label="First Name" />
              </Col>
              <Col md={6}>
                <FieldInput name="lastName" type="text" label="Last Name" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FieldInput
                  name="parentEmail"
                  type="email"
                  label="Parent Email"
                  hint="For sending automated weekly reports"
                />
              </Col>
            </Row>
            <div className={cx(style.tabActions, style.end, 'u-margin-T-m')}>
              <DefaultButton onClick={submitForm}>
                <FontAwesomeIcon icon="plus-square" /> Add New Student
              </DefaultButton>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default NewStudentFormTab;
