import * as React from "react";
import {Fragment} from "react";
import {Alert, Button} from "react-bootstrap";

interface AssignmentFormSubmissionSectionProps {
  pristine: boolean;
  submitting: boolean;
}

interface AssignmentFormSubmissionSectionState {
  showConfirmationAlert: boolean;
}

export class AssignmentFormSubmissionSection
  extends React.Component<
    AssignmentFormSubmissionSectionProps,
    AssignmentFormSubmissionSectionState
    > {

  state = { showConfirmationAlert: false }

  render() {
    const {pristine, submitting} = this.props;

    return (
      <Fragment>
        {this.renderConfirmation()}
        {this.renderAssignButton(pristine, submitting)}
      </Fragment>
    );
  }

  renderAssignButton = (pristine, submitting) => {
    if (this.state.showConfirmationAlert) { return null; }

    return (
      <Button onClick={this.showConfirmation} disabled={pristine || submitting}>Assign</Button>
    )
  };

  renderConfirmation = () => {
    if (!this.state.showConfirmationAlert) { return null; }

    return (
      <Alert bsStyle="info">
        <div>
          Saving this assignment will send a notification to the students in this class. Are you sure
          you want to save this assignment?
        </div>
        <br />
        <Button type="submit">Assign and send notifications</Button>
      </Alert>
    )
  };

  showConfirmation = () => {
    this.setState({ showConfirmationAlert: true });
  };
}
