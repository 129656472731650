import * as React from 'react';
import style from './style.module.scss';
import cx from 'classnames';

export interface StudyDayLabelProps {
  backgroundColor: 'lightgreen' | 'green' | 'gray';
  underline: boolean;
  onClick?: () => {};
}

export default class StudyDayLabel extends React.Component<StudyDayLabelProps, any> {
  render() {
    const containerClasses = cx(style.container, {
      [style.underline]: this.props.underline
    });

    const buttonClasses = cx(style[this.props.backgroundColor], {
      [style.studyDayLabel]: true,
      [style.clickable]: !!this.props.onClick
    });

    return (
      <React.Fragment>
        <div className={containerClasses}>
          <button
            className={buttonClasses}
            onClick={this.props.onClick}
            disabled={this.props.onClick ? false : true}>
            {this.props.children}
          </button>
        </div>
        &nbsp;
      </React.Fragment>
    );
  }
}
