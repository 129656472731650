import React from 'react';
import cx from 'classnames';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  children: React.ReactNode | string;
  className?: string;
}

const PlainAlert: React.FC<Props> = (props) => {
  return (
    <div
      className={cx(props.className, 'u-padding-A-xs')}
      style={{
        backgroundColor: '#fff',
        borderColor: colors.grayLighter,
        borderWidth: '0px 0px 2px',
        borderStyle: 'solid',
        color: colors.textColor
      }}
    >
      {typeof props.children === 'string' ? <span dangerouslySetInnerHTML={{__html: props.children }} /> : props.children}
    </div>
  );
};

export default PlainAlert;
