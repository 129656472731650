import fetch from 'utilities/fetch';
import { paths } from "config/path_helpers";
import { isEqual, toPairs } from 'lodash';
import trackEvent from 'utilities/track_event';

/**
 * Convert to an acceptable format
 * Send to backend for persistence
 * Fire mixpanel events to track important changes
 */
export async function saveStudyPreferences(draft, saved) {
  const daysDidChange = !isEqual(draft.days, saved.days);

  const data = {
    userProfile: {
      days: draft.days,
      studyHoursPerDay: draft.hoursPerDay
    }
  };

  return fetch(paths.api.userProfile(), {
    method: 'PUT',
    body: JSON.stringify(data)
  }).then(() => {
    if (daysDidChange) {
      const selectedDays = toPairs(draft.days)
        .filter(([day, value]) => value)
        .map(([day, value]) => day);

      trackEvent('study_date_changes', {
        category: 'study_commitment',
        value: selectedDays
      });
    }
  });
}
