import React, { useContext, useState } from 'react';
import { BorderlessButton, SecondaryButton } from '@magoosh/lib/button';
import { DataTable, inactiveRowStyles } from '@magoosh/lib/data_table';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import { pluralize } from 'utilities/pluralize';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Search } from '@magoosh/lib/data_table/search';
import { FilterToggle } from '@magoosh/lib/data_table/filter_toggle';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import Icon from '../../assets/icons';
import colors from '../../assets/style.module.scss';
import { Badge } from '@magoosh/lib/data_table/badge';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@magoosh/b2b/app/app_context';
import { ArchiveClassSectionsModal } from '@magoosh/b2b/app/organisms/archive_class_sections_modal';

export const Classes: React.FC = () => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const { queryParameters, runQuery, dataQueryTableProps } = useDataQuery(
    paths.api.partner.classes,
    { sortColumn: 'displayName' }
  );

  const [archivingClassSections, setArchivingClassSections] = useState(null);
  const [archiveClassSectionError, setArchiveClassSectionError] = useState(false);

  const resetArchivingClassSections = () => {
    setArchivingClassSections(null);
  };

  const archiveClassSections = (ids) => {
    return fetch(paths.api.partner.archiveClasses(ids), { method: 'DELETE' }).then(() => {
      runQuery();
    });
  };

  const booksIcon = <Icon iconType="Class" fill={colors.supplementaryRadRed} size="m" />;
  const grayBooksIcon = <Icon iconType="Class" fill={colors.grayLight} size="m" />;

  return (
    <>
      <DataTable
        {...dataQueryTableProps}
        selectableRows
        primaryAttribute={{ selector: 'displayName', name: 'Class Name', sortable: true }}
        secondaryAttributeSelector="subject"
        linkBaseUrl="/classes/"
        columns={[
          { selector: 'sectionNumber', name: 'Section Number', sortable: true },
          { selector: 'studentsCount', name: 'Students', sortable: true },
          { selector: 'teachersCount', name: 'Teachers', sortable: true }
        ]}
        conditionalRowStyles={[
          {
            when: (classSection) => !classSection.active || !classSection.schoolActive,
            style: inactiveRowStyles
          }
        ]}>
        <DataTable.Filters>
          <Search runQuery={runQuery} />
          <FilterToggle
            queryParameters={queryParameters}
            runQuery={runQuery}
            filterName={'archived'}
            filterLabel={'Show archived'}
          />
        </DataTable.Filters>
        <DataTable.HeaderActions>
          {!appContext.externalSourceUser && (
            <SecondaryButton
              onClick={() => history.push('/classes/create')}
              icon={'plus'}
              size={'xsmall'}>
              Create New Class
            </SecondaryButton>
          )}
          <DownloadButton
            queryParameters={queryParameters}
            fetchUrl={paths.api.partner.classes}
            fileName={'class_data.csv'}
            disabled={appContext.trialAccess}
          />
        </DataTable.HeaderActions>
        <DataTable.ItemIcon>
          {(classSection: ClassSection) => (classSection.active && classSection.schoolActive ? booksIcon : grayBooksIcon)}
        </DataTable.ItemIcon>
        <DataTable.PrimaryAttributeLabel>
          {(classSection: ClassSection) => (
            <>
              {classSection.active ? null : <Badge>Archived</Badge>}
              {classSection.schoolActive ? null : <Badge>Archived School</Badge>}
              {classSection.displayName}
            </>
          )}
        </DataTable.PrimaryAttributeLabel>
        <DataTable.ItemActions>
          {!appContext.externalSourceUser && ((classSection: ClassSection) => (
            <>
              <BorderlessButton
                title={'Edit class'}
                icon="edit"
                onClick={() => history.push(`/classes/${classSection.id}/edit`)}
              />
              {classSection.active && (
                <BorderlessButton
                  title={'Archive class'}
                  icon="trash"
                  onClick={() => {
                    setArchiveClassSectionError(false);
                    setArchivingClassSections([classSection]);
                  }}
                />
              )}
            </>
          ))}
        </DataTable.ItemActions>
        <DataTable.BulkActions>
          <SecondaryButton
            size={'xsmall'}
            icon="trash"
            onClick={() => {
              setArchivingClassSections(dataQueryTableProps.selectedRows);
            }}>
            Archive {pluralize(dataQueryTableProps.selectedRows.length, 'class', 'classes')}
          </SecondaryButton>
        </DataTable.BulkActions>
      </DataTable>
      <ArchiveClassSectionsModal
        classSections={archivingClassSections}
        reset={resetArchivingClassSections}
        showErrorMessage={archiveClassSectionError}
        archiveClassSections={() => {
          archiveClassSections(archivingClassSections.map((classSection) => classSection.id)).then(() =>
            setArchivingClassSections(null)
          ).catch(() => {
            setArchiveClassSectionError(true);
          });
        }}
      />
    </>
  );
};
