import React from 'react';

const IELTSDetailsContent: React.FC = (props) => {
  return (
    <>
      <h4>Preparation</h4>
      <p>Just like with the real test, you should have a <strong>pen or pencil</strong> and <strong>scratch paper</strong> to write notes and work out problems.</p>
      <p>You should also set aside about <strong>3 hours and 15 minutes</strong> for this test. Let your family and friends know not to interrupt you during this time. :)</p>
      <p>You can pause between sections if you need to, but not within a section. Once you start a section, you'll need to finish it because the timer will keep running. We recommend taking the entire test in one sitting to practice your pacing and build your test-taking stamina, which you'll need on test day. Good luck!</p>
    </>
  );
};

export default IELTSDetailsContent;
