import { isUndefined } from 'lodash';

import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';

export const analyticsSaveRecentFilters = async (filters) => {
  fetch(paths.api.statisticRecentFilters(), {
    method: 'POST',
    body: JSON.stringify({ filters })
  });
};

export const createAIPromptAnswer = (formData) => {
  const data = new URLSearchParams(formData);

  return fetch(paths.api.aiPromptAnswers(), {
    method: 'POST',
    body: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }).then((response) => {
    return response.answer;
  });
};

export const createOrUpdateRating = (rating) => {
  return fetch(paths.api.rating(rating.rateableId), {
    method: 'PUT',
    body: JSON.stringify(rating)
  });
};

export const createTutoringSession = (skill: string, resume?: boolean) => {
  return fetch(paths.api.tutoringSessions(), {
    method: 'POST',
    body: JSON.stringify({
      resume: isUndefined(resume) ? true : resume,
      tutoringSession: {
        path: window.location.pathname,
        skill
      }
    })
  }).then((response) => {
    return response.tutoringSession;
  });
};

// Expects content to include text: string, and optionally activeActivityId: number
export const createTutoringSessionChat = (tutoringSessionId: number, content: any) => {
  return fetch(paths.api.tutoringSessionInteractions(tutoringSessionId), {
    method: 'POST',
    body: JSON.stringify({
      interaction: {
        content,
        type: 'chat'
      }
    })
  }).then((response) => {
    return response.interactions;
  });
};

export const fetchAvailableTutoringSessionSkills = () => {
  const path = paths.api.tutoringSessionsAvailableSkills(window.location.pathname);
  return fetch(path).then((response) => {
    return response.tutoringSession.availableSkills;
  });
};

export const fetchNextTutoringSessionInteractions = (tutoringSessionId: number, id: number) => {
  return fetch(paths.api.tutoringSessionNextInteractions(tutoringSessionId, id)).then((response) => {
    return response.interactions;
  });
};

export const fetchPage = (path) => {
  return fetch(`${path}.json`).then((response) => {
    return response;
  });
};

export const fetchStatistic = (name, filters) => {
  return fetch(paths.api.statistic(name, filters)).then((response) => {
    return response.statistic;
  });
};

export const fetchTutoringSessionInteraction = (tutoringSessionId: number, id: number) => {
  return fetch(paths.api.tutoringSessionInteraction(tutoringSessionId, id)).then((response) => {
    return response.interaction;
  });
};

export const fetchUserProfile = () => {
  return fetch(paths.api.userProfile()).then((response) => {
    return response.userProfile;
  });
};

export const updateTutoringSessionInteraction = (interaction) => {
  return fetch(paths.api.tutoringSessionInteraction(interaction.tutoringSessionId, interaction.id), {
    method: 'PUT',
    body: JSON.stringify({ interaction })
  }).then((response) => {
    return response.interaction;
  });
};

export const updateUserProfile = (userProfile) => {
  return fetch(paths.api.userProfile(), {
    method: 'PUT',
    body: JSON.stringify({ userProfile })
  }).then((response) => {
    return response.userProfile;
  });
};
