import React, { useEffect, useMemo, useState } from 'react';
import { AnswerCount, ExamSection } from '@magoosh/b2b/pages/school_report/types';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import { DataTableQueryParameters } from 'utilities/data_table_queries';
import { durationFormatter } from '@magoosh/b2b/app/utils';
import StatsBlock from '@magoosh/b2b/app/lib/stats_block';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';

interface StudentsStatsBlockProps {
  queryParameters: DataTableQueryParameters;
}

interface StudentStats {
  branding?: string;
  answerCountsByExamSectionId?: NumberHash<AnswerCount>;
  lessonsSeenByExamSectionId?: NumberHash<number>;
  timesSpentByExamSectionId?: NumberHash<number>;
  examSections?: ExamSection[];
  studentsCount?: number;
}

export const PerformanceStatsBlock = (props: StudentsStatsBlockProps) => {

  const { queryParameters } = props;

  const [studentStats, setStudentStats] = useState<StudentStats>(null);
  const [selectedStudentStatsSection, setSelectedStudentStatsSection] = useState<ExamSection | 'totals'>(null);

  const queryKeyParameters = {
    startDate: queryParameters.startDate,
    endDate: queryParameters.endDate,
    searchTerm: queryParameters.searchTerm,
    schoolId: queryParameters.filters['schoolId'],
    classSectionId: queryParameters.filters['classSectionId']
  }

  useQuery(
    ['performanceSummaryQuery', { queryKeyParameters }],
    ({ queryKey }) => {
      const [_key, { queryKeyParameters: DataTableQueryParameters }] = (queryKey as any);

      const url = paths.api.schoolReportPerformanceSummary(
        null,
        queryKeyParameters.schoolId,
        queryKeyParameters.classSectionId,
        queryKeyParameters.startDate,
        queryKeyParameters.endDate,
        queryKeyParameters.searchTerm);

      return fetch(url, { returnFullResponse: true })
    },
    {
      enabled: !studentStats,
      onError: () => {
        setStudentStats({});
      },
      onSuccess: (response) => {
        if (response.status == 200) {
          response.json().then(data => setStudentStats(data));
        }
      },
      refetchInterval: () => {
        return studentStats ? false : 5000;
      }
    }
  );

  useEffect(() => {
    setStudentStats((null))
  }, [
    queryParameters.startDate, queryParameters.endDate, queryParameters.searchTerm,
    queryParameters.filters['schoolId'], queryParameters.filters['classSectionId']]
  );

  const { totalQuestionsAnswered, totalPercentCorrect, totalLessonsWatched, totalTimeSpent } = useMemo(() => {
    if (isEmpty(studentStats)) { return {}; }

    let { totalCorrect, totalIncorrect } = Object
      .values(studentStats.answerCountsByExamSectionId)
      .reduce((sums, current) => {
        return {
          totalCorrect: sums.totalCorrect + (current.correct || 0),
          totalIncorrect: sums.totalIncorrect + (current.incorrect || 0),
        };
      }, { totalCorrect: 0, totalIncorrect: 0});

    let totalLessonsWatched = Object
      .values(studentStats.lessonsSeenByExamSectionId)
      .reduce((sum, current) => {
        return sum + (current || 0);
      }, 0);

    let totalTimeSpent = Object
      .values(studentStats.timesSpentByExamSectionId)
      .reduce((sum, current) => {
        return sum + (current || 0);
      }, 0);

    let total = totalCorrect + totalIncorrect;

    return {
      totalQuestionsAnswered: total,
      totalPercentCorrect: Math.round((totalCorrect / total) * 100),
      totalLessonsWatched: totalLessonsWatched,
      totalTimeSpent: totalTimeSpent
    };
  }, [studentStats]);

  const { questionsAnswered, percentCorrect, lessonsWatched, timeSpent } = useMemo(
    () => {
      if (!studentStats) { return {}; }

      let section = selectedStudentStatsSection;

      if (!section) {
        section = 'totals';
        setSelectedStudentStatsSection('totals');
      }

      if (section == 'totals') {
        return {
          questionsAnswered: totalQuestionsAnswered || 0,
          percentCorrect: totalPercentCorrect || 0,
          lessonsWatched: totalLessonsWatched || 0,
          timeSpent: totalTimeSpent || 0
        }
      }

      const answerCount = studentStats.answerCountsByExamSectionId[section.id] || {
        correct: 0,
        incorrect: 0
      };

      const lessonsWatched = studentStats.lessonsSeenByExamSectionId[section.id];
      const timeSpent = studentStats.timesSpentByExamSectionId[section.id];
      const questionsAnswered = (answerCount.correct || 0) + (answerCount.incorrect || 0);
      let percentCorrect;

      if (questionsAnswered) {
        percentCorrect = Math.round((answerCount.correct / questionsAnswered) * 100);
      }

      return {
        questionsAnswered: questionsAnswered || 0,
        percentCorrect: percentCorrect || 0,
        lessonsWatched: lessonsWatched || 0,
        timeSpent: timeSpent || 0
      }
    },[selectedStudentStatsSection, studentStats]);

  const examSections = (!isEmpty(studentStats) && studentStats.examSections.map((examSection) => {
    let label = examSection.displayName;

    if ([
      'ACT', 'SAT', 'GRE', 'GMAT', 'LSAT', 'MCAT', 'IELTS', 'TOEFL', 'IELTS General Training '
    ].includes(examSection.displayName)) {
      label = 'General ' + label;
    }

    return <div key={examSection.id}>
      <a
        href='#'
        className={examSection === selectedStudentStatsSection ? style.selectedStatsSectionLink : ''}
        title={label}
        onClick={() => {
          setSelectedStudentStatsSection(examSection);
        }}
      >
        {label} {examSection.digital && '(Digital)'}
      </a>
    </div>;
  })) || <div>--</div>;

  return <StatsBlock>
    <StatsBlock.Header>
      <div className={style.statsSectionLinks}>
        <div>
          <a
            href='#'
            className={selectedStudentStatsSection === 'totals' ? style.selectedStatsSectionLink : ''}
            title={`${studentStats && studentStats.branding} Totals`}
            onClick={() => {
              setSelectedStudentStatsSection('totals');
            }}
          >
            {studentStats && studentStats.branding} Totals
          </a>
        </div>
        {examSections}
      </div>
    </StatsBlock.Header>
    <StatsBlock.Stats>
      <div>
        <div>{questionsAnswered && questionsAnswered.toLocaleString() || '--'}</div>
        <div>Questions answered</div>
      </div>
      <div>
        <div>{percentCorrect || '--'}</div>
        <div>% correct</div>
      </div>
      <div>
        <div>{lessonsWatched && lessonsWatched.toLocaleString() || '--'}</div>
        <div>Lessons Watched</div>
      </div>
      <div>
        <div>{timeSpent && durationFormatter(timeSpent) || '--'}</div>
        <div>Time spent</div>
      </div>
    </StatsBlock.Stats>
  </StatsBlock>
}
