import React from 'react';

const LSATDetailsContent: React.FC = (props) => {
  return (
    <>
      <h4>Preparation</h4>
      <p>Just like with the real test, you should have a pen or pencil and scratch paper to write notes and work out problems.</p>
      <p>You should also set aside about <strong>2 hours and 30 minutes</strong> for a full test, or about <strong>1 hour and 45 minutes</strong> if you take it without the experimental section.</p>
      <p>Let your family and friends know not to interrupt you during this time. :)</p>
      <p>We recommend taking the entire test in one sitting to practice your pacing and build your test-taking stamina, which you'll need on test day. Good luck!</p>
      <p><strong>*Important note</strong>: Tests marked with an asterisk do not yet have complete explanations; however, you will still receive a score and stats on your pacing and correct and incorrect answers.</p>
    </>
  );
};

export default LSATDetailsContent;
