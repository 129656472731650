import React from 'react';
import { TabsProps, TabsContextProps, TabProps, PanelProps, TabsCompositionProps } from './types';
import {TabButton} from '@magoosh/lib/button';
import style from './style.module.scss'

export const TabsContext = React.createContext<TabsContextProps | undefined>(undefined);

export const useTabs = (): TabsContextProps => {
  const context = React.useContext(TabsContext);
  if (!context) {
    throw new Error('This component must be used within a <Tabs> component.');
  }
  return context;
};

export const Tab: React.FC<TabProps> = (props) => {
  const { activeTab, setActiveTab } = useTabs();
  const handleClick = () =>{
    setActiveTab(props.tab)
    if (props.addToHandleClick) props.addToHandleClick()
  }
  return (
    <>
      <TabButton
        disabled={activeTab === props.tab}
        onClick={() => handleClick()}>{props.children}
      </TabButton>
    </>
  )
};

export const Panel: React.FC<PanelProps> = (props) => {
  const { activeTab } = useTabs();
  return activeTab === props.tab ? <div>{props.children}</div> : null;
};


export const Tabs: React.FC<TabsProps> & TabsCompositionProps = (props) => {
  const [activeTab, setActiveTab] = React.useState(props.defaultTab);
  const filterChildren = (type) => {
    return props.children.filter((ch) => ch.type === type)
  }
  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      <div className={style.container}>
        <div className={style.tabsNav}>
          <div className={style.tabsRow}>
            {filterChildren(Tab)}
          </div>

          <hr className="u-margin-V-n" />
        </div>
        {filterChildren(Panel)}
      </div>
    </TabsContext.Provider>
  );
};

Tabs.Tab = Tab;
Tabs.Panel = Panel;

export default Tabs;
