import React, { useState } from 'react';
import cx from 'classnames';

import { HandsHoldingPlantIcon, LightBulbPlantIcon } from '@magoosh/lib/icons';
import ProgressBar from '@magoosh/lib/progress_bar';
import { paths } from 'config/path_helpers';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from '../style.module.scss';

interface Props {
  examName: string;
}

const AboutMagooshContent: React.FC<Props> = (props) => {
  // TODO - default data
  const [aboutMagooshData, setAboutMagooshData] = useState({
    infoSections: [],
  })

  import(`./exams/${props.examName.trim().toLowerCase().replace(/ /g, '_')}`).then((data) => {
    setAboutMagooshData(data.default);
  }).catch((error) => {});

  if (!aboutMagooshData.infoSections.length) {
    return null;
  }

  return (
    <div>
      <h4 className={cx(style.textDivider, 'text-center text-secondary u-margin-B-m u-margin-T-n')}>
        <strong>Magoosh can help you reach your {props.examName} goals</strong>
      </h4>

      <div className="d-flex flex-column flex-lg-row" style={{ gap: 20 }}>
        {
          aboutMagooshData.infoSections.map((infoSection, idx) => (
            <div
              key={idx}
              className="flex-1 u-padding-A-m"
              style={{ borderColor: colors.brandSecondary, borderRadius: 8, borderStyle: 'solid', borderWidth: 2 }}
            >
              <div className="d-flex align-items-center" style={{ gap: 20 }}>
                <div style={{ maxWidth: 80, minWidth: 80 }}>
                  {infoSection.icon}
                </div>

                <div className="d-flex flex-column" style={{ gap: 10 }}>
                  <div>
                    <h4 className="u-margin-A-n"><strong>{infoSection.name}</strong></h4>
                  </div>

                  <div style={{ lineHeight: 1 }}>
                    <small>{infoSection.text}</small>
                  </div>

                  <div>
                    <a href={paths.plans()}>Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default AboutMagooshContent;
