import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { InlineEditableProps } from './types';
import fetch from 'utilities/fetch';
import style from './style.module.scss';

export const InlineEditable: React.FC<InlineEditableProps> = (props) => {
  const { value, type, placeholder, patchUrl, name, readonly, onSave, ...inputProps } = props;

  const inputRef = React.useRef(null);
  const [changeEvent, setChangeEvent] = useState<React.ChangeEvent<HTMLInputElement>>(null);
  const [inputValue, setInputValue] = useState(value == null ? '' : value.toString());
  const [previousValue, setPreviousValue] = useState('');
  const [savedText, setSavedText] = useState(inputValue);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setInputValue(value == null ? '' : value.toString());
  }, [value])

  useEffect(() => {
    setSavedText(inputValue);
    if (!editMode) {
      setPreviousValue(inputValue);
    }
  }, [inputValue, editMode]);

  const handleClickDisplay = () => {
    if (readonly) return;
    setEditMode(true);
  };

  const handleBlur = (save = true) => {
    if (inputRef.current) {
      const { value: currentInputValue } = inputRef.current;
      if (save && previousValue !== currentInputValue) {
        doSave({
          value: currentInputValue,
          previousValue: previousValue
        });
        setSavedText(currentInputValue);
        setPreviousValue(currentInputValue);
      } else if (!save) {
        handleChange({
          ...changeEvent,
          target: changeEvent?.target
            ? { ...changeEvent.target, value: previousValue }
            : { value: previousValue }
        });
      }
      setEditMode(false);
    }
  };

  const handleKeydown = (e) => {
    if (e.keyCode === 13 || e.charCode === 13) {
      handleBlur();
    } else if (e.keyCode === 27 || e.charCode === 27) {
      handleBlur(false);
    }
  };

  const handleFocus = (e) => {
    if (type === 'text') {
      e.currentTarget.setSelectionRange(
        e.currentTarget.value.length,
        e.currentTarget.value.length
      );
    }
  };

  const handleChange = (e) => {
    let val = e.target.value;
    if (type == 'number' && val) {
      if (inputProps.min != null) {
        val = Math.max(inputProps.min, val);
      }
      if (inputProps.max != null) {
        val = Math.min(inputProps.max, val);
      }
    }

    setInputValue(val.toString());
  }

  const doSave = ({ value, previousValue }) => {
    fetch(patchUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        [name]: value
      })
    })
    .then(() => {
      if (onSave) {
        onSave(value, previousValue, name);
      }
    })
    .catch((error) => {
      setInputValue(previousValue);
      if (error.errors?._error) {
        alert(error.errors._error);
      }
    });
  };

  const renderDisplayMode = () => {
    return (
      <div className={style.displayContainer}>
        <div
          className={cx(
            style.label,
            style.shared,
            {
              [style.placeholder]: placeholder && !savedText,
              [style.readonly]: readonly
            }
          )}
          onClick={handleClickDisplay}
        >
          {savedText || placeholder}
        </div>
      </div>
    );
  };

  const renderEditMode = () => {
    return (
      <input
        {...inputProps}
        ref={inputRef}
        onBlur={() => handleBlur()}
        onKeyDown={handleKeydown}
        onFocus={handleFocus}
        type={type}
        name={name}
        className={style.shared}
        value={inputValue}
        onChange={(e) => {
          setChangeEvent(e);
          handleChange(e);
        }}
      />
    );
  };

  return !readonly && editMode
    ? renderEditMode()
    : renderDisplayMode();
}
