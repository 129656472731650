import * as React from 'react';
import { Fragment } from 'react';
import cx from 'classnames';
import { Nav, Navbar, NavItem, OverlayTrigger, Popover } from 'react-bootstrap';

import style from '../style.module.scss';
import logoImage from './assets/Magoosh-logo-purple-400x90.png';
import doubleChecks from './assets/Double-Checks-120x120.png';
import { NavigationLink, ProfileDropdownLink } from '../types';
import NavLink from '../nav_link/template';

export interface NavBarProps {
  navigationLinks: NavigationLink[];
  profileDropdownLinks: ProfileDropdownLink[];
}

export default class NavBar extends React.Component<NavBarProps, {}> {
  render() {
    return (
      <div className={cx(style.navBar, 'bg-white')}>
        <div className="visible-xs">{this.renderMobile()}</div>
        <div className="visible-sm">{this.renderTablet()}</div>

        <div className="hidden-xs hidden-sm">{this.renderDesktop()}</div>
      </div>
    );
  }

  renderDesktop() {
    return (
      <Fragment>
        <div className={cx(style.navbarFlexWrapper, 'container')} style={{ padding: '11px 0' }}>
          <div className={cx('u-margin-R-m')}>
            <a href="/">
              <img alt="Magoosh" className={style.logo} src={logoImage} />
            </a>
          </div>

          {this.renderNavLinks()}

          <div className={style.profileDropdown}>
            <OverlayTrigger
              overlay={this.renderProfilePopover()}
              placement="bottom"
              rootClose
              trigger="click">
              <div>
                <span className={cx(style.profileDropdownIcon, 'border-radius-all')}>
                  <span className="fa fa-user" />
                </span>
                <span className={cx(style.profileCaret, 'fa fa-caret-down')} />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </Fragment>
    );
  }

  renderTablet() {
    return (
      <div>
        <div className={cx(style.navbarFlexWrapper, 'container u-padding-V-xs')}>
          <div className="align-items-center d-flex flex-1">
            <a href="/">
              <img alt="Magoosh" className={style.miniLogo} src={doubleChecks} />
            </a>
            {this.renderNavLinks()}
          </div>

          <div className={style.profileDropdown}>
            <OverlayTrigger
              overlay={this.renderProfilePopover()}
              placement="bottom"
              rootClose
              trigger="click">
              <div>
                <span className={cx(style.profileDropdownIcon, 'border-radius-all')}>
                  <span className="fa fa-user" />
                </span>
                <span className={cx(style.profileCaret, 'fa fa-caret-down')} />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div>
        <Navbar className={style.mobileMenu}>
          <Navbar.Header>
            <Navbar.Toggle>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </Navbar.Toggle>

            <a href="/" style={{ float: 'left', height: 54, padding: 15 }}>
              <img alt="Magoosh" className={style.logo} src={logoImage} />
            </a>
          </Navbar.Header>

          <Navbar.Collapse>
            <Nav>
              {this.renderNavLinks()}

              {this.props.profileDropdownLinks.map((profileLink, idx) => (
                <NavItem eventKey={idx} href={profileLink.url} key={idx}>
                  {profileLink.name}
                </NavItem>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }

  renderNavLinks() {
    return (
      <div className={cx(style.navLinks)}>
        {this.props.navigationLinks.map((navLink, index) => (
          <NavLink key={index} name={navLink.name} url={navLink.url} selected={false} />
        ))}
      </div>
    );
  }

  renderProfilePopover() {
    return (
      <Popover id="profile-popover">
        <div>
          {this.props.profileDropdownLinks.map((profileLink, idx) => (
            <div key={idx}>
              <a className={style.profileLink} href={profileLink.url} target={profileLink.target}>
                {profileLink.name}
              </a>
            </div>
          ))}
        </div>
      </Popover>
    );
  }
}
