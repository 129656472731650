import style from '@magoosh/pages/essay_review/style.module.scss';
import Essay from '@magoosh/pages/essay_review/essay/template';
import cx from 'classnames';
import * as React from 'react';
import { WritingExample } from '@magoosh/admin/writing_examples/types';
import { useEffect } from 'react';
import { Evaluation } from '@magoosh/pages/essay_review/types';
import AnnotationSidebar from '@magoosh/pages/essay_review/annotation_sidebar/template';
import Modal from '@magoosh/lib/modal';
import VideoPlayer from '@magoosh/organisms/video_player';
import { DefaultLabel } from '@magoosh/high_school/pages/calypso/label';
import RatingForm from '@magoosh/organisms/rating';

interface Props {
  writingExample: WritingExample;
  evaluation?: Evaluation;
}
const WritingExampleDocument: React.FC<Props> = (props: Props) => {
  const [currentAnnotation, setCurrentAnnotation] = React.useState(null);
  const [mobileMode, setMobileMode] = React.useState(false);
  const evaluation = props.writingExample.evaluation;

  useEffect(() => {
    setMobileMode(window.innerWidth < 992);
  });

  const renderAnnotations = () => {
    if (mobileMode) {
      return renderAnnotationModal();
    } else {
      return (
        <div className={style.rightColumn}>
          <div>
            <AnnotationSidebar evaluation={evaluation} currentAnnotation={currentAnnotation} />
          </div>
        </div>
      );
    }
  };

  const renderAnnotationModal = () => {
    if (!currentAnnotation) {
      return null;
    }

    const highlight = props.writingExample.document.slice(
      currentAnnotation.startIndex,
      currentAnnotation.endIndex
    );
    const author = evaluation.author;

    return (
      <Modal
        show={!!currentAnnotation}
        backdrop={true}
        showCloseButton={true}
        onHide={() => setCurrentAnnotation(null)}>
        <div className={style.modalAnnotation}>
          <h3>Evaluation & Commentary</h3>
          <small>
            by {author.name}
            {author.name && ', '}
            {author.description}
          </small>
          <div className={style.modalHighlight}>
            <mark>{highlight}</mark>
          </div>
          <div className={cx(style.preSpace, 'u-margin-B-l')}>{currentAnnotation.text}</div>
        </div>
      </Modal>
    );
  };

  const authorTitle = () => {
    const author = evaluation.author;
    if (author.name) {
      return `${author.name}, ${author.description}`;
    } else {
      return author.description;
    }
  };

  return (
    <div className={mobileMode ? '' : style.essayReviewBackground}>
      <div className={style.essayReviewContainer}>
        <div className={style.flexRow}>
          <div className={style.leftColumn}>
            <h1>{props.writingExample.title}</h1>
            {props.writingExample.documentType && (
              <DefaultLabel className="u-margin-R-m">{props.writingExample.documentType}</DefaultLabel>
            )}
            {props.writingExample.pdfUrl && (
              <a href={props.writingExample.pdfUrl} target="_blank">
                Download formatted resume as a PDF
              </a>
            )}
            {props.writingExample.applicantProfile && (
              <>
                <h3>Applicant Profile</h3>
                <p>{props.writingExample.applicantProfile}</p>
              </>
            )}
            {props.writingExample.applicantProfileLink && (
              <p className="u-margin-T-m">
                <a href={props.writingExample.applicantProfileLink} target="_blank">
                  See full applicant profile here.
                </a>
              </p>
            )}
            {props.writingExample.prompt && (
              <>
                <h3>Prompt</h3>
                <p>{props.writingExample.prompt}</p>
              </>
            )}
            {props.writingExample.lesson && (
              <>
                <h3>{props.writingExample.lesson.title}</h3>
                <VideoPlayer {...props.writingExample.lesson.videoPlayerProps} />
              </>
            )}
          </div>
          <div className={style.rightColumn} />
        </div>
        <div className={style.flexRow}>
          <div className={style.leftColumn}>
            <div>
              <Essay
                onClickHighlight={setCurrentAnnotation}
                mobileMode={mobileMode}
                text={props.writingExample.document}
                evaluation={evaluation}
              />
              {evaluation?.text && (
                <>
                  <h4 className={cx(style.commentaryHeader, 'u-margin-T-m u-padding-T-m')}>
                    Additional Commentary
                  </h4>

                  <div className={style.evaluation}>
                    <p className={style.evaluationCredit}>by {authorTitle()}</p>
                    {evaluation.text}
                  </div>
                </>
              )}
            </div>
            <div className="u-margin-V-m">
              <RatingForm
                rating={props.writingExample.rating}
                rateableName={'writing example evaluation & commentary'}
              />
            </div>
          </div>
          {evaluation && renderAnnotations()}
        </div>
      </div>
    </div>
  );
};

export default WritingExampleDocument;
