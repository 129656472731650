import React from 'react';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import GradientPill from '../gradient_pill';

interface Props {
  skill: string;
  text?: string;
}

const LaunchTutorButton: React.FC<Props> = (props) => {
  const launchTutor = () => {
    const launchTutorEvent = new CustomEvent('launchTutor', { detail: { skill: props.skill } });
    document.dispatchEvent(launchTutorEvent);
  };

  return (
    <div onClick={launchTutor} style={{ cursor: 'pointer', display: 'inline-block' }}>
      <GradientPill size="sm">
        <FontAwesomeIcon icon="asterisk"/> {props.text || 'Launch AI Tutor'}
      </GradientPill>
    </div>
  );
};

export default LaunchTutorButton;
