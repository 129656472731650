// Manages a multistep UI on a single page, choosing which steps to render at a given time.
// Also can present a list of step names and which is currently active to set user expectations.
// Example usage:
// <Multistep currentStep={1}>
//   // Shows "Create Account -> Choose Avatar -> Invite Friends"
//   <Multistep.StepIndicators />
//
//   <Multistep.Step name="Create Account">
//     {step content here}
//   </Multistep.Step>
//
//   <Multistep.Step name="Choose Avatar">
//     {step content here}
//   </Multistep.Step>
//
//   <Multistep.Step name="Invite Friends">
//     {step content here}
//   </Multistep.Step>
// </Multistep>

import React, { Children, cloneElement } from 'react';
import { filter } from 'lodash';

import Step from './step';
import StepIndicators from './step_indicators';

interface Props {
  children: React.ReactNode;
  currentStep: number;
}

class Multistep extends React.Component<Props> {
  static Step = Step;
  static StepIndicators = StepIndicators;

  stepIndicatorSteps = () => {
    let childStepCount = 0;
    const allChildren = Children.toArray(this.props.children);
    const stepChildren = filter(allChildren, (child) => (child as React.ReactElement<any>).type === Step);

    return Children.map(stepChildren, (child, idx) => (
      {
        active: this.props.currentStep === idx + 1,
        name: (child as React.ReactElement).props.name,
        navigable: (child as React.ReactElement).props.navigable || false
      }
    ));
  };

  render() {
    const allChildren = Children.toArray(this.props.children);
    let childStepCount = 0;

    return (
      Children.map(allChildren, (child) => {
        if ((child as React.ReactElement<any>).type === StepIndicators) {
          return cloneElement(
            child as React.ReactElement<any>,
            { steps: this.stepIndicatorSteps() }
          );
        } else if ((child as React.ReactElement<any>).type === Step) {
          childStepCount += 1;
          return childStepCount === this.props.currentStep ? child : null;
        } else {
          return child;
        }
      })
    );
  }
}

export default Multistep;
