import React, { useEffect, useState } from 'react';
import { AssignInstructorsFormValues } from './types';
import { Switch, Route, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { NavBar, NavLink } from '../../organisms/nav_bar';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import colors from '../../assets/style.module.scss';
import PageColumn from '../../lib/page_column';
import Icon from '../../assets/icons';
import PreviewListField from '../../lib/preview_list_field';
import SelectInstructorsFormTab from '../../organisms/select_instructors_form_tab';
import NewInstructorFormTab from '../../organisms/new_instructor_form_tab';
import fetch from 'utilities/fetch';
import { uniqWith, isEqual } from 'lodash';
import { ErrorMessages } from '@magoosh/lib/formik_inputs/errors_messages';
import { compact } from 'lodash';
import SelectClassesFormTab from '@magoosh/b2b/app/organisms/select_classes_form_tab';

const validationSchema = yup.object({
  classSections: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().nullable(true),
        name: yup.string()
      })
    )
    .required('Please add classes to the list.'),
  instructors: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().nullable(true),
        email: yup.string().required(),
        firstName: yup.string(),
        lastName: yup.string()
      })
    )
    .required('Please add instructors to the list.')
});

export const AssignInstructorsForm: React.FC<{ classSection?: ClassSection }> = (props) => {
  const [classSections, setClassSections] = useState([]);
  const [existingInstructors, setExistingInstructors] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { url, path } = useRouteMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(paths.api.partner.assignInstructorsForm()).then((response) => {
      setClassSections(response.classSections);
      setExistingInstructors(
        response.instructors.map((instructor) => {
          return { ...instructor, isHidden: false, isSelected: false };
        })
      );
      setLoading(false);
    });
  }, []);

  const initialValues = {
    classSections: [],
    instructors: []
  };

  const handleOnSubmit = (
    values: AssignInstructorsFormValues,
    { setSubmitting }: FormikActions<AssignInstructorsFormValues>
  ): void => {
    setSubmitting(true);
    const postValues = { instructors: values.instructors };
    postValues['classSectionIds'] = values.classSections.map((classSection) => classSection.id);

    fetch(paths.api.partner.assignInstructors(), {
      body: JSON.stringify(postValues),
      method: 'POST'
    }).then((response) => history.push('/instructors/'));
  };

  const addSelectedClasses = (classes: ClassSection[], setFieldValue, values) => {
    setFieldValue('classSections', uniqWith([...values.classSections, ...classes], isEqual));
  };

  const removeClasses = (deletedClass, setFieldValue, values) => {
    const filteredList = values.classSections.filter((classSection) => deletedClass.id !== classSection.id);
    setFieldValue('classSections', filteredList);
  };

  const addSelectedInstructors = (instructors: Person[], setFieldValue, values) => {
    setFieldValue('instructors', uniqWith([...values.instructors, ...instructors], isEqual));
  };

  const addNewInstructor = (instructor: Person, setFieldValue, values) => {
    setFieldValue('instructors', uniqWith([...values.instructors, { id: null, ...instructor }], isEqual));
  };

  const removeInstructor = (deletedInstructor, setFieldValue, values) => {
    const filteredList = values.instructors.filter((instructor) =>
      deletedInstructor.id
        ? deletedInstructor.id !== instructor.id
        : deletedInstructor.email !== instructor.email ||
          deletedInstructor.firstName !== instructor.firstName ||
          deletedInstructor.lastName !== instructor.lastName
    );
    setFieldValue('instructors', filteredList);
  };

  return (
    <>
      <PageColumn>
        <div className={style.assignInstructorsForm}>
          <div className={style.formHeader}>
            <div className={style.headerText}>
              <h2 className="u-margin-T-n">Assign Instructors</h2>
              <h4>Let{"'"}s create a list of instructors and assign them to classes.</h4>
            </div>
            <div className="u-margin-L-s">
              <Icon iconType="Instructor" fill={colors.supplementaryMagenta} size="l" />
            </div>
          </div>

          {loading ? (
            <div className="text-center u-margin-T-l">
              <FontAwesomeIcon icon="spinner" className="fa-pulse fa-5x" />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}>
              {({ isSubmitting, submitForm, setFieldValue, values, errors }) => (
                <Form>
                  <NavBar>
                    <NavLink to={`${url}/select-instructors`} text="Select Instructors" />
                    <NavLink to={`${url}/new-instructors`} text="New Instructors" />
                    <NavLink to={`${url}/select-classes`} text="Select Classes" />
                  </NavBar>
                  <Switch>
                    <Route exact path={`${path}/`}>
                      <Redirect to={`${url}/select-instructors`} />
                    </Route>
                    <Route path={`${path}/select-instructors`}>
                      <SelectInstructorsFormTab
                        instructorsList={existingInstructors}
                        addSelectedInstructors={(instructors) =>
                          addSelectedInstructors(instructors, setFieldValue, values)
                        }
                      />
                    </Route>
                    <Route path={`${path}/new-instructors`}>
                      <NewInstructorFormTab
                        addNewInstructor={(instructor) => addNewInstructor(instructor, setFieldValue, values)}
                      />
                    </Route>
                    <Route path={`${path}/select-classes`}>
                      <SelectClassesFormTab
                        classesList={classSections}
                        addSelectedClasses={(classes) => addSelectedClasses(classes, setFieldValue, values)}
                      />
                    </Route>
                  </Switch>
                  <hr />

                  <PreviewListField
                    label="Instructors for the Classes"
                    emptyMessage="Please add instructors to the list."
                    name="instructors"
                    removeItem={(deletedInstructor) =>
                      removeInstructor(deletedInstructor, setFieldValue, values)
                    }>
                    {(item: Person) => (
                      <>
                        <strong>
                          {item.fullName || compact([item.lastName, item.firstName]).join(', ') || item.email}
                        </strong>
                      </>
                    )}
                  </PreviewListField>

                  <PreviewListField
                    label="Classes to Assign"
                    emptyMessage="Please add classes to the list."
                    name="classSections"
                    removeItem={(deletedClassSection) =>
                      removeClasses(deletedClassSection, setFieldValue, values)
                    }>
                    {(item: ClassSection) => (
                      <>
                        <strong>{item.displayName}</strong> {item.subject}{' '}
                      </>
                    )}
                  </PreviewListField>

                  <Row className="u-margin-T-s">
                    <Col xs={6}>
                      <SecondaryButton disabled={isSubmitting} onClick={submitForm} block>
                        Assign Instructors
                      </SecondaryButton>
                    </Col>

                    <Col xs={6}>
                      <DefaultButton onClick={() => history.push('/instructors')} block>
                        Cancel
                      </DefaultButton>
                    </Col>
                  </Row>
                  <ErrorMessages errors={errors} />
                  <p className="u-margin-T-s">
                    <small>
                      <FontAwesomeIcon icon={'info-circle'} /> An account will be created for each new
                      instructor giving them access to manage this class. Emails will be sent to the
                      instructors with directions for setting up their account passwords.
                    </small>
                  </p>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </PageColumn>
    </>
  );
};

export default AssignInstructorsForm;
