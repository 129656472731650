import * as React from 'react';
import { StaticRouter } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

// React router provides different router components for client and server rendering.
// This component wraps them up in a single spot and chooses the right one based on
// whether the DOM is accessible or not.

const canUseDOM = !!(
  (typeof window !== 'undefined' &&
  window.document && window.document.createElement)
);

const ContextualRouter = ({ children, location }) => {
  let Router = canUseDOM ? BrowserRouter : StaticRouter;

  return (
    <Router location={location}>
      {children}
    </Router>
  )
}

export default ContextualRouter;
