import React, { useState } from 'react';
import { paths } from 'config/path_helpers';
import ProgressBar from '@magoosh/lib/progress_bar';
import style from './style.module.scss';
import { DefaultButton, PrimaryButton, SecondaryButton } from '@magoosh/lib/button';
import fetch from 'utilities/fetch';
import RatingModal from '@magoosh/organisms/rating_modal';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

export interface IntraLessonNavProps {
  authenticityToken: string;
  lessonId: number;
  lessonTitle: string;
  mode: 'lesson' | 'quiz' | 'review';
  currentLessonSeries: {
    name: string;
    id: number;
    slug: string;
    subsection: string;
    totalLessonsCount: number;
    completedLessonsCount: number;
  };
  nextLesson: {
    title: string;
    slug: string;
  };
  drillBlockId: number;
  drillLocked: boolean;
}

export const IntraLessonNav: React.FC<IntraLessonNavProps> = (props) => {
  const {
    lessonId, lessonTitle, mode, currentLessonSeries, nextLesson, drillBlockId, drillLocked
  } = props;

  const [ratingsModalOpen, setRatingsModalOpen] = useState(false);

  const renderNextItem = () => {
    let component;
    let upNextLabel;
    let href;
    let nextButton;

    const onClick = () => {
      fetch(paths.lessonMarking(lessonId), {
        method: 'POST',
        body: JSON.stringify({
          authenticityToken: props.authenticityToken,
          marking: {
            name: 'completed'
          }
        })
      }).then(() => {
        window.location.href = href;
      });
    };

    if (mode == 'quiz') {
      return null;
    } else if (mode == 'lesson') {
      if (nextLesson) {
        upNextLabel = nextLesson.title;
        href = `${paths.lesson(nextLesson.slug)}?study_item=${currentLessonSeries.id}`;

        nextButton = (
          <PrimaryButton onClick={onClick}>Continue</PrimaryButton>
        );
      } else if (drillBlockId) {
        upNextLabel = 'Quiz';

        if (drillLocked) {
          href = paths.mcatUpgrade();
          nextButton = (
            <DefaultButton onClick={onClick}>
              Continue{' '}<FontAwesomeIcon icon={'lock'} />
            </DefaultButton>
          );
        } else {
          href = paths.contentBlock(drillBlockId, 'practice');
          nextButton = (
            <PrimaryButton onClick={onClick}>Continue</PrimaryButton>
          );
        }
      } else {
        href = paths.lessonSeries(currentLessonSeries.slug);

        nextButton = (
          <PrimaryButton onClick={onClick}>Finish</PrimaryButton>
        );
      }

      component = (
        <>
          <span>
            <span className={style.upNext}>Up Next: </span>
            <a onClick={onClick} role={'link'} tabIndex={0}>
              {upNextLabel}
            </a>
          </span>
          {nextButton}
        </>
      );
    } else {
      component = (
        <SecondaryButton
          onClick={() => {
            setRatingsModalOpen(true);
          }}>
          Finish
        </SecondaryButton>
      );
    }

    return component;
  };

  let title = null;

  if (mode == 'lesson') {
    title = lessonTitle;
  } else {
    title = 'Quiz';
  }

  return (
    <div className={style.container}>
      <div className={style.currentSeries}>
        <span className={style.subsection}>
          {currentLessonSeries.subsection}
          {' > '}
        </span>
        <a href={paths.lessonSeries(currentLessonSeries.slug)}>{currentLessonSeries.name}</a>
      </div>
      <div className={style.progressBar}>
        <ProgressBar
          backgroundColor="#cef0e3"
          color="#26B67C"
          percentage={
            (currentLessonSeries.completedLessonsCount / currentLessonSeries.totalLessonsCount) * 100
          }
          size={'sm'}
        />
      </div>
      <div className={style.nextLesson}>{renderNextItem()}</div>
      <h2 className={style.lessonTitle}>{title}</h2>
      <RatingModal
        rateableId={currentLessonSeries.id}
        open={ratingsModalOpen}
        onHide={() => setRatingsModalOpen(false)}
      />
    </div>
  );
};

export default IntraLessonNav;
