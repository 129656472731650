import {RootStore} from "@magoosh/store";
import {connect} from "react-redux";
import ClassTableTemplate from './template';

function mapStateToProps(store: RootStore) {
  return {
    ...store.teacherDashboard
  };
}

export default connect(mapStateToProps)(ClassTableTemplate);
