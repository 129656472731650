import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import { Answer } from '../types';

const ResultIcon:React.FC<Answer> = (props) => {
  if (props.answerState === 'system_graded_correct') {
    return <i className="fa correct-sprite" />;
  } else if (props.answerState === 'system_graded_incorrect') {
    return <i className="fa incorrect-sprite" />;
  } else if (props.answerState === 'auto_grade_needed') {
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="self-graded-incomplete-tooltip">
            This answer can be automatically graded.
          </Tooltip>
        }>
        <i className="fa ungraded-sprite" />
      </OverlayTrigger>
    );
  } else if (props.answerState === 'auto_graded') {
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="self-graded-complete-tooltip">
            Score ranges from {props.nonsystemGrading.min} to {props.nonsystemGrading.max}
          </Tooltip>
        }>
        <b>{props.nonsystemGrading.value}</b>
      </OverlayTrigger>
    );
  } else if (props.answerState === 'self_grade_needed') {
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="self-graded-incomplete-tooltip">
            Click on this answer to read our rubrics and grade yourself.
          </Tooltip>
        }>
        <i className="fa ungraded-sprite" />
      </OverlayTrigger>
    );
  } else if (props.answerState === 'self_graded') {
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="self-graded-complete-tooltip">
            Score ranges from {props.nonsystemGrading.min} to {props.nonsystemGrading.max}
          </Tooltip>
        }>
        <b>{props.nonsystemGrading.value}</b>
      </OverlayTrigger>
    );
  } else if (props.answerState === 'video') {
    return (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="video-clip-tooltip">This was a video clip.</Tooltip>}>
        <span>
          <FontAwesomeIcon icon="video-camera" />
        </span>
      </OverlayTrigger>
    );
  } else if (props.answerState === 'question_passage') {
    return (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="question-passage-tooltip">This was a question passage.</Tooltip>}>
        <span>
          <FontAwesomeIcon icon="file-text" />
        </span>
      </OverlayTrigger>
    );
  } else if (props.answerState === 'ungraded') {
    return <i className="fa ungraded-sprite" />;
  } else {
    return null;
  }
};

export default ResultIcon;
