import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import { capitalize, debounce, isObject } from 'lodash';
import Combobox from '@magoosh/lib/forms/combobox';

import fetch from 'utilities/fetch';

interface Props {
  id?: string;
  className?: string;
  data?: any[];
  defaultValue: any;
  disabled?: boolean;
  error?: string;
  filter?: string | ((any) => boolean);
  groupBy?: string | ((any) => any);
  label?: string | React.ReactNode;
  name: string;
  placeholder?: string;
  searchEndpoint?: (string) => string;
  textField?: string | ((dataItem?) => string);
  valueField?: string;
}

const FieldCombobox: React.FC<Props> = (props) => {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <Combobox
          {...props}
          value={field.value}
          onChange={(value) => form.setFieldValue(props.name, value)}
        />
      )}
    </Field>
  );
};

export default FieldCombobox;
