import React from 'react';

import { CircleIcon, FontAwesomeIcon, VideoClipIcon } from '@magoosh/lib/icons';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  lengthInWords: string;
  locked: boolean;
  name: string;
  slug: string;
  status: {
    drills: 'completed';
    video: 'completed' | 'watched';
  };
}

const Lesson: React.FC<Props> = (props) => {
  const renderDrillsStatusBadge = () => {
    if (!props.status.drills) return;

    return (
      <div
        className="align-items-center d-flex gap-xs"
        style={{
          border: `1px solid ${colors.brandWarning}`,
          borderRadius: 20,
          color: colors.brandWarning,
          fontSize: 12,
          padding: '1px 8px'
        }}
      >
        <FontAwesomeIcon icon="bolt" />
        <div>Drills Finished</div>
      </div>
    );
  };

  const renderVideoStatusBadge = () => {
    if (!props.status.video) return;

    return (
      <div
        className="align-items-center d-flex gap-xs"
        style={{
          border: `1px solid ${colors.brandPrimary}`,
          borderRadius: 20,
          color: colors.brandPrimary,
          fontSize: 12,
          padding: '1px 8px'
        }}
      >
        {(() => {
          if (props.status.video === 'completed') {
            return (
              <>
                <FontAwesomeIcon icon="check" style={{ WebkitTextStroke: '1px #ffffff' }} />
                <div>Finished</div>
              </>
            );
          } else if (props.status.video === 'watched') {
            return (
              <>
                <FontAwesomeIcon icon="eye" />
                <div>Watched</div>
              </>
            );
          }
        })()}
      </div>
    );
  };

  return (
    <div className="align-items-center d-flex gap-m">
      <div>
        {
          props.locked ? (
            <CircleIcon
              colorPalette={{ dark: colors.grayLight, light: colors.grayLighter }}
              size={40}
            >
              <FontAwesomeIcon icon="lock" style={{ fontSize: 18 }} />
            </CircleIcon>
          ) : (
            <VideoClipIcon height={40} width={40} />
          )
        }
      </div>

      <div className="d-flex flex-1 flex-column flex-lg-row gap-m">
        <div className="d-flex flex-column flex-1 gap-xxs">
          <div>{props.name}</div>
          <div className="small text-gray-light">{props.lengthInWords}</div>
        </div>

        <div className="align-items-center d-flex gap-s">
          {renderDrillsStatusBadge()}
          {renderVideoStatusBadge()}
        </div>
      </div>
    </div>
  );
};

export default Lesson;
