import * as React from 'react';
import { AnswerChoice, AnswerData, PromptData } from './types';
import cx from 'classnames';
import style from './style.module.scss';

interface ShortAnswerProps {
  prompt: PromptData;
  review: boolean;
}

export default function ShortAnswerInput(props: ShortAnswerProps) {
  const prompt = props.prompt;
  const previousAnswer: AnswerData = prompt.userAnswers.find((answer) => answer.promptId === prompt.id);
  const answerPart = previousAnswer && previousAnswer.parts[0];
  const correctAnswer = props.review && prompt.answerChoiceGroups[0].answerChoices[0];

  return (
    <div className="answer_string">
      <div className={cx(resultClass(props.review, previousAnswer), 'answer_input answer-inputs')}>
        {prompt.errors && <p className={style.error}>{prompt.errors}</p>}
        <input
          type="string"
          name={`prompts[${prompt.id}][${prompt.answerChoiceGroups[0].id}[text][]`}
          defaultValue={answerPart ? answerPart.text : ''}
          disabled={props.review}
          className={style.stringInput}
        />
        {renderCorrectAnswer(previousAnswer, correctAnswer)}
      </div>
    </div>
  );
}

function renderCorrectAnswer(previousAnswer: AnswerData, correctAnswer: AnswerChoice) {
  if (!previousAnswer || !correctAnswer || previousAnswer.correct) {
    return null;
  }

  return <p className={'u-padding-T-xxs'}>Correct answer: {correctAnswer.text}</p>;
}

function resultClass(review: boolean, answer: AnswerData) {
  if (!review) {
    return '';
  }
  if (answer.correct) {
    return style.correct;
  } else {
    return style.incorrect;
  }
}
