import * as React from 'react';

import ExamSectionCard from '../exam_section_card';
import { ExamSectionGroup as Group } from '../types';

interface ExamSectionGroupProps {
  group: Group;
  wideCards?: boolean;
}

export default class ExamSectionGroup extends React.Component<ExamSectionGroupProps> {
  render() {
    const group = this.props.group;

    const groupHeader = (
      <div className="d-flex justify-content-center u-margin-B-s">
        <span style={{ color: '#4a4a4a' }}>
          {group.displayName} score <b>{group.estimatedScore}</b>
        </span>
      </div>
    );

    return (
      <React.Fragment>
        {group.estimatedScore && groupHeader}
        <div className="d-flex flex-row justify-content-around flex-wrap">
          {group.examSections.map((section, idx) => (
            <ExamSectionCard key={idx} examSection={section} wide={this.props.wideCards} />
          ))}
        </div>
      </React.Fragment>
    );
  }
}
