import * as React from 'react';
import { LiveClassCohort } from '@magoosh/admin/live_class_sessions/types';
import { DefaultButton, PrimaryButton, TabButton } from '@magoosh/lib/button';
import { adminPaths } from 'config/path_helpers';
import { DataTable } from '@magoosh/lib/data_table';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { useMemo, useState } from 'react';
import Modal from '@magoosh/lib/modal';
import { PageProps } from '@magoosh/admin/live_class_cohorts/index/types';
import CohortForm from '@magoosh/admin/live_class_cohorts/index/form';
import DropdownList from 'react-widgets/lib/DropdownList';
import { Col, Row } from 'react-bootstrap';
import { Search } from '@magoosh/lib/data_table/search';
import { QueryClient, QueryClientProvider } from 'react-query';

const LiveClassCohortsIndex: React.FC<PageProps> = (props) => {
  const defaultQueryParams = useMemo(() => {
    const thoseParams = { filters: {} };
    return thoseParams;
  }, []);

  const { runQuery, dataQueryTableProps } = useDataQuery(
    adminPaths.api.liveClassCohorts,
    defaultQueryParams
  );

  const [showFormModal, setShowFormModal] = useState(false);
  const [editingCohort, setEditingCurrentCohort] = useState(null);

  const onClickNew = () => {
    setEditingCurrentCohort(null);
    setShowFormModal(true);
  };

  const onClickEdit = (cohort: LiveClassCohort) => {
    setEditingCurrentCohort(cohort);
    setShowFormModal(true);
  };

  return (
    <div>
      <div>
        <div className="text-center">
          <TabButton href={adminPaths.liveClassSessions()}>Sessions</TabButton>
          <TabButton disabled>Cohorts</TabButton>
        </div>
        <div>
          <h1>On-Demand Cohorts</h1>
        </div>
      </div>
      <DataTable
        {...dataQueryTableProps}
        columns={[
          {
            name: 'Name',
            selector: 'name'
          },
          {
            name: 'Type',
            selector: 'attachableType'
          },
          {
            name: 'Attached To',
            selector: 'attachableName'
          }
        ]}>
        <DataTable.HeaderActions>
          <PrimaryButton icon="plus" onClick={onClickNew}>
            New
          </PrimaryButton>
        </DataTable.HeaderActions>
        <DataTable.Filters>
          <div className="filters" style={{ width: '800px' }}>
            <Row>
              <Col md={4}>
                <Search runQuery={runQuery} placeholder={'Search Name...'} />
              </Col>
              <Col md={4}>
                <DropdownList
                  data={[
                    { name: 'Active', value: 'true' },
                    { name: 'Inactive', value: 'false' },
                    { name: 'Any', value: 'any' }
                  ]}
                  onChange={(selection) => {
                    runQuery({ active: selection.value });
                  }}
                  placeholder="Filter by active"
                  textField="name"
                  valueField="value"
                  dataKey="value"
                />
              </Col>
            </Row>
          </div>
        </DataTable.Filters>
        <DataTable.ItemActions>
          {(row: LiveClassCohort) => {
            if (row.onDemand) {
              return (
                <DefaultButton onClick={() => onClickEdit(row)} icon="edit">
                  Edit
                </DefaultButton>
              );
            } else {
              return null;
            }
          }}
        </DataTable.ItemActions>
      </DataTable>
      <Modal
        onHide={() => setShowFormModal(false)}
        show={showFormModal}
        title={editingCohort ? `Edit ${editingCohort.name}` : 'New Cohort'}
        showCloseButton={true}>
        <div>
          <CohortForm
            {...props}
            cohort={editingCohort}
            runQuery={runQuery}
            closeModal={() => setShowFormModal(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default (props: PageProps) => {
  const queryClient = new QueryClient();

  return <QueryClientProvider client={queryClient}>
    <LiveClassCohortsIndex {...props} />
  </QueryClientProvider>;
};
