import shieldNull from './assets/shield_null.png';
import style from './style.module.scss';
import shieldNeedsWork from './assets/shield_needs_work.png';
import shieldMastered from './assets/shield_mastered.png';
import Card from '@magoosh/lib/card';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';
import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import * as React from 'react';

export const DrillCard: React.FC<{ drill: Drill; index: number }> = (props) => {
  const { drill, index } = props;

  if (!drill) {
    return null;
  }

  let drillBadge;
  let instructions;
  let reviewButton = null;
  let action;
  let answerParams = {
    'review[practice_session_id]': drill.latestPracticeSessionId,
    collapse_filters: true
  };

  if (!drill.state) {
    drillBadge = <img src={shieldNull} />;
    instructions = (
      <>
        Answer <strong>4 out of 5 concept-based questions</strong> correctly to pass this quiz. You can review
        your answers and retake the quiz as many times as you need.
      </>
    );

    action = 'Start Quiz';
  } else {
    action = 'Retake Quiz';
    let correctAnswers = drill.answers.filter((answer) => answer.correct).length;

    if (drill.state == 'attempted') {
      drillBadge = (
        <strong className={style.attempted}>
          <img src={shieldNeedsWork} />
          Needs work
        </strong>
      );
      instructions = (
        <>
          <p>
            Keep learning! You answered <strong>{correctAnswers} out of 5</strong> answers correctly.
          </p>
          <p>
            Watch the explanation & lesson videos for the questions you missed. Then retake the quiz and try
            again to get at least 4 out of 5 correct.
          </p>
        </>
      );
    } else {
      drillBadge = (
        <strong className={style.mastered}>
          <img src={shieldMastered} />
          Passed
        </strong>
      );
      instructions = (
        <>
          Well done! You answered <strong>{correctAnswers} out of 5</strong> answers correctly. Review the
          explanation & lesson videos for the questions you missed.
        </>
      );
    }
  }

  if (drill.latestPracticeSessionId) {
    reviewButton = <DefaultButton href={paths.answers(answerParams)}>Review answers</DefaultButton>;
  }

  return (
    <Card shadow className={style.drill}>
      <div className={style.cardTitle}>
        <h4>
          <strong>
            {index == null ? 'Drill this topic' : `Drill #${index + 1}`}
          </strong>
        </h4>
      </div>
      <div className={style.badge}>{drillBadge}</div>
      <div className={style.totalTime}>
        <span className={style.clockIcon}>
          <FontAwesomeIcon icon={'clock-o'} />
        </span>
        15 minutes
      </div>
      <div className={style.instructions}>{instructions}</div>
      <div className={style.answers}>
        {drill.answers &&
          drill.answers.map((answer) => (
            <div key={answer.id} className={style.answer}>
              <div>
                {answer.correct ? (
                  <FontAwesomeIcon icon={'check-circle'} className={style.correct} />
                ) : (
                  <FontAwesomeIcon icon={'times-circle'} className={style.incorrect} />
                )}
              </div>
              <div>
                <a href={paths.answer(answer.id, answerParams)}>{answer.question}</a>
              </div>
            </div>
          ))}
      </div>
      <div className={style.button}>
        {reviewButton}
        {drill.locked ? (
          <DefaultButton href={paths.mcatUpgrade()}>
            Start Quiz{' '}<FontAwesomeIcon icon={'lock'} />
          </DefaultButton>
        ) : (
          <PrimaryButton href={paths.contentBlock(drill.id, 'practice')}>{action}</PrimaryButton>
        )}
      </div>
    </Card>
  );
};
