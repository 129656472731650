import { TeacherDashboardData } from '../types';
import { Reducer } from 'redux';
import { TeacherDashboardActionTypes } from '../actions';

const blankState = {
  couponUser: null,
  classSections: [],
  classSectionsByGrade: {},
  students: [],
  usedAccounts: 0,
  totalAccounts: 0,
  totalStudents: 0,
  page: 0,
  showPerformanceData: null
};

export const teacherDashboardReducer: Reducer<TeacherDashboardData> = (state = blankState, action: any) => {
  switch (action.type) {
    case TeacherDashboardActionTypes.INITIALIZE_DASHBOARD:
      return {
        ...action.teacherDashboard,
        page: 0
      };
    case TeacherDashboardActionTypes.ADD_STUDENTS:
      return {
        ...state,
        students: state.students.concat(action.students),
        totalStudents: action.totalStudents,
        page: action.page
      };
    case TeacherDashboardActionTypes.CHANGE_CLASS_SECTION_FILTER:
      return {
        ...state,
        currentClassSectionFilter: parseInt(action.classSectionId)
      };
    case TeacherDashboardActionTypes.RESET_STUDENT_DATA:
      return { ...state, page: 0, students: [], totalStudents: 0 };
    default:
      return state;
  }
};
