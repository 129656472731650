import * as React from 'react';
import { Provider } from 'react-redux';
import CalypsoContainer from './container';
import { StudyPreferencesStore } from './reducers';
import { setInitialStudyPreferences } from './actions';
import DEFAULT_DATA from './default_data'; // TODO: consider defaultProps everywhere to avoid some runtime errors
import trackEvent from 'utilities/track_event';
import { currentDayName } from 'utilities/date_functions';
import configureStore from '@magoosh/store';

export default class Calypso extends React.Component<any> {
  store = configureStore();

  constructor(props) {
    super(props);

    this.store.dispatch(
      setInitialStudyPreferences({
        studyPreferencesSaved: props.studyPreferences,
        studyPreferencesUnsaved: props.studyPreferences,
        studyPreferencesModalIsOpen: props.studyPreferencesModalIsOpen,
        modalStartStep: 1, // For later: Can be upgraded to accept from server
        studyActivityThisWeek: props.studyActivityThisWeek
      } as StudyPreferencesStore)
    );
  }

  componentDidMount() {
    // In component did mount because we only want to fire this client-side
    const today = currentDayName();

    if (this.props.studyPreferences.days[today]) {
      trackEvent('study_as_promised', {
        category: 'study_commitment',
        label: today
      });
    }
  }

  render() {
    return (
      <Provider store={this.store}>
        <CalypsoContainer {...DEFAULT_DATA} {...this.props} />
      </Provider>
    );
  }
}
