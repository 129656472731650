import * as React from 'react';
import { PracticePreviewData } from '../types';
import StudyItem from '../study_item_list/study_item';
import PracticeSessionPreviewModal from '../practice_session_preview_modal/template';
import style from './style.module.scss';
import StudyItemList, { StudyItemListProps } from '../../study_items/study_item_list';
import { StudyItemData } from '../../study_items/types';
import LSATPracticeSessionPreviewModal from '@magoosh/organisms/study_schedule/practice_session_preview_modal/lsat_practice_session_preview_modal';

export interface StudyScheduleItemListProps extends StudyItemListProps {
  itemLimit?: number;
  incompleteItemsOnly?: boolean;
  excludeItem?: number;
  examName: string;
  popoverPlacement?: string;
}

interface State {
  practicePreview?: PracticePreviewData;
  errorMessage?: string;
  checkedItems: number[]; // A list of item IDs that have been checked off on this page
}

// A component for rendering a list of study items.
// When itemLimit is provided, only <itemLimit> items will be rendered to start.
// When inCompleteItemsOnly == true, only incomplete items will be rendered to start.
// Additional available items will be rendered as current ones are checked off.
// Items checked off on this page load will remain.
//
// When excludeItem is provided, the item with the corresponding ID will not be rendered.

export default class StudyScheduleItemList extends StudyItemList<StudyScheduleItemListProps, State> {
  listType = 'Study Schedule';

  constructor(props) {
    super(props);
    this.state = {
      practicePreview: null,
      checkedItems: []
    };
  }

  render() {
    return (
      <div>
        {this.state.errorMessage && this.renderErrorMessage()}
        {/* Additional styling when the list is not contained in the CurrentSchedule component */}
        <div className={this.props.itemLimit ? style.studyItems : null}>
          {this.props.incompleteItemsOnly && this.renderCheckedItems()}
          {this.renderStudyItems()}
        </div>
        {this.state.practicePreview && this.renderPracticeSessionPreviewModal()}
      </div>
    );
  }

  renderPracticeSessionPreviewModal = () => {
    if (this.props.examName === 'LSAT') {
      return (
        <LSATPracticeSessionPreviewModal
          onHide={this.onClosePreviewModal.bind(this)}
          {...this.state.practicePreview}
        />
      );
    } else {
      return (
        <PracticeSessionPreviewModal
          onHide={this.onClosePreviewModal.bind(this)}
          {...this.state.practicePreview}
        />
      );
    }
  };

  // Render, at the top of the list, items checked off on this page load.
  renderCheckedItems = () => {
    const checkedItems = this.props.studyItems.filter((item) => this.state.checkedItems.includes(item.id));
    return checkedItems.map((item) => {
      return (
        <StudyItem
          key={item.id}
          onClick={this.handleItemClick}
          onCompletedToggle={this.handleChangeItemState}
          item={item}
          popoverPlacement={this.props.popoverPlacement}
        />
      );
    });
  };

  renderStudyItems = () => {
    let numRenderedItems = 0;
    const itemLimit = this.props.itemLimit || 100;
    const allCompleted = this.props.studyItems.every(
      (item) => item.completed || item.id == this.props.excludeItem
    );
    if (allCompleted && this.props.incompleteItemsOnly) {
      return <div className={style.finishedMessage}>🎉 You finished all your tasks for today!</div>;
    }

    let items = this.props.studyItems;
    if (this.props.excludeItem) {
      items = this.props.studyItems.filter((item) => {
        return item.id != this.props.excludeItem;
      });
    }

    return this.props.studyItems.map((item: StudyItemData) => {
      if (numRenderedItems < itemLimit && this.shouldRenderItem(item)) {
        numRenderedItems++;
        return (
          <StudyItem
            key={item.id}
            onClick={this.handleItemClick}
            onCompletedToggle={this.handleChangeItemState.bind(this)}
            item={item}
            popoverPlacement={this.props.popoverPlacement}
          />
        );
      } else {
        return null;
      }
    });
  };

  shouldRenderItem(item: StudyItemData) {
    // Remove an item from the collection before rendering.
    // For example, don't show the current lesson when rendering this on the lesson page.
    if (this.props.excludeItem && item.id == this.props.excludeItem) {
      return false;
    }
    return !item.completed || !this.props.incompleteItemsOnly;
  }

  handleChangeItemState(item: StudyItemData, isCompleted: boolean) {
    if (this.props.incompleteItemsOnly) {
      if (this.state.checkedItems.includes(item.id)) {
        this.setState({ checkedItems: this.state.checkedItems.filter((id) => item.id != id) });
      } else {
        this.setState({ checkedItems: this.state.checkedItems.concat([item.id]) });
      }
    }

    return super.handleChangeItemState(item, isCompleted);
  }
}
