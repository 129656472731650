const breakdownSections = [
  {
    name: 'Algebra',
    percentCorrect: 67
  },
  {
    name: 'Powers and Roots',
    percentCorrect: 50
  },
  {
    name: 'Percents and Ratios',
    percentCorrect: 44
  },
  {
    name: 'Data Analysis',
    percentCorrect: 28
  },
  {
    name: 'Geometry',
    percentCorrect: 27
  }
];

const scoreSections = [
  {
    name: 'Math',
    sampleScore: 620,
    scoreRange: 'Score range: 200-800'
  },
  {
    name: 'Verbal',
    sampleScore: 640,
    scoreRange: 'Score range: 200-800'
  },
  {
    name: 'Total',
    sampleScore: 1260,
    scoreRange: 'Score range: 400-1600'
  }
];

export default {
  null: { breakdownSections, scoreSections },
  digital: { breakdownSections, scoreSections }
};
