import React from 'react';
import cx from 'classnames';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  children: React.ReactNode | string;
  className?: string;
}

const SuccessAlert: React.FC<Props> = (props) => {
  return (
    <div
      className={cx(props.className, 'u-padding-A-xs text-body')}
      style={{
        backgroundColor: colors.greenLight,
        borderColor: colors.green,
        borderWidth: '0px 0px 2px',
        borderStyle: 'solid',
        color: colors.green
      }}
    >
      {typeof props.children === 'string' ? <span dangerouslySetInnerHTML={{__html: props.children }} /> : props.children}
    </div>
  );
};

export default SuccessAlert;
