import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { every, find, isEqual, snakeCase } from 'lodash';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useConstantsContext } from '@magoosh/context/constants_context';
import { useCurrentProfileContext } from '@magoosh/context/current_profile_context';
import Card from '@magoosh/lib/card';
import { DefaultButton } from '@magoosh/lib/button';
import Filters, { filtersURLSearchParams } from '@magoosh/organisms/filters';
import { FilterConfig, FilterValues } from '@magoosh/organisms/filters/types';
import StudySchedule from '@magoosh/organisms/study_schedule';
import { StudyScheduleStore } from '@magoosh/organisms/study_schedule/types';
import useSearchQuery from '@magoosh/utils/use_search_query';
import { paths } from 'config/path_helpers';
import { CheckableContentBlocksType } from 'types/content_blocks';

import ContentCard from '@admissions/components/content_card';
import EditableApplicationCycle from '@admissions/components/editable_application_cycle';
import PastDueItemsAlert from '@admissions/components/past_due_items_alert';
import TextTabButtons from '@admissions/components/text_tab_buttons';
import ApplicationCycleChunks from '@admissions/organisms/application_cycle_chunks';
import ApplicationTracker from '@admissions/organisms/application_tracker';
import HighlightedContentCardSet from '@admissions/organisms/highlighted_content_card_set';
import HighlightedContentDropdown from '@admissions/organisms/highlighted_content_dropdown';
import StatusMessage from '@admissions/organisms/status_message';
import { useQueries } from '@admissions/queries';
import {
  AdmissionsHighlightedContent,
  ApplicationCycleChunk,
  ApplicationCycleDeadline,
  ApplicationCycleFilters,
  ApplicationCyclePlan,
  ApplicationCyclePlanItem,
  ApplicationCycleSegment
} from '@admissions/types';

import style from './style.module.scss';

interface Props {
  highlightedContent: AdmissionsHighlightedContent[];
  notesHref?: string;
  plan: ApplicationCyclePlan;
  studySchedule: StudyScheduleStore;
}

const VIEW_STUDY_SCHEDULE = 'study_schedule';
const VIEW_APPLICATION_TRACKER = 'application_tracker';

const MBAAdmissionsDashboard: React.FC<Props> = (props) => {
  const { getColorPalette } = useConstantsContext();
  const { currentProfile } = useCurrentProfileContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const q = useQueries();

  const [hasLoadedAllChunks, setHasLoadedAllChunks] = useState<boolean>(false);
  const [lastPageContext, setLastPageContext] = useState(null);

  // Filters
  const filters = {
    focus: useSearchQuery()['filters[focus]'] || null,
    programs: useSearchQuery()['filters[programs]'] || 'all',
    status: useSearchQuery()['filters[status]'] || 'to_do'
  } as ApplicationCycleFilters;
  const view = useSearchQuery().view as string || VIEW_STUDY_SCHEDULE;

  useEffect(() => {
    const pageContext = {
      filters,
      deadlines: props.plan.deadlines
    };

    if (isEqual(pageContext, lastPageContext)) return;

    setLastPageContext(pageContext);
    setHasLoadedAllChunks(false);
  }, [filters, props.plan.deadlines]);

  const filtersConfig = [
    {
      all: true,
      className: 'flex-1',
      collapsible: true,
      multiple: true,
      key: 'programs',
      name: 'Programs',
      options: props.plan.deadlines.filter((d) => !!d.programName).map((deadline) => {
        return {
          name: deadline.programName.replace(/^(.{5}[^\s]*).*/, '$1'),
          value: deadline.id.toString()
        }
      })
    },
    {
      className: 'flex-none',
      collapsible: false,
      key: 'status',
      name: 'Status',
      options: [
        { name: 'To Do', value: 'to_do' },
        { name: 'Completed', value: 'completed' },
      ]
    }
  ] as FilterConfig[];

  const buildWarningChunk = () => {
    return {
      name: 'Admissions Advice',
      deadlines: [],
      items: [
        {
          category: 'Admissions Advice',
          colorPalette: getColorPalette('purple'),
          icon: 'person',
          id: null,
          isCompleted: false,
          name: 'Consider applying to a later round/application cycle',
          segment: {
            accessLevel: 60,
            id: null,
            isCompleted: false,
            name: null
          } as ApplicationCycleSegment,
          tasks: [
            {
              id: null,
              isCompleted: false,
              name: null,
              richText: "<strong>Magoosh strongly recommends dedicating at least 3-4 months to the application process.</strong> If you choose to update your deadlines, edit them in the Application Tracker accordingly. If not, you can expect a high number of weekly tasks until you're caught up with the recommended application timeline.",
              type: 'RichText'
            } as CheckableContentBlocksType
          ]
        } as ApplicationCyclePlanItem
      ]
    } as ApplicationCycleChunk;
  };

  const currentChunkCount = () => {
    const currentChunks = queryClient.getQueryData(['chunks', filters]) as ApplicationCycleChunk[] || [];

    // There is a chance that all items in a current chunk have been marked complete/incomplete.
    // In this case they are still in our currentChunks because the UI hasn't done a full
    // update yet. We need to exclude these chunks in the count because the backend filtered
    // chunks will filter them out.
    let chunkCount = 0;
    const expectedIsCompleted = filters.status === 'to_do' ? false : true;
    currentChunks.forEach((chunk) => {
      if (!!chunk.deadlines.length || find(chunk.items, (item) => item.isCompleted === expectedIsCompleted)) {
        chunkCount += 1;
      }
    });

    return chunkCount;
  };

  const applicationCycleChunksQuery = q.applicationCycleChunksQuery(() => {
    const chunkCount = currentChunkCount();

    return {
      cycle: currentProfile.applicationCycle,
      filters,
      numberOfChunks: chunkCount > 0 ? chunkCount : 3,
      startingAt: 0
    };
  });

  const chunks = () => {
    let chunksToReturn = applicationCycleChunksQuery.data || [];

    // By default we show the most recent past due chunk if there is one, but the user needs to
    // choose to view all past due chunks. Add an indication after the single past due chunk
    // that there are more to view.
    const firstChunkIsPastDue = chunksToReturn[0] && every(chunksToReturn[0].items, (item) => item.isPastDue);
    if (firstChunkIsPastDue && !filters.focus && props.plan.itemCounts.pastDue > 1) {
      chunksToReturn[0].afterChunk = (
        <div className="text-center">
          <a className="text-gray-light" onClick={() => handleFiltersChanged({ focus: 'Past Due Tasks' })}>
            See All Past Due Tasks
          </a>
        </div>
      );
    }

    // Maybe add a warning chunk telling the student to consider applying in later rounds
    if (props.plan.deadlines.length && filters.status === 'to_do' && !filters.focus) {
      var planCreatedAt = moment(props.plan.createdAt);
      var earliestDeadlineDueAt = moment(props.plan.deadlines[0].dueAt);

      if (earliestDeadlineDueAt.diff(planCreatedAt, 'weeks') <= 12) {
        chunksToReturn = [buildWarningChunk(), ...chunksToReturn];
      }
    }

    return chunksToReturn;
  };

  const fetchMoreChunks = () => {
    return q.fetchMoreChunksMutation.mutate(
      {
        cycle: currentProfile.applicationCycle,
        filters: filters,
        numberOfChunks: 2,
        startingAt: currentChunkCount()
      },
      {
        onSuccess: (result) => {
          if (result.length < 2) {
            setHasLoadedAllChunks(true);
          }
        }
      }
    );
  };

  const handleFiltersChanged = (updatedFilters: ApplicationCycleFilters) => {
    updateDashboardPathWithParams({ filters: updatedFilters });
  };

  const handleSaveDeadline = (deadline: ApplicationCycleDeadline) => {
    return new Promise((resolve, reject) => {
      q.updateOrCreateApplicationCycleDeadlineMutation.mutate({
        cycle: props.plan.applicationCycle,
        deadline
      }, {
        onSuccess: (result) => {
          return resolve(result.deadline);
        },
        onError: (error) => {
          return reject(error);
        }
      });
    });
  };

  const handleViewChanged = (view: string) => {
    updateDashboardPathWithParams({ view });
  };

  const isLoadedAndHasChunks = () => {
    return applicationCycleChunksQuery.data && applicationCycleChunksQuery.data.length > 0;
  };

  const isLoadedButHasNoChunks = () => {
    return applicationCycleChunksQuery.data && applicationCycleChunksQuery.data.length === 0;
  };

  const isLoadingInitialChunks = () => {
    return !applicationCycleChunksQuery.data;
  };

  const updateDashboardPathWithParams = (params) => {
    console.log(params)
    const queryParams = filtersURLSearchParams(params.filters || filters);
    queryParams.append('view', params.view || view);
    history.replace(`${paths.dashboard()}?${queryParams.toString()}`);
  };

  return (
    <div>
      {/*Header with content card*/}
      <div className="bg-white u-border-B-s">
        {/*On sm/xs, display content card above the title and full width*/}
        <div className="visible-sm visible-xs">
          {/*TODO*/}
          {/*<ContentCard className="u-padding-V-xs" />*/}
        </div>

        <div className="container">
          <div className="align-items-center d-flex u-padding-V-xs">
            <div style={{flexGrow: 1}}>
              <h3 className="u-margin-A-n">
                <strong>MBA Dashboard</strong>
              </h3>

              {/*On sm/xs, display highlighted content as a select dropdown*/}
              <div className="visible-sm visible-xs u-margin-T-s">
                <div><small>Quick Links</small></div>
                <HighlightedContentDropdown
                  highlightedContent={props.highlightedContent}
                  notesHref={props.notesHref}
                />
              </div>
            </div>

            <div className="hidden-sm hidden-xs">
              {/*TODO*/}
              {/*<ContentCard />*/}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/*Highlighted content cards*/}
        <div className="hidden-sm hidden-xs u-margin-V-m">
          <HighlightedContentCardSet
            highlightedContent={props.highlightedContent}
            notesHref={props.notesHref}
          />
          <hr />
        </div>

        {/*On sm/xs, tabs to switch between study schedule and application tracker*/}
        <div className="visible-sm visible-xs">
          <div className="u-margin-B-n u-margin-T-m">
            <TextTabButtons currentTab={view} onChange={handleViewChanged}>
              <TextTabButtons.Tab name={VIEW_APPLICATION_TRACKER}>Study Schedule</TextTabButtons.Tab>
              <TextTabButtons.Tab name={VIEW_STUDY_SCHEDULE}>Application Tracker</TextTabButtons.Tab>
            </TextTabButtons>

            <hr className="u-margin-V-xs" />
          </div>
        </div>

        {/*Column titles*/}
        <div className="hidden-sm hidden-xs">
          <div className="d-flex" style={{ gap: 40 }}>
            {
              props.studySchedule && (
                <div className={style.constrainedWidth}>
                  <h3 className="u-margin-A-n">Study Schedule</h3>
                  <hr className="u-margin-V-xs" />
                </div>
              )
            }

            <div className="flex-1">
              <div className="align-items-center d-flex">
                <div className="flex-1">
                  <h3 className="u-margin-A-n">Application Tracker</h3>
                </div>

                <EditableApplicationCycle applicationCycle={props.plan?.applicationCycle} />
              </div>

              <hr className="u-margin-V-xs" />
            </div>
          </div>
        </div>

        <div className="d-flex" style={{ gap: 40 }}>
          {
            props.studySchedule && (
              <div className={cx(style.constrainedWidth, { 'hidden-sm hidden-xs': view !== VIEW_APPLICATION_TRACKER })}>
                <Card>
                  <div className={style.studyScheduleOverrides}>
                    <StudySchedule {...props.studySchedule} />
                  </div>
                </Card>
              </div>
            )
          }

          <div
            className={cx('d-flex flex-1 flex-column', { 'hidden-sm hidden-xs': view !== VIEW_STUDY_SCHEDULE })}
            style={{ maxWidth: props.studySchedule ? 730 : 1140, width: '100%' }}
          >
            <Card>
              <ApplicationTracker
                applicationCycle={props.plan.applicationCycle}
                deadlines={props.plan.deadlines}
                onSaveDeadline={handleSaveDeadline}
              />
            </Card>

            {
              !!props.plan.itemCounts.pastDue && (
                <PastDueItemsAlert
                  className="u-margin-T-s"
                  onClick={() => handleFiltersChanged({ focus: 'Past Due Tasks' })}
                />
              )
            }

            <div className="u-margin-T-l">
              <div className="u-margin-A-n" style={{ fontSize: 20 }}>Tasks</div>
              <hr className="u-margin-V-xs" />

              <Filters
                filters={filters as FilterValues}
                filtersConfig={filtersConfig}
                key={JSON.stringify(filtersConfig)}
                onChange={handleFiltersChanged}
              />

              <div className="u-margin-T-m">
                {
                  isLoadingInitialChunks() && (
                    <StatusMessage headline="Loading Your Tasks" text={null} isColored />
                  )
                }
                {
                  isLoadedButHasNoChunks() && (
                    <StatusMessage
                      headline="No Tasks to Display"
                      text="Adjust your filters to see more of your tasks."
                    />
                  )
                }
                {/*TODO - empty and complete*/}
                {
                  isLoadedAndHasChunks() && (
                    <>
                      <ApplicationCycleChunks
                        applicationCycle={currentProfile.applicationCycle}
                        chunks={chunks()}
                      />

                      {
                        !hasLoadedAllChunks && (
                          <div className="text-center u-margin-T-l">
                            <DefaultButton
                              onClick={fetchMoreChunks}
                              submitting={q.fetchMoreChunksMutation.isLoading}>
                              See More
                            </DefaultButton>
                          </div>
                        )
                      }
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MBAAdmissionsDashboard;
