import * as React from 'react';
import { Col, MenuItem, Nav, NavItem, Row, Tab, Dropdown } from 'react-bootstrap';
import style from './style.module.scss';
import ModuleCard from './module_card';
import { ModuleGoals } from './module_goals';
import { ModuleProgressSummary } from './module_progress_summary';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import { LessonModuleData, ModuleTrack, ModuleGoalData } from './types';
import { LinkButton } from '@magoosh/lib/button';

interface State {
  selectedExamSection: string;
  selectedModuleId: number;
  dropdownDisabled: boolean;
}

interface Props extends LessonModuleData, ModuleGoalData {
  setItemCompleted: (number, boolean) => void;
}

export default class LessonModulesTemplate extends React.Component<Props, State> {
  cardRefs: any;
  constructor(props) {
    super(props);
    this.state = {
      selectedExamSection: this.props.selectedTab || this.props.studyModuleTracks[0].examSection,
      dropdownDisabled: false,
      selectedModuleId: this.props.selectedModuleId
    };
    this.cardRefs = props.studyModuleTracks.reduce((cardRefs, track) => {
      const trackRefs = track.modules.reduce((trackRefs, module) => {
        trackRefs[module.id] = React.createRef();
        return trackRefs;
      }, {});
      return { ...cardRefs, ...trackRefs };
    }, {});
  }

  componentDidMount() {
    this.moduleCardFocus();
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedModuleId !== this.state.selectedModuleId) {
      this.moduleCardFocus();
    }
  }

  render() {
    const { studyModuleTracks } = this.props;

    return (
      <React.Fragment>
        <div className="lesson-header bg-white u-margin-B-l">
          <div className="container u-margin-T-m">
            <div className="d-flex justify-content-between u-margin-B-m">
              <h2 className="h3-small u-margin-A-n">Lessons</h2>
            </div>
          </div>
        </div>
        <div className="container container-app">
          <Tab.Container activeKey={this.state.selectedExamSection} onSelect={this.onSectionModuleSelect}>
            <Row>
              <Col sm={4} smPush={8} xsHidden={true}>
                <ModuleGoals
                  moduleGoals={this.props.moduleGoals}
                  numCompletedModules={this.props.numCompletedModules}
                  showPracticeTestLink={this.props.showPracticeTestLink}
                  practiceTestCompleted={this.props.practiceTestCompleted}
                  mobileFixedItems={3}
                  desktopFixedItems={5}
                  onModuleClick={this.onSectionModuleSelect}
                />
                <ModuleProgressSummary progresses={this.props.progresses} />
              </Col>
              <Col sm={8} smPull={4}>
                <Row>
                  <Col xs={12} sm={8}>
                    {this.renderNav(studyModuleTracks)}
                  </Col>
                  <Col xs={12} sm={4} md={4}>
                    {this.shouldRenderTrackPickerForExamSection(this.state.selectedExamSection) &&
                      this.renderTrackPicker(studyModuleTracks)}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Tab.Content>
                      {studyModuleTracks.map((track) => (
                        <Tab.Pane
                          key={track.examSection}
                          eventKey={track.examSection}
                          onEntered={this.moduleCardFocus}>
                          <div className={style.moduleTrack}>
                            <div className={style.modules}>
                              {track.modules.map((module) => {
                                return (
                                  <div
                                    key={module.id}
                                    ref={this.cardRefs[module.id]}
                                    className={style.moduleCard}>
                                    <ModuleCard
                                      setItemCompleted={this.props.setItemCompleted}
                                      branding={'gre'}
                                      {...module}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </React.Fragment>
    );
  }

  renderNav = (studyModuleTracks: ModuleTrack[]) => {
    return (
      <div className={style.examSectionTabs}>
        <Nav>
          {studyModuleTracks.map((track) => (
            <NavItem key={track.examSection} eventKey={track.examSection} className={style.examSectionTab}>
              {track.examSection}
            </NavItem>
          ))}
        </Nav>
      </div>
    );
  };

  renderTrackPicker = (studyModuleTracks: ModuleTrack[]) => {
    const track = studyModuleTracks.find((track) => track.examSection === this.state.selectedExamSection);

    return (
      <div className={style.moduleTrackOptions}>
        <Dropdown
          disabled={this.state.dropdownDisabled}
          id={`${this.state.selectedExamSection}Difficulty`}
          pullRight
          title={`${this.state.selectedExamSection} Level: ${track.difficulty}`}
          onSelect={this.onDifficultySelect}>
          <Dropdown.Toggle className={style.toggleWithoutBorders}>
            <LinkButton className={style.btnGrayLink}>
              {`${this.state.selectedExamSection} Level: ${track.difficulty}`}{' '}
            </LinkButton>
          </Dropdown.Toggle>
          <Dropdown.Menu className="super-colors">
            {['beginner', 'intermediate', 'advanced'].map((menuItemDifficulty) => (
              <MenuItem
                key={menuItemDifficulty}
                eventKey={menuItemDifficulty}
                className={track.difficulty == menuItemDifficulty ? style.selected : ''}>
                <span className={style.difficultyLabel}>{menuItemDifficulty}</span>
              </MenuItem>
            ))}
            <MenuItem className={style.difficultyInfo} header>
              Your progress will be saved even if you switch levels.
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  onSectionModuleSelect = (examSection, moduleId = null) => {
    this.setState({ selectedExamSection: examSection, selectedModuleId: moduleId });
  };

  onDifficultySelect = (difficulty) => {
    this.setState({ dropdownDisabled: true }, () => {
      fetch(paths.api.setModuleTrack(), {
        method: 'POST',
        body: JSON.stringify({
          examSectionName: this.state.selectedExamSection,
          track: difficulty
        })
      }).then(() => {
        window.location.href = `${paths.lessons()}?tab=${this.state.selectedExamSection}`;
      });
    });
  };

  onScroll = () => {
    this.setState({
      ...this.state,
      selectedModuleId: 0
    });
  };

  moduleCardFocus = () => {
    if (this.state.selectedModuleId && this.cardRefs[this.state.selectedModuleId]) {
      this.cardRefs[this.state.selectedModuleId].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  shouldRenderTrackPickerForExamSection = (examSection) => {
    return examSection === 'Math' || examSection === 'Verbal';
  };
}
