import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';

import CardLayout from '@magoosh/layouts/card';
import { InfoAlert, SuccessAlert, WarningAlert } from '@magoosh/lib/alerts';
import { DefaultButton, PrimaryButton, SecondaryButton } from '@magoosh/lib/button';
import { HandsHoldingPlantIcon } from '@magoosh/lib/icons';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import useSearchQuery from '@magoosh/utils/use_search_query';
import { paths } from 'config/path_helpers';
import { pluralize } from 'utilities/pluralize';

import { useAppContext } from '@test_prep/app/context';
import { useQueries } from '@test_prep/app/queries';
import ContextualLink from '@test_prep/app/components/contextual_link';

import DescriptionContent from './description_content';
import DetailsContent from './details_content';
import ScoreBadge from './score_badge';
import SectionOptionsSelect from './section_options_select';
import TemplateRadioGroup from './template_radio_group';
import UpsellCard from '../components/upsell_card';
import { Template } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface PageProps {
  currentTest: {
    currentPracticeSession: {
      id: number;
    };
    id: number;
    template: {
      name: string;
    };
  };
  entitlements: {
    limit: boolean | number;
    limitReached: boolean;
  };
  previousTestResults: {
    count: number;
    mostRecent: {
      id: number;
      score: string;
      scoredExamSections: {
        name: string;
        score: {
          reason: string;
          value: string;
        };
      }[];
      wasSingleSection: boolean;
    };
  };
  templates: Template[];
  ui: {
    saleMessage: string;
    scoredExamSections: string[];
  };
}

const NewMockTest: React.FC<PageProps> = (props) => {
  const authenticityToken = useAuthenticityToken();
  const { branding } = useAppContext().data;
  const q = useQueries();
  const interaction = useSearchQuery()['interaction'] || null;

  const page = q.pageQuery(paths.practiceTestsStart()).data as PageProps;

  const breadcrumbs = [
    { name: 'Home', path: paths.dashboard() },
    { name: 'Practice Test' }
  ];

  const [currentTemplate, setCurrentTemplate] = useState(page ? page.templates[0] : null);
  const [currentSectionOption, setCurrentSectionOption] = useState(page ? page.templates[0].sectionOptions[0] : null);
  useEffect(() => {
    if (!page) return;
    if (!currentTemplate) {
      setCurrentTemplate(page.templates[0]);
    };

    if (!currentSectionOption && page.templates[0]) {
      setCurrentSectionOption(page.templates[0].sectionOptions[0])
    }
  }, [page]);

  const continueCurrentTestPath = () => {
    if (!page?.currentTest) return;

    if (page.currentTest.currentPracticeSession) {
      return paths.practiceSession(page.currentTest.currentPracticeSession.id);
    } else {
      return paths.practiceTestStartSection(page.currentTest.id);
    }
  };

  const renderInfoAlerts = () => {
    const alerts = [];

    if ( interaction === 'section-test') {
      alerts.push(
        <InfoAlert>
          <strong>Section Tests</strong>: You can start a section test by choosing a specific section in the "Section Options" dropdown below.
        </InfoAlert>
      );
    }

    if (page.entitlements.limit && page.entitlements.limitReached) {
      alerts.push(
        <InfoAlert>
          You've reached the limit for practice tests with you current subscription. To get full access <ContextualLink className="text-info" href={paths.plans()}>upgrade your subscription</ContextualLink>.
        </InfoAlert>
      );
    } else if (page.entitlements.limit) {
      alerts.push(
        <InfoAlert>
          <strong>Limit</strong>: You can take {pluralize(page.entitlements.limit, 'practice test')} with you current plan. To get full access <ContextualLink className="text-info" href={paths.plans()}>upgrade your subscription</ContextualLink>.
        </InfoAlert>
      );
    }

    if (page.currentTest) {
      alerts.push(
        <InfoAlert>
          <strong>You're in the middle of a practice test</strong>. Click "Continue Practice Test" below to finish and get your scores.
        </InfoAlert>
      );
    }

    if (alerts.length) {
      return (
        <>
          <hr />

          {
            alerts.map((alert, idx) => (
              <div key={idx} className="u-margin-T-s">
                {alert}
              </div>
            ))
          }
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Practice Test - Magoosh {branding.examName}</title>
      </Helmet>

      <CardLayout breadcrumbs={breadcrumbs} linkComponent={ContextualLink} loading={!page}>
        {({ loading }) => {
          if (loading || !currentTemplate) return;

          const availableTemplates = page.templates.filter((template) => template.available);
          const showSectionOptions = page.templates.some((template) => template.sectionOptions.length > 1) && !page.currentTest;
          const showTemplateOptions = page.templates.length > 1 && !page.currentTest;
          const showUpsell = !availableTemplates.length;

          return (
            <>
              {
                showUpsell && (
                  <WarningAlert className="u-margin-V-s">
                    Magoosh practice tests require that you have a Premium subscription. To take this test, <ContextualLink className="text-body" href={paths.plans()}>upgrade your subscription</ContextualLink>.
                  </WarningAlert>
                )
              }

              <div className="d-flex flex-column flex-lg-row gap-m">
                <div className="d-flex flex-1 flex-column gap-m">
                  <CardLayout.Card>
                    <h1 className="h2 u-margin-A-n u-padding-B-xxs" style={{ lineHeight: 1.3 }}>
                      <strong>{branding.examName} Practice Test</strong>
                    </h1>

                    {currentTemplate && <div><small>Time <strong>{currentTemplate.estimatedLength}</strong></small></div>}

                    <div className="u-margin-T-s">
                      <DescriptionContent examName={branding.examName} />
                    </div>

                    {renderInfoAlerts()}

                    {
                      showTemplateOptions && (
                        <>
                          <hr />
                          <div className="u-margin-T-s">
                            <TemplateRadioGroup
                              currentTemplate={currentTemplate}
                              onChange={(template) => {
                                setCurrentTemplate(template)
                                setCurrentSectionOption(template.sectionOptions[0])
                              }}
                              templates={page.templates}
                            />
                          </div>
                        </>
                      )
                    }

                    {
                      showSectionOptions && (
                        <div className="u-margin-T-s">
                          <SectionOptionsSelect
                            currentSectionOption={currentSectionOption}
                            currentTemplate={currentTemplate}
                            onChange={(sectionOption) => setCurrentSectionOption(sectionOption)}
                          />
                        </div>
                      )
                    }

                    {
                      (showTemplateOptions || showSectionOptions) && (
                        <div>
                          <small>
                            Your selected test will take about {(currentSectionOption || currentTemplate)?.estimatedLength}
                          </small>
                        </div>
                      )
                    }

                    <hr />

                    <div className="u-margin-T-s">
                      <DetailsContent examName={branding.examName} />
                    </div>

                    <hr />

                    <div className="d-flex u-margin-T-s">
                      {
                        page.currentTest ? (
                          <ContextualLink href={continueCurrentTestPath()}>
                            <SecondaryButton className="flex-1 flex-sm-none">
                              Continue Practice Test
                            </SecondaryButton>
                          </ContextualLink>
                        ) : (
                          <form action={paths.practiceTests()} className="d-flex flex-1 flex-sm-none" method="post">
                            <input type="hidden" name="authenticity_token" value={authenticityToken} />

                            {
                              currentSectionOption ? (
                                currentSectionOption.params.map((param) => (
                                  <input key={param[0]} type="hidden" name={param[0]} value={param[1]} />
                                ))
                              ) : (
                                <input type="hidden" name="mock_test[template_id]" value={currentTemplate?.id} />
                              )
                            }

                            <SecondaryButton
                              className="flex-1 flex-sm-none"
                              disabled={!currentTemplate?.available}
                              type="submit"
                            >
                              Start Practice Test
                            </SecondaryButton>
                          </form>
                        )
                      }
                    </div>
                  </CardLayout.Card>
                </div>

                <div className={cx(style.rightColumn, 'd-flex flex-column-reverse flex-sm-column gap-m')}>
                  {
                    showUpsell && (
                      <UpsellCard examName={branding.examName} saleMessage={page.ui.saleMessage} />
                    )
                  }

                  <CardLayout.Card>
                    <div>
                      <h4 className="u-margin-T-n">Your Test Results</h4>

                      <ScoreBadge
                        examName={branding.examName}
                        previousTestResult={page.previousTestResults.mostRecent}
                        scoredExamSections={page.ui.scoredExamSections}
                      />

                      {(() => {
                        if (page.currentTest) {
                          return (
                            <div>
                              <p><small>You're in the middle of a test. Finish to get your up-to-date scores.</small></p>
                              <ContextualLink href={continueCurrentTestPath()}>
                                <SecondaryButton size="sm">Continue Test</SecondaryButton>
                              </ContextualLink>
                            </div>
                          );
                        } else if (page.previousTestResults.mostRecent) {
                          return (
                            <div>
                              <p><small>This is your most recent score. Review your answers to see what you got wrong.</small></p>
                              <ContextualLink href={paths.practiceTest(page.previousTestResults.mostRecent.id)}>
                                <DefaultButton size="sm">Review Test Results</DefaultButton>
                              </ContextualLink>
                            </div>
                          );
                        } else {
                          return (
                            <p><small>Complete the test to get your score estimate.</small></p>
                          );
                        }
                      })()}
                    </div>

                    {
                      page.previousTestResults.count !== 0 && (
                        <>
                          <hr />

                          <div>
                            <h4 className="u-margin-T-n">Past Test Results</h4>

                            <p><small>You have finished this test {pluralize(page.previousTestResults.count, 'time')}. Look at your old scores to see where you've improved.</small></p>
                            <ContextualLink href={paths.practiceTests()}>
                              <DefaultButton size="sm">See Past Test Scores</DefaultButton>
                            </ContextualLink>
                          </div>
                        </>
                      )
                    }
                  </CardLayout.Card>
                </div>
              </div>
            </>
          )
        }}
      </CardLayout>
    </>
  );
};

export default NewMockTest;
