import React from 'react';
import cx from 'classnames';

import style from './style.module.scss';

interface Props {
  children: any;
  size?: 'sm' | 'md';
}

const GradientPill: React.FC<Props> = (props) => {
  return (
    <div style={{ position: 'relative', zIndex: 1 }}>
      <div className={cx(style.gradientPill, { [style.small]: props.size === 'sm' })}>
        <div
          className="text-bold"
          style={{
            background: '-webkit-linear-gradient(135deg, #26B67C, #9B51E0)',
            fontSize: props.size === 'sm' ? 14 : 16,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default GradientPill;
