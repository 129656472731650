import * as React from 'react';
import { adminPaths } from 'config/path_helpers';
import { pluralize } from 'utilities/pluralize';
import { DangerButton, InfoButton } from '@magoosh/lib/button';

interface Props {
  id: number;
  profileName: string;
  profilePictureId: number;
  transcriptId: number;
  branding: string;
  accessLevel: {
    level: number;
    name: string;
  };
  ratingSummary: {
    total: number;
    likeRatio: number;
  };
}

const ApplicantProfileAdminShow: React.FC<Props> = (props: Props) => {
  const [showProfilePictureUpload, setShowProfilePictureUpload] = React.useState(false);
  const [showTranscriptUpload, setShowTranscriptUpload] = React.useState(false);

  const onChangeAttachment = (event) => {
    const formData = new FormData();
    formData.append('attachment', event.currentTarget.files[0]);
    fetch(adminPaths.api.applicantProfileAssets(props.id), {
      method: 'PUT',
      body: formData
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onClickDeleteAsset = (assetId: number) => {
    fetch(adminPaths.api.applicantProfileDeleteAsset(props.id, assetId), {
      method: 'DELETE'
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div>
      <h1>Manage Applicant Profile: {props.profileName || props.id}</h1>
      <h4>Branding:</h4>
      <p>{props.branding}</p>
      <h4>Access Level:</h4>
      <p>{props.accessLevel.name}</p>
      <h4>Ratings</h4>
      <div>
        <p>
          This writing example has{' '}
          <a href={adminPaths.applicantProfileRatings(props.id)}>
            {pluralize(props.ratingSummary.total, 'rating', 'ratings')}
          </a>
          , with a like ratio of {props.ratingSummary.likeRatio}.
        </p>
      </div>

      <div className={'u-margin-B-xl'}>
        {props.profilePictureId ? (
          <DangerButton className={'u-margin-R-m'} onClick={() => onClickDeleteAsset(props.profilePictureId)}>
            Delete Profile Picture
          </DangerButton>
        ) : showProfilePictureUpload ? (
          <label>
            Add Profile Picture
            <input
              type="file"
              accept={'image/png,image/jpg,image/gif,image/jpeg,image/svg'}
              onChange={onChangeAttachment}
            />
          </label>
        ) : (
          <InfoButton className={'u-margin-R-m'} onClick={() => setShowProfilePictureUpload(true)}>
            Add Profile Picture
          </InfoButton>
        )}

        {props.transcriptId ? (
          <DangerButton className={'u-margin-R-m'} onClick={() => onClickDeleteAsset(props.transcriptId)}>
            Delete Transcript PDF
          </DangerButton>
        ) : showTranscriptUpload ? (
          <label>
            Add Transcript PDF
            <input type="file" accept={'application/pdf'} onChange={onChangeAttachment} />
          </label>
        ) : (
          <InfoButton className={'u-margin-R-m'} onClick={() => setShowTranscriptUpload(true)}>
            Add Transcript PDF
          </InfoButton>
        )}

        <a className={'btn btn-success u-margin-R-m'} href={adminPaths.applicantProfileEdit(props.id)}>
          Edit
        </a>
        <a className={'btn btn-default'} href={adminPaths.applicantProfiles()}>
          Back to List
        </a>
      </div>
    </div>
  );
};

export default ApplicantProfileAdminShow;
