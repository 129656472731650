import React from 'react';

import ContextualLink from '@test_prep/app/components/contextual_link';

import StatisticData from '../statistic_data';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  filters: any;
}

const QuestionsStat: React.FC<Props> = (props) => {
  const loadingPlaceholder = (
    <QuestionsContent color={colors.gray85} data={null} />
  );

  if (!props.filters) {
    return loadingPlaceholder;
  } else {
    return (
      <StatisticData
        filters={props.filters}
        name="questions"
      >
        <StatisticData.Loading>
          {loadingPlaceholder}
        </StatisticData.Loading>

        {/* @ts-ignore */}
        <QuestionsContent color={colors.dataDarkPurple} />
      </StatisticData>
    );
  }
};

interface QuestionsContentProps {
  color: string;
  data: {
    reviewPath: string;
    value: number;
  };
}

const QuestionsContent: React.FC<QuestionsContentProps> = (props) => {
  return (
    <div className="align-items-center d-flex flex-column gap-xs">
      <div style={{ color: props.color, lineHeight: 1 }}>
        <span style={{ fontSize: 36 }}>
          <strong>{props.data?.value ? props.data.value : '-'}{' '}</strong>
        </span>

        <span style={{ fontSize: 24 }}>
          <strong>Answered</strong>
        </span>
      </div>

      {
        !!props.data?.value && !!props.data?.reviewPath && (
          <div>
            <small>
              <ContextualLink className="text-gray-light" href={props.data.reviewPath}>
                Review these questions
              </ContextualLink>
            </small>
          </div>
        )
      }
    </div>
  );
};

export default QuestionsStat;
