import React, { useLayoutEffect, useRef, useState } from 'react';
import { isEqual, isNil, mapKeys, omit, omitBy } from 'lodash';

import { useModalContext } from '@magoosh/context/modal_context';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

import ActiveFilters from './active_filters';
import ButtonFilters from './button_filters';
import FocusFilter from './focus_filter';
import ModalFilters from './modal_filters';
import { FilterConfig } from './types';

interface Props {
  filters: { [key: string]: string };
  filtersConfig: FilterConfig[];
  onChange: (updatedFilters: { [key: string]: string }) => void;
}

const Filters: React.FC<Props> = (props) => {
  const { setModal } = useModalContext();
  const buttonFiltersRef = useRef(null);

  const [overflowAmount, setOverflowAmount] = useState(0);

  useLayoutEffect(() => {
    const element = buttonFiltersRef.current;
    if (!element) return;

    setOverflowAmount(element.scrollWidth - element.offsetWidth);
  }, [props.filtersConfig]);

  const handleFiltersChanged = (updatedFilters: { [key: string]: string }) => {
    return props.onChange(updatedFilters);
  };

  const presentFiltersModal = () => {
    setModal({
      header: 'Filters',
      body: <ModalFilters
        filters={props.filters}
        filtersConfig={props.filtersConfig}
        onSave={handleFiltersChanged}
        onClose={() => setModal(null)}
      />
    });
  };

  return (
    <>
      {/* On sm/xs, show active filters and a button to launch the filter selection modal */}
      <div className="visible-sm visible-xs">
        <div className="d-flex flex-row" style={{ gap: 10 }}>
          <div className="flex-1">
            <ActiveFilters
              filters={props.filters || {}}
              filtersConfig={props.filtersConfig}
              focused={!!props.filters?.focus}
              onShowCollapsed={presentFiltersModal}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column" style={{ gap: 10 }}>
        <div className="hidden-sm hidden-xs" ref={buttonFiltersRef}>
          <ButtonFilters
            collapseBy={overflowAmount}
            filters={props.filters || {}}
            focused={!!props.filters?.focus}
            filtersConfig={props.filtersConfig}
            onChange={handleFiltersChanged}
            onShowCollapsed={presentFiltersModal}
          />
        </div>

        {
          props.filters?.focus && (
            <div className="d-flex flex-row" style={{ gap: 10 }}>
              <FocusFilter
                onClear={() => handleFiltersChanged(omit(props.filters, 'focus'))}
                value={props.filters.focus}
              />
            </div>
          )
        }
      </div>
    </>
  );
};

export const filtersURLSearchParams = (filters) => {
  // TODO - this also happens in path_helpers - consolidate the logic!
  const filtersWithNullsRemoved = omitBy(filters, isNil);
  const filtersForUrl = mapKeys(filtersWithNullsRemoved, (value, key) => `filters[${key}]`);

  return new URLSearchParams({ ...filtersForUrl });
};

export default Filters;
