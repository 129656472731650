import * as React from 'react';
import {Modal as BootstrapModal} from 'react-bootstrap';
import cx from 'classnames';

import style from './style.module.scss';
import { ReactElement } from 'react';

export interface ModalProps {
  backdrop?: boolean | 'static';
  children: React.ReactNode;
  className?: string;
  dialogClassName?: string;
  title?: string;
  show?: boolean;
  onHide?: () => any;
  showCloseButton?: boolean;
}

const Footer = () => null;

export default class Modal extends React.Component<ModalProps> {
  static Footer: Function;

  render() {
    return (
      <BootstrapModal
        backdrop={this.props.backdrop || 'static'}
        className={cx(style.modalForm, this.props.className)}
        dialogClassName={this.props.dialogClassName}
        onHide={this.props.onHide}
        show={this.props.show}>
        {this.renderHeader()}
        <BootstrapModal.Body>
          {this.props.children}
        </BootstrapModal.Body>
        {this.renderFooter()}
      </BootstrapModal>
    )
  }

  renderHeader() {
    if (!this.props.title && !this.props.showCloseButton) { return null; }

    return (
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{this.props.title}</BootstrapModal.Title>
      </BootstrapModal.Header>
    )
  }

  renderFooter() {
    const footers = React.Children.toArray(this.props.children).filter(child => {
      return React.isValidElement(child) && child.type == Footer;
    });

    if (footers.length == 1) {
      return <BootstrapModal.Footer>
        {(footers[0] as ReactElement).props.children}
      </BootstrapModal.Footer>
    }

    return null;
  }
}

Footer.displayName = 'Footer';
Modal.Footer = Footer;
