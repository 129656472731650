import style from './style.module.scss';
import ChildBlock from '../child_block/template';
import EditableFields from '../editable_fields/';
import { getPermissionsByBlockType, humanizedBlockType } from '../helpers';
import OrderableListItem from '../orderable_list_item';
import { ContentBlock, ContentBlockEditorData, ContentBlockTypes } from '../types';
import { DangerButton, InfoButton, PrimaryButton } from '@magoosh/lib/button';
import cx from 'classnames';
import { adminPaths } from 'config/path_helpers';
import * as React from 'react';
import fetch from 'utilities/fetch';
import { FormikHelpers as FormikActions } from 'formik';
import ErrorBoundary from '@magoosh/utils/error_boundary';
import DropdownList from 'react-widgets/lib/DropdownList';
import StudyScheduleUploadModal from '@magoosh/admin/content_block_editor/study_schedule_upload_modal';
import Modal from '@magoosh/lib/modal';
import BlockCopyForm from '@magoosh/admin/content_block_editor/parent_block/copy_form';
import BlockPreviewForm from '@magoosh/admin/content_block_editor/parent_block/preview_form';

interface State {
  block: ContentBlock;
  isLoading: boolean;
  showDropdown: boolean;
  showCopyModal: boolean;
  showConfirmCopyModal: boolean;
  showConfirmPreviewModal: boolean;
  selectedType: any;
  showStudyScheduleUploadModal: boolean;
}

const contentBlockTypes = [
  { name: 'Assignment Block', type: 'ContentBlock::AssignmentBlock' },
  { name: 'Sectioned Assignment Block', type: 'ContentBlock::SectionedAssignmentBlock' },
  { name: 'Assessment Block', type: 'ContentBlock::AssessmentBlock' },
  { name: 'Assignment Group', type: 'ContentBlock::AssignmentGroup' },
  { name: 'Container', type: 'ContentBlock::Container' },
  { name: 'Diagnostic Section Block', type: 'ContentBlock::DiagnosticSectionBlock' },
  { name: 'Diagnostic Test Block', type: 'ContentBlock::DiagnosticTestBlock' },
  { name: 'Drill', type: 'ContentBlock::DrillBlock' },
  { name: 'Admissions External Resource', type: 'ContentBlock::AdmissionsExternalResourceBlock' },
  { name: 'External Resource', type: 'ContentBlock::ExternalResourceBlock' },
   { name: 'Flashcard Deck', type: 'ContentBlock::FlashcardsDeckBlock' },
  { name: 'JSON Payload', type: 'ContentBlock::JSONBlock' },
  { name: 'Learning Session', type: 'ContentBlock::LearningSessionBlock' },
  { name: 'Admissions Lesson', type: 'ContentBlock::AdmissionsLessonBlock' },
  { name: 'Lesson', type: 'ContentBlock::LessonBlock' },
  { name: 'Lesson Series', type: 'ContentBlock::LessonSeriesBlock' },
  { name: 'Lesson Series', type: 'ContentBlock::LessonSeriesLinkBlock' },
  { name: 'Mock Test Section', type: 'ContentBlock::MockTestSectionBlock' },
  { name: 'Official Questions Section', type: 'ContentBlock::OfficialQuestionsSectionBlock' },
  { name: 'Practice Session', type: 'ContentBlock::PracticeSessionBlock' },
  { name: 'Review', type: 'ContentBlock::ReviewBlock' },
  { name: 'Study Module', type: 'ContentBlock::StudyModuleBlock' },
  { name: 'Study Schedule', type: 'ContentBlock::StudyScheduleBlock' },
  { name: 'Subject Mastery', type: 'ContentBlock::SubjectMasteryBlock' },
  { name: 'Subject Mastery Group', type: 'ContentBlock::SubjectMasteryGroup' },
  { name: 'Subject Mastery Root', type: 'ContentBlock::SubjectMasteryRoot' },
  { name: 'Rich Text Block', type: 'ContentBlock::RichTextBlock' },
  { name: 'Writing Example Block', type: 'ContentBlock::WritingExampleBlock' },
  { name: 'Guided Experience Block', type: 'ContentBlock::GuidedExperienceBlock' },
  { name: 'Guided Experience Section', type: 'ContentBlock::GuidedExperienceSectionBlock' },
  { name: 'Guided Experience Step', type: 'ContentBlock::GuidedExperienceStepBlock' },
  { name: 'Guided Experience Writing Block', type: 'ContentBlock::GuidedExperienceWritingBlock' },
  { name: 'Admissions Application Cycle Plan Block', type: 'ContentBlock::Admissions::ApplicationCycles::PlanBlock' },
  { name: 'Admissions Section Block', type: 'ContentBlock::Admissions::ApplicationCycles::SectionBlock' },
  { name: 'Admissions Segment Block', type: 'ContentBlock::Admissions::ApplicationCycles::SegmentBlock' },
  { name: 'Admissions Segment With Deadline Block', type: 'ContentBlock::Admissions::ApplicationCycles::SegmentWithDeadlineBlock' }
];

export default class ParentBlock extends React.Component<ContentBlockEditorData, State> {
  state = {
    block: this.props.contentBlock,
    isLoading: false,
    showDropdown: false,
    showCopyModal: false,
    showConfirmCopyModal: false,
    showConfirmPreviewModal: false,
    selectedType: { name: '', type: '' },
    showStudyScheduleUploadModal: false
  };

  componentDidMount() {
    // This page dynamically loads content blocks via async requests, so the browser's history is
    // manually maintained by storing the content block ids when navigation occurs. This stores the
    // initial content block id.
    history.replaceState(
      { contentBlockId: this.props.contentBlock.id },
      `Content Block ${this.props.contentBlock.id}`,
      adminPaths.editContentBlock(this.props.contentBlock.id)
    );

    window.addEventListener('popstate', this.handlePopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  handlePopState = (event) => {
    // Handles the browser having gone back a page, and loads the content block with the id stored
    // in the state history.
    this.loadBlockById(event.state.contentBlockId, false);
  };

  render() {
    const { block, isLoading } = this.state;

    return (
      <div className={cx(style.container, { [style.loading]: isLoading })}>
        <div className="d-flex justify-content-between">
          <a href={adminPaths.contentBlocks}>All Content Blocks</a>
          {block.hasMtt && <a href={adminPaths.moduleTrackTemplates()}>Module Track Templates</a>}
        </div>
        {this.state.block.ancestors.map((ancestor, idx) => (
          <div key={ancestor.id}>
            {'>'.repeat(idx + 1)}{' '}
            {this.state.block.id === ancestor.id ? (
              <span>
                {humanizedBlockType(ancestor.type)} #{ancestor.id}: {ancestor.name}
              </span>
            ) : (
              <a onClick={() => this.loadBlockById(ancestor.id)}>
                {humanizedBlockType(ancestor.type)} #{ancestor.id}: {ancestor.name}
              </a>
            )}
          </div>
        ))}
        <EditableFields config={this.props.config} block={block} key={block.id} />

        <ErrorBoundary>{this.renderChildBlocks()}</ErrorBoundary>
        <hr />

        <DangerButton onClick={this.deleteBlock}>Delete this block</DangerButton>
      </div>
    );
  }

  /**
   * UI to add, remove, and arrange child blocks
   */
  renderChildBlocks() {
    const block = this.state.block;
    const childBlocks = block.childBlocks || [];
    const permissions = getPermissionsByBlockType(block.type);
    const allowedTypes = this.allowedContentBlockTypes(block, permissions);

    return (
      <React.Fragment>
        <hr />
        <i>Immediate Children ({childBlocks.length}):</i>

        {childBlocks.map((c, index) => (
          <OrderableListItem
            key={c.id}
            canMoveUp={index !== 0}
            canMoveDown={childBlocks.length !== index+1}
            onMoveDown={() => this.moveBlock(c.id, 'down')}
            onMoveUp={() => this.moveBlock(c.id, 'up')}>
            <ChildBlock {...c} onEdit={() => this.loadBlockById(c.id)} />
          </OrderableListItem>
        ))}

        <div className="u-margin-T-m">
          {this.state.showDropdown && (
            <>
              <DropdownList
                data={allowedTypes}
                defaultValue={this.state.selectedType.type}
                filter="contains"
                textField="name"
                valueField="type"
                onChange={(value) => this.setState({ selectedType: value })}
              />

              <DangerButton onClick={this.resetDropdown}>Cancel</DangerButton>
            </>
          )}

          {allowedTypes.length !== 0 && (
            <PrimaryButton onClick={this.handleAddBtnClick}>Add {this.state.selectedType.name}</PrimaryButton>
          )}

          {block.type === 'ContentBlock::StudyScheduleBlock' && (
            <>
              <PrimaryButton onClick={this.handleCopyBtnClick}>Copy</PrimaryButton>
              <PrimaryButton onClick={this.handlePreviewBtnClick}>Preview</PrimaryButton>
            </>
          )}
          <Modal
            onHide={() => this.setState({ showConfirmPreviewModal: false })}
            show={this.state.showConfirmPreviewModal}
            title={'Preview Study Schedule'}
            showCloseButton={true}>
            <div>
              <BlockPreviewForm
                block={block}
                closeModal={() => this.setState({ showConfirmPreviewModal: false })}
              />
            </div>
          </Modal>

          {this.state.showCopyModal && (
            <Modal
              onHide={() => {
                this.setState({ showCopyModal: false });
                this.setState({ showConfirmCopyModal: false });
              }}
              show={this.state.showCopyModal}
              title={'Copy StudyScheduleBlock'}
              showCloseButton={true}>
              <div>
                {this.state.showConfirmCopyModal ? (
                  <>
                    <p>
                      New copies of the block and its children are being created. This process may take a few
                      minutes.
                    </p>
                    <PrimaryButton
                      className="u-margin-T-s"
                      onClick={() => {
                        this.setState({ showConfirmCopyModal: false });
                        this.setState({ showCopyModal: false });
                      }}>
                      Ok
                    </PrimaryButton>
                  </>
                ) : (
                  <BlockCopyForm
                    block={block}
                    showConfirm={() => this.setState({ showConfirmCopyModal: true })}
                  />
                )}
              </div>
            </Modal>
          )}
        </div>
        <div>
          {permissions.addStudyScheduleBlockChild && (
            <InfoButton onClick={() => this.setState({ showStudyScheduleUploadModal: true })}>
              Upload Study Schedule
            </InfoButton>
          )}
        </div>
        {this.state.showStudyScheduleUploadModal && (
          <StudyScheduleUploadModal
            parentId={this.state.block.id}
            showModal={this.state.showStudyScheduleUploadModal}
            onClose={() => this.setState({ showStudyScheduleUploadModal: false })}
            onSuccess={() => this.loadBlockById(this.state.block.id, false)}
          />
        )}
      </React.Fragment>
    );
  }

  handleAddBtnClick = () => {
    if (!this.state.showDropdown) {
      this.setState({ showDropdown: true });
    } else {
      this.createChildBlock(this.state.selectedType.type as ContentBlockTypes);
      this.resetDropdown();
    }
  };

  handleCopyBtnClick = () => {
    if (!this.state.showCopyModal) {
      this.setState({ showCopyModal: true });
    }
  };

  handlePreviewBtnClick = () => {
    if (!this.state.showConfirmPreviewModal) {
      this.setState({ showConfirmPreviewModal: true });
    }
  };

  resetDropdown = () => {
    this.setState({ selectedType: { name: '', type: '' } });
    this.setState({ showDropdown: false });
  };

  allowedContentBlockTypes = (block, permissions) => {
    const result = [];

    contentBlockTypes.forEach((type) => {
      let blockName = type.type.replace('ContentBlock::', '').replace(/::/g, '');
      const methodName = `add${blockName}Child`;

      // Fow now, Lesson Series blocks are only supported in a particular structure of content blocks
      // for MCAT Content Review.
      if (
        type.type === 'ContentBlock::LessonSeriesBlock' &&
        (block.ancestors.length !== 3 || block.ancestors[0].name !== 'MCAT Content Review')
      ) {
        return;
      }

      if (permissions[methodName]) result.push(type);
    });

    return result;
  };

  loadBlockById = (id, trackHistory = true) => {
    this.fetchThenSetBlock(adminPaths.api.showContentBlock(id)).then(() => {
      if (trackHistory) {
        history.pushState({ contentBlockId: id }, `Content Block ${id}`, adminPaths.editContentBlock(id));
      }
    });
  };

  moveBlock = (id, direction: 'up' | 'down') => {
    this.fetchThenSetBlock(adminPaths.api.moveContentBlock(id), {
      method: 'PATCH',
      body: JSON.stringify({ direction })
    });
  };

  createChildBlock = (type: ContentBlockTypes) => {
    this.fetchThenSetBlock(adminPaths.api.createContentBlock(), {
      method: 'POST',
      body: JSON.stringify({
        type,
        parentId: this.state.block.id
      })
    });
  };

  deleteBlock = () => {
    this.fetchThenSetBlock(adminPaths.api.destroyContentBlock(this.state.block.id), {
      method: 'DELETE'
    });
  };

  fetchThenSetBlock = (path, config = undefined, actions: FormikActions<ContentBlock> = null) => {
    this.setState({ isLoading: true });

    return fetch(path, config)
      .then((b) => {
        this.setState({ block: b, isLoading: false });
      })
      .catch((e) => {
        console.error(e);
        alert('Something went wrong! Try again or reach out to Eng for support.');
        this.setState({ isLoading: false });
      });
  };
}
