import colors from '@magoosh/lib/styles/colors.module.scss';

const brandColors = (brand) => {
  if (brand === 'emory-goizueta') {
    return {
      background: 'rgba(0, 125, 186, 0.1)',
      border: '#012169',
      button: {
        background: '#012169',
        color: '#ffffff'
      }
    };
  } else if (brand === 'nyu-stern') {
    return {
      background: 'rgba(87, 6, 140, 0.1)',
      border: 'rgb(87, 6, 140)',
      button: {
        background: 'rgb(87, 6, 140)',
        color: '#ffffff'
      }
    };
  } else {
    return {
      background: 'rgba(155, 81, 224, 0.1)',
      border: colors.brandSecondary,
      button: {
        background: colors.brandSecondary,
        color: '#ffffff'
      }
    };
  }
};

export default brandColors;
