import React from 'react';
import { NewInstructorFormTabProps, NewInstructorFormTabValues } from './types';
import style from './style.module.scss';
import cx from 'classnames';
import { DefaultButton } from '@magoosh/lib/button';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { FieldInput } from '@magoosh/lib/formik_inputs/field_input/template';

const initialValues = {
  email: '',
  firstName: '',
  lastName: ''
};

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required.'),
  firstName: yup.string().required('First Name is required.'),
  lastName: yup.string().required('Last Name is required.')
});

export const NewInstructorFormTab: React.FC<NewInstructorFormTabProps> = (props) => {
  const handleOnSubmit = (
    values: NewInstructorFormTabValues,
    { resetForm }: FormikActions<NewInstructorFormTabValues>
  ): void => {
    props.addNewInstructor(values);
    resetForm();
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
        {({ submitForm }) => (
          <>
            <Row>
              <Col md={12}>
                <FieldInput name="email" type="email" label="Email" />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FieldInput name="firstName" type="text" label="First Name" />
              </Col>
              <Col md={6}>
                <FieldInput name="lastName" type="text" label="Last Name" />
              </Col>
            </Row>
            <div className={cx(style.tabActions, style.end, 'u-margin-T-m')}>
              <DefaultButton onClick={submitForm}>
                <FontAwesomeIcon icon="plus-square" /> Add New Instructor
              </DefaultButton>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default NewInstructorFormTab;
