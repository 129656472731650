import React, { useEffect, useState } from 'react';

import { DangerAlert, HighlightAlert } from '@magoosh/lib/alerts';
import { FontAwesomeIcon, MagooshAIIcon } from '@magoosh/lib/icons';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  answerId: number;
  contentProducer: {
    name: string;
    producerType: 'ai' | 'human';
  };
  onFinished?: () => void;
  renderOnFinished?: boolean;
  status: 'new' | 'waiting' | 'processing' | 'finished' | 'error';
}

const GradingRequestStatusAlert: React.FC<Props> = (props) => {
  const authenticityToken = useAuthenticityToken();
  const [reportStatus, setReportStatus] = useState<string>(props.status);

  useEffect(() => {
    if (reportStatus === 'new' || reportStatus === 'finished' || reportStatus === 'error') return;

    const poller = setInterval(() => {
      fetch(paths.api.gradingRequestStatus(props.answerId)).then((result) => {
        if (result.gradingRequest.status !== reportStatus) {
          setReportStatus(result.gradingRequest.status);

          if (result.gradingRequest.status === 'finished' && props.onFinished) {
            props.onFinished();
          }

          if (result.gradingRequest.status === 'finished' || result.gradingRequest.status === 'error') {
            clearInterval(poller);
          }
        }
      });
    }, 5000);

    return () => clearInterval(poller)
  }, []);

  if (reportStatus === 'finished' && props.renderOnFinished) {
    return (
      <HighlightAlert>
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center" style={{ gap: 10 }}>
          {
            props.contentProducer.producerType === 'ai' ? (
              <>
                <div className="hidden-sm hidden-xs">
                  <MagooshAIIcon />
                </div>
                <div>
                  Your essay score report is ready!
                </div>
              </>
            ) : (
              <div>
                Your essay score report is ready!
              </div>
            )
          }

          <div className="d-flex flex-1 justify-content-end" style={{ gap: 10 }}>
            <a href={paths.answerScoreReport(props.answerId)} style={{ color: colors.purple }}>
              View Score Report
            </a>
          </div>
        </div>
      </HighlightAlert>
    );
  } else if(reportStatus === 'finished') {
    return null;
  } else if (reportStatus === 'error') {
    return (
      <DangerAlert>
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center" style={{ gap: 10 }}>
          <div>
            We ran into an issue grading your essay. Retry grading and if you are still having trouble, contact us at
            {' '}
            <a className="text-danger" href="mailto:help@magoosh.com">help@magoosh.com</a>.
          </div>

          <div className="d-flex flex-1 justify-content-end" style={{ gap: 10 }}>
            <form action={paths.answerRequestGrading(props.answerId)} method="post">
              <input name="authenticity_token" type="hidden" value={authenticityToken} />
              <input
                className="btn btn-link u-padding-A-n text-danger small text-underline"
                type="submit"
                value="Retry Grading"
              />
            </form>
          </div>
        </div>
      </DangerAlert>
    );
  } else if (reportStatus === 'new') {
    return (
      <HighlightAlert>
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center" style={{ gap: 10 }}>
          {
            props.contentProducer.producerType === 'ai' ? (
              <>
                <div className="hidden-sm hidden-xs">
                  <MagooshAIIcon />
                </div>
                <div>
                  The Magoosh AI Tutor can automatically grade your essay. This can take a minute or two.
                </div>
              </>
            ) : (
              <div>
                Magoosh experts can grade your essay. This can take between 24 and 48 hours.
              </div>
            )
          }

          <div className="d-flex flex-1 justify-content-end" style={{ gap: 10 }}>
            <form action={paths.answerRequestGrading(props.answerId)} method="post">
              <input name="authenticity_token" type="hidden" value={authenticityToken} />
              <input
                className="btn btn-link u-padding-A-n small text-underline"
                style={{ color: colors.purple }}
                type="submit"
                value="Request Grading"
              />
            </form>
          </div>
        </div>
      </HighlightAlert>
    );
  } else {
    return (
      <HighlightAlert>
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center" style={{ gap: 10 }}>
          {
            props.contentProducer.producerType === 'ai' ? (
              <>
                <div className="hidden-sm hidden-xs">
                  <MagooshAIIcon />
                </div>
                <div>
                  The Magoosh AI Tutor is grading your essay. This can take a minute or two.
                </div>
              </>
            ) : (
              <div>
                We're grading your essay. This can take between 24 and 48 hours.
              </div>
            )
          }

          <div className="d-flex align-items-center flex-1 align-self-start align-self-lg-center justify-content-lg-end small" style={{ gap: 10 }}>
            {reportStatus === 'waiting' ? 'Waiting to be Graded' : 'Grading in Progress'}
            <FontAwesomeIcon icon="spinner" className="fa-pulse" />
          </div>
        </div>
      </HighlightAlert>
    );
  }
};

export default GradingRequestStatusAlert;
