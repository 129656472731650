import * as React from 'react';

import { ModalContext } from '@magoosh/context/modal_context';
import PopupBanner from '@magoosh/lib/popup_banner';
import { paths } from 'config/path_helpers';
import { bugsnagNotify } from 'utilities/bugsnag';
import fetch from 'utilities/fetch';
import trackEvent from 'utilities/track_event';

import { StudyItemData } from '../study_items/types';
import * as api from '../study_schedule/api';
import { PracticePreviewData } from '../study_schedule/types';

export interface StudyItemListProps {
  studyItems?: StudyItemData[];
  setItemCompleted: (number, boolean) => void;
}

export interface StudyItemListState {
  practicePreview?: PracticePreviewData;
  errorMessage?: string;
}

export default class StudyItemList<
  P extends StudyItemListProps,
  S extends StudyItemListState
> extends React.Component<P, S> {
  static contextType = ModalContext;
  listType = 'StudyItemList';

  handleItemClick = (item: StudyItemData, completionCallback: () => void) => {
    trackEvent(`${this.listType} > Click Study Item`, {
      study_schedule_item_id: item.id,
      study_schedule_item_name: item.name
    });

    if (item.locked && item.type === 'Admissions') {
      return this.fetchSubscriptionAddOnOfferToUnlockItem(item, completionCallback);
    }

    if (item.type === 'Misc') {
      if (item.url) {
        window.open(item.url, '_blank');
      }

      this.handleChangeItemState(item, true);
      completionCallback();
    } else if (['Review', 'Flashcards', 'Series', 'Lesson Quiz', 'Practice Test'].includes(item.type)) {
      this.handleChangeItemState(item, true).then(() => {
        window.location.assign(item.url);
      });
    } else if (item.type === 'Lesson' || item.type === 'Admissions') {
      window.location.assign(item.url);
    } else {
      if (item.url.indexOf('/practices/start') !== -1) {
        window.location.assign(item.url);
      } else {
        this.fetchPractice(item, completionCallback);
      }
    }
  };

  protected handleChangeItemState(item: StudyItemData, isCompleted: boolean) {
    // Track in Mixpanel
    if (isCompleted) {
      trackEvent('Study Schedule > Complete Study Item', {
        study_schedule_item_id: item.id,
        study_schedule_item_name: item.name
      });
    }

    // Update the UI quickly to give the user feedback. Very unlikely the request will fail
    this.props.setItemCompleted(item.id, isCompleted);
    return api.recordContentBlockProgress(item.id, isCompleted);
  }

  fetchPractice(item: StudyItemData, clickCompletionCallback) {
    fetch(paths.api.studySchedulePractice(item.id), {
      method: 'POST'
    })
      .then((response) => {
        clickCompletionCallback();
        this.handleFetchPracticeResponse(response);
      })
      .catch((response) => {
        clickCompletionCallback();
        this.handleFetchPracticeError(response);
      });
  }

  fetchSubscriptionAddOnOfferToUnlockItem(item: StudyItemData, clickCompletionCallback) {
    if (item.type !== 'Admissions') {
      clickCompletionCallback();
      return;
    }

    fetch(paths.api.subscriptionAddOnOffer(item.id, 'content_block')).then((response) => {
      clickCompletionCallback();

      if (response.offer && response.componentPath) {
        bugsnagNotify(`Attempting to show add-on component ${response.componentPath}`);

        this.context.setModal({
          body: (
            <AddOnOfferModalContent
              componentPath={response.componentPath}
              offer={{ ...response.offer, successPath: item.url }}
            />
          ),
          header: response.title
        });
      }
    }).catch((response) => {
      clickCompletionCallback();
    });
  }

  handleFetchPracticeResponse(response) {
    if (response.preview) {
      this.setState({ practicePreview: response.preview });
    } else if (response.redirect) {
      window.location.assign(response.redirect);
    }
  }

  handleFetchPracticeError(response) {
    this.setState({ errorMessage: response.errors._error });
  }

  onClosePreviewModal() {
    this.setState({ practicePreview: null });
  }

  renderErrorMessage() {
    return (
      <PopupBanner bsStyle={'danger'} onSelfDestruct={() => this.setState({ errorMessage: null })}>
        {this.state.errorMessage}
      </PopupBanner>
    );
  }
}

import AdmissionsAddOn from '@magoosh/payments/pages/add_ons/admissions';
import MBAAdmissionsAddOn from '@magoosh/payments/pages/add_ons/mba_admissions';

const AddOnOfferModalContent = (props) => {
  // This lazy loading approach is desired, but including the call to React.lazy is causing an
  // `ReferenceError: window is not defined` error in the server render pack. Moving to hard
  // coded imports keyed by the component path to get unblocked.

  // const DefaultContent = () => null;

  // const SpecificContent = React.lazy(() => {
  //   return import(`@magoosh/${props.componentPath}`).catch((error) => {
  //     return { default: DefaultContent };
  //   })
  // });

  // return <SpecificContent {...props.offer} compact />;

  const AddOnComponent = {
    'payments/pages/add_ons/admissions': AdmissionsAddOn,
    'payments/pages/add_ons/mba_admissions': MBAAdmissionsAddOn
  }[props.componentPath];

  return <AddOnComponent {...props.offer} compact />;
};
