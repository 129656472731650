import colors from '@magoosh/lib/styles/colors.module.scss';

const COLORS = [
  colors.dataLightPurple,
  colors.dataDarkPurple,
  colors.dataLightBlue,
  colors.dataDarkBlue,
  colors.dataLightGreen,
  colors.dataDarkGreen,
  colors.dataLightOrange,
  colors.dataDarkOrange,
  colors.dataLightRed,
  colors.dataDarkRed
];

export default COLORS;
