import React from 'react';

import { DangerBadge, DefaultBadge, InfoBadge, SuccessBadge } from '@magoosh/lib/badge';

interface Props {
  status: string;
}

const StatusBadge: React.FC<Props> = (props) => {
  if (props.status === 'accepted') {
    return <SuccessBadge>Accepted</SuccessBadge>;
  } else if (props.status === 'pending') {
    return <SuccessBadge>Pending</SuccessBadge>;
  } else if (props.status === 'rejected') {
    return <DangerBadge>Rejected</DangerBadge>;
  } else if (props.status === 'processing') {
    return <InfoBadge>Processing</InfoBadge>;
  } else if (props.status === 'passed') {
    return <SuccessBadge>Passed</SuccessBadge>;
  } else if (props.status === 'failed') {
    return <DangerBadge>Failed</DangerBadge>;
  } else if (props.status === 'errored') {
    return <DangerBadge>Errored</DangerBadge>;
  } else if (props.status) {
    return <DefaultBadge>{props.status}</DefaultBadge>
  } else {
    return null;
  }
};

export default StatusBadge;
