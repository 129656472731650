import { EditableFieldsByBlockType, ContentBlockTypes, EditableFields } from './types';

export function humanizedBlockType(type: string) {
  return type.replace('ContentBlock::', '');
}

export function getPermissionsByBlockType(blockType: ContentBlockTypes): EditableFields {
  return PERMISSIONS_BY_BLOCK_TYPE[blockType];
}

const PERMISSIONS_BY_BLOCK_TYPE: EditableFieldsByBlockType = {
  'ContentBlock::AdmissionsLessonBlock': {
    lessonPicker: true,
    name: true
  },
  'ContentBlock::LessonBlock': {
    lessonPicker: true,
    name: true
  },
  'ContentBlock::PracticeSessionBlock': {
    accessLevel: true,
    autofill: true,
    difficultyRange: true,
    estimatedTimeMinutes: true,
    examSectionPicker: true,
    includeOldPrompts: true,
    itemCount: true,
    name: true,
    promptPickerStrategy: true,
    quizMode: true,
    slug: true,
    subjectTags: true,
    timeLimit: true
  },
  'ContentBlock::SubjectMasteryRoot': {
    manageChildBlocks: true
  },
  'ContentBlock::SubjectMasteryGroup': {
    name: true,
    slug: true,
    manageChildBlocks: true
  },
  'ContentBlock::AssignmentGroup': {
    examSectionPicker: true,
    accessLevel: true,
    name: true,
    slug: true,
    addAssignmentBlockChild: true,
    addSectionedAssignmentBlockChild: true,
    manageChildBlocks: true
  },
  'ContentBlock::SubjectMasteryBlock': {
    name: true,
    slug: true,
    addLessonBlockChild: true,
    addPracticeSessionBlockChild: true,
    manageChildBlocks: true,
    difficultySelector: true,
    accessLevel: true
  },
  'ContentBlock::AssignmentBlock': {
    name: true,
    slug: true,
    addLessonBlockChild: true,
    addPracticeSessionBlockChild: true,
    manageChildBlocks: true,
    difficultySelector: true,
    accessLevel: true
  },
  'ContentBlock::SectionedAssignmentBlock': {
    name: true,
    slug: true,
    manageChildBlocks: true,
    accessLevel: true,
    addContainerChild: true
  },
  'ContentBlock::Container': {
    name: true,
    slug: true,
    manageChildBlocks: true,
    addPracticeSessionBlockChild: true,
    addLessonBlockChild: true,
    addJsonBlockChild: true,
    addContainerChild: true,
    addStudyScheduleBlockChild: true,
    addReviewBlockChild: true,
    addExternalResourceBlockChild: true,
    addRichTextBlockChild: true,
    addStudyModuleBlockChild: true,
    addGuidedExperienceBlockChild: true,
    addAdmissionsApplicationCyclesPlanBlockChild: true,
    addAdmissionsApplicationCyclesSegmentWithDeadlineBlockChild: true,
    addAssignmentGroupChild: true
  },
  'ContentBlock::JSONBlock': {
    name: true,
    slug: true,
    payload: true
  },
  'ContentBlock::LearningSessionBlock': {
    addAdmissionsExternalResourceBlockChild: true,
    addExternalResourceBlockChild: true,
    addAdmissionsLessonBlockChild: true,
    addLessonBlockChild: true,
    addPracticeSessionBlockChild: true,
    addReviewBlockChild: true,
    addLessonSeriesLinkBlockChild: true,
    addFlashcardsDeckBlockChild: true,
    manageChildBlocks: true,
    name: true,
    slug: true
  },
  'ContentBlock::StudyScheduleBlock': {
    accessLevel: true,
    addLearningSessionBlockChild: true,
    experienceLevels: true,
    manageChildBlocks: true,
    minDaysUntilTest: true,
    numMonths: true,
    description: true,
    name: true,
    learningSessionSetName: true,
    learningSessionsPerSet: true,
    learningSessionName: true,
    examSectionFocus: true,
    examVersion: true
  },
  'ContentBlock::ReviewBlock': {
    estimatedTimeMinutes: true,
    name: true
  },
  'ContentBlock::AdmissionsExternalResourceBlock': {
    estimatedTimeMinutes: true,
    name: true,
    url: true,
    description: true,
    examSectionPicker: true
  },
  'ContentBlock::ExternalResourceBlock': {
    estimatedTimeMinutes: true,
    name: true,
    url: true,
    description: true,
    examSectionPicker: true
  },
  'ContentBlock::DiagnosticTestBlock': {
    addDiagnosticSectionBlockChild: true,
    manageChildBlocks: true,
    name: true,
    accessLevel: true
  },
  'ContentBlock::DiagnosticSectionBlock': {
    addPracticeSessionBlockChild: true,
    manageChildBlocks: true
  },
  'ContentBlock::AssessmentBlock': {
    manageChildBlocks: true,
    displayName: true,
    maximumEngagementHours: true,
    minimumEngagementHours: true
  },
  'ContentBlock::MockTestSectionBlock': {
    manageChildBlocks: false,
    questions: true
  },
  'ContentBlock::StudyModuleBlock': {
    manageChildBlocks: true,
    addLessonBlockChild: true,
    addPracticeSessionBlockChild: true,
    addDrillBlockChild: true,
    addFlashcardsDeckBlockChild: true,
    name: true,
    slug: true
  },
  'ContentBlock::DrillBlock': {
    manageChildBlocks: false,
    includeOldPrompts: true,
    itemCount: true,
    quizMode: true,
    estimatedTimeMinutes: true,
    examSectionPicker: true,
    promptPickerStrategy: true,
    accessLevel: true,
    name: true,
    slug: true
  },
  'ContentBlock::LessonSeriesBlock': {
    name: true,
    slug: true,
    lessons: true,
    manageChildBlocks: true,
    addDrillBlockChild: true,
    blurb: true
  },
  'ContentBlock::LessonSeriesLinkBlock': {
    lessonSeriesSlug: true
  },
  'ContentBlock::FlashcardsDeckBlock': {
    name: true,
    accessLevel: true,
    slug: true,
    estimatedTimeMinutes: true,
    examSectionPicker: true
  },
  'ContentBlock::OfficialQuestionsSectionBlock': {
    name: true,
    accessLevel: true,
    slug: true,
    manageChildBlocks: true
  },
  'ContentBlock::RichTextBlock': {
    richText: true
  },
  'ContentBlock::GuidedExperienceBlock': {
    name: true,
    addGuidedExperienceSectionBlockChild: true,
    experienceType: true
  },
  'ContentBlock::GuidedExperienceSectionBlock': {
    name: true,
    addGuidedExperienceStepBlockChild: true,
    addGuidedExperienceWritingBlockChild: true
  },
  'ContentBlock::GuidedExperienceStepBlock': {
    name: true,
    header: true,
    embedLessons: true,
    addWritingExampleBlockChild: true,
    addLessonBlockChild: true,
    addPracticeSessionBlockChild: true,
    addRichTextBlockChild: true
  },
  'ContentBlock::GuidedExperienceWritingBlock': {
    name: true,
    header: true,
    lessonPicker: true,
    defaultWritingTab: true
  },
  'ContentBlock::WritingExampleBlock': {
    name: true,
    writingExamplePicker: true,
    descriptor: true
  },
  'ContentBlock::PracticeTestLinkBlock': {
    name: true
  },
  'ContentBlock::Admissions::ApplicationCycles::PlanBlock': {
    addContainerChild: true,
    addAdmissionsApplicationCyclesSectionBlockChild: true,
    addAdmissionsApplicationCyclesSegmentWithDeadlineBlockChild: true,
    accessLevel: true,
    applicationCyclePicker: true,
    name: true
  },
  'ContentBlock::Admissions::ApplicationCycles::SectionBlock': {
    addAdmissionsApplicationCyclesSegmentBlockChild: true,
    accessLevel: true,
    colorPalettePicker: true,
    iconPicker: true,
    name: true
  },
  'ContentBlock::Admissions::ApplicationCycles::SegmentBlock': {
    addLessonBlockChild: true,
    addRichTextBlockChild: true,
    accessLevel: true,
    name: true
  },
  'ContentBlock::Admissions::ApplicationCycles::SegmentWithDeadlineBlock': {
    addLessonBlockChild: true,
    addRichTextBlockChild: true,
    accessLevel: true,
    deadlineBasisPicker: true,
    name: true,
    programPicker: true,
    segmentTypePicker: true
  },
  'ContentBlock::LessonQuizLinkBlock': {
    name: true,
    url: true,
    description: true
  },
  'ContentBlock::LessonDrillsBlock': {
    accessLevel: true,
    lessonPicker: true,
    name: true,
    addPracticeSessionBlockChild: true
  }
};
