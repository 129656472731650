import * as React from 'react';
import cx from 'classnames';
import style from '../style.module.scss';
import { PrimaryButton, DefaultButton } from '@magoosh/lib/button';
import Modal from '@magoosh/lib/modal';
import * as moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { Col, Row } from 'react-bootstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { SubscriptionPlan } from 'types/subscription_plan';
import Multiselect from 'react-widgets/lib/Multiselect';

moment.locale('en');
momentLocalizer(moment);

interface Props {
  availableSubscriptionPlans: SubscriptionPlan[];
  onAddSubscription: Function;
}

export default class AddSubscription extends React.Component<Props> {
  state = {
    subscriptionDialogOpen: false,
    plans: [],
    expirationDate: null,
    plansError: false,
    expirationError: false,
    subscriptionPlanSelectOpen: null
  };

  render() {
    const { subscriptionPlanSelectOpen } = this.state;

    return (
      <React.Fragment>
        <DefaultButton onClick={this.showSubscriptionDialog} type="button">
          Add Subscription
        </DefaultButton>
        <Modal
          dialogClassName={style.dialog}
          show={this.state.subscriptionDialogOpen}
          title="Add Subscription"
          onHide={this.hideSubscriptionDialog}>
          <Row>
            <Col md={3}>
              <label>Subscription Plan</label>
            </Col>
            <Col md={7}>
              <Multiselect
                data={this.props.availableSubscriptionPlans}
                filter="contains"
                valueField="id"
                textField="name"
                groupBy="brandingName"
                onChange={value => this.addPlan(value)}
                onSelect={() => this.setState({ subscriptionPlanSelectOpen: false })}
                onToggle={() => this.setState({ subscriptionPlanSelectOpen: !subscriptionPlanSelectOpen }) }
                open={subscriptionPlanSelectOpen}
              />
            </Col>
            <Col md={2}>
              <div className={cx(!this.state.plansError && style.hidden, style.error)}>
                Required
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Expiration Date</label>
            </Col>
            <Col md={4}>
              <DateTimePicker
                onChange={value => this.setState({ expirationDate: value, expirationError: false })}
                value={this.state.expirationDate}
                format="MM/DD/YYYY"
                time={false}
                min={new Date()}
              />
            </Col>
            <Col md={2}>
              <div className={cx(!this.state.expirationError && style.hidden, style.error)}>
                Required
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col xs={8} md={7}>
              <PrimaryButton onClick={this.onSubmit} type="button">
                Add Subscription
              </PrimaryButton>
            </Col>
            <Col xs={4} md={2}>
              <DefaultButton onClick={this.hideSubscriptionDialog} type="button">
                Cancel
              </DefaultButton>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }

  addPlan = value => {
    // If not already updated, set a default plan expiration of today + the first selected plan's duration
    const expirationDate =
      this.state.expirationDate ||
      new Date(
        moment()
          .add(value[0].durationDays, 'd')
          .format('MM/DD/YYYY')
      );

    this.setState({
      plans: value,
      plansError: false,
      expirationDate: expirationDate,
      expirationError: false
    });
  };

  // Show modal and reset all state values to allow user to add new subscriptions
  showSubscriptionDialog = () => {
    this.setState({
      subscriptionDialogOpen: true,
      plans: [],
      expirationDate: null,
      plansError: false,
      expirationError: false
    });
  };

  hideSubscriptionDialog = () => {
    this.setState({ subscriptionDialogOpen: false });
  };

  showErrors = () => {
    if (this.state.plans.length < 1) {
      this.setState({ plansError: true });
    }

    if (!this.state.expirationDate) {
      this.setState({ expirationError: true });
    }
  };

  // Only let them submit if both the plan(s) and expiration date have been chosen
  isValid = () => {
    if (this.state.plans.length > 0 && this.state.expirationDate) {
      return true;
    }
    this.showErrors();
    return false;
  };

  // Pass the selected subscription data as an object to the callback.
  // This will set the parent's form values to include the subscription
  onSubmit = () => {
    if (this.isValid()) {
      this.state.plans.map(plan => {
        this.props.onAddSubscription({
          planId: plan.id,
          planName: plan.name,
          expirationDate: this.state.expirationDate
        });
      });

      this.setState({ subscriptionDialogOpen: false }); // Close the modal
    }
  };
}
