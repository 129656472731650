import * as React from 'react';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import style from './style.module.scss';
import cx from 'classnames';
import { TagIcon } from '@magoosh/lib/icons';

interface Props {
  displayName: string;
  onClickCallback: (e) => void;
  isSelected: boolean;
  negative?: boolean;
  canBeSelected?: boolean;
}

export default function Tag({ displayName, onClickCallback, isSelected, negative, canBeSelected }: Props) {
  if (canBeSelected) {
    return (
      <button className={cx(style.tag, isSelected && style.selected, negative && style.negative)} onClick={onClickCallback}>
        {/* Render a hidden check icon to keep tag width the same on selection vs. no selection */}
        <FontAwesomeIcon
          icon={negative ? "times": "check"}
          className={cx(!isSelected && style.notVisible, negative && style.negative)} />{' '}
        <span className={cx(!isSelected && style.unselectedText)}>{displayName}</span>
      </button>
    );
  }
  return (
    <button disabled className={cx(style.tag, style.disabled)}>
      {isSelected && <TagIcon height={13} width={13} />} {displayName}
    </button>
  );
}
