import * as React from 'react';

import { SuggestedLessonsSectionData } from '../types';
import { LinkButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';

interface Props {
  suggestedLessons: SuggestedLessonsSectionData[];
  onChangePlan?: (any) => void;
}

export default ({ suggestedLessons, onChangePlan }: Props) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="u-margin-V-n">
          <strong>Suggested Lessons</strong>
        </h4>

        <LinkButton href={paths.lessons()}>
          <small>
            See all lessons
          </small>
        </LinkButton>
      </div>

      {onChangePlan && (
        <a className="h7 text-gray-light" onClick={onChangePlan}>
          Select Study Plan
        </a>
      )}

      <hr />

      {suggestedLessons.map((sectionData) =>
        <div key={sectionData.section.id}>
          {!!sectionData.lessons.length && (
            <>
              <h5 className="u-margin-B-xxs">{sectionData.section.name}</h5>
              <ul className="list-unstyled u-margin-B-m">
                {sectionData.lessons.map((lesson) => (
                  <li className="u-margin-B-xxs" key={lesson.id}>
                    <LinkButton href={`${sectionData.section.host ? ('//' + sectionData.section.host) : ''}${paths.lesson(lesson.slug)}`}>
                      {lesson.title}
                    </LinkButton>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}

      <hr/>

      <p className="text-center text-gray-light u-margin-V-n">
        <small>Suggestions are based on your lesson progress</small>
      </p>
    </div>
  );
};
