import * as React from 'react';
import { isNumber } from 'lodash';
import style from './style.module.scss';

interface Props {
  children: any;
  duration?: number;
}

export const FadeIn = ({ children, duration }: Props) => {
  const animationDuration = isNumber(duration) ? duration : 1500;

  return (
    <div className={style.fadeIn} style={{ animationDuration: `${animationDuration}ms` }}>
      {children}
    </div>
  );
}
