import React from 'react';
import * as moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { ErrorMessage, Field, FieldProps } from 'formik';
import style from '../field_input/style.module.scss';
import cx from 'classnames';

export interface FieldDateTimeProps {
  label?: string;
  name: string;
  optional?: boolean;
  placeholder?: string;
  time?: boolean;
}

export const FieldDateTime: React.FC<FieldDateTimeProps> = (props) => {
  return <Field component={DateTime} {...props} />;
};

const DateTime: React.FC<FieldDateTimeProps & FieldProps> = ({
  field,
  form: { setFieldValue },
  ...props
}) => {
  const { label, optional, placeholder, time } = props;
  const enableTime = time == null || time;
  moment.locale('en');
  momentLocalizer(moment);

  const format = enableTime ? 'L LT' : 'L';

  return (
    <FormGroup className="u-margin-T-s">
      <ControlLabel>
        {label}{' '}
        {optional && (
          <span className={style.optional}>
            <small>(Optional)</small>
          </span>
        )}
      </ControlLabel>
      <DateTimePicker
        defaultCurrentDate={moment().endOf('day').toDate()}
        name={field.name}
        format={format}
        placeholder={placeholder}
        value={field.value}
        onChange={(value) => {
          setFieldValue(field.name, value);
        }}
        time={enableTime}
      />
      <div className={cx(style.errorMessage, 'u-margin-B-xs')}>
        <ErrorMessage name={field.name} />
      </div>
    </FormGroup>
  );
};

export default FieldDateTime;
