import React, { useEffect, useState } from 'react';

import { SecondaryButton } from '@magoosh/lib/button';
import { Select } from '@magoosh/lib/forms';

import { AdmissionsHighlightedContent } from '@admissions/types';

interface Props {
  highlightedContent: AdmissionsHighlightedContent[];
  notesHref?: string;
}

const HighlightedContentDropdown: React.FC<Props> = (props) => {
  const [selectedHref, setSelectedHref] = useState<string>(null);

  useEffect(() => {
    if (props.highlightedContent.length) {
      setSelectedHref(props.highlightedContent[0].cta.href);
    }
  }, [props.highlightedContent]);

  const handleChange = (e) => {
    setSelectedHref(e.target.value);
  };

  return (
    <div className="d-flex" style={{ gap: 10 }}>
      <div style={{ flexGrow: 1 }}>
        <Select onChange={handleChange}>
          {
            props.highlightedContent.map((content, idx) => (
              <option key={idx} value={content.cta.href}>{content.title} ({content.subtitle})</option>
            ))
          }

          {props.notesHref && <option value={props.notesHref}>My Notes</option>}
        </Select>
      </div>

      <div>
        <SecondaryButton href={selectedHref}>Go</SecondaryButton>
      </div>
    </div>
  );
};

export default HighlightedContentDropdown;
