import React, { useEffect } from 'react';
import { compact, find, without } from 'lodash';
import moment from 'moment';

import { useModalContext } from '@magoosh/context/modal_context';
import { SimpleDonutChart } from '@magoosh/lib/charts';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import useSearchQuery from '@magoosh/utils/use_search_query';
import { ColorPalette } from 'types/constants';
import { pluralize } from 'utilities/pluralize';

import { ApplicationCycleDeadline } from '@admissions/types';

import ModalAddEditDeadline from './modal_add_edit_deadline';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  applicationCycle: string;
  deadlines: ApplicationCycleDeadline[];
  onSaveDeadline: (deadline: ApplicationCycleDeadline) => Promise<any>;
}

const ApplicationTracker: React.FC<Props> = (props) => {
  const { setModal } = useModalContext();
  const interaction = useSearchQuery()['interaction'] || null;

  useEffect(() => {
    if (interaction === 'add_deadline') {
      presentAddEditDeadlineModal(null);
    }
  }, [interaction]);

  const colorPalette = (deadline: ApplicationCycleDeadline) => {
    if (!deadline) {
      // Gray
      return {
        dark: '#706D74',
        light: '#DBDADC'
      } as ColorPalette;
    } else if (deadline?.program?.institution?.colorPalette) {
      return deadline.program.institution.colorPalette;
    } else {
      // UCLA blue as default for now
      return {
        dark: '#2774AE',
        light: '#DAEBFE'
      } as ColorPalette;
    }
  };

  const daysLeft = (deadline: ApplicationCycleDeadline) => {
    var dueDate = moment(`${deadline.dueAt} 23:59:59`);
    var todaysDate = moment();

    return dueDate.diff(todaysDate, 'days');
  };

  const deadlineHasPassed = (deadline: ApplicationCycleDeadline) => {
    return !deadlineIsUpcoming(deadline) && !deadlineIsToday(deadline);
  };

  const deadlineIsToday = (deadline: ApplicationCycleDeadline) => {
    return daysLeft(deadline) === 0 && moment(`${deadline.dueAt} 23:59:59`).isAfter(moment());
  };

  const deadlineIsUpcoming = (deadline: ApplicationCycleDeadline) => {
    return daysLeft(deadline) > 0;
  };

  const deadlineTitle = (deadline: ApplicationCycleDeadline) => {
    let daysLeftText;

    if (deadlineIsUpcoming(deadline)) {
      daysLeftText = `${pluralize(daysLeft(deadline), 'Day')} Left`;
    } else if (deadlineIsToday(deadline)) {
      daysLeftText = 'Today';
    } else {
      daysLeftText = 'Deadline Passed';
    }

    return compact([
      deadline.programName,
      daysLeftText
    ]).join(' - ');
  };

  const donutInterior = (deadline: ApplicationCycleDeadline) => {
    if (!deadline) {
      return (
        <FontAwesomeIcon
          icon="plus"
          style={{
            color: colorPalette(primaryDeadline).dark,
            fontSize: 40,
            WebkitTextStroke: '3px #fff'
          }}
        />
      );
    } else if (progressValue(deadline) === 100) {
      return (
        <FontAwesomeIcon
          icon="check"
          style={{
            color: colorPalette(primaryDeadline).dark,
            fontSize: 40,
            WebkitTextStroke: '3px #fff'
          }}
        />
      );
    } else {
      return null;
    }
  };

  const findPrimaryDeadline = () => {
    const pinnedDeadline = find(props.deadlines, (deadline) => deadline.pinned);
    if (pinnedDeadline) return pinnedDeadline;

    const nextUpcomingDeadline = find(props.deadlines, (deadline) => (
      deadlineIsUpcoming(deadline) || deadlineIsToday(deadline)
    ));
    if (nextUpcomingDeadline) return nextUpcomingDeadline;

    const mostRecentlyPassedDeadline = find([...props.deadlines].reverse(), (deadline) => (
      deadlineHasPassed(deadline)
    ));
    if (mostRecentlyPassedDeadline) return mostRecentlyPassedDeadline;

    return props.deadlines[0];
  };

  const progressValue = (deadline: ApplicationCycleDeadline) => {
    if (!deadline) {
      return 0;
    } else {
      return Math.trunc((deadline.progress.completedItems / deadline.progress.items) * 100);
    }
  };

  const presentAddEditDeadlineModal = (deadline: ApplicationCycleDeadline) => {
    setModal({
      header: deadline ? 'Edit Application Deadline' : 'Add Application Deadline',
      body: <ModalAddEditDeadline
        applicationCycle={props.applicationCycle}
        deadline={deadline}
        onClose={() => setModal(null)}
        onSave={props.onSaveDeadline}
        otherDeadlines={without(props.deadlines, deadline)}
      />
    });
  };

  const primaryDeadlineTitle = () => {
    if (!primaryDeadline) return 'Your Next Deadline';

    if (primaryDeadline.pinned) {
      return 'Your Pinned Deadline';
    } else if (deadlineHasPassed(primaryDeadline)) {
      return 'Your Most Recently Passed Deadline';
    } else {
      return 'Your Next Deadline';
    }
  };

  const togglePinDeadline = (deadline: ApplicationCycleDeadline, pinned: boolean) => {
    props.onSaveDeadline({ ...deadline, pinned });
  };

  const primaryDeadline = findPrimaryDeadline();
  const otherDeadlines = without(props.deadlines, primaryDeadline);

  return (
    <div className="d-flex flex-column" style={{ gap: 20 }}>
      <div className="align-items-center d-flex">
        <div className="flex-1">
          <strong>{primaryDeadlineTitle()}</strong>
        </div>

        {
          primaryDeadline && (
            <div>
              <a className="small text-gray-light" onClick={() => presentAddEditDeadlineModal(null)}>Add Another Deadline</a>
            </div>
          )
        }
      </div>

      <div>
        <div className="align-items-center d-flex" style={{ gap: 15 }}>
          <div>
            <SimpleDonutChart
              colorPalette={colorPalette(primaryDeadline)}
              data={progressValue(primaryDeadline)}
              interior={donutInterior(primaryDeadline)}
              size={100}
            />
          </div>

          <div className="flex-1">
            {
              primaryDeadline ? (
                <PrimaryDeadlineView
                  colorPalette={colorPalette(primaryDeadline)}
                  deadline={primaryDeadline}
                  deadlineTitle={deadlineTitle(primaryDeadline)}
                  onEditDeadline={() => presentAddEditDeadlineModal(primaryDeadline)}
                  onTogglePinDeadline={() => togglePinDeadline(primaryDeadline, false)}
                />
              ) : (
                <AddApplicationView onAddDeadline={() => presentAddEditDeadlineModal(null)} />
              )
            }
          </div>
        </div>

        {
          otherDeadlines.length > 0 && (
            <>
              <hr className="u-margin-V-s" />

              <div className="d-flex flex-column" style={{ gap: 10 }}>
                {
                  otherDeadlines.map((deadline) => (
                    <OtherDeadlineView
                      key={deadline.id}
                      colorPalette={colorPalette(deadline)}
                      deadline={deadline}
                      deadlineTitle={deadlineTitle(deadline)}
                      onEditDeadline={() => presentAddEditDeadlineModal(deadline)}
                      onTogglePinDeadline={() => togglePinDeadline(deadline, true)}
                    />
                  ))
                }
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

const AddApplicationView: React.FC<{ onAddDeadline: () => void; }> = (props) => {
  return (
    <div className="d-flex flex-column" style={{ gap: 10 }}>
      <div style={{ fontSize: 20 }}>
        <strong>Add Application Deadline</strong>
      </div>

      <div>
        Add application deadlines and get weekly priorities that tell you exactly what you need to do to submit a strong application.
      </div>

      <a
        className="small"
        onClick={props.onAddDeadline}
      >
        Add Your Deadline
      </a>
    </div>
  );
};

interface DeadlineViewProps {
  colorPalette: ColorPalette;
  deadline: ApplicationCycleDeadline;
  deadlineTitle: string;
  onEditDeadline: () => void;
  onTogglePinDeadline: () => void;
}

const OtherDeadlineView: React.FC<DeadlineViewProps> = (props) => {
  return (
    <div className="align-items-center d-flex" style={{ gap: 15 }}>
      <div
        className="hidden-sm hidden-xs"
        style={{
          backgroundColor: props.colorPalette.dark,
          height: 10,
          width: 10
        }}
      />

      <div className="flex-1 small" style={{ color: props.colorPalette.dark }}>
        {props.deadlineTitle}
      </div>

      <div className="hidden-sm hidden-xs small" style={{ minWidth: 140 }}>
        {moment(props.deadline.dueAt).format('MMMM D, YYYY')}
      </div>

      <div className="hidden-sm hidden-xs small" style={{ minWidth: 140 }}>
        {props.deadline.progress.completedItems}/{props.deadline.progress.items} Tasks Complete
      </div>

      <a className="small text-gray-light" onClick={props.onEditDeadline}>
        Edit
      </a>

      <a className="small text-gray-light" onClick={props.onTogglePinDeadline}>
        Pin
      </a>
    </div>
  );
};

const PrimaryDeadlineView: React.FC<DeadlineViewProps> = (props) => {
  return (
    <div className="d-flex flex-column" style={{ gap: 5 }}>
      {/*TODO - if no color palette - text should be default body color*/}
      <div style={{ color: props.colorPalette.dark, fontSize: 20 }}>
        <strong>{props.deadlineTitle}</strong>
      </div>

      <div style={{ color: props.colorPalette.dark }}>
        {moment(props.deadline.dueAt).format('MMMM D, YYYY')}
      </div>

      <div className="d-flex" style={{ gap: 10 }}>
        <a
          className="small text-gray-light"
          onClick={props.onEditDeadline}
        >
          Edit Deadline{!props.deadline.programName && ' / Specify Program'}
        </a>

        {
          props.deadline.pinned && (
            <a className="small text-gray-light" onClick={props.onTogglePinDeadline}>
              Unpin
            </a>
          )
        }
      </div>
    </div>
  );
};

export default ApplicationTracker;
