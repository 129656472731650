import * as React from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Checkmark, FontAwesomeIcon } from '@magoosh/lib/icons';
import { pluralize } from 'utilities/pluralize';
import cx from 'classnames';
import style from './style.module.scss';
import { LinkButton } from '@magoosh/lib/button';
import { StudyItemData } from '../../../study_items/types';

interface Props {
  item: StudyItemData;
  popoverPlacement?: string;
  onClick: (item: StudyItemData, clickCompletionCallback: () => void) => void;
  onCompletedToggle: (item: StudyItemData, completed: boolean) => void;
}

interface LabelWrapperProps {
  popoverPlacement?: string;
  studyItem: StudyItemData;
}

const LabelWrapper = (props: React.PropsWithChildren<LabelWrapperProps>) => {
  const { id, description } = props.studyItem;

  if (description) {
    return (
      <OverlayTrigger
        placement={props.popoverPlacement}
        overlay={
          <Popover id={`study-item-tooltip-${id}`}>
            <small>{description}</small>
          </Popover>
        }>
        {props.children}
      </OverlayTrigger>
    );
  }

  return <>{props.children}</>;
};

export default class StudyItem extends React.Component<Props> {
  state = {
    submitting: false
  };

  render() {
    const { completed, estimatedTimeMinutes, id, name, type, url, locked } = this.props.item;

    return (
      <Row key={id}>
        <Col xs={2} className="u-padding-R-s u-margin-T-xxs">
          <a onClick={this.handleCompletedToggle}>
            <Checkmark completed={completed} />
          </a>
        </Col>

        <Col xs={10}>
          <div className={style.itemTypeAndDuration}>
            <span className="text-uppercase">
              <strong>{type}</strong>
            </span>
            {estimatedTimeMinutes ? (
              <span className="u-margin-L-xxs">{pluralize(estimatedTimeMinutes, 'minute', 'minutes')}</span>
            ) : null}
          </div>

          <LabelWrapper studyItem={this.props.item} popoverPlacement={this.props.popoverPlacement}>
            <div className={cx('u-margin-B-xs', style.name)}>
              {/* TODO - mixpanel tracking */}
              {url ? (
                <LinkButton
                  href={url}
                  onClick={this.handleItemClick}
                  submitting={this.state.submitting}>
                  {name}
                </LinkButton>
              ) : (
                name
              )}
              {locked && <FontAwesomeIcon icon={'lock'} className={style.lock} />}
            </div>
          </LabelWrapper>
        </Col>
      </Row>
    );
  }

  handleItemClick = (e) => {
    this.setState({ submitting: true });
    this.props.onClick(this.props.item, this.itemClickCompletion.bind(this));
  };

  itemClickCompletion = () => {
    this.setState({ submitting: false });
  };

  handleCompletedToggle = () => {
    this.props.onCompletedToggle(this.props.item, !this.props.item.completed);
  };
}
