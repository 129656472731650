import * as React from 'react';
import { Provider } from 'react-redux';
import configureStore from '@magoosh/store';
import { TeacherDashboardData } from './types';
import TeacherDashboardContainer from './container';
import { initializeTeacherDashboard } from './actions';

type Props = TeacherDashboardData;

export default class TeacherDashboard extends React.Component<Props> {
  store = configureStore();

  constructor(props) {
    super(props);
    this.store.dispatch(initializeTeacherDashboard(props));
  }

  render() {
    return (
      <Provider store={this.store}>
        <TeacherDashboardContainer />
      </Provider>
    );
  }
}
