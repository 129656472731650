import * as React from 'react';
import Modal from '@magoosh/lib/modal';
import { DefaultButton } from '@magoosh/lib/button';
import style from './style.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  show: boolean;
  confirmText: string;
  cancelText?: string;
  confirmClass?: string;
}

const ConfirmationModal: React.FC<Props> = (props) => {
  return (
    <Modal className={style.confirmationModal} show={props.show} title={props.title} onHide={props.onClose}>
      {props.children}
      <div className={style.buttonDock}>
        <DefaultButton onClick={props.onClose}>{props.cancelText || 'Cancel'}</DefaultButton>
        <DefaultButton onClick={props.onConfirm} className={props.confirmClass}>
          {props.confirmText}
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
