import * as React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import style from '../style.module.scss';
import { ClassSection } from '../types';

interface FiltersProps {
  classSectionsByGrade: StringHash<ClassSection[]>;
  endDate: Date;
  dispatch: (...args) => any;
  resetFilters: () => any;
  selectedClassSectionId: number;
  selectedGrade: string;
  startDate: Date;
  updateFilter: (filter) => any;
}

export default class Filters extends React.Component<FiltersProps> {
  render() {
    return (
      <Grid className={style.performanceFilters}>
        <Row>
          <Col md={2} />
          <Col md={3}>Class</Col>
          <Col md={2}>Date Range</Col>
        </Row>
        <Row>
          <Col md={2} />
          <Col md={3}>{this.renderClassSectionFilter()}</Col>
          <Col md={2}>
            <DateTimePicker
              value={this.props.startDate}
              placeholder="Start date"
              max={this.props.endDate || new Date()}
              time={false}
              onChange={(value) => {
                this.props.dispatch(this.props.updateFilter({ startDate: value }));
              }}
            />
          </Col>
          <Col md={1} className={style.dateRangeSeparator}>
            to
          </Col>
          <Col md={2}>
            <DateTimePicker
              value={this.props.endDate}
              placeholder="End date"
              min={this.props.startDate}
              max={new Date()}
              time={false}
              onChange={(value) => {
                this.props.dispatch(this.props.updateFilter({ endDate: value }));
              }}
            />
          </Col>
          <Col md={2} className={style.resetFiltersCell}>
            <a
              href="#"
              onClick={() => {
                this.props.dispatch(this.props.resetFilters());
              }}>
              Reset filters
            </a>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderClassSectionFilter() {
    const props = this.props;
    const { classSectionsByGrade } = this.props;

    return (
      <select
        name="classSectionsFilter"
        className="form-control"
        onChange={(e) => {
          const value = e.target.value;
          const gradeCaptures = value.match(/grade:(.+)/);
          const filter = {
            selectedClassSectionId: null,
            selectedGrade: null
          };

          if (gradeCaptures && gradeCaptures.length > 1) {
            filter.selectedGrade = gradeCaptures[1];
          } else {
            filter.selectedClassSectionId = value;
          }

          props.dispatch(props.updateFilter(filter));
        }}>
        <option value="">All</option>
        {Object.keys(classSectionsByGrade).map((grade) => {
          if (!grade) {
            return null;
          }
          return this.renderGradeOptGroup(grade, `Grade ${grade}`, classSectionsByGrade[grade]);
        })}
        {this.renderGradeOptGroup(null, 'Other', classSectionsByGrade[''])}
      </select>
    );
  }

  renderGradeOptGroup(grade, gradeLabel, classSections) {
    let allGradeClasses = null;

    if (!classSections || classSections.length <= 0) {
      return null;
    }

    if (grade) {
      let selected = null;
      if (this.props.selectedGrade === grade) {
        selected = { selected: 'selected' };
      }

      allGradeClasses = (
        <option key={grade} value={`grade:${grade}`} {...selected}>
          All Grade {grade} classes
        </option>
      );
    }

    return (
      <optgroup label={gradeLabel} key={gradeLabel}>
        {allGradeClasses}
        {classSections.map((classSection: ClassSection) => {
          let selected = null;
          if (classSection.id === this.props.selectedClassSectionId) {
            selected = { selected: 'selected' };
          }

          return (
            <option key={classSection.id} value={classSection.id} {...selected}>
              {classSection.ensuredName}
            </option>
          );
        })}
      </optgroup>
    );
  }
}
