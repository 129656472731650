import React, { useState } from 'react';
import { DataTable } from '@magoosh/lib/data_table';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { adminPaths } from '../../../../config/path_helpers';
import { BorderlessButton, PrimaryButton } from '@magoosh/lib/button';
import Modal from '@magoosh/lib/modal';
import MTTCopyForm from '@magoosh/admin/module_track_templates/list/copy_form';
import MTTActivateForm from '@magoosh/admin/module_track_templates/list/activate_form';
import MTTPreviewForm from '@magoosh/admin/module_track_templates/list/preview_form';
import MTTResetForm from '@magoosh/admin/module_track_templates/list/reset_form';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

export const List: React.FC = () => {
  const { runQuery, dataQueryTableProps } = useDataQuery(
    adminPaths.api.moduleTrackTemplates,
    {perPage: 100}
  );

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showConfirmCopyModal, setShowConfirmCopyModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [currentMTT, setCurrentMTT] = useState(null);

  const onClickCopy = (mtt: any) => {
    setCurrentMTT(mtt);
    setShowCopyModal(true);
  };

  const onClickActivate = (mtt: any) => {
    setCurrentMTT(mtt);
    setShowActivateModal(true);
  };

  const onClickPreview = (mtt: any) => {
    setCurrentMTT(mtt);
    setShowPreviewModal(true);
  };

  const onClickReset = (mtt: any) => {
    setCurrentMTT(mtt);
    setShowResetModal(true);
  };

  return (
    <div>
      <div>
        <h1>Module Track Templates</h1>
        <p>
          <p><b>To make immediate changes:</b></p>
          <p>Click into a Container Block to make immediate changes to study item content blocks.</p>
          <p><b>To batch multiple changes together:</b></p>
          <ol>
            <li>
              <p><b>Copy a track</b></p>
              <p>Make a copy of the Track you would like to change by clicking its Copy button <FontAwesomeIcon icon="copy" />. A new <b>(inactive!)</b> track will be created, with new Study Item content blocks copied from the original.</p>
            </li>
            <li>
              <p><b>Edit the Study Items</b></p>
              <p>Click into the Container Block of the new copy to make the changes needed for the new study items.</p>
            </li>
            <li><p><b>Preview your changes</b></p>
              <p>
                Click the Preview Eye button <FontAwesomeIcon icon="eye" /> next to the new track. This will manually set your current account to use the new track. Go to the front-end dashboard to see your changes in action.
              </p>
            </li>
            <li>
              <p><b>Activate the new track</b></p>
              <p>When you&apos;re finished making all your changes, and confirmed they work by previewing them, click the Check button <FontAwesomeIcon icon="check" /> on the new copy. This will deactivate the existing track for the same exam section (and difficulty), and activate the new track. New and existing users will immediately start using it.</p>
            </li>
          </ol>
        </p>
      </div>
      <DataTable
        {...dataQueryTableProps}
        columns={[
          {
            name: 'Exam Section',
            selector: 'examSection.Name',
            cell: (props) => (`${props.examSection.branding} ${props.examSection.name}`)
          },
          {
            name: 'Container Block',
            selector: 'containerBlock.name',
            cell: (props) => (
              <a href={adminPaths.editContentBlock(props.containerBlock.id)}>
                {props.containerBlock.name}
              </a>
            )
          },
          {
            name: 'Difficulty',
            selector: 'difficulty'
          },
          {
            name: 'Active',
            selector: 'active',
            cell: (props) => (
              <>
                {props.active.current && (
                  <FontAwesomeIcon icon="check" className='text-primary' />
                )}
              </>
            )
          }
        ]}>
        <DataTable.ItemActions>
          {(row: any) => (
            <div style={{width: '100%', display: 'flex', justifyContent: 'start', marginLeft: '5rem'}}>
              <BorderlessButton onClick={() => onClickCopy(row)} icon="copy" title={'Copy to new track'}>
              </BorderlessButton>
              { row.inUse ?
                <BorderlessButton onClick={() => onClickReset(row)} icon="rotate-right" title={'Reset this track for your personal account.'}>
                </BorderlessButton>
                :
                <BorderlessButton onClick={() => onClickPreview(row)} icon="eye" title={'Preview this track by setting your account to use it'}>
                </BorderlessButton>
              }
              {!row.active.current && (
                <BorderlessButton onClick={() => onClickActivate(row)} icon="check" title={'Activate this track for all users'}>
                </BorderlessButton>
              )}
            </div>
          )}
        </DataTable.ItemActions>
      </DataTable>
      { showCopyModal && (
        <Modal
          onHide={() => {
            setShowCopyModal(false)
            setShowConfirmCopyModal(false)
          }}
          show={showCopyModal}
          title={'Copy Module Track Template'}
          showCloseButton={true}>
          <div>
            { showConfirmCopyModal ?
              <>
                <p>
                  New copy of the module track template is being created. This process may take a few minutes.
                </p>
                <PrimaryButton
                  className="u-margin-T-s"
                  onClick={() => {
                    setShowConfirmCopyModal(false);
                    setShowCopyModal(false);
                  }}>
                  Ok
                </PrimaryButton>
              </>
              :
              <MTTCopyForm
                mtt={currentMTT}
                runQuery={runQuery}
                showConfirm={() => setShowConfirmCopyModal(true)}
              />
            }
          </div>
        </Modal>
      )}
      <Modal
        onHide={() => setShowPreviewModal(false)}
        show={showPreviewModal}
        title={'Preview Module Track Template'}
        showCloseButton={true}>
        <div>
          <MTTPreviewForm
            mtt={currentMTT}
            runQuery={runQuery}
            closeModal={() => setShowPreviewModal(false)}
          />
        </div>
      </Modal>
      <Modal
        onHide={() => setShowResetModal(false)}
        show={showResetModal}
        title={'Reset Module Track Template'}
        showCloseButton={true}>
        <div>
          <MTTResetForm
            mtt={currentMTT}
            runQuery={runQuery}
            closeModal={() => setShowResetModal(false)}
          />
        </div>
      </Modal>
      <Modal
        onHide={() => setShowActivateModal(false)}
        show={showActivateModal}
        title={'Activate Module Track Template'}
        showCloseButton={true}>
        <div>
          <MTTActivateForm
            mtt={currentMTT}
            runQuery={runQuery}
            closeModal={() => setShowActivateModal(false)}
          />
        </div>
      </Modal>
    </div>
  )
}
