export const upsellPoints = {
  short: {
    act: [
      '1300+ practice questions',
      'Video and/or text explanations',
      'Up to 4 practice tests',
      '250+ video lessons',
      'Curated study schedules',
      'Accurate, data-driven score prediction'
    ],
    gmat: [
      'Math, verbal, and data insights',
      'Practice tests',
      '200+ video lessons',
      'Score increase guarantee',
      '800+ practice questions',
      'MBA admissions support'
    ],
    gre: [
      'Licensed official GRE® questions',
      'Practice tests',
      'Expert video lessons',
      'Customizable study schedules',
      '1600+ practice questions',
      'Accurate score predictor'
    ],
    ielts: [
      'Speaking, listening, reading, and writing',
      'Up to 10 full-length mock tests',
      '120+ video lessons',
      '850+ practice questions',
      'Graded speaking assessments',
      'Graded writing assessments'
    ],
    ielts_general_training: [
      'Speaking, listening, reading, and writing',
      'Up to 10 full-length mock tests',
      '120+ video lessons',
      '850+ practice questions',
      'Graded speaking assessments',
      'Graded writing assessments'
    ],
    lsat: [
      'Comprehensive prep for the LSAT',
      '7000+ official LSAT questions',
      'Explanation videos from expert tutors',
      'Practice tests',
      'Customizable study schedules',
      'Score improvement guaranteed'
    ],
    mcat: [
      'Comprehensive MCAT prep',
      '380+ content review records',
      '700+ practice questions',
      'Full-length practice test',
      'Customizable study schedules',
      'Score improvement guaranteed'
    ],
    sat: [
      'Digital SAT ready',
      '1750+ practice questions',
      'Video and/or text explanations',
      'Up to 3 practice tests',
      '200+ video lessons',
      'Study schedules',
      'Data-driven score prediction'
    ],
    toefl: [
      'Graded writing assignments',
      'Practice tests',
      'Expert video lessons',
      'Custom study schedules',
      '420+ practice questions',
      'Accurate score prediction'
    ]
  },
  long: {
    act: [
      '1300+ practice questions covering every concept tested on the ACT',
      'Every question has its own explanation video or text explanations detailing wrong answers',
      'Up to 4, full-length practice tests',
      'Over 250 video lessons that teach you every ACT concept',
      'Expert designed prep schedules and checklists to keep you on track',
      'Accurate, data-driven score prediction so you’ll know when you’re ready'
    ],
    gmat: [
      'High-quality material for each section of the test created by GMAT experts',
      'Time yourself taking a full-length practice test, just like you would on test day',
      '200+ video lessons to help you learn new skills and study hacks to improve your GMAT Focus score',
      'Increase your score by up to 70 points or your money back',
      '800+ practice questions each with a video explanation',
      'Insider advice on how to build your best MBA application'
    ],
    gre: [
      '8 full sections worth of licensed official GRE® questions from ETS',
      'Timed, full-length practice tests from our pool of questions',
      'Expert video lessons covering every concept tested on the GRE',
      'Study checklists to stay motivated, 1 week to 6 months long',
      '1600+ practice questions each with a video explanation',
      'Accurate score predictor so you’ll know when you’re ready'
    ],
    ielts: [
      'Comprehensive coverage of IELTS Speaking, Listening, Reading, and Writing',
      'Up to 10 full-length mock tests',
      'Over 120 video lessons that teach you every IELTS concept',
      '850+ practice questions each with a video explanation',
      'Receive detailed feedback and a grade report on your speaking interviews and essays'
    ],
    ielts_general_training: [
      'Comprehensive coverage of IELTS Speaking, Listening, Reading, and Writing',
      'Up to 10 full-length mock tests',
      'Over 120 video lessons that teach you every IELTS concept',
      '850+ practice questions each with a video explanation',
      'Receive detailed feedback and a grade report on your speaking interviews and essays'
    ],
    lsat: [
      'Comprehensive prep for the current LSAT and the new August 2024 LSAT',
      'Access thousands of official questions through our official integration with LSAC® Prep',
      '700+ questions with expert video explanations, so you learn from every mistake',
      'Over 55 official practice tests',
      "Get checklists and schedules to keep you motivated (whether you're studying for 1 month or 6 months)",
      "We guarantee that you'll improve your score by at least 5 points, or your money back"
    ],
    mcat: [
      'Cover all MCAT subjects from CARS to physics, with a focus on high-yield topics, so you are prepared on test day',
      'Over 380 content review videos tailored to what the MCAT tests',
      '700+ practice questions each with a video explanation',
      'Practice your pacing by taking a full-length practice test or practice individual sections',
      'Day-by-day study plans to keep you motivated and on track',
      "We guarantee that you'll improve your score by at least 10 points, or we'll give you your money back"
    ],
    sat: [
      'Digital SAT ready',
      '1750+ practice questions covering every concept tested on the SAT',
      'Every question has its own explanation video or text explanations detailing wrong answers',
      'Up to 3, full-length practice tests',
      'Over 200 video lessons that teach you every SAT concept',
      'Expert designed prep schedules and checklists to keep you on track',
      'Accurate, data-driven score prediction so you’ll know when you’re ready'
    ],
    toefl: [
      'Our TOEFL experts grade your essay and provide tips for improvement',
      'Up to 6, timed, full-length practice tests',
      'Over 120 video lessons that teach you every TOEFL concept',
      'Study checklists to stay motivated, 2 weeks to 2 months long',
      '420+ practice questions each with a video explanation',
      'Accurate score predictor so you’ll know when you’re ready'
    ]
  }
}
