import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { isString } from 'lodash';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import { ModalInterface, ModalContextInterface } from './types';

// This context allows for easy control and rendering of a modal. Usage example:

// const { setModal } = useModalContext()

// To open a modal. You can pass a string or ReactNode as the `header`.
// setModal({body: <p>This would be the body</p>});
// setModal({body: <p>body</p>, header: 'Optional Title'})

// To close the modal
// setModal(null);

export const ModalContext = React.createContext<ModalContextInterface | null>(null);

export const useModalContext = () => {
  const context = useContext(ModalContext) as ModalContextInterface;
  if (context === undefined) {
    throw new Error('You must wrap your page with <ModalProvider /> in order to useModalContext().');
  }
  return context;
};

export const ModalProvider: React.FC = ({ children }) => {
  // Check if we already are in a modal context, and if so just return the children
  const modalContext = useContext(ModalContext);
  if (modalContext) return <>{children}</>;

  const setModal = (modal: ModalInterface) => {
   setData({...data, modal});
  };

  const [data, setData] = React.useState({ modal: null });

  return (
    <ModalContext.Provider value={{ setModal }}>
      {children}

      <Modal bsSize={data.modal?.size || 'md'} show={!!data.modal}  onHide={() => setModal(null)}>
        {
          data.modal && data.modal.header
          ? <Modal.Header className="u-border-A-n u-margin-V-s" style={{ paddingLeft: 30, paddingRight: 30 }}>
              <div className="align-items-center d-flex">
                <div className="flex-1">
                  {isString(data.modal.header) ? <h3 className="u-margin-A-n"><strong>{data.modal.header}</strong></h3> : data.modal.header}
                </div>

                <div className="align-self-top u-padding-L-s" onClick={() => setModal(null)} style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon="close" style={{ fontSize: 20 }} />
                </div>
              </div>
            </Modal.Header>
          : null
        }
        <Modal.Body className="u-padding-T-n">
          {data.modal && data.modal.body}
        </Modal.Body>
      </Modal>
    </ModalContext.Provider>
  );
};
