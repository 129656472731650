import React, { Children, cloneElement } from 'react';
import cx from 'classnames';

interface TextButtonProps {
  active?: boolean;
  children: string;
  name: string;
  onMakeActive?: () => void;
}

const Tab:React.FC<TextButtonProps> = (props) => {
  if (props.active) {
    return (
      <div style={{ fontSize: 20 }}><strong>{props.children}</strong></div>
    );
  } else {
    return (
      <a className="text-gray-light" onClick={props.onMakeActive}>{props.children}</a>
    );
  }
};

interface Props {
  currentTab: string;
  onChange: (name: string) => void;
}


class TextTabButtons extends React.Component<Props> {
  static Tab = Tab;

  render() {
    const allChildren = Children.toArray(this.props.children);

    return (
      <div className="align-items-center d-flex" style={{ gap: 10 }}>
        {
          Children.map(allChildren, (child: React.ReactElement<any>, idx) => (
            <>
              {idx !== 0 && <div className="align-self-stretch bg-gray-lighter" style={{ width: 1}} />}
              <div key={idx}>
                {
                  cloneElement(
                    child,
                    {
                      active: child.props.name === this.props.currentTab,
                      onMakeActive: () => this.props.onChange(child.props.name),
                      ...child.props
                    }
                  )
                }
              </div>
            </>
          ))
        }
      </div>
    );
  }
}

export default TextTabButtons;
