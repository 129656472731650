import moment from 'moment';

import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';

export const fetchApplicationCycleChunks = ({ cycle, startingAt, numberOfChunks, filters }) => {
  return fetch(paths.api.admissions.applicationCycleChunks(cycle, startingAt, numberOfChunks, filters)).then((response) => {
    return response.chunks;
  });
};

export const fetchApplicationCyclePlan = (cycle: string) => {
  return fetch(paths.api.admissions.applicationCyclePlan(cycle)).then((response) => {
    return response.plan;
  });
};

export const fetchApplicationCycleSections = (cycle: string) => {
  return fetch(paths.api.admissions.applicationCycleSections(cycle)).then((response) => {
    return response.sections;
  });
};

export const fetchHighlightedContent = () => {
  return fetch(paths.api.admissions.highlightedContent()).then((response) => {
    return response.highlightedContent;
  });
};

export const fetchStudySchedule = () => {
  return fetch(paths.api.studyScheduleCurrent()).then((response) => {
    return response.studySchedule;
  });
};

export const toggleApplicationCyclePlanItemComplete = ({ cycle, item }) => {
  const completedAt = item.isCompleted ? null : moment().utc().format('YYYY-MM-DDThh:mm:ss.SSSZ');

  return fetch(paths.api.admissions.applicationCyclePlanItem(cycle, item.id), {
    method: 'PUT',
    body: JSON.stringify({ item: { completedAt } })
  });
};

export const toggleApplicationCyclePlanTaskComplete = ({ cycle, item, task }) => {
  const completedAt = task.isCompleted ? null : moment().utc().format('YYYY-MM-DDThh:mm:ss.SSSZ');

  return fetch(paths.api.admissions.applicationCyclePlanItemTask(cycle, item.id, task.id), {
    method: 'PUT',
    body: JSON.stringify({ task: { completedAt } })
  });
};

export const updateOrCreateApplicationCycleDeadline = ({ cycle, deadline }) => {
  const method = deadline.id ? 'PATCH' : 'POST';

  return fetch(paths.api.admissions.applicationCycleDeadline(cycle, deadline.id), {
    method: method,
    body: JSON.stringify({ deadline: { ...deadline } })
  });
};
