import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import cx from 'classnames';
import { map, sortBy } from 'lodash';

import { EditorialChart } from '../types';

import style from './style.module.scss';

const COLORS = [
  '#8BD5F4',
  '#4765B0',
  '#81EBA5',
  '#1F875D',
  '#FF9A9A',
  '#FF3366',
  '#FDBB7A',
  '#F38722',
  '#BF7AFF',
  '#4D2079'
];
const HEIGHT = 300;
const WIDTH = 300;

const ContentfulEditorialChart: React.FC<EditorialChart & { className?: string; }> = (props) => {
  const formattedData = () => {
    const data = map(props.data, (data) => (
      {
        id: data.label,
        label: data.label,
        value: data.value
      }
    ));

    const sortedData = sortBy(data, (d) => d.value * -1);

    return sortedData.map((d, idx) => (
      { ...d, color: COLORS[idx % COLORS.length] }
    ));
  };

  const flexDirection = () => {
    if (props.legendPosition === 'bottom')
      return 'column';
    else if (props.legendPosition === 'top')
      return 'column-reverse';
    else if (props.legendPosition === 'left')
      return 'row-reverse';
    else if (props.legendPosition === 'right')
      return 'row';
    else
      return null;
  }

  return (
    <div className={cx(props.className, style.chartContainer)}>
      {props.name && <div className="u-margin-B-m"><strong>{props.name}</strong></div>}

      <div className={cx(style.chart, 'd-flex')} style={{ flexDirection: flexDirection(), gap: 40 }}>
        <div style={{ height: HEIGHT , margin: 'auto', width: WIDTH }}>
          <ResponsivePie
            colors={COLORS}
            data={formattedData()}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            fit
            innerRadius={0.6}
          />
        </div>

        {
          props.legendPosition !== 'none' && (
            <div className="d-flex flex-column" style={{ gap: 5, margin: 'auto' }}>
              {
                formattedData().map((d) => (
                  <div key={d.id} className="align-items-center d-flex small">
                    <div className="align-items-center d-flex" style={{ gap: 10, width: 80 }}>
                      <div style={{ borderRadius: '50%', height: 10, width: 10, backgroundColor: d.color }} />

                      <div>
                        <strong>{d.value}%</strong>
                      </div>
                    </div>

                    <div>
                      {d.label}
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ContentfulEditorialChart;
