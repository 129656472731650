import {Subscription} from "./types";
import {Col, Row} from "react-bootstrap";
import style from "./style.module.scss";
import * as React from "react";
import { FontAwesomeIcon } from '@magoosh/lib/icons';

export class StudentStrengthWeaknesses extends React.Component<Subscription> {

  render() {
    const subscription = this.props;
    const sectionStats = subscription.sectionStats;

    return (
      <div>
        <Row>
          <Col md={9} className={style.sectionHeader}>
                        Strengths & Weaknesses
          </Col>
          <Col md={3} className={style.sectionHeader}>
            <a href={subscription.answersReviewUrl}>
              <FontAwesomeIcon icon='list-alt'></FontAwesomeIcon>&nbsp;
              Review all {subscription.displayName} answers
            </a>
          </Col>
        </Row>
        <Row className={style.headerRow}>
          <Col md={2} />
          <Col md={3}>% Correct</Col>
          <Col md={3}>Explanations Watched</Col>
          <Col md={3}>Average Pace</Col>
        </Row>
        {Object.keys(sectionStats).map(examSectionName => {
          const examSectionStats = sectionStats[examSectionName];

          if (!examSectionStats) { return; }

          const correctAnswers = examSectionStats.correctAnswers;
          const totalAnswers = examSectionStats.totalAnswers;
          const videosWatched = examSectionStats.explanationVideosWatched;

          return (
            <Row key={examSectionName} className={style.strengthsWeaknesses}>
              <Col md={2}>{examSectionName}</Col>
              <Col md={3}>
                {this.renderPercentage(correctAnswers, totalAnswers)}
              </Col>
              <Col md={3}>
                {this.renderPercentage(videosWatched, totalAnswers)}
              </Col>
              <Col md={3}>{examSectionStats.averagePace}</Col>
            </Row>
          )
        })}
      </div>
    )
  }

  renderPercentage = (numerator, denominator) => {
    if (numerator === -1) { return 'N/A'; }
    const percent = denominator > 0 ? Math.round((numerator / denominator) * 100) : 0;
    return (<span>{percent}%&nbsp;&nbsp;<span className={style.ratio}>({numerator}/{denominator})</span></span>);
  };
}
