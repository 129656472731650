import React, { useContext, useEffect, useState } from 'react';
import { CreateEditClassFormValues } from './types';
import { Switch, Route, Redirect, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { NavBar, NavLink } from '../../organisms/nav_bar';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import { FieldSelect } from '@magoosh/lib/formik_inputs/field_select/template';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { DefaultButton, PrimaryButton, SecondaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import colors from '../../assets/style.module.scss';
import PageColumn from '../../lib/page_column';
import Icon from '../../assets/icons';
import PreviewListField from '../../lib/preview_list_field';
import SelectInstructorsFormTab from '../../organisms/select_instructors_form_tab';
import NewInstructorFormTab from '../../organisms/new_instructor_form_tab';
import fetch from 'utilities/fetch';
import { uniqWith, isEqual, compact } from 'lodash';
import { ErrorMessages } from '@magoosh/lib/formik_inputs/errors_messages';
import { Spinner } from '@magoosh/b2b/app/lib/spinner';
import { AppContext } from '@magoosh/b2b/app/app_context';
import styles from '@magoosh/b2b/app/organisms/nav_bar/style.module.scss';

const validationSchema = yup
  .object({
    id: yup.number().nullable(),
    schoolId: yup.number().required('School is required.'),
    displayName: yup.string().required('Class Name is required.'),
    subject: yup.string().nullable(),
    period: yup.string().nullable(),
    grade: yup.string().nullable(),
    sectionNumber: yup.string().nullable(),
    instructors: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(true),
        email: yup.string().required(),
        firstName: yup.string(),
        lastName: yup.string()
      })
    )
  })
  .test('instructors-on-create', 'Please add instructors to the list.', (classSection) => {
    return classSection.id || classSection.instructors.length > 0;
  });

export const CreateEditClassForm: React.FC<{ classSection?: ClassSection }> = (props) => {
  const { classSectionId } = useParams();
  const location = useLocation();
  const [schools, setSchools] = useState([]);
  const [gradeOptions, setGradeOptions] = useState({});
  const [existingInstructors, setExistingInstructors] = useState([]);
  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingClassSection, setLoadingClassSection] = useState(classSectionId != null);
  const [showAccessDialog, setShowAccessDialog] = useState(false);
  const appContext = useContext(AppContext);

  const [initialValues, setInitialValues] = useState({
    id: classSectionId,
    displayName: '',
    schoolId:
      (props.classSection && props.classSection.schoolId) ||
      (location.state && location.state.schoolId) ||
      (schools.length == 1 && schools[0].id) ||
      '',
    instructors: []
  });

  const history = useHistory();
  const { url, path } = useRouteMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (classSectionId) {
      fetch(paths.api.partner.class(classSectionId)).then((response) => {
        setInitialValues({
          ...initialValues,
          ...response
        });

        setLoadingClassSection(false);
      });
    }
  }, [classSectionId]);

  useEffect(() => {
    fetch(paths.api.partner.classForm()).then((response) => {
      setSchools(response.schools);

      if (initialValues.schoolId == '' && response.schools.length == 1) {
        setInitialValues({
          ...initialValues,
          schoolId: response.schools[0].id
        });
      }

      setExistingInstructors(
        response.instructors.map((instructor) => {
          return { ...instructor, isHidden: false, isSelected: false };
        })
      );

      setGradeOptions(
        response.grades.reduce((map, obj) => {
          map[obj] = obj;
          return map;
        }, {})
      );

      setLoadingForm(false);
    });
  }, []);

  const handleOnSubmit = (
    values: CreateEditClassFormValues,
    { setSubmitting, setStatus }: FormikActions<CreateEditClassFormValues>
  ): void => {
    setStatus(null);
    setSubmitting(true);

    if (
      !appContext.supervisor &&
      !values.instructors.some(instructor => instructor.email == appContext.currentUserEmail) &&
      !showAccessDialog
    ) {
      setShowAccessDialog(true);
      return;
    }

    const [url, method] = classSectionId
      ? [paths.api.partner.class(classSectionId), 'PUT']
      : [paths.api.partner.classes(), 'POST'];

    fetch(url, {
      body: JSON.stringify(values),
      method: method
    }).then((response) => {
      if (response && response.id) {
        history.push(`/classes/${response.id}`);
      } else {
        // This happens if the the current user doesn't have access to the created class, i.e. they
        // are a non-supervisor who didn't add themselves as an instructor. So just direct them to
        // /classes instead.
        history.push('/classes');
      }
    })
      .catch((response) => {
        setStatus(response.errors);
        setSubmitting(false);
      });
  };

  const addSelectedInstructors = (instructors: Person[], setFieldValue, values) => {
    setFieldValue('instructors', uniqWith([...values.instructors, ...instructors], isEqual));
  };

  const addNewInstructor = (instructor: Person, setFieldValue, values) => {
    setFieldValue('instructors', uniqWith([...values.instructors, { id: null, ...instructor }], isEqual));
  };

  const removeInstructor = (deletedInstructor, setFieldValue, values) => {
    const filteredList = values.instructors.filter((instructor) =>
      deletedInstructor.id
        ? deletedInstructor.id !== instructor.id
        : deletedInstructor.email !== instructor.email ||
          deletedInstructor.firstName !== instructor.firstName ||
          deletedInstructor.lastName !== instructor.lastName
    );
    setFieldValue('instructors', filteredList);
  };

  return (
    <>
      <PageColumn>
        <div className={style.createClassForm}>
          <div className={style.formHeader}>
            <div className={style.headerText}>
              <h2 className="u-margin-T-n">{classSectionId ? 'Edit' : 'Create New'} Class</h2>
              {!classSectionId && <h4>Add the following details about the new class you want to create.</h4>}
            </div>
            <div className="u-margin-L-s">
              <Icon iconType="Class" fill={colors.supplementaryRadRed} size="l" />
            </div>
          </div>

          {loadingForm || loadingClassSection ? (
            <Spinner />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}>
              {({ isSubmitting, submitForm, setFieldValue, values, errors, status, setSubmitting }) => (
                <Form>
                  <FieldSelect
                    className="form-control"
                    name="schoolId"
                    label="School"
                    objects={schools}
                    keyAccessor="name"
                    valueAccessor="id"
                    placeholder="School"
                    block={true}
                    disabled={props.classSection ? true : false}
                  />

                  <FieldInput name="displayName" type="input" label="Class Name" />
                  <Row>
                    <Col xs={6}>
                      <FieldInput name="subject" type="input" label="Subject" optional />
                    </Col>
                    <Col xs={6}>
                      <FieldInput name="period" type="input" label="Period" optional />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <FieldSelect
                        className="form-control"
                        name="grade"
                        label="Grade"
                        options={gradeOptions}
                        block={true}
                        optional={true}
                      />
                    </Col>
                    <Col xs={6}>
                      <FieldInput name="sectionNumber" type="input" label="Section Number" optional />
                    </Col>
                  </Row>
                  {!classSectionId && (
                    <>
                      <NavBar>
                        <NavLink to={`${url}/select-instructors`} text="Select Instructors" />
                        { !appContext.trialAccess ?
                          <NavLink to={`${url}/new-instructors`} text="New Instructors" />
                          :
                          <DefaultButton className={styles.tab} disabled={true}>New Instructors</DefaultButton>
                        }
                      </NavBar>
                      <Switch>
                        <Route exact path={`${path}/`}>
                          <Redirect to={`${url}/select-instructors`} />
                        </Route>
                        <Route path={`${path}/select-instructors`}>
                          <SelectInstructorsFormTab
                            instructorsList={existingInstructors}
                            addSelectedInstructors={(instructors) =>
                              addSelectedInstructors(instructors, setFieldValue, values)
                            }
                          />
                        </Route>
                        { !appContext.trialAccess &&
                          <Route path={`${path}/new-instructors`}>
                            <NewInstructorFormTab
                              addNewInstructor={(instructor) =>
                                addNewInstructor(instructor, setFieldValue, values)
                              }
                            />
                          </Route>
                        }
                      </Switch>
                      <hr />

                      <PreviewListField
                        label="Instructors of the Class"
                        emptyMessage="Please add new or existing instructors to the enrollment list."
                        name="instructors"
                        removeItem={(deletedInstructor) =>
                          removeInstructor(deletedInstructor, setFieldValue, values)
                        }>
                        {(item: Person) => (
                          <>
                            <strong>
                              {item.fullName || compact([item.lastName, item.firstName]).join(', ')}
                            </strong>{' '}
                            {item.email}{' '}
                          </>
                        )}
                      </PreviewListField>
                    </>
                  )}

                  <Row className="u-margin-T-s">
                    <Col xs={6}>
                      <SecondaryButton disabled={isSubmitting} onClick={submitForm} block>
                        {classSectionId ? 'Save' : 'Create New'} Class
                      </SecondaryButton>
                    </Col>

                    <Col xs={6}>
                      <DefaultButton onClick={() => history.goBack()} block>
                        Cancel
                      </DefaultButton>
                    </Col>
                  </Row>

                  <ErrorMessages errors={errors} />
                  <ErrorMessages errors={status || []} />

                  {!classSectionId && (
                    <p className="u-margin-T-s">
                      <small>
                        <FontAwesomeIcon icon={'info-circle'} /> An account will be created for each new
                        instructor giving them access to manage this class. Emails will be sent to the
                        instructors with directions for setting up their account passwords.
                      </small>
                    </p>
                  )}
                  <Modal
                    show={showAccessDialog}
                    onHide={() => {
                      setSubmitting(false);
                      setShowAccessDialog(false);
                    }}>
                    <Modal.Body>
                      <p>
                        <strong>Note:</strong> since you haven&apos;t added yourself as an
                        instructor to this class, you won&apos;t have access to it after it&apos;s
                        created.
                      </p>
                      <p>
                        Either click <strong>Cancel</strong> and add yourself as an instructor,
                        or <strong>Continue</strong> to create the class anyway.
                      </p>

                      <div className={style.accessDialogButtons}>
                        <DefaultButton
                          onClick={() => {
                            setSubmitting(false);
                            setShowAccessDialog(false);
                          }}>
                          Cancel
                        </DefaultButton>
                        <SecondaryButton
                          onClick={submitForm}>
                          Continue
                        </SecondaryButton>
                      </div>
                    </Modal.Body>
                  </Modal>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </PageColumn>
    </>
  );
};

export default CreateEditClassForm;
