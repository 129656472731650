import React from 'react';
import cx from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  name: string;
  score: number | string;
  scoreReason?: string;
}

const ScoreCard: React.FC<Props> = (props) => {
  return (
    <div className={cx(style.card, 'd-flex align-items-center flex-row flex-lg-column u-padding-H-l u-padding-V-s')}>
      <div className={style.sectionName}>
        <strong>{props.name}</strong>
      </div>

      <div className="align-content-end flex-1">
        {
          props.scoreReason ? (
            <OverlayTrigger overlay={<Tooltip id={`no-score-reason-tooltip-${props.name}`}>{props.scoreReason}</Tooltip>} placement="top">
              <span
                className="h2 text-primary text-underdash u-margin-A-n"
                style={{ borderColor: colors.brandPrimary }}
              >
                <strong>{props.score || 'NS'}</strong>
              </span>
            </OverlayTrigger>
          ) : (
            <span
              className={cx('h2 text-primary u-margin-A-n', { 'text-underdash': !props.score })}
              style={{ borderColor: colors.brandPrimary }}
            >
              <strong>{props.score || 'NS'}</strong>
            </span>
          )
        }
      </div>
    </div>
  );
};

export default ScoreCard;
