const breakdownSections = [
  {
    name: 'Assumption',
    percentCorrect: 67
  },
  {
    name: 'Flawed Argument',
    percentCorrect: 50
  },
  {
    name: 'Inference',
    percentCorrect: 44
  },
  {
    name: 'Paradox',
    percentCorrect: 28
  },
  {
    name: 'Method of Argument',
    percentCorrect: 27
  },
  {
    name: 'Parallel Flaw',
    percentCorrect: 22
  }
];

const scoreSections = [
  {
    name: 'Logic Games',
    sampleScore: 16,
    scoreRange: 'Questions correct'
  },
  {
    name: 'Logical Reasoning',
    sampleScore: 15,
    scoreRange: 'Questions correct'
  },
  {
    name: 'Reading Comp',
    sampleScore: 25,
    scoreRange: 'Questions correct'
  },
  {
    name: 'Total',
    sampleScore: 161,
    scoreRange: 'Score range: 120-180'
  }
];

// The 24/25 LSAT does not have the Logic Games section
const scoreSections2425 = [
  {
    name: 'Logical Reasoning',
    sampleScore: 31,
    scoreRange: 'Questions correct'
  },
  {
    name: 'Reading Comp',
    sampleScore: 25,
    scoreRange: 'Questions correct'
  },
  {
    name: 'Total',
    sampleScore: 161,
    scoreRange: 'Score range: 120-180'
  }
];

export default {
  null: { breakdownSections, scoreSections },
  '24_25_lsat': { breakdownSections, scoreSections: scoreSections2425 }
};
