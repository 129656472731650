import * as React from 'react';
import StudyDayLabel from '../study_day_label';
import { WeekDayBooleans } from 'types/week_day_booleans';

interface StudyDayPickerProps extends WeekDayBooleans {
  onToggleDay: (day: string, prevValue: boolean) => any;
}

const DAYS = [
  ['monday', 'M'],
  ['tuesday', 'T'],
  ['wednesday', 'W'],
  ['thursday', 'Th'],
  ['friday', 'F'],
  ['saturday', 'Sa'],
  ['sunday', 'Su']
];

export default class StudyDayPicker extends React.Component<StudyDayPickerProps> {
  render() {
    return (
      <div>
        {DAYS.map(([day, abbreviation]) => (
          <StudyDayLabel
            backgroundColor={this.props[day] ? 'green' : 'gray'}
            underline={false}
            key={day}
            onClick={() => this.props.onToggleDay(day, this.props[day])}>
            {abbreviation}
          </StudyDayLabel>
        ))}
      </div>
    );
  }
}
