import * as React from 'react';
import { Field } from 'formik';
import without from 'lodash/without';
import { FieldCheckboxGroupProps } from './types';

export const FieldCheckboxGroup: React.FC<FieldCheckboxGroupProps> = (props) => {
  const selected = props.selected || [];

  return (
    <>
      {props.label && (
        <>
          <label htmlFor={props.name}>{props.label}</label>
          <br />
        </>
      )}
      {props.description && <p className="u-margin-B-n">{props.description}</p>}
      <fieldset className="checkbox">
        <div className="controls check_boxes form-group">
          <div>
            {props.optionsArray.map((option) => (
              <div className="radio u-margin-T-n" key={option.value}>
                <label className="d-block">
                  <Field
                    component="input"
                    type="checkbox"
                    name={option.value}
                    value={option.value}
                    checked={selected && selected.includes(option.value)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        props.onChange([...selected, option.value]);
                      } else {
                        props.onChange(without(selected, option.value));
                      }
                    }}
                  />
                  {option.displayName}
                  {option.children && selected && selected.includes(option.value) && option.children}
                </label>
              </div>
            ))}
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default FieldCheckboxGroup;
