import React from 'react';

import HandsRaisedIcon from '../assets/hands_raised.svg';
import MapIcon from '../assets/map.svg';

const infoSections = [
  {
    icon: <img src={HandsRaisedIcon} alt="Hands raised in the air" />,
    name: 'Improve Your GMAT Focus Score, Guaranteed',
    text: 'Magoosh has high quality practice questions, full-length practice tests, expert video lessons, custom study schedules and more.'
  },
  {
    icon: <img src={MapIcon} alt="Map showing a path to follow" />,
    name: 'Plus! Accurate Score Predictor',
    text: "Be confident when you're ready with our score predictor tool that's based on official scores from Magoosh students."
  }
];

export default { infoSections };
