import * as React from 'react';
import cx from 'classnames';

import ProgressBar from '@magoosh/lib/progress_bar';

import StudyWeekView from '../study_week_view';
import style from './style.module.scss';
import {pluralize} from "utilities/pluralize";

export interface DashboardTimelineProps {
  onEditStudyPreferences: (event: any) => void;
  overallScore: {
    previous: number;
    target: number;
    scoreStatus: {
      label: string;
      type: string;
    };
  };
  streak: {
    current: number;
    longest: number;
  };
  studyHours: {
    recommended: number;
    userTotal: number;
  };
  testDate: string;
}

export default class DashboardTimeline extends React.Component<DashboardTimelineProps, {}> {
  render() {
    const studyHoursPercentage = Math.round(this.props.studyHours.userTotal / this.props.studyHours.recommended * 100);

    return (
      <div className="d-flex flex-row justify-content-around flex-wrap">
        <div className={style.flexCol}>
          <div className={cx(style.timelineSection, style.flexDivEqualHeight)}>
            <StudyWeekView/>
            <small>
              Study days this week
              <a className="u-margin-L-xs" onClick={this.props.onEditStudyPreferences}>
                <span className="fa fa-pencil text-body"/>
              </a>
            </small>
          </div>

          <div className={cx(style.timelineSection, 'hidden-xs hidden-sm')}>
            <ProgressBar
              backgroundColor="#cef0e3"
              color="#26B67C"
              percentage={studyHoursPercentage}
              size="lg"
              minLabel={this.props.studyHours.userTotal.toString()}
              maxLabel={this.props.studyHours.recommended.toString()}
              popoverText={`We recommend that students study at least ${this.props.studyHours.recommended} hours to prepare.`}
            />
            <small>Total study hours</small>
          </div>
        </div>

          <div className={style.streaks}>
            <div>
              <h3>
                <strong>{pluralize(this.props.streak.current, 'day')}</strong>
              </h3>
              <small>Current Streak</small>
            </div>
            <div>
              <h3>
                <strong>{pluralize(this.props.streak.longest, 'day')}</strong>
              </h3>
              <small>Longest Streak</small>
            </div>
          </div>
      </div>
    );
  }
}
