import React, { useEffect, useRef, useState } from 'react';
import { capitalize, debounce, isObject } from 'lodash';
import WidgetsCombobox from 'react-widgets/lib/Combobox';

import fetch from 'utilities/fetch';

export interface Props {
  id?: string;
  className?: string;
  data?: any[];
  defaultValue: any;
  disabled?: boolean;
  error?: string;
  filter?: string | ((any) => boolean);
  groupBy?: string | ((any) => any);
  label?: string | React.ReactNode;
  onChange: (value: string) => void;
  placeholder?: string;
  searchEndpoint?: (string) => string;
  textField?: string | ((dataItem?) => string);
  value: string;
  valueField?: string;
}

const Combobox: React.FC<Props> = (props) => {
  const [data, setData] = useState(props.data || []);
  const [isFetchingData, setIsFetchingData] = useState(false);

  useEffect(() => {
    if (data.length) return;

    fetchData('');
  }, []);

  const fetchData = (value) => {
    setIsFetchingData(true);

    fetch(
      props.searchEndpoint(value),
      { method: 'GET' }
    ).then((response) => {
      setData(response);
      setIsFetchingData(false);
    });
  };

  const debouncedSearch = useRef(
    debounce((value) => {
      fetchData(value);
    }, 250)
  ).current;

  const handleOnChange = (value) => {
    let newValue = value;
    if (isObject(value)) {
      newValue = props.valueField ? value[props.valueField] : value;
      props.onChange(newValue);
    } else {
      props.onChange(newValue);
    }

    debouncedSearch(newValue);
  };

  return (
    <div className={props.className}>
      <label htmlFor={props.id}>
        {
          React.isValidElement(props.label)
            ? props.label
            : capitalize(props.label as string)
        }
      </label>

      <WidgetsCombobox
        {...props}
        busy={isFetchingData}
        data={data}
        messages={{
          emptyList: `No matches, but you can still use "${props.value}".`
        }}
        value={props.value}
        onChange={(value) => handleOnChange(value)}
      />

      {
        props.error && (
          <div className="text-danger">
            {props.error}
          </div>
        )
      }
    </div>
  );
};

export default Combobox;
