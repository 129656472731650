import * as React from 'react';
import { upperFirst } from 'lodash';

import ModuleCard from '../template';

export default {
  title: 'Page | LessonModules/Organisims/ModuleCard',
  argTypes: {
    compact: {
      control: 'boolean',
      table: {
        type: {
          defaultValue: { summary: 'false' },
          summary: 'Condense the display of the card'
        }
      }
    },
    difficulty: {
      control: 'text',
      table: {
        type: {
          summary: 'Difficulty of the module'
        }
      }
    },
    id: {
      control: 'number',
      table: {
        type: {
          summary: '(required) Id of the module'
        }
      }
    },
    name: {
      control: 'text',
      table: {
        type: {
          summary: '(required) Name of the module'
        }
      }
    },
    setItemCompleted: {
      action: 'setItemCompleted',
      table: {
        type: {
          summary: '(required) Set the study item `completed` value'
        }
      }
    },
    showDifficulty: {
      control: 'boolean',
      table: {
        type: {
          defaultValue: { summary: 'false' },
          summary: 'Display the module difficulty prop as part of the name'
        }
      }
    },
    studyItems: {
      control: { disable: true },
      table: {
        type: {
          summary: 'Array of StudyItemData objects'
        }
      }
    }
  }
};

export const Default = (args) => <ModuleCard {...args} />;
Default.args = {
  id: 1,
  name: 'Algebra, Equations, and Inequalities',
  studyItems: [
    {
      completed: false,
      estimatedTimeMinutes: 6,
      id: 1,
      name: 'Simplifying Expressions',
      type: 'Lesson'
    },
    {
      completed: false,
      estimatedTimeMinutes: 8,
      id: 2,
      name: 'Practice',
      type: 'Practice'
    }
  ]
};

export const InProgress = (args) => <ModuleCard {...args} />;
InProgress.args = {
  id: 1,
  name: 'Algebra, Equations, and Inequalities',
  studyItems: [
    {
      completed: true,
      estimatedTimeMinutes: 6,
      id: 1,
      name: 'Simplifying Expressions',
      type: 'Lesson'
    },
    {
      completed: false,
      estimatedTimeMinutes: 8,
      id: 2,
      name: 'Practice',
      type: 'Practice'
    }
  ]
};

export const Completed = (args) => <ModuleCard {...args} />;
Completed.args = {
  id: 1,
  name: 'Algebra, Equations, and Inequalities',
  studyItems: [
    {
      completed: true,
      estimatedTimeMinutes: 6,
      id: 1,
      name: 'Simplifying Expressions',
      type: 'Lesson'
    },
    {
      completed: true,
      estimatedTimeMinutes: 8,
      id: 2,
      name: 'Practice',
      type: 'Practice'
    }
  ]
};
