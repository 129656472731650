import * as React from 'react';
import { PromptData, QuestionNodeData } from './types';
import style from './style.module.scss';
import { Col } from 'react-bootstrap';
import CheckInput from './check_input';
import RadioInput from './radio_input';
import ShortAnswerInput from './short_answer_input';
import cx from 'classnames';

interface PromptProps {
  prompts: PromptData[];
  questionNode: QuestionNodeData;
  review: boolean;
}

export default class Prompts extends React.Component<PromptProps> {
  render() {
    return (
      <Col sm={5} smOffset={1}>
        {this.renderPrompts()}
      </Col>
    );
  }

  // Currently unused because passages need to be rendered by Rails for Passage Tools to work
  renderPassage = () => {
    if (this.props.questionNode.type == 'QuestionPassageNode') {
      return (
        <div
          id={'highlightable-content-node'}
          dangerouslySetInnerHTML={{ __html: this.props.questionNode.content }}
        />
      );
    }
  };

  renderPrompts = () => {
    return (
      <div>
        {this.props.prompts.map((prompt) => {
          return (
            <div
              className={cx('prompt u-margin-B-s', prompt.highlighted ? style.highlighted : '')}
              key={`prompt_${prompt.id}`}>
              <span dangerouslySetInnerHTML={{ __html: prompt.content }} />
              {this.renderInputs(prompt)}
            </div>
          );
        })}
      </div>
    );
  };

  renderInputs = (prompt: PromptData) => {
    if (prompt.type === 'Radio') {
      return <RadioInput prompt={prompt} review={this.props.review} />;
    } else if (prompt.type === 'Check') {
      return <CheckInput prompt={prompt} review={this.props.review} />;
    } else if (prompt.type === 'Input') {
      return <ShortAnswerInput prompt={prompt} review={this.props.review} />;
    }
  };
}
