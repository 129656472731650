import * as React from 'react';
import style from './style.module.scss';
import cx from 'classnames';
import { renderLatexInRawHtml } from 'utilities/latex';

export interface HintPopupProps {
  text: string;
  type: 'success' | 'warning' | 'default';
  onClick: () => void;
}

export default function HintPopup(props: HintPopupProps) {
  return (
    <div className={style.popupBackground} onClick={props.onClick}>
      <button
        className={cx(style.hintPopup, style[props.type])}
        dangerouslySetInnerHTML={{ __html: renderLatexInRawHtml(props.text) }}
      />
    </div>
  );
}
