import React from 'react';

import { CurrentProfileContextData, CurrentProfileContextInterface } from './types';

const CurrentProfileContext = React.createContext<CurrentProfileContextInterface | null>(null);

export const useCurrentProfileContext = () => {
  const context = React.useContext(CurrentProfileContext) as CurrentProfileContextInterface;
  if (context === undefined) {
    throw new Error('You must wrap your page with <CurrentProfileProvider /> in order to useCurrentProfileContext().');
  }
  return context;
};

interface Props {
  initialData?: CurrentProfileContextData;
}

export const CurrentProfileProvider: React.FC<Props> = ({ children, initialData }) => {
  const defaultData = {
    applicationCycle: null
  } as CurrentProfileContextData;

  const [data, setData] = React.useState(initialData || defaultData);

  return <CurrentProfileContext.Provider value={{ currentProfile: data } as CurrentProfileContextInterface}>{children}</CurrentProfileContext.Provider>;
};
