import React from 'react';

import { secondsFormattedAsMinutes } from 'utilities/time';

import StatisticData from '../statistic_data';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  filters: any;
}

const PaceStat: React.FC<Props> = (props) => {
  const loadingPlaceholder = (
    <PaceContent color={colors.gray85} data={null} />
  );

  if (!props.filters) {
    return loadingPlaceholder;
  } else {
    return (
      <StatisticData
        filters={props.filters}
        name="pace"
      >
        <StatisticData.Loading>
          {loadingPlaceholder}
        </StatisticData.Loading>

        {/* @ts-ignore */}
        <PaceContent color={colors.dataDarkPurple} />
      </StatisticData>
    );
  }
};

interface PaceContentProps {
  color: string;
  data: {
    others: {
      value: number;
    };
    user: {
      value: number;
    };
  };
}

const PaceContent: React.FC<PaceContentProps> = (props) => {
  return (
    <div className="align-items-center d-flex flex-column gap-xs">
      <div style={{ color: props.color, lineHeight: 1 }}>
        <span style={{ fontSize: 36 }}>
          <strong>{props.data?.user?.value ? secondsFormattedAsMinutes(props.data.user.value) : '-:--'}</strong>
        </span>

        <span style={{ fontSize: 24 }}>
          <strong> / Question</strong>
        </span>
      </div>

      {
        props.data?.others?.value && (
          <div className="text-gray-light">
            <small>
              Other students averaged {secondsFormattedAsMinutes(props.data.others.value)}
            </small>
          </div>
        )
      }
    </div>
  );
};

export default PaceStat;
