import React from 'react';
import cx from 'classnames';

import CardLayout from '@magoosh/layouts/card';
import { PlainAlert, WarningAlert } from '@magoosh/lib/alerts';
import { DefaultFilledButton, PrimaryButton, SecondaryButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';
import { upsellPoints } from 'utilities/exam_info';
import { pluralize } from 'utilities/pluralize';

import PlayButton from '@magoosh/assets/gradient_play_button.svg';
import TrialProfileIllustration from '@magoosh/assets/trial_profile_illustration.svg';
import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  branding: {
    examName: string;
    saleMessage: string;
  };
  highlightedContent: {
    cta: {
      href: string;
      title: string;
    };
    description?: string;
    subtitle?: string;
    title: string;
  }[];
  highlightedExamSections: {
    id: number;
    lessonsAvailable: number;
    name: string;
  }[];
  user: {
    passwordResetIsRequired: boolean;
  };
}

const BasicDashboard: React.FC<Props> = (props) => {
  const breadcrumbs = [
    { name: props.branding.examName, path: paths.dashboard() },
    { name: 'Basics' }
  ];
  const examKey = props.branding.examName.trim().toLowerCase().replace(/ /g, '_');

  return (
    <CardLayout breadcrumbs={breadcrumbs}>
      <div className="d-flex flex-column" style={{ gap: 30 }}>
        {
          (props.branding.saleMessage || props.user.passwordResetIsRequired) && (
            <div className="d-flex flex-column" style={{ gap: 10 }}>
              {
                props.branding.saleMessage && (
                  <WarningAlert><strong>Sale!</strong> {props.branding.saleMessage}</WarningAlert>
                )
              }

              {
                props.user.passwordResetIsRequired && (
                  <PlainAlert>
                    <FontAwesomeIcon icon="exclamation-circle" />
                    {' '}Remember to <a href={paths.passwordEdit()}>set your account password</a> so you can access your saved progress and study with our mobile apps.
                  </PlainAlert>
                )
              }
            </div>
          )
        }

        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <h3 className="u-margin-A-n"><strong>Free {props.branding.examName} Prep Resources</strong></h3>
          <hr className="u-margin-A-n" />
        </div>

        <div className="d-flex flex-column flex-lg-row" style={{ gap: 10 }}>
          {
            props.highlightedContent.map((highlightedContent, idx) => (
              <CardLayout.Card key={idx} className="flex-1">
                <div className="d-flex flex-column" style={{ gap: 10, height: '100%' }}>
                  <div className="d-flex flex-column" style={{ gap: 3 }}>
                    <h4 className="u-margin-A-n">
                      <strong>{highlightedContent.title}</strong>
                    </h4>
                    {highlightedContent.subtitle && <div className="small text-gray-light">{highlightedContent.subtitle}</div>}
                  </div>

                  {highlightedContent.description && <div>{highlightedContent.description}</div>}

                  <div className="align-items-end d-flex flex-1 u-margin-T-s">
                    <SecondaryButton className="flex-lg-1" href={highlightedContent.cta.href}>
                      {highlightedContent.cta.title}
                    </SecondaryButton>
                  </div>
                </div>
              </CardLayout.Card>
            ))
          }

          <CardLayout.Card>
            <div className="d-flex flex-column" style={{ gap: 10, height: '100%' }}>
              <h4 className="d-flex justify-content-start justify-content-lg-center text-gray-light u-margin-A-n">
                <strong>Past Test Scores</strong>
              </h4>

              <div className="align-items-center d-flex flex-1 hidden-sm hidden-xs justify-content-center">
                <FontAwesomeIcon icon="th" style={{ color: '#DADADA', fontSize: 48 }} />
              </div>

              <div className="align-items-end d-flex flex-1 u-margin-T-s">
                <DefaultFilledButton className="flex-lg-1" href={paths.practiceTests()}>
                  See Past Scores
                </DefaultFilledButton>
              </div>
            </div>
          </CardLayout.Card>
        </div>

        {
          props.highlightedExamSections.length != 0 && (
            <div className="d-flex flex-column" style={{ gap: 10 }}>
              {
                props.highlightedExamSections.map((highlightedExamSection) => {
                  const path = `/${props.branding.examName === 'MCAT' ? 'videos' : 'lessons'}?section=${highlightedExamSection.id}`;

                  return (
                    <CardLayout.Card key={highlightedExamSection.id}>
                      <div className="align-items-center d-flex" style={{ gap: 20 }}>
                        <div>
                          <a href={path}>
                            <img src={PlayButton} alt="Play icon" />
                          </a>
                        </div>

                        <div className="d-flex flex-column">
                          <div>
                            <a className="text-body text-no-decoration" href={path}>
                              {highlightedExamSection.name} Lessons
                            </a>
                          </div>

                          <div>
                            <a className="text-gray-light text-no-decoration" href={path}>
                              <small>{pluralize(highlightedExamSection.lessonsAvailable, 'free lesson available', 'free lessons available')}</small>
                            </a>
                          </div>
                        </div>
                      </div>
                    </CardLayout.Card>
                  );
                })
              }
            </div>
          )
        }

        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <h3 className="u-margin-A-n"><strong>Magoosh Premium</strong></h3>
          <hr className="u-margin-A-n" />
        </div>

        <CardLayout.Card>
          <div className="d-flex flex-column" style={{ gap: 10 }}>
            <div className="d-flex" style={{ gap: 20 }}>
              <div className="hidden-sm hidden-xs">
                <img
                  src={TrialProfileIllustration} alt="Student wearing glasses and a bunny hat"
                  style={{ height: 132, width: 133 }}
                />
              </div>

              <div className="d-flex flex-column" style={{ gap: 5 }}>
                <h3 className="u-margin-A-n">
                  <strong>Your target {props.branding.examName} score is waiting!</strong>
                </h3>

                <div>
                  For full access upgrade your plan and start moving towards your {props.branding.examName} goals.
                </div>

                <div className="flex-none u-margin-T-s">
                  <PrimaryButton href={paths.plans()}>See Premium Plans</PrimaryButton>
                </div>
              </div>
            </div>

            <hr />

            <div>
              <ul style={{ paddingLeft: 16 }}>
                {
                  upsellPoints.long[examKey].map((upsellPoint, idx) => (
                    <li
                      key={idx}
                      className="u-margin-B-s"
                    >
                      {upsellPoint}
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </CardLayout.Card>
      </div>
    </CardLayout>
  );
};

export default BasicDashboard;
