import * as React from 'react';
import Tag from "@magoosh/lib/tag";
import fetch from 'utilities/fetch';


interface TaggerProps {
  options: TagData[];
  paramNamespace: string;
  rateableType?: string;
  url: string;
}

interface TagData {
  label: string;
  selected: boolean;
  slug: string;
}

interface TaggerState {
  options: TagData[];
}

// A component for toggling boolean values on a record. After clicking a button, the value
// is toggled and the record is automatically submitted.
export default class Tagger extends React.Component<TaggerProps, TaggerState> {

  state = {
    options: this.props.options
  };

  render() {
    return <div>
      {this.state.options.map((option) => {
        return <Tag
          key={option.slug}
          displayName={option.label}
          onClickCallback={() => {
            this.toggleItem(option.slug)
          }}
          isSelected={option.selected}
          canBeSelected={true}
        />
      })
      }
    </div>

  }

  toggleItem = (slug: string) => {
    let options = this.state.options;
    options.forEach(option => {
      if (option.slug === slug) {
        option.selected = !option.selected
      }
    });
    this.setState({options: options});

    this.submit();
  };

  submit = () => {
    fetch(this.props.url, {
      body: this.serializeData(),
      method: 'PUT'
    })
  };

  serializeData = () => {
    let data = {};
    data[this.props.paramNamespace] = {};
    this.state.options.forEach((option) => {
      data[this.props.paramNamespace][option.slug] = option.selected;
    });
    if (this.props.rateableType) data['rateableType'] = this.props.rateableType;
    return JSON.stringify(data)
  }

}
