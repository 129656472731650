import * as React from 'react';
import cx from 'classnames';
import { paths } from 'config/path_helpers';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import style from './style.module.scss';
import { LinkButton } from '@magoosh/lib/button';
import trackEvent from 'utilities/track_event';
import { ReactNode } from 'react';

interface Props {
  bsStyle: 'success' | 'warning' | 'info' | 'danger' | 'banner' | 'banner-info';
  children?: React.ReactNode;
  innerHtml?: string;
  slug?: string;
  className?: string;
  dismissLinkType?: 'icon' | 'text';
  action?: ReactNode;
}

// A dismissible banner.
// If you provide a `slug`, it will submit a `dismissBanner` on dismiss, and never show the banner
// to the given user profile again.
// The innerHtml prop is available for this component to be embedded in a Rails template.
export default class DismissibleBanner extends React.Component<Props> {
  state = {
    show: true
  };

  render() {
    if (!this.state.show) {
      return null;
    }

    let layout;
    const dismissLinkType = this.props.dismissLinkType || 'icon';

    if (dismissLinkType === 'text') {
      layout = style.textDismissLink;
    } else {
      layout = style.iconDismissLink;
    }

    return (
      <div className={cx(this.props.className)}>
        <div
          className={cx(
            this.props.className,
            `alert alert-${this.props.bsStyle}`,
            style.bannerWrapper,
            layout
          )}>
          <div className={style.contentCell}>
            {this.props.children}
            {this.props.innerHtml && <div dangerouslySetInnerHTML={{ __html: this.props.innerHtml }} />}
          </div>
          <div className={style.buttonsCell}>
            {this.props.action && <div className={style.actionCell}>{this.props.action}</div>}
            {dismissLinkType === 'text' && (
              <LinkButton onClick={this.onDismiss} className={style.dismissLinkButton}>
                Dismiss
              </LinkButton>
            )}
          </div>
          {dismissLinkType === 'icon' && (
            <button className={style.closeButton} onClick={this.onDismiss}>
              <FontAwesomeIcon icon={'times'} />
              <span className="sr-only">Close alert</span>
            </button>
          )}
        </div>
      </div>
    );
  }

  onDismiss = () => {
    this.setState({ show: false });
    if (!this.props.slug) {
      return;
    }

    trackEvent('dismiss_banner', {
      slug: this.props.slug
    });

    fetch(paths.api.dismissBanner(this.props.slug), {
      method: 'PUT'
    });
  };
}
