import React from 'react';

import ImageWithLogoOverlay from '@magoosh/lib/image_with_logo_overlay';
import { paths } from 'config/path_helpers';

interface Props {
  coverPhotos: {
    title: string;
    url: string;
  }[];
  defaultCategorySlug: string;
  logo: string;
  name: string;
  slug: string;
}

const SchoolProfileImage: React.FC<Props> = (props) => {
  const image = props.coverPhotos[0];
  
  return (
    <div className="hidden-xs">
      <ImageWithLogoOverlay
        image={{
          title: image.title,
          url: `${image.url}?fit=fill&h=118&w=177`
        }}
        logo={{
          title: `${props.name} Logo`,
          url: `${props.logo}?fit=fill&h=35&w=35`
        }}
      />

      <div className="text-center">
        <a href={paths.admissions.schoolProfile(props.slug, props.defaultCategorySlug)}>
          <small>See School Profile</small>
        </a>
      </div>
    </div>
  );
};

export default SchoolProfileImage;
