import * as React from 'react';
import { PracticePreviewData } from '@magoosh/organisms/study_schedule/types';
import style from './style.module.scss';
import StudyItemList, {
  StudyItemListProps,
  StudyItemListState
} from '@magoosh/organisms/study_items/study_item_list';
import PracticeSessionPreviewModal from '@magoosh/organisms/study_schedule/practice_session_preview_modal/template';
import ModuleItem from './module_item/template';
import ProgressBar from '@magoosh/lib/progress_bar';
import { Collapse } from 'react-bootstrap';
import { LinkButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { pluralize } from 'utilities/pluralize';
import cx from 'classnames';
import { percent } from 'utilities/math';

export interface StudyModuleProps extends StudyItemListProps {
  compact?: boolean;
  name: string;
  id: number;
  showDifficulty?: boolean;
  difficulty?: string;
  branding: string;
}

export interface StudyModuleState extends StudyItemListState {
  open: boolean;
  practicePreview?: PracticePreviewData;
}

export default class ModuleCard extends StudyItemList<StudyModuleProps, StudyModuleState> {
  listType = 'Lesson Module';

  constructor(props) {
    super(props);
    this.state = {
      open: this.percentComplete() != 100,
      practicePreview: null
    };
  }

  render() {
    return (
      <div className={cx(style.moduleCard, { [style.compact]: this.props.compact })}>
        {this.percentComplete() < 100 ? this.renderIncompleteHeader() : this.renderCompleteHeader()}
        <div className={style.moduleBody}>
          <Collapse in={this.state.open}>
            <div className={style.moduleItemContainer}>
              {this.props.studyItems.map((item) => {
                return (
                  <ModuleItem
                    key={item.id}
                    item={item}
                    onClick={ item.locked ? null : this.handleItemClick }
                    onCompletedToggle={ item.locked ? null : this.handleChangeItemState.bind(this) }
                  />
                );
              })}
            </div>
          </Collapse>
        </div>
        <div className={style.moduleFooter}>{this.renderFooterContent()}</div>

        {this.state.practicePreview && (
          <PracticeSessionPreviewModal
            onHide={this.onClosePreviewModal.bind(this)}
            {...this.state.practicePreview}
          />
        )}
      </div>
    );
  }

  percentComplete = () => {
    let completed = this.props.studyItems.filter((item) => item.completed).length;
    return percent(completed, this.props.studyItems.length);
  };

  onClickCollapse = () => {
    this.setState({ open: !this.state.open });
  };

  renderCompleteHeader = () => {
    return (
      <div className={cx(style.moduleHeader, style.completeHeader)}>
        <h3 className={style.moduleName}>
          {this.props.name}{' '}
          {this.props.showDifficulty && this.props.difficulty && <span> ({this.props.difficulty})</span>}
        </h3>
        <div className={cx(style.moduleStatus, style.completed)}>{this.renderModuleStatus()}</div>
      </div>
    );
  };

  renderIncompleteHeader = () => {
    return (
      <div className={cx(style.moduleHeader, style.incompleteHeader)}>
        <h3 className={style.moduleName}>
          {this.props.name}{' '}
          {this.props.showDifficulty && this.props.difficulty && <span> ({this.props.difficulty})</span>}
        </h3>
        <div className={style.totalTime}>
          <FontAwesomeIcon icon="clock-o" />
          <span className="u-margin-L-xs">{this.totalTime()}</span>
        </div>
        <div className={style.progressBar}>{this.renderProgressBar()}</div>
        <div
          className={cx(style.moduleStatus, this.percentComplete() > 0 ? style.started : style.notStarted)}>
          {this.renderModuleStatus()}
        </div>
      </div>
    );
  };

  renderModuleStatus = () => {
    const completion = this.percentComplete();
    if (completion == 100) {
      return (
        <div>
          <FontAwesomeIcon icon="check-circle-o" />
          <span className="u-margin-L-xs">Completed</span>
        </div>
      );
    } else if (completion > 0) {
      return (
        <div>
          <FontAwesomeIcon icon="circle-o-notch" />
          <span className="u-margin-L-xs">In progress</span>
        </div>
      );
    } else {
      return (
        <div>
          <FontAwesomeIcon icon="circle-o" />
          <span className="u-margin-L-xs">Not started</span>
        </div>
      );
    }
  };

  renderProgressBar = () => {
    return (
      <ProgressBar
        backgroundColor={'#d8d8d8'}
        color={'#26B67C'}
        percentage={this.percentComplete()}
        size={'md'}
      />
    );
  };

  renderFooterContent = () => {
    if (this.props.compact) {
      return null;
    }

    return (
      <>
        <LinkButton onClick={this.onClickCollapse}>
          {this.state.open ? (
            <span>
              Collapse <FontAwesomeIcon icon={'chevron-up'} />
            </span>
          ) : (
            <span>
              Expand <FontAwesomeIcon icon={'chevron-down'} />
            </span>
          )}
        </LinkButton>
      </>
    );
  };

  totalTime = () => {
    const totalMinutes = this.props.studyItems.reduce((total, item) => {
      return total + item.estimatedTimeMinutes;
    }, 0);

    const hours = Math.floor(totalMinutes / 60);
    const remainderMinutes = totalMinutes % 60;
    if (hours > 0) {
      return `${hours} hr ${remainderMinutes} min`;
    } else {
      return pluralize(remainderMinutes, 'minute', 'minutes');
    }
  };
}
