import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import { useModalContext } from '@magoosh/context/modal_context';
import { DangerButton, DefaultFilledButton, PrimaryButton } from '@magoosh/lib/button';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import TextPreview from './text_preview';
import RequestPassageEditsModal from './request_passage_edits_modal';
import { ContentRequest, PromptRequest } from './types';

interface Props {
  onChange?: (passage: string) => void;
  contentRequest?: ContentRequest;
  passage: string;
  promptRequest: PromptRequest;
  readOnly?: boolean;
}

const DraftPassagePreview: React.FC<Props> = (props) => {
  const { setModal } = useModalContext();
  const [readOnly, setReadOnly] = useState(props.readOnly);
  const [isEditing, setIsEditing] = useState(false);

  const accept = () => {
    setReadOnly(true);

    const path = adminPaths.api.promptRequestContentRequestAccept(
      props.promptRequest.id,
      props.contentRequest.id
    );

    fetch(path, {
      method: 'POST',
    });
  };

  const handleSubmit = (values, { setErrors, setSubmitting }) => {
    setSubmitting(true)

    fetch(adminPaths.api.promptRequestContentRequestCreate(props.promptRequest.id), {
      method: 'POST',
      body: JSON.stringify({
        contentRequest: {
          content: values,
          contentType: 'manual_edit',
          status: 'finished'
        }
      })
    }).then((response) => {
      setIsEditing(false);
      setSubmitting(false);

      props.onChange(values.passage);
    }).catch((error) => {
      setErrors(error.errors);
      setSubmitting(false);
    });
  };

  const isEditable = () => {
    return !readOnly;
  };

  const presentRequestEditsModal = () => {
    setModal({
      header: 'Request Edits',
      body: <RequestPassageEditsModal
        draftPassage={props.passage}
        onAccept={props.onChange}
        onClose={() => setModal(null)}
        promptRequest={props.promptRequest}
      />,
      size: 'lg'
    });
  };

  return (
    <Formik enableReinitialize initialValues={{ passage: props.passage || '' }} onSubmit={handleSubmit}>
      {({ dirty, errors, isSubmitting, resetForm, setFieldValue, submitForm , values}) => (
        <Form>
          <div className="d-flex gap-s justify-content-end u-margin-B-s">
            {
              isEditable() && (
                isEditing ? (
                  <>
                    <PrimaryButton onClick={submitForm} size="sm" submitting={isSubmitting}>
                      Save Edits
                    </PrimaryButton>
                    <DangerButton onClick={() => { resetForm(); setIsEditing(false); }} size="sm">
                      Discard Edits
                    </DangerButton>
                  </>

                ) : (
                  <>
                    <PrimaryButton onClick={accept} size="sm">
                     Accept
                    </PrimaryButton>
                    <DefaultFilledButton onClick={presentRequestEditsModal} size="sm">
                      Request Edits
                    </DefaultFilledButton>
                    <DefaultFilledButton onClick={() => setIsEditing(true)} size="sm">
                      Manual Edits
                    </DefaultFilledButton>
                  </>
                )
              )
            }
          </div>

          <div>
            {
              isEditing ? (
                <FieldInput hideLabel name="passage" type="textarea" style={{ height: 400 }} />
              ) : (
                <>
                  <TextPreview text={values.passage} />
                  <div>
                    {values.passage.split(/[ |\s]+/).length} Words
                  </div>
                </>
              )
            }
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DraftPassagePreview;
