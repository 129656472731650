import React, { useState } from 'react';
import { filter, find } from 'lodash';

import { ModalProvider } from '@magoosh/context/modal_context';
import Card from '@magoosh/lib/card';
import { paths } from 'config/path_helpers';

import { updateOrCreateApplicationCycleDeadline } from '@admissions/api';
import PastDueItemsAlert from '@admissions/components/past_due_items_alert';
import ApplicationTracker from '@admissions/organisms/application_tracker';
import { ApplicationCycleDeadline, ApplicationCyclePlan } from '@admissions/types';

interface Props {
  plan: ApplicationCyclePlan;
}

const ApplicationTrackerEmbed: React.FC<Props> = (props) => {
  const [currentDeadlines, setCurrentDeadlines] = useState<ApplicationCycleDeadline[]>(props.plan.deadlines);

  const handleSaveDeadline = (deadline: ApplicationCycleDeadline) => {
    return new Promise((resolve, reject) => {
      updateOrCreateApplicationCycleDeadline({
        cycle: props.plan.applicationCycle,
        deadline
      }).then((response) => {
        const existingDeadline = find(currentDeadlines, (deadline) => deadline.id === response.deadline.id);
        const updatedDeadline = response.deadline;
        let allDeadlines = []

        const otherDeadlines = filter(currentDeadlines, (deadline) => deadline.id !== updatedDeadline.id);
        if (updatedDeadline.deletedAt) {
          allDeadlines = otherDeadlines;
        } else {
          allDeadlines = otherDeadlines.concat([updatedDeadline]);
        }

        allDeadlines = allDeadlines.sort((a, b) => new Date(a.dueAt).getTime() - new Date(b.dueAt).getTime());
        setCurrentDeadlines(allDeadlines);

        resolve(updatedDeadline);

        // Don't redirect if just changeing pinned status from the embed
        if (!existingDeadline || updatedDeadline.pinned === existingDeadline.pinned) {
          navigateToMBADashboard();
        }
      }).catch((error) => {
        return reject(error);
      })
    });
  };

  const navigateToMBADashboard = (params = {}) => {
    let domain = window.location.hostname.split('.').slice(1).join('.');
    if (domain === 'localhost') domain += ':3000';

    const searchParams = new URLSearchParams(params);
    const url = `//mba.${domain}${paths.dashboard()}?${searchParams.toString()}`;
    window.location.href = url;
  };

  return (
    <ModalProvider>
      <Card>
        <ApplicationTracker
          applicationCycle={props.plan.applicationCycle}
          deadlines={currentDeadlines}
          onSaveDeadline={handleSaveDeadline}
        />
      </Card>

      {
        !!props.plan.itemCounts.pastDue && (
          <PastDueItemsAlert
            className="u-margin-T-s"
            onClick={() => navigateToMBADashboard({ 'filters[focus]': 'Past Due Tasks' })}
          />
        )
      }
    </ModalProvider>
  );
};

export default ApplicationTrackerEmbed;
