import React, { useState } from 'react';
import { SelectClassesFormTabProps, SelectClassesFormTabValues } from './types';
import style from './style.module.scss';
import colors from '@magoosh/b2b/app/assets/style.module.scss';
import cx from 'classnames';
import { DefaultButton } from '@magoosh/lib/button';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import ListSelectorField from '@magoosh/b2b/app/lib/list_selector_field';
import NamePlate from '@magoosh/b2b/app/lib/name_plate';
import { Icon } from '@magoosh/b2b/app/assets/icons';

const initialValues = {
  selectedClasses: []
};

const validationSchema = yup.object({
  selectedClasses: yup
    .array()
    .nullable()
    .required('You must select at least one class to add into the students list.')
});

export const SelectClassesFormTab: React.FC<SelectClassesFormTabProps> = (props) => {
  const handleOnSubmit = (
    values: SelectClassesFormTabValues,
    { resetForm }: FormikActions<SelectClassesFormTabValues>
  ): void => {
    props.addSelectedClasses(values.selectedClasses);
    resetForm({ values: initialValues });
  };

  const filterFunction = (values, item) => {
    return values.some(
      (value) =>
        (!item.displayName || !item.displayName.toLocaleLowerCase().includes(value)) &&
        (!item.name || !item.name.toLocaleLowerCase().includes(value)) &&
        (!item.subject || !item.subject.toLocaleLowerCase().includes(value)) &&
        (!item.grade || !item.grade.toLocaleLowerCase().includes(value)) &&
        (!item.period || !item.period.toLocaleLowerCase().includes(value)) &&
        (!item.sectionNumber || !item.sectionNumber.toLocaleLowerCase().includes(value)) &&
        (!item.schoolName || !item.schoolName.toLocaleLowerCase().includes(value))
    );
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
        {({ submitForm }) => (
          <>
            <ListSelectorField
              name="selectedClasses"
              list={props.classesList}
              emptyMessage="There are no classes listed in your school. Please create classes before create assignments."
              filterFunction={filterFunction}>
              {(item: ClassSection) => (
                <NamePlate
                  icon={<Icon iconType="Class" fill={colors.supplementaryRadRed} size="s" />}
                  headline={item.displayName}
                  subheadline={[
                    item.schoolName,
                    item.subject,
                    item.grade,
                    item.period,
                    item.sectionNumber
                  ].join(' ')}
                />
              )}
            </ListSelectorField>
            <div className={cx(style.tabActions, style.end)}>
              <DefaultButton onClick={submitForm}>
                <FontAwesomeIcon icon="plus-square" /> Add Selected Classes
              </DefaultButton>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default SelectClassesFormTab;
