interface WindowWithBugsnag extends Window {
  bugsnagClient: {
    notify: (error: any) => boolean;
  };
}

export function bugsnagNotify(error: any) {
  if (window && 'bugsnagClient' in window) {
    (window as unknown as WindowWithBugsnag).bugsnagClient.notify(error);
    return true;
  }
  return false;
}
