import * as React from 'react';
import { Formik, useFormikContext } from 'formik';

import { PrimaryButton } from '@magoosh/lib/button';
import { FieldInput } from '@magoosh/lib/formik_inputs/field_input/template';

import { ContentBlockTypes } from '../types';

interface props {
  bag: any
}

const Autofill: React.FC<props> = (props) => {
  const fieldMap = {
    difficulty_max: 'difficultyMaximum',
    difficulty_min: 'difficultyMinimum',
    exam_section_id: 'contents.examSection.id',
    prompt_count_limit: 'itemCount',
    quiz_mode: 'quizMode',
    tag_ids: 'contents.subjectTags',
    time_limit: 'timeLimit'
  };

  const adjustDifficultyParams = (queryParams: URLSearchParams) => {
    const difficulty = queryParams.get('practice_session[difficulty_range_str]');

    if (!!difficulty) {
      queryParams.append('difficulty_min', difficulty.split(',')[0]);
      queryParams.append('difficulty_max', difficulty.split(',')[1]);
    }
  };

  const adjustSubjectTagsParams = (queryParams: URLSearchParams) => {
    const tagIds = queryParams.get('practice_session[tag_ids]');

    if (!!tagIds) {
      const mappedTagIds = tagIds.split(',').map((id) => ({id: id}));
      props.bag.setFieldValue(fieldMap['tag_ids'], mappedTagIds);
      queryParams.delete('practice_session[tag_ids]');
    }
  };

  const adjustTimeLimitParams = (queryParams: URLSearchParams) => {
    const timeLimitSec = queryParams.get('practice_session[time_limit]');

    if (!!timeLimitSec) {
      queryParams.set('practice_session[time_limit]', String(timeLimitSec));
    }
  };

  const autofill = (url: string) => {
    const urlObj = new URL(url);
    const queryParams = new URLSearchParams(urlObj.search);

    adjustDifficultyParams(queryParams);
    adjustSubjectTagsParams(queryParams);
    adjustTimeLimitParams(queryParams);

    queryParams.forEach((value, key) => {
      const k = key.replace(/practice_session|[\[\]]/g, ''); // Make keys more human-friendly
      const inputName = fieldMap[k];

      if (inputName) {
        if (inputName === 'quizMode') {
           props.bag.setFieldValue(inputName, value === 'true');
        } else {
          props.bag.setFieldValue(inputName, value);
        }
      }
    });
  };

  return (
    <div>
      <FieldInput label="Autofill Url" name="autofillUrl" type="string" />
      <PrimaryButton onClick={() => autofill(props.bag.values.autofillUrl)}>
        Autofill
      </PrimaryButton>
    </div>
  );
};

export default Autofill;
