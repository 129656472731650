import * as React from 'react';
import cx from 'classnames';
import style from '../style.module.scss';
import { PrimaryButton, DefaultButton } from '@magoosh/lib/button';
import Modal from '@magoosh/lib/modal';
import { Col, Row } from 'react-bootstrap';
import { ClassSection } from 'types/class_section';
import Multiselect from 'react-widgets/lib/Multiselect';

interface IProps {
  availableClassSections: ClassSection[];
  availableRoles: string[];
  onAddClassEnrollment: (ClassEnrollment) => void;
}

export default class AddClassEnrollment extends React.Component<IProps> {
  state = {
    classEnrollmentDialogOpen: false,
    classSections: [],
    role: this.props.availableRoles[0],
    classSectionsError: false,
    roleError: false,
    sectionSelectOpen: null
  };

  render() {
    const { sectionSelectOpen } = this.state;

    return (
      <React.Fragment>
        <DefaultButton onClick={this.showClassEnrollmentDialog} type="button">
          Add Class Enrollment
        </DefaultButton>
        <Modal
          dialogClassName={style.dialog}
          show={this.state.classEnrollmentDialogOpen}
          title="Add Class Enrollment"
          onHide={this.hideClassEnrollmentDialog}>
          <Row>
            <Col md={3}>
              <label>Class Section</label>
            </Col>
            <Col md={7}>
              <Multiselect
                data={this.props.availableClassSections}
                filter="contains"
                valueField="id"
                textField="name"
                groupBy="schoolName"
                onChange={value => this.setState({ classSections: value, classSectionsError: false })}
                onSelect={() => this.setState({ sectionSelectOpen: false })}
                onToggle={() => this.setState({ sectionSelectOpen: !sectionSelectOpen }) }
                open={sectionSelectOpen}
              />
            </Col>
            <Col md={2}>
              <div className={cx(!this.state.classSectionsError && style.hidden, style.error)}>
                Required
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Role</label>
            </Col>
            <Col md={7}>
              <select
                onChange={e => this.setState({ role: e.target.value, roleError: false })}>
                {this.props.availableRoles.map((role, index) => (
                  <option key={index} value={role} className="option">
                    {role}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={2}>
              <div className={cx(!this.state.roleError && style.hidden, style.error)}>
                Required
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col xs={8} md={7}>
              <PrimaryButton onClick={this.onSubmit} type="button">
                Add Class Enrollment
              </PrimaryButton>
            </Col>
            <Col xs={4} md={2}>
              <DefaultButton
                onClick={this.hideClassEnrollmentDialog}
                type="button"
                className={style.classEnrollmentCancelButton}>
                Cancel
              </DefaultButton>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }

  // Show modal and reset all state values to allow user to add new subscriptions
  showClassEnrollmentDialog = () => {
    this.setState({
      classEnrollmentDialogOpen: true,
      classSections: [],
      role: this.props.availableRoles[0],
      classSectionsError: false,
      roleError: false
    });
  };

  hideClassEnrollmentDialog = () => {
    this.setState({ classEnrollmentDialogOpen: false });
  };

  showErrors = () => {
    if (this.state.classSections.length < 1) {
      this.setState({ classSectionsError: true });
    }

    if (!this.state.role) {
      this.setState({ roleError: true });
    }
  };

  // Only let them submit if both the class section(s) and role have been chosen
  isValid = () => {
    if (this.state.classSections.length > 0 && this.state.role) {
      return true;
    }
    this.showErrors();
    return false;
  };

  // Pass the selected class section and role data as an object to the callback.
  // This will set the parent's form values to include the class section and role
  onSubmit = () => {
    if (this.isValid()) {
      this.state.classSections.map(section => {
        this.props.onAddClassEnrollment({
          sectionName: section.name,
          sectionId: section.id,
          role: this.state.role
        });
      });

      this.setState({ classEnrollmentDialogOpen: false }); // Close the modal
    }
  };
}
