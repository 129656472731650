export enum ActionTypes {
  INITIALIZE = 'LESSON_MODULES/INITIALIZE',
  SET_ITEM_COMPLETED = 'LESSON_MODULES/SET_ITEM_COMPLETED'
}

export const setItemCompleted = (id: number, completed: boolean) => {
  return {
    type: ActionTypes.SET_ITEM_COMPLETED,
    id,
    completed
  }
};
