import React, { useLayoutEffect, useState } from 'react';
import { find, findIndex, maxBy } from 'lodash';

import BarChart from './index';
import ChartContainer from '../container';
import Legend from '../legend'

const fontFamily = '"Tondo", Arial, "Helvetica Neue", Helvetica, sans-serif';

interface Props {
  borderRadius?: number;
  chartProps?: any;
  data: {
    name: string;
    data: { [key: string]: number | string; }[];
  }[];
  formatValueFn?: (value: number) => string,
  hideLegend?: boolean;
  horizontal?: boolean
  indexBy: string;
  legendPosition?: 'bottom' | 'left' | 'right' | 'top';
}

const BarChartGroup: React.FC<Props> = (props) => {
  const [seriesLabelWidth, setSeriesLabelWidth] = useState(0);

  const uniqueSeries = () => {
    const keysObserved = new Set();

    props.data.forEach((group) => {
      group.data.forEach((datum) => {
        Object.keys(datum).forEach((key) => {
          if (key === 'type') {
            keysObserved.add(datum[key]);
          }
        });
      });
    });

    return Array.from(keysObserved) as string[];
  };

  // Because we have multiple charts grouped together we need to calculate the maximum label
  // width and pass it into to each of the charts, rather than letting each chart calculate itself
  useLayoutEffect(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `14px ${fontFamily}`;

    const width = context.measureText(
      maxBy(uniqueSeries(), (seriesName) => seriesName.length)
    ).width;

    setSeriesLabelWidth(width);
  }, [props.data]);

  const legendGroups = () => {
    const legendGroupings = [];

    props.data.forEach((group) => {
      group.data.forEach((dataPoints) => {
        Object.keys(dataPoints).forEach((key) => {
          if (key === 'type') return;
          if (key.match(/.*Color$/)) return;

          const groupIndex = findIndex(legendGroupings, (g) => g.name === key);

          if (groupIndex === -1) {
            legendGroupings.push({ colors: [dataPoints[`${key}Color`]], name: key });
          }
        })
      });
    });

    return legendGroupings;
  };

  return (
    <ChartContainer legendPosition={props.legendPosition}>
      <div className="d-flex flex-column gap-xs" style={{ width: '100%' }}>
        {
          props.data.map((group, idx) => (
            <React.Fragment key={idx}>
              {
                props.data.length > 1 && (
                  <span style={{ fontSize: 12 }}><strong>{group.name}</strong></span>
                )
              }

              <BarChart
                borderRadius={props.borderRadius}
                chartProps={{ animate: false, margin: { left: seriesLabelWidth } }}
                data={group.data}
                formatValueFn={props.formatValueFn}
                hideLegend
                horizontal
                indexBy={props.indexBy}
              />
            </React.Fragment>
          ))
        }
      </div>

      <Legend groups={legendGroups()} />
    </ChartContainer>
  );
};

export default BarChartGroup;
