import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { find, reduce, without } from 'lodash';
import * as yup from 'yup';

import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { DefaultFilledButton, PrimaryButton, ResponsiveButtonGroup } from '@magoosh/lib/button';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';

import * as api from '@test_prep/app/api';
import { useAppContext } from '@test_prep/app/context';

interface Props {
  onClose: () => void;
  onUpdate: () => void;
}

// This component is meant to be presented as the modal body in a setModal call
const TargetScoresModal: React.FC<Props> = (props) => {
  const [targetScoreConfig, setTargetScoreConfig] = useState(null);

  useEffect(() => {
    api.fetchUserProfile().then((userProfile) => {
      setTargetScoreConfig(userProfile.targetScores);
    });
  }, []);

  const handleSubmit = (values) => {
    return api.updateUserProfile({ userProfile: values }).then(() => {
      props.onUpdate();
      props.onClose();
    });
  };

  if (targetScoreConfig) {
    const rootTargetScore = find(targetScoreConfig, (targetScore) => targetScore.examSection.isRoot);
    const sectionTargetScores = without(targetScoreConfig, rootTargetScore);

    const initialData = {
      targetScore: rootTargetScore.target,
      targetSectionScores: reduce(sectionTargetScores, (output, targetScore) => {
        output[targetScore.examSection.id] = targetScore.target;
        return output;
      }, {})
    };

    const validationSchema = yup.object({
      targetScore: yup.number().nullable().min(
        rootTargetScore.examSection.scoreRange.low
      ).max(
        rootTargetScore.examSection.scoreRange.high
      ).label('Overall target score'),
      targetSectionScores: yup.object().shape(
        reduce(sectionTargetScores, (output, targetScore) => {
          output[targetScore.examSection.id] = yup.number().nullable().min(
            targetScore.examSection.scoreRange.low
          ).max(
            targetScore.examSection.scoreRange.high
          ).label(`${targetScore.examSection.name} target score`);
          return output;
        }, {})
      )
    });

    return (
      <Formik
        initialValues={initialData}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div>
              <FieldInput
                label={
                  <>
                    Overall{' '}
                    <span className="small text-gray-light text-normal">
                      ({rootTargetScore.examSection.scoreRange.low} - {rootTargetScore.examSection.scoreRange.high})
                    </span>
                  </>
                }
                name="targetScore"
                type="number"
               />
            </div>

            <div className="d-flex flex-column flex-lg-row flex-wrap" style={{ columnGap: 20 }}>
              {
                sectionTargetScores.map((targetScore) => (
                  <div key={targetScore.examSection.id} className="flex-1" style={{ flexBasis: '40%' }}>
                    <FieldInput
                      label={
                        <>
                          {targetScore.examSection.name}{' '}
                          <span className="small text-gray-light text-normal">
                            ({targetScore.examSection.scoreRange.low} - {targetScore.examSection.scoreRange.high})
                          </span>
                        </>
                      }
                      name={`targetSectionScores.${targetScore.examSection.id}`}
                      type="number"
                    />
                  </div>
                ))
              }
            </div>

            <div className="u-margin-T-s">
              <ResponsiveButtonGroup>
                <PrimaryButton submitting={isSubmitting} type="submit">
                  Save Target Scores
                </PrimaryButton>

                <DefaultFilledButton onClick={props.onClose}>
                  Cancel
                </DefaultFilledButton>
              </ResponsiveButtonGroup>
            </div>
          </Form>
        )}
      </Formik>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <LoadingIndicator />
      </div>
    );
  }
};

export default TargetScoresModal;
