import React from 'react';
import { PreviewListFieldProps, ItemId } from './types';
import { ErrorMessage, useField } from 'formik';
import style from './style.module.scss';
import cx from 'classnames';

export const PreviewListField = <ListItem extends ItemId>(props: PreviewListFieldProps<ListItem>) => {
  const { label, notification, emptyMessage, showNew, removeItem, children } = props;
  const [field] = useField(props);

  return (
    <>
      <fieldset className={cx(style.previewListField, 'inputs')}>
        <div className={style.label}>
          <label className="control-label">{label}</label>

          {notification && (
            <div style={{ color: style.brandSecondary }}>
              <small>
                <strong>{notification}</strong>
              </small>
            </div>
          )}
        </div>

        <div className={style.selector}>
          {field.value.length !== 0 ? (
            field.value.map((item, index) => (
              <div className={cx(style.item, 'u-margin-T-xxs', 'u-margin-B-n', 'u-margin-H-s')} key={index}>
                <p className="u-margin-A-n">
                  <small>
                    {children(item)}
                    {showNew && (
                      <span style={{ color: style.brandSecondary }}>
                        <strong>{item.id ? '' : 'New!'}</strong>
                      </span>
                    )}
                  </small>
                </p>
                <a style={{ color: style.grayDarker }} onClick={() => removeItem(item)}>
                  ✕
                </a>
              </div>
            ))
          ) : (
            <p className="text-center u-margin-T-xl">
              <small>{emptyMessage}</small>
            </p>
          )}
        </div>
      </fieldset>
      <div className={cx(style.errorMessage, 'u-margin-B-xs')}>
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
};

export default PreviewListField;
