import React from 'react';

import { CurrentBrandingContextData, CurrentBrandingContextInterface } from './types';

const CurrentBrandingContext = React.createContext<CurrentBrandingContextInterface | null>(null);

export const useCurrentBrandingContext = () => {
  const context = React.useContext(CurrentBrandingContext) as CurrentBrandingContextInterface;
  if (context === undefined) {
    throw new Error('You must wrap your page with <CurrentBrandingProvider /> in order to useCurrentBrandingContext().');
  }
  return context;
};

interface Props {
  initialData?: CurrentBrandingContextData;
}

export const CurrentBrandingProvider: React.FC<Props> = ({ children, initialData }) => {
  const defaultData = {
    examSections: [],
    slug: null
  } as CurrentBrandingContextData;

  const [data, setData] = React.useState(initialData || defaultData);

  return <CurrentBrandingContext.Provider value={{ currentBranding: data } as CurrentBrandingContextInterface}>{children}</CurrentBrandingContext.Provider>;
};
