import React from 'react';

import { renderLatexInRawHtml } from 'utilities/latex';

interface Props {
  text: string;
}

const TextPreview: React.FC<Props> = (props) => {
  const formatLatex = (text) => {
    if (typeof text === 'object') return JSON.stringify(text);
    if (typeof text !== 'string') return text;

    const matches = text.match(/\[latex\][\s\S]+?\[\/latex\]/g);
    let textWithFormattedLatex = text;

    if (matches && matches.length !== 0) {
      matches.map((latexMatch) => {
        let latex = latexMatch;
        latex = latex.replace('[latex]', '');
        latex = latex.replace('[/latex]', '');

        textWithFormattedLatex = textWithFormattedLatex.replace(
          latexMatch,
          `<code class=\"redactor-katex" data-source="${latex}"></code>`
        );
      })
    }

    return textWithFormattedLatex.split(/\n+/).map((paragraph) => (
      `<p>${paragraph}</p>`
    )).join('\n\n');
  };

  const textWithFormattedLatex = formatLatex(props.text);

  return (
    <div dangerouslySetInnerHTML={{ __html: renderLatexInRawHtml(textWithFormattedLatex) }} />
  );
};

export default TextPreview;
