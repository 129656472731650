import React from 'react';
import { paths } from 'config/path_helpers';
import { CheckpointCardProps } from '@magoosh/gre/organisms/checkpoint_card/types';
import { Checkpoint } from './types';
import CheckpointCard from '@magoosh/gre/organisms/checkpoint_card';
import FullPracticeTestIncompleteIllustration from './assets/full_practice_test_incomplete.svg';
import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  checkpoint: Checkpoint;
}

const PrioritizedPracticeTestCheckpoint: React.FC<Props> = ({ checkpoint }) => {
  const illustration = (
    <img
      src={FullPracticeTestIncompleteIllustration}
      alt="Full Practice Test Incomplete Illustration"
    />
  );

  const checkpointProps: CheckpointCardProps = {
    children: <p>Practice your pacing and stamina and get a snapshot of your strengths and weaknesses.</p>,
    estimatedTime: '2 Hours*',
    headline: null,
    iconType: 'MultipleStars',
    illustration: illustration,
    isWrapped: true,
    primaryCTA: null,
    running: checkpoint.running
  };

  const checkpointIsInProgress = checkpoint.running;

  if (checkpointIsInProgress) {
    checkpointProps.headline = (
      <>
        <span style={{ color: colors.brandPrimary }}>Almost There!</span> Complete Your Full Practice Test
      </>
    );
    checkpointProps.primaryCTA = {
      text: 'Continue',
      variant: 'secondary',
      href: paths.practiceTest(checkpoint.mockTestId)
    };
  } else {
    checkpointProps.headline = `Time for a ${checkpoint.displayName}`;
    checkpointProps.primaryCTA = {
      text: 'Start',
      variant: 'secondary',
      href: paths.startPracticeTest()
    };
  }

  return (
    <CheckpointCard {...checkpointProps} />
  );
};

export default PrioritizedPracticeTestCheckpoint;
