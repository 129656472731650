import * as React from 'react';
import style from './style.module.scss';
import cx from 'classnames';

interface AnnotationBoxProps {
  text: string;
  active: boolean;
  index: number;
}

export default class AnnotationBox extends React.Component<AnnotationBoxProps> {
  myRef = React.createRef<HTMLDivElement>();

  // Scroll to the selected annotation
  componentDidUpdate(): void {
    if (this.props.active) {
      const tagOffset = this.myRef.current.offsetTop;
      const bottomOffset = this.myRef.current.offsetTop + this.myRef.current.offsetHeight;
      const navOffset = document.getElementsByTagName('nav')[0].offsetHeight;

      let scrollTo: number;
      if (tagOffset - navOffset < window.scrollY) {
        // Top of tag is above the top of the screen.
        // Scroll so the top of the tag is at the top of the screen.
        scrollTo = tagOffset - navOffset;
      } else if (bottomOffset > window.scrollY + window.innerHeight) {
        // At least part of the tag is below the bottom of the screen.
        if (window.innerHeight - navOffset < this.myRef.current.offsetHeight) {
          // Tag is taller than screen height.
          // Scroll to fit as much of tag as possible on screen.
          scrollTo = tagOffset - navOffset;
        } else {
          // Scroll so the entire tag fits on screen.
          scrollTo = bottomOffset - window.innerHeight;
        }
      }

      window.scrollTo({ top: scrollTo });
    }
  }

  render() {
    return (
      <div className={cx(style.annotationBox, this.props.active ? style.active : '')} ref={this.myRef}>
        <div className={style.numberLabel}>{this.props.index + 1}</div>
        {this.props.text}
      </div>
    );
  }
}
