import { WritingExample } from '@magoosh/admin/writing_examples/types';
import * as React from 'react';
import Modal from '@magoosh/lib/modal';
import AdminWritingExampleForm from '@magoosh/admin/writing_examples/index/form';

interface EditWritingExampleModalProps {
  show: boolean;
  writingExample: WritingExample;
  onFormSuccess: () => void;
  onClose: () => void;
}

const EditWritingExampleModal: React.FC<EditWritingExampleModalProps> = (props) => {
  return (
    <Modal show={props.show} onHide={props.onClose} showCloseButton={true} title="Edit Writing Example">
      <div>
        <AdminWritingExampleForm writingExample={props.writingExample} onSuccess={props.onFormSuccess} />
      </div>
    </Modal>
  );
};

export default EditWritingExampleModal;
