import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import * as api from '@admin/app/api';

import CollapsibleTaskLogs from '../collapsible_task_logs';
import StatusBadge from '../status_badge';
import { ContentAudit } from '../../types';

interface Props {
  audit: ContentAudit;
}

const ContentAudit: React.FC<Props> = (props) => {
  const poller = useRef(null);
  const [audit, setAudit] = useState(props.audit);

  useEffect(() => {
    if (isDone(props.audit)) return;

    poller.current = setTimeout(pollForUpdates, 1000);

    return () => {
      clearTimeout(poller.current);
    }
  }, []);

  const isDone = (contentAudit) => {
    return ['passed', 'failed', 'errored'].indexOf(contentAudit.status) !== -1;
  };

  const pollForUpdates = () => {
    api.fetchTestPrepAIContentAudit(audit.id).then((contentAudit) => {
      setAudit(contentAudit);
      if (isDone(contentAudit)) return;

      poller.current = setTimeout(pollForUpdates, 2000);
    });
  };

  return (
    <div className="d-flex flex-column gap-s">
      <div className="align-items-center d-flex gap-xs">
        <StatusBadge status={audit.status} />

        <h4 className="u-margin-A-n">
          <strong>
            {audit.name}, {moment(audit.createdAt * 1000).format('MMMM D, YYYY')}
          </strong>
        </h4>
      </div>

      <hr className="u-margin-A-n" />

      <div>
        {audit.result?.reasoning}
      </div>

      <CollapsibleTaskLogs tasks={audit.tasks} />
    </div>
  );
};

export default ContentAudit;
