import React, { useContext } from 'react';
import { DataTable, inactiveRowStyles } from '@magoosh/lib/data_table';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Search } from '@magoosh/lib/data_table/search';
import Icon from '../../assets/icons';
import colors from '../../assets/style.module.scss';
import StatsBlock from '@magoosh/b2b/app/lib/stats_block';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import { FilterToggle } from '@magoosh/lib/data_table/filter_toggle';
import { Badge } from '@magoosh/lib/data_table/badge';
import { AppContext } from '@magoosh/b2b/app/app_context';

export const Schools: React.FC = () => {
  const appContext = useContext(AppContext);

  const { queryParameters, runQuery, dataQueryTableProps, aggregateData } = useDataQuery(
    paths.api.partner.schools,
    { sortColumn: 'name' }
  );

  const aggregateStudentsCount = aggregateData ? aggregateData.studentsCount.toLocaleString() : null;
  const aggregateTeachersCount = aggregateData ? aggregateData.teachersCount.toLocaleString() : null;
  const aggregateClassSectionsCount = aggregateData ? aggregateData.classSectionsCount.toLocaleString() : null;

  const schoolIcon = <Icon iconType="School" fill={colors.supplementaryCarrot} size="m" />;
  const graySchoolIcon = <Icon iconType="School" fill={colors.grayLight} size="m" />;

  return (
    <>
      <DataTable
        {...dataQueryTableProps}
        primaryAttribute={{ selector: 'name', name: 'School Name', sortable: true }}
        linkBaseUrl="/schools/"
        columns={[
          { selector: 'activeClassesCount', name: 'Classes', sortable: true },
          { selector: 'activeStudentsCount', name: 'Students', sortable: true },
          { selector: 'activeTeachersCount', name: 'Teachers', sortable: true },
          { selector: 'assignmentsCount', name: 'Assignments', sortable: true }
        ]}
        conditionalRowStyles={[
          {
            when: (school) => !school.active,
            style: inactiveRowStyles
          }
        ]}>
        <DataTable.Filters>
          <Search runQuery={runQuery} />
          <FilterToggle
            queryParameters={queryParameters}
            runQuery={runQuery}
            filterName={'archived'}
            filterLabel={'Show archived'}
          />
        </DataTable.Filters>
        <DataTable.HeaderActions>
          <DownloadButton
            queryParameters={queryParameters}
            fetchUrl={paths.api.partner.schools}
            fileName={'school_data.csv'}
            disabled={appContext.trialAccess}
          />
        </DataTable.HeaderActions>
        <DataTable.StatsBlock>
          <StatsBlock>
            <StatsBlock.Stats>
              <div>
                <div>{aggregateClassSectionsCount}</div>
                <div>Classes</div>
              </div>
              <div>
                <div>{aggregateStudentsCount}</div>
                <div>Students</div>
              </div>
              <div>
                <div>{aggregateTeachersCount}</div>
                <div>Instructors</div>
              </div>
            </StatsBlock.Stats>
          </StatsBlock>
        </DataTable.StatsBlock>
        <DataTable.ItemIcon>
          {(school: School) => (school.active ? schoolIcon : graySchoolIcon)}
        </DataTable.ItemIcon>
        <DataTable.PrimaryAttributeLabel>
          {(school: School) => (
            <>
              {school.active ? null : <Badge>Archived</Badge>}
              {school.name}
            </>
          )}
        </DataTable.PrimaryAttributeLabel>
      </DataTable>
    </>
  );
};
