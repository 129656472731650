import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import { genericErrorMessage, PaymentError } from './errors';
import { CreateSubscriptionResponse } from './types';

export const confirmStripePaymentRequest = async (stripe, clientSecret, paymentMethodId, paymentMethodType) => {
  const response = await stripe.confirmCardPayment(
    clientSecret,
    { payment_method: paymentMethodId },
    { handleActions: false }
  );

  if (response.error) {
    const debugValues = {
      clientSecret,
      paymentMethodId
    };
    throw new PaymentError(
      genericErrorMessage,
      `Error in confirmStripePaymentRequest with values ${JSON.stringify(debugValues)} and response ${JSON.stringify(response)}`
    );
  } else if (response.paymentIntent.status === 'requires_action') {
    return await confirmStripePayment(stripe, response.clientSecret, null, null, paymentMethodType);
  } else {
    return { nextUrl: response.nextStepUrl };
  }
};

export const confirmStripePayment = async (stripe, clientSecret, paymentMethodId = null, nextUrl = null, paymentMethodType = null) => {
  const isAlipay = paymentMethodType === 'alipay'

  const options: any = {};
  if (paymentMethodId) {
    options.payment_method = paymentMethodId;
    if (!isAlipay) {
      options.setup_future_usage = 'off_session';
    }
  }
  if (nextUrl) {
    options.return_url = nextUrl
  }

  const response = isAlipay ?
    await stripe.confirmAlipayPayment(clientSecret, options)
    :
    await stripe.confirmCardPayment(clientSecret, options);

  if (response.error) {
    const debugValues = {
      clientSecret,
      paymentMethodId,
      nextUrl
    };
    throw new PaymentError(
      genericErrorMessage,
      `Error in confirmStripePayment with values ${JSON.stringify(debugValues)} and response ${JSON.stringify(response)}`
    );
  } else {
    return { nextUrl };
  }
};

export const createStripePaymentMethod = async (stripe, card, values) => {
  let payload = {}

  if (card !== null) {
    const { cardName, zipCode, email } = values;
    payload = {
      type: 'card',
      card,
      billing_details: {
        name: cardName,
        email: email || null,
        address: {
          postal_code: zipCode
        }
      }
    };
  } else {
    payload = { type: 'alipay' };
  }

  const response = await stripe.createPaymentMethod(payload);

  if (response.error) {
    throw new PaymentError(
      parseStripeError(response.error),
      `Error in createStripePaymentMethod with values ${JSON.stringify(values)} and response ${JSON.stringify(response)}`
    );
  } else {
    return { stripePaymentMethod: response.paymentMethod };
  }
};

export const createStripePaymentRequest = async (stripe, name, priceCents) => {
  const response = await stripe.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: {
      label: name,
      amount: priceCents
    },
    requestPayerName: true,
    requestPayerEmail: false
  });

  if (response.error) {
    const debugValues = {
      name,
      priceCents
    };
    throw new PaymentError(
      null,
      `Error in createStripePaymentRequest with values ${JSON.stringify(debugValues)} and response ${JSON.stringify(response)}`
    );
  } else {
    return response;
  }
};

export const createStripeToken = async (stripe, card): Promise<{ purchaseToken: string; }> => {
  const response = await stripe.createToken(card);

  if (response.error) {
    throw new PaymentError(
      parseStripeError(response.error),
      `Error in createStripeToken with response ${JSON.stringify(response)}`
    );
  } else {
    return { purchaseToken: response.token.id };
  }
};

export const createOrder = async (stripe, values): Promise<CreateSubscriptionResponse> => {
  const response: CreateSubscriptionResponse = await fetch(
    paths.api.orders(),
    {
      method: 'POST',
      body: JSON.stringify(values)
    }
  );

  if (!response.actionRequired && response.nextUrl) {
    return response;
  } else if (response.actionRequired?.type === 'confirmPayment') {
    return await confirmStripePayment(
      stripe,
      response.actionRequired.clientSecret,
      response.actionRequired.paymentMethodId,
      response.nextUrl
    );
  } else {
    throw new PaymentError(
      genericErrorMessage,
      `Unexpected response in createOrder with values ${JSON.stringify(values)} and response ${JSON.stringify(response)}`
    );
  }
};

export const createSubscription = async (stripe, values): Promise<CreateSubscriptionResponse> => {
  const response: CreateSubscriptionResponse = await fetch(
    paths.api.subscriptions(),
    {
      method: 'POST',
      body: JSON.stringify(values)
    }
  );

  if (!response.actionRequired && response.nextUrl) {
    return response;
  } else if (response.actionRequired?.type === 'completePaymentRequest') {
    return response;
  } else if (response.actionRequired?.type === 'confirmPayment') {
    return await confirmStripePayment(
      stripe,
      response.actionRequired.clientSecret,
      response.actionRequired.paymentMethodId,
      response.nextUrl,
      values.stripePaymentMethod.type
    );
  } else {
    throw new PaymentError(
      genericErrorMessage,
      `Unexpected response in createSubscription with values ${JSON.stringify(values)} and response ${JSON.stringify(response)}`
    );
  }
};

export const getSubscriptionPricing = (subscriptionPlanId, couponCode, paymentMethod, zipCode) => {
  const path = paths.api.subscriptionsPricing(
    subscriptionPlanId,
    couponCode,
    paymentMethod,
    zipCode
  );

  return fetch(path).then((response) => response.pricing);
};

// Allows the user to pay the balance remaining on their installment plan. Credit cards are the
// only accepted value for installment plans, so that is all we support here.
export const payRemainingBalance = async (stripe, values, subscriptionId) => {
  const response = await fetch(paths.api.subscriptionPayRemainingBalance(subscriptionId), {
    method: 'PUT',
    body: JSON.stringify(values)
  });

  if (!response.actionRequired && response.nextUrl) {
    return response;
  } else if (response.actionRequired?.type === 'confirmPayment') {
    return await confirmStripePayment(
      stripe,
      response.actionRequired.clientSecret,
      response.actionRequired.paymentMethodId,
      response.nextUrl,
      values.stripePaymentMethod.type
    );
  } else {
    throw new PaymentError(
      genericErrorMessage,
      `Unexpected response in payRemainingBalance with values ${JSON.stringify(values)} and response ${JSON.stringify(response)}`
    );
  }
};

export const queueCartAbandonFollowup = (email, authenticityToken) => {
  return fetch(paths.api.queueCartAbandonFollowup(), {
    method: 'POST',
    body: JSON.stringify({
      email,
      authenticityToken
    })
  });
};

const parseStripeError = (error) => {
  if (error.code === 'email_invalid') {
    return { email: error.message };
  } else if (error.type === 'card_error' || error.type === 'validation_error') {
    if (error.message.indexOf('expiration date') !== -1 || error.message.indexOf('expiration year') !== -1) {
      return { stripeExpirationDate: error.message };
    } else if (error.message.indexOf('card number') !== -1) {
      return { stripeCardNumber: error.message };
    } else if (error.message.indexOf('security code') !== -1) {
      return { stripeSecurityCode: error.message };
    } else {
      return { _error: error.message };
    }
  } else {
    return { _error: error.message };
  }
};
