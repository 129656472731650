import React, { useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import * as api from '@test_prep/app/api';
import { Interaction } from '@test_prep/app/ai_tutor/types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  interaction: Interaction;
  onSelected: (hint: string) => void;
}

const ChatHint: React.FC<Props> = (props) => {
  const [selectedHint, setSelectedHint] = useState(props.interaction.content.selectedOption);

  const handleHintSelected = (hint) => {
    if (selectedHint) return;

    setSelectedHint(hint);
    // Queue up a chat response from the tutor based on the hint selection
    props.onSelected(hint);

    const updatedInteraction = { ...props.interaction };
    updatedInteraction.content.selectedOption = hint;

    api.updateTutoringSessionInteraction(updatedInteraction);
  };

  return (
    <div className="d-flex flex-column gap-s">
      {props.interaction.content.text}

      <div className="small text-bold text-primary">
        Select an option or ask away below
      </div>

      {
        props.interaction.content.options.map((hint, idx) => (
          <div
            key={idx}
            className="align-items-center d-flex gap-s small"
            onClick={() => handleHintSelected(hint)}
            style={{
              border: '1px solid #E0E0E0',
              borderRadius: 8,
              cursor: !selectedHint ? 'pointer' : 'default',
              padding: '10px 20px'
            }}
          >
            <div style={{ width: 14 }}>
              {
                selectedHint === hint ? (
                  <FontAwesomeIcon icon="check" style={{ WebkitTextStroke: `1px ${colors.grayLightest}` }} />
                ) : (
                  <div style={{ border: `1px solid ${colors.gray}`, borderRadius: 8, height: 8, width: 8 }} />
                )
              }
            </div>

            {hint}
          </div>
        ))
      }
    </div>
  );
};

export default ChatHint;
