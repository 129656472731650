import React, { useEffect } from 'react';
import { difference, isEqual, omit, omitBy, some } from 'lodash';

import Filters from '@magoosh/organisms/filters';
import { parseCustomDateRange } from '@magoosh/organisms/filters/custom_date_range';
import { FilterConfig } from '@magoosh/organisms/filters/types';
import useSearchQuery from '@magoosh/utils/use_search_query';

import * as api from '@test_prep/app/api';
import { useAppContext } from '@test_prep/app/context';

interface Props {
  filtersConfig: FilterConfig[];
  onChange: (updatedFilters: { [key: string]: string }) => void;
}

const FilterManager: React.FC<Props> = (props) => {
  const { data, setFilters } = useAppContext();
  const filters = data.filters;

  const defaultFilters = () => {
    let result = {};
    props.filtersConfig.map((filterConfig) => {
      result[filterConfig.key] = filterConfig.default;
    });
    return result;
  };

  const extractUrlFilters = () => {
    let result = {} as any;
    props.filtersConfig.map((filterConfig) => {
      result[filterConfig.key] = (useSearchQuery()[`filters[${filterConfig.key}]`] as string);
    });

    const focusFilter = useSearchQuery()['filters[focus]'];
    if (focusFilter) {
      result.focus = focusFilter;
    }

    return omitBy(result, (filter) => !filter || filter === 'undefined' || filter === 'null');
  };
  const urlFilters = extractUrlFilters();

  // Handle any invalid filter values
  useEffect(() => {
    if (!filters) return;

    // Ensure any custom period filter value is parseable
    if (filters.period?.startsWith('custom')) {
      try {
        const dateRange = parseCustomDateRange(filters.period);

        if (!dateRange.startDate || !dateRange.endDate) {
          setFilters({
            ...filters,
            period: 'all_time'
          });
        }
      } catch {
        setFilters({
          ...filters,
          period: 'all_time'
        });
      }
    } else if (filters.period !== 'all_time' && filters.period !== 'since_last_practice_test') {
      setFilters({
        ...filters,
        period: 'all_time'
      });
    }
  }, [filters]);

  useEffect(() => {
    // Ensure that all url filters are applied
    if (some(urlFilters, (value, key) => filters[key] !== value)) {
      setFilters({
        ...filters,
        ...urlFilters
      });
    } else if (filters.focus && !urlFilters.focus) {
      console.log(omit(filters, 'focus'))
      setFilters({
        ...filters,
        focus: null
      });
    }
  }, [urlFilters]);

  //When we switch to a page that might have new filters, merge in the defaults to our filters object
  useEffect(() => {
    if (!filters) return;
    if (difference(props.filtersConfig.map((filterConfig) => filterConfig.key), Object.keys(filters)).length === 0) return;

    setFilters({
      ...defaultFilters(),
      ...filters
    });
  }, [props.filtersConfig]);

  const handleFiltersChanged = (updatedFilters) => {
    const previousFilters = filters;
    setFilters(updatedFilters);

    if (updatedFilters.period !== previousFilters?.period) {
      api.analyticsSaveRecentFilters(updatedFilters); // Async
    }

    props.onChange(updatedFilters);
  };

  return (
    <Filters
      filters={filters}
      filtersConfig={props.filtersConfig}
      onChange={handleFiltersChanged}
    />
  );
};

export default FilterManager;
