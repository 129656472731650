import React from 'react';
import cx from 'classnames';

import { EditorialArticleLink, EditorialArticleSet } from '../types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

const ContentfulEditorialArticleSet: React.FC<EditorialArticleSet & { className?: string }> = (props) => {
  return (
    <div className={cx(style.articleSet, props.className, 'd-flex flex-wrap')} style={{ width: '100%' }}>
      {
        props.articles.map((article, idx) => (
          <ArticleLink key={idx} {...article} />
        ))
      }
    </div>
  );
};

const ArticleLink: React.FC<EditorialArticleLink> = (props) => {
  const imageHeight = 250;
  const imageWidth = 420;
  const imageAspectRatio = imageWidth/imageHeight;

  return (
    <div className={cx(style.articleLink, 'd-flex flex-column')}>
      <div
        style={{
          borderRadius: 6,
          boxShadow: '0 6px 12px #E6E2EA',
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            backgroundColor: colors.grayLightest,
            paddingTop: `${(1/imageAspectRatio) * 100}%`,
            position: 'relative',
            width: '100%'
          }}
        >
          <a href={props.url} target="_blank">
            {
              props.image && (
                <img
                  alt={props.image.title}
                  loading="lazy"
                  src={`${props.image.url}?fit=fill&h=${imageHeight}&w=${imageWidth}`}
                  style={{ height: 'auto', position: 'absolute', top: 0, width: '100%' }}
                />
              )
            }
          </a>
        </div>

        <div className="d-flex flex-column u-padding-H-s u-padding-V-m" style={{ gap: 10 }}>
          <div>
            <a className="text-no-decoration text-secondary" href={props.url} target="_blank">
              <strong>{props.name}</strong>
            </a>
          </div>

          <div>
            {props.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentfulEditorialArticleSet;
