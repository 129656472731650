import React from 'react';

export interface SchoolsAppContextData {
  apiHost: string;
}

const SchoolsAppContext = React.createContext<SchoolsAppContextData | null>(null);

export const useSchoolsAppContext = () => {
  const context = React.useContext(SchoolsAppContext) as SchoolsAppContextData;
  if (context === undefined) {
    throw new Error('You must wrap your page with <SchoolsAppProvider /> in order to useSchoolsAppContext().');
  }
  return context;
};

interface Props {
  initialData?: SchoolsAppContextData;
}

export const SchoolsAppProvider: React.FC<Props> = ({ children, initialData }) => {
  const defaultData = {
    apiHost: null
  } as SchoolsAppContextData;

  const [data, setData] = React.useState(initialData || defaultData);

  return <SchoolsAppContext.Provider value={{ ...data } as SchoolsAppContextData}>{children}</SchoolsAppContext.Provider>;
};
