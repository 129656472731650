import * as React from 'react';
import { range } from 'lodash';
import moment from 'moment';

import { Select } from './template';

interface DateSelectProps {
  className?: string;
  inline?: boolean;
  onChange: (date: string) => void;
  selectClassName?: string;
  value?: string;
  disabled?: boolean;
  style?: any;
}

interface DateSelectState {
  day: number;
  month: number;
  year: number;
}

export default class DateSelect extends React.Component<DateSelectProps, DateSelectState> {
  handleChange = () => {
    if (this.state.year && this.state.month && this.state.day) {
      const paddedDay = this.state.day < 10 ? `0${this.state.day}` : this.state.day;
      const paddedMonth = this.state.month < 10 ? `0${this.state.month}` : this.state.month;
      const dateString = `${this.state.year}-${paddedMonth}-${paddedDay}`;

      if (moment(dateString, 'YYYY-MM-DD', true).isValid()) {
        this.props.onChange(dateString);
      } else {
        this.props.onChange(null);
      }
    } else {
      this.props.onChange(null);
    }
  };

  handleDayChange = (e) => {
    this.setState({ day: e.target.value }, this.handleChange);
  }; 

  handleMonthChange = (e) => {
    this.setState({ month: e.target.value }, this.handleChange);
  }; 

  handleYearChange = (e) => {
    this.setState({ year: e.target.value }, this.handleChange);
  }; 

  constructor(props) {
    super(props);
    const initialDate = moment(props.value, 'YYYY-MM-DD', true);

    this.state = {
      day: initialDate.isValid()? initialDate.date() : null,
      month: initialDate.isValid()? initialDate.month() + 1 : null,
      year: initialDate.isValid()? initialDate.year() : null
    }
  }

  render() {
    const { inline, selectClassName, disabled } = this.props;

    return (
      <div className={this.props.className} style={this.props.style}>
        <Select
          groupClassName={selectClassName}
          inline={inline}
          onChange={this.handleMonthChange}
          value={this.state.month || ''}
          disabled={disabled}>
          <option>Month</option>
          <option value={1}>January</option>
          <option value={2}>February</option>
          <option value={3}>March</option>
          <option value={4}>April</option>
          <option value={5}>May</option>
          <option value={6}>June</option>
          <option value={7}>July</option>
          <option value={8}>August</option>
          <option value={9}>September</option>
          <option value={10}>October</option>
          <option value={11}>November</option>
          <option value={12}>December</option>
        </Select>

        <Select
          groupClassName={selectClassName}
          inline={inline}
          onChange={this.handleDayChange}
          value={this.state.day || ''}
          disabled={disabled}>
          <option>Day</option>
          {
            range(31).map((day) => (
              <option key={day} value={day + 1}>{day + 1}</option>
            ))
          }
        </Select>

        <Select
          groupClassName={selectClassName}
          inline={inline}
          onChange={this.handleYearChange}
          value={this.state.year || ''}
          disabled={disabled}>
          <option>Year</option>
          {
            range(6).map((idx) => {
              const year = moment().year() + idx;
              return <option key={year} value={year}>{year}</option>
            })
          }
        </Select>
      </div>
    )
  }
}
