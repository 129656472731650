import * as React from 'react';
import style from './style.module.scss';

/**
 * A list item with move up and move down buttons
 *
 * Looks like this:
 * [ up / down ] [           the list item                ]
 * [ up / down ] [           the list item                ]
 * [ up / down ] [           the list item                ]
 */
const OrderableListItem = ({ canMoveUp = true, canMoveDown = true, onMoveUp, onMoveDown, children }) => (
  <div className={style.container}>
    <div className={style.actions}>
      {canMoveUp && <a onClick={onMoveUp}>Move up</a>}
      {canMoveDown && <a onClick={onMoveDown}>Move down</a>}
    </div>
    <div className={style.item}>{children}</div>
  </div>
);

export default OrderableListItem;
