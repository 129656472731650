import * as React from 'react';
import { Checkmark, FontAwesomeIcon } from '@magoosh/lib/icons';
import { StudyItemData } from '@magoosh/organisms/study_items/types';
import { LinkButton } from '@magoosh/lib/button';
import style from '../style.module.scss';
import cx from 'classnames';

interface ModuleItemProps {
  item: StudyItemData;
  onClick: (item: StudyItemData, clickCompletionCallback: () => void) => void;
  onCompletedToggle: any;
}

interface ModuleItemState {
  submitting: boolean;
}

export default class ModuleItem extends React.Component<ModuleItemProps, ModuleItemState> {
  state = {
    submitting: false
  };

  render() {
    const { estimatedTimeMinutes, locked } = this.props.item;
    return (
      <div className={cx(style.studyItem)} onClick={this.handleItemClick}>
        <div className={style.iconCell}>{this.renderIcon()}</div>
        <div className={style.itemName}>{this.renderLink()}</div>
        <div className={style.timeCell}>
          <span>{estimatedTimeMinutes} minutes</span>
        </div>
        { locked ? this.renderLock() : this.renderCheckmark() }
      </div>
    );
  }

  renderLink = () => {
    const disabled = this.props.item.locked;
    const submitting = disabled ? null : this.state.submitting;

    const completedStyles = this.props.item.completed ? style.completedItem : '';
    const disabledStyles = disabled ? style.disabledItem : '';

    return (
      <LinkButton
        className={cx(style.itemLink, completedStyles, disabledStyles)}
        submitting={submitting}
        disabled={disabled}>
        {this.props.item.name}
      </LinkButton>
    )
  }

  renderCheckmark = () => {
    return (
      <div className={style.checkmark}>
        <a onClick={this.handleCompletedToggle}>
          <Checkmark completed={this.props.item.completed} />
        </a>
      </div>
    )
  }

  renderLock = () => {
    return (
      <div className={style.lock}>
        <FontAwesomeIcon icon="fa-solid fa-lock" />
      </div>
    )
  }

  renderIcon = () => {
    if (this.props.item.type == 'Lesson') {
      return <FontAwesomeIcon icon={'play-circle-o'} />;
    }
    return <FontAwesomeIcon icon={'graduation-cap'} />;
  };

  handleItemClick = (e) => {
    this.setState({ submitting: true });
    this.props.onClick(this.props.item, this.itemClickCompletion.bind(this));
  };

  itemClickCompletion = () => {
    this.setState({ submitting: false });
  };

  handleCompletedToggle = (event) => {
    event.stopPropagation();
    this.props.onCompletedToggle(this.props.item, !this.props.item.completed);
  };
}
