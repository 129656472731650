import React from 'react';
import cx from 'classnames';
import { capitalize, compact, find, groupBy, isNull, without } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CardLayout from '@magoosh/layouts/card';
import { SimpleBarChart } from '@magoosh/lib/charts';
import {
  FontAwesomeIcon,
  HighSignificanceIcon,
  LowSignificanceIcon,
  ModerateSignificanceIcon
} from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';
import { pluralize } from 'utilities/pluralize';

import ContextualLink from '@test_prep/app/components/contextual_link';

import { SubjectData } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  data: SubjectData[];
  filters: any;
  hideSubjectsWithNoActivity: boolean;
  section: string;
}

const PerformanceBySubject: React.FC<Props> = (props) => {
  const dataPointGroups = () => {
    const dataPoints = without(props.data, overallDataPoint());

    const subjectsByFrequency = groupBy(dataPoints, 'frequency');

    return compact([
      subjectsByFrequency.high && { frequency: 'high', subjects: subjectsByFrequency.high },
      subjectsByFrequency.medium && { frequency: 'medium', subjects: subjectsByFrequency.medium },
      subjectsByFrequency.low && { frequency: 'low', subjects: subjectsByFrequency.low }
    ]);
  };

  const overallDataPoint = () => {
    return find(props.data, (datum) => datum.name === 'Overall');
  };

  return (
    <div className="d-flex">
      <div
        className="hidden-sm hidden-xs"
        style={{
          borderRight: `1px solid ${colors.gray85}`,
          width: 30
        }}
      />

      <div className="d-flex flex-1 flex-column gap-m">
        {
          dataPointGroups().map((group, idx) => {
            const FrequencyIcon = {
              high: HighSignificanceIcon,
              low: LowSignificanceIcon,
              medium: ModerateSignificanceIcon
            }[group.frequency];

            return (
              <div key={idx} className="d-flex flex-1">
                <div
                  className="hidden-sm hidden-xs"
                  style={{
                    borderBottom: `1px solid ${colors.gray85}`,
                    height: 40,
                    marginRight: 15,
                    width: 20
                  }}
                />

                <CardLayout.Card key={idx} className={cx(style.card, 'd-flex flex-1 flex-column gap-m')}>
                  <div className="d-flex">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`${props.section}-${group.frequency}-tooltip`}>
                          {(() => {
                            if (group.frequency === 'high') {
                              return 'These subjects appear most frequently on the test and have the biggest impact on your score.';
                            } else if (group.frequency === 'medium') {
                              return 'While these subjects appear less frequently on the test, they are still important for your overall score.';
                            } else if (group.frequency === 'low') {
                              return 'These subjects appear least frequently. Improving in these subjects is less important for your overall score.';
                            }
                          })()}
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <div className="align-items-center d-flex gap-s">
                        <div className="align-items-center d-flex gap-xs text-underdash" style={{ cursor: 'default' }}>
                          <FrequencyIcon fill={colors.dataLightPurple} height={15} width={19} />
                          <strong>{capitalize(group.frequency)} Frequency Subjects</strong>
                        </div>

                        <FontAwesomeIcon icon="info-circle" style={{ color: colors.gray85 }} />
                      </div>
                    </OverlayTrigger>
                  </div>

                  <div className="d-flex flex-column gap-m">
                    {
                      group.subjects.map((subject) => {
                        if (subject.answers.total === 0 && props.hideSubjectsWithNoActivity) {
                          return null;
                        }

                        const detailPath = paths.analyticsDetail(
                          props.section,
                          subject.slug,
                          props.filters
                        );

                        return (
                          <div
                            key={subject.name}
                            className="align-items-center d-flex gap-m gap-lg-l"
                          >
                            <div className="align-items-lg-center d-flex flex-1 flex-column flex-md-row gap-xs gap-md-l">
                              <div className={style.subjectName}>
                                <div className="visible-xs">
                                  <span><strong>{subject.name}</strong></span>
                                  <span className="text-gray-light u-margin-L-xs" style={{ fontSize: 12 }}>
                                    {subject.answers.total ? `${subject.answers.correct} of ${subject.answers.total} correct` : 'No Activity'}
                                  </span>
                                </div>

                                <div className="hidden-xs">
                                  <strong>{subject.name}</strong>

                                  <div className="text-gray-light" style={{ fontSize: 12 }}>
                                    {subject.answers.total ? `${subject.answers.correct} of ${subject.answers.total} correct` : 'No Activity'}
                                  </div>
                                </div>
                              </div>

                              <div className="flex-1">
                                <SimpleBarChart
                                  colorPalette={{ dark: colors.dataDarkPurple, light: colors.dataMutedPurple }}
                                  data={[{ value: subject.answers.total === 0 ? null : subject.answers.percentCorrect }]}
                                  hideLabel
                                  hideLegend
                                />
                              </div>
                            </div>

                            <div>
                              <ContextualLink className={cx('btn btn-default btn-sm', style.detailsButton)} href={detailPath}>
                                View<span className="hidden-xs"> Breakdown</span>
                              </ContextualLink>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </CardLayout.Card>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default PerformanceBySubject;
