import React from 'react';
import Modal from '@magoosh/lib/modal';
import { Formik, FormikHelpers } from 'formik';
import { FieldInput } from 'react/lib/formik_inputs/field_input/template';
import { PrimaryButton } from '@magoosh/lib/button';
import { adminPaths } from 'config/path_helpers';

interface Props {
  parentId: number;
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const StudyScheduleUploadModal: React.FC<Props> = (props) => {
  const [error, setError] = React.useState<string | null>(null);

  const initialValues = {
    csv: null
  };

  interface FormValues {
    csv: File;
  }

  const onSubmit = (values: any, { setSubmitting }: FormikHelpers<FormValues>) => {
    const formData = new FormData();
    formData.append('csv', values.csv);

    setSubmitting(true);

    const url = adminPaths.api.uploadStudyScheduleBlock(props.parentId);
    console.log(url);
    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        if (response.ok) {
          setSubmitting(false);
          props.onClose();
          props.onSuccess();
        } else {
          setSubmitting(false);
          response.json().then((error) => {
            setError(error._error);
            console.log(error);
          });
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
      });
  };

  return (
    <Modal show={props.showModal} showCloseButton={true} onHide={props.onClose} title="Upload Study Schedule">
      <div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <p>
                This form may time out with larger schedules. In that case, you'll need an engineer to process
                the schedule through the console.
              </p>
              <label>
                CSV
                <input
                  type="file"
                  name="csv"
                  accept=".csv"
                  onChange={(event) => {
                    setFieldValue('csv', event.currentTarget.files[0]);
                  }}
                />
              </label>
              <PrimaryButton type="submit" disabled={isSubmitting} submitting={isSubmitting}>
                Submit
              </PrimaryButton>
              {error && <div className="error">{error}</div>}
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default StudyScheduleUploadModal;
