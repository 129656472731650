import style from './style.module.scss';
import { paths } from 'config/path_helpers';
import * as React from 'react';
import cx from 'classnames';
import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import { PracticeSessionForQuiz, SubjectBlockData } from 'types/content_blocks';
import { pluralize } from 'utilities/pluralize';
import { filter } from 'lodash';
import { ASSIGNMENT_STATUS_MASTERED } from 'config/constants';
import { SuccessBadge } from '@magoosh/lib/badge';
import { Assignment } from '../../types';
import Card from '@magoosh/lib/card';

interface QuizCardProps {
  assignment?: Assignment;
  contentBlock: SubjectBlockData;
  quiz: PracticeSessionForQuiz;
  title: string;
}

export default class QuizCard extends React.Component<QuizCardProps> {
  render() {
    const { title, quiz } = this.props;
    let quizResults;

    if (!quiz) {
      return null;
    }

    if (this.isQuizTaken()) {
      quizResults = (
        <div className="u-margin-T-m">
          <h4 className="u-margin-B-n">Quiz Results</h4>
          <hr className="u-margin-V-xs" />
          {this.renderQuizAnswerTable()}
        </div>
      );
    }

    return (
      <Card shadow className={style.card}>
        <h4 className="u-margin-B-xxs">
          <strong>{title}</strong>
          {this.isMastered() && <span className="hidden-xs">{this.renderMasteredBadge()}</span>}
        </h4>
        <small className="text-gray-light">{quiz.estimatedTimeMinutes} minutes</small>

        <div className="u-margin-V-m">{this.renderQuizStatusText()}</div>

        {quizResults}

        <div className={style.centeredButtonContainer}>{this.renderQuizStartButton()}</div>
      </Card>
    );
  }

  renderMasteredBadge() {
    return (
      <SuccessBadge className={style.masteredBadge}>
        <span className="fa fa-check" /> Mastered
      </SuccessBadge>
    );
  }

  renderQuizAnswerTable() {
    const { answers } = this.props.quiz;

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th />
            <th>Question</th>
            <th>Difficulty</th>
            <th className="hidden-xs">Flag</th>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer) => (
            <tr key={answer.id}>
              <td>
                <span
                  className={cx('fa', {
                    'correct-sprite': answer.isCorrect,
                    'incorrect-sprite': !answer.isCorrect
                  })}
                />
              </td>
              <td>
                <a href={paths.answer(answer.id)}>{answer.prompt.questionTitle}</a>
              </td>
              <td>{answer.humanDifficulty}</td>
              <td className="hidden-xs">
                <div className={cx(style.answerFlag, 'flag', { active: answer.isFlagged })}>
                  <i />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderQuizStartButton() {
    let { practiceSessionStartPath } = this.props.quiz;
    const { assignment } = this.props;

    if (assignment) {
      practiceSessionStartPath += `?assignment_id=${assignment.id}`;
    }

    if (this.props.quiz.noAccess) {
      return <DefaultButton disabled={true}>Start Quiz</DefaultButton>;
    }

    if (this.isQuizTaken() && this.canBeRetaken()) {
      return <DefaultButton href={practiceSessionStartPath}>Take New Quiz</DefaultButton>;
    } else if (!this.isQuizTaken()) {
      return <PrimaryButton href={practiceSessionStartPath}>Start Quiz</PrimaryButton>;
    }
  }

  renderQuizStatusText() {
    const { quiz } = this.props;
    const { answers } = quiz;
    const correctAnswerCount = this.quizCorrectAnswerCount();

    if (this.isQuizPassed()) {
      return (
        <React.Fragment>
          <div>
            Well done! You answered{' '}
            <strong>
              {correctAnswerCount} of {answers.length} questions
            </strong>{' '}
            correctly.
          </div>

          {answers.length > correctAnswerCount ? (
            <div className="u-margin-T-m">
              Review the explanation & lesson videos for the questions you missed.
            </div>
          ) : null}
        </React.Fragment>
      );
    } else if (this.isQuizTaken()) {
      return (
        <React.Fragment>
          <div>
            You answered{' '}
            <strong>
              {correctAnswerCount} of {answers.length} questions
            </strong>{' '}
            correctly(3 correct answers are needed to pass).
          </div>

          <div className="u-margin-T-m">
            Watch the explanation & lesson videos for the questions you missed.
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          Answer {pluralize(quiz.itemCountToPass, 'question', 'questions')} correctly about "
          {this.props.contentBlock.name}" to master this subject.{' '}
          <strong>You can only take this quiz once.</strong>
        </React.Fragment>
      );
    }
  }

  isMastered() {
    return this.props.contentBlock.status === ASSIGNMENT_STATUS_MASTERED;
  }

  isQuizPassed() {
    return this.quizCorrectAnswerCount() >= this.props.quiz.itemCountToPass;
  }

  isQuizTaken() {
    const { answers } = this.props.quiz;
    return answers && answers.length > 0;
  }

  canBeRetaken() {
    const { assignment } = this.props;
    const currentUserAssignment = assignment && assignment.currentUserAssignment;

    return currentUserAssignment.canBeRetaken;
  }

  quizCorrectAnswerCount() {
    return filter(this.props.quiz.answers || [], 'isCorrect').length;
  }
}
