/* global mixpanel*/

interface MixpanelParams {
  category?: string;
  action?: string;
  label?: string;
  value?: any;
  location?: string;

  [key: string]: any;
}

export default function trackEvent(name, properties: MixpanelParams = {}) {
  if(window && typeof (window as any).mixpanel !== 'undefined') {
    // console.log('track', name, properties);
    (window as any).mixpanel.track(name, properties);
  }
}
