import React from 'react';

import style from './style.module.scss';

interface Props {
  children: React.ReactNode;
}

const ResponsiveButtonGroup: React.FC<Props> = (props) => {
  return (
    <div className={style.buttonGroup}>
      {props.children}
    </div>
  );
};

export default ResponsiveButtonGroup;

