import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { StudentReport } from './student_report'
import Card from '@magoosh/lib/card';
import styles from './style.module.scss';
import Icon from '@magoosh/b2b/app/assets/icons';
import colors from '@magoosh/b2b/app/assets/style.module.scss';
import { BorderlessButton, LinkButton, SecondaryButton } from '@magoosh/lib/button';
import { DataTable, inactiveRowStyles } from '@magoosh/lib/data_table';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { NavBar, NavLink } from '@magoosh/b2b/app/organisms/nav_bar';
import { Search } from '@magoosh/lib/data_table/search';
import { FilterToggle } from '@magoosh/lib/data_table/filter_toggle';
import { Badge } from '@magoosh/lib/data_table/badge';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import { dateFormatter } from '@magoosh/b2b/app/utils';
import { AppContext } from '@magoosh/b2b/app/app_context';
import { ResetSubmissionModal } from '@magoosh/b2b/app/organisms/reset_submission_modal';
import * as moment from 'moment';
import { Spinner } from '@magoosh/b2b/app/lib/spinner';
import { Subjects } from '@magoosh/b2b/app/pages/reporting/subjects';
import { PracticeTests } from '@magoosh/b2b/app/pages/reporting/practice_tests';
import { InlineEditable } from '@magoosh/b2b/app/lib/inline_editable';

export const Student: React.FC = (props) => {
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const { studentId } = useParams();
  const appContext = useContext(AppContext);

  const [student, setStudent] = useState(null);
  const [resettingSubmission, setResettingSubmission] = useState(null);

  useEffect(() => {
    fetch(paths.api.partner.student(studentId))
      .then((response) => {
        setStudent(response);
      })
  }, [studentId]);

  const defaultQueryParams = { sortColumn: 'title', filters: {} };
  const { dataQueryTableProps, queryParameters, runQuery } = useDataQuery(
    (queryParameters) => paths.api.partner.studentAssignments(studentId, queryParameters),
    defaultQueryParams
  );

  const assignmentIcon = <Icon iconType="Assignment" fill={colors.supplementaryAzure} size="m" />;
  const archivedAssignmentIcon = <Icon iconType="Assignment" fill={colors.grayLight} size="m" />;

  if (!student) { return <Spinner />; }

  return <>
    <div className={styles.breadcrumbs}>
      <strong>
        <Link to="/">Portal</Link>&nbsp;&gt;&nbsp;<Link to={url}>{student.name || student.email}</Link>
      </strong>
    </div>
    <h2>
      {student.name}
    </h2>
    <Card shadow className={styles.studentInfo}>
      <div className={styles.icon}>
        <Icon iconType="Student" fill={colors.supplementaryRainforest} />
      </div>
      <div className={styles.editButton}>
        {!appContext.externalSourceUser && (
          <LinkButton onClick={() => history.push(`/students/${studentId}/edit`)}>
            Edit Student
          </LinkButton>
        )}
      </div>
      <div className={styles.email}>
        <label>Email:</label>
        <span>{student.email}</span>
        {appContext.admin && <>&nbsp;(<a href={`/admin/users/${studentId}`} target={'admin'}>admin</a>)</>}
      </div>
      <div className={styles.school}>
        <label>School:</label>
        <span>{student.school}</span>
      </div>
      <div className={styles.enrolledClasses}>
        <label>Enrolled Classes:</label>
        <span>{student.enrolledClassesCount}</span>
      </div>
      <div className={styles.lastActiveAt}>
        <label>Last Active:</label>
        <span>{student.lastActiveAt && moment(student.lastActiveAt).format('L')}</span>
      </div>
    </Card>
    <NavBar>
      <NavLink to={`${url}/performance`} text="Performance" />
      <NavLink to={`${url}/assignments`} text="Assignments" />
      <NavLink to={`${url}/subjects`} text="Subjects" />
      <NavLink to={`${url}/practice-tests`} text="Practice Tests" />
    </NavBar>
    <Switch>
      <Route path={`${path}/performance`}>
        <StudentReport subscriptions={student.subscriptions} />
      </Route>
      <Route path={`${path}/assignments`}>
        <div className={styles.studentAssignmentsDataTable}>
          <DataTable
            {...dataQueryTableProps}
            primaryAttribute={{ selector: 'title', name: 'Name', sortable: true }}
            secondaryAttributeSelector='classSectionName'
            linkBaseUrl="/assignments/"
            columns={[
              {
                selector: 'content',
                name: 'Content',
                sortable: true
              },
              {
                selector: 'assignmentDueDate',
                name: 'Due Date',
                sortable: true,
                format: (assignment) => dateFormatter(assignment.assignmentDueDate),
              },
              {
                selector: 'videoViewsCount',
                name: 'Activity',
                format: (assignment) => {
                  return <>
                    Videos: {assignment['videoViewsCount']}/{assignment['videosCount']}
                    <br />
                    Questions: {assignment['practiceQuestionsPromptsAnsweredCount']}/{assignment['practiceQuestionsPromptsCount']}
                  </>
                }
              },
              {
                selector: 'quizStatus',
                name: 'Quiz Status',
                sortable: false,
                format: (assignment) => {
                  return <>
                    {assignment['completedQuizzes']} completed
                    <br />
                    {assignment['passedQuizzes']} passed
                  </>
                }
              },
              {
                selector: 'accuracy',
                name: 'Accuracy',
                sortable: true,
                format: (assignment) => {
                  if (assignment.accuracy) {
                    return `${Math.round(assignment.accuracy * 100)}%`;
                  }
                }
              },
              {
                selector: 'grade',
                name: 'Grade',
                sortable: true,
                format: (student) => {
                  if (student['userAssignmentId']) {
                    return (
                      <InlineEditable
                        value={student['grade']}
                        placeholder='Not graded'
                        patchUrl={paths.api.gradeAssignment(student['userAssignmentId'])}
                        name='grade'
                        type='number'
                        min={0}
                        max={100}
                      />
                    )
                  }
                }
              },
              {
                selector: 'assignmentState',
                name: 'Status',
                sortable: true,
                style: { textTransform: 'capitalize' }
              },
            ]}
            conditionalRowStyles={[
              {
                when: (assignment) => assignment.archived,
                style: inactiveRowStyles
              }
            ]}>
            <DataTable.Filters>
              <Search runQuery={runQuery} />
              <FilterToggle
                queryParameters={queryParameters}
                runQuery={runQuery}
                filterName={'archived'}
                filterLabel={'Show archived'}
              />
            </DataTable.Filters>
            <DataTable.HeaderActions>
              <DownloadButton
                queryParameters={queryParameters}
                fetchUrl={(params) => paths.api.partner.studentAssignments(studentId, params)}
                fileName={`${student.name} ${student.email} assignments data.csv`.trim()}
                disabled={appContext.trialAccess}
              />
            </DataTable.HeaderActions>
            <DataTable.PrimaryAttributeLabel>
              {(assignment: Assignment) => (
                <>
                  {assignment.archived ? <Badge>Archived</Badge> : null}
                  {assignment.title}
                </>
              )}
            </DataTable.PrimaryAttributeLabel>
            <DataTable.ItemIcon>
              {(assignment) => {
                return assignment.archived ? archivedAssignmentIcon : assignmentIcon;
              }}
            </DataTable.ItemIcon>
            <DataTable.ItemActions>
              {(assignment) => {
                return assignment.assignmentState == 'submitted' && <BorderlessButton
                  title={'Reset assignment'}
                  icon="undo"
                  onClick={() => {
                    setResettingSubmission({
                      assignment: assignment,
                      student: student,
                      userAssignmentId: assignment.userAssignmentId
                    })
                  }}
                  size={'sm'}
                >
                  Reset
                </BorderlessButton>
              }}
            </DataTable.ItemActions>
          </DataTable>
          <ResetSubmissionModal
            assignment={resettingSubmission && resettingSubmission.assignment}
            student={resettingSubmission && resettingSubmission.student}
            userAssignmentId={resettingSubmission && resettingSubmission.userAssignmentId}
            onCancel={() => {
              setResettingSubmission(null);
            }}
            onResetAssignmentComplete={() => {
              runQuery();
              setResettingSubmission(null);
            }}
          />
        </div>
      </Route>
      <Route path={`${path}/subjects`}>
        <Subjects studentId={student.id} />
      </Route>
      <Route path={`${path}/practice-tests`}>
        <PracticeTests studentId={student.id} />
      </Route>
      <Route exact path={path}>
        <Redirect to={`${url}/performance`} />
      </Route>
    </Switch>
  </>;
}
