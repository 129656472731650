import style from './style.module.scss';
import { paths } from 'config/path_helpers';
import * as React from 'react';
import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import { PracticeSessionBlockData, SubjectBlockData } from 'types/content_blocks';
import { pluralize } from 'utilities/pluralize';
import { ExamSectionData } from 'types/exam_section';
import { Assignment } from '../../types';
import Card from '@magoosh/lib/card';

interface PracticeCardProps {
  assignment?: Assignment;
  contentBlock: SubjectBlockData;
  examSection: ExamSectionData;
  practice: PracticeSessionBlockData;
  title: string;
}

export default class PracticeCard extends React.Component<PracticeCardProps> {
  render() {
    const { examSection, practice, title } = this.props;
    let itemCountAndType;

    if (!practice) {
      return null;
    }

    if (practice.itemCount && practice.itemType) {
      itemCountAndType = (
        <div className="text-gray-light">
          <small>
            {pluralize(practice.itemCount, practice.itemType, `${practice.itemType}s`)}{' '}
            {this.renderPromptCount(practice)}
            {this.renderAnswerCount(practice)}
          </small>
        </div>
      );
    }

    return (
      <Card shadow className={style.card}>
        <h4 className="u-margin-B-xxs">
          <strong>{title}</strong>
        </h4>
        <small className="text-gray-light">{practice.estimatedTimeMinutes} minutes</small>

        <div className="u-margin-T-m">{this.practiceCopyForExamSection()}</div>

        <div className={style.centeredButtonContainer}>
          <PrimaryButton href={this.startPath(practice)} disabled={practice.noAccess}>
            Practice {examSection.displayName}
          </PrimaryButton>

          {itemCountAndType}
        </div>

        <div className={style.centeredButtonContainer}>
          <DefaultButton href={this.reviewPath()} disabled={practice.noAccess}>
            Review {examSection.displayName}
          </DefaultButton>
        </div>
      </Card>
    );
  }

  practiceCopyForExamSection() {
    const examSectionSlug = this.props.examSection.slug;

    if (examSectionSlug === 'english') {
      return (
        <React.Fragment>
          Practice English passages (about 15 questions) that will give you practice with a range of skills
          and subjects, including this one. It's important to practice full passages to get familiar with the
          passage format on the exam. Pay attention to the subjects you miss most often for later review and
          don't get discouraged!
        </React.Fragment>
      );
    } else if (examSectionSlug === 'math') {
      return (
        <React.Fragment>
          {this.props.examSection.branding.displayName} questions are complex and can test multiple subjects
          in a single question. Pay attention to the subjects you miss most often for later review and don't
          get discouraged!
        </React.Fragment>
      );
    } else if (examSectionSlug === 'reading') {
      return (
        <React.Fragment>
          Practice Reading passages (about 10 questions) that cover a range of subjects, including this one.
          It's important to practice full passages to get familiar with the passage format on the exam. Pay
          attention to the subjects you miss most often for later review and don't get discouraged!
        </React.Fragment>
      );
    } else if (examSectionSlug === 'science') {
      return (
        <React.Fragment>
          Practice Science passages (5 - 8 questions) that cover a range of subjects, including this one. It's
          important to practice full passages to get familiar with the passage format on the exam. Pay
          attention to the subjects you miss most often for later review and don't get discouraged!
        </React.Fragment>
      );
    }
  }

  renderPromptCount(practice: PracticeSessionBlockData) {
    if (practice.promptCount !== null && practice.itemType === 'question') {
      return `(${pluralize(practice.promptCount, 'prompt')})`;
    }

    return null;
  }

  renderAnswerCount(practice: PracticeSessionBlockData) {
    if (practice.answerCount !== null) {
      return (
        <>
          <br />
          {practice.answerCount} answered
        </>
      );
    }

    return null;
  }

  reviewPath() {
    const options: any = {
      collapse_filters: true
    };

    if (this.props.assignment) {
      options.assignment_id = this.props.assignment.id;
      options['review[study_item]'] = this.props.practice.id;
    } else {
      options.subject_block_id = this.props.contentBlock.id;
    }

    return paths.examSectionReview(this.props.examSection.id, options);
  }

  startPath(practice: PracticeSessionBlockData) {
    let path = practice.practiceSessionStartPath;

    if (this.props.assignment) {
      path += `?assignment_id=${this.props.assignment.id}&review[study_item]=${this.props.practice.id}`;
    }

    return path;
  }
}
