import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StudentsDataTable } from '@magoosh/b2b/app/organisms/students_data_table';
import fetch from 'utilities/fetch';
import * as moment from 'moment';
import { paths } from 'config/path_helpers';
import styles from './style.module.scss';
import colors from '../../assets/style.module.scss';
import { LinkButton } from '@magoosh/lib/button';
import Card from '@magoosh/lib/card';
import Icon from '../../assets/icons';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { NavBar, NavLink } from '@magoosh/b2b/app/organisms/nav_bar';
import { AssignmentsDataTable } from '@magoosh/b2b/app/organisms/assignments_data_table';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import EnrollStudentsForm from '@magoosh/b2b/app/pages/enroll_students_form';
import { AppContext } from '@magoosh/b2b/app/app_context';

export const Class: React.FC = (props) => {
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const { classSectionId } = useParams();
  const appContext = useContext(AppContext);

  const [classSection, setClassSection] = useState<ClassSection>();

  const loadClassSection = () => {
    fetch(paths.api.partner.class(classSectionId)).then((response) => {
      setClassSection(response);
    });
  }

  useEffect(() => {
    loadClassSection();
  }, []);

  const name = classSection ? classSection.name || classSection.displayName : '';

  return (
    <>
      {classSection && (
        <>
          <div className={styles.breadcrumbs}>
            <strong>
              <Link to="/">Portal</Link>&nbsp;&gt;&nbsp;<Link to={url}>{name}</Link>
            </strong>
          </div>
          <Switch>
            <Route path={`${path}/enroll`}>
              <EnrollStudentsForm classSection={classSection} />
            </Route>
            <Route>
              <Card shadow className={styles.classSectionInfo}>
                <div className={styles.icon}>
                  {classSection.active ? (
                    <Icon iconType="Class" fill={colors.supplementaryRadRed} />
                  ) : (
                    <Icon iconType="Class" fill={colors.grayLight} />
                  )}
                </div>
                <div className={styles.editButton}>
                  {!appContext.externalSourceUser && (
                    <LinkButton onClick={() => history.push(`/classes/${classSectionId}/edit`)}>
                      Edit Class
                    </LinkButton>
                  )}
                </div>
                <div className={styles.name}>
                  <label>Name:</label>
                  <span>{name}</span>
                </div>
                <div className={styles.subject}>
                  <label>Subject:</label>
                  <span>{classSection.subject || '--'}</span>
                </div>
                <div>
                  <label className={styles.period}>Period:</label>
                  <span>{classSection.period || '--'}</span>
                </div>
                <div className={styles.grade}>
                  <label>Grade:</label>
                  <span>{classSection.grade || '--'}</span>
                </div>
                <div className={styles.sectionNumber}>
                  <label>Section Number:</label>
                  <span>{classSection.sectionNumber || '--'}</span>
                </div>
                {!classSection.active && (
                  <div className={styles.badge}>
                    <FontAwesomeIcon icon={'circle'} style={{ color: colors.supplementaryCarrot }} />
                    &nbsp;<strong>ARCHIVED</strong>
                  </div>
                )}
                {classSection.active && classSection.externalSource == 'schoology' && (
                  <div className={styles.syncedAt}>
                    <label>Synced At:</label>
                    <span>{
                      classSection.lastSyncedAt && moment(classSection.lastSyncedAt).format('l h:mm:ss A') ||
                      '--'
                    }</span>
                  </div>
                )}
              </Card>
              <NavBar>
                <NavLink to={`${url}/students-performance`} text="Students Performance" />
                <NavLink to={`${url}/class-assignments`} text="Class Assignments" />
              </NavBar>
              <Switch>
                <Route path={`${path}/students-performance`}>
                  <StudentsDataTable classSection={classSection} />
                </Route>
                <Route path={`${path}/class-assignments`}>
                  <AssignmentsDataTable classSection={classSection} />
                </Route>
                <Route exact path={path}>
                  <Redirect to={`${url}/students-performance`} />
                </Route>
              </Switch>
            </Route>
          </Switch>
        </>
      )}
    </>
  );
};
