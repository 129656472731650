import * as React from 'react';

export default class MultiStep extends React.Component {
  props: {
    stepIndex: number;
    override?: React.ReactNode;
    footer?: React.ReactNode;
    children: React.ReactNode;
  };

  render() {
    return (
      <React.Fragment>
        {
          this.props.override
          ? this.props.override
          : this.props.children[this.props.stepIndex]
        }

        {
          this.props.footer
          ? <div className="u-margin-T-xl">
              {this.props.footer}
            </div>
          : null
        }
      </React.Fragment>
    );
  }
}
