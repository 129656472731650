import { StudyScheduleDetailData, SuggestedLessonsSectionData } from './types';

export enum ActionTypes {
  INITIALIZE = 'STUDY_SCHEDULE/INITIALIZE',
  SET_ITEM_COMPLETED = 'STUDY_SCHEDULE/SET_ITEM_COMPLETED',
  SET_PLAN = 'STUDY_SCHEDULE/SET_PLAN',
  TOGGLE_PLAN_OPTIONS = 'STUDY_SCHEDULE/TOGGLE_PLAN_OPTIONS',
  UPDATE_USER_PROFILE = 'STUDY_SCHEDULE/UPDATE_USER_PROFILE',
  OPT_OUT = 'STUDY_SCHEDULE/OPT_OUT',
  SET_SUGGESTED_LESSONS = 'STUDY_SCHEDULE/SET_SUGGESTED_LESSONS'
}

export const hidePlanOptions = () => {
  return {
    type: ActionTypes.TOGGLE_PLAN_OPTIONS,
    value: false
  };
};

export const setItemCompleted = (id: number, isCompleted: boolean) => {
  return {
    type: ActionTypes.SET_ITEM_COMPLETED,
    id,
    isCompleted
  };
};

export const setPlan = (plan: StudyScheduleDetailData) => {
  return {
    type: ActionTypes.SET_PLAN,
    plan
  };
};

export const showPlanOptions = () => {
  return {
    type: ActionTypes.TOGGLE_PLAN_OPTIONS,
    value: true
  };
};

export const updateUserProfile = (userProfile: any) => {
  return {
    type: ActionTypes.UPDATE_USER_PROFILE,
    userProfile
  };
};

export const optOut = () => {
  return {
    type: ActionTypes.OPT_OUT
  };
};

export const setSuggestedLessons = (suggestedLessons: SuggestedLessonsSectionData[]) => {
  return {
    type: ActionTypes.SET_SUGGESTED_LESSONS,
    suggestedLessons
  }
}
