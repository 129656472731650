import React, { useEffect, useState } from 'react';
import { findIndex } from 'lodash';

import { ModalProvider } from '@magoosh/context/modal_context';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import ContentRequestsStatus from './content_requests_status';
import DraftPassagePreview from './draft_passage_preview';
import DraftPromptPreview from './draft_prompt_preview';
import EditPromptRequest from './edit_prompt_request';
import PersistPromptsAction from './persist_prompts_action';
import PromptRequestDescription from './prompt_request_description';
import StatusLabel from './status_label';
import { PromptRequest } from './types';

interface Props {
  promptRequest: PromptRequest;
}

const ShowPromptRequest: React.FC<Props> = (props) => {
  const [promptRequest, setPromptRequest] = useState(props.promptRequest);

  useEffect(() => {
    if (!isActive(promptRequest.status)) return;

    pollForUpdates();
  }, []);

  const cancel = () => {
    fetch(adminPaths.api.promptRequestCancel(promptRequest.id), {
      method: 'POST'
    }).then((response) => {
      setPromptRequest(response.promptRequest);
    });
  };

  const canCancel = () => {
    return isActive(promptRequest.status);
  };

  const canRetry = () => {
    return promptRequest.status === 'error';
  };

  const fetchPromptRequest = () => {
    return fetch(adminPaths.api.promptRequest(promptRequest.id));
  };

  const isActive = (status) => {
    if (status === 'finished') return false;
    if (status === 'error') return false;
    if (status === 'cancelled') return false;

    return true;
  };

  const passageContentRequest = () => {
    return promptRequest.contentRequests.find((contentRequest) => (
      contentRequest.contentType === 'passage'
    ));
  };

  const passageIsEditable = () => {
    return !!promptRequest.contentRequests.find((contentRequest) => (
      contentRequest.status != 'waiting' && contentRequest.contentType !== 'passage' && contentRequest.contentType !== 'question_profile'
    ));
  };

  const pollForUpdates = () => {
    setTimeout(() => {
      fetchPromptRequest().then((resp) => {
        setPromptRequest(resp.promptRequest);

        if (isActive(resp.promptRequest.status)) {
          pollForUpdates();
        }
      });
    }, 2000);
  };

  const retry = () => {
    fetch(adminPaths.api.promptRequestRetry(promptRequest.id), {
      method: 'POST'
    }).then((response) => {
      pollForUpdates();
      setPromptRequest(response.promptRequest);
    });
  };

  const setPassage = (passage) => {
    setPromptRequest({
      ...promptRequest,
      passage
    });
  };

  const setDraftPrompt = (draftPrompt) => {
    const draftPromptIndex = findIndex(promptRequest.draftPrompts, (p) => p.id === draftPrompt.id);
    const updatedDraftPrompts = [...promptRequest.draftPrompts];
    updatedDraftPrompts[draftPromptIndex] = draftPrompt;

    setPromptRequest({
      ...promptRequest,
      draftPrompts: updatedDraftPrompts
    });
  };

  return (
    <div style={{ minHeight: '100vh' }}>
      <div style={{ backgroundColor: '#DEF4EB', borderBottom: '2px solid #26B67C', margin: '0 -15px', position: 'sticky', top: 0, zIndex: 10 }}>
        <div className="align-items-center d-flex u-padding-A-s">
          <div className="align-items-center d-flex flex-1 gap-s">
            <div className="label label-success">
              {promptRequest.draftPrompts.filter((draftPrompt) => draftPrompt.status === 'accepted').length} Accepted
            </div>

            <div className="label bg-gray-light">
              {promptRequest.draftPrompts.filter((draftPrompt) => draftPrompt.status === 'maybe').length} Maybe
            </div>

            <div className="label label-danger">
              {promptRequest.draftPrompts.filter((draftPrompt) => draftPrompt.status === 'rejected').length} Rejected
            </div>

            <div>
              <strong><small>out of {promptRequest.draftPrompts.length} generated</small></strong>
            </div>
          </div>

          {
            promptRequest.status === 'validating' && promptRequest.contentRequests.every((cr) => cr.status === 'finished') && (
              <div>
                <PersistPromptsAction
                  onSuccess={(promptRequest) => { setPromptRequest(promptRequest) }}
                  promptRequest={promptRequest}
                />
              </div>
            )
          }
        </div>
      </div>

      <div className="d-flex flex-column gap-m u-margin-T-m">
        <div className="d-flex">
          <div className="flex-1">
            <a href={adminPaths.promptRequests()}>All Prompt Requests</a>
            <h2 className="text-primary u-margin-A-n"><strong>Prompt Request</strong></h2>
            <PromptRequestDescription promptRequest={props.promptRequest} />
          </div>
        </div>

        <EditPromptRequest promptRequest={promptRequest} />
      </div>

      <hr />

      <div className="d-flex flex-column gap-m">
        <div className="d-flex gap-m">
          <div className="d-flex flex-1 gap-s">
            <div>
              <h3 className="u-margin-A-n"><strong>Status</strong></h3>
            </div>

            <div>
              <StatusLabel status={promptRequest.status} />
            </div>
          </div>

          {
            canRetry() && (
              <a onClick={retry}>Retry</a>
            )
          }
          {
            canCancel() && (
              <a className="text-danger" onClick={cancel}>Cancel</a>
            )
          }
        </div>

        <ContentRequestsStatus promptRequest={promptRequest} />
      </div>

      <hr />

      {
        promptRequest.passage && (
          <>
            <div className="d-flex flex-column gap-m">
              <h3 className="u-margin-A-n"><strong>Passage</strong></h3>
              <DraftPassagePreview
                contentRequest={passageContentRequest()}
                onChange={setPassage}
                passage={promptRequest.passage}
                promptRequest={promptRequest}
                readOnly={passageIsEditable()}
              />
            </div>

            <hr />
          </>
        )
      }

      <div className="d-flex flex-column gap-m">
        <h3 className="u-margin-A-n"><strong>Prompts</strong></h3>

        {
          promptRequest.draftPrompts.map((draftPrompt) => (
            <DraftPromptPreview
              key={draftPrompt.id}
              draftPrompt={draftPrompt}
              onChange={setDraftPrompt}
              promptRequest={promptRequest}
            />
          ))
        }
      </div>
    </div>
  );
};

const ShowPromptRequestProvider: React.FC<Props> = (props) => {
  return (
    <ModalProvider>
      <ShowPromptRequest {...props} />
    </ModalProvider>
  );
};

export default ShowPromptRequestProvider;
