import * as React from 'react';
import { isString } from 'lodash';

import { Select, InputSize } from '@magoosh/lib/forms'
import { pluralize } from 'utilities/pluralize';

interface Props {
  hoursSelected: number | string;
  onChangeHoursPerDay: (hours: number) => void;
  size?: InputSize;
}

export default class StudyLengthHoursPicker extends React.Component<Props> {
  HOUR_OPTIONS = [0.5, 1, 1.5, 2, 2.5, 3];

  handleHoursChanged = (e) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    this.props.onChangeHoursPerDay(value);
  };


  render() {
    const { hoursSelected } = this.props;

    return (
      <Select
        groupClassName="u-margin-B-n"
        onChange={this.handleHoursChanged}
        size={this.props.size}
        value={isString(hoursSelected) ? parseFloat(hoursSelected as string) : hoursSelected}>
        {
          this.HOUR_OPTIONS.map((hours, idx) => (
            <option
              key={idx}
              value={hours}>
              {hours == 0.5 ? '30 minutes' : pluralize(hours, 'hour', 'hours')}
            </option>
          ))
        }
      </Select>
    );
  }
}
