import Dashboard from './pages/dashboard';

const routes = [
  {
    path: '/dashboard',
    component: Dashboard
  }
];

export default routes;
