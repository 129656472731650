import Modal from "@magoosh/lib/modal";
import {Modal as BootstrapModal} from "react-bootstrap";
import style from "./style.module.scss";
import * as React from "react";
import cx from 'classnames'

export default class CustomModal extends Modal {
  render() {
    return (
      <BootstrapModal
        backdrop={this.props.backdrop || 'static'}
        className={cx(style.modalForm, this.props.className)}
        dialogClassName={cx(this.props.dialogClassName, style.customModal)}
        onHide={this.props.onHide}
        show={this.props.show}>
        {this.renderHeader()}
        <BootstrapModal.Body>
          {this.props.children}
        </BootstrapModal.Body>
      </BootstrapModal>
    )
  }
}
