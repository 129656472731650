import React from 'react';
import cx from 'classnames';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import LoadingStateCompleteIllustration from './assets/loading_state_complete.svg';
import LoadingStateIncompleteIllustration from './assets/loading_state_incomplete.svg';
import style from './style.module.scss';

interface Props {
  headline: string;
  text: string;
  isColored?: boolean;
}

export const StatusMessage: React.FC<Props> = (props) => {
  const { headline, text, isColored, children } = props;

  return (
    <div className={cx(style.statusMessage, 'u-margin-V-xl')}>
      <div className={style.content}>
        {isColored ? (
          <>
            <img src={LoadingStateIncompleteIllustration} alt="Loading State Incomplete Illustration" />
            <div className={style.spinner}>
              <FontAwesomeIcon icon="spinner" className="fa-pulse fa-5x" />
            </div>
          </>
        ) : (
          <img src={LoadingStateCompleteIllustration} alt="Loading State Complete Illustration" />
        )}
      </div>
      <h4>
        <strong>{headline}</strong>
      </h4>
      <div className="text-center">{text}</div>
      {children}
    </div>
  );
};

export default StatusMessage;
