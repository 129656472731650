import * as React from 'react';
import { Fragment } from 'react';
import style from '../style.module.scss';
import { AnswerCount, ClassSection, ExamSection, StudentPerformanceStore } from '../types';
import Filters from '../student_performance/filters';
import PerformanceData from '../student_performance/performance_data';

export interface StudentPerformanceProps extends StudentPerformanceStore {
  answerCountsByExamSectionId?: NumberHash<AnswerCount>;
  averageLessonsSeenByExamSectionId?: NumberHash<number>;
  averageTimesSpentByExamSectionId?: NumberHash<number>;
  classSectionsByGrade: StringHash<ClassSection[]>;
  dispatch: (...args) => any;
  endDate: Date;
  examSections?: ExamSection[];
  onGetData: (grade, classSectionId) => any;
  resetFilters: () => any;
  selectedClassSectionId: number;
  selectedGrade: string;
  setData: (data) => any;
  setFilter: (filter) => any;
  setLoadingMode: (loadingMode) => any;
  startDate: Date;
  studentCount: number;
  updateFilter: (filter) => any;
}

export default class StudentPerformance extends React.Component<StudentPerformanceProps> {
  componentDidMount(): void {
    this.props.onGetData(this.props.selectedGrade, this.props.selectedClassSectionId);
  }

  render() {
    return (
      <Fragment>
        <div className={style.sectionHeader}>Class Performance</div>
        <Filters {...this.props} />
        <PerformanceData {...this.props} />
      </Fragment>
    );
  }
}
