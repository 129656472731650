import React from 'react';

import { EditorialVideo } from '../types';

const ContentfulEditorialVideo: React.FC<EditorialVideo & { className?: string; }> = (props) => {
  return (
    // Adding a key ensures this components works as user navigates from profile to
    // profile (was showing previous profile's video when navigating back)
    <div className={props.className} key={props.contentfulEntryId}>
      {/*lite-youtube provided by lite_yt_embed.js and lite_yt_embed.css Rails assets*/}
      {/*@ts-ignore*/}
      <lite-youtube params="rel=0" videoId={props.youTubeVideoId}>
        <button className="lty-playbtn" />
      {/*@ts-ignore*/}
      </lite-youtube>
    </div>
  );
};

export default ContentfulEditorialVideo;
