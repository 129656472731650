import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import styles from './style.module.scss';
import colors from '../../assets/style.module.scss';
import Card from '@magoosh/lib/card';
import Icon from '../../assets/icons';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { AppContext } from '@magoosh/b2b/app/app_context';
import { ClassSectionsPerformanceDataTable } from './class_sections_performance_data_table';
import { NavBar, NavLink } from '@magoosh/b2b/app/organisms/nav_bar';
import { AssignmentsDataTable } from '@magoosh/b2b/app/organisms/assignments_data_table';

export const School: React.FC = (props) => {
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const { schoolId } = useParams();
  const appContext = useContext(AppContext);

  const [school, setSchool] = useState<School>();

  useEffect(() => {
    fetch(paths.api.partner.school(schoolId)).then((response) => {
      setSchool(response);
    });
  }, []);

  return (
    <>
      {school && (
        <>
          <div className={styles.breadcrumbs}>
            <strong>
              <Link to="/">Portal</Link>&nbsp;&gt;&nbsp;<Link to={url}>{school.name}</Link>
            </strong>
          </div>
          <Card shadow className={styles.schoolInfo}>
            <div className={styles.icon}>
              <Icon iconType="School" fill={colors.supplementaryCarrot} />
            </div>
            <div className={styles.name}>
              <label>Name:</label>
              <span>{school.name}</span>
            </div>
            <div className={styles.districtName}>
              <label>District:</label>
              <span>{school.districtName || '--'}</span>
            </div>
            <div className={styles.studentsCount}>
              <label>Students:</label>
              <span>{school.studentsCount || '--'}</span>
            </div>
            <div className={styles.classSectionsCount}>
              <label>Classes:</label>
              <span>{school.classSectionsCount || '--'}</span>
            </div>
            <div className={styles.teachersCount}>
              <label>Instructors:</label>
              <span>{school.teachersCount || '--'}</span>
            </div>
            <div className={styles.assignmentsCount}>
              <label>Assignments:</label>
              <span>{school.assignmentsCount || '--'}</span>
            </div>
          </Card>
          <NavBar>
            <NavLink to={`${url}/classes-performance`} text="Classes Performance" />
            <NavLink to={`${url}/assignments`} text="School Assignments" />
          </NavBar>
          <Switch>
            <Route path={`${path}/classes-performance`}>
              <ClassSectionsPerformanceDataTable school={school} />
            </Route>
            <Route path={`${path}/assignments`}>
              <AssignmentsDataTable school={school} />
            </Route>
            <Route exact path={path}>
              <Redirect to={`${url}/classes-performance`} />
            </Route>
          </Switch>
        </>
      )}
    </>
  );
};
