import React, { useCallback, useContext, useMemo, useState } from 'react';
import { BorderlessButton, LinkButton, SecondaryButton } from '@magoosh/lib/button';
import { DataTable, inactiveRowStyles } from '@magoosh/lib/data_table';
import { paths } from 'config/path_helpers';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Search } from '@magoosh/lib/data_table/search';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import fetch from 'utilities/fetch';
import { DateRangeFilter } from '@magoosh/lib/data_table/date_range_filter';
import { PerformanceStatsBlock } from '@magoosh/b2b/app/organisms/performance_stats_block';
import { UpdateEnrollmentsModal } from '@magoosh/b2b/app/organisms/students_data_table/update_enrollments_modal';
import { pluralize } from 'utilities/pluralize';
import { dateFormatter, durationFormatter } from '@magoosh/b2b/app/utils';
import colors from '../../assets/style.module.scss';
import Icon from '@magoosh/b2b/app/assets/icons';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@magoosh/b2b/app/app_context';
import style from './style.module.scss';
import { FilterToggle } from '@magoosh/lib/data_table/filter_toggle';
import { Badge } from '@magoosh/lib/data_table/badge';

export const StudentsDataTable: React.FC<{ classSection?: ClassSection }> = (props) => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const defaultQueryParams = useMemo(() => {
    const defaultQueryParams = { sortColumn: 'fullName', filters: {} };
    if (props.classSection) {
      defaultQueryParams.filters['classSectionId'] = props.classSection.id;
    }

    return defaultQueryParams;
  }, [props.classSection]);

  const { queryParameters, runQuery, dataQueryTableProps } = useDataQuery(
    paths.api.partner.students,
    defaultQueryParams
  );

  const studentIcon = <Icon iconType="Student" fill={colors.supplementaryRainforest} size="m" />;
  const grayStudentIcon = <Icon iconType="Student" fill={colors.grayLight} size="m" />;

  const estimatedScoresFormatter = useCallback((student) => {
    return (
      <>
        {Object.keys(student.estimatedScores).map((examName) => {
          const scoreRange = student.estimatedScores[examName];
          let formattedEstimatedScores = '';

          if (!scoreRange) {
            return;
          }

          if (scoreRange.notEnoughData) {
            formattedEstimatedScores = 'N/A';
          } else {
            formattedEstimatedScores = scoreRange.scoreLow + ' - ' + scoreRange.scoreHigh;
          }

          return (
            <p key={examName} className={style.estimatedScores}>
              <strong>{examName.toLocaleUpperCase()}</strong>
              <br />
              {formattedEstimatedScores}
            </p>
          );
        })}
      </>
    );
  }, []);

  const [editEnrollmentStudents, setEditEnrollmentStudents] = useState(null);

  const resetUnenrollingStudents = () => {
    setEditEnrollmentStudents(null);
  };

  const unenrollStudents = (ids) => {
    fetch(paths.api.partner.unenrollStudents(props.classSection.id, ids), { method: 'DELETE' }).then(() => {
      runQuery();
      resetUnenrollingStudents();
    });
  };

  const reenrollStudent = (id) => {
    fetch(paths.api.partner.reenrollStudent(props.classSection.id, id), { method: 'PUT' })
      .then(() => {
        runQuery();
        resetUnenrollingStudents();
      })
      .catch((response) => {
        alert(response.errors._error);
      });
  };

  return (
    <>
      <DataTable
        {...dataQueryTableProps}
        primaryAttribute={{ selector: 'fullName', name: 'Student', sortable: true }}
        linkBaseUrl="/students/"
        secondaryAttributeSelector="email"
        selectableRows={props.classSection != null}
        columns={[
          {
            selector: 'lastActiveAt',
            name: 'Last Active',
            format: (student) => dateFormatter(student.lastActiveAt),
            sortable: true
          },
          {
            selector: 'weekTime',
            name: 'Week Time',
            format: (student) => durationFormatter(student.weekTime),
            sortable: true
          },
          { selector: 'answersCount', name: 'Answers', sortable: true },
          { selector: 'lessonsWatched', name: 'Lessons', sortable: true },
          {
            selector: 'totalTime',
            name: 'Total Time',
            format: (student) => durationFormatter(student.totalTime),
            sortable: true
          },
          { selector: 'estimatedScores', name: 'Est. Scores', format: estimatedScoresFormatter }
        ]}
        conditionalRowStyles={[
          {
            when: (student) => !student.enrolled,
            style: inactiveRowStyles
          }
        ]}>
        <DataTable.Filters>
          <Search runQuery={runQuery} />
          <DateRangeFilter runQuery={runQuery} />
          {!appContext.externalSourceUser && props.classSection && (
            <FilterToggle
              queryParameters={queryParameters}
              runQuery={runQuery}
              filterName="unenrolled"
              filterLabel="Show archived"
            />
          )}
        </DataTable.Filters>
        <DataTable.HeaderActions>
          {!appContext.externalSourceUser && (
            <SecondaryButton onClick={() => history.push('enroll')} icon={'plus'} size={'xsmall'}>
              Enroll Students
            </SecondaryButton>
          )}
          <DownloadButton
            queryParameters={queryParameters}
            fetchUrl={paths.api.partner.students}
            fileName={'student_data.csv'}
            disabled={appContext.trialAccess}
          />
        </DataTable.HeaderActions>
        <DataTable.StatsBlock>
          <PerformanceStatsBlock queryParameters={queryParameters} />
        </DataTable.StatsBlock>
        <DataTable.ItemIcon>
          {(student) => student.enrolled ? studentIcon : grayStudentIcon }
        </DataTable.ItemIcon>
        <DataTable.ItemActions>
          {!appContext.externalSourceUser &&
            ((student) => (
              <>
                <BorderlessButton
                  title={'Edit student'}
                  icon="edit"
                  onClick={() => history.push(`/students/${student.id}/edit`)}
                />
                {props.classSection &&
                  (student.enrolled ? (
                    <BorderlessButton
                      title={'Unenroll student'}
                      icon={'minus-square'}
                      onClick={() => setEditEnrollmentStudents([student])}
                    />
                  ) : (
                    <BorderlessButton
                      title={'Re-enroll student'}
                      icon={'plus-square'}
                      onClick={() => setEditEnrollmentStudents([student])}
                    />
                  ))}
              </>
            ))}
        </DataTable.ItemActions>
        <DataTable.PrimaryAttributeLabel>
          {(student) => (
            <>
              {student.enrolled ? null : <Badge>Archived</Badge>}
              {student.fullName}
            </>
          )}
        </DataTable.PrimaryAttributeLabel>
        <DataTable.BulkActions>
          <SecondaryButton
            size={'xsmall'}
            icon={'minus-square'}
            onClick={() => setEditEnrollmentStudents(dataQueryTableProps.selectedRows)}>
            Unenroll {pluralize(dataQueryTableProps.selectedRows.length, 'student')}
          </SecondaryButton>
        </DataTable.BulkActions>
      </DataTable>
      <UpdateEnrollmentsModal
        students={editEnrollmentStudents}
        classSection={props.classSection}
        reset={resetUnenrollingStudents}
        unenroll={() => unenrollStudents(editEnrollmentStudents.map((student) => student.id))}
        reenroll={() => reenrollStudent(editEnrollmentStudents[0].id)}
      />
    </>
  );
};
