import React, { useState } from 'react';

import { DangerButton, InfoButton } from '@magoosh/lib/button';

import * as api from '@admin/app/api';

interface Props {
  contentFixId: number;
  onAccept: () => void;
  onReject: () => void;
}

const ContentFixActions: React.FC<Props> = (props) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const accept = () => {
    setIsAccepting(true);

    api.acceptTestPrepAIContentFix(props.contentFixId).then((_) => {
      props.onAccept();
    }).catch((error) => {
      alert(error.errors._error);
      setIsAccepting(false);
    });
  };

  const reject = () => {
    setIsRejecting(true);

    api.rejectTestPrepAIContentFix(props.contentFixId).then((_) => {
      props.onReject();
    }).catch((error) => {
      alert(error.errors._error);
      setIsRejecting(false);
    });
  };

  return (
    <>
      <DangerButton
        disabled={isAccepting || isRejecting}
        onClick={reject}
        submitting={isRejecting}
      >
        Reject
      </DangerButton>

      <InfoButton
        disabled={isAccepting || isRejecting}
        onClick={accept}
        submitting={isAccepting}
      >
        Accept
      </InfoButton>
    </>
  );
};

export default ContentFixActions;
