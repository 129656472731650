import React, { useContext } from 'react';
import { NavBar, NavLink } from '@magoosh/b2b/app/organisms/nav_bar';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Subjects } from '@magoosh/b2b/app/pages/reporting/subjects';
import { PracticeTests } from '@magoosh/b2b/app/pages/reporting/practice_tests';
import { AppContext } from '@magoosh/b2b/app/app_context';
import { Modules } from '@magoosh/b2b/app/pages/reporting/modules';
import { DefaultButton } from '@magoosh/lib/button';
import styles from '@magoosh/b2b/app/organisms/nav_bar/style.module.scss';
import { Alert } from 'react-bootstrap';
import { paths } from 'config/path_helpers';

export const Reporting: React.FC = () => {
  const appContext = useContext(AppContext);
  const { url, path } = useRouteMatch();

  const renderTrialContent = () => {
    return (
      <>
        <Alert className='text-center alert alert-banner'>
          You must <a href={paths.plans()}>upgrade your subscription</a> to access this feature.
        </Alert>
        <NavBar>
          <DefaultButton className={styles.tab} disabled={true}>Subjects</DefaultButton>
          <DefaultButton className={styles.tab} disabled={true}>Practice Tests</DefaultButton>
          {appContext.showModulesReport &&
            <DefaultButton className={styles.tab} disabled={true}>Modules</DefaultButton>
          }
        </NavBar>
      </>
    )
  }

  const renderDefaultContent = () => {
    return <>
      <NavBar>
        <NavLink to={`${url}/subjects`} text="Subjects" />
        <NavLink to={`${url}/practice-tests`} text="Practice Tests" />
        {appContext.showModulesReport &&
          <NavLink to={`${url}/modules`} text="Modules" />
        }
      </NavBar>
      <Switch>
        <Route path={`${path}/subjects`}>
          <Subjects />
        </Route>
        <Route path={`${path}/practice-tests`}>
          <PracticeTests />
        </Route>
        <Route path={`${path}/modules`}>
          <Modules />
        </Route>
        <Route exact path={path}>
          <Redirect to={`${url}/subjects`} />
        </Route>
      </Switch>
    </>;
  }

  return appContext.trialAccess ?  renderTrialContent() : renderDefaultContent();
}
