import { connect } from 'react-redux';
import StudyDayPicker from './template';
import { toggleStudyDay } from '../actions';
import { RootStore } from '@magoosh/store';

const mapStateToProps = (state: RootStore) => {
  return { ...state.studyPreferences.studyPreferencesUnsaved.days };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleDay: (day: string, prevValue: boolean) => {
      const newValue = !prevValue;
      dispatch(toggleStudyDay(day, newValue));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyDayPicker);
