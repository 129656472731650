import * as React from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import cx from 'classnames';
import style from '@magoosh/lib/formik_inputs/field_input/style.module.scss';
import { FieldRadioGroupProps } from './types';

export const FieldRadioGroup: React.FC<FieldRadioGroupProps> = (props) => {
  return <Field component={RadioGroup} {...props} />;
};

const RadioGroup: React.FC<FieldRadioGroupProps & FieldProps> = ({
  field,
  form: { setFieldValue },
  ...props
}) => {
  return (
    <>
      {props.label &&
        (typeof props.label == 'string' ? (
          <>
            <label htmlFor={field.name}>{props.label}</label>
          </>
        ) : (
          props.label
        ))}
      {props.description && <p className="u-margin-B-n">{props.description}</p>}
      <fieldset className={cx('radio', props.className)}>
        <div className="controls radio_buttons form-group">
          <div>
            {props.optionsArray.map((option, index) => (
              <div className={cx('radio', props.optionClassName)} key={index}>
                <label className={cx('choice', { 'text-gray-light': option.disabled })}>
                  <Field
                    component="input"
                    type="radio"
                    name={field.name}
                    className={option.className}
                    value={option.value}
                    checked={props.value === option.value}
                    onChange={(e) => {
                      props.onChangeCondition && props.onChangeCondition(e.currentTarget.value);
                      setFieldValue(field.name, option.value);
                    }}
                    disabled={option.disabled}
                  />
                  {option.icons && option.icons.map((icon) => <li key={icon} className={icon} />)}
                  <span className={cx('labelText', { disabled: option.disabled })}>{option.label} </span>
                  {option.labelTag && (
                    <small className="text-primary">
                      <strong>{option.labelTag}</strong>
                    </small>
                  )}

                  {option.hint && (
                    <p className={cx('hint', { disabled: option.disabled })}>
                      <small>{option.hint}</small>
                    </p>
                  )}
                </label>
                <div className="u-margin-V-n" style={{marginLeft: 20}}>{option.children}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="text-danger">
          <ErrorMessage name={field.name} />
        </div>
      </fieldset>
    </>
  );
};

export default FieldRadioGroup;
