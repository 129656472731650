import React, { useState } from 'react';
import cx from 'classnames';
import { capitalize, without } from 'lodash';
import moment from 'moment-timezone';
import { Checkbox } from 'react-bootstrap';

import CardLayout from '@magoosh/layouts/card';
import { InfoAlert } from '@magoosh/lib/alerts';
import { DefaultButton } from '@magoosh/lib/button';
import { FontAwesomeIcon, MagooshAIIcon } from '@magoosh/lib/icons';
import Tabs from '@magoosh/lib/tabs';
import RatingForm from '@magoosh/organisms/rating';
import ScoreCard from '@magoosh/organisms/score_card';
import StatusAlert from '@magoosh/organisms/grading_request_status_alert';

import { paths } from 'config/path_helpers';
import { RatingData } from 'types/rating';
import { pluralize } from 'utilities/pluralize';

import BannerAndStars from './assets/banner_and_stars.svg';
import DeskWithLamp from './assets/desk_with_lamp.svg';
import HandWriting from './assets/hand_writing.svg';
import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Response {
  content: string;
  createdAt: number;
  type: 'essay';
  wordCount?: number;
}

interface ScoreReport {
  content: {
    editedResponse: {
      content: string;
      diff: string;
      score: number;
    };
    narrative: string;
    sections: {
      bullets: string[];
      title: string;
    }[];
  };
  contentProducer: {
    name: string;
    producerType: 'ai' | 'human';
  };
  finishedAt: number;
  score: number;
  scorePercentile: number;
  scoreRange: {
    max: number;
    min: number;
  };
  status: 'waiting' | 'processing' | 'finished' | 'error';
}

interface Props {
  answer: {
    id: number;
    practiceSession: {
      id: number;
      practiceTargetId: number;
      practiceTargetType: string;
    };
    prompt: {
      content: string;
      hasSampleResponses: boolean;
      id: number;
    };
    question: {
      content: string;
      title: string;
    };
  };
  branding: {
    examName: string;
  };
  response: Response;
  rating: RatingData;
  scoreReport: ScoreReport;
}

const ScoreReport: React.FC<Props> = (props) => {
  const [showFullResponse, setShowFullResponse] = useState(!props.response.wordCount || props.response.wordCount < 100);

  let breadcrumbs;
  if (props.answer.practiceSession?.practiceTargetType === 'MockTest') {
    breadcrumbs = [
      { name: 'Home', path: paths.dashboard() },
      { name: 'Practice Tests', path: paths.practiceTests() },
      { name: 'Results', path: paths.practiceTest(props.answer.practiceSession.practiceTargetId) },
      { name: `${capitalize(props.response.type)} Score Report` }
    ];
  } else {
    breadcrumbs = [
      { name: 'Home', path: paths.dashboard() },
      { name: 'Practice Session', path: paths.answers({ 'review[practice_session_id]': props.answer.practiceSession.id }) },
      { name: 'Answer', path: paths.answer(props.answer.id, { 'review[practice_session_id]': props.answer.practiceSession.id }) },
      { name: `${capitalize(props.response.type)} Score Report` }
    ];
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  const rubricUrl = () => {
    if (props.branding.examName === 'GRE') {
      return 'https://www.ets.org/gre/test-takers/general-test/prepare/content/analytical-writing/scoring.html';
    } else {
      return null;
    }
  };

  return (
    <div>
      <CardLayout breadcrumbs={breadcrumbs}>
        {
          props.scoreReport.status !== 'finished' && (
            <div className="u-margin-B-s">
              <StatusAlert
                answerId={props.answer.id}
                contentProducer={props.scoreReport.contentProducer}
                onFinished={() => window.location.reload() }
                status={props.scoreReport.status}
              />
            </div>
          )
        }

        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <CardLayout.Card className="d-flex flex-column" style={{ gap: 20 }}>
            <div className="d-flex flex-column flex-lg-row" style={{ gap: 20 }}>
              <div>
                <h1 className="h2 u-margin-A-n u-padding-B-xxs" style={{ lineHeight: 1.3 }}>
                  <strong>{capitalize(props.response.type)} Score Report</strong>
                </h1>

                <div><small>Question <strong>{props.answer.question.title}</strong></small></div>
                <div>
                  <small>
                    Answered {
                      moment(props.response.createdAt * 1000).tz(timeZone).isSame(moment().tz(timeZone), 'day')
                        ? 'Today'
                        : moment(props.response.createdAt * 1000).tz(timeZone).fromNow()
                    }
                  </small>
                </div>
              </div>

              <div className="d-flex flex-1 flex-column align-items-lg-end" style={{ gap: 10 }}>
                <div className="d-flex flex-1 flex-column flex-lg-row" style={{ gap: 15 }}>
                  <ScoreCard
                    name="Score"
                    score={props.scoreReport?.score || '-'}
                  />

                  <ScoreCard
                    name="Percentile"
                    score={
                      props.scoreReport?.scorePercentile
                        ? `${props.scoreReport?.scorePercentile}%`
                        : '-'
                    }
                  />
                </div>

                <div className="text-gray-light" style={{ fontSize: 12 }}>
                  <FontAwesomeIcon icon="info-circle" /> Scores range from {props.scoreReport.scoreRange.min}-{props.scoreReport.scoreRange.max}.
                </div>
              </div>
            </div>

            <hr className="u-margin-V-n" />

            <div className="d-flex flex-column" style={{ gap: 10 }}>
              <div><strong>Question</strong></div>
              <div className="small"><em>{props.answer.prompt.content}</em></div>
              <div>{props.answer.question.content}</div>
            </div>
          </CardLayout.Card>

          <CardLayout.Card className="d-flex flex-column flex-lg-row" style={{ gap: 20 }}>
            <div className={cx(style.sectionInfo, 'd-flex flex-column')}>
              <div
                className="d-flex align-items-center hidden-sm hidden-xs justify-content-center"
                style={{ backgroundColor: colors.greenLight, borderRadius: 8, height: 80, width: 80 }}
              >
                <img src={HandWriting} alt="Hand holding a pen and writing" />
              </div>

              <h3 className="u-margin-V-n"><strong>Your {capitalize(props.response.type)}</strong></h3>

              {(() => {
                if (props.answer.practiceSession?.practiceTargetType === 'MockTest') {
                  return (
                    <>
                      <div className="small">
                        This essay was part of a practice test.
                      </div>
                      <div style={{ lineHeight: 1 }}>
                        <a
                          className="text-no-decoration text-secondary"
                          href={paths.practiceTest(props.answer.practiceSession.practiceTargetId)}
                          style={{ fontSize: 12 }}
                        >
                          <strong>See full practice test results</strong>
                        </a>
                      </div>
                    </>
                  )
                } else if (props.answer.practiceSession) {
                  return (
                    <>
                      <div className="small">
                        This essay was part of a practice session.
                      </div>
                      <div style={{ lineHeight: 1 }}>
                        <a
                          className="text-no-decoration text-secondary"
                          href={paths.answers({ 'review[practice_session_id]': props.answer.practiceSession.id })}
                          style={{ fontSize: 12 }}
                        >
                          <strong>Review all answers in the session</strong>
                        </a>
                      </div>
                    </>
                  )
                } else if (props.response.wordCount) {
                  return (
                    <div className="small">
                      You wrote {pluralize(props.response.wordCount, 'word')}.
                    </div>
                  )
                }
              })()}
            </div>

            <hr className="u-margin-V-n visible-sm visible-xs" />

            <div className="d-flex flex-column" style={{ gap: 20 }}>
              <div>
                <span className="small text-gray-light text-underdash">
                  You on {moment.unix(props.response.createdAt).format('MMMM D, YYYY')}
                </span>
              </div>

              <div>
                {
                  props.response.wordCount || props.response.wordCount >= 100 ? (
                    <div style={{ position: 'relative' }}>
                      <div
                        className={showFullResponse ? null : style.truncatedResponse}
                        style={{
                          maxHeight: showFullResponse ? 2000 : 150,
                          overflow: 'hidden',
                          transition: 'max-height 0.25s ease-in-out'
                        }}
                      >
                        {renderTextAsParagraphs(props.response.content)}
                      </div>
                    </div>
                  ) : (
                    <div>{renderTextAsParagraphs(props.response.content)}</div>
                  )
                }
              </div>

              {
                !showFullResponse && (
                  <div>
                    <DefaultButton onClick={() => setShowFullResponse(true)}>
                      Show Full {capitalize(props.response.type)}
                    </DefaultButton>
                  </div>
                )
              }
            </div>
          </CardLayout.Card>

          <CardLayout.Card className="d-flex flex-column flex-lg-row" style={{ gap: 20 }}>
            <div className={cx(style.sectionInfo, 'd-flex flex-column')}>
              <div
                className="d-flex align-items-center hidden-sm hidden-xs justify-content-center"
                style={{ backgroundColor: colors.greenLight, borderRadius: 8, height: 80, width: 80 }}
              >
                <img src={BannerAndStars} alt="Celebratory banner and stars" />
              </div>

              <h3 className="u-margin-V-n"><strong>Feedback</strong></h3>

              <div className="small">
                This evaluation is based on the {props.branding.examName} scoring rubric.
              </div>

              {
                rubricUrl() && (
                  <div style={{ lineHeight: 1 }}>
                    <a
                      className="text-no-decoration text-secondary"
                      href={rubricUrl()}
                      style={{ fontSize: 12 }}
                      target="_blank"
                    >
                      <strong>See the scoring rubric</strong>
                    </a>
                  </div>
                )
              }
            </div>

            <hr className="u-margin-V-n visible-sm visible-xs" />

            <div className="d-flex flex-column flex-1" style={{ gap: 20 }}>
              <FeedbackContent rating={props.rating} scoreReport={props.scoreReport} />
            </div>
          </CardLayout.Card>

          <CardLayout.Card className="d-flex flex-column flex-lg-row" style={{ gap: 20 }}>
            <div className={cx(style.sectionInfo, 'd-flex flex-column')}>
              <div
                className="d-flex align-items-center hidden-sm hidden-xs justify-content-center"
                style={{ backgroundColor: colors.purpleLight, borderRadius: 8, height: 80, width: 80 }}
              >
                <img src={DeskWithLamp} alt="Desk with a lamp shining light" />
              </div>

              <h3 className="u-margin-V-n"><strong>Suggested Edits</strong></h3>

              <div className="small">
                <FontAwesomeIcon icon="flask" /> Suggested edits is an experimental feature that we think is valuable for learning. However you may see some inconsistencies in the edited essay content.
              </div>

              {
                props.answer.prompt.hasSampleResponses && (
                  <div style={{ lineHeight: 1 }}>
                    <a
                      className="text-no-decoration text-secondary"
                      href={paths.sampleResponses(props.answer.prompt.id)}
                      style={{ fontSize: 12 }}
                    >
                      <strong>See other sample responses</strong>
                    </a>
                  </div>
                )
              }
            </div>

            <div className="d-flex flex-column flex-1" style={{ gap: 20 }}>
              <EditedResponseContent
                response={props.response}
                scoreReport={props.scoreReport}
              />
            </div>
          </CardLayout.Card>
        </div>
      </CardLayout>
    </div>
  );
};

const EditedResponseContent: React.FC<{ response: Response; scoreReport: ScoreReport; }> = (props) => {
  if (props.scoreReport.content?.editedResponse) {
    const [showEditedEssayEdits, setShowEditedEssayEdits] = useState([]);

    const editedEssayDiff = () => {
      let diff = props.scoreReport.content.editedResponse.diff;

      showEditedEssayEdits.forEach((editType) => {
        console.log(diff)
        const regex = new RegExp(`class=\"${editType}\"`, 'g');
        console.log(regex)
        diff = diff.replace(regex, `class="${editType} active"`);
      });

      return diff;
    };

    const toggleShowEditedEssayEdits = (editType) => {
      if (showEditedEssayEdits.indexOf(editType) !== -1) {
        setShowEditedEssayEdits(without(showEditedEssayEdits, editType));
      } else {
        setShowEditedEssayEdits([...showEditedEssayEdits, editType]);
      }
    };

    return (
      <Tabs defaultTab="editedEssay">
        <Tabs.Tab tab="editedEssay">Edited Essay (Score {props.scoreReport.content.editedResponse.score})</Tabs.Tab>
        <Tabs.Tab tab="originalEssay">Original Essay (Score {props.scoreReport.score})</Tabs.Tab>

        <Tabs.Panel tab="editedEssay">
          <div className="d-flex flex-column" style={{ gap: 20 }}>
            <InfoAlert>
              We've edited your essay to show what changes might get you to a score of {props.scoreReport.content.editedResponse.score}.
            </InfoAlert>

            <div className="bg-gray-lightest border-radius-all u-padding-A-s">
              <strong>Show Edits Related To:</strong>

              <div className={cx(style.checkboxList, 'd-flex align-items-start align-items-lg-center flex-column flex-lg-row u-margin-T-xxs')}>
                <div>
                  <Checkbox
                    checked={showEditedEssayEdits.indexOf('spelling') !== -1}
                    className="u-margin-A-n"
                    onChange={() => toggleShowEditedEssayEdits('spelling')}
                  >
                    <div className="u-margin-L-xxs">Spelling</div>
                  </Checkbox>
                </div>

                <div>
                  <Checkbox
                    checked={showEditedEssayEdits.indexOf('grammar-word-choice') !== -1}
                    className="u-margin-A-n"
                    onChange={() => toggleShowEditedEssayEdits('grammar-word-choice')}
                  >
                    <div className="u-margin-L-xxs">Grammar and Word Choice</div>
                  </Checkbox>
                </div>

                <div>
                  <Checkbox
                    checked={showEditedEssayEdits.indexOf('flow') !== -1}
                    className="u-margin-A-n"
                    onChange={() => toggleShowEditedEssayEdits('flow')}
                  >
                    <div className="u-margin-L-xxs">Essay Flow</div>
                  </Checkbox>
                </div>

                <div>
                  <Checkbox
                    checked={showEditedEssayEdits.indexOf('reasoning-examples') !== -1}
                    className="u-margin-A-n"
                    onChange={() => toggleShowEditedEssayEdits('reasoning-examples')}
                  >
                    <div className="u-margin-L-xxs">Reasoning and Examples</div>
                  </Checkbox>
                </div>
              </div>
            </div>

            <div
              className={style.editedEssay}
              dangerouslySetInnerHTML={{ __html: editedEssayDiff() }}
            />
          </div>
        </Tabs.Panel>

        <Tabs.Panel tab="originalEssay">
          {renderTextAsParagraphs(props.response.content)}
        </Tabs.Panel>
      </Tabs>
    );
  } else if (props.scoreReport.status === 'finished' && props.scoreReport.score >= 5) {
    return <NoContent message="You already have a great score and we weren't able to generate an edited essay this time." />;
  } else if (props.scoreReport.status === 'finished') {
    return <NoContent message="We weren't able to generate an edited essay this time." />;
  } else {
    return <NoContent />;
  }
};

const FeedbackContent: React.FC<{ rating: RatingData; scoreReport: ScoreReport; }> = (props) => {
  if (props.scoreReport.status === 'finished') {
    return (
      <>
        <div className="d-flex align-items-center">
          {
            props.scoreReport.contentProducer.producerType === 'ai' && (
              <div className="u-margin-R-s"><MagooshAIIcon /></div>
            )
          }

          <div className="small text-gray-light text-underdash">
            {
              props.scoreReport.contentProducer.producerType === 'ai'
                ? 'Magoosh AI Tutor'
                : props.scoreReport.contentProducer.name
            }
            {' '} on {moment.unix(props.scoreReport.finishedAt).format('MMMM D, YYYY')}
          </div>
        </div>

        <div className="d-flex flex-column flex-1" style={{ gap: 20 }}>
          <div>
            {renderTextAsParagraphs(props.scoreReport.content.narrative)}
          </div>

          {
            props.scoreReport.content.sections.map((section, idx) => (
              <div key={idx} className="d-flex flex-column" style={{ gap: 10 }}>
                <div>
                  <strong>{section.title}</strong>
                </div>

                <div>
                  <ul>
                    {
                      section.bullets.map((bullet, bulletIdx) => (
                        <li key={bulletIdx}>{bullet}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            ))
          }

          <hr className="u-margin-V-n" />

          <div>
            <h4 className="u-margin-T-n"><strong>Tell Us How We Did</strong></h4>
            <RatingForm
              rating={props.rating}
              rateableName="score report and feedback"
            />
          </div>
        </div>
      </>
    );
  } else {
    return <NoContent />;
  }
};

const NoContent: React.FC<{ message?: string }> = (props) => {
  return (
    <div
      className="bg-gray-lightest border-radius-all d-flex align-items-center justify-content-center u-padding-A-m"
      style={{ height: '100%', width: '100%' }}
    >
      {props.message || 'Nothing to display yet.'}
    </div>
  );
};

const renderTextAsParagraphs = (text: string) => {
  return text.replace(/\r\n/g, '\n').split(/\n+/).map((paragraph, idx) => (
    <p key={idx}>{paragraph}</p>
  ));
};

export default ScoreReport;
