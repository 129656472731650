import React from 'react';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';

import CardLayout from '@magoosh/layouts/card';
import { DefaultButton } from '@magoosh/lib/button';
import { SuccessBadge, WarningBadge } from '@magoosh/lib/badge';
import { CircleIcon, FontAwesomeIcon, SquareIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';

import { useAppContext } from '@test_prep/app/context';
import { useQueries } from '@test_prep/app/queries';
import ContextualLink from '@test_prep/app/components/contextual_link';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface PageProps {
  classSessions: any[];
}

const OnDemandClassesIndex: React.FC<PageProps> = (props) => {
  const { branding } = useAppContext().data;
  const q = useQueries();

  const page = q.pageQuery(paths.onDemandClasses()).data as PageProps;

  const breadcrumbs = [
    { name: 'Home', path: paths.dashboard() },
    { name: 'On-Demand Classes' }
  ];

  return (
    <>
      <Helmet>
        <title>Classes - Magoosh {branding.examName}</title>
      </Helmet>

      <CardLayout breadcrumbs={breadcrumbs} linkComponent={ContextualLink} loading={!page}>
        {({ loading }) => {
          if (loading) return;

          return (
            <CardLayout.Card className="d-flex flex-column gap-m">
              <div className="d-flex gap-m">
                <div className="hidden-xs">
                  <SquareIcon
                    colorPalette={{ dark: colors.purple, light: colors.purpleLight }}
                    iconContents="calendar-check-o"
                    iconType="fa"
                    size={80}
                  />
                </div>

                <div>
                  <h2 className="u-margin-A-n u-padding-B-xxs">
                    <strong>On-Demand Classes</strong>
                  </h2>

                  <div>Watch or revisit your classes and associated resources anytime.</div>
                </div>
              </div>

              <div className="d-flex flex-column">
                {
                  page.classSessions.map((classSession, idx) => (
                    <div key={classSession.id} className="d-flex gap-l">
                      <div className="d-flex hidden-sm hidden-xs">
                        <div
                          style={{
                            borderRight: `1px solid ${colors.gray85}`,
                            minWidth: 30,
                            width: 30
                          }}
                        />

                        <div
                          style={{
                            borderBottom: `1px solid ${colors.gray85}`,
                            height: 36,
                            marginRight: 15,
                            width: 20
                          }}
                        />
                      </div>

                      <div className="u-margin-T-m">
                        <div className="align-items-lg-center d-flex flex-column flex-lg-row gap-m gap-lg-l">
                          <div className="d-flex flex-column gap-s">
                            <div>
                              <h4 className="u-margin-A-n"><strong>{classSession.name}</strong></h4>
                            </div>

                            <div>{classSession.description}</div>

                            {
                              classSession.resourceLinks.length > 0 && (
                                <div>
                                  <strong>
                                    Resources:{' '}

                                    {
                                      classSession.resourceLinks.map((link, idx) => (
                                        <React.Fragment key={idx}>
                                          <a className="text-body" href={link.url} target="_blank">
                                            {link.name}
                                          </a>

                                          {idx < classSession.resourceLinks.length - 1 ? ', ' : ''}
                                        </React.Fragment>
                                      ))
                                    }
                                  </strong>
                                </div>
                              )
                            }

                            {
                              classSession.assignments.map((assignment) => (
                                <div key={assignment.id} className="d-flex gap-xs">
                                  <div>
                                    {
                                      assignment.submitted ? (
                                        <SuccessBadge>Completed</SuccessBadge>
                                      ) : (
                                        <WarningBadge>To Do</WarningBadge>
                                      )
                                    }
                                  </div>

                                  <div>
                                    <ContextualLink href={paths.contentBlockAssignment(assignment)}>
                                      {assignment.title}
                                    </ContextualLink>
                                  </div>
                                </div>
                              ))
                            }
                          </div>

                          <div>
                            {
                              classSession.recordingUrl ? (
                                <DefaultButton href={classSession.recordingUrl} target="_blank">
                                  Watch Class
                                </DefaultButton>
                              ) : (
                                <DefaultButton disabled>
                                  Recording Unavailable
                                </DefaultButton>
                              )
                            }
                          </div>
                        </div>

                        {idx < page.classSessions.length - 1 && <hr className="u-margin-A-n u-margin-T-m" />}
                      </div>
                    </div>
                  ))
                }
              </div>
            </CardLayout.Card>
          )
        }}
      </CardLayout>
    </>
  );
};

export default OnDemandClassesIndex;
