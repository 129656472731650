import React from 'react';
import { differenceWith, isEqual } from 'lodash';

import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';

import { useQueries } from '@schools/app/queries';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  categorySlug: string;
  measurementId?: number;
  metricSlug: string;
}

const StatRankingsTable: React.FC<Props> = (props) => {
  const q = useQueries();

  const schoolProfileCategoryQuery = q.schoolProfileCategoryQuery(props.categorySlug, false);
  const category = schoolProfileCategoryQuery.data;

  const schoolProfileMetricQuery = q.schoolProfileMetricQuery(props.metricSlug, props.categorySlug);
  const metric = schoolProfileMetricQuery.data;

  const measurements = () => {
    const allEntities = category.profiles.map((profile) => profile.entity);
    const entitiesWithMeasurement = metric.measurements.map((measurement) => measurement.entity);

    const entitiesWithoutMeasurement = differenceWith(allEntities, entitiesWithMeasurement, isEqual);
    const measurementsForEntitiesWithoutData = entitiesWithoutMeasurement.map((entity) => (
      {
        entity,
        ranking: '-',
        value: 'No Data'
      }
    ));

    return [
      ...metric.measurements,
      ...measurementsForEntitiesWithoutData
    ];
  };

  if (category && metric) {
    return (
      <div>
        <table className="table">
          <caption>{metric.name} - {category.name}</caption>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Program</th>
              <th>{metric.name}</th>
            </tr>
          </thead>

          <tbody>
            {
              measurements().map((measurement, idx) => (
                <tr key={idx} style={{ backgroundColor: measurement.id && (measurement.id === props.measurementId) ? colors.purpleLight : null }}>
                  <td>{measurement.ranking}</td>
                  <td>
                    {measurement.entity.name}
                    {
                      measurement.id !== props.measurementId && (
                        <a
                          className="small u-margin-L-xxs"
                          href={paths.admissions.schoolProfile(measurement.profileSlug, category.slug)}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon="external-link" />
                        </a>
                      )
                    }
                  </td>
                  <td>{measurement.value}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div>
        <table className="table">
          <caption></caption>
          <thead>
            <tr>
              <th>Rank</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {
              new Array(category ? category.profiles.length : 10).fill(null).map((_, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td></td>
                  <td></td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
};

export default StatRankingsTable;
