import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { paths } from 'config/path_helpers';

interface Props {
  examName: string;
}

export default ({ examName }: Props) => (
  <>
    <h4 className="u-margin-V-n">
      <strong>Features</strong>
    </h4>

    <hr className="u-margin-V-xs"/>

    <ul className="list-unstyled u-margin-B-n">
      <li className="u-margin-B-xxs">
        <a href={paths.lessons()}>
          Lessons
        </a>
      </li>

      <li className="u-margin-B-xxs">
        <a href={paths.startPractice()}>
          Customized Practice
        </a>
      </li>

      <li className="u-margin-B-xxs">
        <a href={paths.answers()}>
          Review
        </a>
      </li>

      <li className="u-margin-B-xxs">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={
            <Popover title="Practice Test" id="practice-test">
              <strong>This is a premium feature.</strong>
              <br/>
              Our timed and scored full-length practice tests simulate the experience of taking
              the {examName} on test day.
            </Popover>
          }>
          <div className="d-inline">
            <a href={paths.plans()} target="_blank">
              Practice Test
            </a>
            &nbsp;
            <span className="fa fa-lock gold"/>
          </div>
        </OverlayTrigger>
      </li>

      <li className="u-margin-B-xxs">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={
            <Popover title="Essay Grading" id="essay-grading">
              <strong>This is a premium feature.</strong>
              <br/>
              Submit an essay for grading with our premium 6-month plan.
              Receive a score report from an IELTS expert.
            </Popover>
          }>
          <div className="d-inline">
            <a href="https://ielts.magoosh.com/submit-essays-for-scoring" target="_blank">
              Essay Grading
            </a>
            &nbsp;
            <span className="fa fa-lock gold"/>
          </div>
        </OverlayTrigger>
      </li>

      <li>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={
            <Popover title="Ask an IELTS expert" id="ask-an-ielts-expert">
              <strong>This is a premium feature.</strong>
              <br/>
              Our responsive tutors answer Premium students' questions via email within a day.
            </Popover>
          }>
          <div className="d-inline">
            <a href={paths.plans()} target="_blank">
              Ask an IELTS expert
            </a>
            &nbsp;
            <span className="fa fa-lock gold"/>
          </div>
        </OverlayTrigger>
      </li>
    </ul>
  </>
);
