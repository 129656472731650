/*
  A compound component for rendering a data table.

  This is designed to be used with the useDataQuery hook, which can provide most of the props to
  this component. Aside from that, props for primary and secondary attributes of the items in the
  list, other standard columns, as well as child components, can be used to control the look and
  functionality of the data table.

  The available child components are:
  - Filters:
      Components for filtering the data, which can update and rerun the query from useDataQuery.
      These get rendered above the table (and optional stats block), below the contextual indicator
      bar when rows are selected.

  - HeaderActions:
      General header actions (e.g. a "Create" button). Rendered on the same line as the Filters, but
      right aligned.

  - StatsBlock
      An optional component rendered under the filters and header actions, intended for showing
      aggregate information, and can be based on the query from useDataQuery.

  - ItemIcon
      The icon rendered in the primary (first) cell of each row.

  - PrimaryAttributeLabel
      A component for rendering the primary label of each item. If not provided, the selector value
      of primaryAttribute prop is used instead. This should be defined as function that takes a
      row's data item, and returns the label for that particular item.

  - ItemActions
      Components for handling the actions that can be performed on each row, rendered in the last
      cell of each row. This should be defined as function that takes a row's data item, and returns
      the actions available for that particular item.

  - BulkActions
      Components for handling actions that can be performed on multiple rows, when rows are
      selected. These are rendered in the contextual indicator bar when rows are selected, aligned
      to the right.

*/

import React, { PropsWithChildren, useMemo } from 'react';
import ReactDataTableComponent, {
  IDataTableColumn,
  IDataTableConditionalRowStyles
} from 'react-data-table-component';
import style from './style.module.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import cx from 'classnames';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import NamePlate from '@magoosh/b2b/app/lib/name_plate';
import { Link } from 'react-router-dom';

interface AttributeDescriptor {
  selector?: string;
  name: string;
  format?: (row, index) => any;
  sortable?: boolean;
  cell?: (row, index, column, id) => any;
  style?: any;
}

type DataTableProps = PropsWithChildren<{
  data: any[];
  totalRows: number;
  fetchPending: boolean;
  selectedRows: any[];
  clearSelectedRows: boolean;
  handleSort: (column, direction) => void;
  handleChangePage: (page) => void;
  handleChangeRowsPerPage: (newPerPage, page) => void;
  perPage: number;
  setSelectedRows: (any) => void;
  selectableRows?: boolean;
  primaryAttribute?: AttributeDescriptor;
  secondaryAttributeSelector?: string;
  linkBaseUrl?: string;
  columns: IDataTableColumn[];
  conditionalRowStyles?: IDataTableConditionalRowStyles[];
  wrapColumnHeaders?: boolean;
  refresh?: () => any;
}>;

const Filters = (props) => <></>;
const HeaderActions = (props) => <></>;
const StatsBlock = (props) => <></>;
const ItemIcon = (props) => <></>;

// An optional component for rendering the label of each item. If not provided, the selector value
// of primaryAttribute prop is used instead.
const PrimaryAttributeLabel = (props) => <></>;

// Components for handling the actions that can be performed on each row, rendered in the last cell.
const ItemActions = (props) => <></>;

// Components for handling actions that can be performed on multiple rows, when rows are selected.
// These are rendered in the contextual indicator bar when rows are selected, aligned to the right.
const BulkActions = (props) => <></>;

export const DataTable = (props: DataTableProps) => {
  const customStyles = useMemo(() => {
    return {
      table: {
        style: {
          maxWidth: 'unset',
          minWidth: '100%',
          width: 'fit-content'
        }
      },
      header: {
        style: {
          flexGrow: '0'
        }
      },
      headRow: {
        style: {
          minWidth: '100%'
        }
      },
      headCells: {
        style: {
          fontSize: '12px'
        }
      },
      rows: {
        style: {
          minHeight: 'auto',
          paddingTop: '1rem',
          paddingBottom: '1rem'
        }
      },
      progress: {
        style: {
          maxWidth: '1070px'
        }
      }
    };
  }, []);

  const compoundComponents = {
    filters: null,
    headerActions: null,
    statsBlock: null,
    itemIcon: null,
    primaryAttributeLabel: null,
    itemActions: null,
    bulkActions: null
  };

  React.Children.forEach(props.children, (child) => {
    if (React.isValidElement(child)) {
      switch (child.type) {
        case Filters:
          compoundComponents.filters = child.props.children;
          break;
        case HeaderActions:
          compoundComponents.headerActions = child.props.children;
          break;
        case StatsBlock:
          compoundComponents.statsBlock = child.props.children;
          break;
        case ItemIcon:
          compoundComponents.itemIcon = child.props.children;
          break;
        case PrimaryAttributeLabel:
          compoundComponents.primaryAttributeLabel = child.props.children;
          break;
        case ItemActions:
          compoundComponents.itemActions = child.props.children;
          break;
      }
    }
  });

  const {
    filters,
    headerActions,
    statsBlock,
    itemIcon,
    primaryAttributeLabel,
    itemActions
  } = compoundComponents;

  const subHeader = (
    <>
      <div className={style.filters}>
        {filters}
        <div className={style.subHeaderActions}>{headerActions}</div>
      </div>
      {statsBlock && <div className={style.statsBlock}>{statsBlock}</div>}
    </>
  );

  const bulkActions = useMemo(() => {
    const bulkActionsChild = React.Children.toArray(props.children).find((child) => {
      return React.isValidElement(child) && child.type == BulkActions;
    });

    if (bulkActionsChild) {
      return (bulkActionsChild as React.ReactElement).props.children;
    }
  }, [props.selectedRows]);

  const columns = useMemo(() => buildColumns(props, itemIcon, primaryAttributeLabel), [props.columns]);
  itemActions &&
    columns.push({
      name: <div className={style.columnHeaderCell}>Actions</div>,
      cell: itemActions,
      center: true
    });

  return (
    <div className={style.dataTable}>
      <ReactDataTableComponent
        columns={columns}
        data={props.data || []}
        progressPending={props.fetchPending}
        customStyles={customStyles}
        selectableRows={props.selectableRows}
        conditionalRowStyles={props.conditionalRowStyles}
        persistTableHead
        subHeader
        subHeaderAlign="left"
        subHeaderComponent={subHeader}
        sortServer
        sortIcon={<FontAwesomeIcon icon={'chevron-down'} className={style.sortIcon} />}
        defaultSortField={props.primaryAttribute && props.primaryAttribute.selector}
        onSort={props.handleSort}
        pagination
        paginationServer
        paginationTotalRows={props.totalRows}
        paginationPerPage={props.perPage}
        paginationRowsPerPageOptions={[25, 50, 100]}
        onChangePage={props.handleChangePage}
        onChangeRowsPerPage={props.handleChangeRowsPerPage}
        onSelectedRowsChange={({ selectedRows }) => {
          props.setSelectedRows(selectedRows);
        }}
        contextActions={bulkActions}
        clearSelectedRows={props.clearSelectedRows}
        fixedHeader={true}
        fixedHeaderScrollHeight={'100vh'}
      />
      {props.refresh && !props.fetchPending && <small className={style.refresh}>
        Data can be cached for up to 30 minutes. Click <a onClick={props.refresh}>refresh</a> to
        generate the latest data.
      </small>}
    </div>
  );
};

function buildColumns(props: DataTableProps, itemIcon, primaryAttributeLabel) {
  const { primaryAttribute, secondaryAttributeSelector, linkBaseUrl, columns, wrapColumnHeaders = false } = props;
  const initialColumns = [];

  (primaryAttributeLabel || primaryAttribute) &&
    initialColumns.push({
      name: <div className={style.columnHeaderCell}>{primaryAttribute.name}</div>,
      selector: primaryAttribute.selector,
      center: false,
      sortable: true,
      grow: 2,
      cell: (row) => {
        const namePlate = <NamePlate
          icon={itemIcon(row)}
          headline={primaryAttributeLabel && primaryAttributeLabel(row) || row[primaryAttribute.selector]}
          subheadline={row[secondaryAttributeSelector]}
        />;

        if (linkBaseUrl) {
          return <Link to={`${linkBaseUrl}${row.id}`} className={style.primaryLink}>
            {namePlate}
          </Link>;
        }

        return namePlate;
      }
    });

  columns.forEach((column) => {
    const classes = [style.columnHeaderCell];
    if (column.sortable) classes.push(style.sortableColumnHeaderCell);
    if (wrapColumnHeaders) classes.push(style.wrappedColumnHeaderCell);

    initialColumns.push({
      center: true,
      ...column,
      name: <div className={cx(classes)}>{column.name}</div>
    });
  });

  return initialColumns;
}

DataTable.Filters = Filters;
DataTable.HeaderActions = HeaderActions;
DataTable.StatsBlock = StatsBlock;
DataTable.ItemIcon = ItemIcon;
DataTable.PrimaryAttributeLabel = PrimaryAttributeLabel;
DataTable.ItemActions = ItemActions;
DataTable.BulkActions = BulkActions;

export const inactiveRowStyles = { backgroundColor: '#f3872210' };
