import { connect } from 'react-redux';
import Template from './template';
import { setItemCompleted } from '../actions';
import { RootStore } from '@magoosh/store';

function mapStateToProps(state: RootStore, ownProps) {
  const module = state.lessonModuleData.studyModuleTracks[0].modules[0];
  return { studyItems: module.studyItems, compact: true, name: module.name, id: module.id, ...ownProps };
}

const mapDispatchToProps = {
  setItemCompleted
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
