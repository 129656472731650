import React from 'react';
import { find } from 'lodash';

import CardLayout from '@magoosh/layouts/card';
import { InfoAlert } from '@magoosh/lib/alerts';
import LessonEditorial from '@magoosh/organisms/lesson_summary_section';
import NoteForm from '@magoosh/organisms/note_form';
import Rating from '@magoosh/organisms/rating';

import SimpleStudySchedule, { SimpleStudyScheduleProps } from '@magoosh/organisms/study_schedule/simple_study_schedule';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import usePageContext from '@magoosh/utils/use_page_context';
import useSearchQuery from '@magoosh/utils/use_search_query';
import { paths } from 'config/path_helpers';

import GuidedStudyNav, { IntraModuleNavProps } from '@magoosh/organisms/intra_module_nav';

import ActivityCard from './activities/card';
import VideoActivity from './activities/video';
import AuthorBio from './author_bio'
import MarkingButton from './marking_button';
import { Lesson } from './types';

import style from './style.module.scss';

interface Props {
  guidedStudyActivity: IntraModuleNavProps;
  lesson: Lesson;
  sidebarHtml: string;
  studySchedule: SimpleStudyScheduleProps;
}

const ShowLesson: React.FC<Props> = (props) => {
  const currentUser = usePageContext().currentUser;
  const studyItemId = useSearchQuery().study_item ? parseInt(useSearchQuery().study_item as string) : null;

  const nextActivity = () => {
    return find(props.lesson.activities, (activity) => activity.status !== 'finished');
  };

  const renderHeader = () => {
    let studyActivity = props.guidedStudyActivity;

    if (!studyActivity) {
      studyActivity = {
        allowNavigation: false,
        authenticityToken: useAuthenticityToken(),
        currentStudyItem: {
          name: props.lesson.title
        },
        guidedActivity: {
          id: null,
          name: props.lesson.lessonGroup?.title || 'Lessons',
          type: null,
          url: paths.lessons()
        },
        nextStudyItem: null,
        remainingItemCount: 0,
        totalItemCount: 0
      };
    }

    return (
      <div className="u-margin-V-s">
        <GuidedStudyNav {...studyActivity} />
      </div>
    );
  }

  const renderNextThingsToStudy = () => {
    if (props.studySchedule) {
      return (
        <div className="d-flex flex-column gap-s">
          <h5 className="u-margin-T-n"><strong>Next On Your Study Plan</strong></h5>
          <SimpleStudySchedule
            {...props.studySchedule}
            excludeItem={studyItemId}
            incompleteItemsOnly
            itemLimit={1}
          />

          <a className="text-gray-light" href={paths.dashboard()}>
            <small>See all study plan tasks</small>
          </a>
        </div>
      );
    } else {
      return (
        <div dangerouslySetInnerHTML={{ __html: props.sidebarHtml }} />
      );
    }
  };

  return (
    <CardLayout resizeableContainer>
      {renderHeader()}

      <div className="d-flex gap-m flex-column flex-lg-row">
        <div className="d-flex flex-column flex-1 gap-m">
          <CardLayout.Card className={style.videoActivityCard}>
            <VideoActivity
              lesson={props.lesson}
              nextActivity={nextActivity()}
            />
          </CardLayout.Card>

          {
            props.lesson.alertNote && (
              <InfoAlert>{props.lesson.alertNote}</InfoAlert>
            )
          }

          {/* Mobile author, rating, and marking button */}
          <div className="visible-sm visible-xs">
            <CardLayout.Card>
              <AuthorBio {...props.lesson.author} />

              {
                currentUser && !props.guidedStudyActivity && (
                  <>
                    <hr className="u-margin-V-s" />

                    <div className="text-center">
                      <MarkingButton isCompleted={props.lesson.isCompleted} lessonId={props.lesson.id} />
                    </div>
                  </>
                )
              }
            </CardLayout.Card>
          </div>

          {/* Mobile concept check and study schedule/related lessons sidebar */}
          <div className="visible-sm visible-xs">
            <CardLayout.Card>
              {
                props.lesson.activities.length > 0 && (
                  <>
                    <div className="d-flex flex-column gap-s">
                      <h5 className="u-margin-T-n"><strong>Practice</strong></h5>
                      {
                        props.lesson.activities.map((activity, idx) => (
                          <ActivityCard
                            activity={activity}
                            key={idx}
                            next={activity === nextActivity()}
                          />
                        ))
                      }
                    </div>
                    <hr className="u-margin-V-s" />
                  </>
                )
              }

              {renderNextThingsToStudy()}
            </CardLayout.Card>
          </div>

          <CardLayout.Card>
            <div className="hidden-sm hidden-xs">
              <div className="pull-left">
                <AuthorBio {...props.lesson.author} />
              </div>

              <Rating binaryFeedbackClassName="justify-content-end" rating={props.lesson.rating} />

              <hr className="u-margin-B-xxs u-margin-T-s" />
            </div>

            <LessonEditorial {...props.lesson.editorial} />
          </CardLayout.Card>
        </div>

        <div className="d-flex flex-column hidden-sm hidden-xs gap-m" style={{ width: 375 }}>
          {
            currentUser && (
              <>
                <div>
                  <h5 className="u-margin-T-n"><strong>Notes</strong></h5>
                  <NoteForm
                    notableId={props.lesson.id}
                    notableType="lesson"
                    text={props.lesson.userNote}
                  />
                </div>

                <hr className="u-margin-A-n" />
              </>
            )
          }

          <div className="d-flex flex-column gap-l">
            {
              currentUser && !props.guidedStudyActivity && (
                <div className="d-flex flex-column gap-s">
                  <h5 className="u-margin-T-n"><strong>Status</strong></h5>
                  <MarkingButton isCompleted={props.lesson.isCompleted} lessonId={props.lesson.id} />
                </div>
              )
            }

            {
              props.lesson.activities.length > 0 && (
                <div className="d-flex flex-column gap-s">
                  <h5 className="u-margin-T-n"><strong>Practice</strong></h5>
                  {
                    props.lesson.activities.map((activity, idx) => (
                      <ActivityCard
                        activity={activity}
                        key={idx}
                        next={activity === nextActivity()}
                      />
                    ))
                  }
                </div>
              )
            }

            {renderNextThingsToStudy()}
          </div>
        </div>
      </div>
    </CardLayout>
  );
};

export default ShowLesson;
