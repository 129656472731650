import * as React from 'react';
import { Fragment } from 'react';
import ClassActivity from './class_activity';
import { ClassActivityData } from './class_activity/template';
import StudentPerformance from './student_performance';
import { ClassSection } from './types';
import { Branding } from 'types/branding';
import { pluralize } from 'utilities/pluralize';
import style from './style.module.scss';

interface SchoolReportProps {
  brandings: Branding[];
  classActivity: ClassActivityData[];
  classSectionsByGrade: StringHash<ClassSection[]>;
  dateRange?;
}

export default class SchoolReport extends React.Component<SchoolReportProps> {
  render() {
    return (
      <Fragment>
        <StudentPerformance {...this.props} />
        <ClassActivity data={this.props.classActivity} />
        <div className={style.sectionHeader}>Accounts Summary</div>
        {this.props.brandings.map((branding) => {
          return (
            <div key={branding.id}>
              You have {pluralize(branding.accountsAvailable, `${branding.name} account`)} available.
            </div>
          );
        })}
      </Fragment>
    );
  }
}
