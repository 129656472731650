import React, { useLayoutEffect, useState } from 'react';
import { differenceWith, find, isEqual } from 'lodash';
import { Waypoint } from 'react-waypoint';

import { Interaction, TutoringSession } from '@test_prep/app/ai_tutor/types';

import InteractionCard from '../interaction';

interface Props {
  activity: Interaction;
  addOrUpdateUserInteractions: (interactions: Interaction[]) => void;
  setCurrentActivity: (interaction: Interaction) => void;
  submitUserInput: (text: string) => void;
  tutoringSession: TutoringSession;
}

const InteractionsList: React.FC<Props> = (props) => {
  const [previousInteractions, setPreviousInteractions] = useState<Interaction[]>(props.tutoringSession.interactions);
  const [focusedInteraction, setFocusedInteraction] = useState<Interaction>(null);
  const [viewedInteractionIds, setViewedInteractionIds] = useState<number[]>([]);

  // Whenever there are new interactions scroll to make them visible
  useLayoutEffect(() => {
    const newInteractions = differenceWith(
      props.tutoringSession.interactions,
      previousInteractions,
      isEqual
    );

    setPreviousInteractions(props.tutoringSession.interactions);
    if (!newInteractions.length) return;

    const newInteraction = newInteractions[0];
    if (viewedInteractionIds.indexOf(newInteraction.id) !== -1) return;

    // If no focused interaction, we get more updated content for the focused interaction, or
    // the previous focused interaction is not longer in the tutoring session (e.g. it was a
    // provisionl one with a uuid), attempt to scroll the new content into view
    if (
      !focusedInteraction
      || focusedInteraction.id === newInteraction.id
      || !find(props.tutoringSession.interactions, (interaction) => interaction.id === focusedInteraction.id)
      || newInteraction.role === 'user'
      || focusedInteraction.type !== 'chat'
    ) {
      const newInteractionElement = document.getElementById(newInteraction.id);
      newInteractionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setFocusedInteraction(newInteraction);
    }
  }, [props.tutoringSession.interactions]);

  const markInteractionAsViewed = (interaction) => {
    setViewedInteractionIds([...viewedInteractionIds, interaction.id]);
  };

  return (
    <div className="d-flex flex-1 flex-column gap-m">
      {
        props.tutoringSession.interactions.map((interaction) => (
          <div key={interaction.id} id={interaction.id.toString()}>
            <Waypoint
              onLeave={() => markInteractionAsViewed(interaction)}
            />

            <InteractionCard
              interaction={{ ...interaction, isCurrentActivity: interaction.id === props.activity?.id }}
              onOptionSelected={props.submitUserInput}
              addOrUpdateUserInteractions={props.addOrUpdateUserInteractions}
              setCurrentActivity={props.setCurrentActivity}
            />

            <Waypoint
              onEnter={() => setFocusedInteraction(null)}
            />
          </div>
        ))
      }
    </div>
  );
};

export default InteractionsList;
