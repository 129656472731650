import React from 'react';

import { useCurrentBrandingContext } from '@magoosh/context/current_branding_context';
import { useCurrentProfileContext } from '@magoosh/context/current_profile_context';
import { paths } from 'config/path_helpers';
import { BrandingSlugs } from 'types/branding';

import { useQueries } from '@admissions/queries';

import GradAdmissionsDashboard from './grad';
import MBAAdmissionsDashboard from './mba';

interface Props {}

const Dashboard: React.FC<Props> = (props) => {
  const { currentBranding } = useCurrentBrandingContext();
  const { currentProfile } = useCurrentProfileContext();
  const q = useQueries();

  const applicationCyclePlanQuery = q.applicationCyclePlanQuery(currentProfile.applicationCycle);
  const highlightedContentQuery = q.highlightedContentQuery();
  const notesHref = currentBranding.examSections.length
                      ? paths.examSectionNotes(currentBranding.examSections[0].slug)
                      : null;

  if (currentBranding.slug === BrandingSlugs.GRAD_ADMISSIONS) {
    const applicationCycleSectionsQuery = q.applicationCycleSectionsQuery(currentProfile.applicationCycle);

    return (
      <div className="u-margin-B-xl">
        <GradAdmissionsDashboard
          highlightedContent={highlightedContentQuery.data}
          notesHref={notesHref}
          plan={applicationCyclePlanQuery.data}
          sections={applicationCycleSectionsQuery.data}
        />
      </div>
    );
  } else if (currentBranding.slug === BrandingSlugs.MBA) {
    const studyScheduleQuery = q.studyScheduleQuery();

    return (
      <div className="u-margin-B-xl">
        <MBAAdmissionsDashboard
          highlightedContent={highlightedContentQuery.data}
          notesHref={notesHref}
          plan={applicationCyclePlanQuery.data}
          studySchedule={studyScheduleQuery.data}
        />
      </div>
    );
  }
}

export default Dashboard;
