import React from 'react';
import moment from 'moment-timezone';

import { LineChart } from '@magoosh/lib/charts';

import { Series } from '../types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  colorPalette?: {
    dark: string;
    light: string;
  };
  series: Series;
}

const EstimatedScoreLineChart: React.FC<Props> = (props) => {
  const colorPalette = props.colorPalette || {
    'Math': { dark: colors.dataLightPurple, light: '#F2E4FF' },
    'Verbal': { dark: colors.dataLightBlue, light: '#E8F7FD' },
    'Overall': { dark: colors.dataDarkPurple, light: '#E6C8FF' }
  }[props.series.name];

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  const formatChartData = () => {
    const formattedData = {
      id: props.series.name,
      target: props.series.target
    } as any;

    if (colorPalette) {
      formattedData.color = colorPalette.dark;
    }

    if (props.series.score) {
      const values = props.series.values.map((dataPoint) => (
        {
          fuzzyRange: dataPoint.range,
          x: moment(dataPoint.date * 1000).tz(timeZone).format('YYYY-MM-DD'),
          y: dataPoint.score
        }
      ));

      formattedData.data = values;
    } else {
      // Placeholder data for when we don't have an estimated score yet
      const values = [0.4, 0.45, 0.55, 0.7].map((percentile, idx) => {
        const rangeSize = props.series.scoreRange.high - props.series.scoreRange.low
        const score = props.series.scoreRange.low + (rangeSize * percentile);

        return {
          fuzzyRange: {
            low: score - (rangeSize * 0.1),
            high: score + (rangeSize * 0.1)
          },
          x: moment().add(idx * 2, 'weeks').format('YYYY-MM-DD'),
          y: score
        }
      });

      formattedData.data = values;
    }

    return formattedData;
  };

  const chartData = formatChartData();

  const fuzzyRangeLayer = (chart) => {
    return chart.series.map(({ id, data, color }) => {
      const validData = (data || []).filter((d) => !!d.data.y);
      if (!validData.length) return;

      return (
        <svg key={id}>
          <defs>
            <pattern id={`diagonalHatch-${id}`} width="10" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
              {/* @ts-ignore */}
              <line
                x1="0"
                y1="0"
                x2="0"
                y2="10"
                strokeWidth="5"
                stroke={colorPalette?.light || color}
                strokeLinecap="square" />
            </pattern>
          </defs>

          <path
            d={chart.lineGenerator([
              ...validData.map(d => ({
                x: chart.xScale(d.data.x),
                y: chart.yScale(d.data.fuzzyRange.high)
              })),
              ...validData.slice().reverse().map(d => ({
                x: chart.xScale(d.data.x),
                y: chart.yScale(d.data.fuzzyRange.low)
              })),
              {
                x: chart.xScale(validData[0].data.x),
                y: chart.yScale(validData[0].data.fuzzyRange.high)
              }
            ])}
            fill={`url(#diagonalHatch-${id})`}
          />
        </svg>
      );
    });
  };

  const pointLabelLayer = (chart) => {
    return chart.series.map(({ id, data, color }) => {
      return (
        <React.Fragment key={id}>
          {
            data.map((d, idx) => {
              if (!d.data.y) return null;

              return (
                <svg
                  key={`${id}-${idx}`}
                  x={chart.xScale(d.data.x) - 17}
                  y={chart.yScale(d.data.y) - 28}
                  width={34}
                  height={18}
                >
                  <rect
                    fill={color}
                    x={0}
                    y={0}
                    width={34}
                    height={18}
                    rx={10}
                  />
                  <text
                    dominantBaseline="middle"
                    fill="#ffffff"
                    fontFamily="Tondo, Arial, Helvetica Neue, Helvetica, sans-serif"
                    fontSize={12}
                    fontWeight="bold"
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                  >
                    {props.series.score ? d.data.y : 'TBD'}
                  </text>
                </svg>
              )
            })
          }
        </React.Fragment>
      );
    });
  };

  const renderCustomTooltip = ({ point }) => {
    return (
      <div
        className="align-items-center d-flex gap-xs"
        style={{
          background: 'white',
          color: 'inherit',
          fontSize: 'inherit',
          borderRadius: 2,
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
          padding: '5px 9px'
        }}
      >
        <span style={{ display: 'block', width: 12, height: 12, background: point.serieColor }} />
        <span>{moment(point.data.xFormatted).format('MMM D, YYYY')}:</span>
        <span className="text-bold">{point.data.fuzzyRange.low} - {point.data.fuzzyRange.high}</span>
      </div>
    );
  };

  const targetLineLayer = (chart) => {
    return chart.series.map(({ id, target }) => {
      if (!target) return;

      return (
        <svg key={id}>
          <line
            x1={0}
            y1={chart.yScale(target)}
            x2={chart.width - chart.margin.right - chart.margin.left}
            y2={chart.yScale(target)}
            strokeWidth={1.5}
            stroke="#BBB7C0"
            strokeDasharray={6}
          />

         <text
            fill="#BBB7C0"
            fontFamily="Tondo, Arial, Helvetica Neue, Helvetica, sans-serif"
            fontSize={10}
            fontWeight="bold"
            x={30}
            y={chart.yScale(target) - 6}
          >
            Target: {target}
          </text>
        </svg>
      );
    });
  };

  return (
    <LineChart
      chartProps={{
        axisBottom: {
          format: '%b %d',
          tickPadding: 10,
          tickSize: 0,
          tickValues: chartData.data.map((d) => moment(d.x))
        },
        axisLeft: {
          tickPadding: 20,
          tickSize: 0,
          tickValues: 5
        },
        layers: [
          fuzzyRangeLayer,
          'grid',
          targetLineLayer,
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
          pointLabelLayer
        ],
        gridXValues: chartData.data.map((d) => moment(d.x)),
        tooltip: renderCustomTooltip,
        useMesh: !!props.series.score,
        xFormat: 'time:%Y-%m-%d',
        xScale: {
          format: '%Y-%m-%d',
          precision: 'day',
          type: 'time',
          useUTC: false
        },
        yScale: {
          max: props.series.scoreRange.high,
          min: props.series.scoreRange.low,
          type: 'linear'
        }
      }}
      data={[chartData]}
      height={270}
      hideLegend
    />
  );
};

export default EstimatedScoreLineChart;
