import { Reducer } from 'redux';
import { every, isNull, merge } from 'lodash';

import tempData from './default_data';
import { StudyPreferencesActionTypes } from './actions';
import { StudyPreferences } from 'types/study_preferences';
import { WeekDayOptionalBooleans } from 'types/week_day_booleans';

export interface StudyPreferencesStore {
  studyPreferencesSaved: StudyPreferences;
  studyPreferencesUnsaved: StudyPreferences;
  studyPreferencesModalIsOpen: boolean;
  modalStartStep: number;
  studyActivityThisWeek?: WeekDayOptionalBooleans;
}

const inititalState: StudyPreferencesStore = {
  studyPreferencesSaved: { ...tempData.studyPreferences } as StudyPreferences,
  studyPreferencesUnsaved: { ...tempData.studyPreferences } as StudyPreferences,
  studyPreferencesModalIsOpen: false,
  modalStartStep: 1
};

export const studyPreferencesReducer: Reducer<StudyPreferencesStore> = (
  state = inititalState,
  action: any
) => {
  switch (action.type) {
    case StudyPreferencesActionTypes.SET_INITIAL_PREFERENCES:
      let newState = merge({}, state, action.payload);

      // Apply defaults. I'm sure there is a better way to do this!
      if (isNull(newState.studyPreferencesUnsaved.hoursPerDay)) {
        newState.studyPreferencesUnsaved.hoursPerDay = 1;
      }
      
      if (every(newState.studyPreferencesUnsaved.days, (v) => !v)) {
        newState.studyPreferencesUnsaved.days.monday = true;
        newState.studyPreferencesUnsaved.days.tuesday = true;
        newState.studyPreferencesUnsaved.days.wednesday = true;
      }

      return newState;
    
    case StudyPreferencesActionTypes.TOGGLE_MODAL:
      return merge({}, state, {
        studyPreferencesModalIsOpen: !state.studyPreferencesModalIsOpen,
        modalStartStep: action.payload.startStep
      } as StudyPreferencesStore);
    
    case StudyPreferencesActionTypes.TOGGLE_STUDY_DAY:
      return merge({}, state, {
        studyPreferencesUnsaved: {
          days: {
            [action.payload.day]: action.payload.value
          }
        }
      } as StudyPreferencesStore);
    
    case StudyPreferencesActionTypes.CHANGE_STUDY_LENGTH_HOURS:
      return merge({}, state, {
        studyPreferencesUnsaved: {
          hoursPerDay: action.payload
        }
      } as StudyPreferencesStore);
    
    case StudyPreferencesActionTypes.DID_SAVE:
      return merge({}, state, {
        studyPreferencesSaved: state.studyPreferencesUnsaved
      } as StudyPreferencesStore);
    default:
      return state;
  }
};
