import { connect } from 'react-redux';
import AssignmentDetail from './template';
import { hideDeleteAssignmentDialog, showDeleteAssignmentDialog } from '../actions';
import { RootStore } from '@magoosh/store';

const mapStateToProps = (state: RootStore) => {
  return {
    assignment: state.assignments.detailsAssignment,
    deleteAssignmentDialogOpen: state.assignments.deleteAssignmentDialogOpen
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showDeleteAssignmentDialog: () => {
      dispatch(showDeleteAssignmentDialog());
    },
    hideDeleteAssignmentDialog: () => {
      dispatch(hideDeleteAssignmentDialog());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetail);
