import * as React from 'react';
import { ExamSectionFocus } from '../types';

import { PrimaryButton } from '@magoosh/lib/button';

import style from './style.module.scss';
import cx from 'classnames';

interface Props {
  onComplete: (studyExperience: string) => void;
  examSectionFocus: ExamSectionFocus;
}

interface State {
  examSectionFocus: ExamSectionFocus;
}

export default class ExamSectionFocusStep extends React.Component<Props, State> {
  handleOnClick = (e) => {
    this.setState({ examSectionFocus: e.target.value });
  };

  handleOnComplete = () => {
    this.props.onComplete(this.state.examSectionFocus);
  };

  constructor(props) {
    super(props);
    this.state = {
      examSectionFocus: props.examSectionFocus
    };
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ flexGrow: 1 }}>
          <h3 className="text-center u-margin-A-n">What Sections Do You Want to Focus On?</h3>

          <div className="u-margin-H-s u-margin-T-m">
            <label className={cx("u-margin-B-s", style.examSectionFocusOption)}>
              <input
                checked={this.state.examSectionFocus === ExamSectionFocus.MathAndVerbal}
                name="examSectionFocus"
                onChange={this.handleOnClick}
                type="radio"
                value={ExamSectionFocus.MathAndVerbal}
              />
              <span className="font-weight-normal small u-margin-L-xs">
                I want to focus equally on <br/> math and verbal
              </span>
            </label>

            <label className={cx("u-margin-B-s", style.examSectionFocusOption)}>
              <input
                checked={this.state.examSectionFocus === ExamSectionFocus.Math}
                name="examSectionFocus"
                onChange={this.handleOnClick}
                type="radio"
                value={ExamSectionFocus.Math}
              />
              <span className="font-weight-normal small u-margin-L-xs">I want to focus more on math</span>
            </label>

            <label className={cx("u-margin-B-s", style.examSectionFocusOption)}>
              <input
                checked={this.state.examSectionFocus === ExamSectionFocus.Verbal}
                name="examSectionFocus"
                onChange={this.handleOnClick}
                type="radio"
                value={ExamSectionFocus.Verbal}
              />
              <span className="font-weight-normal small u-margin-L-xs">I want to focus more on verbal</span>
            </label>
          </div>
        </div>

        <div className="text-center">
          <PrimaryButton className={style.primaryButton} onClick={this.handleOnComplete}>
            Next
          </PrimaryButton>
        </div>
      </React.Fragment>
    );
  }
}
