import React from 'react';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

interface Props {}

const LoadingIndicator: React.FC<Props> = (props) => {
  return (
    <div
      className="u-padding-A-m"
      style={{
        backgroundColor: 'rgba(238, 238, 238, 0.75)',
        borderRadius: 8,
        height: 80,
        width: 80
      }}
    >
      <FontAwesomeIcon icon="spinner" className="fa-pulse fa-2x" />
    </div>
  );
};

export default LoadingIndicator;
