import * as React from 'react';
import cx from 'classnames';

import style from './style.module.scss';

interface Props {
  children: any;
  className?: string;
  shadow?: boolean;
  style?: any;
}

const Card: React.FC<Props> = (props) => {
  return (
    <div className={cx(style.cardContainer, props.className, { [style.boxShadow]: props.shadow })} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card;
