import React, { useEffect, useState } from 'react';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import DropdownList from 'react-widgets/lib/DropdownList';
import styles from '@magoosh/b2b/app/pages/reporting/style.module.scss';

export default function useReportingFilterOptions(runQuery) {
  const [filterOptions, setFilterOptions] = useState({ schoolAndClassSections: [] });

  useEffect(() => {
    fetch(paths.api.partner.reportingFilterOptions())
      .then(data => {
        const schools: School[] = data.schools;
        const schoolAndClassSections: (School | ClassSection)[] = [];

        schools.forEach(school => {
          if (schools.length > 1) {
            schoolAndClassSections.push(school);
          }

          schoolAndClassSections.push(...school.classSections);
        });

        setFilterOptions({ schoolAndClassSections: schoolAndClassSections });
      });
  }, []);

  const reportingFiltersDropdown =
    <div className={styles.classSectionAndSchoolFilter}>
      <DropdownList
        placeholder={'Filter by class and school'}
        textField={item => item && (item.name || item.displayName)}
        itemComponent={item => {
          if (!item.value) {
            return <strong>All students</strong>;
          }

          if (item.value.classSections) {
            return <strong>{item.value.name}</strong>;
          }

          return <div
            className={styles.classSectionListItem}>{item.value.displayName || item.value.name}</div>;
        }}
        filter='contains'
        data={[null, ...filterOptions.schoolAndClassSections]}
        busy={filterOptions['schoolAndClassSections'].length <= 0}
        onChange={(value) => {
          if (!value) {
            runQuery({ filters: {} });
          } else if (value.classSections) {
            runQuery({ filters: { schoolId: value.id } });
          } else {
            runQuery({ filters: { classSectionId: value.id } });
          }
        }}
      />
    </div>

  return { reportingFiltersDropdown };
}
