import style from './style.module.scss';
import { paths } from 'config/path_helpers';
import { secondsFormattedAsMinutes } from 'utilities/time';
import * as React from 'react';
import cx from 'classnames';
import { LessonData } from 'types/lesson';
import { Assignment } from '../../types';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import Card from '@magoosh/lib/card';

interface LessonCardProps {
  assignment?: Assignment;
  lessons: LessonData[];
  subtitle: string;
  title: string;
  user: {
    accessLevel: number;
  };
}

export default class LessonsCard extends React.Component<LessonCardProps> {
  render() {
    const { title, subtitle, lessons, user, assignment } = this.props;

    return (
      <Card shadow className={style.card}>
        <h4>
          <strong>{title}</strong>
        </h4>
        <div className="u-margin-V-m">{subtitle}</div>

        <ul className="list-unstyled">
          {lessons.map((lesson) => {
            const userCannotAccess = lesson.accessLevel > user.accessLevel;

            return (
              <li
                className={cx(style.lessonListItem, { [style.lessonListItemNoAccess]: userCannotAccess })}
                key={lesson.id}>
                <a className="text-no-decoration" href={paths.lesson(lesson.slug, assignment)}>
                  {lesson.title}
                </a>
                <span className={style.lessonLength}>
                  <small>{secondsFormattedAsMinutes(lesson.lengthSeconds)}</small>
                </span>
                {this.renderBadge(lesson)}
              </li>
            );
          })}
        </ul>
      </Card>
    );
  }

  renderBadge(lesson: LessonData) {
    if (lesson.markedFinished) {
      return <FontAwesomeIcon icon="check" title="Lesson finished" />;
    } else if (lesson.viewed) {
      return <FontAwesomeIcon icon="eye" title="Lesson viewed" />;
    }

    return null;
  }
}
