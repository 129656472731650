import React from 'react';
import cx from 'classnames';

import { SimpleBarChart } from '@magoosh/lib/charts';

import { ExamSectionDetail } from '../types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

const PerformanceBreakdown: React.FC<ExamSectionDetail> = (props) => {
  return (
    <div className="d-flex flex-column gap-s">
      {
        props.performanceBreakdown.map((category, idx) => {
          const percentCorrect = category.totalAnswerCount === 0 ? 0 : Math.round((category.correctAnswerCount / category.totalAnswerCount) * 100);

          return (
            <div key={idx} className="align-items-center d-flex gap-m gap-lg-l">
              <div className="align-items-lg-center d-flex flex-1 flex-column flex-md-row gap-xs gap-md-l">
                <div className={style.subjectName}>
                  <div className="visible-xs">
                    <span><strong>{category.name}</strong></span>
                    <span className="text-gray-light u-margin-L-xs" style={{ fontSize: 12 }}>
                      {category.totalAnswerCount ? `${category.correctAnswerCount} of ${category.totalAnswerCount} correct` : 'No Activity'}
                    </span>
                  </div>

                  <div className="hidden-xs">
                    <strong>{category.name}</strong>

                    <div className="text-gray-light" style={{ fontSize: 12 }}>
                      {category.totalAnswerCount ? `${category.correctAnswerCount} of ${category.totalAnswerCount} correct` : 'No Activity'}
                    </div>
                  </div>
                </div>

                <div className="flex-1">
                  <SimpleBarChart
                    colorPalette={{ dark: colors.dataDarkPurple, light: colors.dataMutedPurple }}
                    data={[{ value: category.totalAnswerCount === 0 ? null : percentCorrect }]}
                    hideLabel
                    hideLegend
                  />
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default PerformanceBreakdown;
