import React from 'react';
import cx from 'classnames';
import { debounce, isNull } from 'lodash';

import { SuccessAlert } from '@magoosh/lib/alerts';
import { PrimaryButton } from '@magoosh/lib/button';
import Tag from '@magoosh/lib/tag';
import { paths } from 'config/path_helpers';
import { RatingData } from 'types/rating';
import fetch from 'utilities/fetch';

import Tagger from './tagger';

import style from './style.module.scss';

interface RatingFormProps {
  binaryFeedbackClassName?: string;
  centerButtons?: boolean;
  feedbackClassName?: string;
  rating?: RatingData;
  rateableName?: string;
}

export default class RatingForm extends React.Component<RatingFormProps> {
  state = {
    text: this.props.rating && this.props.rating.text,
    rating: this.props.rating && this.props.rating.rating,
    alreadySubmitted: this.props.rating && this.props.rating.text && this.props.rating.text.length > 0, // Manually submitted, or typed and reloaded
    submitting: false,
    hasSubmitted: false // For showing the "thank you" message after submission
  };

  render() {
    return (
      <div>
        <div className={cx(style.rating, this.props.centerButtons ? style.centerButtons : null, this.props.binaryFeedbackClassName)}>
          {this.props.rating && this.renderBinaryFeedback()}
        </div>
        {this.props.rating && (
          <div className={cx(style.feedbackDetails, this.props.feedbackClassName)}>
            {this.state.hasSubmitted && this.renderThanks()}
            {this.renderFeedbackOptions()}
          </div>
        )}
      </div>
    );
  }

  renderBinaryFeedback = () => {
    return (
      <div className={cx(style.binaryFeedback, this.props.feedbackClassName)}>
        <div className="u-margin-B-xxs">
          {this.props.rating.rateableType == 'Video::File' ? (
            <>
              <strong>Lesson Feedback:</strong> Did you like this lesson?
            </>
          ) : (
            <>
              <strong>Feedback</strong>: Did you like this {this.props.rateableName}?
            </>
          )}
        </div>
        <div>
          <Tag
            displayName={'Yes, I Liked It'}
            onClickCallback={this.thumbsUp}
            isSelected={this.state.rating > 0}
            canBeSelected={true}
          />
          <Tag
            displayName={"No, I Didn't Like It"}
            onClickCallback={this.thumbsDown}
            isSelected={this.state.rating < 0}
            canBeSelected={true}
            negative={true}
          />
        </div>
      </div>
    );
  };

  renderFeedbackOptions = () => {
    if (this.state.hasSubmitted || this.state.alreadySubmitted || !this.state.rating) {
      return null;
    }

    const rating = this.state.rating;
    return (
      <div className="flex-1">
        <div className="u-margin-T-m u-border-T-s u-padding-V-m">
          {rating < 0 && this.props.rating.reasons && (
            <div className={'u-margin-B-s'}>
              <div className="u-margin-B-xs">
                <strong>What didn't you like about it?</strong> <small>(choose all that apply)</small>
              </div>
              <Tagger
                options={this.props.rating.reasons}
                paramNamespace={'rating'}
                rateableType={this.props.rating.rateableType}
                url={paths.api.rating(this.props.rating.rateableId)}
              />
            </div>
          )}

          <label htmlFor={'rating-text'}>
            <strong>
              {(() => {
                if (isNull(rating)) {
                  return 'What feedback do you have to share?';
                } else if (rating < 0) {
                  return 'How can we improve?';
                } else {
                  return 'What did you like about it?';
                }
              })()}
            </strong>
          </label>
          <textarea
            name={'rating.text'}
            defaultValue={this.state.text}
            id="rating-text"
            rows={5}
            onChange={this.onChangeText}
          />
          <div className={'u-margin-T-s'}>
            <PrimaryButton submitting={this.state.submitting} onClick={this.onClickSubmitRating}>
              Submit Feedback
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  };

  renderThanks = () => {
    return (
      <SuccessAlert className="flex-1 u-margin-T-s">
        <strong>Thanks for letting us know!</strong>
        <br />
        We may not be able to respond to all of your feedback, but we promise to read and consider all of it
        :)
      </SuccessAlert>
    );
  };

  // Feedback text is submitted automatically whenever the user stops typing,
  // but the "thank you" message only appears when they manually submit it.
  onChangeText = (event) => {
    this.setState({ text: event.target.value, saved: false }, this.backgroundSubmit);
  };

  backgroundSubmit = debounce(
    async () => {
      this.onSubmitRating();
    },
    2000,
    { trailing: true }
  );

  onClickSubmitRating = () => {
    this.setState({ submitting: true }, () => {
      this.onSubmitRating(true);
    });
  };

  onSubmitRating = (manualSubmit = false) => {
    fetch(paths.api.rating(this.props.rating.rateableId || 'feature'), {
      method: 'PUT',
      body: JSON.stringify({
        feature: this.props.rating.feature,
        rateableType: this.props.rating.rateableType,
        rating: {
          rating: this.state.rating,
          text: this.state.text
        }
      })
    }).then((response) => {
      this.setState({
        hasSubmitted: this.state.hasSubmitted || manualSubmit,
        submitting: false
      });
    });
  };

  thumbsUp = () => {
    this.setState({ rating: 1 }, this.onSubmitRating);
  };

  thumbsDown = () => {
    this.setState({ rating: -1 }, this.onSubmitRating);
  };
}
