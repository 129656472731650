import React, { useLayoutEffect, useRef, useState } from 'react';

import { PrimaryButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

import style from './style.module.scss';

interface Props {
  disabled: boolean;
  onSubmit: (text: string) => Promise<any>;
}

const UserInput: React.FC<Props> = (props) => {
  const textAreaRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [userText, setUserText] = useState<string>('');

  useLayoutEffect(() => {
    const handleTextAreaKeydown = (e) => {
      if (e.keyCode === 13) {
        // Shift + Enter
        if (e.shiftKey) {
          textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        // Enter
        } else {
          e.preventDefault();
          submitUserText();
        }
      }
    };

    textAreaRef.current.addEventListener('keydown', handleTextAreaKeydown);

    return () => {
      textAreaRef.current.removeEventListener('keydown', handleTextAreaKeydown);
    };
  }, [userText]);

  const handleUserTextChanged = (e) => {
    setUserText(e.target.value);
  };

  const submitUserText = () => {
    if (!userText.length) return;

    setIsSubmitting(true);
    setUserText('');

    const userTextWithAdjustedNewlines = userText.replace(/\n/g, '\n\n');

    props.onSubmit(userTextWithAdjustedNewlines).then(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <div
      className="align-items-center bg-gray-lightest d-flex gap-s"
      style={{ borderRadius: 8, padding: '8px 15px' }}
    >
      <div className="flex-1">
        <textarea
          className={style.textInput}
          disabled={props.disabled || isSubmitting}
          onChange={handleUserTextChanged}
          placeholder="Ask away..."
          ref={textAreaRef}
          rows={1}
          value={userText}
        />
      </div>

      <div>
        <PrimaryButton
          className="u-padding-H-s"
          disabled={props.disabled || isSubmitting}
          onClick={submitUserText}
          size="sm"
        >
          <FontAwesomeIcon icon="send" />
        </PrimaryButton>
      </div>
    </div>
  );
};

export default UserInput;
