import styles from './style.module.scss';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import { DefaultButton } from '@magoosh/lib/button';
import * as React from 'react';
import cx from 'classnames';

interface NavLinkProps {
  to: string;
  text: string;
}

export const NavLink: React.FC<NavLinkProps> = (props) => (
  <ReactRouterNavLink to={props.to} activeClassName={styles.active}>
    <DefaultButton className={styles.tab}>{props.text}</DefaultButton>
  </ReactRouterNavLink>
);

export const NavBar: React.FC<{ children: React.ReactElement<NavLinkProps>[] }> = (props) => (
  <>
    <div className={cx(styles.tabNav, 'u-padding-V-xs', 'u-margin-V-m')}>{props.children}</div>
  </>
);
