import React, { Children, cloneElement, useEffect, useRef } from 'react';

import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';

import { useQueries } from '@test_prep/app/queries';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  filters: any; // TODO
  formatDataFn?: (data: any) => any;
  name: string;
  onLoaded?: (data, previousData) => void;
}

const Loading: React.FC<any> = (props) => {
  return props.children;
};

const StatisticData: (React.FC<Props> & { Loading: any }) = (props) => {
  const previousData = useRef(null);
  const q = useQueries();

  const statisticQuery = q.statisticQuery(props.name, props.filters);
  const statistic = statisticQuery.data;

  useEffect(() => {
    if (!statistic) return;

    props.onLoaded && props.onLoaded(statistic.data, previousData.current);
    previousData.current = statistic.data;
  }, [statistic]);

  const renderLoading = () => {
    if (previousData.current) {
      return renderChildren(previousData.current);
    } else {
      return Children.map(props.children, (child) => {
        if ((child as React.ReactElement<any>).type === Loading) {
          return child;
        }
      });
    }
  };

  const renderChildren = (statisticData) => {
    const data = props.formatDataFn ? props.formatDataFn(statisticData) : statisticData;

    return (
      <>
        {
          Children.map(props.children, (child) => {
            if ((child as React.ReactElement<any>).type !== Loading) {
              return cloneElement(
                child as React.ReactElement<any>,
                { data }
              );
            }
          })
        }
      </>
    );
  };

  if (!statistic) {
    return (
      <div style={{ position: 'relative' }}>
        {renderLoading()}

        <div
          style={{
            left: '50%',
            position: 'absolute',
            top: 10,
            transform: 'translateX(-50%)',
            zIndex: 10
          }}
        >
          <LoadingIndicator />
        </div>
      </div>
    );
  } else {
    return renderChildren(statistic.data);
  }
};

StatisticData.Loading = Loading;

export default StatisticData;
