import React from 'react';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  image: {
    title: string;
    url: string;
  };
  logo: {
    title: string;
    url: string;
  }
};

const ImageWithLogoOverlay: React.FC<Props> = (props) => {
  return (
    <div className={style.container}>
      <div
        className={style.coverContainer}
        style={{ backgroundColor: colors.grayLightest }}
      >
        <img
          alt={props.image.title}
          className={style.cover}
          src={props.image.url}
        />
      </div>

      <img
        alt={props.logo.title}
        className={style.logo}
        src={props.logo.url}
      />
    </div>
  );
};

export default ImageWithLogoOverlay;
