import { appendSearchParam, paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import * as api from '@magoosh/organisms/study_schedule/api';

export const createMarking = (lessonId, token) => {
  return fetch(paths.lessonMarking(lessonId), {
    method: 'POST',
    body: JSON.stringify({
      authenticityToken: token,
      marking: {
        name: 'completed'
      }
    })
  });
};

export const goToNextUrl = (props, setPracticePreview) => {
  const nextItem = props.nextStudyItem;
  let url;

  if (nextItem.url) {
    url = nextItem.url;
    if (props.guidedActivity?.assignmentId) {
      url = appendSearchParam(
        new URL(url, window.location.origin),
        'assignment_id',
        props.guidedActivity?.assignmentId
      );
    }

    url = appendSearchParam(
      new URL(url, window.location.origin),
      'review[study_item]',
      nextItem.id
    );
  } else {
    url = paths.lessons();
  }

  if (nextItem.type === 'Misc') {
    window.open(url, '_blank');
    handleItemCompletion(nextItem);
  } else if (['Review', 'Flashcards'].includes(nextItem.type)) {
    handleItemCompletion(nextItem).then(() => {
      window.location.assign(url);
    });
  } else if (nextItem.type === 'Lesson') {
    window.location.assign(url);
  } else {
    fetchPractice(nextItem.id, setPracticePreview, props.guidedActivity?.assignmentId);
  }
};

const handleItemCompletion = (item) => {
  return api.recordContentBlockProgress(item.id, true);
};

const fetchPractice = (blockId: number, setPracticePreview, assignmentId?) => {
  const url = appendSearchParam(
    new URL(paths.api.studySchedulePractice(blockId), window.location.origin),
    'assignment_id',
    assignmentId
  );

  fetch(url, {
    method: 'POST'
  }).then((response) => {
    handleFetchPracticeResponse(response, setPracticePreview, assignmentId);
  });
};

const handleFetchPracticeResponse = (response, setPracticePreview, assignmentId?) => {
  if (response.preview) {
    setPracticePreview(response.preview);
  } else if (response.redirect) {
    const url = appendSearchParam(
      new URL(response.redirect, window.location.origin),
      'assignment_id',
      assignmentId
    );

    window.location.assign(url);
  }
};
