import React, { useContext } from 'react';
import Icon from '@magoosh/b2b/app/assets/icons';
import { SecondaryButton } from '@magoosh/lib/button';
import { DataTable } from '@magoosh/lib/data_table';
import { paths } from 'config/path_helpers';
import { dateFormatter } from '@magoosh/b2b/app/utils';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Search } from '@magoosh/lib/data_table/search';
import fetch from 'utilities/fetch';
import colors from '@magoosh/b2b/app/assets/style.module.scss';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@magoosh/b2b/app/app_context';

interface InstructorsDataTableProps {
  classSection?: ClassSection;
}

export const InstructorsDataTable: React.FC<InstructorsDataTableProps> = (props) => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const defaultQueryParams = { sortColumn: 'fullName', filters: {} };

  if (props.classSection) {
    defaultQueryParams.filters['classSectionId'] = props.classSection.id;
  }

  const { queryParameters, runQuery, dataQueryTableProps } = useDataQuery(
    paths.api.partner.instructors,
    defaultQueryParams
  );

  return (
    <>
      <DataTable
        {...dataQueryTableProps}
        primaryAttribute={{ selector: 'fullName', name: 'Instructor', sortable: true }}
        secondaryAttributeSelector="email"
        columns={[
          {
            selector: 'lastActiveAt',
            name: 'Last Active',
            sortable: true,
            format: (student) => dateFormatter(student.lastActiveAt)
          },
          { selector: 'activeClassesCount', name: 'Active Classes', sortable: true },
          { selector: 'studentsCount', name: 'Students', sortable: true },
          { selector: 'activeAssignmentsCount', name: 'Active Assignments', sortable: true }
        ]}>
        <DataTable.Filters>
          <Search runQuery={runQuery} />
        </DataTable.Filters>
        <DataTable.HeaderActions>
          {!appContext.externalSourceUser && (
            <SecondaryButton onClick={() => history.push('/instructors/assign')} icon={'plus'} size={'xsmall'}>
              Assign Instructors
            </SecondaryButton>
          )}
          <DownloadButton
            queryParameters={queryParameters}
            fetchUrl={paths.api.partner.instructors}
            fileName={'instructors_data.csv'}
            disabled={appContext.trialAccess}
          />
        </DataTable.HeaderActions>
        <DataTable.ItemIcon>
          {(instructor) => <Icon iconType="Instructor" fill={colors.supplementaryMagenta} size="m" />}
        </DataTable.ItemIcon>
      </DataTable>
    </>
  );
};
