import React from 'react';
import Modal from '@magoosh/lib/modal';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { pluralize } from 'utilities/pluralize';

interface ArchiveAssignmentsModalProps {
  assignments: Assignment[];
  reset: () => void;
  archiveAssignments: () => void;
}

export const ArchiveAssignmentsModal: React.FC<ArchiveAssignmentsModalProps> = (props) => {
  const { assignments, reset, archiveAssignments } = props;

  if (!assignments || assignments.length <= 0) {
    return null;
  }

  let message;
  let buttonText;

  if (assignments.length == 1) {
    const assignment = assignments[0];

    message = <>
      <p>
        Are you sure you want to archive this assignment?
      </p>
      <div style={{ marginLeft: '3rem' }}>
        <p>
          Assignment <br />
          <strong>{assignment.title}</strong>
        </p>
        <p>
          Content <br />
          <strong>{assignment.contentName}</strong>
        </p>
      </div>
    </>;

    buttonText = 'Archive Assignment';
  } else {
    message = <p>
        Are you sure you want to archive the selected assignments?
    </p>;

    buttonText = `Archive ${pluralize(assignments.length, 'Assignment')}`
  }

  return <Modal
    show={assignments && assignments.length > 0}
    onHide={reset}
  >
    {message}
    <Modal.Footer>
      <DefaultButton onClick={reset}>
        Cancel
      </DefaultButton>
      <SecondaryButton onClick={() => {
        archiveAssignments();
      }}>
        {buttonText}
      </SecondaryButton>
    </Modal.Footer>
  </Modal>;
}
