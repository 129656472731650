import React from 'react';

const MCATDetailsContent: React.FC = (props) => {
  return (
    <>
      <h4>Preparation</h4>
      <p>Just like with the real test, you should have a <strong>wet erase board</strong> and <strong>marker</strong> (or <strong>pen or pencil</strong> and <strong>scratch paper</strong>) to write notes and work out problems.</p>
      <p>For the full-length MCAT, you should set aside about <strong>7 hours and 30 minutes, including breaks</strong> for a full test.</p>
      <p>Let your family and friends know not to interrupt you during this time. :)</p>
      <p>You can pause between sections if you need to, but not within a section. Once you start a section, you'll need to finish it because the timer will keep running.</p>
      <p>We recommend taking the entire test in one sitting to practice your pacing and build your test-taking stamina, which you'll need on test day. Good luck!</p>
    </>
  );
};

export default MCATDetailsContent;
