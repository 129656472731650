import * as React from 'react';
import cx from 'classnames';
import { Field } from 'redux-form';
import { omit } from 'lodash';

import {
  ControlLabel,
  HelpBlock,
  FormControl,
  FormGroup,
  Alert
} from 'react-bootstrap'

// FORM
export interface FormProps {
  children?: any;
  className?: string;
  error?: string;
  errorClass?: string;
  onSubmit: (any) => void;
};

export class Form extends React.Component<FormProps> {
  render() {
    return (
      <form className={this.props.className} onSubmit={this.props.onSubmit}>
        {this.props.error &&
        <Alert bsStyle={this.props.errorClass || 'info'}>{this.props.error}</Alert>}
        {this.props.children}
      </form>
    )
  }
}

// INPUT
export type InputSize = 'sm' | 'small' | 'lg' | 'large';

export interface InputProps {
  children?: any;
  className?: string;
  componentClass?: React.ElementType<any>;
  error?: string;
  groupClassName?: string;
  id?: string;
  inline?: boolean;
  label?: string;
  name?: string;
  onChange?: (any) => void;
  placeholder?: string;
  size?: InputSize;
  type?: string;
  value?: any;
  disabled?: boolean;
  required?: boolean;
};

export class Input extends React.Component<InputProps> {
  render() {
    let help, validationState;
    if (this.props.error) {
      help = this.props.error;
      validationState = 'error';
    }

    const groupProps = {
      className: cx(this.props.groupClassName, { 'd-inline-block': this.props.inline }),
      controlId: this.props.id,
      validationState: validationState
    };
    const inputProps = {
      bsClass: cx(this.props.className, 'form-control'),
      bsSize: this.props.size,
      children: this.props.children,
      componentClass: this.props.componentClass || 'input',
      name: this.props.name,
      onChange: this.props.onChange,
      placeholder: this.props.placeholder,
      type: this.props.type,
      value: this.props.value,
      disabled: this.props.disabled,
      required: this.props.required
    };

    return (
      <FormGroup {...groupProps}>
        {this.props.label && <ControlLabel>{this.props.label}</ControlLabel>}
        <FormControl {...inputProps} />
        {help && <HelpBlock>{help}</HelpBlock>}
      </FormGroup>
    );
  }
}

// SELECT
export class Select extends React.Component<InputProps> {
  render() {
    return (
      <Input {...this.props} componentClass="select" />
    );
  }
}

// FIELD INPUT - for Redux Form
export interface FieldInputProps extends InputProps {
  name: string;
  validate?: any;
};

export class FieldSelect extends React.Component<FieldInputProps> {
  render() {
    return (
      <FieldInput {...this.props} componentClass="select" />
    );
  }
}

export class FieldInput extends React.Component<FieldInputProps> {
  render() {
    return (
      <Field component={this._renderInput} {...this.props} />
    )
  }

  _renderInput = (field) => {
    const error = (field.meta.touched && field.meta.error) ? field.meta.error : null
    return (
      <Input error={error}
             componentClass={this.props.componentClass} {...field.input} {...omit(field, ['input', 'meta'])} />
    )
  }
}
