import React, { useState } from 'react';

import { DefaultButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

interface Props {
  isCompleted: boolean;
  lessonId: number;
}

const MarkingButton: React.FC<Props> = (props) => {
  const authenticityToken = useAuthenticityToken();
  const [isCompleted, setIsCompleted] = useState(props.isCompleted);

  const handleLessonCompleted = (completed: boolean) => {
    setIsCompleted(completed);

    fetch(paths.lessonMarking(props.lessonId), {
      method: completed ? 'POST' : 'DELETE',
      body: JSON.stringify({
        authenticityToken,
        marking: {
          name: 'completed'
        }
      })
    });
  };

  if (isCompleted) {
    return (
      <DefaultButton onClick={() => handleLessonCompleted(false)}>
        <FontAwesomeIcon
          className="text-primary"
          icon="check"
          style={{ WebkitTextStroke: `1px #fff` }}
        />
        {' '}Lesson Finished!
      </DefaultButton>
    );
  } else {
    return (
      <DefaultButton onClick={() => handleLessonCompleted(true)}>
        Mark Lesson as Finished
      </DefaultButton>
    );
  }
};

export default MarkingButton;
