import React, { useLayoutEffect, useState } from 'react';
import { flatten, max, truncate } from 'lodash';

import { SimpleBarChart } from '@magoosh/lib/charts';

import StatisticData from '../statistic_data';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  sections: string;
  filters: any;
}

const PerformanceByTypeChart: React.FC<Props> = (props) => {
  const loadingData = () => {
    const mathTypes = [
      { name: 'Data Interpretation', value: 100 },
      { name: 'Multiple Answer', value: 100 },
      { name: 'Multiple Choice', value: 100 },
      { name: 'Numeric Entry', value: 100 },
      { name: 'Quantitative Comp...', value: 100 }
    ];

    const verbalTypes = [
      { name: 'Reading Comprehen...', value: 100 },
      { name: 'Sentence Equivalence', value: 100 },
      { name: 'Text Completion', value: 100 }
    ];

    let result = [];

    if (!props.sections || props.sections === 'all') {
      result = [
        { name: 'Math', data: mathTypes },
        { name: 'Verbal', data: verbalTypes }
      ];
    } else {
      if (props.sections.indexOf('math') !== -1){
        result.push({ name: 'Math', data: mathTypes });
      }
      if (props.sections.indexOf('verbal') !== -1) {
        result.push({ name: 'Verbal', data: verbalTypes });
      }
    }

    return result;
  };

  const loadingPlaceholder = (
    <SectionedBarGroups
      colorPalette={{ dark: colors.gray85, light: colors.grayLightest }}
      data={loadingData()}
    />
  );

  const formatStatisticData = (statisticData) => {
    return statisticData.map((group) => {
      const data = group.data.map((dataPoints) => {
        return {
          ...dataPoints,
          name: truncate(dataPoints.name, { length: 20 })
        };
      });

      return {
        name: group.name,
        data
      };
    });
  };

  if (!props.filters) {
    return loadingPlaceholder;
  } else {
    return (
      <StatisticData
        filters={props.filters}
        formatDataFn={formatStatisticData}
        name="performance_by_type"
      >
        <StatisticData.Loading>
          {loadingPlaceholder}
        </StatisticData.Loading>

        {/* @ts-ignore */}
        <SectionedBarGroups
          colorPalette={{ dark: colors.dataDarkPurple, light: colors.dataMutedPurple }}
        />
      </StatisticData>
    );
  }
};

interface SectionedBarGroupsProps {
  colorPalette: {
    dark: string;
    light: string;
  };
  data: {
    name: string;
    data: {
      name: string;
      value: number;
    }[];
  }[];
}

const SectionedBarGroups: React.FC<SectionedBarGroupsProps> = (props) => {
  const [labelWidth, setLabelWidth] = useState(0);

  useLayoutEffect(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = "14px 'Tondo', Arial, 'Helvetica Neue', Helvetica, sans-serif";

    const names = flatten(props.data.map((group) => group.data.map((d) => d.name)));
    const width = max(names.map((name) => context.measureText(name).width));

    setLabelWidth(width);
  }, [props.data]);

  return (
    <div className="d-flex flex-column gap-s">
      {
        props.data.map((group, idx) => (
          <div key={idx} className="d-flex flex-column gap-xs">
            {
              props.data.length > 1 && (
                <div>
                  <small><strong>{group.name}</strong></small>
                </div>
              )
            }

            <SimpleBarChart
              colorPalette={props.colorPalette}
              data={group.data}
              hideLegend={idx !== props.data.length - 1}
              labelWidth={labelWidth}
              seriesNames={['Correct', 'Incorrect']}
            />
          </div>
        ))
      }
    </div>
  );
};

export default PerformanceByTypeChart;
