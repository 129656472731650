import React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import style from './style.module.scss';

interface Props {
  bio: string;
  name: string;
  photo: string;
};

const AuthorBio: React.FC<Props> = (props) => {
  const renderAuthorBio = () => {
    return (
      <Popover id="lesson-author-bio" title={`About ${props.name}`}>
        <div>{props.bio}</div>
      </Popover>
    );
  };

  return (
    <div className={style.authorInfo}>
      <img alt={props.name} className={style.authorPhoto} src={props.photo} />

      <div className={style.authorCredits}>
        Lesson by{' '}
        <OverlayTrigger
          overlay={renderAuthorBio()}
          placement="bottom"
          rootClose
          trigger={['hover', 'focus']}>
          <strong id="lesson-author-name" className={style.popoverItem}>
            {props.name}
          </strong>
        </OverlayTrigger>

        <div>
          <small className="text-gray-light">Magoosh Expert</small>
        </div>
      </div>
    </div>
  );
};

export default AuthorBio;
