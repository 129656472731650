import React from 'react';
import { without } from 'lodash';

import FieldCheckboxGroup from '@magoosh/lib/formik_inputs/field_checkbox_group';

import { FilterConfig } from './types';

interface Props {
  filterConfig: FilterConfig;
  onChange: (updatedValue: string) => void;
  value: string;
}

// For use within a Formik form
const CheckboxFilter: React.FC<Props> = (props) => {
  let options = props.filterConfig.options;
  if (props.filterConfig.all) {
    options = [{ name: 'All', value: 'all' }].concat(options);
  }

  const handleValuesChanged = (selectedValues: string[]) => {
    if (selectedValues.indexOf('all') !== -1) {
      if (props.value.indexOf('all') === -1) {
        props.onChange('all');
      } else {
        props.onChange(without(selectedValues, 'all').join(','));
      }
    } else if (!selectedValues.length)  {
      props.onChange('all');
    } else {
      props.onChange(selectedValues.join(','));
    }
  };

  return (
    <FieldCheckboxGroup
      name={props.filterConfig.key}
      onChange={handleValuesChanged}
      selected={props.value.split(',')}
      optionsArray={options.map((option) => (
        {
          displayName: option.name,
          value: option.value
        }
      ))}
    />
  );
};

export default CheckboxFilter;
