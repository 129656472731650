import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import { CustomButton, DefaultFilledButton, ResponsiveButtonGroup } from '@magoosh/lib/button';

import CheckboxFilter from './checkbox_filter';
import CustomDateRange from './custom_date_range';
import RadioFilter from './radio_filter';
import { FilterConfig } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  filters: { [key: string]: string };
  filtersConfig: FilterConfig[];
  onSave: (updatedFilters: { [key: string]: string }) => void;
  onClose: () => void;
}

const ModalFilters: React.FC<Props> = (props) => {
  const [showCustomPeriodInputs, setShowCustomPeriodInputs] = useState(null);
  const [customPeriodValue, setCustomPeriodValue] = useState(null);

  const handleRadioFilterChanged = (key: string, value: string) => {
    if (key === 'period' && value === 'custom') {
      setShowCustomPeriodInputs({ key, value });
      setCustomPeriodValue(value);
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    props.onSave(values);
    props.onClose();
  };

  const renderCustomPeriodInputs = (key, value, setFieldValue) => {
    return (
      <>
        <CustomDateRange onChange={setCustomPeriodValue} value={customPeriodValue} />

        <ResponsiveButtonGroup>
          <CustomButton
            onClick={() => {
              console.log(key)
              console.log(customPeriodValue)
              setFieldValue(key, customPeriodValue);
              setShowCustomPeriodInputs(false);
            }}
            style={{ backgroundColor: colors.blue, borderColor: colors.blue, color: '#ffffff' }}
          >
            Set Custom Period
          </CustomButton>

          <DefaultFilledButton onClick={() => setShowCustomPeriodInputs(null)}>
            Back
          </DefaultFilledButton>
        </ResponsiveButtonGroup>
      </>
    );
  };

  const renderFilterInputs = (values, isSubmitting, setFieldValue) => {
    return (
      <>
        {
          props.filtersConfig.map((filterConfig, idx) => {
            if (props.filters.focus && !filterConfig.allowWhileFocused) {
              return null;
            }

            return (
              <div key={idx} className="u-margin-B-s">
                <h4>{filterConfig.name}</h4>
                <hr className="u-margin-V-xxs" />

                {
                  filterConfig.multiple ? (
                    <CheckboxFilter
                      filterConfig={filterConfig}
                      onChange={(values) => setFieldValue(filterConfig.key, values)}
                      value={values[filterConfig.key]}
                    />
                  ) : (
                    <RadioFilter
                      filterConfig={filterConfig}
                      onChange={handleRadioFilterChanged}
                      value={values[filterConfig.key]}
                    />
                  )
                }
              </div>
            );
          })
        }

        <ResponsiveButtonGroup>
          <CustomButton
            style={{ backgroundColor: colors.blue, borderColor: colors.blue, color: '#ffffff' }}
            submitting={isSubmitting}
            type="submit"
          >
            Apply
          </CustomButton>

          <DefaultFilledButton onClick={props.onClose}>
            Cancel
          </DefaultFilledButton>
        </ResponsiveButtonGroup>
      </>
    );
  };

  return (
    <Formik initialValues={props.filters} onSubmit={onSubmit}>
      {({ values, isSubmitting, setFieldValue }) => (
        <Form>
          {
            showCustomPeriodInputs ? (
              renderCustomPeriodInputs(showCustomPeriodInputs.key, showCustomPeriodInputs.value, setFieldValue)
            ) : (
            renderFilterInputs(values, isSubmitting, setFieldValue)
            )
          }
        </Form>
      )}
    </Formik>
  );
};

export default ModalFilters;
