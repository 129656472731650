import { Reducer } from 'redux';
import { StudentPerformanceActionTypes } from './actions';
import { ClassSection, ExamSection, StudentPerformanceStore } from '../types';

export const studentPerformanceReducer: Reducer<StudentPerformanceStore> = (
  state = {
    classSectionsByGrade: {} as StringHash<ClassSection[]>,
    examSections: new Array<ExamSection>(),
    loadingMode: false
  },
  action = { type: StudentPerformanceActionTypes }
) => {
  switch (action.type) {
    case StudentPerformanceActionTypes.SET_CLASS_SECTIONS_BY_GRADE:
      return { ...state, classSectionsByGrade: action.classSectionsByGrade };
    case StudentPerformanceActionTypes.SET_DATA:
      return { ...state, ...action.data };
    case StudentPerformanceActionTypes.SET_FILTER:
      return { ...state, ...action.filter };
    case StudentPerformanceActionTypes.SET_LOADING_MODE:
      return { ...state, loadingMode: action.loadingMode };
    default:
      return { ...state };
  }
};
