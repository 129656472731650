import * as React from 'react';
import education from './assets/education.png';
import singleCheck from './assets/single_check.png';
import { paths } from 'config/path_helpers';
import { SecondaryButton } from '@magoosh/lib/button';
import style from './style.module.scss';
import { CARSDailyQuestionState } from './types';
import Card from '@magoosh/lib/card';

interface DailyCARSQuestionProps {
  questionState: CARSDailyQuestionState;
  practiceSessionId: number;
  carsExamSectionId: number;
  locked: boolean;
}

const DailyCARSQuestion: React.FC<DailyCARSQuestionProps> = (props) => {
  const { questionState, practiceSessionId, carsExamSectionId, locked } = props;

  if (questionState == CARSDailyQuestionState.DISMISSED) {
    return null;
  }

  const renderImage = function () {
    switch (questionState) {
      case CARSDailyQuestionState.UNANSWERED:
      case CARSDailyQuestionState.STARTED:
        return <img src={education} />;
      case CARSDailyQuestionState.COMPLETED:
        return <img src={singleCheck} />;
    }
  };

  const renderText = function () {
    switch (questionState) {
      case CARSDailyQuestionState.UNANSWERED:
      case CARSDailyQuestionState.STARTED:
        return `Daily CARS practice is a proven strategy to keep you in shape for test day. Answer
          today's passage to improve your score.`;
      case CARSDailyQuestionState.COMPLETED:
        return (
          <>
            Great job on completing the daily CARS passage! You can review your results{' '}
            <a
              href={paths.examSectionReview(carsExamSectionId, {
                collapse_filters: true,
                'review[practice_session_id]': practiceSessionId
              })}>
              here
            </a>
            .
          </>
        );
    }
  };

  const renderButton = function () {
    if (locked) {
      return <SecondaryButton href={paths.mcatUpgrade()}>Upgrade</SecondaryButton>;
    }

    switch (questionState) {
      case CARSDailyQuestionState.UNANSWERED:
        return <SecondaryButton href={paths.dashboardDailyQuestion()}>Start</SecondaryButton>;
      case CARSDailyQuestionState.STARTED:
        return <SecondaryButton href={paths.dashboardDailyQuestion()}>Resume</SecondaryButton>;
      case CARSDailyQuestionState.COMPLETED:
        return null;
    }
  };

  return (
    <Card shadow className={style.dailyCARSQuestion}>
      <div className={style.dailyCARSQuestionImage}>{renderImage()}</div>
      <div className={style.dailyCARSQuestionHeader}>CARS of the Day</div>
      <div className={style.dailyCARSQuestionText}>{renderText()}</div>
      <div className={style.dailyCARSQuestionCloseButton}></div>
      <div className={style.dailyCARSQuestionButton}>{renderButton()}</div>
    </Card>
  );
};

export default DailyCARSQuestion;
