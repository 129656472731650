const breakdownSections = [
  {
    name: 'Reading: Biography',
    percentCorrect: 67
  },
  {
    name: 'Reading: Hard Sciences',
    percentCorrect: 50
  },
  {
    name: 'Reading: Social Sciences',
    percentCorrect: 44
  },
  {
    name: 'Reading: Humanities',
    percentCorrect: 28
  }
];

const scoreSections = [
  {
    name: 'Listening',
    sampleScore: 7,
    scoreRange: 'Score range: 0-9'
  },
  {
    name: 'Writing',
    sampleScore: 6,
    scoreRange: 'Score range: 0-9'
  },
  {
    name: 'Reading',
    sampleScore: 6.5,
    scoreRange: 'Score range: 0-9'
  },
  {
    name: 'Speaking',
    sampleScore: 5.5,
    scoreRange: 'Score range: 0-9'
  },
  {
    name: 'Total',
    sampleScore: 6.5,
    scoreRange: 'Score range: 0-9'
  }
];

export default {
  null: { breakdownSections, scoreSections }
};
