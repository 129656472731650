import AIPromptVersionHistoryPage from './pages/ai_prompts/version_history';

const routes = [
  {
    exact: true,
    path: '/admin/ai_prompts/:id/versions',
    component: AIPromptVersionHistoryPage
  },
  {
    exact: true,
    path: '/admin/ai_prompts/:id/versions/:versionId',
    component: AIPromptVersionHistoryPage
  }
];

export default routes;
