import * as React from "react";
import * as moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import {ControlLabel, FormGroup, HelpBlock} from "react-bootstrap";
import {Field} from "redux-form";

moment.locale('en');
momentLocalizer(moment);

interface FieldDateTimeProps {
  label?: string;
  name: string;
  value?: any;
  min?: Date;
  validate?: any;
  defaultTime?: string;
}

export class FieldDateTime extends React.Component<FieldDateTimeProps> {

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.renderDateTimePicker}
        validate={this.props.validate}
      />);

  }

  parseDateTime = value => {
    // Check if it matches a format without time, and return a value with the default time added.
    if (moment(value, ['L', 'M/D/YYYY', 'M/D/YY'], true).isValid()) {
      return new Date(`${moment(value).format('L')} ${this.props.defaultTime}`);
    }

    const valueMoment = moment(value, 'L LT');

    if (valueMoment.isValid()) {
      return valueMoment.toDate();
    }

    return null;
  };

  renderDateTimePicker = ({input: {onChange, value}, label, type, meta: {touched, error, warning}}) => {
    return (
      <FormGroup validationState={error ? 'error' : null}>
        <ControlLabel>{this.props.label}</ControlLabel>
        <DateTimePicker
          defaultCurrentDate={moment().endOf("day").toDate()}
          onChange={onChange}
          format="L LT"
          parse={this.parseDateTime}
          time={true}
          value={!value ? null : new Date(value)}
          min={this.props.min}
        />
        {touched && ((error && <HelpBlock>{error}</HelpBlock>) || (warning &&
          <HelpBlock>{warning}</HelpBlock>))}
      </FormGroup>
    );
  };
}
