import React, { useState} from 'react';
import { find, sumBy } from 'lodash';

import { SimpleBarChart } from '@magoosh/lib/charts';

import StatisticData from '../statistic_data';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  filters: any;
}

const PerformanceByDifficultyChart: React.FC<Props> = (props) => {
  const loadingPlaceholder = (
    <SimpleBarChart
      colorPalette={{ dark: colors.gray85, light: colors.grayLightest }}
      data={[
        { name: 'Easy', value: 60 },
        { name: 'Medium', value: 40 },
        { name: 'Hard', value: 20 },
        { name: 'Very Hard', value: 0 },
      ]}
      seriesNames={['Correct', 'Incorrect']}
    />
  );

  if (!props.filters) {
    return loadingPlaceholder;
  } else {
    return (
      <StatisticData
        filters={props.filters}
        name="performance_by_difficulty"
      >
        <StatisticData.Loading>
          {loadingPlaceholder}
        </StatisticData.Loading>

        {/* @ts-ignore */}
        <SimpleBarChart
          colorPalette={{ dark: colors.dataDarkPurple, light: colors.dataMutedPurple }}
          seriesNames={['Correct', 'Incorrect']}
        />
      </StatisticData>
    );
  }
};

export default PerformanceByDifficultyChart;
