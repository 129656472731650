import React, { useState } from 'react';

import { useModalContext } from '@magoosh/context/modal_context';
import { DefaultButton, DefaultFilledButton, SecondaryButton } from '@magoosh/lib/button';
import { CircleIcon, FontAwesomeIcon } from '@magoosh/lib/icons';
import PracticeSessionPreviewModal from '@magoosh/organisms/study_schedule/practice_session_preview_modal/template';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';

import { Activity } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  activity: Activity;
  detailed?: boolean;
  next?: boolean;
  source?: string;
}

const PracticeSet: React.FC<Props> = (props) => {
  const { setModal } = useModalContext();
  const [isStarting, setIsStarting] = useState(false);

  const icon = () => {
    if (isDrill()) {
      return (
        <CircleIcon colorPalette={{ dark: '#F6A351', light: '#FFD9B4' }} size={25}>
          <FontAwesomeIcon icon="bolt" />
        </CircleIcon>
      );
    } else if (props.activity.content.practiceSessionConfig?.difficulty === 'Easy') {
      return (
        <div style={{ backgroundColor: colors.brandPrimary, borderRadius: 20, height: 14, width: 14 }} />
      );
    } else if (props.activity.content.practiceSessionConfig?.difficulty === 'Medium') {
      return (
        <div style={{ backgroundColor: colors.dataDarkBlue, height: 14, width: 14 }} />
      );
    }  else if (props.activity.content.practiceSessionConfig?.difficulty === 'Hard') {
      return (
        <div className={style.blackDiamond} />
      );
    } else if (props.activity.content.practiceSessionConfig?.difficulty === 'Very Hard') {
      return (
        <div className="d-flex">
          <div className={style.blackDiamond} />
          <div className={style.blackDiamond} style={{ marginLeft: -4 }} />
        </div>
      );
    } else {
      return (
        <svg width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M7.92.935c-.96-.96-2.64-.96-3.6 0l-3.6 3.6c-.48.48-1.44 2 0 3.52l8.16 8.16H16v-7.12L7.92.935Zm1.28 13.28-5.52-5.52 1.76-1.76 5.52 5.52-1.76 1.76Zm2.96-3.04-5.44-5.44 1.76-1.76L14 9.495l-1.84 1.68ZM1.92 5.735l3.6-3.6a.87.87 0 0 1 1.2 0l.56.56-4.8 4.8-.56-.64c-.48-.48-.16-.96 0-1.12Zm9.36 8.8 3.12-3.12v3.12h-3.12Z" fill="#363437"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(0 .215)" d="M0 0h16v16H0z"/></clipPath></defs></svg>
      );
    }
  };

  const isDrill = () => {
    return props.activity.type === 'practice' && props.activity.content.type === 'drills';
  };

  const presentPracticePreviewModal = (contentBlockId) => {
    setIsStarting(true);

    fetch(paths.api.studySchedulePractice(contentBlockId), {
      method: 'POST'
    }).then((response: any) => {
      if (response.redirect) {
        window.location.assign(response.redirect);
        return;
      }

      setModal({
        body: (
          <PracticeSessionPreviewModal
            onHide={() => {
              setIsStarting(false);
              setModal(null);
            }}
            {...response.preview}
          />
        )
      });
    });
  };

  const renderStartButton = () => {
    if (!props.activity.content.questionsAvailable || props.activity.locked) {
      return (
        <DefaultButton disabled>
          {props.activity.locked && <FontAwesomeIcon icon="lock" />} Start
        </DefaultButton>
      );
    }

    const authenticityToken = useAuthenticityToken();

    const Button = props.next ? SecondaryButton : DefaultFilledButton;
    const text = props.activity.status === 'finished' ? 'Retake' : 'Start';

    if (props.activity.type === 'practice' && props.activity.content.practiceSessionConfig) {
      const config = props.activity.content.practiceSessionConfig;
      const urlParams = [
        ['practice_session[exam_section_id]', config.examSectionId.toString()],
        ['practice_session[difficulty_range_str]', config.difficultyRangeStr]
      ];

      if (config.tagIds) {
        config.tagIds.forEach((tagId) => (
          urlParams.push(['practice_session[tag_ids][]', tagId.toString()])
        ));
      }

      if (props.source) {
        urlParams.push(['practice_session[source]', props.source]);
      }

      return (
        <form method="POST" action="/practices">
          <input type="hidden" name="authenticity_token" value={authenticityToken} />
          {
            urlParams.map((paramTuple) => (
              <input key={paramTuple[0]} type="hidden" name={paramTuple[0]} value={paramTuple[1]} />
            ))
          }

          <Button block type="submit">
            {text}
          </Button>
        </form>
      );
    } else if (props.activity.type === 'practice' && props.activity.content.contentBlockId) {
      return (
        <Button
          onClick={() => presentPracticePreviewModal(props.activity.content.contentBlockId)}
          submitting={isStarting}
        >
          {text}
        </Button>
      );
    } else {
      // TODO - log unexpected case
    }
  }


  return (
    <div className="bg-white border-radius-all d-flex flex-1 flex-column gap-m u-padding-A-s" style={{ border: '1px dashed #B5B1BB' }}>
      {(() => {
        if (props.detailed && isDrill()) {
          return (
            <div className="hidden-sm hidden-xs d-flex flex-column gap-s">
              <h3 className="u-margin-A-n"><strong>Check Your Understanding!</strong></h3>
              <p>Answer these quick questions to make sure you're fluent with the concepts from the lesson video.</p>
              <hr className="u-margin-A-n" />
            </div>
          );
        }
      })()}

      <div className="d-flex flex-1 gap-m">
        <div>
          <div className="align-items-center d-flex gap-s">
            <div>{icon()}</div>
            <div><strong>{props.activity.name}</strong></div>
          </div>

          {
            props.activity.description && (
              <div>
                <div className="text-gray-light"><small>{props.activity.description}</small></div>
              </div>
            )
          }
        </div>
      </div>

      {(() => {
        if (props.next) {
          return renderStartButton();
        } else if (props.activity.status === 'finished') {
          const reviewPath = paths.answers({
            'review[practice_session_id]': props.activity.content.practiceSessionId,
            'review[study_item]': props.activity.content.contentBlockId
          });

          return (
            <div className="align-items-center d-flex gap-xs">
              <DefaultFilledButton className="flex-1" href={reviewPath}>Review</DefaultFilledButton>
              {renderStartButton()}
            </div>
            );
        } else {
          return renderStartButton();
        }
      })()}
    </div>
  );
};

export default PracticeSet;
