import * as React from 'react';
import { Annotation, EssayResponse, Evaluation } from '../types';
import style from '../style.module.scss';

interface EssayProps {
  onClickHighlight: (annotation: Annotation) => any;
  mobileMode: boolean;
  evaluation?: Evaluation;
  text: string;
}

// This class assumes the annotations are already sorted by startIndex
export default class Essay extends React.Component<EssayProps> {
  textIndex = 0;
  componentWillUpdate() {
    this.textIndex = 0;
  }

  render() {
    return (
      <div className={style.essay}>
        {this.props.evaluation && this.props.evaluation.annotations.map(this.renderEssayChunk.bind(this))}
        {this.props.text.slice(this.textIndex)}
      </div>
    );
  }

  // Render the next part of the essay, up to and including the annotated text.
  // Has a side-effect of incrementing the textIndex.
  renderEssayChunk(annotation: Annotation, index: number) {
    const chunk = (
      <React.Fragment key={annotation.startIndex}>
        {this.props.text.slice(this.textIndex, annotation.startIndex)}
        <a onClick={() => this.props.onClickHighlight(annotation)} className={style.highlightedText}>
          <mark>{this.props.text.slice(annotation.startIndex, annotation.endIndex)}</mark>
          {this.renderHighlightLabel(index)}
        </a>
      </React.Fragment>
    );

    this.textIndex = annotation.endIndex;
    return chunk;
  }

  renderHighlightLabel(index: number) {
    return (
      <div className={style.highlightNumberContainer}>
        {this.props.mobileMode ? (
          <small className={style.highlightLabel}>[read commentary]</small>
        ) : (
          <span className={style.highlightNumber}>{index + 1}</span>
        )}
      </div>
    );
  }
}
