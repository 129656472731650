import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { compact, sortBy } from 'lodash';
import moment from 'moment';

import { DangerButton, DefaultFilledButton, PrimaryButton, ResponsiveButtonGroup } from '@magoosh/lib/button';
import FieldCombobox from '@magoosh/lib/formik_inputs/field_combobox';
import { DateSelect } from '@magoosh/lib/forms';
import { paths } from 'config/path_helpers';

import { ApplicationCycleDeadline } from '@admissions/types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  applicationCycle: string;
  deadline: ApplicationCycleDeadline;
  onClose: () => void;
  onSave: (deadline: ApplicationCycleDeadline) => Promise<any>;
  otherDeadlines: ApplicationCycleDeadline[];
}

const ModalAddEditDeadline: React.FC<Props> = (props) => {
  const [isRemovingDeadline, setIsRemovingDeadline] = useState<boolean>(false);

  const newDeadlineDefaults = {
    confirmRemovingDeadline: false,
    dueAt: null,
    id: null,
    program: null
  };

  const onSubmit = (values, { setErrors, setFieldError, setSubmitting }) => {
    setSubmitting(true);

    const deadlineValues = values as ApplicationCycleDeadline;

    if (isRemovingDeadline) {
      deadlineValues.deletedAt = moment().utc().format('YYYY-MM-DDThh:mm:ss.SSSZ');
      props.onSave(deadlineValues).then(() => {
        props.onClose();
      });
    } else {
      props.onSave(deadlineValues).then(() => {
        props.onClose();
      }).catch((error) => {
        setSubmitting(false);

        setErrors(error.errors);
        if (error.errors._error) {
          setFieldError('general', error.errors._error);
        }
      });
    }
  };

  const readyToSubmit = (values) => {
    if (isRemovingDeadline) return values.confirmRemovingDeadline;
  };

  return (
    <Formik initialValues={props.deadline || newDeadlineDefaults} onSubmit={onSubmit}>
      {({ errors, values, isSubmitting, setFieldValue }) => (
        <Form>
          {
            errors.general && (
               <div
                className="small u-padding-A-xs"
                style={{
                  backgroundColor: colors.redLight,
                  borderColor: colors.red,
                  borderWidth: '0 0 2px 0',
                  borderStyle: 'solid',
                  color: colors.red
                }}
              >
                {errors.general}
              </div>
            )
          }

          <div className="d-flex flex-column" style={{ gap: 20 }}>
            {
              isRemovingDeadline
                ? <RemoveFormFields values={values} />
                : <AddEditFormFields
                    deadline={props.deadline}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
            }

            <Notice
              deadline={props.deadline}
              isRemovingDeadline={isRemovingDeadline}
              otherDeadlines={props.otherDeadlines}
              values={values}
            />

            <ResponsiveButtonGroup>
              {
                isRemovingDeadline ? (
                  <DangerButton
                    disabled={isSubmitting || !readyToSubmit(values)}
                    submitting={isSubmitting}
                    type="submit"
                  >
                    Remove Deadline
                  </DangerButton>
                ) : (
                  <PrimaryButton submitting={isSubmitting} type="submit">
                    {props.deadline ? 'Save' : 'Add'} Deadline
                  </PrimaryButton>
                )
              }

              <DefaultFilledButton onClick={props.onClose}>
                Cancel
              </DefaultFilledButton>
            </ResponsiveButtonGroup>

            {
              props.deadline && (
                isRemovingDeadline ? (
                  <a className="small text-gray-light" onClick={() => !isSubmitting && setIsRemovingDeadline(false)}>back to edit</a>
                ) : (
                  <a className="small text-danger" onClick={() => !isSubmitting && setIsRemovingDeadline(true)}>remove deadline</a>
                )
              )
            }
          </div>
        </Form>
      )}
    </Formik>
  );
};

interface AddEditFormFieldsProps {
  deadline: ApplicationCycleDeadline;
  setFieldValue: any;
  values: ApplicationCycleDeadline;
}

const AddEditFormFields: React.FC<AddEditFormFieldsProps> = (props) => {
  return (
    <>
      <div>
        <FieldCombobox
          defaultValue={props.values.programName}
          label="Program"
          name="programName"
          searchEndpoint={paths.api.admissions.programsSearch}
          textField="name"
          valueField="name"
        />
      </div>

      <div>
        <label>Deadline</label>
        <DateSelect
          className="d-flex"
          onChange={(date: string) => props.setFieldValue('dueAt', date)}
          selectClassName="flex-1 u-margin-B-n"
          style={{ gap: 10 }}
          value={props.values.dueAt}
        />
        <div className="small text-gray-light u-margin-T-xxs">Enter the deadline for the round you currently intend to apply. You can always edit this date if you choose to submit your application later.</div>
      </div>
    </>
  );
};

const RemoveFormFields: React.FC<{ values: ApplicationCycleDeadline }> = (props) => {
  return (
    <div>
      <strong>Are you sure you want to remove your {props.values.programName} deadline?</strong>
      <div className="align-items-center d-flex u-margin-T-xs" style={{ gap: 5 }}>
        <Field
          name="confirmRemovingDeadline"
          type="checkbox"
          id="confirmRemovingDeadline"
          style={{ margin: 0 }}
        />
        <label className="u-margin-A-n" htmlFor="confirmRemovingDeadline" style={{ fontWeight: 400 }}>
          Yes, remove this deadline
        </label>
      </div>
    </div>
  );
};

interface NoticeProps {
  deadline: ApplicationCycleDeadline;
  otherDeadlines: ApplicationCycleDeadline[];
  isRemovingDeadline: boolean;
  values: ApplicationCycleDeadline;
}

const Notice: React.FC<NoticeProps> = (props) => {
  const notices = [];
  const existingEarliestDeadline = sortBy(compact([...props.otherDeadlines, props.deadline]), (d) => d.dueAt)[0];

  if (props.isRemovingDeadline) {
    // Removing the earliest deadline and no other deadlines remaining
    if (existingEarliestDeadline.id === props.deadline.id && props.otherDeadlines.length) {
      const newEarliestDeadline = sortBy(props.otherDeadlines, (d) => d.dueAt)[0];

      notices.push(`This will make ${moment(newEarliestDeadline.dueAt).format('MMMM D, YYYY')} ${newEarliestDeadline.programName ? `(${newEarliestDeadline.programName})` : ''} your new earliest deadline. We will update your "All Program" tasks to reflect the new deadline.`);
    // Removing the earliest deadline,but there is another deadline remaining
    } else if (existingEarliestDeadline.id === props.deadline.id) {
      notices.push(`Since this is your only deadline we'll update your "All Program" tasks with generic deadlines, but won't be able to give specific target due dates.`);
    }
  } else {
    const dueAtChanged = props.deadline?.dueAt && props.deadline.dueAt !== props.values.dueAt;
    const newEarliestDeadline = sortBy([...props.otherDeadlines, props.values], (d) => d.dueAt)[0];

    // Existing deadline and the dueAt has changed
    if (dueAtChanged) {
      notices.push(`We'll update the tasks for ${props.deadline.programName ? props.deadline.programName : 'this program'} to reflect the new deadline.`);
    }

    if (existingEarliestDeadline?.id !== newEarliestDeadline.id) {
      // This will be the new earliest deadline
      if (newEarliestDeadline === props.values) {
        notices.push(`This will be your new earliest deadline. We will update your "All Program" tasks to reflect the new deadline.`);
      // Thhis change will change the overall earliest deadline
      } else {
        notices.push(`This will make ${moment(newEarliestDeadline.dueAt).format('MMMM D, YYYY')} ${newEarliestDeadline.programName ? `(${newEarliestDeadline.programName})` : ''} your new earliest deadline. We will update your "All Program" tasks to reflect the new deadline.`);
      }
    // This is (and still is) the earliest deadline and the dueAt is changing
    } else if (dueAtChanged && props.deadline.id === existingEarliestDeadline.id) {
      notices.push(`Since this is your earliest deadline we'll update your "All Program" tasks to reflect the new deadline.`);
    }
  }

  return (
    <div className="d-flex flex-column" style={{ gap: 10 }}>
      {
        notices.map((notice, idx) => (
          <div
            key={idx}
            className="small u-padding-A-xs"
            style={{
              backgroundColor: props.isRemovingDeadline ? colors.redLight : colors.greenLight,
              borderColor: props.isRemovingDeadline ? colors.red : colors.green,
              borderWidth: '0 0 2px 0',
              borderStyle: 'solid',
              color: props.isRemovingDeadline ? colors.red : colors.green
            }}
          >
            {notice}
          </div>
        ))
      }
    </div>
  )
};

export default ModalAddEditDeadline;
