import * as React from 'react';
import { Field } from 'formik';

interface Props {
  className?: string;
}

export default class PasswordShowHide extends React.Component<Props> {
  state = {
    showPassword: false
  };

  render() {
    return (
      <div className={this.props.className}>
        <Field
          type={this.state.showPassword ? 'text' : 'password'}
          name="password"
          autoComplete="new-password"
          className="form-control"
        />
        <button onClick={this.togglePassword} type="button">
          {this.state.showPassword ? 'Hide' : 'Show'}
        </button>
      </div>
    );
  }

  togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
}
