import * as React from 'react';
import {Alert} from "react-bootstrap";
import style from './style.module.scss';
import cx from 'classnames';

interface PopupBannerProps {
  bsStyle: 'success' | 'warning' | 'info' | 'danger';
  children: React.ReactNode;
  onSelfDestruct: () => any;
  className?: string;
}

export default class PopupBanner extends React.Component<PopupBannerProps> {
  state = {
    hidden: true
  };

  componentDidMount(): void {
    setTimeout(() => this.setState({hidden: false}), 1);
    setTimeout(() => this.setState({hidden: true}), 5000);
    setTimeout(this.props.onSelfDestruct, 5500);
  }

  render() {
    return <div className={cx(style.wrapper, this.state.hidden ? style.hidden : '', this.props.className)}>
      <Alert bsStyle={this.props.bsStyle}>
        {this.props.children}
      </Alert>
    </div>
  }
}
