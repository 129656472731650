import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { truncate } from 'lodash';

import { DefaultFilledButton, PrimaryButton } from '@magoosh/lib/button';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import DraftPassagePreview from './draft_passage_preview';
import { PromptRequest } from './types';

interface Props {
  draftPassage: string;
  onAccept: (draftPassage: string) => void;
  onClose: () => void;
  promptRequest: PromptRequest;
}

const RequestPassageEditsModal: React.FC<Props> = (props) => {
  const [contentRequest, setContentRequest] = useState(null);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const poller = useRef(null);

  useEffect(() => {
    return () => {
      poller.current && clearTimeout(poller.current);
    };
  }, []);

  const accept = () => {
    setIsAccepting(true);

    const path = adminPaths.api.promptRequestContentRequestAccept(
      props.promptRequest.id,
      contentRequest.id
    );

    fetch(path, {
      method: 'POST'
    }).then((response) => {
      props.onAccept(contentRequest.content.passage);
      props.onClose();
    }).catch((error) => {
      setIsAccepting(false);
      alert(error.errors._error);
    });
  };

  const fetchContentRequest = (contentRequestId) => {
    return fetch(adminPaths.api.promptRequestContentRequest(
      props.promptRequest.id,
      contentRequestId
    ));
  };

  const handleSubmit = (values, { setFieldValue, setSubmitting, setErrors }) => {
    setIsWorking(true);

    fetch(adminPaths.api.promptRequestContentRequestCreate(props.promptRequest.id), {
      method: 'POST',
      body: JSON.stringify({
        contentRequest: {
          ...values,
          contentType: 'assisted_edit',
          parentId: contentRequest?.parentId
        }
      })
    }).then((response) => {
      setFieldValue('instructions', '')
      setContentRequest(response.contentRequest);
      pollForUpdates(response.contentRequest.id)
    }).catch((error) => {
      setErrors(error.errors);
      setIsWorking(false);
    });
  };

  const isActive = (status) => {
    if (status === 'validating') return false;
    if (status === 'finished') return false;
    if (status === 'error') return false;
    if (status === 'cancelled') return false;

    return true;
  };

  const pollForUpdates = (contentRequestId) => {
    poller.current = setTimeout(() => {
      fetchContentRequest(contentRequestId).then((resp) => {
        setContentRequest(resp.contentRequest);

        if (isActive(resp.contentRequest.status)) {
          pollForUpdates(contentRequestId);
        } else {
          setIsWorking(false);
        }
      });
    }, 2000);
  };

  const toggleDetails = (id: number) => {
    if (showDetails[id]) {
      setShowDetails({ ...showDetails, [id]: false });
    } else {
      setShowDetails({ ...showDetails, [id]: true });
    }
  };

  const initialValues = {
    instructions: ''
  };

  return (
    <div>
      {
        contentRequest && (
          isWorking ? (
            <div className="well well-hollow">Working...</div>
          ) : (
            <DraftPassagePreview
              passage={contentRequest.content.passage}
              promptRequest={props.promptRequest}
              readOnly
            />
          )
        )
      }

      <div className="d-flex flex-column gap-s u-margin-T-s">
        {
          (contentRequest?.instructionsHistory || []).map((manualEdit) => (
            <div key={manualEdit.id} className="d-flex gap-s">
              <div className="flex-1">
                {'>'} {showDetails[manualEdit.id] ? manualEdit.instructions : truncate(manualEdit.instructions, { length: 100 })}
              </div>

              <div>
                <a className="text-gray-light" onClick={() => toggleDetails(manualEdit.id)}>
                  <small>{showDetails[manualEdit.id] ? 'Hide' : 'Show'}</small>
                </a>
              </div>
            </div>
          ))
        }
      </div>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ dirty, errors, isSubmitting }) => (
          <Form>
            <FieldInput name="instructions" style={{ height: 200 }} type="textarea" />

            <div className="d-flex gap-s">
              <div className="flex-1">
                <DefaultFilledButton type="submit" submitting={isWorking}>Request</DefaultFilledButton>
              </div>

              <div>
                <PrimaryButton
                  disabled={!contentRequest?.content || isWorking}
                  onClick={accept}
                  submitting={isAccepting}
                >
                  Accept
                </PrimaryButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestPassageEditsModal;
