import * as React from 'react';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { adminPaths, paths } from 'config/path_helpers';
import { useMemo } from 'react';
import { DataTable } from '@magoosh/lib/data_table';
import { PrimaryButton } from '@magoosh/lib/button';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Col, Row } from 'react-bootstrap';
import DropdownList from 'react-widgets/lib/DropdownList';
import { Search } from '@magoosh/lib/data_table/search';

const GradingRequestsIndex: React.FC = () => {
  const defaultQueryParams = useMemo(() => {
    return { requestType: 'interview' };
  }, []);

  const { runQuery, dataQueryTableProps } = useDataQuery(adminPaths.api.gradingRequests, defaultQueryParams);

  return (
    <div>
      <h1>Grading Requests</h1>
      <DataTable
        {...dataQueryTableProps}
        columns={[
          {
            name: 'User',
            selector: 'userDescriptor',
            cell: (row) => {
              let path;
              if (row.requestType === 'essay') {
                path = adminPaths.answer(row.answerId);
              } else {
                path = adminPaths.gradingRequest(row.id);
              }
              return (
                <div>
                  <a href={path}>{row.userDescriptor}</a>
                </div>
              );
            }
          },
          { name: 'Question', selector: 'questionTitle' },
          {
            name: 'Exam Section',
            selector: 'examSection'
          },
          {
            name: 'Created At',
            selector: 'createdAt'
          }
        ]}>
        <DataTable.HeaderActions>
          <PrimaryButton icon={'plus'} href={adminPaths.newGradingRequest()}>
            New
          </PrimaryButton>
        </DataTable.HeaderActions>

        <DataTable.Filters>
          <div className="filters" style={{ width: '800px' }}>
            <Row>
              <Col md={4}>
                <DropdownList
                  data={[
                    { name: 'Interviews', value: 'interview' },
                    { name: 'Essays', value: 'essay' },
                    { name: 'Admissions Writing', value: 'written_material' }
                  ]}
                  onChange={(selection) => {
                    runQuery({ requestType: selection.value });
                  }}
                  placeholder="Filter by request type"
                  textField="name"
                  valueField="value"
                  defaultValue="interview"
                  dataKey="value"
                />
              </Col>
              <Col md={4}>
                <Search runQuery={runQuery} placeholder="Search user email..." />
              </Col>
            </Row>
          </div>
        </DataTable.Filters>
      </DataTable>
    </div>
  );
};
export default () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <GradingRequestsIndex />
    </QueryClientProvider>
  );
};
