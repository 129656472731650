import * as React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

export const Breadcrumbs: React.FC = () => {
  return (
    <>
      <div className={style.breadcrumbs}>
        <strong>
          <Link to="/">Portal</Link>&nbsp;&gt;&nbsp;
          <Link to="/">Class Name</Link>&nbsp;&gt;&nbsp;{' '}
        </strong>
        Enroll Students
      </div>
    </>
  );
};
