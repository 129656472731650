import * as React from 'react';
import { AnswerChoiceData, GuidedQuizPromptData } from './types';
import RadioWithLabel from './radio_with_label';
import BaseButton from '@magoosh/lib/button/base';
import style from './style.module.scss';
import HintPopup from './hint_popup';

interface GuidedQuizStepProps extends GuidedQuizPromptData {
  onClick: (choice: AnswerChoiceData) => void;
  selected?: AnswerChoiceData;
}

export default class GuidedQuizStep extends React.Component<GuidedQuizStepProps> {
  state = {
    showHint: false,
    showExplanation: false
  };

  render() {
    return (
      <div className={'u-margin-L-m'}>
        <div dangerouslySetInnerHTML={{ __html: this.props.prompt }} />
        <div className={style.answerChoices}>
          {this.props.choices.map((choice, index) => (
            <RadioWithLabel
              id={`choice${index}`}
              isChecked={this.props.selected === choice}
              isCorrect={choice.correct}
              isDisabled={false}
              key={index}
              name={'answer-choice'}
              onSelect={() => this.onSelectAnswer(choice)}
              showGrade={false}
              text={choice.value}
            />
          ))}
        </div>

        {this.state.showExplanation && this.props.selected && (
          <HintPopup
            text={this.props.selected.explanation}
            type={this.props.selected.correct ? 'success' : 'warning'}
            onClick={() => this.setState({ showExplanation: false })}
          />
        )}
        {this.state.showHint && (
          <HintPopup
            text={this.props.hint}
            type={'default'}
            onClick={() => this.setState({ showHint: false })}
          />
        )}

        {this.renderHintButton()}
      </div>
    );
  }

  renderHintButton = () => {
    if (this.props.hint) {
      return (
        <div className={style.hintButtonContainer}>
          <BaseButton onClick={this.showHint}>Need a Hint?</BaseButton>
        </div>
      );
    }
  };
  showHint = () => {
    this.setState({ showHint: true });
  };

  onSelectAnswer = (choice: AnswerChoiceData) => {
    this.setState({ showExplanation: true, showHint: false });
    this.props.onClick(choice);
  };
}
