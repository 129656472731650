import React from 'react';
import { paths } from 'config/path_helpers';
import { CheckpointCardProps } from '@magoosh/gre/organisms/checkpoint_card/types';
import { Checkpoint } from './types';
import CheckpointCard from '@magoosh/gre/organisms/checkpoint_card';
import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  checkpoint: Checkpoint;
  testDate: string;
}

const TestDayCheckpoint: React.FC<Props> = ({ checkpoint, testDate }) => { 
  const primaryCTA = {
    text: 'Read Our Tips',
    variant: 'default',
    href: paths.tips(),
    target: '_blank'
  };

  const secondaryCTA = (
    <a className="u-margin-H-xs" style={{ color: colors.grayDarker }} href={paths.profileEdit()}>
      Change Date in Profile
    </a>
  );

  const checkpointProps: CheckpointCardProps = {
    children: "You're in the final push before your test date! You’ve already put in a lot of hard work studying, so focus on making sure you’re reviewing your weakest high and moderate significance topics. And make sure you get some rest before the big day. You got this!",
    date: testDate,
    estimatedTime: '4 Hours',
    headline: checkpoint.displayName,
    iconType: 'CalendarStar',
    primaryCTA,
    secondaryCTA
  };

  const checkpointIsComplete = checkpoint.mockTestId && !checkpoint.running;
  const checkpointIsInProgress = checkpoint.running;
  const checkpointIsAccessLocked = checkpoint.locked;

  if (checkpointIsComplete) {
    checkpointProps.primaryCTA = {
      text: 'Review',
      variant: 'default',
      href: paths.practiceTest(checkpoint.mockTestId)
    };
  } else if (checkpointIsInProgress) {
    checkpointProps.primaryCTA = {
      text: 'Continue',
      variant: 'default',
      href: paths.practiceTest(checkpoint.mockTestId)
    };
  } else if (checkpointIsAccessLocked) {
    checkpointProps.primaryCTA = {
      text: 'Preview',
      variant: 'default',
      href: paths.startPracticeTest()
    };
  } else {
    checkpointProps.primaryCTA = {
      text: 'Start',
      variant: 'default',
      href: paths.startPracticeTest()
    };
  }

  return (
    <CheckpointCard {...checkpointProps} />
  );
};

export default TestDayCheckpoint;
