import CalypsoTemplate from './template';
import { connect } from 'react-redux';
import { toggleStudyPreferencesModal } from './actions';
import { RootStore } from '@magoosh/store';

function mapStateToProps(store: RootStore) {
  return {
    shouldShowModal: store.studyPreferences.studyPreferencesModalIsOpen,
    modalStartStep: store.studyPreferences.modalStartStep
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onCloseModal: () => {
      dispatch(toggleStudyPreferencesModal(2));
    },
    onOpenModal: () => {
      dispatch(toggleStudyPreferencesModal(2));
    },
    ...ownProps
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalypsoTemplate);
