import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';

import { ModalProvider } from '@magoosh/context/modal_context';
import configureStore from '@magoosh/store';

import { ActionTypes } from '../actions';
import StudyItemList from '../study_item_list/';
import { StudyScheduleStore } from '../types';

export interface SimpleStudyScheduleProps extends StudyScheduleStore {
  itemLimit?: number; // Show only this many items
  incompleteItemsOnly: boolean; // Show only items that have not been marked off
  excludeItem: number; // A block ID to exclude from the list
}

// A component for rendering only the items in the current study schedule,
// without controls for changing the schedule or day.
class SimpleStudySchedule extends React.Component<SimpleStudyScheduleProps> {
  store = configureStore();

  constructor(props) {
    super(props);
    this.store.dispatch({ type: ActionTypes.INITIALIZE, data: props });
  }

  render() {
    return (
      <Provider store={this.store}>
        <ModalProvider>
          <StudyItemList
            studyItems={this.props.currentStudySchedule.currentDay.items}
            excludeItem={this.props.excludeItem}
            itemLimit={this.props.itemLimit}
            incompleteItemsOnly={this.props.incompleteItemsOnly}
            examName={this.props.userProfile.examName}
            popoverPlacement={'bottom'}
          />
        </ModalProvider>
      </Provider>
    );
  }
}

export default hot(SimpleStudySchedule);
