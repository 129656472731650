import * as React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { PrimaryButton } from '@magoosh/lib/button';
import style from './style.module.scss';
import { paths } from 'config/path_helpers';

interface DiagnosticAssessmentProps {
  lowestScore: number;
  examSectionName: string;
  examSectionId: number;
}

export default class DiagnosticAssessment extends React.Component<DiagnosticAssessmentProps> {
  render() {
    return (
      <Grid>
        <Row>
          <Col md={9}></Col>
          <Col md={3} className="float-right u-margin-T-m">
            <a href={paths.dashboard()}>Return to Dashboard</a>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <h1>Your Diagnostic Results</h1>
          </Col>
        </Row>
        <Row className="u-margin-T-m">
          <Col md={2} />
          <Col md={8}>
            {this.props.lowestScore <= 0.75 && (
              <h3 className="text-center">
                Congratulations on finishing the diagnostic! We recommend that you spend most of your time on
                the {this.renderPracticeSessionLink()} section for the greatest score improvement.
              </h3>
            )}
            {this.props.lowestScore > 0.75 && (
              <h3 className="text-center">
                You are already strong across exam sections, that's great! We recommend that you spend most of
                your time on the {this.renderPracticeSessionLink()} section for the greatest score
                improvement.
              </h3>
            )}
          </Col>
        </Row>
        <Row className="text-center u-margin-T-xl u-margin-B-m">
          <PrimaryButton
            className="u-margin-R-xs"
            href={paths.examSectionPracticeSession(this.props.examSectionId)}>
            Practice {this.props.examSectionName}
          </PrimaryButton>
          <PrimaryButton className={style.mobileButton} href={paths.diagnosticAnswers()}>
            Review Answers and Explanations
          </PrimaryButton>
        </Row>
      </Grid>
    );
  }

  renderPracticeSessionLink = () => {
    return (
      <a
        className={style.examSectionName}
        href={paths.examSectionPracticeSession(this.props.examSectionId)}>
        {this.props.examSectionName}
      </a>
    );
  };
}
