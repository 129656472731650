import React, { useState } from 'react';
import moment from 'moment-timezone';

import CardLayout from '@magoosh/layouts/card';
import { LightBulbPlantIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';

import { useAppContext } from '@test_prep/app/context';
import ContextualLink from '@test_prep/app/components/contextual_link';

import AnswersTable from './answers_table';
import PerformanceBreakdown from './performance_breakdown';
import { ExamSectionDetail, ScoreReport } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  mockTest: {
    examSectionDetails: ExamSectionDetail[];
    finishedAt: number;
    template: {
      name: string;
    };
  };
}

const OfficialQuestionSetResults: React.FC<Props> = (props) => {
  const examSection = props.mockTest.examSectionDetails[0];
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  return (
    <div className="d-flex flex-column gap-m">
      <div className="d-flex gap-m">
        <CardLayout.Card className="flex-1">
          <h1 className="h2 u-margin-A-n u-padding-B-xxs" style={{ lineHeight: 1.3 }}>
            <strong>Official Questions Results</strong>
          </h1>

          <div>
            <small>
              <strong>{props.mockTest.template.name}</strong>
            </small>
          </div>

          <div>
            <small>
              Finished {
                moment(props.mockTest.finishedAt * 1000).tz(timeZone).isSame(moment().tz(timeZone), 'day')
                  ? 'Today'
                  : moment(props.mockTest.finishedAt * 1000).tz(timeZone).fromNow()
              }
            </small>
          </div>
        </CardLayout.Card>

        <ReviewPerformanceNextStep mockTest={props.mockTest} />
      </div>

      <div className="d-flex flex-column-reverse flex-md-column gap-s">
        <CardLayout.Card>
          <div>
            {
              examSection.performanceBreakdown && (
                <>
                  <div><strong>{examSection.name} Performance Breakdown</strong></div>
                  <small>Note: Some questions can appear in multiple categories</small>

                  <div className="u-margin-T-m">
                    <PerformanceBreakdown { ...examSection } />
                  </div>

                  <hr />
                </>
              )
            }
          </div>

          <div>
            <AnswersTable { ...examSection } />
          </div>
        </CardLayout.Card>
      </div>
    </div>
  );
};

const ReviewPerformanceNextStep: React.FC<{ mockTest: any }> = (props) => {
  const { features } = useAppContext().data;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  return (
    <CardLayout.Card className="d-flex flex-1" style={{ gap: 30 }}>
      <div>
        <LightBulbPlantIcon fill={colors.purple} height={80} width={80} />
      </div>

      <div>
        <div>
          <small className="text-gray-light"><strong>NEXT STEPS</strong></small>
        </div>

        <div className="text-gray-darker u-margin-V-xxs">
          <strong>Review Your Performance</strong>
        </div>

        <div className="text-gray-light">
          Review your performance breakdown below to find areas where you can improve. For questions you got wrong watch our expert video explanations to see how to approach the question next time.
        </div>

        {
          features.analytics && (
            <div className="align-items-center d-flex gap-s u-margin-T-s">
              <div>
                <span
                  style={{
                    backgroundColor: colors.purpleLight,
                    borderRadius: 20,
                    color: colors.purple,
                    fontSize: 12,
                    padding: '2px 10px'
                  }}
                >
                  <strong>BETA</strong>
                </span>
              </div>

              <div className="small">
                <ContextualLink
                  href={paths.analytics({ focus: `Official Questions - ${moment(props.mockTest.finishedAt * 1000).tz(timeZone).format('MMM D')}:${props.mockTest.id}` })}
                  style={{ color: colors.purple }}
                >
                  Take a deep dive into your analytics
                </ContextualLink>
              </div>
            </div>
          )
        }
      </div>
    </CardLayout.Card>
  );
};

export default OfficialQuestionSetResults;
