import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import styles from './style.module.scss';
import Card from '@magoosh/lib/card';
import Icon from '../../assets/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import colors from '@magoosh/b2b/app/assets/style.module.scss';
import { BorderlessButton, LinkButton, SecondaryButton } from '@magoosh/lib/button';
import { DataTable } from '@magoosh/lib/data_table';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import StatsBlock from '@magoosh/b2b/app/lib/stats_block';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Spinner } from '@magoosh/b2b/app/lib/spinner';
import { ResetSubmissionModal } from '@magoosh/b2b/app/organisms/reset_submission_modal';
import { InlineEditable } from '@magoosh/b2b/app/lib/inline_editable';

export const Assignment: React.FC = (props) => {
  const { assignmentId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [assignment, setAssignment] = useState(null);
  const [resettingSubmission, setResettingSubmission] = useState(null);

  useEffect(() => {
    loadAssignment();
  }, []);

  const loadAssignment = () => {
    if (assignment) {
      setAssignment(null);
    }

    fetch(paths.api.partner.assignment(assignmentId)).then((response) => {
      setAssignment(response);
    });
  }

  const defaultQueryParams = { sortColumn: 'fullName', filters: {} };
  const { queryParameters, dataQueryTableProps, runQuery } = useDataQuery(
    (queryParameters) => paths.api.partner.assignmentStudents(assignmentId, queryParameters),
    defaultQueryParams
  );

  if (!assignment) { return <Spinner />; }

  const {
    title, dueDate, lessonCount, estimatedTime, questionPromptsCount, quizCount, content,
    submittedCount, assignedCount, accuracyPercentage, classSectionId, classSectionName
  } = assignment;

  const studentIcon = <Icon iconType="Student" fill={colors.supplementaryRainforest} size="m" />;

  return (
    <>
      <div className={styles.breadcrumbs}>
        <strong>
          <Link to="/">Portal</Link>&nbsp;&gt;&nbsp;<Link to={url}>{title}</Link>
        </strong>
      </div>
      <h2>
        {title}
      </h2>
      <Card shadow className={styles.assignmentInfo}>
        <div className={styles.icon}>
          <Icon iconType="Assignment" fill={colors.supplementaryAzure} />
        </div>
        <div className={styles.editButton}>
          <LinkButton onClick={() => history.push(`/assignments/${assignmentId}/edit`)}>
            Edit Assignment
          </LinkButton>
        </div>
        <div className={styles.assignee}>
          <label>Assigned to:</label>
          <span>{classSectionId ? <Link to={`/classes/${classSectionId}/class-assignments`}>{classSectionName}</Link> : 'Individual students'}</span>
        </div>
        <div className={styles.content}>
          <label>Content:</label>
          <span>{content}</span>
        </div>
        <div className={styles.dueDate}>
          <label>Due date:</label>
          <span>{dueDate && moment(dueDate).format('L')}</span>
        </div>
        <div className={styles.videoLessons}>
          <label>Video lessons:</label>
          <span>{lessonCount}</span>
        </div>
        <div className={styles.estimatedTime}>
          <label>Estimated Time:</label>
          <span>{estimatedTime}</span>
        </div>
        <div className={styles.practiceQuestions}>
          <label>Practice Questions:</label>
          <span>{questionPromptsCount}</span>
        </div>
        <div className={styles.hasQuiz}>
          <label>Quizzes:</label>
          <span>{quizCount}</span>
        </div>
      </Card>
      <div className={styles.assignmentStudentsDataTable}>
        <DataTable
          {...dataQueryTableProps}
          primaryAttribute={{ selector: 'fullName', name: 'Student', sortable: true }}
          secondaryAttributeSelector="email"
          linkBaseUrl="/students/"
          columns={[
            {
              selector: 'videosCount',
              name: 'Videos',
              sortable: true
            },
            {
              selector: 'quizStatus',
              name: 'Quiz Status',
              sortable: false,
              format: (student) => {
                return <>
                  {student['completedQuizzes']} completed
                  <br />
                  {student['passedQuizzes']} passed
                </>
              }
            },
            {
              selector: 'promptsAnsweredCount',
              name: 'Practice Questions',
              sortable: true,
              format: (student) => `${student['promptsAnsweredCount']}/${questionPromptsCount}`
            },
            {
              selector: 'accuracy',
              name: 'Accuracy',
              sortable: true,
              format: (assignment) => {
                if (assignment.accuracy) {
                  return `${Math.round(assignment.accuracy * 100)}%`;
                }
              }
            },
            {
              selector: 'grade',
              name: 'Grade',
              sortable: true,
              format: (student) => {
                if (student['userAssignmentId']) {
                  return (
                    <InlineEditable
                      value={student['grade']}
                      placeholder='Not graded'
                      patchUrl={paths.api.gradeAssignment(student['userAssignmentId'])}
                      name='grade'
                      type='number'
                      min={0}
                      max={100}
                    />
                  )
                }
              }
            },
            {
              selector: 'assignmentState',
              name: 'Status',
              sortable: true,
              style: { textTransform: 'capitalize' }
            }
          ]}>
          <DataTable.HeaderActions>
            <DownloadButton
              queryParameters={queryParameters}
              fetchUrl={(queryParameters) => paths.api.partner.assignmentStudents(assignmentId, queryParameters)}
              fileName={`${assignment.title}.csv`}
            />
          </DataTable.HeaderActions>
          <DataTable.StatsBlock>
            <StatsBlock>
              <StatsBlock.Stats>
                <div>
                  <div>{submittedCount || '0'}</div>
                  <div>Submitted</div>
                </div>
                <div>
                  <div>{assignedCount || '0'}</div>
                  <div>Assigned</div>
                </div>
                <div>
                  <div>{accuracyPercentage || '0'}%</div>
                  <div>Accuracy</div>
                </div>
              </StatsBlock.Stats>
            </StatsBlock>
          </DataTable.StatsBlock>
          <DataTable.ItemIcon>{(student) => studentIcon}</DataTable.ItemIcon>
          <DataTable.ItemActions>
            {(student) => {
              return student.assignmentState == 'submitted' && <BorderlessButton
                title={'Reset assignment'}
                icon="undo"
                onClick={() => {
                  setResettingSubmission({
                    assignment: assignment,
                    student: student,
                    userAssignmentId: student.userAssignmentId
                  })
                }}
                size={'sm'}
              >
                Reset
              </BorderlessButton>
            }}
          </DataTable.ItemActions>
        </DataTable>
      </div>
      <ResetSubmissionModal
        assignment={resettingSubmission && resettingSubmission.assignment}
        student={resettingSubmission && resettingSubmission.student}
        userAssignmentId={resettingSubmission && resettingSubmission.userAssignmentId}
        onCancel={() => {
          setResettingSubmission(null);
        }}
        onResetAssignmentComplete={() => {
          loadAssignment();
          runQuery();
          setResettingSubmission(null);
        }}
      />
    </>
  );
};
