import React from 'react';
import cx from 'classnames';

import MagooshLogoWhite from '@schools/app/assets/magoosh-logo-white.png';
import style from './style.module.scss';

interface Props {}

const Footer: React.FC<Props> = (props) => {
  return (
    <div className={cx(style.footer, 'u-padding-V-xl')}>
      <div className="container">
        <div>
          <a href="https://magoosh.com">
            <img alt="Magoosh Logo" src={MagooshLogoWhite} style={{ height: 'auto', width: 166 }} />
          </a>
        </div>

        <nav className={cx(style.footerNavigation, 'd-flex')}>
          <div className={style.footerNavigationSection}>
            <strong>Our Products</strong>
            <ul>
              <li><a href="https://gre.magoosh.com" target="">GRE Prep</a></li>
              <li><a href="https://gmat.magoosh.com" target="">GMAT Prep</a></li>
              <li><a href="https://toefl.magoosh.com" target="">TOEFL Prep</a></li>
              <li><a href="https://sat.magoosh.com" target="">SAT Prep</a></li>
              <li><a href="https://act.magoosh.com" target="">ACT Prep</a></li>
              <li><a href="https://praxis.magoosh.com" target="">Praxis Prep</a></li>
              <li><a href="https://lsat.magoosh.com/" target="">LSAT Prep</a></li>
              <li><a href="https://mcat.magoosh.com" target="">MCAT Prep</a></li>
              <li><a href="https://ielts.magoosh.com" target="">IELTS Prep</a></li>
              <li><a href="https://go.magoosh.com/schools" target="">High School Packages</a></li>
              <li><a href="https://gradadmissions.magoosh.com" target="">Graduate Admissions</a></li>
              <li><a href="https://mba.magoosh.com" target="">MBA Admissions</a></li>
            </ul>
          </div>

          <div className={style.footerNavigationSection}>
            <strong>Company</strong>
            <ul>
              <li><a href="https://magoosh.com/team/" target="">About Magoosh</a></li>
              <li><a href="https://magoosh.com/mission/" target="">Mission</a></li>
              <li><a href="https://magoosh.com/press/" target="">Press</a></li>
              <li><a href="https://magoosh.com/contact/" target="">Contact</a></li>
              <li><a href="https://gre.magoosh.com/privacy" target="">Privacy Policy</a></li>
              <li><a href="https://magoosh.com/partner-with-us/" target="">Affiliates and Group Partners</a></li>
              <li><a href="https://magoosh.com/careers/" target="">Careers</a></li>
              <li><a href="https://connect.studentbeans.com/v4/hosted/magoosh/us" target="">Student Beans Discount!</a></li>
            </ul>
          </div>

          <div className={style.footerNavigationSection}>
            <strong>Our Blogs</strong>
            <ul>
              <li><a href="https://magoosh.com/blog/" target="">Company Blog</a></li>
              <li><a href="https://magoosh.com/gre/" target="">GRE Blog</a></li>
              <li><a href="https://magoosh.com/gmat/" target="">GMAT Blog</a></li>
              <li><a href="https://magoosh.com/grad-admissions/" target="">Grad Admissions Blog</a></li>
              <li><a href="https://magoosh.com/toefl/" target="">TOEFL Blog</a></li>
              <li><a href="https://magoosh.com/hs/sat/" target="">SAT Blog</a></li>
              <li><a href="https://magoosh.com/hs/act/" target="">ACT Blog</a></li>
              <li><a href="https://magoosh.com/praxis/" target="">Praxis Blog</a></li>
              <li><a href="https://magoosh.com/lsat/" target="">LSAT Blog</a></li>
              <li><a href="https://magoosh.com/mcat" target="">MCAT Blog</a></li>
              <li><a href="https://magoosh.com/ielts" target="">IELTS Blog</a></li>
            </ul>
          </div>
        </nav>

        <div className={style.footerFineprint}>
          <p>GMAT® is a registered trademark of the Graduate Management Admission Council (GMAC). This website is not endorsed or approved by GMAC. GRE®, TOEFL®, and Praxis® are registered trademarks of Educational Testing Service (ETS). This website is not endorsed or approved by ETS. SAT® is a registered trademark of the College Board, which was not involved in the production of, and does not endorse this product. LSAT® is a registered trademark of the Law School Admission Council, Inc. This website is not endorsed or approved by the LSAC. ACT® is a registered trademark of ACT, inc. This website is not endorsed or approved by ACT, inc. MCAT is a registered trademark of the Association of American Medical Colleges (AAMC). This website is not endorsed or approved by AAMC. </p><p>View our <a href="https://gre.magoosh.com/privacy">privacy policy</a>.</p><p></p>
        </div>

        <div className="footer-marks">
          <p className="copyright">© Magoosh 2024</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
