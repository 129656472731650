export default class PaymentError extends Error {
  errors = null
  debug = null

  constructor(errors, debug = null) {
    super(typeof errors === 'string' ? errors : JSON.stringify(errors));

    if (typeof errors === 'string') {
      this.errors = { _error: errors };
    } else {
      this.errors = errors;
    }

    this.debug = debug || JSON.stringify(this.errors);
    this.name = "PaymentError";
  }
};
