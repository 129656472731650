import React, { useEffect, useState } from 'react';

import { InfoButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

import * as api from '@test_prep/app/api';
import { Interaction } from '@test_prep/app/ai_tutor/types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  addOrUpdateUserInteractions: (interactions: Interaction[]) => void;
  interaction: Interaction;
}

const ActivityOffer: React.FC<Props> = (props) => {
  const [hasAccepted, setHasAccepted] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  const acceptOffer = () => {
    setIsAccepting(true);

    const updatedInteraction = { ...props.interaction };
    updatedInteraction.content.activity.accepted = true;

    api.updateTutoringSessionInteraction(updatedInteraction).then((interaction) => {
      setIsAccepting(false);
      setHasAccepted(true);

      api.fetchNextTutoringSessionInteractions(props.interaction.tutoringSessionId, props.interaction.id).then((interactions) => {
        props.addOrUpdateUserInteractions(interactions);
      });
    });
  };

  return (
    <div className="d-flex flex-column gap-s">
      {props.interaction.content.text}

      <div>
        <InfoButton
          disabled={hasAccepted || props.interaction.content.activity.accepted}
          icon={hasAccepted || props.interaction.content.activity.accepted ? 'check' : null}
          onClick={acceptOffer}
          size="sm"
          submitting={isAccepting}
        >
          {props.interaction.content.activity.cta}
        </InfoButton>
      </div>
    </div>
  );
};

export default ActivityOffer;
