import React from 'react';
import cx from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import CustomDateRange, { formatCustomDateRange } from './custom_date_range';
import { FilterConfig } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  disabled: boolean;
  filterConfig: FilterConfig;
  onChange: (name: string, value: string) => void;
  value: string;
}

const SingleSelectFilter: React.FC<Props> = (props) => {
  const handleFilterSelected = (selectedValue: string) => {
    props.onChange(props.filterConfig.key, selectedValue);
  };

  const renderDisabled = (name: string) => {
    return (
      <div
        className={cx(style.filterButton, 'small text-gray-light')}
        style={{
          backgroundColor: colors.grayLighter,
          borderRadius: 4
        }}
      >
        {name}
      </div>
    );
  };

  const renderSelected = (name: string) => {
    let displayName = name;

    if (props.filterConfig.key === 'period' && name === 'Custom') {
      return (
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={
            <Popover style={{ maxWidth: 600 }} id="date-range-popover">
              <CustomDateRange onChange={handleFilterSelected} value={props.value} />
            </Popover>
          }
        >
          <div
          className={cx(style.filterButton, 'small text-white')}
          style={{
            backgroundColor: colors.blue,
            borderRadius: 4,
            cursor: 'pointer'
          }}
        >
          {formatCustomDateRange(props.value)}
        </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div
          className={cx(style.filterButton, 'small text-white')}
          style={{
            backgroundColor: colors.blue,
            borderRadius: 4
          }}
        >
          {name}
        </div>
      );
    }
  };

  const renderUnselected = (name: string, value: string) => {
    if (props.filterConfig.key === 'period' && name === 'Custom') {
      return (
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={
            <Popover style={{ maxWidth: 600 }} id="date-range-popover">
              <CustomDateRange onChange={handleFilterSelected} value={props.value} />
            </Popover>
          }
        >
          <div
            className={cx(style.filterButton, 'small')}
            style={{
              backgroundColor: colors.lightBlue,
              borderRadius: 4,
              color: colors.blue,
              cursor: 'pointer'
            }}
          >
            Custom
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div
          className={cx(style.filterButton, 'small')}
          onClick={() => handleFilterSelected(value)}
          style={{
            backgroundColor: colors.lightBlue,
            borderRadius: 4,
            color: colors.blue,
            cursor: 'pointer'
          }}
        >
          {name}
        </div>
      );
    }
  };

  return (
    <div
      className={cx(style.filterButtonGroup, 'd-flex')}
      style={{
        backgroundColor: props.disabled ? colors.grayLighter : colors.blueLight,
        borderRadius: 4,
        color: props.disabled ? colors.grayLight : colors.blue,
        gap: 5
      }}
    >
      {
        props.filterConfig.options.map((option, idx) => {
          if (props.disabled) {
            return <React.Fragment key={idx}>{renderDisabled(option.name)}</React.Fragment>;
          } else if (props.value === option.value || (props.value.startsWith('custom:') && option.value === 'custom')) {
            return <React.Fragment key={idx}>{renderSelected(option.name)}</React.Fragment>;
          } else {
            return <React.Fragment key={idx}>{renderUnselected(option.name, option.value)}</React.Fragment>;
          }
        })
      }
    </div>
  );
};

export default SingleSelectFilter;
