import React, { useLayoutEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { PrimaryButton } from '@magoosh/lib/button';
import Card from '@magoosh/lib/card';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  description: string;
  linkText: string;
  linkUrl: string;
  mobileOffsetY: number;
  offsetY: number;
  name: string;
  stickyCTAOnMobile?: boolean
}

const CTACard: React.FC<Props> = (props) => {
  const waypointRef = useRef(null);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const stickyCTAOnMobile = props.hasOwnProperty('stickyCTAOnMobile') ? props.stickyCTAOnMobile : true;

  // If page is reloaded and the user is past the waypoint it won't trigger.
  // Do a one time check if we are already past the waypoint and show the sticky bar if so.
  useLayoutEffect(() => {
    maybeSetSticky()
  }, []);

  const maybeSetSticky = () => {
    const rect = waypointRef?.current?.getBoundingClientRect();
    if (!rect) return;

    if (rect.y < 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const renderCardContents = () => {
    return (
      <>
        <p><strong>{props.name}</strong></p>

        {props.description && <p>{props.description}</p>}

        <PrimaryButton block href={props.linkUrl}>
          {props.linkText}
        </PrimaryButton>

        {/*Trigger the waypoint at what would be the top of the sticky element*/}
        <div ref={waypointRef} style={{ position: 'absolute', bottom: 56 }}>
          <Waypoint
            onEnter={() => maybeSetSticky()}
            onLeave={() => maybeSetSticky()}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Card className="hidden-sm hidden-xs" style={{ position: 'sticky', top: props.offsetY }}>
        {renderCardContents()}
      </Card>

      <Card className="visible-sm visible-xs" style={{ position: 'relative' }}>
        {renderCardContents()}
      </Card>

      {
        stickyCTAOnMobile && (
          <div
            className="visible-xs"
            style={{
              backgroundColor: '#fff',
              height: isSticky ? 56 : 0,
              left: 0,
              position: 'fixed',
              top: props.mobileOffsetY,
              transition: isSticky ? 'height 0.15s ease-out' : 'none',
              width: '100%',
              // Below style.categoryProfileListOverlay which has $zindex-navbar-fixed
              zIndex: 1000
            }}
          >
            <div
              style={{
                backgroundColor: colors.grayLightest,
                height: '100%',
                opacity: isSticky ? 1 : 0,
                transition: isSticky ? 'opacity 1s ease-out' : 'none'
              }}
            >
              <div className="container" style={{ height: '100%' }}>
                <div className="align-items-center d-flex" style={{ gap: 20, height: '100%' }}>
                  <div>
                    <strong>{props.name}</strong>
                  </div>

                  <div className="d-flex flex-1 justify-content-end">
                    <PrimaryButton size="sm" href={props.linkUrl}>
                      {props.linkText}
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default CTACard;

