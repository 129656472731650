import React, { useEffect, useState } from 'react';

import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

import { Interaction } from '@test_prep/app/ai_tutor/types';

import PromptActivity from './prompt_activity';

interface Props {
  activity: Interaction;
  addOrUpdateUserInteractions: (interactions: Interaction[]) => void;
}

interface CompositionProps {
  Title?: React.FC<any>;
}

const Activity: React.FC<Props> & CompositionProps = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  // Add a slight delay when switching between activities so it's obvious that something
  // new has loaded.
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [props.activity.id]);

  if (isLoading || props.activity.status !== 'finished') {
    return (
      <div className="d-flex flex-1 justify-content-center">
        <LoadingIndicator />
      </div>
    );
  } else if (props.activity.type === 'prompt_activity') {
    return <PromptActivity {...props} />;
  } else {
    return null;
  }
};

Activity.Title = (props) => {
  const CircleIcon = (
    <div
      className="align-items-center d-flex justify-content-center"
      style={{
        background: '-webkit-linear-gradient(135deg, #26B67C, #9B51E0)',
        borderRadius: '50%',
        height: 20,
        width: 20
      }}
    >
      <FontAwesomeIcon icon="asterisk" style={{ color: '#ffffff', fontSize: 12 }} />
    </div>
  );

  if (props.activity.type === 'prompt_activity') {
    return (
      <div className="align-items-center d-flex gap-xs">
        <div className="flex-none">
          {CircleIcon}
        </div>

        <div>
          <strong>Question</strong> {'>'} "{props.activity.activityContent.originalPrompt.title}"
        </div>
      </div>
    );
  } else {
    return (
      <div className="align-items-center d-flex gap-xs">
        {CircleIcon}

        <div><strong>Activity</strong></div>
      </div>
    );
  }
  return (
    <div>Title</div>
  );
};

export default Activity;
