import * as React from 'react';
import { WritingExample } from '@magoosh/admin/writing_examples/types';
import WritingExampleDocument from '@magoosh/pages/writing_examples/show/document';
import { DangerButton, InfoButton, PrimaryButton } from '@magoosh/lib/button';
import { adminPaths } from 'config/path_helpers';
import style from '../index/style.module.scss';
import EvaluationModal from '@magoosh/admin/writing_examples/show/evaluation_modal';
import EditWritingExampleModal from '@magoosh/admin/writing_examples/show/edit_writing_example_modal';
import magooshFetch from 'utilities/fetch';
import { pluralize } from 'utilities/pluralize';

interface Props {
  writingExample: WritingExample;
}
const WritingExampleAdminShow: React.FC<Props> = (props: Props) => {
  const [showEvaluationModal, setShowEvaluationModal] = React.useState(false);
  const [showEditWritingExampleModal, setShowEditWritingExampleModal] = React.useState(false);
  const [writingExample, setWritingExample] = React.useState<WritingExample>(props.writingExample);
  const [showPdfUpload, setShowPdfUpload] = React.useState(false);

  const fetchWritingExample = () => {
    fetch(adminPaths.api.writingExample(props.writingExample.id))
      .then((response) => response.json())
      .then((response) => {
        setWritingExample(response);
      });
  };

  const onEvalFormSuccess = () => {
    setShowEvaluationModal(false);
    fetchWritingExample();
  };

  const onEvalModalClose = () => {
    setShowEvaluationModal(false);
  };

  const onEditWritingExampleFormSuccess = () => {
    setShowEditWritingExampleModal(false);
    fetchWritingExample();
  };

  const onEditWritingExampleModalClose = () => {
    setShowEditWritingExampleModal(false);
  };

  const onChangePdfValue = (event) => {
    const formData = new FormData();
    formData.append('pdf', event.currentTarget.files[0]);
    fetch(adminPaths.api.writingExample(props.writingExample.id), {
      method: 'PUT',
      body: formData
    })
      .then((response) => {
        if (response.ok) {
          fetchWritingExample();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onClickDeletePdf = () => {
    if (confirm('Are you sure you want to delete this PDF?')) {
      magooshFetch(adminPaths.api.writingExampleDeletePdf(props.writingExample.id), {
        method: 'DELETE'
      })
        .then((response) => {
          fetchWritingExample();
        })
        .catch((error) => {
          alert(error.errors._error);
        });
    }
  };

  return (
    <>
      <div>
        <div className={style.adminInfo}>
          <div>
            <h2>Manage writing example: {writingExample.title}</h2>
            <h4>In Brandings:</h4>
            <p>{writingExample.brandings.map((branding) => branding.name).join(', ')}</p>
            <h4>Access Level:</h4>
            <p>{writingExample.accessLevel.name}</p>
            <h4>Ratings</h4>
            <div>
              <p>
                This writing example has{' '}
                <a href={adminPaths.writingExampleRatings(writingExample.id)}>
                  {pluralize(writingExample.ratingSummary.total, 'rating', 'ratings')}
                </a>
                , with a like ratio of {writingExample.ratingSummary.likeRatio}.
              </p>
            </div>
            <div className="u-margin-B-xl">
              {writingExample.evaluation ? (
                <PrimaryButton onClick={() => setShowEvaluationModal(true)}>Edit Evaluation</PrimaryButton>
              ) : (
                <PrimaryButton onClick={() => setShowEvaluationModal(true)}>Create Evaluation</PrimaryButton>
              )}
              <PrimaryButton className="u-margin-H-m" onClick={() => setShowEditWritingExampleModal(true)}>
                Edit Writing Example
              </PrimaryButton>
              {writingExample.pdfUrl ? (
                <DangerButton className="u-margin-R-m" onClick={onClickDeletePdf}>
                  Delete PDF
                </DangerButton>
              ) : showPdfUpload ? (
                <label>
                  Add PDF
                  <input name="pdf" type="file" accept="application/pdf" onChange={onChangePdfValue} />
                </label>
              ) : (
                <InfoButton className="u-margin-H-m" onClick={() => setShowPdfUpload(true)}>
                  Add PDF
                </InfoButton>
              )}
              <a className="btn btn-default" href={adminPaths.writingExamples()}>
                Back to List
              </a>
            </div>
          </div>
        </div>
        <div>
          <WritingExampleDocument writingExample={writingExample} evaluation={writingExample.evaluation} />

          <EvaluationModal
            show={showEvaluationModal}
            evaluation={writingExample.evaluation}
            writingExampleId={writingExample.id}
            onFormSuccess={onEvalFormSuccess}
            onClose={onEvalModalClose}
          />
          <EditWritingExampleModal
            show={showEditWritingExampleModal}
            writingExample={writingExample}
            onFormSuccess={onEditWritingExampleFormSuccess}
            onClose={onEditWritingExampleModalClose}
          />
        </div>
      </div>
    </>
  );
};

export default WritingExampleAdminShow;
