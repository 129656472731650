import * as React from 'react';
import cx from 'classnames';

import style from './style.module.scss';

interface BadgeProps {
  children: any;
  className?: string;
}

export default function Badge({ children, className }: BadgeProps) {
  return (
    <span className={cx(style.badgeContainer, className)}>
      {children}
    </span>
  );
}

export const DefaultBadge = (props: BadgeProps) => {
  return <Badge {...props} className={cx('bg-gray-light', props.className)} />
};

export const InfoBadge = (props: BadgeProps) => {
  return <Badge {...props} className={cx(style.bgInfo, props.className)} />
};

export const SuccessBadge = (props: BadgeProps) => {
  return <Badge {...props} className={cx(style.bgSuccess, props.className)} />
};

export const WarningBadge = (props: BadgeProps) => {
  return <Badge {...props} className={cx(style.bgWarning, props.className)} />
};

export const DangerBadge = (props: BadgeProps) => {
  return <Badge {...props} className={cx(style.bgDanger, props.className)} />
};
