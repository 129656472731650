import * as React from 'react';
import { Badge, Col, Grid, Panel, Row } from 'react-bootstrap';
import { get, some } from 'lodash';
import * as moment from 'moment';
import { paths } from 'config/path_helpers';
import cx from 'classnames';

import { BorderlessButton, DefaultButton } from '@magoosh/lib/button';
import AssignmentDialog from '../assignment_dialog';
import DeleteAssignmentDialog from '../delete_assignment_dialog/template';
import { Assignment, UserAssignment, UserAssignmentState } from '../types';
import style from '../style.module.scss';

interface AssignmentsProps {
  teacherMode: boolean;
  assignments: Assignment[];
  assignmentDialogOpen: boolean;
  deleteAssignmentDialogOpen: boolean;
  assignmentCreated: (assignment: Assignment) => any;
  assignmentDeleted: (assignment: Assignment) => any;
  showAssignmentDialog: () => any;
  hideAssignmentDialog: () => any;
  showDeleteAssignmentDialog: () => any;
  hideDeleteAssignmentDialog: () => any;
}

interface AssignmentsState {
  assignmentToDelete: Assignment;
}

export default class AssignmentsList extends React.Component<AssignmentsProps, AssignmentsState> {
  state = {
    assignmentToDelete: null
  };

  render() {
    return (
      <div className={style.assignments}>
        {this.renderHeader()}
        {this.noAssignmentsMessage(this.props.assignments)}
        {this.props.assignments.map((assignment) => {
          return (
            <Panel key={assignment.id} className={style.panel}>
              <Panel.Body>
                <Grid>
                  <Row>
                    <Col md={9}>
                      <a href={this.assignmentURL(assignment)} className={style.assignmentLink}>
                        <div className={style.title}>{assignment.title}</div>
                        <div className={style.className}>{get(assignment, 'classSection.ensuredName')}</div>
                      </a>
                    </Col>
                    <Col md={2}>
                      {this.renderDueDate(assignment)}
                      {this.renderSubmittedBadge(assignment)}
                      {this.renderSubmissionCount(assignment)}
                    </Col>
                    <Col md={1} className={style.buttonsColumn}>
                      {this.renderButtons(assignment)}
                    </Col>
                  </Row>
                </Grid>
              </Panel.Body>
            </Panel>
          );
        })}
        <AssignmentDialog
          isOpen={this.props.assignmentDialogOpen}
          onHide={this.props.hideAssignmentDialog}
          onSuccess={this.onAssignmentDialogSuccess}
          title="New Assignment"
        />
        <DeleteAssignmentDialog
          show={this.props.deleteAssignmentDialogOpen}
          onHide={this.props.hideDeleteAssignmentDialog}
          onSuccess={this.onDeleteAssignmentDialogSuccess}
          assignment={this.state.assignmentToDelete}
        />
      </div>
    );
  }

  renderDueDate = (assignment) => {
    if (assignment.dueDate) {
      return <span>Due: {moment(assignment.dueDate).format('L')}</span>;
    } else {
      return <span className={style.noDueDate}>No due date</span>;
    }
  };

  renderSubmittedBadge = (assignment: Assignment) => {
    if (!this.teacherMode()) {
      if (this.anyUserAssignmentState(assignment.userAssignments, UserAssignmentState.submitted)) {
        return (
          <div>
            <Badge bsClass={cx('badge', 'submittedBadge')}>submitted</Badge>
          </div>
        );
      } else if (this.anyUserAssignmentState(assignment.userAssignments, UserAssignmentState.started)) {
        return (
          <div>
            <Badge bsClass={cx('badge', 'startedBadge')}>started</Badge>
          </div>
        );
      }
    }

    return null;
  };

  renderSubmissionCount = (assignment: Assignment) => {
    if (this.teacherMode()) {
      return (
        <div>
          {assignment.submissionCount} of {assignment.studentCount} submitted
        </div>
      );
    }

    return null;
  };

  noAssignmentsMessage = (assignments) => {
    if (!assignments || assignments.length <= 0) {
      return <div>You have no assignments</div>;
    }
  };

  onAssignmentDialogSuccess = (assignment) => {
    this.props.assignmentCreated(assignment);
    this.props.hideAssignmentDialog();
  };

  onDeleteAssignmentClick = (assignment) => {
    this.setState({ assignmentToDelete: assignment });
    this.props.showDeleteAssignmentDialog();
  };

  onDeleteAssignmentDialogSuccess = (assignment) => {
    this.props.assignmentDeleted(assignment);
    this.props.hideDeleteAssignmentDialog();
  };

  protected assignmentURL(assignment) {
    if (this.teacherMode()) {
      return paths.assignment(assignment.id);
    } else {
      return paths.contentBlockAssignment(assignment);
    }
  }

  protected renderButtons(assignment) {
    if (this.teacherMode()) {
      return (
        <BorderlessButton
          icon="trash"
          className={style.deleteAssignmentButton}
          onClick={this.onDeleteAssignmentClick.bind(null, assignment)}
        />
      );
    }
  }

  protected renderHeader() {
    if (this.teacherMode()) {
      return (
        <div className={style.listHeader}>
          <DefaultButton
            className={style.createAssignmentButton}
            icon="plus"
            onClick={this.props.showAssignmentDialog}>
            Create New Assignment
          </DefaultButton>
        </div>
      );
    }
  }

  protected teacherMode() {
    return this.props.teacherMode;
  }

  // Returns true if any of the provided user assignments are in the specififed user assignment state.
  protected anyUserAssignmentState(userAssignments: UserAssignment[], state: UserAssignmentState) {
    return some(userAssignments, (userAssignment: UserAssignment) => {
      return userAssignment.state === state;
    });
  }
}
