import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { without } from 'lodash';

import Combobox from '@magoosh/lib/forms/combobox';
import { DateSelect } from '@magoosh/lib/forms';
import { paths } from 'config/path_helpers';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  applicationDeadlines: {
    dueAt: string;
    programName?: string;
  }[];
  formInputName: string;
}

const AddDeadlinesEmbed: React.FC<Props> = (props) => {
  const newDeadlineDefaults = {
    dueAt: '',
    programName: ''
  };
  const initialData = !!props.applicationDeadlines.length ? props.applicationDeadlines : [newDeadlineDefaults];

  const [applicationDeadlines, setApplicationDeadlines] = useState(initialData);

  const addAnotherDeadline = () => {
    setApplicationDeadlines([...applicationDeadlines, newDeadlineDefaults]);
  };

  const handleFieldChanged = (idx: number, field: string, value: string) => {
    const updatedDeadlines = [...applicationDeadlines];
    updatedDeadlines[idx][field] = value;
    setApplicationDeadlines(updatedDeadlines);
  };

  const removeDeadline = (idx: number) => {
    const updatedDeadlines = [...applicationDeadlines];
    updatedDeadlines.splice(idx, 1);
    setApplicationDeadlines(updatedDeadlines);
  }

  return (
    <div className="d-flex flex-column" style={{ gap: 20 }}>
      {
        applicationDeadlines.map((deadline, idx) => {
          let label;
          if (idx === 0) {
            label = <div>Program</div>;
          } else {
            label = (
              <div>
                 Program{' '}
                <span style={{ fontWeight: 400 }}>
                  <a onClick={() => removeDeadline(idx)}>
                    <small>Remove</small>
                  </a>
                </span>
              </div>
            );
          }

          return (
            <div key={idx} className="d-flex flex-column" style={{ gap: 20 }}>
              <div>
                <Combobox
                  defaultValue=""
                  label={label}
                  onChange={(value) => handleFieldChanged(idx, 'programName', value)}
                  searchEndpoint={paths.api.admissions.programsSearch}
                  textField="name"
                  valueField="name"
                  value={deadline.programName}
                />
              </div>

              <div>
                <label>Deadline</label>
                <DateSelect
                  className="d-flex"
                  onChange={(date: string) => handleFieldChanged(idx, 'dueAt', date)}
                  selectClassName="flex-1 u-margin-B-n"
                  style={{ gap: 10 }}
                  value={deadline.dueAt}
                />
                <div className="small text-gray-light u-margin-T-xxs">Enter the deadline for the round you currently intend to apply. You can always edit this date if you choose to submit your application later.</div>
              </div>

              <hr className="u-margin-V-n" style={{ width: '100%' }}/>

              <input
                type="hidden"
                name={props.formInputName}
                value={JSON.stringify({ due_at: deadline.dueAt, program_name: deadline.programName })}
              />
            </div>
          );
        })
      }

      <a onClick={addAnotherDeadline}>Add another deadline</a>
    </div>
  );
};

export default AddDeadlinesEmbed;
