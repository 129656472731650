import React from 'react';

import { SimpleDonutChart } from '@magoosh/lib/charts';

import StatisticData from '../statistic_data';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  filters: any;
}

const PercentCorrectChart: React.FC<Props> = (props) => {
  const loadingPlaceholder = (
    <SimpleDonutChart
      chartProps={{ animate: false }}
      colorPalette={{ dark: colors.gray85, light: colors.grayLightest }}
      data={60}
      interior={<></>}
      size={150}
    />
  );

  if (!props.filters) {
    return loadingPlaceholder;
  } else {
    return (
      <StatisticData
        filters={props.filters}
        formatDataFn={(data) => data.value}
        name="percent_correct"
      >
        <StatisticData.Loading>
          {loadingPlaceholder}
        </StatisticData.Loading>

        {/* @ts-ignore */}
        <SimpleDonutChart
          chartProps={{ animate: false }}
          colorPalette={{ dark: colors.dataDarkPurple, light: colors.dataMutedPurple }}
          size={150}
        />
      </StatisticData>
    );
  }
};

export default PercentCorrectChart;
