import * as React from 'react';
import { PrimaryButton } from '@magoosh/lib/button';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

interface FormProps {
  mtt: any;
  runQuery: any;
  closeModal: () => void;
}

const MTTPreviewForm: React.FC<FormProps> = (props) => {
  const submitForm = () => {
    fetch(adminPaths.api.previewModuleTrackTemplate(props.mtt.id), {
      method: 'POST',
    })
      .then((response) => {
        props.closeModal();
        props.runQuery();
      })
      .catch((response) => alert(response.errors._error));
  };

  return (
    <div>
      <p>
        <p>Preview <b>{props.mtt.containerBlock.name}</b>?</p>

        <p>This will set your account to use this track. After doing this, you can go to the front-end modules dashboard to see these changes in action, before activating the track for users.</p>
      </p>
      <PrimaryButton className="u-margin-T-s" onClick={() => submitForm()}>
        Ok
      </PrimaryButton>
    </div>
  );
};

export default MTTPreviewForm;
