import * as React from 'react';
import { capitalize } from 'lodash';
import { Field } from 'formik';
import Multiselect from 'react-widgets/lib/Multiselect';

import style from './style.module.scss';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import fetch from 'utilities/fetch';

interface Props {
  name: string;
  id?: string;
  data?: any[];
  valueField?: string;
  textField?: string | ((dataItem?) => string);
  defaultValue: any;
  label?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  filter?: string | ((any) => boolean);
  groupBy?: string | ((any) => any);
  searchEndpoint?: (string) => string;
  allowCreate?: boolean;
}
export default class FieldMultiselect extends React.Component<Props> {
  state = {
    data: this.props.data || []
  };
  render() {
    return (
      <div className={cx('u-margin-T-s', style.fieldMultiSelect)}>
        <label htmlFor={id(this.props)}>{capitalize(this.props.label || this.props.name)}</label>
        <Field name={this.props.name}>
          {({ field, form, meta }) => (
            <Multiselect
              data={this.state.data}
              value={field.value}
              filter={this.props.filter}
              valueField={this.props.valueField}
              textField={this.props.textField}
              groupBy={this.props.groupBy}
              defaultValue={this.props.defaultValue}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              className={cx(style.fieldMultiSelect, this.props.className)}
              onChange={(values) => {
                form.setFieldValue(
                  this.props.name,
                  this.props.valueField ? values.map((v) => v[this.props.valueField]) : values
                );
              }}
              allowCreate={this.props.allowCreate}
              onCreate={
                this.props.allowCreate
                  ? (value) => {
                      this.setState({ data: [...this.state.data, value] }, () => {
                        form.setFieldValue(this.props.name, [...form.values[this.props.name], value]);
                      });
                    }
                  : null
              }
              onSearch={
                this.props.searchEndpoint
                  ? debounce((value) => {
                      fetch(this.props.searchEndpoint(value), { method: 'GET' }).then((response) => {
                        const valuesToKeep = field.value.map((v) => {
                          return this.state.data.find((d) => d[this.props.valueField] === v);
                        });

                        const newData = [...valuesToKeep, ...response].filter((d) => d);
                        this.setState({ data: newData });
                      });
                    }, 500)
                  : null
              }
            />
          )}
        </Field>
      </div>
    );
  }
}

function id(props: Props) {
  return props.id || `${props.name}-input`;
}
