import React from 'react';

const LSATDescriptionContent: React.FC = (props) => {
  return (
    <>
      <p>This practice test is designed to simulate the experience you will have taking the LSAT on test day.</p>
      <p>You can choose which <strong>official PrepTest</strong> you'd like to take from the menu below. When you finish the test, you will receive a <strong>scaled score estimate</strong>.</p>
    </>
  );
};

export default LSATDescriptionContent;
