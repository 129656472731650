import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { findIndex, sumBy } from 'lodash';

import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import { pluralize } from 'utilities/pluralize';

import SelectDayDropdown from '../select_day_dropdown';
import StudyItemList from '../study_item_list/';
import { PracticePreviewData, StudyScheduleDayData, StudyScheduleDetailData } from '../types';

import style from './style.module.scss';
import { StudyItemData } from '../../study_items/types';
import capitalize from 'react-bootstrap/lib/utils/capitalize';

interface Props extends StudyScheduleDetailData {
  onChangePlan?: (any) => void;
  onSetCurrentDay: (id: number) => any;
  examName: string;
}

interface State {
  isFetchingId: number;
  practicePreview?: PracticePreviewData;
  errorMessage?: any;
}

export default class CurrentSchedule extends React.Component<Props, State> {
  setCurrentDay = (id) => {
    if (!id) return;

    this.setState({ isFetchingId: id });
    return this.props.onSetCurrentDay(id).then(() => this.setState({ isFetchingId: null }));
  };

  constructor(props) {
    super(props);
    this.state = {
      isFetchingId: null
    };
  }

  render() {
    const { currentDay } = this.props;

    return (
      <div>
        <Row>
          <Col md={8}>
            <h4 className="u-margin-V-n">{this.props.name}</h4>
          </Col>

          <Col md={4}>
            <SelectDayDropdown
              currentDayId={currentDay.id}
              days={this.props.days}
              onSelect={this.setCurrentDay}
              learningSessionsPerSet={this.props.learningSessionsPerSet}
              learningSessionSetName={this.props.learningSessionSetName}
              learningSessionName={this.props.learningSessionName}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            {this.props.description && <p className="u-margin-V-xs">{this.props.description}</p>}
            {this.props.onChangePlan && (
              <a className="h7 text-gray-light" onClick={this.props.onChangePlan}>
                Change Plan
              </a>
            )}
          </Col>
        </Row>

        <hr className="u-margin-V-s" />

        <div className="u-margin-H-s">
          <h4 className="u-margin-B-n">
            <strong>
              {capitalize(this.props.learningSessionName)} {this.currentDayIndex() + 1}
              {currentDay.name ? ': ' : ''}
              {currentDay.name}
            </strong>
          </h4>
          <div className={style.estimatedTime}>Estimated Time: {this.estimatedTimeForCurrentDay()}</div>
        </div>

        <div className="u-margin-T-m">
          {<StudyItemList studyItems={this.props.currentDay.items} examName={this.props.examName} />}
        </div>

        <div className="u-margin-T-m">
          <Row>
            <Col xs={6}>
              {this.previousDayId() ? (
                <DefaultButton
                  block
                  data={this.previousDayId()}
                  onClick={this.setCurrentDay}
                  submitting={this.state.isFetchingId === this.previousDayId()}>
                  Previous
                </DefaultButton>
              ) : null}
            </Col>

            <Col xs={6}>
              {this.nextDayId() ? (
                <PrimaryButton
                  block
                  data={this.nextDayId()}
                  onClick={this.setCurrentDay}
                  submitting={this.state.isFetchingId === this.nextDayId()}>
                  Next
                </PrimaryButton>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  currentDayIndex() {
    return findIndex(this.props.days, (day: StudyScheduleDayData) => day.id === this.props.currentDay.id);
  }

  estimatedTimeForCurrentDay() {
    const totalMinutes = sumBy(
      this.props.currentDay.items,
      (item: StudyItemData) => item.estimatedTimeMinutes || 0
    );

    if (totalMinutes >= 60) {
      const hours = Math.floor(totalMinutes / 60);
      return `${pluralize(hours, 'hour', 'hours')}, ${pluralize(
        totalMinutes - hours * 60,
        'minute',
        'minutes'
      )}`;
    } else {
      return `${pluralize(totalMinutes, 'minute', 'minutes')}`;
    }
  }

  nextDayId() {
    const nextIndex = this.currentDayIndex() + 1;
    if (this.props.days[nextIndex]) return this.props.days[nextIndex].id;
  }

  previousDayId() {
    const previousIndex = this.currentDayIndex() - 1;
    if (this.props.days[previousIndex]) return this.props.days[previousIndex].id;
  }
}
