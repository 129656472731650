import * as React from 'react';
import { EditorStatus, WrittenMaterial } from '@magoosh/pages/writing_experience/types';
import Editor from '@magoosh/pages/writing_experience/show/editor';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import { DefaultButton, LinkButton, SecondaryButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';

const WrittenMaterialShow: React.FC<WrittenMaterial> = (props) => {
  const [editorStatus, setEditorStatus] = React.useState<EditorStatus>('savePending');
  const [showRename, setShowRename] = React.useState(false);
  const writtenMaterial = props;

  interface FormValues {
    prompt: string;
    brainstorm: string;
    outline: string;
    draft: string;
    title: string;
  }

  const initialValues: FormValues = {
    prompt: writtenMaterial.prompt || '',
    brainstorm: writtenMaterial.brainstorm || '',
    outline: writtenMaterial.outline || '',
    draft: writtenMaterial.draft || '',
    title: writtenMaterial.title || ''
  };

  const onSubmit = (values, actions: FormikHelpers<FormValues>) => {
    fetch(paths.api.writtenMaterial(writtenMaterial.id), {
      method: 'PATCH',
      body: JSON.stringify(values)
    })
      .then((response) => {
        setEditorStatus('saved');
        setShowRename(false);
      })
      .catch((error) => {
        setEditorStatus('serverError');
        setTimeout(actions.submitForm, 2000);
      });
  };

  return (
    <div className={style.show}>
      <div className={style.nav}>
        <DefaultButton href={paths.writingExperiences()}>
          <FontAwesomeIcon icon={'chevron-left'} /> Back
        </DefaultButton>
        <SecondaryButton href={paths.writtenMaterialExport(writtenMaterial.id)}>Download PDF</SecondaryButton>
      </div>
      <div className={style.editor}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ setFieldValue, submitForm, values }) => (
            <Form>
              <div className={style.header}>
                {showRename ? <Field name={'title'} className={style.titleField} /> : <h1>{values.title}</h1>}
                {showRename ? (
                  <LinkButton onClick={submitForm}>Save</LinkButton>
                ) : (
                  <LinkButton onClick={() => setShowRename(true)}>Rename</LinkButton>
                )}
              </div>
              <Editor
                defaultWritingTab={'draft'}
                status={editorStatus}
                setEditorStatus={setEditorStatus}
                setFieldValue={setFieldValue}
                submitForm={submitForm}
                fullScreen
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WrittenMaterialShow;
