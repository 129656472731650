import { StudentResponseData } from '../types';

export enum TeacherDashboardActionTypes {
  INITIALIZE_DASHBOARD = 'TEACHER_DASHBOARD/INITIALIZE',
  ADD_STUDENTS = 'TEACHER_DASHBOARD/ADD_STUDENTS',
  CHANGE_CLASS_SECTION_FILTER = 'TEACHER_DASHBOARD/CHANGE_CLASS_SECTION_FILTER',
  RESET_STUDENT_DATA = 'TEACHER_DASHBOARD/RESET_STUDENT_DATA'
}

export const initializeTeacherDashboard = (props) => ({
  type: TeacherDashboardActionTypes.INITIALIZE_DASHBOARD,
  teacherDashboard: props
});

export const addStudents = (props: StudentResponseData) => ({
  type: TeacherDashboardActionTypes.ADD_STUDENTS,
  ...props
});

export const changeClassFilter = (classSectionId: number) => ({
  type: TeacherDashboardActionTypes.CHANGE_CLASS_SECTION_FILTER,
  classSectionId
});

export const resetStudentData = () => ({
  type: TeacherDashboardActionTypes.RESET_STUDENT_DATA
});
