import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import { DangerBadge, DefaultBadge, InfoBadge, SuccessBadge } from '@magoosh/lib/badge';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

import { WorkflowTask } from '../../types';

interface Props {
  tasks: WorkflowTask[];
}

const CollapsibleTaskLogs: React.FC<Props> = (props) => {
  const [showLogs, setLogs] = useState(false);

  const renderBadge = (status) => {
    if (status === 'finished') {
      return <SuccessBadge>Finished</SuccessBadge>;
    } else if (status === 'errored') {
      return <DangerBadge>Error</DangerBadge>;
    } else if (status === 'processing') {
      return <InfoBadge>Processing</InfoBadge>;
    } else {
      return <DefaultBadge>{status}</DefaultBadge>;
    }
  };

  return (
    <div>
      <span onClick={() => setLogs(!showLogs)} style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={showLogs ? 'caret-down' : 'caret-right'}/>{' '}
        <small>Show Logs</small>
      </span>

      <Collapse in={showLogs}>
        <div className="u-margin-T-xs">
          {
            props.tasks.map((task, idx) => (
              <React.Fragment key={task.id}>
                <div className="bg-gray-lighter u-padding-A-s">
                  <div className="d-flex flex-column gap-xs">
                    <div className="align-items-center d-flex gap-xs">
                      <div>{renderBadge(task.status)}</div>
                      <div><strong><small>{task.identifier}</small></strong></div>
                    </div>

                    <div>
                      {
                        task.events.map((event) => (
                          <div key={event.id}>
                            <div className="d-flex flex-column gap-xs">
                              <div>{renderBadge(event.event)}</div>
                              <div className="flex-1">
                                <pre style={{ fontSize: 12 }}>
                                  {JSON.stringify(event.content, null, 2)}
                                </pre>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>

                {idx != props.tasks.length - 1 && <hr className="u-margin-A-n u-margin-V-xxs" />}
              </React.Fragment>
            ))
          }
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsibleTaskLogs;
