import React, { useLayoutEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Waypoint } from 'react-waypoint';

import CardLayout from '@magoosh/layouts/card';
import { DangerAlert } from '@magoosh/lib/alerts';
import { TabButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import EditorialPage from '@magoosh/organisms/editorial_page';
import Footer from '@magoosh/organisms/footer';
import { paths } from 'config/path_helpers';

import AboutMagooshContent from './about_magoosh_content';
import { Button as BrandButton, Logo as BrandLogo, brandColors } from './brand';
import TemplateOptionAndForm from './template_option_and_form';
import WhatYoullGetContent from './what_youll_get_content';

import MagooshLogo from '@magoosh/assets/magoosh-logo-purple.png';
import MagooshBrain from './assets/magoosh_brain.png';
import style from './style.module.scss';

interface Props {
  branding: {
    examName: string;
    examVersions: {
      current: string;
    };
  };
  editorialPage?: any,
  error?: string;
  planSlug: string;
  referrer?: {
    brand: string;
    name: string;
  };
  templateOptions: {
    estimatedLength: string;
    name: string;
    sectionId?: number;
    templateId: number;
  }[];
  user: {
    email: string;
    firstName: string;
  }
}

const FreeMockTest: React.FC<Props> = (props) => {
  const waypointRef = useRef(null);
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const colors = brandColors(props.referrer?.brand);
  const stickyBarHeight = 60;

  // If page is reloaded and the user is past the waypoint it won't trigger.
  // Do a one time check if we are already past the waypoint and show the sticky bar if so.
  useLayoutEffect(() => {
    const rect = waypointRef?.current?.getBoundingClientRect();
    if (!rect) return;

    if (rect.y < 0) setIsSticky(true);
  }, []);

  const handleWaypointPositionChanged = (data) => {
    if (data.currentPosition === 'above' && !isSticky) {
      setIsSticky(true);
    } else if (isSticky) {
      setIsSticky(false);
    }
  };

  return (
    <div>
      {/* Sticky bar */}
      <div
        style={{
          backgroundColor: '#fff',
          height: isSticky ? stickyBarHeight : 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 1001
        }}
      >
        <div
          className={style.confettiBackground}
          style={{
            backgroundColor: colors.background,
            borderBottom: `1px solid ${colors.border}`,
            height: '100%',
            opacity: isSticky ? 1 : 0,
            transition: isSticky ? 'opacity 1s ease-out' : 'none'
          }}
        >
          <div className="container d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
            <div className="hidden-xs">
              <h4 className="u-margin-A-n">
                <strong>Free {props.branding.examName} Practice Test and Score Report</strong>
              </h4>
            </div>

            <div className="flex-1 visible-xs">
              <h4 className="u-margin-A-n">
                <strong>Free {props.branding.examName} Practice Test</strong>
              </h4>
            </div>

            <div className="u-margin-L-s">
              <BrandButton brand={props.referrer?.brand} href="#top">Start Now</BrandButton>
            </div>
          </div>
        </div>
      </div>

      <div
        className={cx(style.container, style.confettiBackground)}
        style={{
          backgroundColor: colors.background,
          borderBottom: props.referrer?.brand ? 'none' : `4px solid ${colors.border}`,
          minHeight: props.referrer?.brand ? '100vh' : 'auto'
        }}
      >
        <div className="container d-flex flex-column" style={{ gap: 20 }}>
          <div className="d-flex align-items-center" style={{ gap: 20 }}>
            <div>
              <BrandLogo brand={props.referrer?.brand } />
            </div>

            {
              !props.referrer?.brand && props.editorialPage && (
                <div className="d-flex flex-1 justify-content-end text-right">
                  <a className="small text-secondary" href="#what-to-expect">
                    Learn more about {props.branding.examName} practice tests
                  </a>
                </div>
              )
            }
          </div>

          <div className="d-flex flex-column flex-lg-row" style={{ gap: 20 }}>
            <CardLayout.Card className="flex-1">
              <h1 className={cx(style.title, 'text-gray-darker u-margin-T-n')}>
                <strong>Free {props.branding.examName} Practice Test and Score Report</strong>
              </h1>

              {props.error && <DangerAlert className="u-margin-V-s">{props.error}</DangerAlert>}

              <p>This practice test is designed to simulate the experience you will have taking the {props.branding.examName} on test day.</p>
              <p>You'll see questions just like the real exam, get an accurate score, and  report of your strengths and weaknesses to guide your studies.</p>

              <div className="u-margin-V-m">
                <TemplateOptionAndForm
                  brand={props.referrer?.brand}
                  examName={props.branding.examName}
                  examVersion={props.branding.examVersions.current}
                  planSlug={props.planSlug}
                  templateOptions={props.templateOptions}
                  user={props.user}
                />
              </div>

              {
                props.referrer?.brand ? (
                  <>
                    <small className="text-gray-light">
                      <FontAwesomeIcon icon="lock" /> {props.referrer.name} and Magoosh will keep your information private and secure.
                    </small>

                    <div className="d-flex align-items-center u-margin-T-s" style={{ gap: 5 }}>
                      <div className="small text-gray-light">Powered by</div>
                      <a href="/"><img alt="Magoosh" src={MagooshLogo} style={{ height: 20 }} /></a>
                    </div>
                  </>
                ) : (
                  <small className="text-gray-light">
                    <FontAwesomeIcon icon="lock" /> Magoosh will keep your information private and secure.
                    {props.referrer?.name && <>{' '}We will share your email with our partner {props.referrer.name}.</>}
                  </small>
                )
              }

              <div ref={waypointRef}>
                <Waypoint onPositionChange={handleWaypointPositionChanged} />
              </div>
            </CardLayout.Card>

            <CardLayout.Card>
              <div className={style.whatYoullGet}>
                <WhatYoullGetContent
                  examName={props.branding.examName}
                  examVersion={props.branding.examVersions.current}
                />
              </div>
            </CardLayout.Card>
          </div>
        </div>
      </div>

      {
        !props.referrer?.brand && (
          <div className="container u-padding-V-xl">
            <AboutMagooshContent examName={props.branding.examName} />
          </div>
        )
      }

      {
        !props.referrer?.brand && props.editorialPage && (
          <>
            <div className="bg-gray-lightest d-flex align-items-center justify-content-center u-padding-A-s" style={{ gap: 10 }}>
              <div className="flex-none">
                <img src={MagooshBrain} alt="Brain with Magoosh sunglasses" style={{ height: 37, width: 'auto' }}/>
              </div>

              <div>
                <strong>
                  Study on the go with the Magoosh <a href="/prep-app">{props.branding.examName} Prep & Practice Mobile App</a>.
                </strong>
              </div>
            </div>

            <div className="u-margin-V-l" id="what-to-expect">
              <EditorialPage
                editorialPage={props.editorialPage}
                offsetY={stickyBarHeight}
                stickyCTAOnMobile={false}
              />
            </div>
          </>
        )
      }

      {!props.referrer?.brand && <Footer />}
    </div>
  );
};

export default FreeMockTest;
