import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Switch, Route } from 'react-router-dom';

import { ConstantsProvider } from '@magoosh/context/constants_context';
import { ConstantsContextData } from '@magoosh/context/constants_context/types';
import { CurrentBrandingProvider } from '@magoosh/context/current_branding_context';
import { CurrentBrandingContextData } from '@magoosh/context/current_branding_context/types';
import { CurrentProfileProvider } from '@magoosh/context/current_profile_context';
import { CurrentProfileContextData } from '@magoosh/context/current_profile_context/types';
import { ModalProvider } from '@magoosh/context/modal_context';

import Router from '@magoosh/lib/router';
import routes from './routes';

const queryClient = new QueryClient();

interface Props {
  context: {
    constants: ConstantsContextData;
    currentBranding: CurrentBrandingContextData;
    currentProfile: CurrentProfileContextData;
  };
  initialData?: any;
  url: string;
}

const App: React.FC<Props> = (props) => {  
  if (props.initialData) {
    Object.keys(props.initialData).forEach((key) => {
      queryClient.setQueryData(key, props.initialData[key])
    })
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConstantsProvider initialData={props.context.constants}>
        <CurrentBrandingProvider initialData={props.context.currentBranding}>
          <CurrentProfileProvider initialData={props.context.currentProfile}>
            <ModalProvider>
              <Router location={props.url}>
                <Switch>
                  {}
                  {
                    routes.map((route, idx) => {
                      return <Route {...route} key={idx} />
                    })
                  }
                </Switch>
              </Router>
            </ModalProvider>
          </CurrentProfileProvider>
        </CurrentBrandingProvider>
      </ConstantsProvider>
    </QueryClientProvider>
  );
}

export default App;
