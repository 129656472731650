import * as React from 'react';
import { DefaultButton, LinkButton, SecondaryButton } from '@magoosh/lib/button';
import style from './style.module.scss';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import Modal from '@magoosh/lib/modal';
import { paths } from 'config/path_helpers';
import writing_experience_page from '@magoosh/pages/writing_experience/show/writing_experience_page';
import { Form, Formik, FormikHelpers } from 'formik';
import fetch from 'utilities/fetch';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { FieldSelect } from '@magoosh/lib/formik_inputs/field_select/template';
import { DateSelect } from '@magoosh/lib/forms';
import FieldDateTime from '@magoosh/lib/formik_inputs/field_datetime';
import Field_datetime from '@magoosh/lib/formik_inputs/field_datetime';
import moment from 'moment-timezone';
import ProgressBar from '@magoosh/lib/progress_bar';
import cx from 'classnames';
import ConfirmationModal from '@magoosh/organisms/confirmation_modal';
import Badge from '@magoosh/lib/badge';

interface Props {
  user: {
    firstName: string;
    gradingCredits: number;
  };
  writtenMaterials: WrittenMaterial[];
}
interface WrittenMaterial {
  id: number;
  title: string;
  updatedAt: number;
  dueDate: number;
  documentType: string;
  maxSteps: number;
  stepsCompleted: number;
  gradingRequested: boolean;
}

interface ModalProps {
  onHide: () => any;
}

interface FormValues {
  title: string;
  documentType: string;
  applicationDeadline: string;
}

interface SquircleProps {
  icon: 'file-text' | 'file';
  text?: string;
  complete?: boolean;
}
const SquirclePaper: React.FC<SquircleProps> = (props) => {
  return (
    <div aria-hidden={true} className={cx(style.squirclePaper, props.complete ? style.completedPaper : null)}>
      <FontAwesomeIcon icon={props.icon} className={style.paper} />
      {props.text && <span>{props.text}</span>}
    </div>
  );
};
const WritingExperienceIndex: React.FC<Props> = (props) => {
  const [showNewModal, setShowNewModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [materialToDelete, setMaterialToDelete] = React.useState<WrittenMaterial>(null);

  const initialValues = {
    title: '',
    documentType: 'Personal Statement',
    applicationDeadline: moment().add(1, 'day').toDate()
  };

  const onSubmit = (values, helpers: FormikHelpers<FormValues>) => {
    fetch(paths.api.writingExperiences(), {
      method: 'POST',
      body: JSON.stringify(values)
    })
      .then((response) => {
        window.location.assign(paths.writingExperience(response.id, 1));
      })
      .catch((response) => {
        helpers.setSubmitting(false);
        if (response.errors) {
          console.log(response.errors);
          helpers.setErrors(response.errors);
        }
      });
  };

  const onClickClone = (writtenMaterialId: number) => {
    fetch(paths.api.writingExperienceClone(writtenMaterialId), {
      method: 'POST'
    })
      .then((response) => {
        window.location.assign(paths.writingExperience(response.id, 1));
      })
      .catch((response) => {
        console.log(response);
      });
  };

  const onClickDelete = (writtenMaterial: WrittenMaterial) => {
    var message = `Are you sure you want to delete "${writtenMaterial.title}"?`;
    if (writtenMaterial.gradingRequested) {
      message +=
        ' This draft has been submitted for grading. You will not be refunded for the grading credit.';
    }

    setMaterialToDelete(writtenMaterial);
    setShowDeleteModal(true);
  };

  const onDelete = () => {
    fetch(paths.api.writtenMaterial(materialToDelete.id), {
      method: 'DELETE'
    })
      .then((response) => {
        window.location.reload();
      })
      .catch((response) => {
        console.log(response);
      });
  };

  return (
    <div className={style.writingExperience}>
      <h1 className="text-secondary">
        Guided Admissions Writing <Badge className="badge badge-secondary">Beta</Badge>
      </h1>

      <p>
        Our Guided Admissions Writing portal provides you with a guided experience to efficiently and
        effectively get from a blank page to a well-written draft of your Statement of Purpose or Personal
        Statement.
      </p>
      <div className={style.subheader}>
        <h3>Your Essays</h3>
        <SecondaryButton
          onClick={() => {
            setShowNewModal(true);
          }}>
          New Draft
        </SecondaryButton>
      </div>

      {props.writtenMaterials.length == 0 && (
        <div className="flex-center-column">
          <SquirclePaper icon={'file-text'} />
          <h4>Start Your Application Essays</h4>
          <p>Finish your first draft in less than 20 steps with our guided process.</p>
          <SecondaryButton
            onClick={() => {
              setShowNewModal(true);
            }}>
            New Draft
          </SecondaryButton>
        </div>
      )}

      <ul className={style.writtenMaterialsList}>
        {props.writtenMaterials.map((writtenMaterial) => {
          const completed = writtenMaterial.stepsCompleted == writtenMaterial.maxSteps;
          const barColor = completed ? '#37B95C' : '#4D2079';
          var paperText: string;
          switch (writtenMaterial.documentType) {
            case 'Personal Statement':
              paperText = 'PS';
              break;
            case 'Statement of Purpose':
              paperText = 'SOP';
              break;
          }

          const stepNumber = Math.min(writtenMaterial.stepsCompleted + 1, writtenMaterial.maxSteps);

          return (
            <li className={style.writtenMaterialCard}>
              <div className={style.progressContainer}>
                <SquirclePaper icon={'file'} text={paperText} complete={completed} />
                <div className={style.progress}>
                  <span>
                    {completed ? (
                      'Complete!'
                    ) : (
                      <>
                        {writtenMaterial.stepsCompleted} of {writtenMaterial.maxSteps} steps completed
                      </>
                    )}
                  </span>
                  <ProgressBar
                    backgroundColor={'#D8D8D8'}
                    color={barColor}
                    percentage={(writtenMaterial.stepsCompleted * 100) / writtenMaterial.maxSteps}
                    size={'md'}
                  />
                </div>
              </div>
              <h4>{writtenMaterial.title}</h4>
              <small>
                Last edited {moment.unix(writtenMaterial.updatedAt).format('MMMM DD, YYYY')} • Due{' '}
                {moment.unix(writtenMaterial.dueDate).format('MMMM DD, YYYY')}
              </small>
              <div>
                <a
                  className="btn btn-secondary u-margin-R-m"
                  href={paths.writingExperience(writtenMaterial.id, stepNumber)}>
                  Continue
                </a>
                <DefaultButton href={paths.writtenMaterial(writtenMaterial.id)}>View Essay</DefaultButton>
              </div>
              <LinkButton onClick={() => onClickClone(writtenMaterial.id)}>Clone</LinkButton>
              <LinkButton onClick={() => onClickDelete(writtenMaterial)}>Delete</LinkButton>
            </li>
          );
        })}
      </ul>

      <Modal
        className={style.modal}
        show={showNewModal}
        onHide={() => setShowNewModal(false)}
        showCloseButton
        title={'New Draft'}>
        <div>
          <div className="flex-center-column">
            <SquirclePaper icon="file-text" />
            <h3>New Draft</h3>
          </div>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting }) => {
              const documentTypeOptions = {
                'Personal Statement': 'Personal Statement'
              };
              if (window.location.origin.search('gradadmissions') > -1) {
                documentTypeOptions['Statement of Purpose'] = 'Statement of Purpose';
              }
              return (
                <Form>
                  <FieldInput name={'title'} label={'Title'} type={'string'} />
                  <small>e.g. "Personal statement for HBS"</small>
                  <FieldSelect
                    name={'documentType'}
                    label={'Type'}
                    className={'form-control'}
                    options={documentTypeOptions}
                    block
                  />
                  <SecondaryButton
                    className={cx(style.modalSubmit, 'u-margin-T-m')}
                    submitting={isSubmitting}
                    type={'submit'}>
                    Continue
                  </SecondaryButton>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
      {showDeleteModal && (
        <ConfirmationModal
          title={'Delete Draft'}
          onConfirm={onDelete}
          onClose={() => setShowDeleteModal(false)}
          show={showDeleteModal}
          confirmText={'Delete'}
          confirmClass={'btn-danger'}>
          <p>Are you sure you want to delete "{materialToDelete.title}"?</p>
          {materialToDelete.gradingRequested && (
            <p>
              This draft has been submitted for review.{' '}
              <strong>You will not be refunded for the review credit.</strong>
            </p>
          )}
        </ConfirmationModal>
      )}
    </div>
  );
};

export default WritingExperienceIndex;
