import * as React from 'react';
import { Fragment } from 'react';
import cx from 'classnames';
import * as moment from 'moment';
import { Badge, Col, OverlayTrigger, Panel, Row, Tooltip } from 'react-bootstrap';
import DashboardTimeline from './dashboard_timeline';
import ExamSectionGroup from './exam_section_group';
import StudyPreferencesSetupModal from './study_preferences_setup_modal';
import DismissibleBanner from '@magoosh/lib/dismissible_banner';
import style from './style.module.scss';
import { DashboardData } from './types';
import trackEvent from 'utilities/track_event';
import { paths } from 'config/path_helpers';
import {
  Assignment,
  UserAssignment,
  UserAssignmentState
} from '@magoosh/high_school/pages/assignments/types';
import { groupBy, some } from 'lodash';
import { pluralize } from 'utilities/pluralize';
import Label, { LabelType } from './label';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

export interface CalypsoTemplateProps extends DashboardData {
  shouldShowModal: boolean;
  modalStartStep: number;
  onCloseModal: () => void;
  onOpenModal: () => void;
  weekAssignments: Assignment[];
}

export default class CalypsoTemplate extends React.Component<CalypsoTemplateProps, {}> {
  static defaultProps = {
    modalStartStep: 1
  };

  // We use 3 blocks if assignments are shown, and 2 otherwise, so adjust column size accordingly
  timelineSm = () => {
    if (this.props.weekAssignments) {
      return 5;
    } else {
      return 7;
    }
  };

  myTestSm = this.props.weekAssignments ? 3 : 5;

  render() {
    const daysUntilTest = this.props.testDate ? moment(this.props.testDate).diff(moment(), 'days') : null;
    const wideExamSectionCards = this.props.examSectionGroups.reduce((sum, group) => sum + group.examSections.length, 0) <= 2;

    return (
      <div>
        <Row className={style.flexSmallPlus}>
          <Col sm={this.timelineSm()} xs={12} className={style.timelineWrapper}>
            <div className="hidden-xs">
              <div className={style.responsiveHeader}>My Timeline</div>
              <div className="u-padding-T-l">{this.renderDashboardTimeline()}</div>
            </div>

            <div className={cx(style.timelinePanel, 'visible-xs')}>
              <Panel defaultExpanded={true}>
                <Panel.Toggle componentClass="span">
                  <div className={cx(style.responsiveHeader, 'u-margin-T-xs')}>
                    My Timeline <span className="fa fa-caret-down" />
                  </div>
                </Panel.Toggle>
                <Panel.Collapse>{this.renderDashboardTimeline()}</Panel.Collapse>
              </Panel>
            </div>
          </Col>
          {this.renderFlexCorner()}
        </Row>

        {this.props.shouldSeeDiagnostic && <Row>{this.renderDiagnosticPrompting()}</Row>}

        <Row>
          <Col xs={12}>
            <div className={cx(style.responsiveHeader, 'u-margin-V-m')}>My Performance</div>
            <div className="d-flex flex-row justify-content-around flex-wrap u-margin-B-xs">
              {this.props.examSectionGroups.map((group, idx) => {
                const borderRight =
                  idx < this.props.examSectionGroups.length - 1 ? '1px solid #e0e0e0' : null;

                return (
                  <div className="d-flex flex-column" style={{ borderRight, flex: 1 }} key={idx}>
                    <ExamSectionGroup wideCards={wideExamSectionCards} group={group} />
                  </div>
                );
              })}
            </div>

            <div className="h7 text-center">
              * If you've taken the {this.props.brandName} before,{' '}
              <a className="text-body" href="/profile">
                enter your previous score here
              </a>{' '}
              to get a more accurate status prediction.
            </div>

            <div className="h7 text-right">
              <a className="text-gray-light" href="/reset">
                Reset all stats
              </a>
            </div>
          </Col>
        </Row>

        <StudyPreferencesSetupModal
          brandName={this.props.brandName}
          daysUntilTest={daysUntilTest}
          firstName={this.props.firstName}
          onClose={this.props.onCloseModal}
          show={this.props.shouldShowModal}
          startStep={this.props.modalStartStep}
        />
      </div>
    );
  }

  renderDailyPointsTooltip() {
    return (
      <Tooltip id="daily-points-tooltip">
        Earn points by watching video lessons or explanations and by practice questions.
      </Tooltip>
    );
  }

  // This is the corner that we don't know what to do with.
  renderFlexCorner() {
    return (
      <React.Fragment>
        {this.renderMyTest()}
        {this.props.weekAssignments && this.renderWeekAssignments()}
      </React.Fragment>
    );
  }

  renderStudyStreak() {
    const { current, longest } = this.props.studyStreak;
    return (
      <Col sm={5} xs={12} className={style.dailyPointsWrapper}>
        <div className={style.responsiveHeader}>My Study Streak</div>
        <div>
          <h3>{pluralize(current, 'day')}</h3>
          <small>Current Streak</small>
        </div>
        <div>
          <h3>{pluralize(longest, 'day')}</h3>
          <small>Longest Streak</small>
        </div>
      </Col>
    );
  }

  renderMyTest() {
    const labelType: LabelType = (this.props.overallScore.scoreStatus.type || 'default') as LabelType;

    return (
      <Col
        sm={this.myTestSm}
        xs={12}
        className={cx(style.dailyPointsWrapper, this.props.weekAssignments ? style.timelineWrapper : null)}>
        <div className={cx(style.flexCol, 'hidden-xs')}>
          <div className={cx(style.timelineSection, style.flexDivEqualHeight)}>
            <div className={style.responsiveHeader}>My Test Date</div>
            <div className="u-margin-L-s">
              <div className="u-padding-T-l">{this.renderDaysUntilTest()}</div>
              <div className={style.timelineSection}>
                {this.props.overallScore.target ? (
                  <div>
                    <h3 className="u-margin-B-n">
                      <strong>{this.props.overallScore.target}</strong>
                      <Label
                        className="u-margin-L-xs"
                        type={labelType}
                        onClick={this.onClickTargetScoreStatus}>
                        {this.props.overallScore.scoreStatus.label || 'Status'}
                      </Label>
                    </h3>
                  </div>
                ) : (
                  <div>
                    <small>
                      <a href="/profile">Track your score goal</a>
                    </small>
                  </div>
                )}
                <small>Target score</small>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  renderWeekAssignments() {
    return (
      <Col sm={4} xs={12} className={cx(style.dailyPointsWrapper, style.timelineWrapper)}>
        <div className={style.responsiveHeader}>
          <span>
            <strong>Assignments due soon</strong>
          </span>
        </div>
        <span className={style.assignmentsLink}>
          <a href={paths.assignments()}>Go to assignments</a>
        </span>
        <div className={style.assignmentsList}>{this.renderAssignments(this.props.weekAssignments)}</div>
      </Col>
    );
  }

  renderAssignments(assignments) {
    if (assignments.length <= 0) {
      return 'You have no assignments due through the end of next week.';
    }

    const assignmentsByDay = groupBy(assignments, (assignment) => moment(assignment.dueDate).format('dddd'));

    return Object.keys(assignmentsByDay).map((day: string, dayIndex: number) => {
      const dayAssignments = assignmentsByDay[day];

      const assignmentsList = dayAssignments.map((assignment: Assignment, assignmentIndex: number) => {
        let submittedBadge = null;

        if (
          some(assignment.userAssignments, (userAssignment: UserAssignment) => {
            return userAssignment.state === UserAssignmentState.submitted;
          })
        ) {
          submittedBadge = (
            <Fragment>
              &nbsp;<Badge bsClass={cx('badge', 'submittedBadge')}>submitted</Badge>
            </Fragment>
          );
        }

        return (
          <div key={assignmentIndex}>
            {assignment.title}
            {submittedBadge}
          </div>
        );
      });

      return (
        <Row key={dayIndex}>
          <Col md={4}>
            <strong>{day}</strong>
          </Col>
          <Col md={8}>{assignmentsList}</Col>
        </Row>
      );
    });
  }

  renderDashboardTimeline() {
    return (
      <DashboardTimeline
        onEditStudyPreferences={this.onEditStudyPreferences}
        overallScore={this.props.overallScore}
        studyHours={this.props.studyHours}
        testDate={this.props.testDate}
        streak={this.props.studyStreak}
      />
    );
  }

  renderDaysUntilTest() {
    const testDate = this.props.testDate ? moment(this.props.testDate) : null;
    const daysUntilTest = testDate ? testDate.diff(moment().startOf('day'), 'days') : null;

    if (!testDate) {
      return (
        <div>
          <small>
            <a href="/profile">When is your next exam?</a>
          </small>
        </div>
      );
    }
    if (daysUntilTest >= 0) {
      return (
        <React.Fragment>
          <h3 className="u-margin-A-n">
            <strong>{daysUntilTest}</strong>
          </h3>
          <small>Days until {testDate.format('MMMM Do')}</small>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <small>Your test date has passed</small>
        <div>
          <small>
            <a href="/profile">When is your next exam?</a>
          </small>
        </div>
      </React.Fragment>
    );
  }

  renderDiagnosticPrompting = () => {
    return (
      <React.Fragment>
        <Col md={3}></Col>
        <Col md={6} className={cx(style.diagnosticWrapper, 'u-margin-T-m text-center')}>
          {this.props.diagnosticStatus === 'completed' && (
            <React.Fragment>
              <FontAwesomeIcon icon={'chart-line'} className={cx(style.diagnosticIcon, 'u-margin-R-xs')} />
              <span>
                <a href={paths.diagnosticStart()}>Review my study recommendations</a>
              </span>
            </React.Fragment>
          )}
          {this.props.diagnosticStatus === 'not_taken' && (
            <React.Fragment>
              <FontAwesomeIcon icon={'balance-scale'} className={cx(style.diagnosticIcon, 'u-margin-R-xs')} />
              <span>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="diagnostic-tooltip">You can always exit and come back to it.</Tooltip>
                  }>
                  <a href={paths.diagnosticStart()}>Take a diagnostic to get study recommendations</a>
                </OverlayTrigger>
              </span>
            </React.Fragment>
          )}
          {this.props.diagnosticStatus === 'in_progress' && (
            <React.Fragment>
              <FontAwesomeIcon icon={'list-ul'} className={cx(style.diagnosticIcon, 'u-margin-R-xs')} />
              <span>
                <a href={paths.diagnosticStart()}>Finish diagnostic to get study recommendations</a>
              </span>
            </React.Fragment>
          )}
        </Col>
      </React.Fragment>
    );
  };

  onEditStudyPreferences = () => {
    trackEvent('edit_study_plan', {
      category: 'study_commitment',
      location: 'dashboard'
    });

    this.props.onOpenModal();
  };

  onClickTargetScoreStatus = () => {
    trackEvent('status_tags', {
      location: 'near_target_score'
    });
  };
}
