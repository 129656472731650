import * as React from 'react';
import Multiselect from 'react-widgets/lib/Multiselect';

interface Props {
  data: any[];
  groupBy?: string;
  inputName: string;
  selectedValues: string[];
  textField: string;
  valueField: string;
}

export default class MultiselectWithInputs extends React.Component<Props> {
  state = {
    open: false,
    values: this.props.selectedValues
  };

  render() {
    return (
      <>
        <Multiselect
          data={this.props.data}
          value={this.state.values}
          filter='contains'
          valueField={this.props.valueField}
          textField={this.props.textField}
          groupBy={this.props.groupBy}
          onChange={value => {
            this.setState({ values: value });
          }}
          onSelect={() => this.setState({ open: false })}
          onToggle={() => this.setState({ open: !this.state.open }) }
          open={this.state.open}
        />
        {this.state.values.map(value => (
          <input name={this.props.inputName} key={value} value={value[this.props.valueField]} type='hidden' />
        ))}
      </>
    );
  }
}
