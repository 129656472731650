import * as React from 'react';
import { AssignmentsListProps, AssignmentCardProps } from './types';
import styles from "./style.module.scss"
import Tabs from '@magoosh/lib/tabs';
import moment from 'moment';
import StatusBadge from '@magoosh/pages/sectioned_assignments/organisms/status_badge';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import Desk from '@magoosh/assets/desk.svg';

const List: React.FC<AssignmentsListProps> = (props: AssignmentsListProps) => {

  let renderedAssignmentsCount = 0;

  return <div className={styles.listPage}>
    <div className={styles.breadcrumbs}>
      <a href={'/'}>Dashboard</a>
      &nbsp;&gt;&nbsp;
      Assignments
    </div>
    <h2>
      Assignments
    </h2>
    <Tabs defaultTab={'dueSoon'}>
      <Tabs.Tab tab={'dueSoon'}>Due Soon</Tabs.Tab>
      <Tabs.Tab tab={'completed'}>Completed</Tabs.Tab>

      <Tabs.Panel tab={'dueSoon'}>
        <h3>
          Due Soon
        </h3>
        <div className={styles.assignmentsList}>
          {(!props.dueSoonAssignments || props.dueSoonAssignments?.length == 0) && 'None'}
          {props.dueSoonAssignments?.map(assignment => (
            <AssignmentCard
              key={assignment.id}
              assignment={assignment}
              buttonStyle={(renderedAssignmentsCount++) > 0 ? 'default' : 'secondary'}
            />
          ))}
        </div>
        <h3>
          All Future Assignments
        </h3>
        <div className={styles.assignmentsList}>
          {(!props.futureAssignments || props.futureAssignments?.length == 0) && 'None'}
          {props.futureAssignments?.map(assignment => (
            <AssignmentCard
              key={assignment.id}
              assignment={assignment}
              buttonStyle={(renderedAssignmentsCount++) > 0 ? 'default' : 'secondary'}
            />
          ))}
        </div>
      </Tabs.Panel>
      <Tabs.Panel tab={'completed'}>
        <h3>
          Completed
        </h3>
        <div className={styles.assignmentsList}>
          {(!props.completedAssignments || props.completedAssignments?.length == 0) && 'None'}
          {props.completedAssignments?.map(assignment => (
            <AssignmentCard
              key={assignment.id}
              assignment={assignment}
              buttonStyle={(renderedAssignmentsCount++) > 0 ? 'default' : 'secondary'}
            />
          ))}
        </div>
      </Tabs.Panel>
    </Tabs>
  </div>
}

const AssignmentCard: React.FC<AssignmentCardProps> = (props) => {
  let ButtonTag;

  if (props.buttonStyle == 'default') {
    ButtonTag = DefaultButton;
  } else {
    ButtonTag = SecondaryButton;
  }

  return (
    <div className={styles.assignmentCard}>
      <div className={styles.icon}>
        <img alt="Desk" src={Desk} />
      </div>
      <div className={styles.title}>
        <h3>
          {props.assignment.title}
        </h3>
      </div>
      <div className={styles.info}>
        {props.assignment.classSectionName &&  <div className={styles.classSection}>
          Assigned as part of {props.assignment.classSectionName}
        </div>}
        <div className={styles.estimatedTime}>
          <FontAwesomeIcon icon={'clock-o'} />&nbsp;{props.assignment.estimatedTime} min.
        </div>
      </div>
      <div className={styles.dueDateAndGrading}>
        <div className={styles.dueDate}>
          <div>
            {props.assignment.dueDate && (
              <>
                Due {moment(props.assignment.dueDate).format('l')}{' '}
                by {moment(props.assignment.dueDate).format('h:mma')} (Pacific)
              </>
            )}
          </div>
          <div>
            <StatusBadge {...props.assignment} />
          </div>
        </div>
        {props.assignment.submitted && (
          <div className={styles.grading}>
            {props.assignment.grade == null ? 'Not graded' : `Grade: ${props.assignment.grade}`}
          </div>
        )}
      </div>
      <div className={styles.viewButton}>
        <ButtonTag href={paths.contentBlockAssignment(props.assignment)}>View</ButtonTag>
      </div>
    </div>
  );
}

export default List;
