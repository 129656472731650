import { useState, useEffect } from 'react';

/**
 * Usage:
 *
 * const pageContext = usePageContext()
 */
const usePageContext = () => {
  const [pageContext, setPageContext] = useState<any>({});

  useEffect(() => {
    setPageContext((window as any).pageContext || {});
  }, []);

  return pageContext;
};

export default usePageContext;
