import React from 'react';

import { InfoAlert } from '@magoosh/lib/alerts';
import AnswerFlag from '@magoosh/organisms/answer_flag';
import { paths } from 'config/path_helpers';
import { toSentence } from 'utilities/formatting';
import { secondsFormattedAsMinutes } from 'utilities/time';

import ResultIcon from './result_icon';
import { ExamSectionDetail } from '../types';

const AnswersTable: React.FC<ExamSectionDetail> = (props) => {
  const formatSubjectTags = (tags) => {
    if (!tags.length) return null;

    // Some answers have multiple subject tags with a repeated portion. This is especially common
    // for verbal subjects tags where there could be tags like follows on a single prompt:
    // - Sentence Equivalence: Tough Vocabulary, Sentence Equivalence: Psuedo-synonyms
    // This function alters the tags so that the shared "Sentence Equivalence: " portion is only
    // displayed once at the beginning.
    if (tags[0].indexOf(': ') !== -1) {
      const sharedTagBucket =  tags[0].split(': ')[0] + ': ';
      const adjustedTags = tags.map((tag, idx) => {
        if (idx === 0) {
          return tag;
        } else {
          return tag.replace(sharedTagBucket, '');
        }
      });
      return toSentence(adjustedTags);
    } else {
      return toSentence(tags);
    }
  };

  const answerUrl = (answer) => {
    if (answer.shareableId) {
      return paths.publicAnswer(answer.prompt.id, answer.id, answer.shareableId);
    } else {
      return paths.answer(answer.id);
    }
  };

  return (
    <div>
      {
        props.answersBySection.map((section, idx) => (
          <div key={idx}>
            {
              section.scoreReport && section.scoreReport.status === 'finished' && (
                <InfoAlert className="u-margin-B-s">
                  View your detailed <a href={paths.answerScoreReport(section.scoreReport.answerId)}>score report</a>
                </InfoAlert>
              )
            }

            <div className="d-flex">
              <div>
                <h3 className="u-margin-T-s">{section.name}</h3>
              </div>

              <div className="flex-1">
                <h3 className="text-right">
                  {section.isScored && <>{section.percentCorrect}% correct</>}
                </h3>
              </div>
            </div>

            {
              !!section.answers.length ? (
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      {section.answers.map((answer, answerIdx) => {
                        return (
                          <tr key={answer.id}>
                            <td style={{ paddingLeft: 0, width: '5%' }}>
                              <ResultIcon {...answer} />
                            </td>
                            <td style={{ paddingLeft: 0, width: '30%' }}>
                              <a href={answerUrl(answer)} target="_blank" rel="noopener">
                                Question {answerIdx + 1}
                              </a>
                            </td>
                            <td style={{ paddingLeft: 0, width: '40%' }}>{formatSubjectTags(answer.subjectTags)}</td>
                            <td style={{ paddingLeft: 0, width: '10%' }}>{answer.difficulty}</td>
                            <td style={{ paddingLeft: 0, width: '10%' }}>{secondsFormattedAsMinutes(answer.pace)}</td>
                            {
                              !answer.shareableId && (
                                <td style={{ paddingLeft: 0, width: '5%' }}>
                                  <AnswerFlag flagged={answer.isFlagged} promptId={answer.prompt.id} />
                                </td>
                              )
                            }
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>You didn't answer any questions in this section.</div>
              )
            }
          </div>
        ))
      }
    </div>
  );
};

export default AnswersTable;
