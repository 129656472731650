import React from 'react';
import { List } from '@magoosh/admin/module_track_templates/list';
import { QueryClient, QueryClientProvider } from 'react-query';

export default class ModuleTrackTemplates extends React.Component {
  render() {
    const queryClient = new QueryClient();

    return (
      <QueryClientProvider client={queryClient}>
        <List />
      </QueryClientProvider>
    )
  }
}
