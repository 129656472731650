import React from 'react';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import { UserContextInterface } from './types';

const UserContext = React.createContext<UserContextInterface | null>(null);

export const useUserContext = () => {
  const context = React.useContext(UserContext) as UserContextInterface;
  if (context === undefined) {
    throw new Error('You must wrap your page with <UserProvider /> in order to useUserContext().');
  }
  return context;
};

interface Props {
  initialData?: UserContextInterface;
}

export const UserProvider: React.FC<Props> = ({ children, initialData }) => {
  const defaultData = {
    firstName: '',
    lastName: '',
    email: '',
    timeZone: null
  };

  const [data, setData] = React.useState(initialData || defaultData);

  React.useEffect(() => {
    if (initialData) return;
    fetchData();
  }, []);

  const fetchData = React.useCallback(() => {
    fetch(paths.api.userInfo()).then((response) => {
      setData({ ...data, ...response });
    });
  }, [data]);

  const providerValue = React.useMemo(() => data, [data]);

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
};
