import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

interface Props {
  time: number; // UTC timestamp in seconds
}

const TimeAgo: React.FC<Props> = (props) => {
  moment.updateLocale('en', {
    relativeTime: {
      // s: We override this one below in render
      ss: (number, withoutSuffix, key, isFuture) => {
        return 'A few seconds';
      },
      m: 'A minute or two',
      mm: (number, withoutSuffix, key, isFuture) => {
        if (number >= 5) {
          return `About ${Math.round(number / 5) * 5} minutes`;
        } else if (number > 2) {
          return 'A few minutes';
        } else {
          return 'A minute or two';
        }
      },
      h: '1 hour',
      d: '1 day',
      M: '1 month',
      y: '1 year'
    }
  });

  moment.relativeTimeThreshold('s', 60);
  moment.relativeTimeThreshold('ss', 15);
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 24);
  moment.relativeTimeThreshold('d', 30);
  moment.relativeTimeThreshold('M', 12);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  const updateTimeAgoInterval = useRef(null);
  const [time, setTime] = useState(moment(props.time * 1000).tz(timeZone));

  useEffect(() => {
    if (updateTimeAgoInterval.current) return;

    updateTimeAgoInterval.current = setInterval(updateTimeAgo, 20000);

    return () => {
      clearInterval(updateTimeAgoInterval.current);
    }
  }, []);

  const updateTimeAgo = () => {
    setTime(moment(props.time * 1000).tz(timeZone));
  };

  return (
    <>
      {time.fromNow() === 'a few seconds ago' ? 'Just now' : time.fromNow()}
    </>
  );
};

export default TimeAgo;
