export enum CARSDailyQuestionState {
  UNANSWERED = 'unanswered',
  STARTED = 'started',
  COMPLETED = 'completed',
  DISMISSED = 'dismissed'
}

export interface ExamSection {
  name: string;
  id: number;
  estimatedScore: number;
  questionsAnswered: number;
  answersNeededForEstimate: number;
  totalQuestions: number;
  lessonsReviewed: number;
  totalLessons: number;
  slug: string;
}

export interface SeriesRecommendationsData {
  section: string;
  series: SeriesData[];
}

export interface SeriesData {
  slug: string;
  name: string;
  locked: boolean;
}
