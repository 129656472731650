import * as React from 'react';
import { PracticePreviewData } from '@magoosh/organisms/study_schedule/types';
import CustomModal from '@magoosh/organisms/study_schedule/practice_session_preview_modal/custom_modal';
import { useState } from 'react';
import PopupBanner from '@magoosh/lib/popup_banner';
import { pluralize } from 'utilities/pluralize';
import { secondsFormattedAsMinutesString } from 'utilities/time';
import cx from 'classnames';
import style from './style.module.scss';
import { PrimaryButton } from '@magoosh/lib/button';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import { Modal as BootstrapModal } from 'react-bootstrap';

interface PracticeSessionPreviewModalProps extends PracticePreviewData {
  onHide: () => any;
}

const LSATPracticeSessionPreviewModal: React.FC<PracticeSessionPreviewModalProps> = (props) => {
  const [show, setShow] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  let timeSummary;
  if (props.timeLimitSeconds) {
      timeSummary = (
        <React.Fragment>
          {' '}
          to answer within <strong>{secondsFormattedAsMinutesString(props.timeLimitSeconds)}</strong>
        </React.Fragment>
      );
    } else if (props.estimatedTimeMinutes) {
      timeSummary = (
        <React.Fragment>
          {' '}
          and take around <strong>{pluralize(props.estimatedTimeMinutes, 'minute')}</strong>
        </React.Fragment>
      );
    }

  const renderErrorMessage = () => {
    return (
      <PopupBanner bsStyle={'danger'} onSelfDestruct={() => setErrorMessage(null)}>
        {errorMessage}
      </PopupBanner>
    );
  };

  const renderDescriptionHeader = () => {
    if (props.examSection) {
      return (
        <h3 className={'u-margin-B-l'}>
          {props.exam} Practice {`>`} <strong>{props.examSection}</strong>
        </h3>
      );
    } else {
      return <h3 className={'u-margin-B-l'}>{props.exam} Practice</h3>;
    }
  };

  const renderRepeatBanner = () => {
    return (
      <div className={cx('u-margin-V-s', style.noteBanner, style.lsatBanner)}>
        <span>
          You don't have {pluralize(props.questionCount, props.questionType)} available, so this session will
          include some questions that you've seen before.
        </span>
      </div>
    );
  };

  const onSubmit = () => {
    setSubmitting(true);
    fetch(paths.api.studySchedulePractice(props.blockId), {
      method: 'POST',
      body: JSON.stringify({ ...props, startSession: true })
    })
      .then(handleSubmitSuccess)
      .catch(handleSubmitError);
  };

  const handleSubmitSuccess = (response) => {
    if (response.redirect) {
      window.location.assign(response.redirect);
    } else {
      setSubmitting(false);
      setErrorMessage('Something went wrong. Please reach out to Student Help for assistance.');
    }
  };

  const handleSubmitError = (response) => {
    setSubmitting(false);
    setErrorMessage(response.errors._error);
  };

  return (
    <BootstrapModal backdrop={true} onHide={props.onHide} show={show}>
      <div className="u-padding-A-m">
        {errorMessage && renderErrorMessage()}
        {renderDescriptionHeader()}
        {props.includeOldPrompts && renderRepeatBanner()}
        {(props.questionCount > 0) && (
          <p>
            Your practice session will include{' '}
            <strong>{pluralize(props.questionCount, props.questionType)}</strong>
            {timeSummary}.
          </p>
        )}
        <p>
          Before you start, take 30 seconds for a couple deep breaths. Visualize yourself performing at your
          best, and remember, you’re going to be a lawyer! Good luck!
        </p>
        <div className="text-center u-marin-V-l">
          <PrimaryButton block={true} onClick={onSubmit} type={'submit'} submitting={submitting}>
            Start the Session
          </PrimaryButton>
        </div>
      </div>
    </BootstrapModal>
  );
};

export default LSATPracticeSessionPreviewModal;
