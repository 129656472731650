import React, { useState } from 'react';
import cx from 'classnames';
import { Col, Collapse, Grid, Row } from 'react-bootstrap';

import { ModalProvider, useModalContext } from '@magoosh/context/modal_context';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';
import { formatPrice } from 'utilities/money';

import PurchaseConfirmation from '@payments/components/purchase_confirmation';
import { Offer } from '@payments/types';

import HeadshotEric from './assets/mba_admissions/headshot_eric.png';
import HeadshotJen from './assets/mba_admissions/headshot_jen.png';
import HeadshotVeronica from './assets/mba_admissions/headshot_veronica.png';
import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

const CLASS_DETAILS = [
  {
    description: "Our Application Tracker takes you through the entire application process from start to finish with a comprehensive set of weekly tasks customized to your specific program deadlines.",
    name: "A Central Hub for Managing Applications"
  },
  {
    description: "Go from blank page to well-written draft with our Guided Writing experience.",
    name: "Step-by-Step Guided Essay Writing"
  },
  {
    description: "Applicant Profiles highlight real MBA applications from Magoosh students. Each profile includes detailed stats on the student, along with key application elements such as college transcripts, resumes/CVs, essays, letters of recommendation, and interview questions.",
    name: "30+ Real Applicant Profiles"
  },
  {
    description:
      'With decades of combined experience working with students and b-schools, our diverse team of admissions experts share the deep knowledge you need to put your best foot forward, including how to build an MBA brand, mitigate your weaknesses, craft a compelling resume, and more.',
    name: 'Expert Advice from Admissions Insiders'
  },
  {
    description:
      'Our engaging and in-depth video walkthroughs guide you through the entire application process, from choosing the right schools to preparing for your interviews.',
    name: '30+ MBA Application Videos'
  },
  {
    description:
      "Real essays and resumes/CVs from graduate program applicants! Select writing examples are paired with detailed feedback from a Magoosh Admissions Expert on what works and what doesn't.",
    name: 'Annotated Essay and Resume/CV Examples'
  },
  {
    description:
      'Got questions? Reach out to our Help team for quick email support from an MBA Admissions expert.',
    name: 'Ask an Expert'
  },
  {
    description:
      'Full access to Magoosh MBA Admissions for 12 months. Pause your plan at any time if your application plans change.',
    name: '12 Months of Access'
  }
];

const AdmissionsAddOn: React.FC<Offer & { compact?: boolean }> = (props) => {
  const [expanded, setExpanded] = useState(!props.compact);

  const { setModal } = useModalContext();

  const totalExcludingTax = props.order.pricing.totalExcludingTax;
  const planPrice = props.order.subscriptionPlan.priceCents;
  const isDiscounted = totalExcludingTax !== planPrice;

  const handleAddOnPurchaseClicked = () => {
    if (props.paymentDetails) {
      setModal({
        header: 'Add MBA Admissions Support',
        body: (
          <PurchaseConfirmation
            orderName="MBA Admissions Support"
            handleClose={() => setModal(null)}
            successMessage='You can access your MBA Admissions videos using the "Admissions" button in your GMAT dashboard.'
            {...props}
          />
        )
      });
    } else {
      window.location.href = `${paths.subscriptions(props.order.subscriptionPlan.planSlug)}?coupon=${
        props.order.pricing.couponCode
      }`;
    }
  };

  return (
    <div className={cx(style.container, 'd-flex flex-column', { 'u-padding-T-m u-padding-B-xl': !props.compact })}>
      {
        !props.compact && (
          <div>
            <h4 className="text-gray">One-Time Offer</h4>
            <h2 className="u-margin-T-n">
              Add MBA Admissions Support for{' '}
              {
                isDiscounted && (
                  <>
                    <span style={{ textDecoration: 'line-through' }}>{formatPrice(planPrice, 'usd', true)}</span>{' '}
                  </>
                )
              }
              {formatPrice(totalExcludingTax, 'usd', true)}!
            </h2>

            <hr className="u-margin-T-n" />
          </div>
        )
      }

      <div>
        <p>Our Admissions experts will walk you through the entire business school application process.</p>

        {
          isDiscounted && (
            <p>
              <strong>Plus - if you sign up now, you’ll get a big discount</strong> (regular price is{' '}
              {formatPrice(planPrice, 'usd', true)})!
            </p>
          )
        }

        <div className={cx('d-flex u-margin-V-l', style.ctas)}>
          <SecondaryButton
            href={props.paymentDetails ? null : props.orderUrl}
            onClick={props.paymentDetails ? handleAddOnPurchaseClicked : null}
            target="_blank"
          >
            Yes, Add <span className="hidden-xs">MBA Admissions Support</span> for{' '}
            {formatPrice(totalExcludingTax, 'usd', true)}
          </SecondaryButton>

          {props.nextPath && (
            <DefaultButton className="u-margin-L-s" href={props.nextPath}>
              No Thanks
            </DefaultButton>
          )}
        </div>

        <div
          className="border-radius-all u-margin-V-l u-padding-A-m"
          style={{ backgroundColor: '#F5EEFC' }}>
          <p className="text-uppercase">
            <strong>MBA ADMISSIONS SUPPORT INCLUDES:</strong>
          </p>

          {CLASS_DETAILS.map((classDetail, idx) => (
            <ClassDetail key={idx} classNumber={idx + 1} {...classDetail} />
          ))}
        </div>

        {!expanded && <a className="text-gray-light" onClick={() => setExpanded(true)}>See More</a>}

        {
          expanded && (
            <>
              <div className="u-margin-T-xl">
                <div className="u-margin-B-s">
                  <strong>
                    <u>Meet the Magoosh Admissions Teaching Team</u>
                  </strong>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <img
                      alt="Derrick Photo"
                      className="img-circle"
                      src={HeadshotEric}
                      style={{ objectFit: 'cover', height: 100, width: 100 }}
                    />
                    <div className="u-margin-T-s small text-center">Eric Allen</div>
                  </div>

                  <div>
                    <img
                      alt="Taryn Photo"
                      className="img-circle"
                      src={HeadshotJen}
                      style={{ objectFit: 'cover', height: 100, width: 100 }}
                    />
                    <div className="u-margin-T-s small text-center">Jen Jurgens</div>
                  </div>

                  <div>
                    <img
                      alt="Linnea Photo"
                      className="img-circle"
                      src={HeadshotVeronica}
                      style={{ objectFit: 'cover', height: 100, width: 100 }}
                    />
                    <div className="u-margin-T-s small text-center">Veronica Mandujano</div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

interface ClassDetailProps {
  classNumber: number;
  description: string;
  name: string;
}

const ClassDetail: React.FC<ClassDetailProps> = ({ classNumber, description, name }) => {
  const [isExpanded, setIsExpanded] = React.useState(classNumber === 1);

  return (
    <div className="u-margin-T-s">
      <div onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer' }}>
        <strong>
          <span className="u-margin-R-xs" style={{ color: colors.brandFuchsia }}>
            <span className="u-margin-R-xxs">
              <FontAwesomeIcon icon={isExpanded ? 'minus' : 'plus'} />
            </span>
            <span>{name}</span>
          </span>
        </strong>
      </div>

      <Collapse in={isExpanded}>
        <div>{description}</div>
      </Collapse>
    </div>
  );
};

const AdmissionsAddOnWithProvider: React.FC<Offer> = (props) => {
  return (
    <ModalProvider>
      <AdmissionsAddOn {...props} />
    </ModalProvider>
  );
};

export default AdmissionsAddOnWithProvider;
