import * as React from 'react';

import { FadeIn } from './fade_in';
import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import { LoadingBars } from '@magoosh/lib/icons';
import { StudyScheduleData } from '../types';

import style from './style.module.scss';
import cx from 'classnames';
import trackEvent from 'utilities/track_event';
import moment from 'moment';

interface Props {
  alternativePlans: StudyScheduleData[];
  onComplete: (id: number) => void;
  recommendedPlan: StudyScheduleData;
}

interface State {
  showSpinner: boolean;
}

// This component includes a spinner step that shows for 3 seconds before the actual
// recommendations appear. This delay period is only a tactic to make the user feel like we are
// working on making their recommendation, with the idea that they will trust it more if it took
// us some time to generate. There is no technical reason the delay and spinner are needed!
export default class RecommendedPlanStep extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showSpinner: false
      });
    }, 2000);
  }

  render() {
    if (this.state.showSpinner) {
      return this.renderSpinner();
    } else {
      return this.renderRecommendedPlan();
    }
  }

  renderRecommendedPlan() {
    const { alternativePlans, recommendedPlan, onComplete } = this.props;

    return (
      <FadeIn>
        <div className="text-center">
          <h3 className="u-margin-A-n">Your Study Schedule</h3>

          <p className="u-margin-T-m">
            We think you'll be successful with our
            <br />
            {recommendedPlan.name} schedule!
          </p>
          {recommendedPlan.description && <p>{recommendedPlan.description}</p>}

          <div className="u-margin-B-m u-margin-T-l">
            <PrimaryButton
              className={cx(style.primaryButton, 'btn-multi-line')}
              data={recommendedPlan.id}
              onClick={onComplete}>
              Use {recommendedPlan.name}
            </PrimaryButton>
          </div>

          <hr className="u-margin-V-s" />

          <div className="u-margin-T-s">
            <div className="small">Not quite right? Choose from one of our other options.</div>
            {alternativePlans.map((plan) => (
              <DefaultButton
                className="u-margin-R-xxs u-margin-T-xxs d-inline btn-multi-line"
                data={plan.id}
                key={plan.id}
                onClick={onComplete}
                size="xs">
                {plan.name}
              </DefaultButton>
            ))}
          </div>
        </div>
      </FadeIn>
    );
  }

  renderSpinner() {
    return (
      <div className="align-items-center d-flex flex-column justify-content-center" style={{ flexGrow: 1 }}>
        <div>Finding your perfect study schedule</div>
        <div>
          <LoadingBars height={100} width={100} />
        </div>
      </div>
    );
  }
}
