import * as React from 'react';

import { PrimaryButton } from '@magoosh/lib/button';
import { DateSelect } from '@magoosh/lib/forms';

import style from './style.module.scss';
import cx from 'classnames';

interface Props {
  examName: string;
  onComplete: (date: string) => void;
  testDate: string;
}

interface State {
  testDate: string;
}

export default class TestDateStep extends React.Component<Props, State> {
  handleDateChange = (date: string) => {
    this.setState({ testDate: date });
  }

  handleOnComplete = () => {
    this.props.onComplete(this.state.testDate);
  };

  constructor(props) {
    super(props);
    this.state = {
      testDate: props.testDate
    };
  }

  render() {
    const { examName, onComplete, testDate } = this.props
  
    return (
      <React.Fragment>
        <div className="text-center" style={{ flexGrow: 1 }}>
          <h3 className="u-margin-A-n">Confirm Your Test Date</h3>

          <div className="u-margin-T-m">
            Enter your upcoming {examName} test date to let us know how long you have to get ready!
          </div>

          <div className={cx('u-margin-B-xs u-margin-T-l', style.testDate)}>
            <DateSelect
              inline
              onChange={this.handleDateChange}
              selectClassName="u-margin-B-n u-margin-R-xs"
              value={this.state.testDate}
            />
          </div>

          <div className="u-margin-T-s h7 text-gray-light">
            Don't know your test date yet? We still recommend that you choose a date closest to when you think
            you will be taking the {examName}.
          </div>
        </div>

        <div className="text-center">
          <PrimaryButton className={style.primaryButton} onClick={this.handleOnComplete}>
            Next
          </PrimaryButton>
        </div>
      </React.Fragment>
    );
  }
}
