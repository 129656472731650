import * as React from 'react';
import 'react-table/react-table.css';
import { StudentResponseData, TeacherDashboardData } from './types';
import ClassTable from './class_table/container';
import { fetchStudentData } from './services/student_data_fetcher';
import style from './style.module.scss';
import StudentPerformance from '@magoosh/b2b/pages/school_report/student_performance';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import * as moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DropdownList from 'react-widgets/lib/DropdownList';

moment.locale('en');
momentLocalizer(moment);

interface TeacherDashboardProps extends TeacherDashboardData {
  onFetchStudentData: (response) => any;
  onPickClassFilter: (integer) => any;
  onResetStudentData: () => any;
}

export default class TeacherDashboard extends React.Component<TeacherDashboardProps> {
  state = {
    loading: true,
    noDataText: '',
    startDate: null,
    endDate: null
  };

  asyncController = new AbortController();

  componentDidMount() {
    this.startFetchStudentData();
  }

  render() {
    return (
      <div>
        <div className={style.header}>
          <h1>Classes</h1>
          {this.renderAccountsLink()}
        </div>
        {this.renderStudentPerformance()}
        <div className={style.sectionHeader}>Class Detail</div>
        <div className={style.filterSelect}>
          <div className={style.classSelect}>
            <DropdownList
              filter="contains"
              data={this.getClassSections()}
              onChange={(selection) => this.onChangeClassSelect(selection.id)}
              placeholder="All Classes"
              textField="displayName"
              valueField="id"
            />
          </div>
          {this.renderDateFilter('startDate', null, this.state.endDate || new Date(), 'Start Date')}
          {this.renderDateFilter('endDate', this.state.startDate || new Date(), new Date(), 'End Date')}
        </div>
        <ClassTable
          loading={this.state.loading}
          noDataText={this.state.noDataText}
          showingRange={this.state.startDate || this.state.endDate}
        />
        <span className="pull-right u-margin-V-m">
          Are we missing something in the new class report?{' '}
          <a href="/students">See the old student activity page.</a>
        </span>
      </div>
    );
  }

  getClassSections = () => {
    let sections = [{ id: null, displayName: 'All Classes' }];
    return sections.concat(this.props.classSections);
  };

  applyDateFilters = () => {
    this.setState({ loading: true });
    this.props.onResetStudentData().then(() => {
      this.startFetchStudentData(true);
    });
  };

  renderAccountsLink = () => {
    return this.props.couponUser ? this.renderCouponLink() : this.renderProvisionLink();
  };

  renderCouponLink = () => {
    return (
      <a href="/coupons" className="pull-right">
        {this.props.usedAccounts} of {this.props.totalAccounts} accounts created
      </a>
    );
  };

  renderDateFilter = (stateAttribute, min, max, placeHolder) => {
    const currentStateValue = this.state[stateAttribute];

    return (
      <DateTimePicker
        onChange={(value) => {
          const newState = new Object();
          newState[stateAttribute] = value;

          this.setState(newState, () => {
            this.applyDateFilters();
          });
        }}
        value={currentStateValue}
        format="MM/DD/YYYY"
        time={false}
        min={min}
        max={max}
        className={style.datePicker}
        placeholder={placeHolder}
      />
    );
  };

  renderProvisionLink = () => {
    if (this.props.usedAccounts < this.props.totalAccounts) {
      return (
        <a href="/classes/provision" className="pull-right">
          Assign student accounts
        </a>
      );
    } else {
      return <p className="pull-right">All accounts provisioned</p>;
    }
  };

  renderStudentPerformance = () => {
    if (this.props.showPerformanceData) {
      return <StudentPerformance classSectionsByGrade={this.props.classSectionsByGrade} />;
    }

    return null;
  };

  onChangeClassSelect = (id) => {
    this.props.onPickClassFilter(id);
  };

  startFetchStudentData = (reset = false) => {
    const startDate = this.state.startDate ? moment(this.state.startDate).format('MM/DD/YYYY') : null;
    const endDate = this.state.endDate ? moment(this.state.endDate).format('MM/DD/YYYY') : null;
    const prevStudentCount = this.props.students.length;

    // If resetting, cancel any network requests in progress
    if (reset) {
      this.asyncController.abort();
      this.asyncController = new AbortController();
    }

    fetchStudentData(reset ? 1 : this.props.page + 1, startDate, endDate, {
      signal: this.asyncController.signal
    })
      .then((response: StudentResponseData) => {
        this.setState({ loading: false, noDataText: 'No students' });
        this.props.onFetchStudentData(response);
        if (
          this.props.students.length !== prevStudentCount &&
          this.props.students.length < response.totalStudents
        ) {
          this.startFetchStudentData();
        }
      })
      .catch(() => {
        // No-op. Request was aborted because filters changed most likely
      });
  };
}
