import { Reducer } from 'redux';
import { findIndex } from 'lodash';

import { ActionTypes } from './actions';
import { StudyScheduleStore } from './types';
import { StudyItemData } from '../study_items/types';

const initialState = {
  currentStudySchedule: null,
  studySchedules: [],
  suggestedLessons: [],
  showExamSectionFocusStep: false,
  showStudyExperienceStep: false,
  ui: {
    presentPlanOptions: false
  },
  userProfile: {}
};

export const studyScheduleReducer: Reducer<StudyScheduleStore> = (state = initialState, action) => {
  let userProfile;

  switch (action.type) {
    case ActionTypes.INITIALIZE:
      return { ...state, ...action.data };

    case ActionTypes.SET_ITEM_COMPLETED:
      const { currentDay } = state.currentStudySchedule;
      const index = findIndex(currentDay.items, (item: StudyItemData) => item.id === action.id);
      currentDay.items[index] = { ...currentDay.items[index], completed: action.isCompleted };
      return { ...state, currentStudySchedule: { ...state.currentStudySchedule, currentDay } };

    case ActionTypes.SET_PLAN:
      return { ...state, currentStudySchedule: action.plan };

    case ActionTypes.TOGGLE_PLAN_OPTIONS:
      const ui = { ...state.ui, presentPlanOptions: action.value };
      return { ...state, ui };

    case ActionTypes.UPDATE_USER_PROFILE:
      userProfile = { ...state.userProfile, ...action.userProfile };
      return { ...state, userProfile };

    case ActionTypes.OPT_OUT:
      userProfile = { ...state.userProfile, hasCompletedSyllabusOnboarding: true };
      return { ...state, currentStudySchedule: null, userProfile };

    case ActionTypes.SET_SUGGESTED_LESSONS:
      return { ...state, suggestedLessons: action.suggestedLessons };

    default:
      return state;
  }
};
