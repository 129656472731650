import {connect} from "react-redux";
import {RootStore} from "@magoosh/store";
import StudentPerformance from "./template";

import {
  getData,
  resetFilters,
  setData,
  setFilter,
  setLoadingMode,
  updateFilter,
} from './actions'

const mapStateToProps = (state: RootStore) => {
  return { ...state.studentPerformance };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onGetData: () => dispatch(getData()),
    resetFilters,
    setData,
    setFilter,
    setLoadingMode,
    updateFilter
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentPerformance);
