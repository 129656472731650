import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import StatusLabel from './status_label';
import { PromptRequest } from './types';

interface Props {
  promptRequest: PromptRequest;
}

const ContentRequestsStatus: React.FC<Props> = (props) => {
  const [showDetails, setShowDetails] = useState({});

  const toggleDetails = (id: number) => {
    if (showDetails[id]) {
      setShowDetails({ ...showDetails, [id]: false });
    } else {
      setShowDetails({ ...showDetails, [id]: true });
    }
  };

  return (
    <div className="d-flex flex-column gap-s">
      {
        props.promptRequest.contentRequests.map((contentRequest) => (
          <div key={contentRequest.id} className="well well-highlight">
            <div className="align-items-center d-flex">
              <div className="flex-1">
                {contentRequest.contentType} <StatusLabel status={contentRequest.status} />
              </div>

              <div>
                <a className="text-gray-light" onClick={() => toggleDetails(contentRequest.id)}>
                  <small>{showDetails[contentRequest.id] ? 'Hide Details' : 'Show Details'}</small>
                </a>
              </div>
            </div>

            <Collapse in={!!showDetails[contentRequest.id]}>
              <div>
                <div className="d-flex flex-column gap-s" style={{ overflow: 'hidden' }}>
                  <hr className="u-margin-V-s" />
                  {
                    contentRequest.contentProducer && (
                      <div><strong>{contentRequest.contentProducer.name}</strong></div>
                    )
                  }

                  <div>
                    <ul>
                      {
                        contentRequest.children.map((childContentRequest) => (
                          <li key={childContentRequest.id}>
                            {childContentRequest.contentType} {childContentRequest.draftPromptId} <StatusLabel status={childContentRequest.status} />
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        ))
      }
    </div>
  );
};

export default ContentRequestsStatus;
