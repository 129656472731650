import * as React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import style from './style.module.scss';
import { StudentReportData } from "./types";
import { StudentPerformanceSummary } from './performance_summary'
import { StudentPracticeScores } from './practice_scores'
import { StudentStrengthWeaknesses } from './strength_weaknesses'
import { StudentUsageSummary } from './usage_summary'

export default class StudentReport extends React.Component<StudentReportData> {

  render() {
    return (
      <div>
        <a href="/classes" className={style.backLink}>Back to Classes</a>
        <h1>{this.props.name}</h1>
        <div className={style.email}>{this.props.email}</div>
        {this.renderSubscriptions(this.props.subscriptions)}
        <a href={"/students/" + this.props.id} className={style.oldReportLink}>Old Student Report
          page</a>
      </div>
    )
  }

  renderSubscriptions = (subscriptions) => {
    return (
      <Tabs id='student-report-subscription-tabs' bsStyle='pills'>
        {Object.keys(subscriptions).map((name, index) => {
          const subscription = subscriptions[name];
          let performanceSummary = null;

          if (['GMAT', 'IELTS', 'TOEFL'].indexOf(name) === -1) {
            performanceSummary = (<StudentPerformanceSummary {...subscription} />);
          }

          return (
            <Tab className={style.brandingTab} title={name} key={name} eventKey={index}>
              {performanceSummary}
              <StudentPracticeScores {...subscription} />
              <StudentStrengthWeaknesses {...subscription} />
              <StudentUsageSummary {...subscription} />
            </Tab>
          )
        })}
      </Tabs>
    )
  };
}
