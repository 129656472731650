import * as React from 'react';
import { Annotation, Evaluation } from '../types';
import AuthorIcon from '../author_icon/template';
import AnnotationBox from '../annotation_sidebar/annotation_box';
import avatar1 from '../illustrated-avatars/1.png';
import avatar2 from '../illustrated-avatars/2.png';
import avatar3 from '../illustrated-avatars/3.png';
import avatar4 from '../illustrated-avatars/4.png';
import avatar5 from '../illustrated-avatars/5.png';

interface AnnotationSidebarProps {
  evaluation: Evaluation;
  currentAnnotation: Annotation;
}

export default class AnnotationSidebar extends React.Component<AnnotationSidebarProps> {
  render() {
    return (
      <div>
        <div className={'text-center'}>
          <h5>Evaluation & Commentary</h5>
          <AuthorIcon
            imagePath={
              this.props.evaluation.author.imagePath || this.illustratedAvatars[this.props.evaluation.id % 5]
            }
            description={this.props.evaluation.author.description}
          />
        </div>

        {this.props.evaluation.annotations.map((annotation, index) => {
          return (
            <AnnotationBox
              key={annotation.startIndex}
              text={annotation.text}
              active={annotation == this.props.currentAnnotation}
              index={index}
            />
          );
        })}
      </div>
    );
  }

  illustratedAvatars = [avatar1, avatar2, avatar3, avatar4, avatar5];
}
