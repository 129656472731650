import * as React from 'react';
import { LessonModuleData } from '../types';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import configureStore from '@magoosh/store';
import { ActionTypes } from '../actions';
import HighSchoolLessonModulesContainer from './container';

class HighSchoolLessonModulesIndex extends React.Component<LessonModuleData> {
  store = configureStore();

  constructor(props) {
    super(props);
    this.store.dispatch({ type: ActionTypes.INITIALIZE, data: props });
  }

  render() {
    return (
      <Provider store={this.store}>
        <HighSchoolLessonModulesContainer />
      </Provider>
    );
  }
}

export default hot(HighSchoolLessonModulesIndex);
