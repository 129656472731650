import React from 'react';
import cx from 'classnames';

import Contents from '@magoosh/lib/contentful_contents';
import SectionNav from '@magoosh/lib/contentful_contents/section_nav';
import CTA from '@magoosh/organisms/cta_card';

import style from './style.module.scss';

interface Props {
  editorialPage: any;
  preview?: boolean;
  offsetY?: number;
  stickyCTAOnMobile?: boolean;
}

const Page: React.FC<Props> = (props) => {
  const offsetY = props.offsetY ? props.offsetY : 0;
  const sectionNavHeight = 54;
  const stickyCTAOnMobile = props.hasOwnProperty('stickyCTAOnMobile') ? props.stickyCTAOnMobile : true;

  return (
    <div className="container d-flex flex-column">
      <h2 className="text-gray-darker u-margin-A-n"><strong>{props.editorialPage.name}</strong></h2>

      <div className="flex-1 u-margin-T-xxs" style={{ position: 'relative' }}>
        <div
          className="hidden-xs"
          style={{
            backgroundColor: '#fff',
            position: 'sticky',
            top: offsetY
          }}
        >
          <SectionNav
            height={sectionNavHeight}
            offsetY={sectionNavHeight + offsetY}
            sections={props.editorialPage.contents || []}
          />
        </div>

        <hr className="u-margin-T-xs" />

        <div className="u-margin-T-l">
          <div className={cx(style.contents, 'd-flex')}>
            <div className={cx(style.contentsSections, 'd-flex flex-column flex-1')}>
              <Contents contents={props.editorialPage.contents} />
            </div>

            <div className={style.contentsSidebar}>
              {
                props.editorialPage.cta && (
                  <CTA
                    {...props.editorialPage.cta}
                    mobileOffsetY={offsetY}
                    offsetY={sectionNavHeight + offsetY}
                    stickyCTAOnMobile={stickyCTAOnMobile}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
