import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import { isNull, map, max } from 'lodash';

import { ColorPalette } from 'types/constants';

import style from '../style.module.scss';

interface Props {
  chartProps?: any;
  colorPalette: ColorPalette;
  data: number;
  interior?:  string | React.ReactNode | ((data: any) => React.ReactNode);
  size: number;
}

const SimpleDonutChart: React.FC<Props> = (props) => {
  // Always show at least a sliver of progress so that it is obvious that this is a donut chart
  const value = max([props.data, 1]);

  const data = [
    {
      id: 'shaded',
      label: 'shaded',
      value: value,
      color: props.colorPalette.dark
    },
    {
      id: 'unshaded',
      label: 'unshaded',
      value: 100 - value,
      color: props.colorPalette.light
    }
  ];

  return (
    <div className={style.chart} style={{ height: props.size, position: 'relative', width: props.size }}>
      <ResponsivePie
        data={data}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        fit
        innerRadius={0.7}
        isInteractive={false}
        colors={data.map((datum) => datum.color)}
        {...props.chartProps}
      />

      <div
        className="align-items-center d-flex flex-1 justify-content-center"
        style={{
          height: props.size,
          left: 0,
          position: 'absolute',
          top: 0,
          width: props.size
        }}
      >
        {
          props.interior ? (
            (() => {
              if (typeof props.interior === 'function') {
                return props.interior(props.data);
              } else if (typeof props.interior === 'string') {
                return <span dangerouslySetInnerHTML={{ __html: props.interior }} />;
              } else {
                return props.interior;
              }
            })()
          ) : (
            <strong>
              {
                isNull(props.data) ? (
                  <span style={{ color: props.colorPalette.dark, fontSize: props.size * 0.2 }}>-</span>
                ) : (
                  <>
                    <span style={{ color: props.colorPalette.dark, fontSize: props.size * 0.25 }}>{props.data}</span>
                    <span style={{ color: props.colorPalette.dark, fontSize: props.size * 0.15 }}>%</span>
                  </>
                )
              }
            </strong>
          )
        }
      </div>
    </div>
  );
};

export default SimpleDonutChart;
