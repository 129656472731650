import React from 'react';

const GREDetailsContent: React.FC = (props) => {
  return (
    <>
      <h4>Preparation</h4>
      <p>Just like with the real test, you should have a <strong>pen or pencil</strong> and <strong>scratch paper</strong> to write notes and work out problems. An <strong>on-screen calculator</strong> is provided for the sections where one is allowed.</p>
      <p>You should also set aside <strong>about 2 hours</strong> for a full test. Let your family and friends know not to interrupt you during this time. :)</p>
      <p>We recommend taking the entire test in one sitting to practice your pacing and build your test-taking stamina, which you'll need on test day. Good luck!</p>
    </>
  );
};

export default GREDetailsContent;
