import * as React from 'react';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { AccessLevel } from '@magoosh/admin/content_block_editor/types';
import { DangerButton, PrimaryButton } from '@magoosh/lib/button';
import { SimpleFieldCheckbox } from '@magoosh/lib/field_checkbox/template';
import { FieldDropdownList } from '@magoosh/lib/formik_inputs/field_dropdown_list';
import style from './style.module.scss';
import { Col, Row } from 'react-bootstrap';
import { adminPaths } from 'config/path_helpers';
import FieldMultiselect from '@magoosh/lib/formik_inputs/field_multiselect';
import fetch from 'utilities/fetch';

interface Props {
  applicantProfile: ApplicantProfile;
  accessLevels: AccessLevel[];
  programs: Program[];
  brandings: Branding[];
  degreeOptions: string[];
  tagOptions: string[];
  undergraduateSchoolOptions: string[];
}

interface Branding {
  id: number;
  name: string;
}

interface Program {
  id: number;
  name: string;
}

interface ApplicantProfile {
  accessLevel: number;
  brandingId: number;
  commentary: string;
  firstGeneration: boolean;
  gpa: number;
  id: number;
  internationalStudent: boolean;
  interviewQuestions: {
    answer: string;
    question: string;
  }[];
  lesson: {
    id: number;
    title: string;
  };
  limitedWorkExperience: boolean;
  lowGpa: boolean;
  lowTestScore: boolean;
  undergraduateSchool: string;
  name: string;
  degree: string;
  degreeType: string;
  graduateYearEnrolled: number;
  recommenderJustifications: string[];
  resumeId?: number;
  storyBlurb: string;
  storyTitle: string;
  tags: string[];
  testScores: TestScore[];
  transcriptId?: number;
  undergraduateDegree: string;
  undergraduateGraduationYear: number;
  programApplications: ProgramApplication[];
  yearsWorkExperience: number;

  writingExamples: {
    id: number;
    title: string;
  }[];
}

interface FormFields {
  accessLevel: number;
  brandingId: number;
  commentary: string;
  gpa: number;
  interviewQuestions: {
    answer: string;
    question: string;
  }[];
  undergraduateSchool: string;
  name: string;
  degree: string;
  degreeType: string;
  graduateYearEnrolled: number;
  recommenderJustifications: string[];
  resumeId?: number;
  storyBlurb: string;
  lessonId: number | string;
  storyTitle: string;
  tags: string[];
  testScores: TestScore[];
  transcriptId?: number;
  undergraduateDegree: string;
  undergraduateGraduationYear: number;
  programApplications: ProgramApplication[];
  yearsWorkExperience: number;
  writingExampleIds: number[];
}

interface ProgramApplication {
  programName?: string;
  programId: number;
  admitted: boolean;
  waitlisted: boolean;
  interviewed: boolean;
  denied: boolean;
  enrolled: boolean;
}

interface TestScore {
  textName: string;
  score: number;
  sectionScores: SectionScore[];
}

interface SectionScore {
  sectionName: string;
  score: number;
}
const ApplicantProfileForm: React.FC<Props> = (props) => {
  const profile = props.applicantProfile;
  const initialValues: FormFields = {
    accessLevel: profile.accessLevel || 100,
    brandingId: profile.brandingId || props.brandings[0].id,
    commentary: profile.commentary || '',
    gpa: profile.gpa || 0,
    interviewQuestions: profile.interviewQuestions || [],
    undergraduateSchool: profile.undergraduateSchool || '',
    name: profile.name || '',
    degree: profile.degree || '',
    degreeType: profile.degreeType || '',
    graduateYearEnrolled: profile.graduateYearEnrolled || 2023,
    recommenderJustifications: profile.recommenderJustifications || [],
    storyBlurb: profile.storyBlurb || '',
    lessonId: profile.lesson?.id || '', // Should this be something else?
    storyTitle: profile.storyTitle || '',
    tags: profile.tags || [],
    testScores: profile.testScores || [],
    undergraduateDegree: profile.undergraduateDegree || '',
    undergraduateGraduationYear: profile.undergraduateGraduationYear || 2023,
    programApplications: profile.programApplications || [],
    yearsWorkExperience: profile.yearsWorkExperience || 0,
    writingExampleIds: profile.writingExamples.map((example) => example.id) || []
  };

  const testSections = {
    GRE: ['Verbal', 'Quantitative', 'Analytical Writing'],
    GMAT: ['Verbal', 'Quantitative', 'Integrated Reasoning', 'Analytical Writing'],
    LSAT: ['Reading Comprehension', 'Analytical Reasoning', 'Logical Reasoning'],
    MCAT: [
      'Biological and Biochemical Foundations of Living Systems',
      'Chemical and Physical Foundations of Biological Systems',
      'Psychological, Social, and Biological Foundations of Behavior',
      'Critical Analysis and Reasoning Skills'
    ],
    SAT: ['Reading', 'Writing and Language', 'Math'],
    ACT: ['English', 'Math', 'Reading', 'Science']
  };

  const testOptions = ['GRE', 'GMAT', 'LSAT', 'MCAT', 'SAT', 'ACT', 'IELTS', 'TOEFL'];
  const degreeTypeOptions = ['Masters', 'PhD', 'MBA', 'MA', 'MS'];

  const onSubmit = (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    helpers.setSubmitting(true);
    const path = props.applicantProfile.id
      ? adminPaths.api.applicantProfile(props.applicantProfile.id)
      : adminPaths.api.applicantProfiles();
    const method = props.applicantProfile.id ? 'PUT' : 'POST';

    fetch(path, { method: method, body: JSON.stringify(values) })
      .then((response) => {
        if (response.id) {
          window.location.assign(adminPaths.applicantProfile(response.id));
        }
      })
      .catch((response) => {
        helpers.setSubmitting(false);
        helpers.setErrors(response.errors.errors);
        console.log(response.errors.errors);
      });
  };

  return (
    <div className={style.applicantProfileForm}>
      <h1>{props.applicantProfile.id ? 'Edit Applicant Profile' : 'New Applicant Profile'}</h1>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, isSubmitting, errors, isValid }) => {
          return (
            <Form>
              <FieldInput name={'name'} type={'input'} />
              <Row>
                <Col md={6}>
                  <FieldDropdownList
                    name={'degree'}
                    data={props.degreeOptions}
                    defaultValue={values.degree}
                    filter={'contains'}
                    allowCreate={true}
                  />
                </Col>
                <Col md={6}>
                  <FieldDropdownList
                    name={'degreeType'}
                    label={'Degree type'}
                    data={degreeTypeOptions}
                    defaultValue={values.degreeType}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FieldInput
                    name={'yearsWorkExperience'}
                    label={'Years of work experience'}
                    type={'number'}
                  />
                </Col>
                <Col md={6}>
                  <FieldInput
                    name={'graduateYearEnrolled'}
                    label={'Year enrolled to grad school'}
                    type={'number'}
                  />
                </Col>
              </Row>
              <h3>Access</h3>
              <Row>
                <Col md={6}>
                  <FieldDropdownList
                    name={'brandingId'}
                    label={'Branding'}
                    data={props.brandings}
                    textField={'name'}
                    valueField={'id'}
                    defaultValue={props.brandings[0].id}
                  />
                </Col>
                <Col md={6}>
                  <FieldDropdownList
                    name="accessLevel"
                    label="Subscription Access Level"
                    data={props.accessLevels}
                    valueField="level"
                    textField="name"
                    defaultValue={100}
                  />
                </Col>
              </Row>
              <FieldMultiselect
                name={'tags'}
                defaultValue={values.tags}
                data={props.tagOptions}
                allowCreate={true}
              />
              <FieldInput name={'commentary'} type={'textarea'} />
              <h3>Undergraduate Info</h3>

              <Row>
                <Col md={6}>
                  <FieldDropdownList
                    name={'undergraduateSchool'}
                    label={'Undergraduate school'}
                    defaultValue={values.undergraduateSchool}
                    data={props.undergraduateSchoolOptions}
                    filter={'contains'}
                    allowCreate={true}
                  />
                </Col>
                <Col md={6}>
                  <FieldInput name={'undergraduateDegree'} label="Undergraduate degree" type={'input'} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FieldInput
                    name={'undergraduateGraduationYear'}
                    label="Undergraduate graduation year"
                    type={'number'}
                  />
                </Col>
                <Col md={6}>
                  <FieldInput name={'gpa'} label={'GPA'} type={'number'} />
                </Col>
              </Row>
              <h3>Story</h3>
              <FieldDropdownList
                name={'lessonId'}
                label={'Story Lesson'}
                data={[{ id: values.lessonId, title: props.applicantProfile.lesson?.title || '' }]}
                defaultValue={values.lessonId}
                searchEndpoint={adminPaths.api.lessons}
                groupBy={'branding'}
                filter={'contains'}
                valueField={'id'}
                textField={'title'}
              />
              <FieldInput name={'storyTitle'} label="Story title" type={'input'} />
              <FieldInput name={'storyBlurb'} label={'Story blurb'} type={'textarea'} />
              <h3>Test Scores</h3>
              <FieldArray
                name={'testScores'}
                render={(arrayHelpers) => {
                  const scores = values.testScores;

                  return (
                    <div>
                      {scores.map((score, index) => {
                        return (
                          <div key={index} className={style.subItem}>
                            <FieldDropdownList
                              name={`testScores[${index}].testName`}
                              label={'Test name'}
                              data={testOptions}
                              defaultValue={testOptions[0]}
                            />
                            <FieldInput name={`testScores[${index}].score`} label={`Score`} type={'number'} />
                            <FieldInput
                              name={`testScores[${index}].sectionScores`}
                              type={'string'}
                              label={'Section Scores'}
                            />
                            <small>e.g. "44 V, 44 Q"</small>
                            <div>
                              <DangerButton
                                className={'u-margin-T-s'}
                                onClick={() => arrayHelpers.remove(index)}>
                                Remove
                              </DangerButton>
                            </div>
                          </div>
                        );
                      })}
                      <PrimaryButton
                        onClick={() => arrayHelpers.push({ testName: '', score: 0, sectionScores: '' })}>
                        Add test
                      </PrimaryButton>
                    </div>
                  );
                }}
              />
              <h3>Interview Questions</h3>
              <FieldArray
                name={'interviewQuestions'}
                render={(arrayHelpers) => {
                  const questions = values.interviewQuestions;

                  return (
                    <div>
                      {questions.map((question, index) => {
                        return (
                          <div key={index} className={style.subItem}>
                            <FieldInput
                              name={`interviewQuestions[${index}].question`}
                              label={`Question ${index}`}
                              type={'string'}
                            />
                            <FieldInput
                              name={`interviewQuestions[${index}].answer`}
                              label={'Answer'}
                              type={'string'}
                            />
                            <DangerButton
                              className={'u-margin-T-s'}
                              onClick={() => arrayHelpers.remove(index)}>
                              Remove
                            </DangerButton>
                          </div>
                        );
                      })}
                      <PrimaryButton onClick={() => arrayHelpers.push({ question: '', answer: '' })}>
                        Add interview question
                      </PrimaryButton>
                    </div>
                  );
                }}
              />
              <h3>Recommendations</h3>
              <FieldArray
                name={'recommenderJustifications'}
                render={(arrayHelpers) => {
                  const justifications = values.recommenderJustifications;

                  return (
                    <div>
                      {justifications.map((justification, index) => {
                        return (
                          <div key={index} className={style.subItem}>
                            <FieldInput
                              name={`recommenderJustifications[${index}]`}
                              label={`Why did this student choose recommender ${index + 1}?`}
                              type={'string'}
                            />
                            <DangerButton
                              className={'u-margin-T-s'}
                              onClick={() => arrayHelpers.remove(index)}>
                              Remove
                            </DangerButton>
                          </div>
                        );
                      })}
                      <PrimaryButton onClick={() => arrayHelpers.push('')}>Add recommender</PrimaryButton>
                    </div>
                  );
                }}
              />
              <h3 className="section-header">Applications</h3>
              <FieldArray
                name={'programApplications'}
                render={(arrayHelpers) => {
                  const applications = values.programApplications;

                  return (
                    <div>
                      {applications.map((application, index) => {
                        return (
                          <div key={index} className={style.subItem}>
                            <FieldDropdownList
                              name={`programApplications[${index}].programId`}
                              label={`Name of program ${index + 1}`}
                              data={props.programs}
                              valueField="id"
                              textField="name"
                              defaultValue={props.programs[0]?.id || ''}
                              filter={'contains'}
                            />
                            <SimpleFieldCheckbox
                              name={`programApplications[${index}].admitted`}
                              label={'Admitted'}
                              key={'admitted'}
                              checked={values.programApplications[index].admitted}
                            />
                            <SimpleFieldCheckbox
                              name={`programApplications[${index}].waitlisted`}
                              label={'Waitlisted'}
                              key={'waitlisted'}
                              checked={values.programApplications[index].waitlisted}
                            />
                            <SimpleFieldCheckbox
                              name={`programApplications[${index}].interviewed`}
                              label={'Interviewed'}
                              key={'interviewed'}
                              checked={values.programApplications[index].interviewed}
                            />
                            <SimpleFieldCheckbox
                              name={`programApplications[${index}].denied`}
                              label={'Denied'}
                              key={'denied'}
                              checked={values.programApplications[index].denied}
                            />
                            <SimpleFieldCheckbox
                              name={`programApplications[${index}].enrolled`}
                              label={'Enrolled'}
                              key={'enrolled'}
                              checked={values.programApplications[index].enrolled}
                            />
                            <DangerButton
                              className={'u-margin-T-s'}
                              onClick={() => arrayHelpers.remove(index)}>
                              Remove
                            </DangerButton>
                          </div>
                        );
                      })}
                      <PrimaryButton
                        onClick={() =>
                          arrayHelpers.push({
                            programId: '',
                            admitted: false,
                            waitlisted: false,
                            interviewed: false,
                            denied: false,
                            enrolled: false
                          })
                        }>
                        Add application status
                      </PrimaryButton>
                    </div>
                  );
                }}
              />
              <h3>Writing Examples</h3>
              <FieldArray
                name={'writingExampleIds'}
                render={(arrayHelpers) => {
                  const exampleIds = values.writingExampleIds;

                  return (
                    <div>
                      {exampleIds.map((id, index) => {
                        return (
                          <div key={index} className={style.subItem}>
                            <FieldDropdownList
                              name={`writingExampleIds[${index}]`}
                              defaultValue={id}
                              label={'Writing Example'}
                              data={props.applicantProfile.writingExamples}
                              searchEndpoint={adminPaths.api.writingExampleSearch}
                              textField={(dataItem) => dataItem ? `${dataItem.id} - ${dataItem.title}` : null}
                              valueField={'id'}
                              filter={'contains'}
                            />
                            <DangerButton
                              className={'u-margin-T-s'}
                              onClick={() => arrayHelpers.remove(index)}>
                              Remove
                            </DangerButton>
                          </div>
                        );
                      })}
                      <PrimaryButton className={'u-margin-T-s'} onClick={() => arrayHelpers.push('')}>
                        Add writing example
                      </PrimaryButton>
                    </div>
                  );
                }}
              />
              <PrimaryButton submitting={isSubmitting} type="submit">
                Submit
              </PrimaryButton>
              {!isValid && <p className={style.error}>Form is not valid</p>}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ApplicantProfileForm;
