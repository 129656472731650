import { StudyPreferences } from "types/study_preferences";

export function selectedStudyDays(preferences: StudyPreferences): string[] {
  const daysArray = [];
  preferences.days.monday && daysArray.push('Monday');
  preferences.days.tuesday && daysArray.push('Tuesday');
  preferences.days.wednesday && daysArray.push('Wednesday');
  preferences.days.thursday && daysArray.push('Thursday');
  preferences.days.friday && daysArray.push('Friday');
  preferences.days.saturday && daysArray.push('Saturday');
  preferences.days.sunday && daysArray.push('Sunday');
  return daysArray;
}

export function calculatedHoursPerWeek(preferences: StudyPreferences) {
  let numDaysSelected = 0;
  preferences.days.monday && numDaysSelected++;
  preferences.days.tuesday && numDaysSelected++;
  preferences.days.wednesday && numDaysSelected++;
  preferences.days.thursday && numDaysSelected++;
  preferences.days.friday && numDaysSelected++;
  preferences.days.saturday && numDaysSelected++;
  preferences.days.sunday && numDaysSelected++;

  return preferences.hoursPerDay * numDaysSelected;
}
