import { merge, omit } from 'lodash';
import { useQuery } from 'react-query';

import { useAppContext } from '@admin/app/context';

import * as api from './api';

// These queries and mutations use React hooks, which can only be called from within a functional
// component. Because of that we need to wrap them in functions like below. To access them in a
// functional component:
//   import { useQueries } from './queries';
//   const q = useQueries();
export const useQueries = () => {
  return {
    aiPromptVersionDetailsQuery,
    pageQuery
  };
};

export const aiPromptVersionDetailsQuery = (id: number, versionId: number, proposedFixId: number, options = {}) => {
  let key;
  if (proposedFixId) {
    key = ['aiPrompt', id, 'versions', versionId, 'fixes', proposedFixId];
  } else {
    key = ['aiPrompt', id, 'versions', versionId];
  }

  return useQuery(
    key,
    () => api.fetchAIPromptVersion(id, versionId, proposedFixId), { ...options }
  )
};

export const pageQuery = (path, options = {}) => {
  const { data, setData } = useAppContext();

  return useQuery(['pages', path], () => api.fetchPage(path), {
    retry: false,
    cacheTime: Infinity,
    select: (pageData) => {
      if (pageData.context) {
        setData(merge(pageData.context, data));
        return omit(pageData, 'context');
      } else {
        return pageData;
      }
    },
    staleTime: Infinity,
    ...options
  });
};
