import React, { useLayoutEffect, useRef, useState } from 'react';
import cx from 'classnames';

import ActivityPanel from './activity_panel';
import TutoringPanel from './tutoring_panel';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  active: boolean;
  activeActivity: boolean;
  children: any;
  initialWidth: number;
}

interface CompositionProps {
  ActivityPanel?: React.FC<any>;
  TutoringPanel?: React.FC<any>;
}

const PanelManager: React.FC<Props> & CompositionProps = (props) => {
  const activityPanelRef = useRef(null);
  const tutoringPanelRef = useRef(null);

  const gripperRef = useRef(null);
  const resizeableContainerRef = useRef(null);

  const mouseX = useRef(null);
  const width = useRef(props.initialWidth);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [navbarHeight, setNavbarHeight] = useState(0);

  const PADDING = 15;

  const handleWindowResize = useRef(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else if (window.innerWidth >= 768) {
      setIsMobile(false);
    }

    // If the window gets smaller than the tutoring panel, shrink the panel as well
    if (window.innerWidth < width.current + (PADDING * 2)) {
      width.current = window.innerWidth - (PADDING * 2);
    }

    setWidth(width.current, true);
    updateNavbarHeight();
  });

  const handleGripperDrag = useRef((e) => {
    e.preventDefault();

    const dx = mouseX.current - e.x;
    mouseX.current = e.x;
    const newWidth = parseInt(getComputedStyle(tutoringPanelRef.current, '').width) + dx;

    setWidth(newWidth);
  });

  const handleGripperMouseDown = useRef((e) => {
    e.preventDefault();

    mouseX.current = e.x;
    document.addEventListener('mousemove', handleGripperDrag.current, false);
  });

  const handleGripperMouseUp = useRef((e) => {
    document.removeEventListener('mousemove', handleGripperDrag.current, false);
  });

  const setResizeableContainerWidth = (panelWidth: number) => {
    if (!resizeableContainerRef.current) return;

    // We use the standard responsive container class sizes from Bootstrap to calculate what the
    // margins would have been without the sidebar. We also don't let the original content get
    // larger than it would have been without the sidebar.
    let standardContainerWidth;
    if (window.innerWidth >= 1200) {
      standardContainerWidth = 1170;
    } else if (window.innerWidth >= 992) {
      standardContainerWidth = 970;
    } else if (window.innerWidth >= 768) {
      standardContainerWidth = 750;
    } else {
      standardContainerWidth = window.innerWidth;
    }

    const leftMargin = (window.innerWidth - standardContainerWidth) / 2;
    const newWidth = Math.min(window.innerWidth - panelWidth - (PADDING * 2) - leftMargin, standardContainerWidth);

    // Keep the original content at a minimum of 500px
    if (newWidth > 500) {
      resizeableContainerRef.current.style.marginLeft = `${leftMargin}px`;
      resizeableContainerRef.current.style.width = `${newWidth}px`;
    }
  };

  const setWidth = (newWidth: number, force?: boolean) => {
    if (!tutoringPanelRef.current) return;

    if (newWidth < 400) newWidth = 400;

    if (force || newWidth < (window.innerWidth - (PADDING * 2))) {
      width.current = newWidth;
      tutoringPanelRef.current.style.width = `${newWidth}px`;
      window.localStorage.setItem('tutorPanelWidth', newWidth.toString());

      if (!activityPanelRef.current) {
        setResizeableContainerWidth(newWidth);
      } else {
        const activityPanelWidth = Math.min(window.innerWidth - width.current - PADDING - PADDING, 1170);
        activityPanelRef.current.style.left = `${activityPanelWidth === 1170 ? window.innerWidth - width.current - PADDING - 1170 : PADDING}px`;
        activityPanelRef.current.style.width = `${activityPanelWidth}px`;
      }
    }
  };

  const updateNavbarHeight = () => {
    const navbar = document.getElementsByClassName('navbar-fixed-top')[0];
    if (!navbar) return;

    setNavbarHeight(navbar.scrollHeight);
  };

  // Setup gripper mouse events
  useLayoutEffect(() => {
    if (!resizeableContainerRef.current) {
      resizeableContainerRef.current = document.getElementById('resizeable-container');
    }

    // Mobile won't have a gripper
    if (!gripperRef.current) return;

    const gripper = gripperRef.current;
    gripper.addEventListener('mousedown', handleGripperMouseDown.current, false);
    document.addEventListener('mouseup', handleGripperMouseUp.current, false);

    return () => {
      gripper.removeEventListener('mousedown', handleGripperMouseDown.current, false);
      document.removeEventListener('mouseup', handleGripperMouseUp.current, false);
      document.removeEventListener('mousemove', handleGripperDrag.current, false);
    }
  }, [isMobile]);

  // Handle show/hide toggle
  useLayoutEffect(() => {
    if (props.active) {
      if (width.current > window.innerWidth - (PADDING * 2)) {
        setWidth(window.innerWidth - (PADDING * 2), true);
      }

      if (resizeableContainerRef.current) {
        resizeableContainerRef.current.style.transition = 'width 0.1s ease-in';
        setResizeableContainerWidth(width.current);
      }

      window.addEventListener('resize', handleWindowResize.current);
    } else {
      if (resizeableContainerRef.current) {
        resizeableContainerRef.current.style.marginLeft = 'auto';
        resizeableContainerRef.current.style.removeProperty('width');
      }

      window.removeEventListener('resize', handleWindowResize.current);
    }

    const transitionTimeout = setTimeout(() => {
      if (!resizeableContainerRef.current) return;

      resizeableContainerRef.current.style.transition = 'none'
    }, 200);

    return () => {
      window.removeEventListener('resize', handleWindowResize.current);
      window.clearTimeout(transitionTimeout);

      if (resizeableContainerRef.current) {
        resizeableContainerRef.current.style.marginLeft = 'auto';
        resizeableContainerRef.current.style.removeProperty('width');
      }
    }
  }, [props.active]);

  useLayoutEffect(() => {
    if (props.active && (props.activeActivity || isMobile)) {
      // When the activity panel is visible prevent the orginal body content from scrolling
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';

      if (props.active) {
        setResizeableContainerWidth(width.current);
      }
    }
  }, [props.activeActivity, props.active, isMobile]);

  // Get initial height of the navbar
  useLayoutEffect(() => {
    updateNavbarHeight();
  }, [])

  const filterChildren = (type) => {
    return props.children.filter((ch) => ch.type === type)
  };

  if (isMobile) {
    return (
      <div
        className="bg-gray-lightest"
        style={{
          display: props.active ? 'flex' : 'none',
          height: `calc(100dvh - ${navbarHeight}px)`,
          left: 0,
          position: 'fixed',
          top: navbarHeight,
          width: 'calc(100dvw)',
          zIndex: 1
        }}
      >
        <div
          className="bg-white d-flex flex-column gap-m"
          style={{
            borderRadius: 8,
            margin: 5,
            padding: 20,
            width: '100%'
          }}
        >
          {
            props.activeActivity && (
              filterChildren(ActivityPanel)
            )
          }

          <div
            className="d-flex flex-column gap-m"
            style={{
              display: props.activeActivity ? 'none' : 'flex',
              height: '100%'
            }}
          >
            {filterChildren(TutoringPanel)}
          </div>
        </div>
      </div>
    );
  } else {
    const activityPanelWidth = Math.min(window.innerWidth - width.current - PADDING - PADDING, 1170);

    return (
      <>
        {
          props.active && props.activeActivity && tutoringPanelRef.current && (
            <>
              {/* Provides padding between the navbar and activity */}
              <div
                className={style.fadeIn}
                style={{
                  backgroundColor: '#D2D2D4',
                  height: 12,
                  left: 0,
                  position: 'fixed',
                  top: navbarHeight,
                  width: '100%',
                  zIndex: 51
                }}
              />

              <div
                className={style.fadeIn}
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.15)',
                  height: `calc(-${navbarHeight}px + 100dvh)`,
                  left: 0,
                  position: 'fixed',
                  top: navbarHeight,
                  width: '100%'
                }}
              />

              <div
                className={cx(style.fadeIn, "d-flex flex-column")}
                ref={activityPanelRef}
                style={{
                  bottom: 0,
                  height: `calc(-${navbarHeight}px + 100dvh)`,
                  left: activityPanelWidth === 1170 ? window.innerWidth - width.current - PADDING - 1170 : PADDING,
                  position: 'fixed',
                  top: navbarHeight,
                  width: activityPanelWidth,
                  zIndex: 51 // Higher than sticky options sidebar
                }}
              >
                {/* Provides padding between the navbar and activity */}
                <div className="flex-none" style={{ height: 12 }} />

                <div className="d-flex flex-1" style={{ height: 'calc(-67px + 100dvh)' }}>
                  <div
                    className="bg-gray-lightest flex-1"
                    style={{
                      borderRadius: '8px 0 0 0',
                      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25), 0 2px 10px rgba(0, 0, 0, 0.1)',
                      padding: 20
                    }}
                  >
                    {filterChildren(ActivityPanel)}
                  </div>
                </div>
              </div>
            </>
          )
        }

        <div
          className="d-flex flex-column"
          ref={tutoringPanelRef}
          style={{
            bottom: 0,
            height: `calc(100dvh - ${navbarHeight}px)`,
            right: props.active ? PADDING : width.current * -1,
            position: 'fixed',
            top: navbarHeight,
            transition: 'right 0.2s ease-in',
            width: width.current,
            zIndex: 52 // Higher than activity panel
          }}
        >
          {/* Provides padding between the navbar and panel */}
          <div className="flex-none" style={{ height: 12 }} />

          <div
            className="d-flex flex-1"
            style={{
              backgroundColor: '#ffffff',
              border: '1px solid #efefef',
              borderRadius: props.activeActivity ? '0 8px 0 0' : '8px 8px 0 0',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)',
              height: 'calc(100dvh - 67px)',
              width: '100%'
            }}
          >
            <div className="align-self-center u-padding-H-xs" ref={gripperRef} style={{ cursor: 'ew-resize' }}>
              <div
                style={{
                  borderRadius: 2,
                  backgroundColor: colors.gray85,
                  height: 75,
                  width: 4
                }}
              />
            </div>

            <div
              className="d-flex flex-column gap-m"
              style={{
                height: '100%',
                padding: '20px 20px 20px 5px',
                width: '100%'
              }}
            >
              {filterChildren(TutoringPanel)}
            </div>
          </div>
        </div>
      </>
    );
  }
};

PanelManager.ActivityPanel = ActivityPanel;
PanelManager.TutoringPanel = TutoringPanel;

export default PanelManager;
