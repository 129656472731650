import React, { useEffect, useLayoutEffect, useState } from 'react';
import { find } from 'lodash';

import { Interaction, TutoringSession } from '@test_prep/app/ai_tutor/types';

import LaunchTutorButton from './components/launch_tutor_button';
import PanelManager from './components/panel_manager';
import RatingCollection from './components/rating_collection';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {}

// NOTE: Not suitable for server rendering
const AITutor: React.FC<Props> = (props) => {
  const [currentActivity, setCurrentActivity] = useState<Interaction>(null);
  const [currentTutoringSession, setCurrentTutoringSession] = useState<TutoringSession>(null);
  const [initialSkill, setInitialSkill] = useState<string>(null);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const launchTutor = (e) => {
      setInitialSkill(e.detail?.skill);
      setIsActive(true);
    };
    document.addEventListener('launchTutor', launchTutor);

    return () => {
      document.removeEventListener('launchTutor', launchTutor);
    }
  }, []);

  // When the tutor becomes active/inactive control whether we show the Forethought help widget
  useLayoutEffect(() => {
    if (window && 'Forethought' in window) {
      (window as any).Forethought('widget', isActive ? 'hide' : 'show');
    }
  }, [isActive]);

  const handleNewInteractions = (interactions: Interaction[]) => {
    // Ensure we have the current state, even if this is called from a closure
    setCurrentTutoringSession((tutoringSession) => {
      // We may get more recent content for an interaction we already have
      // (e.g. new streamed chat content). Take the new content.
      // Drop provisional content that was added purely for quick UI feedback
      const nonProvisionalInteractions = tutoringSession.interactions.filter((interaction) => !interaction.provisional);
      const updatedExistingInteractions = nonProvisionalInteractions.map((existingInteraction) => {
        const updatedInteraction = find(interactions, (interaction) => interaction.id === existingInteraction.id);
        return updatedInteraction || existingInteraction;
      })

      const newInteractions = interactions.filter((interaction) => {
        return !find(tutoringSession.interactions, (existingInteraction) => interaction.id === existingInteraction.id)
      });

      return {
        ...tutoringSession,
        interactions: [
          ...updatedExistingInteractions,
          ...newInteractions
        ]
      };
    });

    // If the updated interactions include the current activity, update it as well
    interactions.forEach((interaction) => {
      if (interaction.id === currentActivity?.id) {
        setCurrentActivity(interaction);
      }
    });
  };

  return (
    <>
      <PanelManager
        active={isActive}
        activeActivity={!!currentActivity}
        initialWidth={parseInt(localStorage.getItem('tutorPanelWidth') || '425')}
      >
        <PanelManager.TutoringPanel
          active={isActive}
          activity={currentActivity}
          addOrUpdateUserInteractions={handleNewInteractions}
          initialSkill={initialSkill}
          onHide={() => {
            setIsActive(false);
            setCurrentActivity(null);
          }}
          setCurrentActivity={setCurrentActivity}
          setCurrentTutoringSession={(tutoringSession) => {
            setCurrentTutoringSession(tutoringSession);

            // If the new tutoring session includes an activity to start out, make it active
            if (
              tutoringSession
              && tutoringSession.interactions.every((interaction) => interaction.contentProducer.identifier === 'initial')
            ) {
              setCurrentActivity(
                find(
                  tutoringSession.interactions,
                  (interaction) => (
                    interaction.isActivity && interaction.contentProducer.identifier === 'initial'
                  )
                )
              );
            } else {
              setCurrentActivity(null);
            }
          }}
          tutoringSession={currentTutoringSession}
        />

        <PanelManager.ActivityPanel
          activity={currentActivity}
          addOrUpdateUserInteractions={handleNewInteractions}
          onHide={() => setCurrentActivity(null)}
          onHideTutor={() => {
            setIsActive(false);
            setCurrentActivity(null);
          }}
        />
      </PanelManager>
    </>
  );
};

export default AITutor;
