import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
}

const MagooshAIIcon: React.FC<Props> = (props) => {
  return (
    <div className={cx(props.className, 'd-flex text-secondary')}>
      <div style={{ fontSize: 24, lineHeight: 1.25 }}><strong>M</strong></div>
      <div style={{ fontSize: 10 }}><strong>AI</strong></div>
    </div>
  );
};

export default MagooshAIIcon;
