import { RootStore } from '@magoosh/store';
import { connect } from 'react-redux';
import TeacherDashboardTemplate from './template';
import { StudentResponseData } from './types';
import { addStudents, changeClassFilter, resetStudentData } from './actions';

function mapStateToProps(store: RootStore) {
  return { ...store.teacherDashboard };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onPickClassFilter: (classSectionId: number) => {
      dispatch(changeClassFilter(classSectionId));
    },
    onFetchStudentData: (response: StudentResponseData) => {
      dispatch(addStudents(response));
    },
    onResetStudentData: () => {
      // Wrap dispatch in a promise so that we can execute other code after it completes with
      // `onResetStudentDate().then(...)`
      return new Promise((resolve, reject) => {
        dispatch(resetStudentData());
        resolve(null);
      });
    },
    ...ownProps
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDashboardTemplate);
