import {ContentBlock} from "@magoosh/admin/content_block_editor/types";
import {User} from "types/user";

export interface AssignmentsStore {
  assignments: Assignment[];
  detailsAssignment?: Assignment;
  assignmentDialogOpen?: boolean;
  deleteAssignmentDialogOpen?: boolean;
  teacherMode: boolean;
}

export interface Assignment {
  id: number;
  title: string;
  dueDate: Date;
  classSection: ClassSection;
  contentBlock: ContentBlock;
  userAssignments: UserAssignment[];
  students?: User[];
  currentUserAssignment: UserAssignment;
  estimatedTime?: number;
  hasQuiz?: boolean;
  lessonCount?: number;
  questionPromptsCount?: number;
  studentCount?: number;
  submissionCount?: number;
  quizCount?: number;
}

export enum UserAssignmentState {
  started = 'started',
  submitted = 'submitted'
}

export interface UserAssignment {
  id: number;
  state: UserAssignmentState;
  canBeRetaken: boolean;
}

export interface ClassSection {
  id: number;
  displayName?: string;
  ensuredName?: string;
}
