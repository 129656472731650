import React, { useState } from 'react';

import { FontAwesomeIcon, MagooshAIIcon } from '@magoosh/lib/icons';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

interface Props {
  ai: boolean;
  promptId: number;
}

const ReportPromptIssue: React.FC<Props> = (props) => {
  const authenticityToken = useAuthenticityToken();
  const [issueReportReceived, setIssueReportReceived] = useState(false);

  const reportIssue = () => {
    setIssueReportReceived(true);

    fetch(paths.api.issueReports(), {
      method: 'POST',
      body: JSON.stringify({
        authenticityToken,
        issueReport: {
          reportedFrom: window.location.href,
          targetId: props.promptId,
          targetType: 'Prompt'
        }
      })
    });
  };

  return (
    <div className="align-items-center d-flex gap-s">
      {
        props.ai && (
          <div className="align-items-center d-flex flex-1 gap-s">
            <MagooshAIIcon className="text-gray-light" />

            <div className="text-gray-light">
              <small>This question was created by the Magoosh AI Tutor.</small>
            </div>
          </div>
        )
      }

      <div className="d-flex justify-content-end">
        <small>
          {
            issueReportReceived ? (
              <span className="text-gray-light">
                <FontAwesomeIcon className="text-gray-light" icon="check" />
                {' '}We'll take a look at this question!
              </span>
            ) : (
              <a className="text-gray-light" onClick={reportIssue}>Report an issue</a>
            )
          }
        </small>
      </div>
    </div>
  );
};

export default ReportPromptIssue;
