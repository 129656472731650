import React from 'react';

import { upsellPoints } from 'utilities/exam_info';

interface Props {
  examName: string;
}

const UpsellContent = (props) => {
  const defaultPoints = [
    'Expert Video Lessons',
    'Practice Questions',
    'Score-Improvement Guarantee',
    'No Questions Asked Money-Back Guarantee'
  ];

  const examKey = props.examName.trim().toLowerCase().replace(/ /g, '_');
  const points = upsellPoints.short[examKey]

  return (
    <ul className="u-padding-L-m">
      {
        (points || defaultPoints).map((point, idx) => (
          <li key={idx}>{point}</li>
        ))
      }
    </ul>
  );
};

export default UpsellContent;
