import React from 'react';
import style from './style.module.scss';
import cx from 'classnames';

interface BypassButtonProps {
  copy: string;
  contentId: string;
  isStatic?: boolean;
}

const BypassButton: React.FC<BypassButtonProps> = (props) => {
  return (
    <a
      href={props.contentId}
      className={cx('btn btn-default', style.bypassButton, !props.isStatic && style.absoluteBypassButton)}>
      {' '}
      {props.copy}
    </a>
  );
};

export default BypassButton;
