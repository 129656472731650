import * as React from 'react';
import style from '../style.module.scss';
import trackEvent from 'utilities/track_event';
import cx from 'classnames';

interface NavLinkProps {
  selected: boolean;
  name: string;
  url: string;
}

export default class NavLink extends React.Component<NavLinkProps> {
  render() {
    const { selected, name, url } = this.props;
    return (
      <a
        className={cx(
          style.navLink,
          `btn btn-pill u-margin-R-m ${selected ? 'btn-secondary' : 'btn-default'}`
        )}
        href={url}
        onClick={() => trackEvent('navigation_tab', { value: name })}>
        <strong>{name}</strong>
      </a>
    );
  }
}
