import React from 'react';
import { ExpansionPanelContextProps, ExpansionPanelProps } from './types';
import style from './style.module.scss';
import cx from 'classnames';
import { LinkButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { Modal, Panel } from 'react-bootstrap';

export const ExpansionPanelContext = React.createContext<ExpansionPanelContextProps | undefined>(undefined);

export const useExpansionPanelGroup = (): ExpansionPanelContextProps => {
  const context = React.useContext(ExpansionPanelContext);
  if (!context) {
    throw new Error(
      'You must wrap your page with <ExpansionPanelGroup /> in order to useExpansionPanelGroup().'
    );
  }
  return context;
};

export const ExpansionPanelGroup: React.FC = (props) => {
  const [activePanel, setActivePanel] = React.useState<number>(null);
  return (
    <ExpansionPanelContext.Provider value={{ activePanel, setActivePanel }}>
      {props.children}
    </ExpansionPanelContext.Provider>
  );
};

export const ExpansionPanel: React.FC<ExpansionPanelProps> = (props) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const { id, className, text, title, children, leftAligned } = props;
  const { activePanel, setActivePanel } = useExpansionPanelGroup();

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 768);

    window.addEventListener('resize', () => {
      if (window.innerWidth < 768 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 768 && isMobile) {
        setIsMobile(false);
      }
    });
  });

  return (
    <div className={cx(style.expansionPanel, className, leftAligned && style.leftAligned)}>
      <LinkButton
        className={cx(
          style.btnGrayLink,
          style.expansionButton,
          typeof text === 'string' ? style.textButtonTrigger : style.elementTriggerButton
        )}
        onClick={() => {
          setActivePanel(activePanel === id ? 0 : id);
        }}>
        {typeof text === 'string' ? (
          <p>
            <strong>{text}</strong>
          </p>
        ) : (
          <div className={style.elementTriggerContent}>{text}</div>
        )}
        <p className="u-margin-L-xs">
          <FontAwesomeIcon icon={activePanel === id ? 'chevron-up' : 'chevron-down'} />
        </p>
      </LinkButton>

      {/* Present as a modal on mobile size screens */}
      <Modal onHide={() => setActivePanel(null)} show={isMobile && activePanel === id}>
        <Modal.Header className="u-padding-H-l" closeButton>
          {typeof title === 'string' ? (
            <h3>
              <strong>{title}</strong>
            </h3>
          ) : (
            title
          )}
        </Modal.Header>
        <Modal.Body className="u-margin-B-l">{children(() => setActivePanel(null))}</Modal.Body>
      </Modal>

      {/* Present as dropdown panel on non-mobile size screens */}
      <Panel
        id="overlay"
        expanded={!isMobile && activePanel === id}
        onToggle={() => {
          setActivePanel(activePanel === id ? 0 : id);
        }}
        className={style.panel}>
        <Panel.Collapse>
          <Panel.Body>
            {typeof title === 'string' ? (
              <h4>
                <strong>{title}</strong>
              </h4>
            ) : (
              title
            )}
            {children(() => setActivePanel(null))}
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    </div>
  );
};

export default ExpansionPanel;
