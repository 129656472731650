import * as React from 'react';
import { Provider } from 'react-redux';
import configureStore from '@magoosh/store';
import StudentPerformanceContainer from './container';
import { setClassSectionsByGrade } from '../student_performance/actions';
import { StudentPerformanceStore } from '../types';

export default class extends React.Component<StudentPerformanceStore> {
  store = configureStore();

  constructor(props) {
    super(props);

    this.store.dispatch(setClassSectionsByGrade(this.props.classSectionsByGrade));
  }

  render() {
    return (
      <Provider store={this.store}>
        <StudentPerformanceContainer />
      </Provider>
    );
  }
}
