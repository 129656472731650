import * as React from 'react';
import { isEmpty } from 'lodash';

interface Props {
  firstName?: string;
  brandName: string;
}

const WelcomeStep = ({ firstName, brandName }: Props) => (
  <React.Fragment>
    <h3 className="text-primary u-margin-T-s">
      <strong>{isEmpty(firstName) ? 'Hello,' : `Hello ${firstName},`}</strong>
    </h3>
    <h3>
      <strong>Welcome to Magoosh!</strong>
    </h3>
    <p>
      Studying for the {brandName} can be overwhelming. That's why we're here to help you carve
      out time in your busy schedule to study and hit your goals for this exam.
    </p>
    <p>Let's get started by committing to a study schedule.</p>
  </React.Fragment>
);

export default WelcomeStep;
