import React from 'react';

import { adminPaths } from 'config/path_helpers';

import style from './style.module.scss';

interface Props {
  adminLessonNotes: string;
  adminVideoNotes: string;
  lessonId: number;
}

const AdminContent: React.FC<Props> = (props) => {
  return (
    <div className="d-flex flex-column">
      <a href={adminPaths.lessonEdit(props.lessonId)}>Edit in Admin</a>
      <a href={adminPaths.lesson(props.lessonId)}>View in Admin</a>

      {(props.adminLessonNotes || props.adminVideoNotes) && <hr />}

      {
        props.adminLessonNotes && (
          <div>
            <div className={style.blockTitle}>Lesson Notes</div>
            <div dangerouslySetInnerHTML={{__html: props.adminLessonNotes}}></div>
          </div>
        )
      }

      {
        props.adminVideoNotes && (
          <div>
            <hr />
            <div className={style.blockTitle}>Video Notes</div>
            <div dangerouslySetInnerHTML={{__html: props.adminVideoNotes}}></div>
          </div>
        )
      }
    </div>
  )
}

export default AdminContent;
