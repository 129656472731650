import React from 'react';

import DeadlineCard from '@admissions/components/deadline_card';
import ItemCard from '@admissions/components/item_card';
import { ApplicationCycleChunk } from '@admissions/types';

interface Props {
  applicationCycle: string;
  chunks: ApplicationCycleChunk[];
  hideHeader?: boolean;
}

const ApplicationCycleChunks: React.FC<Props> = (props) => {
  return (
    <div>
      {
        props.chunks.map((chunk, idx) => (
          <div key={idx}>
            {
              !props.hideHeader && (
                <div className="u-margin-B-s" style={{ fontSize: 20}}><strong>{chunk.name}</strong></div>
              )
            }

            <Chunk applicationCycle={props.applicationCycle} {...chunk} />

            {idx !== props.chunks.length - 1 && <hr />}
          </div>
        ))
      }
    </div>
  );
};

const Chunk: React.FC<{ applicationCycle: string; } & ApplicationCycleChunk> = (props) => {
  return (
    <div className="d-flex flex-column" style={{ gap: 15 }}>
      {
        props.items.map((item) => (
          <ItemCard key={item.id} applicationCycle={props.applicationCycle} item={item} />
        ))
      }

      {
        props.deadlines.map((deadline) => (
          <DeadlineCard key={deadline.id} deadline={deadline} />
        ))
      }

      {props.afterChunk}
    </div>
  );
};

export default ApplicationCycleChunks;
