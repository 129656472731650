import * as React from 'react';
import { Field, Form, Formik, FormikHelpers as FormikActions } from 'formik';
import { PrimaryButton } from '@magoosh/lib/button';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

interface FormProps {
  mtt: any;
  runQuery: any;
  showConfirm: () => void;
}

interface MTTCopyFormValues {
  name: string;
}

const MTTCopyForm: React.FC<FormProps> = (props) => {
  const initialValues: MTTCopyFormValues = {
    name: `${props.mtt.containerBlock.name} (${new Date().toLocaleDateString()})`
  };

  const onSubmitForm = (
    values: MTTCopyFormValues,
    { setSubmitting }: FormikActions<MTTCopyFormValues>
  ) => {
    setSubmitting(true);

    fetch(adminPaths.api.copyModuleTrackTemplate(props.mtt.id), {
      method: 'POST',
      body: JSON.stringify(values)
    })
      .then((response) => {
        props.showConfirm();
        props.runQuery();
      })
      .catch((response) => alert(response.errors._error))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
      {({ values, isSubmitting, submitForm }) => (
        <Form>
          <div>
            <label htmlFor="mtt-name">Name</label>
            <Field name="name" className="form-control u-margin-B-s" id="mtt-name" label="Name" />
            <p>
              <p>Exam section: <b>{props.mtt.examSection.branding} {props.mtt.examSection.name}</b></p>
              <p>Difficulty: <b>{props.mtt.difficulty}</b></p>
              <p>Container block: <b>{props.mtt.containerBlock.name}</b></p>

              <p>This will create a new Module Track Template, marked as inactive, with new copies of the study items. The study item content blocks will go into a newly created container block.</p>
            </p>
            <PrimaryButton className="u-margin-T-s" submitting={isSubmitting} onClick={submitForm}>
              Create copy
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MTTCopyForm;
