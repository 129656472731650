import * as React from 'react';
import { useMemo } from 'react';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { adminPaths } from 'config/path_helpers';
import { DataTable } from '@magoosh/lib/data_table';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PrimaryButton } from '@magoosh/lib/button';
import DropdownList from 'react-widgets/lib/DropdownList';

interface Props {
  brandingOptions: {
    name: string;
    id: number;
  }[];
}

const ApplicantProfileAdminIndex: React.FC<Props> = (props: Props) => {
  const defaultQueryParams = useMemo(() => {
    const thoseParams = { sortColumn: 'id', filters: {} };
    return thoseParams;
  }, []);
  // TODO add search/filters/etc

  const { runQuery, dataQueryTableProps } = useDataQuery(
    adminPaths.api.applicantProfiles,
    defaultQueryParams
  );

  return (
    <div>
      <h1>Applicant Profiles</h1>

      <DataTable
        {...dataQueryTableProps}
        columns={[
          {
            name: 'ID',
            selector: 'id',
            cell: (row) => <a href={adminPaths.applicantProfile(row.id)}>{row.id}</a>
          },
          {
            name: 'Name',
            selector: 'name',
            cell: (row) => <a href={adminPaths.applicantProfile(row.id)}>{row.name}</a>
          },
          {
            name: 'Branding',
            selector: 'branding'
          },
          {
            name: 'Access Level',
            selector: 'accessLevel',
            cell: (row) => row.accessLevel.name
          }
        ]}>
        <DataTable.HeaderActions>
          <PrimaryButton href={adminPaths.applicantProfileNew()} icon="plus">
            Create
          </PrimaryButton>
        </DataTable.HeaderActions>
        <DataTable.Filters>
          <div style={{ width: '100%' }}>
            <DropdownList
              data={props.brandingOptions}
              onChange={(selection) => {
                runQuery({ brandingId: selection.id });
              }}
              placeholder="All brandings"
              textField="name"
              valueField="id"
              dataKey="id"
            />
          </div>
        </DataTable.Filters>
      </DataTable>
    </div>
  );
};

export default (props: Props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ApplicantProfileAdminIndex {...props} />
    </QueryClientProvider>
  );
};
