import * as React from 'react';
import { useHistory } from "react-router-dom";
import VideoPlayer from '@magoosh/organisms/video_player/template';
import Editor from '@magoosh/pages/writing_experience/show/editor';
import { Col, Row } from 'react-bootstrap';
import { SecondaryButton } from '@magoosh/lib/button';
import style from './style.module.scss';
import { EditorStatus, GuidedExperienceWritingStep } from '@magoosh/pages/writing_experience/types';
import { RatingData } from 'types/rating';
import { useEffect } from 'react';
import fetch from 'utilities/fetch';
import cx from 'classnames';
import RatingForm from '@magoosh/organisms/rating';

interface Props {
  submitForm?: () => void;
  setFieldValue?: (field: string, value: any) => void;
  rootPath: string;
  nextStep?: number;
  previousStep?: number;
  step: GuidedExperienceWritingStep;
  onClickNext: (GuidedExperienceStep, completed) => void;
  editorStatus: EditorStatus;
  setEditorStatus: (status: EditorStatus) => void;
}

const EditStep: React.FC<Props> = (props) => {
  const history = useHistory();

  const onClickNext = () => {
    props.onClickNext(props.step, true);
    history.push(`${props.rootPath}/step/${props.nextStep}`);
    window.scrollTo(0, 0);
  };
  const onClickPrevious = () => {
    history.push(`${props.rootPath}/step/${props.previousStep}`);
    window.scrollTo(0, 0);
  };

  const [rating, setRating] = React.useState<RatingData>(null);

  useEffect(() => {
    fetch(`/api/web/ratings/ContentBlock::Base/${props.step.id}`).then((response) =>
      setRating(response.rating)
    );
  }, []);

  return (
    <div className={style.guidedExperienceStep}>
      <h1 className={style.stepHeader}>{props.step.header}</h1>

      <Row className={style.editRow}>
        {props.step.lesson && (
          <Col md={6}>
            <VideoPlayer {...props.step.lesson.videoPlayerProps} />
          </Col>
        )}
        <Col md={props.step.lesson ? 6 : 12}>
          <Editor
            defaultWritingTab={props.step.defaultWritingTab}
            setFieldValue={props.setFieldValue}
            submitForm={props.submitForm}
            status={props.editorStatus}
            setEditorStatus={props.setEditorStatus}
          />
        </Col>
      </Row>
      <div className={style.navButtons}>
        {props.previousStep && (
          <div className="btn-backward pull-left">
            <SecondaryButton className="backward" onClick={onClickPrevious}>
              Previous
            </SecondaryButton>
          </div>
        )}
        {props.nextStep && (
          <div className="btn-forward pull-right">
            <SecondaryButton className="forward" onClick={onClickNext}>
              Next
            </SecondaryButton>
          </div>
        )}
      </div>

      {rating && (
        <div className={cx('u-margin-V-m', style.ratingFormContainer)}>
          <RatingForm rating={rating} rateableName={'step'} centerButtons />
        </div>
      )}
    </div>
  );
};

export default EditStep;
