import * as React from 'react';
import fetch from 'utilities/fetch';
import cx from 'classnames';
import throttle from 'lodash/throttle';
import { paths } from 'config/path_helpers';

interface Props {
  flagged: boolean;
  promptId: number;
}

export default class AnswerFlag extends React.Component<Props> {
  state = {
    flagged: this.props.flagged
  };

  render() {
    return (
      <div
        className={cx('flag', this.state.flagged ? 'active' : null)}
        onClick={this.throttledToggleFlag}
        role="button"
        aria-label="Click to flag this answer">
        <i />
      </div>
    );
  }

  toggleFlag = async () => {
    await fetch(paths.api.flagAnswer, {
      method: this.state.flagged ? 'DELETE' : 'POST',
      body: JSON.stringify({
        prompt_id: this.props.promptId,
        authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute('content')
      })
    });

    this.setState({ flagged: !this.state.flagged });
  };

  throttledToggleFlag = throttle(this.toggleFlag, 600);
}
