import React, { useRef, useState } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';

import { DefaultButton } from '@magoosh/lib/button';
import { Input } from '@magoosh/lib/forms';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import style from './style.module.scss';

interface Props {
  notableId: number;
  notableType: 'lesson' | 'prompt';
  text: string;
}

const NoteForm: React.FC<Props> = (props) => {
  const authenticityToken = useAuthenticityToken();
  const [isSaving, setIsSaving] = useState(false);
  const [text, setText] = useState(props.text);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null);

  const debouncedClearLastUpdatedAt = useRef(
    debounce(() => {
      setLastUpdatedAt(null);
    }, 5000)
  ).current;

  const debouncedSave = useRef(
    debounce((updatedText) => {
      save(updatedText);
    }, 1000)
  ).current;

  const handleSaveEntry = () => {
    setIsSaving(true);
    save(text).then(() => setIsSaving(false));
  }

  const save = (updatedText) => {
    return fetch(paths.api.saveNote(props.notableId, props.notableType), {
      method: 'PUT',
      body: JSON.stringify({
        authenticityToken,
        note: {
          text: updatedText
        }
      })
    }).then((response) => {
      setLastUpdatedAt(moment());
      debouncedClearLastUpdatedAt();
    });
  };

  const updateText = (updatedText) => {
    setText(updatedText);
    debouncedSave(updatedText);
  };

  return (
    <>
      <Input
        className={style.textArea}
        componentClass="textarea"
        onChange={(e) => updateText(e.target.value)}
        value={text}
      />

      <div className="d-flex">
        <DefaultButton onClick={handleSaveEntry} submitting={isSaving}>
          Save Entry
        </DefaultButton>

        {
          lastUpdatedAt && (
            <div className="d-flex flex-1 justify-content-end">
              <div className="text-gray-light" style={{ fontSize: 12}}>
                saved {lastUpdatedAt.fromNow()}
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};

export default NoteForm;
