import * as React from 'react';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { useMemo, useState } from 'react';
import { adminPaths, paths } from 'config/path_helpers';
import { DataTable } from '@magoosh/lib/data_table';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PrimaryButton } from '@magoosh/lib/button';
import Modal from '@magoosh/lib/modal';
import AdminWritingExampleForm from '@magoosh/admin/writing_examples/index/form';

interface Props {}
const WritingExampleIndex: React.FC<Props> = (props) => {
  const defaultQueryParams = useMemo(() => {
    const thoseParams = { sortColumn: 'id', filters: {} };
    return thoseParams;
  }, []);

  // TODO add search/filters/etc
  const { runQuery, dataQueryTableProps } = useDataQuery(adminPaths.api.writingExamples, defaultQueryParams);

  const [showEditModal, setShowEditModal] = useState(false);
  const [currentExample, setCurrentExample] = useState(null);
  const openCreateModal = () => {
    setShowEditModal(true);
    setCurrentExample(null);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setCurrentExample(null);
  };

  return (
    <div>
      <h1>Writing Examples</h1>
      <DataTable
        {...dataQueryTableProps}
        selectableRows={false}
        columns={[
          {
            name: 'ID',
            selector: 'id'
          },
          {
            name: 'Title',
            selector: 'title',
            cell: (row) => <a href={adminPaths.writingExample(row.id)}>{row.title}</a>
          },
          {
            name: 'Document Type',
            selector: 'documentType'
          },
          {
            name: 'Brandings',
            selector: 'brandings',
            cell: (row) => row.brandings.map((branding) => branding.name).join(', ')
          },
          {
            name: 'Access Level',
            selector: 'accessLevel',
            cell: (row) => row.accessLevel.name
          }
        ]}>
        <DataTable.HeaderActions>
          <PrimaryButton onClick={openCreateModal} icon="plus">
            Create
          </PrimaryButton>
        </DataTable.HeaderActions>
      </DataTable>
      <Modal
        onHide={closeEditModal}
        show={showEditModal}
        title={currentExample !== null ? 'Edit Example' : 'New Example'}
        showCloseButton={true}>
        <div>
          <AdminWritingExampleForm writingExample={currentExample} />
        </div>
      </Modal>
    </div>
  );
};

export default (props: Props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <WritingExampleIndex {...props} />
    </QueryClientProvider>
  );
};
