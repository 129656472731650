import * as React from 'react';
import {Field} from "formik";
import {Col, Row} from "react-bootstrap";

interface FieldRadioProps {
  name: string;
  value: string;
  id: string;
  checked: boolean;
  children: React.ReactNode;
  className?: string;

}

export const FieldRadio = (props: FieldRadioProps) => {
  return <Row className={props.className}>
    <Col xs={1}>
      <Field name={props.name}
             type={'radio'}
             value={props.value}
             id={props.id}
             checked={props.checked}/>
    </Col>

    <Col xs={10}>
      <label htmlFor={props.id}>
        {props.children}
      </label>
    </Col>
  </Row>
};
