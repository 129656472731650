import React, { useState } from 'react';
import cx from 'classnames';

import CardLayout from '@magoosh/layouts/card';
import { HandsHoldingPlantIcon, LightBulbPlantIcon } from '@magoosh/lib/icons';
import ProgressBar from '@magoosh/lib/progress_bar';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from '../style.module.scss';

interface Props {
  examName: string;
  examVersion: string;
}

const WhatYoullGetContent: React.FC<Props> = (props) => {
  // TODO - default data
  const [examWhatYoullGetData, setExamWhatYoullGetData] = useState({
    breakdownSections: [],
    scoreSections: []
  })

  // The expected format of the exam specific data is:
  // {
  //   <exam_version>: { breakdownSections: [], scoreSections: [] }
  // }
  import(`./exams/${props.examName.trim().toLowerCase().replace(/ /g, '_')}`).then((data) => {
    setExamWhatYoullGetData(data.default[props.examVersion]);
  }).catch((error) => {});

  return (
    <div>
      <h3 className="u-margin-B-m u-margin-T-n">
        <strong>What You'll Get</strong>
      </h3>

      <div className="d-flex flex-column" style={{ gap: 40 }}>
        <div className="d-flex flex-column" style={{ gap: 20 }}>
          <div>
            Accurate Score Prediction
            <hr className="u-margin-T-xxs u-margin-B-n" />
          </div>

          <div className="d-flex" style={{ gap: 20 }}>
            <div>
              <LightBulbPlantIcon fill={colors.brandPrimary} height={60} width={60} />
            </div>

            <div className="small">
              See where you stand with a real, predictive score. You’ll get a score estimate for each section and a total score.
            </div>
          </div>

          <div>
            <div className="u-margin-B-xxs">
              <small className="text-gray-light" style={{ fontSize: 12 }}>
                Sample score prediction
              </small>
            </div>

            <div className={cx(style.scorePredictionCards, 'd-flex')} style={{ gap: 10 }}>
              {
                examWhatYoullGetData.scoreSections.map((scoreSection, idx) => (
                  <div
                    key={idx}
                    className="d-flex align-items-center flex-column u-padding-A-s"
                    style={{
                      backgroundColor: colors.greenLight,
                      borderColor: colors.green,
                      borderStyle: 'solid',
                      borderWidth: '0px 0px 2px',
                      gap: 10,
                      position: 'relative',
                      minWidth: 85
                    }}
                  >
                    <div className="text-center">
                      <small>
                        <strong>{scoreSection.name}</strong>
                      </small>
                    </div>

                    <div>
                      <h3 className="text-primary u-margin-A-n">
                        <strong>{scoreSection.sampleScore}</strong>
                      </h3>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="d-flex flex-column" style={{ gap: 20 }}>
          <div>
            Subject by Subject Performance Breakdown
            <hr className="u-margin-T-xxs u-margin-B-n" />
          </div>

          <div className="d-flex" style={{ gap: 20 }}>
            <div>
              <HandsHoldingPlantIcon fill={colors.brandSecondary} height={60} width={60} />
            </div>

            <div className="small">
              Learn how you performed by subject, and where it’s worth focusing your studies.
            </div>
          </div>

          <div>
            <div className="u-margin-B-xxs">
              <small className="text-gray-light" style={{ fontSize: 12 }}>
                Sample performance breakdown
              </small>
            </div>

            <div className="d-flex flex-column" style={{ gap: 10 }}>
              {
                examWhatYoullGetData.breakdownSections.map((breakdownSection, idx) => (
                  <div
                    key={idx}
                    className="d-flex align-items-center"
                    style={{ gap: 10 }}
                  >
                    <div className="small text-right" style={{ width: 170 }}>
                      <strong>{breakdownSection.name}</strong>
                    </div>

                    <div className="flex-1">
                      <ProgressBar
                        color={breakdownSection.percentCorrect !== 0 ? colors.green : colors.greenLight}
                        backgroundColor={colors.greenLight}
                        percentage={breakdownSection.percentCorrect === 0 ? 100 : Math.max(breakdownSection.percentCorrect, 35)}
                        size="md"
                        minLabel={`${breakdownSection.percentCorrect}%`}
                        minLabelStyle={{
                          display: 'flex',
                          alignSelf: 'center',
                          paddingLeft: '10px',
                          color: breakdownSection.percentCorrect !== 0 ? '#ffffff' : '#817E85',
                          fontSize: 12
                        }}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatYoullGetContent;
