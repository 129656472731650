import React from 'react';

const AppContext = React.createContext<any>(null);

export const useAppContext = () => {
  const context = React.useContext(AppContext) as any;
  if (context === undefined) {
    throw new Error('You must wrap your page with <AppProvider /> in order to useAppContext().');
  }
  return context;
};

interface Props {
  initialData?: any;
}

export const AppProvider: React.FC<Props> = ({ children, initialData }) => {
  const defaultData = {} as any;

  const [data, setData] = React.useState(initialData || defaultData);

  const setFilters = (updatedFilters) => {
    setData({ ...data, filters: updatedFilters });
  };

  return <AppContext.Provider value={{ data, setData, setFilters }}>{children}</AppContext.Provider>;
};
