import * as React from "react";
import {Col, Row} from "react-bootstrap"
import * as moment from 'moment';

import {Subscription,StudentActivity} from "./types";
import style from './style.module.scss';

export class StudentUsageSummary extends React.Component<Subscription> {
    render() {
        const subscription = this.props;
        const usageSummary = subscription.usageSummary;

        return (
            <div>
                <Row>
                    <Col md={12} className={style.sectionHeader}>
                        Usage Summary
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Row className={style.headerRow}>
                            <Col md={4} />
                            <Col md={6}>Last 7 day usage</Col>
                        </Row>
                        <Row className={style.usageSummary}>
                            <Col md={4}>Lesson Videos</Col>
                            <Col md={6}>{usageSummary.weekLessonVideos}</Col>
                        </Row>
                        <Row className={style.usageSummary}>
                            <Col md={4}>Practice Questions</Col>
                            <Col md={6}>{usageSummary.weekPracticeQuestions}</Col>
                        </Row>
                        <Row className={style.usageSummary}>
                            <Col md={4}>Explanation Videos</Col>
                            <Col md={6}>{usageSummary.weekExplanationVideos}</Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row className={style.activeDaysHeaderRow}>
                            <Col md={4} />
                            <Col md={6}>Last 10 Active Days</Col>
                        </Row>
                        {usageSummary.last10DaysActivity.map((activity: StudentActivity, index: number) => {
                            const duration = moment.duration(activity.sum, 'seconds');
                            let formattedSpan = '';

                            if (duration.asHours() > 1) { formattedSpan += Math.floor(duration.asHours()) + ' hours, ' }
                            formattedSpan += duration.minutes() + ' minutes';

                            return (
                                <Row key={index}>
                                    <Col md={1}>{index + 1}.</Col>
                                    <Col md={4}>{moment(activity.createdAt).format('l')}</Col>
                                    <Col md={5} className={style.activeDaySpan}>{formattedSpan}</Col>
                                </Row>
                            )
                        })}
                    </Col>
                </Row>
            </div>
        )
    }
}
