import * as React from 'react';
import {ErrorMessage, Field} from "formik";
import {Col, Row} from "react-bootstrap";
import capitalize from "react-bootstrap/lib/utils/capitalize";
import style from "../formik_inputs/field_input/style.module.scss";

interface FieldRadioProps {
  name: string;
  id?: string;
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;

}

export const FieldCheckbox = (props: FieldRadioProps) => {
  return <Row className={props.className}>
    <Col xs={1}>
      <Field name={props.name}
             type={'checkbox'}
             id={props.id || props.name}/>
    </Col>

    <Col xs={10}>
      <label htmlFor={props.id}>
        {props.children}
      </label>
    </Col>
  </Row>
};

interface SimpleFieldRadioProps {
  name: string;
  label?: string;
  id?: string;
  checked: boolean;
}

export const SimpleFieldCheckbox = (props: SimpleFieldRadioProps) => {
  return <div className="u-margin-T-s">
    <label htmlFor={id(props)} className="u-margin-R-xs" >{capitalize(props.label || props.name)}:</label>
    <Field name={props.name}
           type="checkbox"
           id={id(props)}
           checked={props.checked}
           className="u-margin-L-xs"

    />
    <div className={style.errorMessage}>
      <ErrorMessage name={props.name}/>
    </div>

  </div>
};

function id(props: SimpleFieldRadioProps) {
  return props.id || `${props.name}-input`
}
