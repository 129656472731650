import React from 'react';
import cx from 'classnames';
import { find } from 'lodash';

import { Select } from '@magoosh/lib/forms';
import { PremiumBadge } from '@magoosh/lib/info_badges';

import { Template } from '../types';
import style from '../style.module.scss';

interface Props {
  currentTemplate: Template;
  onChange: (Template) => void;
  templates: Template[];
}

const TemplateRadioGroup: React.FC<Props> = (props) => {
  const OPTIONS_TO_SHOW = 3;

  const featuredTemplates = props.templates.slice(0, OPTIONS_TO_SHOW);
  const additionalTemplates = props.templates.slice(OPTIONS_TO_SHOW);

  const showAdditionalTemplates = !!find(additionalTemplates, (template) => props.currentTemplate?.id === template.id);
  const additionalTemplatesAvailable = additionalTemplates.some((template) => template.available);

  return (
    <>
      <div><strong>Choose Your Test</strong></div>

      <fieldset className="radio u-margin-A-n">
        {
          featuredTemplates.map((template) => (
            <div className="radio" key={template.id}>
              <label>
                <input
                  checked={props.currentTemplate?.id === template.id}
                  disabled={!template.available}
                  name="templateId"
                  onChange={() => { props.onChange(template) }}
                  type="radio"
                  value={template.id}
                />
                <span className={cx({ 'text-gray-light': !template.available })}>
                  {template.name} {!template.available && <PremiumBadge className="u-margin-L-xxs" style={{ fontSize: 12, padding: '4px 8px' }} />}
                </span>
              </label>
            </div>
          ))
        }

        {
          !!additionalTemplates.length && (
            <div className="radio">
              <label>
                <input
                  checked={showAdditionalTemplates}
                  disabled={!additionalTemplatesAvailable}
                  name="templateId"
                  onChange={() => { props.onChange(additionalTemplates[0]) }}
                  type="radio"
                />
                <span className={cx({ 'text-gray-light': !additionalTemplatesAvailable })}>
                  Select a different test ({additionalTemplates.length} more) {!additionalTemplatesAvailable && <PremiumBadge className="u-margin-L-xxs" style={{ fontSize: 12, padding: '4px 8px' }} />}
                </span>
              </label>
            </div>
          )
        }
      </fieldset>

      {
        showAdditionalTemplates && (
          <div className="d-flex">
            <div style={{ backgroundColor: '#DADDE7', marginLeft: 6, height: 19, width: 1 }} />

            <div className="align-items-start d-flex" style={{ gap: 10 }}>
              <div
                style={{
                  backgroundColor: '#DADDE7',
                  flex: 1,
                  height: 1,
                  marginTop: 18,
                  maxWidth: 15,
                  minWidth: 15
                }}
              />

              <Select
                className={style.select}
                groupClassName="u-margin-A-n"
                onChange={(e) => {
                  const templateId = parseInt((e.target as any).value)
                  const template = find(additionalTemplates, (t) => t.id === templateId)
                  props.onChange(template)
                }}
                value={props.currentTemplate?.id}
              >
                {
                  additionalTemplates.map((template) => (
                    <option disabled={!template.available} key={template.id} value={template.id}>
                      {template.name} {!template.available && '(Premium)'}
                    </option>
                  ))
                }
              </Select>
            </div>
          </div>
        )
      }
    </>
  );
};

export default TemplateRadioGroup;
