import React, { useEffect, useState } from 'react';
import { EditStudentFormValues } from './types';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import colors from '../../assets/style.module.scss';
import PageColumn from '../../lib/page_column';
import Icon from '../../assets/icons';
import fetch from 'utilities/fetch';
import { ErrorMessages } from '@magoosh/lib/formik_inputs/errors_messages';
import { Spinner } from '@magoosh/b2b/app/lib/spinner';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

const validationSchema = yup.object({
  email: yup.string().required('Email is required.'),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  password: yup.string().nullable().min(6, 'Password must be at least 6 characters long.')
});

export const EditStudentForm: React.FC<{ id: number }> = ({ id }) => {
  const { studentId } = useParams();
  const [student, setStudent] = useState<EditStudentFormValues>();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(paths.api.partner.student(studentId)).then((response) => {
      setStudent(response);
    });
  }, [id]);

  const handleOnSubmit = (
    values: EditStudentFormValues,
    { setStatus, setSubmitting }: FormikActions<EditStudentFormValues>
  ): void => {
    setSubmitting(true);
    fetch(paths.api.partner.student(studentId), {
      body: JSON.stringify(values),
      method: 'PUT'
    })
      .then((response) => history.push(`/students/${response.id}`))
      .catch((response) => {
        setStatus(response.errors);
        setSubmitting(false);
      });
  };

  if (!student) {
    return <Spinner />;
  }

  const initialValues = {
    email: student.email,
    firstName: student.firstName,
    lastName: student.lastName,
    password: '',
    parentEmail: student.parentEmail
  };

  return (
    <>
      <PageColumn>
        <div className={style.editStudentForm}>
          <div className={style.formHeader}>
            <div className={style.headerText}>
              <h2 className="u-margin-T-n">Edit Student</h2>
              <h4>Update information about the student.</h4>
            </div>
            <div className="u-margin-L-s">
              <Icon iconType="Student" fill={colors.supplementaryRainforest} size="l" />
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}>
            {({ isSubmitting, submitForm, errors, status }) => (
              <Form>
                <Row>
                  <Col md={12}>
                    <FieldInput name="email" type="email" label="Email" />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FieldInput name="firstName" type="text" label="First Name" />
                  </Col>
                  <Col md={6}>
                    <FieldInput name="lastName" type="text" label="Last Name" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FieldInput
                      name="password"
                      type="password"
                      label="New Password"
                      autoComplete={'new-password'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="u-margin-T-s">
                      <small>
                        <FontAwesomeIcon icon={'info-circle'} /> Only provide a new password if you want to
                        reset this student{"'"}s password. Leave this blank to keep their current password.
                      </small>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FieldInput name="parentEmail" type="email" label="Parent Email" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="u-margin-T-s">
                      <small>
                        <FontAwesomeIcon icon={'info-circle'} /> An automated email will be sent weekly that
                        shares questions answered, videos watched, etc.
                      </small>
                    </p>
                  </Col>
                </Row>

                <br />

                <Row className="u-margin-T-s">
                  <Col xs={6}>
                    <SecondaryButton disabled={isSubmitting} onClick={submitForm} block>
                      Save Student
                    </SecondaryButton>
                  </Col>

                  <Col xs={6}>
                    <DefaultButton onClick={() => history.push('/')} block>
                      Cancel
                    </DefaultButton>
                  </Col>
                </Row>

                <ErrorMessages errors={errors} />
                <ErrorMessages errors={status || []} />
              </Form>
            )}
          </Formik>
        </div>
      </PageColumn>
    </>
  );
};

export default EditStudentForm;
