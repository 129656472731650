import React from 'react';
import cx from 'classnames';

import { DefaultButton, DefaultFilledButton } from '@magoosh/lib/button';
import Card from '@magoosh/lib/card';
import{ FontAwesomeIcon, SquareIcon } from '@magoosh/lib/icons';
import TaskWithIcon from '@magoosh/organisms/task_with_icon';
import { ColorPalette } from 'types/constants';
import { CheckableContentBlocksType } from 'types/content_blocks';

import AdditionalActionsButton from '@admissions/components/additional_actions_button';
import { useQueries } from '@admissions/queries';
import { ApplicationCyclePlanItem } from '@admissions/types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  applicationCycle: string;
  item: ApplicationCyclePlanItem;
}

const DEFAULT_COLOR_PALETTE = { dark: colors.gray, light: colors.grayLighter } as ColorPalette;

const ItemCard: React.FC<Props> = (props) => {
  const q = useQueries();

  const primaryCTA = {
    onClick: () => handleItemToggleComplete(),
    title: props.item.isCompleted ? 'Mark As To Do' : 'Mark Complete'
  };

  const handleItemToggleComplete = () => {
    q.toggleApplicationCyclePlanItemCompleteMutation.mutate({ cycle: props.applicationCycle, item: props.item });
  };

  const handleTaskToggleComplete = (task: CheckableContentBlocksType) => {
    q.toggleApplicationCyclePlanTaskCompleteMutation.mutate({ cycle: props.applicationCycle, item: props.item, task });
  };

  if (props.item.isCompleted) {
    return (
      <CompletedCard
        {...props}
        onClickTask={handleTaskToggleComplete}
        primaryCTA={primaryCTA}
      />
    );
  } else if (props.item.isPastDue) {
    return (
      <PastDueCard
        {...props}
        onClickTask={handleTaskToggleComplete}
        primaryCTA={primaryCTA}
      />
    );
  } else {
    return (
      <ToDoCard
        {...props}
        onClickTask={handleTaskToggleComplete}
        primaryCTA={primaryCTA}
      />
    );
  }
};

interface CardProps extends Props {
  onClickTask: (task: CheckableContentBlocksType) => void;
  primaryCTA: {
    onClick: () => void;
    title: string;
  }
}

const CompletedCard: React.FC<CardProps> = (props) => {
  return (
    <Card>
      <div className="d-flex">
        <div>
          <SquareIcon
            colorPalette={props.item.colorPalette || DEFAULT_COLOR_PALETTE}
            completed={props.item.isCompleted}
            iconContents={props.item.iconContents}
            iconType={props.item.icon}
            size={48}
          />
        </div>
        <div style={{ flex: 1 }}>
          <div className="align-items-center d-flex justify-content-end u-margin-B-m">
            <div className="visible-sm visible-xs">
              <AdditionalActionsButton actions={[props.primaryCTA]} />
            </div>

            <div className="hidden-sm hidden-xs">
              <DefaultFilledButton className={style.defaultFilledButton} onClick={props.primaryCTA.onClick}>{props.primaryCTA.title}</DefaultFilledButton>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className="text-gray-light u-margin-A-n">
          {props.item.category}
        </p>
        <h3 className="text-gray-light u-margin-A-n">
          {props.item.name}
        </h3>
      </div>

      <div className="d-flex u-margin-T-xxs">
        <div
          className="align-self-stretch hidden-sm hidden-xs u-margin-L-m"
          style={{ backgroundColor: '#DADDE7', width: 1 }}
        />

        <div>
          {
            props.item.tasks.map((task) => {
              return (
                <div key={task.id} className="align-items-start d-flex u-margin-T-xs" style={{ gap: 10 }}>
                  <div
                    className="hidden-sm hidden-xs"
                    style={{
                      backgroundColor: '#DADDE7',
                      flex: 1,
                      height: 1,
                      marginTop: 13,
                      maxWidth: 15,
                      minWidth: 15
                    }}
                  />

                  <TaskWithIcon
                    icon="check"
                    onClick={task.id ? props.onClickTask : null}
                    task={task}
                    taskClassName="text-gray-light"
                  />
                </div>
              );
            })
          }
        </div>
      </div>
    </Card>
  );
};

const PastDueCard: React.FC<CardProps> = (props) => {
  return (
    <Card style={{
      backgroundColor: colors.yellowLight,
      borderColor: colors.yellow,
      borerWidth: 2
    }}>
      <div className="d-flex">
        <div className="align-self-center" style={{ fontSize: 30 }}>
          <FontAwesomeIcon icon="warning" />
        </div>
        <div style={{ flex: 1 }}>
          <div className="align-items-center d-flex justify-content-end u-margin-B-m">
            <div className="visible-sm visible-xs">
              <AdditionalActionsButton
                actions={[props.primaryCTA]}
                style={{
                  backgroundColor: colors.yellowLight,
                  borderColor: colors.yellow
                }}
              />
            </div>

            <div className="hidden-sm hidden-xs">
              <DefaultButton
                className={cx(style.defaultButton, style.pastDue)}
                onClick={props.primaryCTA.onClick}
              >
                {props.primaryCTA.title}
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className="text-gray-light u-margin-A-n">
          {props.item.category}
        </p>
        <h3 className="u-margin-A-n">
          {props.item.name}
        </h3>
      </div>

      <div className="d-flex u-margin-T-xxs">
        <div
          className="align-self-stretch hidden-sm hidden-xs u-margin-L-m"
          style={{ backgroundColor: '#A19EA3', width: 1 }}
        />

        <div>
          {
            props.item.tasks.map((task) => {
              return (
                <div key={task.id} className="align-items-start d-flex u-margin-T-xs" style={{ gap: 10 }}>
                  <div
                    className="hidden-sm hidden-xs"
                    style={{
                      backgroundColor: '#A19EA3',
                      flex: 1,
                      height: 1,
                      marginTop: 13,
                      maxWidth: 15,
                      minWidth: 15
                    }}
                  />

                  <TaskWithIcon
                    icon={task.isCompleted ? 'check' : 'exclamation'}
                    iconColorPalette={{ dark: '#A19EA3', light: colors.yellowLight } as ColorPalette}
                    onClick={task.id ? props.onClickTask : null}
                    task={task}
                    taskClassName={style.pastDueTask}
                  />
                </div>
              );
            })
          }
        </div>
      </div>
    </Card>
  );
};

const ToDoCard: React.FC<CardProps> = (props) => {
  return (
    <Card>
      <div className="d-flex">
        <div>
          <SquareIcon
            colorPalette={props.item.colorPalette || DEFAULT_COLOR_PALETTE}
            completed={props.item.isCompleted}
            iconContents={props.item.iconContents}
            iconType={props.item.icon}
            size={48}
          />
        </div>
        <div style={{ flex: 1 }}>
          <div className="align-items-center d-flex justify-content-end u-margin-B-m">
            <div className="visible-sm visible-xs">
              <AdditionalActionsButton actions={[props.primaryCTA]} />
            </div>

            {
              props.item.id && (
                <div className="hidden-sm hidden-xs">
                  <DefaultButton className={style.defaultButton} onClick={props.primaryCTA.onClick}>
                    {props.primaryCTA.title}
                  </DefaultButton>
                </div>
              )
            }
          </div>
        </div>
      </div>

      <div>
        <p className="text-gray-light u-margin-A-n">
          {props.item.category}
        </p>
        <h3 className="u-margin-A-n">
          {props.item.name}
        </h3>
      </div>

      <div className="d-flex u-margin-T-xxs">
        <div
          className="align-self-stretch hidden-sm hidden-xs u-margin-L-m"
          style={{ backgroundColor: '#DADDE7', width: 1 }}
        />

        <div>
          {
            props.item.tasks.map((task) => {
              return (
                <div key={task.id} className="align-items-start d-flex u-margin-T-xs" style={{ gap: 10 }}>
                  <div
                    className="hidden-sm hidden-xs"
                    style={{
                      backgroundColor: '#DADDE7',
                      flex: 1,
                      height: 1,
                      marginTop: 13,
                      maxWidth: 15,
                      minWidth: 15
                    }}
                  />

                  <TaskWithIcon
                    icon={task.id ? 'check' : 'star'}
                    onClick={task.id ? props.onClickTask : null}
                    task={task}
                  />
                </div>
              );
            })
          }
        </div>
      </div>
    </Card>
  );
};

export default ItemCard;
