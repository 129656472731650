import React, { useCallback, useMemo, useState } from 'react';
import { FormControl, FormGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { DateRangePicker } from 'react-date-range';
import style from '@magoosh/lib/data_table/style.module.scss';
import moment from 'moment-timezone';
import throttle from 'lodash/debounce';

interface DateRangeFilterProps {
  runQuery: (newQueryOptions) => any;
}

export const DateRangeFilter: React.FC<DateRangeFilterProps> = (props) => {

  const { runQuery } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

  const throttledRunQuery = useMemo(() => {
    return throttle(runQuery, 500, { trailing: true });
  }
  , []);

  const handleDateRangeChange = useCallback((range) => {
    const startDate = range.selection.startDate;
    const endDate = moment(range.selection.endDate).endOf('day').toDate();

    setStartDate(startDate);
    setEndDate(endDate);

    throttledRunQuery({
      startDate: startDate,
      endDate: endDate
    });
  }, []);

  return <FormGroup className={style.formGroup}>
    <OverlayTrigger
      trigger="click"
      placement={"bottom"}
      rootClose
      overlay={
        <Popover style={{maxWidth: "600px"}} id='date-range-popover'>
          <DateRangePicker
            ranges={[{ startDate: startDate, endDate: endDate, key: 'selection'}]}
            onChange={handleDateRangeChange}
            editableDateInputs
            dateDisplayFormat={'P'}
            startDatePlaceholder={'Everything up to'}
          />
        </Popover>
      }
    >
      <div>
        <FormControl
          type="text"
          placeholder='Date Range'
          readOnly
          bsSize={'small'}
          value={`${startDate ? moment(startDate).format('ll') : 'Everything up'}` +
          ` to ` +
          `${endDate ? moment(endDate).format('ll') : '--'}`}
        />
        <FormControl.Feedback>
          <FontAwesomeIcon icon='calendar' />
        </FormControl.Feedback>
      </div>
    </OverlayTrigger>
  </FormGroup>;
}
