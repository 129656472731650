import React from 'react';
import cx from 'classnames';

import Breadcrumbs from '@magoosh/layouts/lib/breadcrumbs';
import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { Breadcrumb } from '@magoosh/layouts/types';

import style from './style.module.scss';

interface Props {
  breadcrumbs?: Breadcrumb[];
  className?: string;
  linkComponent?: React.ComponentType<any>;
  loading?: boolean;
  resizeableContainer?: boolean;
  style?: any;
}

const CardLayout: (React.FC<Props> & { Card: any }) = (props) => {
  const containerStyle = {} as any;
  // Causing a problem with modal backdrop covering modal in Safari and older browsers.
  // Disabling for now.
  // if (props.resizeableContainer) {
  //   containerStyle.containerType = 'inline-size';
  // }

  return (
    <div className={cx(style.page, props.className)} style={props.style}>
      {/* @ts-ignore */}
      <div className="container" id={props.resizeableContainer ? 'resizeable-container' : null} style={containerStyle}>
        {props.breadcrumbs && <Breadcrumbs breadcrumbs={props.breadcrumbs} linkComponent={props.linkComponent} />}
        {
          props.loading ? (
            <div className="d-flex justify-content-center u-margin-T-l">
              <LoadingIndicator />
            </div>
          ) : typeof props.children === 'function' ? (props.children as any)({ loading: props.loading }) : props.children
        }
      </div>
    </div>
  );
};

CardLayout.Card = (props) => {
  return (
    <div className={cx(style.pageCard, props.className)} style={props.style}>
      {props.children}
    </div>
  );
};

export default CardLayout;
