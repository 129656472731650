import * as React from 'react';
import { PrimaryButton } from '@magoosh/lib/button';
import { adminPaths, } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import { ContentBlock } from '@magoosh/admin/content_block_editor/types';

interface FormProps {
  block: ContentBlock;
  closeModal: () => void;
}

const BlockPreviewForm: React.FC<FormProps> = (props) => {
  const handleConfirm = () => {
    fetch(adminPaths.api.previewContentBlock(props.block.id), {
      method: 'POST',
    })
      .then((response) => {
        props.closeModal();
      })
      .catch((response) => alert(response.errors._error))
  };

  return (
    <div>
      <p>Preview <b>{props.block.name}</b>?</p>
      <PrimaryButton
        className="u-margin-T-s"
        onClick={handleConfirm}>
        Ok
      </PrimaryButton>
    </div>
  );
};

export default BlockPreviewForm;
