import * as React from 'react';
import {Collapse} from "react-bootstrap";
import style from './style.module.scss';
import {FontAwesomeIcon} from "@magoosh/lib/icons";

interface CollapsibleProps {
  header: string | React.ReactNode;
  children: React.ReactNode;
  startOpen?: boolean;
}

export default class Collapsible extends React.Component<CollapsibleProps> {
  state = {
    isOpen: this.props.startOpen
  };

  render() {
    return <div>
      <a className={style.headerLink} onClick={() => this.setState({isOpen: !this.state.isOpen})}>
        <h4>
          <FontAwesomeIcon icon={this.state.isOpen ? 'caret-down' : 'caret-right'}/>
          <span>{this.props.header}</span>
        </h4>

      </a>
      <Collapse in={this.state.isOpen}>
        {this.props.children}
      </Collapse>
    </div>
  }
}
