import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import style from '../style.module.scss';
import cx from 'classnames';
import ModuleCard from '../module_card';
import ProgressBar from '@magoosh/lib/progress_bar';
import { LessonModuleData } from '../types';

interface Props extends LessonModuleData {
  setItemCompleted: (number, boolean) => void;
}

export default class HighSchoolLessonModulesTemplate extends React.Component<Props> {
  render() {
    const { studyModuleTracks } = this.props;

    const modulesCompleted = studyModuleTracks[0].modules.filter((module) => {
      return module.studyItems.every((studyItem) => studyItem.completed);
    }).length;

    const percentCompleted = (modulesCompleted / studyModuleTracks[0].modules.length) * 100;

    return (
      <Col sm={10} smOffset={1}>
        <Row>
          <p className={cx(style.underline, 'u-margin-T-s')}>
            <strong> {this.props.examSection.displayName} - Guided Practice</strong>
          </p>
        </Row>
        <Row>
          <Col sm={8} smOffset={2} className="u-margin-T-s">
            <p>
              <strong>
                Modules Completed: {modulesCompleted} of {studyModuleTracks[0].modules.length}
              </strong>
            </p>
            <ProgressBar
              backgroundColor={style.grayColor}
              color={style.brandPrimaryColor}
              percentage={percentCompleted}
              size={'md'}
              minLabel={percentCompleted >= 15 && `${Math.round(percentCompleted).toString()}%`}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="u-margin-T-s">
            {studyModuleTracks.map((track) => (
              <div className={style.moduleTrack} key={track.examSection}>
                <div className={style.modules}>
                  {track.modules.map((module) => {
                    return (
                      <div key={module.id} className={style.moduleCard}>
                        <ModuleCard
                          setItemCompleted={this.props.setItemCompleted}
                          showDifficulty={true}
                          branding={'act'}
                          {...module}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Col>
    );
  }
}
