import { useMutation, useQuery, useQueryClient } from 'react-query';
import { filter, find, findIndex, flatMap, isEqual } from 'lodash';

import { CheckableContentBlocksType } from 'types/content_blocks';

import * as api from './api';
import {
  ApplicationCycleChunk,
  ApplicationCycleDeadline,
  ApplicationCyclePlan,
  ApplicationCyclePlanItem,
  ApplicationCycleSection,
  ApplicationCycleSegment
} from './types';

// These queries and mutations use React hooks, which can only be called from within a functional
// component. Because of that we need to wrap them in functions like below. To access them in a
// functional component:
//   import { useQueries } from './queries';
//   const q = useQueries();
export const useQueries = () => {
  return {
    applicationCycleChunksQuery,
    applicationCyclePlanQuery,
    applicationCycleSectionsQuery,
    highlightedContentQuery,
    studyScheduleQuery,
    fetchMoreChunksMutation: fetchMoreChunksMutation(),
    toggleApplicationCyclePlanItemCompleteMutation: toggleApplicationCyclePlanItemCompleteMutation(),
    toggleApplicationCyclePlanTaskCompleteMutation: toggleApplicationCyclePlanTaskCompleteMutation(),
    updateOrCreateApplicationCycleDeadlineMutation: updateOrCreateApplicationCycleDeadlineMutation()
  };
};

// Utility functions
const chunksWithUpdatedItem = (chunks: ApplicationCycleChunk[], updatedItem: ApplicationCyclePlanItem) => {
  return chunks.map((chunk) => {
    const itemIndex = findIndex(chunk.items, (item: ApplicationCyclePlanItem) => item.id === updatedItem.id);

    if (itemIndex !== -1) {
      chunk.items[itemIndex] = updatedItem;
    }

    return chunk;
  });
};

const chunksWithUpdatedTask = (chunks: ApplicationCycleChunk[], updatedTask: CheckableContentBlocksType) => {
  return chunks.map((chunk) => {
    const items = chunk.items.map((item) => {
      const taskIndex = findIndex(item.tasks, (task: CheckableContentBlocksType) => task.id === updatedTask.id);

      if (taskIndex !== -1) {
        item.tasks[taskIndex] = updatedTask;
      }

      return item;
    });

    return {
      ...chunk,
      items
    };
  });
};

const sectionsWithUpdatedSegment = (sections: ApplicationCycleSection[], updatedSegment: ApplicationCycleSegment) => {
  return sections.map((section) => {
    const segmentIndex = findIndex(section.segments, (segment: ApplicationCycleSegment) => segment.id === updatedSegment.id);

    if (segmentIndex !== -1) {
      section.segments[segmentIndex] = updatedSegment;
    }

    return section;
  });
};

const itemForTask = (chunks: ApplicationCycleChunk[], targetTask: CheckableContentBlocksType): ApplicationCyclePlanItem => {
  const allItems = flatMap(chunks, (chunk) => chunk.items);
  return find(allItems, (item: ApplicationCyclePlanItem) => {
    return findIndex(item.tasks, (task) => task.id === targetTask.id) !== -1
  });
};

export const applicationCyclePlanQuery = (cycle: string, options = {}) => {
  return useQuery('plan', () => api.fetchApplicationCyclePlan(cycle), {
    retry: false,
    staleTime: 1000,
    ...options
  });
};

let chunksKey;
export const applicationCycleChunksQuery = (chunkArgsFn, options = {}) => {
  const chunkArgs = chunkArgsFn();
  const queryClient = useQueryClient();

  const newChunksKey = ['chunks', chunkArgs.filters];
  if (!isEqual(chunksKey, newChunksKey)) {
    queryClient.cancelQueries([chunksKey]);
    queryClient.removeQueries({ queryKey: 'chunks' });
  }
  chunksKey = newChunksKey;

  return useQuery(chunksKey, () => api.fetchApplicationCycleChunks(chunkArgs), {
    retry: false,
    ...options
  });
};

export const applicationCycleSectionsQuery = (cycle: string, options = {}) => {
  return useQuery('sections', () => api.fetchApplicationCycleSections(cycle), {
    retry: false,
    staleTime: 1000,
    ...options
  });
};

export const fetchMoreChunksMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(api.fetchApplicationCycleChunks, {
    onMutate: async (settings) => {
      await queryClient.cancelQueries(chunksKey);
    },
    onSuccess: (result: any, variables, context) => {
      const currentData: any = queryClient.getQueryData(chunksKey);
      queryClient.setQueryData(chunksKey, [...currentData, ...result]);
    }
  });
};

export const highlightedContentQuery = (options = {}) => {
  return useQuery('highlightedContent', api.fetchHighlightedContent, {
    retry: false,
    staleTime: 1000,
    ...options
  });
};

export const studyScheduleQuery = (options = {}) => {
  return useQuery('studySchedule', api.fetchStudySchedule, {
    retry: false,
    staleTime: 1000,
    ...options
  });
};

export const toggleApplicationCyclePlanItemCompleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(api.toggleApplicationCyclePlanItemComplete, {
    onMutate: async ({ cycle, item }) => {
      // Update cached data so it instantly reflects the completion
      await queryClient.cancelQueries([chunksKey, 'sections']);
      const currentChunks: ApplicationCycleChunk[] = queryClient.getQueryData(chunksKey);

      // Update item completion state
      const updatedItem = { ...item, isCompleted: !item.isCompleted };
      queryClient.setQueryData(chunksKey, chunksWithUpdatedItem(currentChunks, updatedItem));

      // Update section progress
      const sections: ApplicationCycleSection[] = queryClient.getQueryData('sections');
      if (sections) {
        const updatedSegment = { ...updatedItem.segment, isCompleted: updatedItem.isCompleted };
        queryClient.setQueryData('sections', sectionsWithUpdatedSegment(sections, updatedSegment));
      }

      // Update deadline progress
      const plan: ApplicationCyclePlan = queryClient.getQueryData('plan');
      if (plan.deadlines) {
        const updatedDeadlines = plan.deadlines.map((deadline) => {
          if (updatedItem.deadline) {
            if (updatedItem.deadline.id === deadline.id) {
              const updatedProgress = {
                ...deadline.progress,
                completedItems: updatedItem.isCompleted ? deadline.progress.completedItems += 1 : deadline.progress.completedItems -= 1
              };
              return { ...deadline, progress: updatedProgress };
            } else {
              return deadline;
            }
          } else {
            const updatedProgress = {
              ...deadline.progress,
              completedItems: updatedItem.isCompleted ? deadline.progress.completedItems += 1 : deadline.progress.completedItems -= 1
            };
            return { ...deadline, progress: updatedProgress };
          }
        });

        queryClient.setQueryData('plan', { ...plan, deadlines: updatedDeadlines });
      }
    }
  });
};

export const toggleApplicationCyclePlanTaskCompleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(api.toggleApplicationCyclePlanTaskComplete, {
    onMutate: async ({ cycle, item, task }) => {
      // Update cached data so it instantly reflects the completion
      await queryClient.cancelQueries([chunksKey, 'sections']);
      const currentChunks: ApplicationCycleChunk[] = queryClient.getQueryData(chunksKey);
      const sections: ApplicationCycleSection[] = queryClient.getQueryData('sections');

      // Update task completion state
      const updatedTask = { ...task, isCompleted: !task.isCompleted };
      queryClient.setQueryData(chunksKey, chunksWithUpdatedTask(currentChunks, updatedTask));

      // Update section progress
      if (sections) {
        const item = itemForTask(currentChunks, updatedTask);
        const currentSectionIndex = findIndex(sections, (section: ApplicationCycleSection) => section.name === item.category);
        sections[currentSectionIndex].progress.completedTasks += updatedTask.isCompleted ? 1 : -1;
        queryClient.setQueryData('sections', sections);
      }
    }
  });
};

export const updateOrCreateApplicationCycleDeadlineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(api.updateOrCreateApplicationCycleDeadline, {
    onSuccess: (result: any, variables, context) => {
      const plan: ApplicationCyclePlan = queryClient.getQueryData('plan');

      const updatedDeadline = result.deadline;
      const otherDeadlines = filter(plan.deadlines, (deadline) => deadline.id !== updatedDeadline.id);
      if (updatedDeadline.deletedAt) {
        plan.deadlines = otherDeadlines;
      } else {
        plan.deadlines = otherDeadlines.concat([updatedDeadline]);
      }

      plan.deadlines = plan.deadlines.sort((a, b) => new Date(a.dueAt).getTime() - new Date(b.dueAt).getTime());

      queryClient.setQueryData('plan', plan);
      queryClient.invalidateQueries('plan');
      queryClient.resetQueries(chunksKey);
    }
  });
};
