import * as React from 'react';
import trackEvent from 'utilities/track_event';

type Props = {
  children: any;
  name: string;
  properties?: any;
};

export default class Trackable extends React.Component<Props> {
  track = () => {
    trackEvent(this.props.name, this.props.properties)
  };

  render() {
    return (
      <div onClick={this.track}>
        {this.props.children}
      </div>
    )
  }
}

