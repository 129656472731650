export interface ContentAudit {
  createdAt: number;
  id: number;
  name: string;
  result: {
    audit: 'fail' | 'pass';
    reasoning: string;
  };
  status: 'waiting' | 'processing' | 'passed' | 'failed' | 'errored';
  tasks: WorkflowTask[];
}

export interface ContentFix {
  createdAt: number;
  id: number;
  status: 'waiting' | 'processing' | 'pending' | 'applied' | 'rejected' | 'errored';
  tasks: WorkflowTask[];
}

export interface CurrentVersion {
  audits: ContentAudit[];
  content: {
    answerChoiceGroups: {
      choices: {
        current: {
          isCorrect: boolean;
          text: string;
        };
        previous: {
          isCorrect: boolean;
          text: string;
        };
      }[];
    }[];
    explanation: {
      current: string;
      previous: string;
    };
    id: number;
    prompt: {
      current: string;
      previous: string;
    };
    type: string;
  };
  createdAt: number;
  createdBy: string;
  fixes: ContentFix[];
  id: number;
  isLive: boolean;
  metadata: any;
  name: string;
  periodLive: {
    from: number;
    to: number;
  };
  previousVersionId: number;
  status?: string; // Only used when viewing a proposed fix
}

export interface Version {
  auditStatus: 'fail' | 'pass';
  id: number;
  isLive: boolean;
  isProposed: boolean;
  name: string;
  previousVersionId: number;
}

export interface WorkflowTask {
  events: {
    content: any;
    createdAt: number;
    event: string;
    id: number;
  }[];
  id: number;
  identifier: string;
  result: any;
  status: 'waiting' | 'queued' | 'processing' | 'finished' | 'errored';
}[];
