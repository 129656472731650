import React, { useContext } from 'react';
import { find, omit } from 'lodash';
import { __RouterContext, matchPath } from 'react-router';
import { Link } from 'react-router-dom';

import routes from '@test_prep/app/routes';

// This component can be used for any link and will automatically use a react-router
// <Link> when supported by the SPA.

// NOTE! React Router's <Link> component will likely have functionality like this in the
// future, and when it does we should use it and remove this component.
const ContextualLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = (props) => {
  // Checking if the router context exists is a method of seeing if we are inside of the SPA.
  // https://stackoverflow.com/questions/59992359/how-can-i-check-if-react-router-is-in-context
  const router = useContext(__RouterContext);
  const { children } = props;
  
  const linkProps:any = omit(props, 'children', 'href');
  if (props.href && props.href.length !== 0) linkProps.href = props.href;

  if (!router || !linkProps.href) {
    return (
      <a {...linkProps}>{children}</a>
    );
  } else {
    const matchingPath = find(routes, (route) => matchPath(linkProps.href.split('?')[0], route));
    
    if (matchingPath) {
      return (
        <Link {...omit(linkProps, 'href')} to={linkProps.href}>{children}</Link>
      );
    } else {
      return (
        <a {...linkProps}>{children}</a>
      );
    }
  }
};

export default ContextualLink;
