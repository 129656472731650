const breakdownSections = [
  {
    name: 'Reading: Biography',
    percentCorrect: 67
  },
  {
    name: 'Reading: Hard Sciences',
    percentCorrect: 50
  },
  {
    name: 'Reading: Social Sciences',
    percentCorrect: 44
  },
  {
    name: 'Reading: Humanities',
    percentCorrect: 28
  }
];

const scoreSections = [
  {
    name: 'Listening',
    sampleScore: 27,
    scoreRange: 'Score range: 0-30'
  },
  {
    name: 'Writing',
    sampleScore: 20,
    scoreRange: 'Score range: 0-30'
  },
  {
    name: 'Reading',
    sampleScore: 23,
    scoreRange: 'Score range: 0-30'
  },
  {
    name: 'Speaking',
    sampleScore: 27,
    scoreRange: 'Score range: 0-30'
  },
  {
    name: 'Total',
    sampleScore: 97,
    scoreRange: 'Score range: 0-120'
  }
];

export default {
  null: { breakdownSections, scoreSections }
};
