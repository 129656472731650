import React from 'react';

interface Props {
  groups: {
    colors: string[];
    name: string;
  }[];
}

const Legend: React.FC<Props> = (props) => {
  return (
    <div className="d-flex flex-wrap justify-content-center" style={{ columnGap: 15, rowGap: 2 }}>
      {
        props.groups.map((group) => (
          <div key={group.name} className="align-items-center d-flex gap-xs small">
            <div className="align-items-center d-flex gap-xxs small">
              {
                group.colors.map((color, idx) => (
                  <React.Fragment key={idx}>
                    {idx !== 0 && <div className="text-gray">/</div>}
                    <div key={color} style={{ borderRadius: '50%', height: 10, width: 10, backgroundColor: color }} />
                  </React.Fragment>
                ))
              }
            </div>

            <div>
              {group.name}
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default Legend;
