import * as React from 'react';
import { Formik, Field, FieldArray, FormikHelpers as FormikActions } from 'formik';
import { capitalize } from 'lodash';

import { useConstantsContext } from '@magoosh/context/constants_context';
import { DangerButton, PrimaryButton } from '@magoosh/lib/button';
import { SimpleFieldCheckbox } from '@magoosh/lib/field_checkbox/template';
import { FieldDropdownList } from '@magoosh/lib/formik_inputs/field_dropdown_list';
import { FieldInput } from '@magoosh/lib/formik_inputs/field_input/template';
import { FieldSelect } from '@magoosh/lib/formik_inputs/field_select/template';
import { adminPaths, paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import Autofill from './autofill';
import { getPermissionsByBlockType } from '../helpers';
import { ContentBlock, EditableFieldsConfig } from '../types';

import style from './style.module.scss';

interface EditableFieldProps {
  block: ContentBlock;
  currentBlock?: {
    promptPickerStrategy: string;
  };
  config: EditableFieldsConfig;
}

// Formik doesn't play nice with null values, so here we substitute nulls for empty strings.
const initializeValues = (obj: ContentBlock) =>
  JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? '' : v)));

const EditableFields: React.FC<EditableFieldProps> = (props) => {
  const { applicationCycles, colorPalettes, icons } = useConstantsContext();

  const { config, block } = props;
  const permissions = getPermissionsByBlockType(block.type);

  const renderMoveControls = (index, length, arrayHelpers) => {
    if (length < 2) {
      return null;
    }

    const controls = [];

    if (index > 0) {
      controls.push(
        <>
          <a onClick={() => arrayHelpers.move(index, index - 1)}>Move Up</a>{' '}
        </>
      );
    }

    if (index < length - 1) {
      controls.push(<a onClick={() => arrayHelpers.move(index, index + 1)}>Move Down</a>);
    }

    return controls;
  };

  const saveBlock = (values: any, actions: FormikActions<ContentBlock>) => {
    actions.setSubmitting(true);

    updateBlock(
      adminPaths.api.showContentBlock(props.block.id),
      {
        method: 'PATCH',
        body: JSON.stringify(values)
      },
      actions
    );
  };

  const updateBlock = (path: string, config, actions: FormikActions<ContentBlock>) => {
    return fetch(path, config)
      .then((block: ContentBlock) => {
        actions.resetForm({ values: initializeValues(block) });
      })
      .catch((response) => {
        console.error(response);
        actions.setErrors(response.errors);
        actions.setSubmitting(false);
      });
  };

  //TODO: Replace prompt/question/prompt/exam section/lesson inputs with dynamic searchers
  return (
    <Formik
      initialValues={{ ...initializeValues(block) }}
      onSubmit={saveBlock}
      validateOnBlur={false}
      validateOnChange={false}>
      {(bag) => (
        <form onSubmit={bag.handleSubmit}>
          {permissions.autofill && <Autofill bag={bag} />}

          {permissions.name && <FieldInput name="name" type="text" hintHtml={block.instructions?.name} />}

          {permissions.slug && (
            <FieldInput
              name="slug"
              type="text"
              id={'slug'}
              hint="Optional. Used for direct links. Must be unique if present."
            />
          )}

          {permissions.header && (
            <React.Fragment>
              <FieldInput name="header" type="string" label="Header" />
              <small>
                "Name" appears in the left nav, while this appears at the top of the screen. The first and
                last pages of a Guided Experience are hard-coded and will ignore this value.
              </small>
            </React.Fragment>
          )}

          {permissions.frequencyFactor && (
            <FieldInput name="frequencyFactor" type="string" label="Frequency Score" />
          )}

          {permissions.easeOfLearningFactor && (
            <FieldInput name="easeOfLearningFactor" type="string" label="Ease of Learning Score" />
          )}

          {permissions.significanceFactor && (
            <FieldInput name="significanceFactor" type="string" label="Significance Score" />
          )}

          {permissions.targetAbility && (
            <FieldInput name="targetAbility" type="string" label="Target Ability Number" />
          )}

          {permissions.difficultySelector && (
            <p>
              Difficulty:{' '}
              <Field name="difficulty" component="select">
                <option></option>
                <option value="Easy">Easy</option>
                <option value="Medium">Medium</option>
                <option value="Advanced">Advanced</option>
              </Field>
            </p>
          )}

          {permissions.accessLevel && (
            <FieldSelect
              name="accessLevel"
              label="Subscription Access Level"
              objects={config.accessLevels}
              valueAccessor="level"
              keyAccessor="name"
            />
          )}

          {permissions.lessonPicker && (
            <React.Fragment>
              <FieldInput
                name="contents.lessons[0].id"
                type="string"
                errorName="lessonId"
                label="Lesson ID (just one)"
              />
              <i>{bag.values.contents.lessons[0] && bag.values.contents.lessons[0].title}</i>
              {bag.errors.lessonId && (
                // Hack in an error message here
                <div className="text-danger">{bag.errors.lessonId}</div>
              )}
            </React.Fragment>
          )}

          {permissions.embedLessons && (
            <React.Fragment>
              <SimpleFieldCheckbox
                name="embedLessons"
                label="Embed Lessons"
                checked={!!bag.values.embedLessons}
              />
              <small>
                If checked, lesson blocks will be shown as embedded video players. Otherwise, they will be
                shown as links to the lesson page.
              </small>
            </React.Fragment>
          )}

          {permissions.examSectionPicker && (
            <React.Fragment>
              <FieldInput name="contents.examSection.id" type="string" label="Exam Section ID" />
              <i>{block.contents.examSection && block.contents.examSection.displayName}</i>
            </React.Fragment>
          )}

          {permissions.subjectTags && (
            <FieldArray
              name="contents.subjectTags"
              render={(arrayHelpers) => {
                const tags = bag.values.contents.subjectTags;
                return (
                  <div className="form-group u-margin-T-s">
                    <label className="d-block">
                      Subject Tags
                    </label>

                    {
                      (tags || []).map((tag, idx) => (
                        <div className="d-flex flex-row" key={idx}>
                          <strong className="d-flex flex-column justify-content-center u-margin-R-s">
                            {tag.name || 'New tag'}
                          </strong>
                          <FieldInput
                            className="flex-1"
                            hideLabel
                            name={`contents.subjectTags[${idx}].id`}
                            type="number"
                          />
                          <DangerButton
                            className="u-margin-A-s"
                            onClick={() => arrayHelpers.remove(idx)}
                          >
                            Remove
                          </DangerButton>
                        </div>
                      ))
                    }
                    <PrimaryButton onClick={() => arrayHelpers.push('')}>Add new tag</PrimaryButton>
                  </div>
                );
              }}
            />
          )}

          {permissions.writingExamplePicker && (
            <React.Fragment>
              <FieldInput
                name={'contents.writingExamples[0].id'}
                type="string"
                label="Writing Example ID"
              />
              <i>{block.contents.writingExamples[0] && block.contents.writingExamples[0].title}</i>
            </React.Fragment>
          )}

          {permissions.experienceType && (
            <React.Fragment>
              <label>
                Subject
                <Field
                  name="experienceType"
                  label="Experience Type"
                  component="select"
                  className="form-control">
                  <option>Statement of Purpose</option>
                  <option>Personal Statement</option>
                </Field>
              </label>
            </React.Fragment>
          )}

          {permissions.defaultWritingTab && (
            <div className="u-margin-T-s">
              <label>
                Default Tab
                <Field
                  name="defaultWritingTab"
                  label="Default Tab"
                  component="select"
                  className="form-control">
                  <option value="prompt">Prompt</option>
                  <option value="brainstorm">Brainstorm</option>
                  <option value="outline">Outline</option>
                  <option value="draft">Draft</option>
                </Field>
              </label>
            </div>
          )}

          {permissions.descriptor && (
            <React.Fragment>
              <FieldInput name="descriptor" type="string" label="Descriptor" />
              <p>
                <small>e.g. "Strong Essay"</small>
              </p>
            </React.Fragment>
          )}

          {permissions.mixedPracticePicker && (
            <React.Fragment>
              <FieldInput
                name={'contents.mixedPractice.id'}
                type="string"
                errorName={'mixedPracticeId'}
                label="Mixed Practice Block ID (just one)"
              />
              {bag.errors.mixedPracticeId && (
                // Hack in an error message here
                <div className="text-danger">{bag.errors.mixedPracticeId}</div>
              )}
            </React.Fragment>
          )}

          {permissions.itemCount && <FieldInput name="itemCount" label="Item limit" type="string" />}

          {permissions.quizMode && (
            <SimpleFieldCheckbox name="quizMode" label="Quiz Mode" checked={!!bag.values.quizMode} />
          )}

          {permissions.includeOldPrompts && (
            <SimpleFieldCheckbox
              name="includeOldPrompts"
              label="Include old prompts"
              checked={!!bag.values.includeOldPrompts}
            />
          )}

          {permissions.timeLimit && (
            <FieldInput name="timeLimit" type="string" label="Time Limit (seconds)" hint="Enforce a time limit for the session" />
          )}

          {permissions.estimatedTimeMinutes && (
            <FieldInput name="estimatedTimeMinutes" type="string" label="Estimated Time (minutes)" hint="For displaying a guess at how long the session might take in the UI" />
          )}

          {permissions.promptPickerStrategy && (
            <React.Fragment>
              <FieldSelect
                name="promptPickerStrategy"
                label="Prompt picking strategy"
                options={{
                  'Default (exam section + subject tags)': 'PromptPickerStrategy::PracticeSession',
                  'Predefined set of prompts': 'PromptPickerStrategy::Predefined',
                  'Predefined set of questions': 'PromptPickerStrategy::PredefinedQuestions',
                  'Custom prompt pool': 'PromptPickerStrategy::CustomPool'
                }}
              />

              {(bag.values.promptPickerStrategy === 'PromptPickerStrategy::Predefined' ||
                bag.values.promptPickerStrategy === 'PromptPickerStrategy::CustomPool') && (
                <FieldArray
                  name="contents.prompts"
                  render={(arrayHelpers) => {
                    const prompts = bag.values.contents.prompts;
                    return (
                      <div>
                        <i>Prompts for this practice session ({prompts.length}):</i>
                        <p>
                          <small>These may appear out of order.</small>
                        </p>
                        {prompts.map((prompt, index) => {
                          return (
                            <div key={index}>
                              <div className={style.textInput}>
                                <FieldInput
                                  label="Prompt ID"
                                  name={`contents.prompts[${index}].id`}
                                  type="string"
                                  id={`prompt-id-${index}`}
                                />
                              </div>
                              {renderMoveControls(index, prompts.length, arrayHelpers)}
                              <br />
                              <a href={`/admin/prompts/${prompt.id}/edit`}>
                                <i>{prompt.title}</i>
                              </a>{' '}
                              <a onClick={() => arrayHelpers.remove(index)}>(Remove)</a>
                            </div>
                          );
                        })}
                        <br />
                        <PrimaryButton onClick={() => arrayHelpers.push({ id: '' })}>
                          Add Prompt
                        </PrimaryButton>
                      </div>
                    );
                  }}
                />
              )}

              {bag.values.promptPickerStrategy === 'PromptPickerStrategy::PredefinedQuestions' && (
                <FieldArray
                  name="contents.questions"
                  render={(arrayHelpers) => {
                    const questions = bag.values.contents.questions;
                    return (
                      <div>
                        <i>Questions for this practice session ({questions.length}):</i>
                        <p>
                          <small>These may appear out of order.</small>
                        </p>
                        {questions.map((question, index) => {
                          return (
                            <div key={index}>
                              <FieldInput
                                label="Question ID"
                                name={`contents.questions[${index}].id`}
                                type="string"
                                id={`question-id-${index}`}
                              />
                              <a href={`/admin/questions/${question.id}/edit`}>
                                <i>{question.title}</i>
                              </a>{' '}
                              <a onClick={() => arrayHelpers.remove(index)}>(Remove)</a>
                            </div>
                          );
                        })}
                        <br />
                        <PrimaryButton onClick={() => arrayHelpers.push({ id: '' })}>
                          Add Question
                        </PrimaryButton>
                      </div>
                    );
                  }}
                />
              )}
            </React.Fragment>
          )}

          {permissions.payload && (
            <React.Fragment>
              <FieldInput name="payload" type="textarea" />
              <p>
                Enter a valid JSON payload. This must be valid JSON; if you're having trouble with the
                syntax, try{' '}
                <a href="https://jsonlint.com" target="_blank">
                  JSONLint
                </a>{' '}
                to find your syntax errors.
              </p>
            </React.Fragment>
          )}

          {permissions.experienceLevels && (
            <FieldInput
              name="experienceLevels"
              type="string"
              label="Experience Levels"
              hint="Comma-separated. For example, `beginner1,beginner2`, or `advanced1`."
            />
          )}

          {permissions.minDaysUntilTest && (
            <FieldInput name="minDaysUntilTest" type="string" label="Min Days Until Test" />
          )}

          {permissions.numMonths && <FieldInput name="numMonths" type="string" label="Number of Months" />}

          {permissions.learningSessionSetName && (
            <FieldInput
              name="learningSessionSetName"
              type="text"
              label='Name of a set of learning sessions (default "week")'
            />
          )}

          {permissions.learningSessionsPerSet && (
            <FieldInput
              name="learningSessionsPerSet"
              type="string"
              label="Number of learning sessions in a set (default 7)"
            />
          )}

          {permissions.learningSessionName && (
            <FieldInput
              name="learningSessionName"
              type="string"
              label='Name of a learning session (default "day")'
            />
          )}

          {permissions.optional && (
            <FieldSelect
              name="isOptional"
              options={{ Yes: true, No: false }}
              label="Optional?"
              placeholder={bag.values.optional ? 'Yes' : 'No'}
            />
          )}

          {permissions.url && <FieldInput name="url" type="text" label="URL" />}

          {permissions.displayName && <FieldInput name="displayName" type="text" label="Display Name" />}

          {permissions.minimumEngagementHours && (
            <FieldInput name="minimumEngagementHours" type="string" label="Minimum Engagement Hours" />
          )}

          {permissions.maximumEngagementHours && (
            <FieldInput name="maximumEngagementHours" type="string" label="Maximum Engagement Hours" />
          )}

          {permissions.lessonSeriesSlug && (
            <div>
              <FieldInput name="contents.lessonSeries.slug" type="text" label="Lesson Series slug" />
              {bag.values.contents.lessonSeries && (
                <a target="_blank" href={paths.lessonSeries(bag.values.contents.lessonSeries.slug)}>
                  {bag.values.contents.lessonSeries.name}
                </a>
              )}
            </div>
          )}

          {permissions.questions && (
            <FieldArray
              name="contents.questions"
              render={(arrayHelpers) => {
                const questions = bag.values.contents.questions;
                return (
                  <div>
                    <i>Questions for section:</i>
                    {questions.map((question, index) => {
                      return (
                        <div key={index}>
                          <FieldInput
                            label="Question ID"
                            name={`contents.questions[${index}].id`}
                            type="string"
                            id={`question-id-${index}`}
                          />
                          <a href={`/admin/questions/${question.id}/edit`}>
                            <i>{question.title}</i>
                          </a>{' '}
                          <a onClick={() => arrayHelpers.remove(index)}>(Remove)</a>
                        </div>
                      );
                    })}
                    <br />
                    <PrimaryButton onClick={() => arrayHelpers.push({ id: '' })}>
                      Add Question
                    </PrimaryButton>
                  </div>
                );
              }}
            />
          )}

          {permissions.description && <FieldInput name="description" type="textarea" label="Description" />}

          {permissions.blurb && <FieldInput name="blurb" type="textarea" label="Blurb" />}

          {permissions.lessons && (
            <FieldArray
              name="contents.lessons"
              render={(arrayHelpers) => {
                const lessons = bag.values.contents.lessons;
                console.log(lessons);
                return (
                  <div>
                    <i>Lessons:</i>
                    {lessons.map((lesson, index) => {
                      return (
                        <div key={`lesson${index}`}>
                          <div className={style.textInput}>
                            <FieldInput
                              label={'Lesson ID'}
                              name={`contents.lessons[${index}].id`}
                              type={'number'}
                              id={`question-id-${index}`}
                            />
                          </div>
                          {renderMoveControls(index, lessons.length, arrayHelpers)}
                          <br />
                          <a href={adminPaths.lesson(lesson.id)}>
                            <i>{lesson.title}</i>
                          </a>{' '}
                          <a onClick={() => arrayHelpers.remove(index)}>Remove</a>
                        </div>
                      );
                    })}
                    <br />
                    <PrimaryButton onClick={() => arrayHelpers.push({ id: '' })}>Add Lesson</PrimaryButton>
                  </div>
                );
              }}
            />
          )}

          {permissions.richText && (
            <FieldInput name="richText" type="textarea" label="Rich Text (HTML markup)" hintHtml={block.instructions?.richText} />
          )}

          {permissions.examSectionFocus && (
            <FieldSelect
              name="examSectionFocus"
              label="Exam Section Focus"
              options={{
                '-': '',
                'Math and Verbal': 'math_and_verbal',
                Math: 'math',
                Verbal: 'verbal'
              }}
            />
          )}

          {permissions.examVersion && (
            <FieldSelect
              name="examVersion"
              label="Exam Version"
              options={{
                'Default': '',
                '2024-2025 LSAT': '24_25_lsat',
                'GMAT Focus': 'focus',
                'Digital SAT': 'digital'
              }}
            />
          )}

          {permissions.applicationCyclePicker && (
            <FieldSelect
              name="applicationCycle"
              label="Application Cycle"
              options={Object.assign({ '-': '' }, ...applicationCycles.map((cycle) => ({ [capitalize(cycle)]: cycle })))}
            />
          )}

          {permissions.colorPalettePicker && (
            <FieldSelect
              name="uiColorPalette"
              label="Color Palette"
              options={Object.assign({ '-': '' }, ...Object.keys(colorPalettes).map((key) => ({ [capitalize(key)]: key })))}
            />
          )}

          {permissions.iconPicker && (
            <FieldSelect
              name="uiIcon"
              label="Icon"
              options={Object.assign({ '-': '' }, ...icons.map((icon) => ({ [capitalize(icon)]: icon })))}
            />
          )}
          
          {permissions.difficultyRange && (
            <React.Fragment>
              <FieldSelect
                className="dificultySelector"
                name="difficultyMinimum"
                label="Difficulty Min"
                options={{
                  Adaptive: '',
                  Easy: '-99.0',
                  Medium: '-1.0',
                  Hard: '0.0',
                  'Very Hard': '1.0',
                }}
              />
              <FieldSelect
                className="dificultySelector"
                name="difficultyMaximum"
                label="Difficulty Max"
                options={{
                  Adaptive: '',
                  Easy: '-1.0',
                  Medium: '0.0',
                  Hard: '1.0',
                  'Very Hard': '99.0',
                }}
              />
            </React.Fragment>
          )}

          {permissions.segmentTypePicker && (
            <FieldSelect
              name="segmentType"
              label="Segment Type"
              options={{
                '-': '',
                'Global': 'global',
                'Program': 'program'
              }}
            />
          )}

          {permissions.programPicker && bag.values.segmentType === 'program' && (
            <FieldDropdownList
              name="programId"
              label="Program"
              defaultValue={bag.values.programId}
              searchEndpoint={paths.api.admissions.programsSearch}
              filter="contains"
              valueField="id"
              textField="name"
            />
          )}

          {permissions.deadlineBasisPicker && (
            <>
              <FieldInput name="deadlineIntervalCount" type="input" label="Interval Count" />

              <FieldSelect
                name="deadlineIntervalType"
                label="Interval Type"
                options={{
                  '-': '',
                  'Days': 'day',
                  'Weeks': 'week',
                  'Months': 'month'
                }}
              />

              <FieldSelect
                name="deadlineIntervalDirection"
                label="Interval Direction"
                options={{
                  '-': '',
                  'Before': 'before',
                  'After': 'after'
                }}
              />

              <FieldSelect
                name="deadlineBasis"
                label="Deadine Basis"
                options={{
                  '-': '',
                  'First Deadline': 'first_deadline',
                  'Program Deadline': 'program_deadline'
                }}
              />


            </>
          )}

          {bag.dirty && (
            <div>
              <button type="submit" className="btn btn-primary" disabled={bag.isSubmitting}>
                {bag.isSubmitting ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default EditableFields;
