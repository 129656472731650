import React from 'react';
import cx from 'classnames';
import { Element as ReactScrollTarget} from 'react-scroll';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import ContentfulEditorialArticleSet from '../editorial_article_set';
import ContentfulEditorialChart from '../editorial_chart';
import ContentfulEditorialStatSet from '../editorial_stat_set';
import ContentfulEditorialText from '../editorial_text';
import ContentfulEditorialVideo from '../editorial_video';
import {
  EditorialArticleSet,
  EditorialChart,
  EditorialSection,
  EditorialStatSet,
  EditorialText,
  EditorialVideo
} from '../types';

import style from './style.module.scss';

const ContentfulEditorialSection: React.FC<EditorialSection & { className?: string; }> = (props) => {
  const renderContents = () => {
    return props.contents.map((content, idx) => {
      const className = cx(
        {
          [style.contentColumn]: props.layout === 'columns',
          [style.contentRow]: props.layout === 'rows'
        }
      );

      if (content.type === 'editorialArticleSet') {
         return (
           <ContentfulEditorialArticleSet
             key={idx}
             className={className}
             {...content as EditorialArticleSet}
           />
         );
      } else if (content.type === 'editorialChart') {
        return (
          <ContentfulEditorialChart
            key={idx}
            className={className}
            {...content as EditorialChart}
          />
        );
      } else if (content.type === 'editorialSection') {
        return (
          <ContentfulEditorialSection
            key={idx}
            className={className}
            {...content as EditorialSection}
          />
        );
      } else if (content.type === 'editorialStatSet') {
        return (
          <ContentfulEditorialStatSet
            key={idx}
            className={className}
            {...content as EditorialStatSet}
          />
        );
      } else if (content.type === 'editorialText') {
        return (
          <ContentfulEditorialText
            key={idx}
            className={className}
            {...content as EditorialText}
          />
        );
      } else if (content.type === 'editorialVideo') {
        return (
          <ContentfulEditorialVideo
            key={idx}
            className={className}
            {...content as EditorialVideo}
          />
        );
      }
    })
  };

  return (
    <ReactScrollTarget name={props.slug}>
      <div className={props.className}>
        {
          props.name && (
            <h3 className="u-margin-A-n">
              {props.icon && <FontAwesomeIcon className="text-secondary u-margin-R-s" icon={props.icon} />}
              {props.name}
            </h3>
          )
        }

        <div
          className={cx(
            'd-flex u-margin-T-m',
            {
              [style.sectionColumnLayout]: props.layout === 'columns',
              [style.sectionRowLayout]: props.layout === 'rows'
            }
          )}
        >
          {props.contents && renderContents()}
        </div>
      </div>
    </ReactScrollTarget>
  );
};

export default ContentfulEditorialSection;
