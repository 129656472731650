export enum StudyPreferencesActionTypes {
  TOGGLE_STUDY_DAY = 'STUDY_PREFERENCES/TOGGLE_STUDY_DAY',
  CHANGE_STUDY_LENGTH_HOURS = 'STUDY_PREFERENCES/CHANGE_STUDY_LENGTH_HOURS',
  DID_SAVE = 'STUDY_PREFERENCES/DID_SAVE',
  SET_INITIAL_PREFERENCES = 'STUDY_PREFERENCES/SET_INITIAL',
  TOGGLE_MODAL = 'STUDY_PREFERENCES/TOGGLE_MODAL'
}

export function toggleStudyDay(day: string, value: boolean) {
  return {
    type: StudyPreferencesActionTypes.TOGGLE_STUDY_DAY,
    payload: {
      day,
      value
    }
  };
}

export function changeStudyLengthHours(hours: number | string) {
  return {
    type: StudyPreferencesActionTypes.CHANGE_STUDY_LENGTH_HOURS,
    payload: hours
  };
}

export function studyPreferenceDidSave() {
  return {
    type: StudyPreferencesActionTypes.DID_SAVE
  };
}

export function setInitialStudyPreferences(prefs: any) {
  return {
    type: StudyPreferencesActionTypes.SET_INITIAL_PREFERENCES,
    payload: prefs
  };
}

export function toggleStudyPreferencesModal(startStep = 1) {
  return {
    type: StudyPreferencesActionTypes.TOGGLE_MODAL,
    payload: {
      startStep
    }
  };
}
