import * as React from 'react';
import { OverlayTrigger, Tooltip as ReactToolTip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@magoosh/lib/icons';
import ImageWithLogoOverlay from '@magoosh/lib/image_with_logo_overlay';
import { paths } from 'config/path_helpers';

import { ApplicantProfileCardProps } from '../types';

import style from './style.module.scss';

const ApplicantProfileCard: React.FC<ApplicantProfileCardProps> = (props) => {
  return (
    <a href={paths.applicantProfile(props.id)} className={style.profileCard}>
      <img src={props.profilePictureUrl} alt="Applicant profile picture" />
      <div>
        <h4>
          {props.name}
          {props.hasVideo && (
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement={'top'}
              overlay={
                <ReactToolTip id={`story-tooltip-${props.id}`}>Includes Applicant Story</ReactToolTip>
              }>
              <span>
                <FontAwesomeIcon className={style.attachmentIcon} icon={'video-camera'} />
              </span>
            </OverlayTrigger>
          )}
          {props.hasCommentary && (
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement={'top'}
              overlay={
                <ReactToolTip id={`commentary-tooltip-${props.id}`}>Includes Magoosh Commentary</ReactToolTip>
              }>
              <span>
                <FontAwesomeIcon className={style.attachmentIcon} icon={'comment'} />
              </span>
            </OverlayTrigger>
          )}
        </h4>
        <span className={style.programName}>
          {props.degreeType}
          {props.degree && ` in ${props.degree}`}
          {props.enrolledProgram ? ` at ${props.enrolledProgram}` : ''}
        </span>
        <small className="text-gray-light">Enrolled {props.graduateYearEnrolled}</small>
        <div>
          {props.tags.map((tag) => (
            <span className="profile-badge" key={tag}>
              {tag}
            </span>
          ))}
        </div>
      </div>

      {
        props.enrolledSchoolProfile &&  (
          <div className="hidden-xs" style={{ marginLeft: 'auto' }}>
            <ImageWithLogoOverlay
              image={{
                title: props.enrolledSchoolProfile.coverPhotos[0].title,
                url: `${props.enrolledSchoolProfile.coverPhotos[0].url}?fit=fill&h=118&w=177`
              }}
              logo={{
                title: `${props.enrolledSchoolProfile.name} Logo`,
                url: `${props.enrolledSchoolProfile.logo}?fit=fill&h=35&w=35`
              }}
            />
          </div>
        )
      }
    </a>
  );
};

export default ApplicantProfileCard;
