import React from 'react';
import useSearchQuery from '@magoosh/utils/use_search_query';

import Preview from './preview';
import Page from './editorial_page';

interface Props {
  editorialPage: any;
  offsetY?: number;
  stickyCTAOnMobile?: boolean;
}

const EditorialPage: React.FC<Props> = (props) => {
  const preview = useSearchQuery()['preview'] === 'true';

  if (preview) {
    return <Preview {...props} />;
  } else {
    return <Page {...props} />
  }
};

export default EditorialPage;
