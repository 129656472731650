import React from 'react';

const IELTSGTDescriptionContent: React.FC = (props) => {
  return (
    <>
      <p>This practice test simulates the experience you will have taking the General Training IELTS on test day.</p>
      <p>When you finish the test, we will give you <strong>score estimates</strong> for reading and listening which will help you predict how well you would do on the actual test.</p>
      <p>You'll also be able to <strong>review your answers</strong> and see the video explanations.</p>
      <p>Note that the format of this practice test is slightly different from the format you'll see on the actual exam.</p>
    </>
  );
};

export default IELTSGTDescriptionContent;
