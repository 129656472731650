import * as React from 'react';
import style from '../style.module.scss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { adminPaths, paths } from 'config/path_helpers';
import cx from 'classnames';

interface ProfileDropdownProps {
  showAdminLink: boolean;
}

export default class ProfileDropdown extends React.Component<ProfileDropdownProps> {
  render() {
    return (
      <div className={style.profileDropdown}>
        <OverlayTrigger overlay={this.renderProfilePopover()} placement="bottom" rootClose trigger="click">
          <div>
            <span className={cx(style.profileDropdownIcon, 'border-radius-all')}>
              <span className="fa fa-user" />
            </span>
            <span className={cx(style.profileCaret, 'fa fa-caret-down')} />
          </div>
        </OverlayTrigger>
      </div>
    );
  }

  protected renderProfilePopover() {
    return (
      <Popover id="profile-popover">
        <div className={style.profileLinkWrapper}>
          <div>
            <a className={style.profileLink} href={paths.profile()}>
              Profile
            </a>
          </div>
          {this.renderAdminLink()}
          <div>
            <a className={style.profileLink} href={paths.logout()}>
              Log Out
            </a>
          </div>
        </div>
      </Popover>
    );
  }

  protected renderAdminLink() {
    if (this.props.showAdminLink) {
      return (
        <div>
          <a className={style.profileLink} href={adminPaths.dashboard()}>
            Admin
          </a>
        </div>
      );
    }

    return null;
  }
}
