import * as React from 'react';
import { PrimaryButton } from '@magoosh/lib/button';
import style from './style.module.scss';
import cx from 'classnames';
import {paths} from "config/path_helpers";

const LSATPracticeSection: React.FC = () => (
  <>
    <div className={style.practiceOptions}>
      <div className={cx(style.header, style.customPracticeHeader)}>
        Practice Anything
      </div>
      <div className={cx(style.instructions, style.customPracticeInstructions)}>
        You choose the type of question. Perfect for targeted practice or a short daily session.
      </div>
      <div className={cx(style.button, style.customPracticeButton)}>
        <PrimaryButton href={paths.startPractice()}>
          Start custom practice
        </PrimaryButton>
      </div>
      <div className={cx(style.header, style.practiceSectionHeader)}>
        Practice Section
      </div>
      <div className={cx(style.instructions, style.practiceSectionInstructions)}>
        Complete an official LSAT section. Perfect for practicing pacing and seeing improvement.
      </div>
      <div className={cx(style.button, style.practiceSectionButton)}>
        <PrimaryButton href={`${paths.startPracticeTest()}?interaction=section-test`}>
          Start a practice section
        </PrimaryButton>
      </div>
      <div className={cx(style.header, style.practiceTestHeader)}>
        Practice Test
      </div>
      <div className={cx(style.instructions, style.practiceTestInstructions)}>
        Complete an official LSAT test. Perfect for developing stamina and putting it all together.
      </div>
      <div className={cx(style.button, style.practiceTestButton)}>
        <PrimaryButton href={paths.startPracticeTest()}>
          Start a practice test
        </PrimaryButton>
      </div>
    </div>
  </>
);

export default LSATPracticeSection;
