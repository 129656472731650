import React from 'react';

import { paths } from 'config/path_helpers';

interface Props {
  mockTestId: number;
  userName: string
}

const ViewingSharedResultsBanner: React.FC<Props> = (props) => {
  return (
    <div className="bg-info d-flex justify-content-center u-padding-A-xs">
      <div className="text-white text-center">
        You are viewing shared practice test results{props.userName ? ` for ${props.userName}`: ''}.
        {' '}
        <a className="text-underline text-white" href={paths.practiceTest(props.mockTestId)}>
          Click here to stop viewing results.
        </a>
      </div>
    </div>
  );
};

export default ViewingSharedResultsBanner;
