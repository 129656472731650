import { useState, useEffect } from 'react';

/**
 * Usage:
 *
 * const token = useAuthToken()
 * return <div>
 *          ...
 *          <input value={token} />
 *          ...
 *        </div>
 */
const useAuthenticityToken = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    setToken(
      document.querySelector("meta[name='csrf-token']") &&
        document.querySelector("meta[name='csrf-token']").getAttribute('content')
    );
  }, []);

  return token;
};

export default useAuthenticityToken;
