import React from 'react';
import { FormikErrors } from 'formik';
import style from './style.module.scss';

export const ErrorMessages: React.FC<{ errors: FormikErrors<any>}> = (props) => {
  return <div className={style.errorMessage}>
    {Object.values(props.errors).map((error, index) => {
      if (Array.isArray(error)) {
        error = Object.values(error).join('. ')
      }

      return <div key={index}>{error}</div>
    })}
  </div>
}
