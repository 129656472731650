import * as React from 'react';
import { Provider } from 'react-redux';
import configureStore from '@magoosh/store';
import AssignmentContainer from './container';
import { setDetailsAssignment } from '../actions';
import { AssignmentDetailProps } from './template';

export default class extends React.Component<AssignmentDetailProps> {
  store = configureStore();

  constructor(props) {
    super(props);
    this.store.dispatch(setDetailsAssignment(props.assignment));
  }

  render() {
    return (
      <Provider store={this.store}>
        <AssignmentContainer />
      </Provider>
    );
  }
}
