import React from 'react';
import cx from 'classnames';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  className?: string;
}

const ContentCard: React.FC<Props> = (props) => {
  return (
    <div
      className={cx('u-padding-H-xs u-padding-V-xxs', props.className)}
      style={{ backgroundColor: colors.purpleLight, color: colors.purple }}
    >
      This is placeholder content card content.{' '}
      <a
        href="#"
        style={{ color: colors.purple }}
      >
        <strong>CTA Link</strong>
      </a>
    </div>
  );
};

export default ContentCard;
