import { connect } from "react-redux";
import StudyWeekView from "./template";
import { RootStore } from "@magoosh/store";

function mapStateToProps(state: RootStore) {

  return {
    scheduled: state.studyPreferences.studyPreferencesSaved.days,
    completed: state.studyPreferences.studyActivityThisWeek
  }
}

export default connect(mapStateToProps)(StudyWeekView);
