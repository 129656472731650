import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { DefaultButton, InfoButton } from '@magoosh/lib/button';

import * as api from '@admin/app/api';

import CollapsibleTaskLogs from '../collapsible_task_logs';
import StatusBadge from '../status_badge';
import { ContentFix } from '../../types';

interface Props {
  fix: ContentFix;
  onReview: () => void;
}

const ContentFix: React.FC<Props> = (props) => {
  const poller = useRef(null);
  const [fix, setFix] = useState(props.fix);

  useEffect(() => {
    if (isDone(props.fix) || isErrored(props.fix)) return;

    poller.current = setTimeout(pollForUpdates, 1000);

    return () => {
      clearTimeout(poller.current);
    }
  }, []);

  const isDone = (contentFix) => {
    return ['pending', 'accepted', 'rejected'].indexOf(contentFix.status) !== -1;
  };

  const isErrored = (contentFix) => {
    return contentFix.status === 'errored';
  };

  const pollForUpdates = () => {
    api.fetchTestPrepAIContentFix(fix.id).then((contentFix) => {
      setFix(contentFix);
      if (isDone(contentFix) || isErrored(contentFix)) return;

      poller.current = setTimeout(pollForUpdates, 2000);
    });
  };

  return (
    <div className="d-flex flex-column gap-s">
      <div className="align-items-center d-flex gap-xs">
        <StatusBadge status={fix.status} />

        <h4 className="u-margin-A-n">
          <strong>
            {moment(fix.createdAt * 1000).format('MMMM D, YYYY')}
          </strong>
        </h4>

        {
          isDone(fix) && (
            <div className="d-flex flex-1 justify-content-end">
              {
                fix.status === 'pending' ? (
                  <InfoButton onClick={props.onReview}>
                   Review
                  </InfoButton>
                ) : (
                  <DefaultButton onClick={props.onReview}>
                   View
                  </DefaultButton>
                )
              }
            </div>
          )
        }
      </div>

      <hr className="u-margin-A-n" />

      <div>
        {/*fix.result?.reasoning*/}
      </div>

      <CollapsibleTaskLogs tasks={fix.tasks} />
    </div>
  );
};

export default ContentFix;
