import React from 'react';
import { find } from 'lodash';

import { CircleIcon, FontAwesomeIcon } from '@magoosh/lib/icons';
import ProgressBar from '@magoosh/lib/progress_bar';
import { ColorPalette } from 'types/constants';
import { pluralize } from 'utilities/pluralize';

import AdditionalActionsButton from '@admissions/components/additional_actions_button';
import { ApplicationCycleSection } from '@admissions/types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  onSectionFocus: (sectionName: string) => void;
  onSegmentFocus: (segmentName: string) => void;
  sections: ApplicationCycleSection[];
}

const DEFAULT_COLOR_PALETTE = { dark: colors.gray, light: colors.grayLighter } as ColorPalette;

const SectionsOverview: React.FC<Props> = (props) => {
  return (
    <>
      {
        props.sections.map((section, idx) => {
          const colorPalette = section.colorPalette || DEFAULT_COLOR_PALETTE;
          const percentTasksCompleted = section.progress.tasks ? (section.progress.completedTasks / section.progress.tasks) * 100 : 0;

          return (
            <React.Fragment key={section.id}>
              <Section
                onSectionFocus={props.onSectionFocus}
                onSegmentFocus={props.onSegmentFocus}
                {...section}
              />

              <div className="u-margin-T-s">
                <small>
                  Progress: {section.progress.completedTasks}/{pluralize(section.progress.tasks, 'task')} complete
                </small>

                <ProgressBar
                  backgroundColor={colors.grayLighter}
                  color={colorPalette.dark}
                  percentage={percentTasksCompleted === 0 ? 5 : percentTasksCompleted}
                  size="sm"
                />

              </div>

              {idx !== props.sections.length - 1 && <hr />}
            </React.Fragment>
          )
        })
      }
    </>
  );
};

interface SectionProps extends ApplicationCycleSection {
  onSectionFocus: (sectionName: string) => void;
  onSegmentFocus: (segmentName: string) => void;
}

const Section: React.FC<SectionProps> = (props) => {
  const currentSegment = find(props.segments, (segment) => !segment.isCompleted);

  const availableActions = [
    {
      onClick: () => props.onSectionFocus(props.name),
      title: 'View Tasks In This Section'
    }
  ];

  return (
    <div>
      <div className="align-items-center d-flex flex-row u-margin-B-s">
        <div style={{ flex: 1, fontSize: 20 }}>
          {props.name}
        </div>

        <div className="visible-sm visible-xs">
          <AdditionalActionsButton actions={availableActions} />
        </div>
      </div>

      {
        props.segments.map((segment, idx) => {
          let colorPalette = DEFAULT_COLOR_PALETTE;
          if (props.colorPalette && (segment.isCompleted || segment === currentSegment)) {
            colorPalette = props.colorPalette;
          }

          return (
            <div
              key={segment.id}
              className="d-flex flex-row cursor"
              onClick={() => props.onSegmentFocus(segment.name)}
              style={{ cursor: 'pointer', gap: 10 }}
            >
              <div className="align-items-center d-flex flex-column">
                <div>
                  <CircleIcon
                    bordered={segment.isCompleted || segment === currentSegment}
                    colorPalette={colorPalette}
                    size={35}
                  >
                    {
                      segment.isCompleted ? (
                        <FontAwesomeIcon icon="check" style={{ WebkitTextStroke: `1px ${colorPalette.light}` }} />
                      ) : idx + 1
                    }
                  </CircleIcon>
                </div>

                {
                  idx !== props.segments.length - 1 && (
                    <div
                      className="bg-gray-lighter u-margin-V-xxs"
                      style={{ flex: 1, minHeight: 10, width: 2 }}
                    />
                  )
                }
              </div>

              <div className={style.underlineOnHover} style={{ marginTop: 5 }}>{segment.name}</div>
            </div>
          )
        })
      }
    </div>
  );
};

export default SectionsOverview;
