import React, { useState } from 'react';

import { ModalProvider, useModalContext } from '@magoosh/context/modal_context';
import { DefaultFilledButton, SecondaryButton } from '@magoosh/lib/button';
import { CircleIcon, FontAwesomeIcon } from '@magoosh/lib/icons';
import PracticeSessionPreviewModal from '@magoosh/organisms/study_schedule/practice_session_preview_modal/template';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import { Activity } from '../types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  activity: Activity;
  detailed?: boolean;
  next?: boolean;
}

const ActivityCard: React.FC<Props> = (props) => {
  const { setModal } = useModalContext();
  const [isStarting, setIsStarting] = useState(false);

  const icon = () => {
    if (props.activity.status === 'finished') {
      return (
        <CircleIcon
          bordered={false}
          colorPalette={{ dark: colors.green, light: colors.greenLight }}
          size={30}
          style={{ WebkitTextStroke: `1px ${colors.greenLight}` }}
        >
          <FontAwesomeIcon icon="check" />
        </CircleIcon>
      );
    }

    if (isDrill()) {
      return (
        <CircleIcon colorPalette={{ dark: '#F6A351', light: '#FFD9B4' }} size={25}>
          <FontAwesomeIcon icon="bolt" />
        </CircleIcon>
      );
    }
  };

  const isDrill = () => {
    return props.activity.type === 'practice' && props.activity.content.type === 'drills';
  };

  const presentPracticePreviewModal = (contentBlockId) => {
    setIsStarting(true);

    fetch(paths.api.studySchedulePractice(contentBlockId), {
      method: 'POST'
    }).then((response: any) => {
      if (response.redirect) {
        window.location.assign(response.redirect);
        return;
      }

      setModal({
        body: (
          <PracticeSessionPreviewModal
            onHide={() => {
              setIsStarting(false);
              setModal(null);
            }}
            {...response.preview}
          />
        )
      });
    });
  };

  const start = () => {
    if (props.activity.type === 'practice') {
      presentPracticePreviewModal(props.activity.content.contentBlockId);
    }
  };

  return (
    <div className="bg-white border-radius-all d-flex flex-column gap-m u-padding-A-s" style={{ border: '1px dashed #B5B1BB' }}>
      {(() => {
        if (props.detailed && isDrill()) {
          return (
            <div className="hidden-sm hidden-xs d-flex flex-column gap-s">
              <h3 className="u-margin-A-n"><strong>Check Your Understanding!</strong></h3>
              <p>Answer these quick questions to make sure you're fluent with the concepts from the lesson video.</p>
              <hr className="u-margin-A-n" />
            </div>
          );
        }
      })()}

      <div className="align-items-center d-flex gap-m">
        <div>
          <div className="align-items-center d-flex gap-s">
            <div>{icon()}</div>
            <div><strong>{props.activity.name}</strong></div>
          </div>

          {
            props.activity.description && (
              <div>
                <div className="text-gray-light"><small>{props.activity.description}</small></div>
              </div>
            )
          }
        </div>
      </div>

      {(() => {
        if (props.next) {
          return (
            <SecondaryButton onClick={start} submitting={isStarting}>Start</SecondaryButton>
          );
        } else if (props.activity.status === 'finished') {
          const reviewPath = paths.answers({
            'review[practice_session_id]': props.activity.content.practiceSessionId,
            'review[study_item]': props.activity.content.contentBlockId
          });

          return (
            <div className="align-items-center d-flex gap-xs">
              <DefaultFilledButton className="flex-1" href={reviewPath}>Review</DefaultFilledButton>
              <DefaultFilledButton onClick={start}>Retake</DefaultFilledButton>
            </div>
            );
        } else {
          return (
            <DefaultFilledButton onClick={start} submitting={isStarting}>Start</DefaultFilledButton>
          );
        }
      })()}
    </div>
  );
};

const WrappedActivityCard: React.FC<Props> = (props) => {
  return (
    <ModalProvider>
      <ActivityCard {...props}/>
    </ModalProvider>
  )
};

export default WrappedActivityCard;
