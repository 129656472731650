import React, { useState } from 'react';
import cx from 'classnames';
import { find } from 'lodash';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import { ModalProvider, useModalContext } from '@magoosh/context/modal_context';
import CardLayout from '@magoosh/layouts/card';
import { DefaultFilledButton, SecondaryButton, TabButton } from '@magoosh/lib/button';
import { Select } from '@magoosh/lib/forms';
import {
  FontAwesomeIcon,
  LightBulbPlantIcon,
  MagnifyingGlassIcon,
  WateringSeedlingIcon
} from '@magoosh/lib/icons';
import StatusAlert from '@magoosh/organisms/grading_request_status_alert';
import ScoreCard from '@magoosh/organisms/score_card';
import useSearchQuery from '@magoosh/utils/use_search_query';
import { paths } from 'config/path_helpers';

import { useAppContext } from '@test_prep/app/context';
import ContextualLink from '@test_prep/app/components/contextual_link';

import AnswersTable from './answers_table';
import PerformanceBreakdown from './performance_breakdown';
import { MockTest, ScoreReport } from './types';
import ViewingSharedResultsBanner from './viewing_shared_results_banner';
import UpsellCard from '../components/upsell_card';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  mockTest: MockTest;
  scoreReport: ScoreReport;
  ui?: {
    saleMessage: string;
    showBasicsContentNextStep?: boolean;
  }
}

const MockTestResults: React.FC<Props> = (props) => {
  const { branding, features } = useAppContext().data;
  const history = useHistory();
  const { id, shareableId } = useParams();
  const { setModal } = useModalContext();
  const examSectionSlug = useSearchQuery()['exam_section'] || null;

  const showUpsell = branding.accessLevel !== 'premium';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  const defaultExamSection = () => {
    const examSectionFromQueryString = props.mockTest.examSectionDetails.find((examSection) => (
      examSection.slug === examSectionSlug
    ));

    return examSectionFromQueryString || props.mockTest.examSectionDetails[0];
  };

  const handleCurrentExamSectionChanged = (slug) => {
    const newExamSection = find(props.mockTest.examSectionDetails, (examSection) => (
      examSection.slug === slug
    ));

    if (!shareableId) {
      window.history.replaceState(null, null, `${paths.practiceTest(props.mockTest.id)}?exam_section=${newExamSection.slug}`);
    }

    setCurrentExamSection(newExamSection);
  };

  const shareResultModal = () => {
    const shareTestUrl = `${window.location.origin}${paths.practiceTestsShare(props.mockTest.shareableId)}`;

    const copyShareLink = async (className: string) => {
      const element = document.querySelector(`.${className}`) as HTMLElement | null;
      const textToCopy = element.textContent;
      await navigator.clipboard.writeText(textToCopy);
      element.innerText = 'Copied!';
      setTimeout(() => {
        element.innerText = textToCopy;
      }, 1000);
    };

    setModal({
      header: 'Share Your Results',
      body: (
        <div>
          <p>Sharing results will let a tutor view your test scores, performance breakdown, and answers to specific questions.</p>
          <p>
            <h5><b><u>Share link</u></b></h5>
            <div className="d-flex">
              <pre className="share-link" style={{
                whiteSpace: 'break-spaces',
                borderRadius: '8px 0 0 8px',
                borderRight: 'none',
                width: '100%'
              }}>
                {shareTestUrl}
              </pre>
              <button className="btn btn-default" type="button" onClick={() => copyShareLink('share-link')} style={{
                marginBottom: '12px',
                borderRadius: '0 8px 8px 0',
                borderWidth: '1px'
              }}>
                <FontAwesomeIcon icon="copy" />
              </button>
            </div>
          </p>
          <SecondaryButton block onClick={() => setModal(null)}>Close</SecondaryButton>
        </div>
      )
    })
  };

  const [currentExamSection, setCurrentExamSection] = useState(defaultExamSection());

  return (
    <>
      <Helmet>
        <title>{branding.examName} Test Results - Magoosh {branding.examName}</title>
      </Helmet>

      {
        props.scoreReport && !shareableId && (
          <div className="u-margin-B-s">
            <StatusAlert
              answerId={props.scoreReport.answerId}
              contentProducer={props.scoreReport.contentProducer}
              renderOnFinished
              status={props.scoreReport.status}
            />
          </div>
        )
      }

      <div className="d-flex flex-column gap-m">
        <CardLayout.Card className="d-flex flex-column flex-lg-row gap-m">
          <div>
            <h1 className="h2 u-margin-A-n u-padding-B-xxs">
              <strong>Practice Test Results</strong>
            </h1>

            <div><small>Test <strong>{props.mockTest.template.name}</strong></small></div>
            <div>
              <small>
                Finished {
                  moment(props.mockTest.finishedAt * 1000).tz(timeZone).isSame(moment().tz(timeZone), 'day')
                    ? 'Today'
                    : moment(props.mockTest.finishedAt * 1000).tz(timeZone).fromNow()
                }
              </small>
            </div>

            {
              !shareableId && (
                <div>
                  <small>
                    <a onClick={shareResultModal}>
                      <FontAwesomeIcon icon="share"/>
                      {' '}Share your results
                    </a>
                  </small>
                </div>
              )
            }
          </div>

          <div className="d-flex flex-1 flex-column align-items-lg-end gap-s">
            <div className="d-flex flex-1 flex-column flex-lg-row gap-s">
              {
                props.mockTest.scoredExamSections.map((examSection) => (
                  <ScoreCard
                    key={examSection.name}
                    name={examSection.name}
                    score={examSection.score?.value}
                    scoreReason={examSection.score?.reason}
                  />
                ))
              }
            </div>

            {
              props.mockTest.scoreIsRange && (
                <div className="text-gray-light" style={{ fontSize: 12 }}>
                  <FontAwesomeIcon icon="info-circle" /> These ranges indicate where we think you would score on a full-length test.
                </div>
              )
            }
          </div>
        </CardLayout.Card>

        <div className="d-flex flex-column-reverse flex-md-column gap-m">
          {
            !shareableId && (
              <div className="d-flex flex-grow flex-column flex-md-row gap-m">
                <div className={cx('d-flex flex-column flex-md-row gap-m', { 'flex-md-column': showUpsell })}>
                  <ReviewPerformanceNextStep mockTest={props.mockTest} />
                  {props.ui?.showBasicsContentNextStep ? <BasicsContentNextStep /> : <DecideOnNextTestNextStep />}
                </div>

                {showUpsell && <UpsellCard examName={branding.examName} saleMessage={props.ui?.saleMessage} />}
              </div>
            )
          }

          <CardLayout.Card>
            <div className="hidden-xs">
              {
                props.mockTest.examSectionDetails.map((examSection) => (
                  <TabButton
                    disabled={examSection === currentExamSection}
                    key={examSection.slug}
                    onClick={() => handleCurrentExamSectionChanged(examSection.slug)}
                  >
                    {examSection.name}
                  </TabButton>
                ))
              }
            </div>

            <div className="visible-xs">
              <Select
                groupClassName="u-margin-B-n"
                label="Exam Section"
                onChange={(e) => handleCurrentExamSectionChanged(e.target.value)}
                value={currentExamSection.slug}
              >
                {
                  props.mockTest.examSectionDetails.map((examSection) => (
                    <option
                      key={examSection.slug}
                      value={examSection.slug}
                    >
                      {examSection.name}
                    </option>
                  ))
                }
              </Select>
            </div>

            <hr className="u-margin-V-s" />

            {
              (currentExamSection.performanceBreakdown || currentExamSection.answersBySection) ? (
                <>
                  <div>
                    {
                      currentExamSection.performanceBreakdown && (
                        <>
                          <div className="align-items-lg-center d-flex flex-column flex-lg-row gap-s">
                            <div className="flex-1">
                              <div><strong>{currentExamSection.name} Performance Breakdown</strong></div>
                              <small>Note: Some questions can appear in multiple categories</small>
                            </div>

                            {
                              features?.analytics && !props.mockTest.template.name.startsWith('Official') && !shareableId && (
                                <ContextualLink href={paths.analytics({ focus: `Practice Test - ${moment(props.mockTest.finishedAt * 1000).tz(timeZone).format('MMM D')}:${props.mockTest.id}`, sections: currentExamSection.slug })}>
                                  <DefaultFilledButton size="sm">View Full {currentExamSection.name} Analytics</DefaultFilledButton>
                                </ContextualLink>
                              )
                            }
                          </div>

                          <div className="u-margin-T-m">
                            <PerformanceBreakdown { ...currentExamSection } />
                          </div>

                          <hr />
                        </>
                      )
                    }
                  </div>

                  <div>
                    <AnswersTable { ...currentExamSection } />
                  </div>
                </>
              ) : (
                <div
                  className="bg-gray-lightest border-radius-all d-flex u-border-A-s u-padding-A-m"
                  style={{ gap: 20 }}
                >
                  <MagnifyingGlassIcon fill={colors.grayLight} />

                  <div className="d-flex flex-1 flex-column justify-content-center">
                    <strong>No Data to Display for {currentExamSection.name}</strong>
                    <span>You didn’t answer any questions in this section.</span>
                  </div>
                </div>
              )
            }
          </CardLayout.Card>
        </div>
      </div>
    </>
  );
};

const BasicsContentNextStep: React.FC<{}> = () => {
  return (
    <CardLayout.Card className="d-flex flex-1" style={{ gap: 30 }}>
      <div>
        <WateringSeedlingIcon fill={colors.brandPrimary} height={80} width={80} />
      </div>

      <div>
        <div>
          <small className="text-gray-light"><strong>NEXT STEPS</strong></small>
        </div>

        <div className="text-gray-darker u-margin-V-xxs">
          <strong>Continue Studying With Free Prep Resources</strong>
        </div>

        <div className="text-gray-light">
          We're here to help you succeed. Keep making progress towards your goals with <ContextualLink href={paths.dashboard()} className="text-gray-light">free prep resources</ContextualLink> from Magoosh!
        </div>
      </div>
    </CardLayout.Card>
  );
};

const DecideOnNextTestNextStep: React.FC<{}> = () => {
  return (
    <CardLayout.Card className="d-flex flex-1" style={{ gap: 30 }}>
      <div>
        <WateringSeedlingIcon fill={colors.brandPrimary} height={80} width={80} />
      </div>

      <div>
        <div>
          <small className="text-gray-light"><strong>NEXT STEPS</strong></small>
        </div>

        <div className="text-gray-darker u-margin-V-xxs">
          <strong>Decide When You'll Take Your Next Test</strong>
        </div>

        <div className="text-gray-light">
          Whether you are close to your goal, or still have a ways to go, we recommend taking a practice test about every 2 weeks. Set aside the time on your calendar now and commit to it!
        </div>
      </div>
    </CardLayout.Card>
  );
};

const ReviewPerformanceNextStep: React.FC<{ mockTest: any }> = (props) => {
  const { features } = useAppContext().data;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  return (
    <CardLayout.Card className="d-flex flex-1" style={{ gap: 30 }}>
      <div>
        <LightBulbPlantIcon fill={colors.purple} height={80} width={80} />
      </div>

      <div>
        <div>
          <small className="text-gray-light"><strong>NEXT STEPS</strong></small>
        </div>

        <div className="text-gray-darker u-margin-V-xxs">
          <strong>Review Your Performance</strong>
        </div>

        <div className="text-gray-light">
          Review your performance breakdown below to find areas where you can improve. For questions you got wrong watch our expert video explanations to see how to approach the question next time.
        </div>

        {
          features.analytics && (
            <div className="align-items-center d-flex gap-s u-margin-T-s">
              <div>
                <span
                  style={{
                    backgroundColor: colors.purpleLight,
                    borderRadius: 20,
                    color: colors.purple,
                    fontSize: 12,
                    padding: '2px 10px'
                  }}
                >
                  <strong>BETA</strong>
                </span>
              </div>

              <div className="small">
                <ContextualLink
                  href={paths.analytics({ focus: `Practice Test - ${moment(props.mockTest.finishedAt * 1000).tz(timeZone).format('MMM D')}:${props.mockTest.id}` })}
                  style={{ color: colors.purple }}
                >
                  Take a deep dive into your analytics
                </ContextualLink>
              </div>
            </div>
          )
        }
      </div>
    </CardLayout.Card>
  );
};

export default MockTestResults;
