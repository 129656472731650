import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import cx from 'classnames';

import { pluralize } from 'utilities/pluralize';
import StudyDayPicker from '../study_day_picker';
import StudyLengthHoursPicker from '../study_length_hours_picker';
import style from './style.module.scss';

interface Props {
  daysUntilTest?: number;
  recommendedHoursPerWeek: number;
  calculatedHoursPerWeek: number;
}

const DaysStep = ({ calculatedHoursPerWeek, daysUntilTest, recommendedHoursPerWeek }: Props) => (
  <>
    <h3 className="u-margin-B-n u-margin-T-s">
      <strong>Choose your study days and length.</strong>
    </h3>

    <small className={style.later}>You can change this later if you need.</small>

    <div className={cx(style.studyDaysStep, 'u-margin-T-l')}>
      {daysUntilTest ? (
        <div>
          <h4 className="u-margin-V-xxs">
            You have{' '}
            <span className="text-primary">
              <strong>
                {daysUntilTest >= 14
                  ? pluralize(Math.round(daysUntilTest / 7), 'week', 'weeks')
                  : pluralize(daysUntilTest, 'day', 'days')}
              </strong>
            </span>{' '}
            left before your test!
          </h4>
        </div>
      ) : null}
      <h4 className="u-margin-V-xxs">
        We recommend that you study{' '}
        <span className="text-primary">
          <strong>{pluralize(recommendedHoursPerWeek, 'hour', 'hours')} per week.</strong>
        </span>
      </h4>
    </div>

    <Row className="u-margin-T-l">
      <Col sm={3} smOffset={2} className="hidden-xs text-right">
        <small>Study days</small>
      </Col>
      <Col xs={12} sm={6}>
        <div>
          <StudyDayPicker />
        </div>
      </Col>
    </Row>

    <Row className="u-margin-T-s">
      <Col sm={3} smOffset={2} className="hidden-xs text-right">
        <small>Study length</small>
      </Col>
      <Col xs={12} sm={6}>
        <div>
          <span className="h4">
            <strong>
              for
              <div className="d-inline-block u-margin-H-xs">
                <StudyLengthHoursPicker />
              </div>
              a day
            </strong>
          </span>
        </div>
      </Col>
    </Row>

    <Row className="u-padding-T-s">
      <Col sm={3} smOffset={2} className="hidden-xs text-right">
        <small>Total weekly study</small>
      </Col>
      <Col xs={12} sm={6}>
        <div>
          <h4 className="u-margin-A-n">
            <strong>= {pluralize(calculatedHoursPerWeek, 'hour', 'hours')} per week</strong>
          </h4>
          <small>
            (We recommend at least {pluralize(recommendedHoursPerWeek, 'hour', 'hours')} per week)
          </small>
        </div>
      </Col>
    </Row>
  </>
);

export default DaysStep;
