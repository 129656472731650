import * as React from 'react';
import { adminPaths, paths } from 'config/path_helpers';
import NavBar from '../nav_bar';
import { NavigationLink, ProfileDropdownLink } from '../types';

export interface TeacherNavBarProps {
  currentUrl: string;
  showAdminLink: boolean;
  showAssignmentsLink: boolean;
  showSchoolReportLink: boolean;
}

export default class TeacherNavBar extends React.Component<TeacherNavBarProps, {}> {
  render() {
    return (
      <NavBar
        navigationLinks={this.navigationLinks()}
        profileDropdownLinks={this.profileDropdownLinks()}
      />
    );
  }

  navigationLinks(): NavigationLink[] {
    const navigationLinks = [
      {
        name: 'Class Report',
        selected: this.props.currentUrl === paths.classes(),
        url: paths.classes()
      },
      {
        name: 'Student Dashboard',
        selected: false,
        url: paths.dashboard()
      }
    ];

    if (this.props.showAssignmentsLink) {
      navigationLinks.splice(1, 0, {
        name: 'Assignments',
        selected: this.props.currentUrl === paths.assignments(),
        url: paths.assignments()
      });
    }

    if (this.props.showSchoolReportLink) {
      navigationLinks.push({
        name: 'School Report',
        selected: this.props.currentUrl === paths.schoolReport(),
        url: paths.schoolReport()
      });
    }

    return navigationLinks;
  }

  profileDropdownLinks(): ProfileDropdownLink[] {
    const profileDropdownLinks = [
      {
        name: 'Profile',
        url: paths.profile()
      },
      {
        name: 'Log Out',
        url: paths.logout()
      }
    ];

    if (this.props.showAdminLink) {
      profileDropdownLinks.splice(1, 0, {
        name: 'Admin',
        url: adminPaths.dashboard()
      });
    }

    return profileDropdownLinks;
  }
}
