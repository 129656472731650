import React from 'react';

import ACT from './exams/act';
import IELTS from './exams/ielts';
import IELTS_GT from './exams/ielts_general_training';
import LSAT from './exams/lsat';
import TOEFL from './exams/toefl';

interface Props {
  examName: string;
}

const DescriptionContent = (props) => {
  const slug = props.examName.trim().toLowerCase().replace(/ /g, '_');

  const DefaultDescriptionContent = () => (
    <>
      <p>This practice test simulates the experience you will have taking the {props.examName} on test day.</p>
      <p>When you finish the test, we will give you a <strong>score estimate</strong> which will help you predict how well you would do on the actual {props.examName}. You'll also be able to <strong>review your answers</strong> and see the video explanations.</p>
    </>
  );

  const ExamDescriptionContent = {
    act: ACT,
    ielts: IELTS,
    ielts_general_training: IELTS_GT,
    lsat: LSAT,
    toefl: TOEFL
  }[slug] || DefaultDescriptionContent;

  return <ExamDescriptionContent />;
};

export default DescriptionContent;
