import * as React from 'react';
import { ApplicantProfileCardProps } from '@magoosh/pages/applicant_profiles/types';
import ApplicantProfileCard from '@magoosh/pages/applicant_profiles/profile_card';
import style from './style.module.scss';

interface Props {
  profiles: ApplicantProfileCardProps[];
}

const SimilarProfiles: React.FC<Props> = (props) => {
  return (
    <div className={style.similarProfilesContainer}>
      <div className={style.similarProfiles}>
        <h2>Similar Profiles</h2>
        <div>
          {props.profiles.map((profile) => (
            <ApplicantProfileCard key={profile.id} {...profile} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SimilarProfiles;
