import * as React from 'react';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { FieldSelect } from '@magoosh/lib/formik_inputs/field_select/template';
import { FieldCheckboxGroup } from '@magoosh/lib/formik_inputs/field_checkbox_group';
import { FieldRadioGroup } from '@magoosh/lib/formik_inputs/field_radio_group';
import style from './style.module.scss';
import { DemographicSurveyValues, DemographicSurveyProps } from './types';
import { countryList } from './assets/country_list';
import { SecondaryButton } from '@magoosh/lib/button';
import { Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import ExpansionPanel, { ExpansionPanelGroup } from '@magoosh/organisms/expansion_panel';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import { Grid, Row, Col } from 'react-bootstrap';
import cx from 'classnames';
import { bugsnagNotify } from 'utilities/bugsnag';
import { FieldInput } from '@magoosh/lib/formik_inputs/field_input/template';

export const DemographicSurvey: React.FC<DemographicSurveyProps> = (props) => {
  const {
    stepNumber,
    stepCount,
    nextPath,
    countryOfOrigin,
    genderIdentity,
    genderIdentityDescription,
    raceOrEthnicity,
    raceOrEthnicityDescription,
    birthYear,
    firstGenerationCollege,
    nonEnglishAtHome,
    livingWithDisability
  } = props;

  const initialValues: DemographicSurveyValues = {
    countryOfOrigin: countryOfOrigin,
    genderIdentity: genderIdentity || [],
    genderIdentityDescription: genderIdentityDescription,
    raceOrEthnicity: raceOrEthnicity || [],
    raceOrEthnicityDescription: raceOrEthnicityDescription,
    birthYear: birthYear,
    firstGenerationCollege: firstGenerationCollege,
    nonEnglishAtHome: nonEnglishAtHome,
    livingWithDisability: livingWithDisability
  };

  const countries = React.useMemo(() => {
    return countryList.map((countryName) => {
      return {
        country: countryName
      };
    });
  }, []);

  const years = React.useMemo(() => {
    const currentYear = new Date().getFullYear();
    const last100Years = Array.from({ length: 100 }, (v, i) => currentYear - i);

    return last100Years.map((year) => {
      return { year: year };
    });
  }, []);

  const handleOnSubmit = (
    values: DemographicSurveyValues,
    { setFieldError, setSubmitting }: FormikActions<DemographicSurveyValues>
  ): void => {
    setSubmitting(true);
    fetch(paths.api.userDemographics(), {
      method: 'POST',
      body: JSON.stringify({ user: values })
    })
      .catch((error) => {
        const fieldErrors = error.errors;
        setFieldError('general', fieldErrors._error);
        bugsnagNotify(error);
      })
      .then(() => {
        window.location.assign(nextPath || paths.dashboard());
      });
  };

  return (
    <Grid className={cx('u-padding-T-m', style.demographicSurvey)}>
      <Row>
        <Col md={6} mdOffset={3}>
          {stepNumber && stepCount && (
            <p style={{ color: style.gray30 }}>
              Step {stepNumber} of {stepCount}
            </p>
          )}
          <h2 style={{ marginTop: '0px' }}>Help Us Close the Gap</h2>
          <p style={{ color: style.gray30 }}>
            We&apos;re committed to making test prep accessible, effective, and enjoyable so that
            students from all backgrounds, anywhere in the world can achieve their educational
            dreams. Your input will help us continue to provide equitable products and offerings.
          </p>
          <ExpansionPanelGroup>
            <ExpansionPanel
              leftAligned={true}
              text={'Learn More'}
              title={<h4>More Information about How We Use Your Demographic Data</h4>}>
              {(onClose) => (
                <>
                  <p style={{ fontSize: '16px', color: style.gray30 }}>
                    Providing demographic information is optional, and your data will not be shared
                    with anyone outside of Magoosh. Your responses will have no impact on your
                    individual score estimates, eligibility for guarantees, access to content on our
                    platform, or the service you receive. For more information about data we collect
                    and how it&apos;s used, please refer to our Privacy Policy.
                  </p>
                  <hr></hr>
                  <SecondaryButton onClick={onClose}>Close</SecondaryButton>
                  <a
                    className="u-margin-L-xs"
                    style={{ fontSize: '16px', color: style.gray30 }}
                    href={paths.privacy()}
                    target={'_blank'}>
                    View Privacy Policy
                  </a>
                </>
              )}
            </ExpansionPanel>
          </ExpansionPanelGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} mdOffset={3}>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
            {({ errors, values, isSubmitting, handleSubmit, setFieldValue }) => {
              return (
                <>
                  {(errors as any).general && <Alert bsStyle="danger">{(errors as any).general}</Alert>}
                  <Form style={{ fontSize: '16px' }}>
                    <FieldSelect
                      className="form-control u-margin-B-l"
                      block
                      placeholder="Select your home country"
                      name="countryOfOrigin"
                      label="What country are you from?"
                      description="Students use Magoosh from all over the world. This helps us understand the diverse needs of
                our international student base."
                      objects={countries}
                      valueAccessor="country"
                      keyAccessor="country"
                    />
                    <FieldCheckboxGroup
                      label="Which gender identity do you most identify with?"
                      description="(Select all that apply.)"
                      name="genderIdentity"
                      optionsArray={[
                        { value: 'woman', displayName: 'Woman' },
                        { value: 'man', displayName: 'Man' },
                        { value: 'transgender', displayName: 'Transgender' },
                        { value: 'non-binary', displayName: 'Non-binary' },
                        {
                          value: 'prefer to self describe',
                          displayName: 'Prefer to self describe',
                          children: (
                            <FieldInput
                              name="genderIdentityDescription"
                              type="text"
                              placeholder="Please add your self description"
                              hideLabel={true}
                            />
                          )
                        },
                        { value: 'prefer not to say', displayName: 'Prefer not to say' }
                      ]}
                      selected={values.genderIdentity}
                      onChange={(newVals) => {
                        setFieldValue('genderIdentity', newVals);
                      }}
                    />
                    <FieldCheckboxGroup
                      label="Which of the following races and/or ethnicities do you most identify with?"
                      description="(Select all that apply.)"
                      name="raceOrEthnicity"
                      optionsArray={[
                        {
                          value: 'indigenous american or alaskan native',
                          displayName: 'Indigenous American or Alaskan Native'
                        },
                        { value: 'black or african american', displayName: 'Black or African American' },
                        { value: 'hispanic or latinx', displayName: 'Hispanic or Latinx' },
                        { value: 'east asian', displayName: 'East Asian' },
                        { value: 'south asian', displayName: 'South Asian' },
                        { value: 'southeast asian', displayName: 'Southeast Asian' },
                        {
                          value: 'native hawaiian or other pacific islander',
                          displayName: 'Native Hawaiian or Other Pacific Islander'
                        },
                        {
                          value: 'middle eastern or north african',
                          displayName: 'Middle Eastern or North African'
                        },
                        { value: 'white', displayName: 'White' },
                        {
                          value: 'prefer to self describe',
                          displayName: 'Prefer to self describe',
                          children: (
                            <FieldInput
                              name="raceOrEthnicityDescription"
                              type="text"
                              placeholder="Please add your self description"
                              hideLabel={true}
                            />
                          )
                        },
                        { value: 'prefer not to say', displayName: 'Prefer not to say' }
                      ]}
                      selected={values.raceOrEthnicity}
                      onChange={(newVals) => {
                        setFieldValue('raceOrEthnicity', newVals);
                      }}
                    />
                    <FieldSelect
                      className="form-control u-margin-B-l"
                      block
                      placeholder="Select a year"
                      name="birthYear"
                      label="In what year were you born?"
                      description="Students take tests at different times in their lives. Sharing your age helps us understand the unique needs of your age group."
                      objects={years}
                      valueAccessor="year"
                      keyAccessor="year"
                    />
                    <FieldRadioGroup
                      label={
                        <>
                          <label htmlFor="firstGenerationCollege" className="u-margin-B-n">
                            Do you identify as a first-generation college student?
                          </label>
                          {'   '}
                          <OverlayTrigger
                            trigger={['click']}
                            rootClose={true}
                            overlay={
                              <Popover id="first-generation-popover">
                                First-generation college students are those whose parents or guardians did not
                                complete a four-year college degree, or students who self-identify as not
                                having prior exposure to or knowledge of navigating the higher education
                                system of the country where they'll be enrolling.
                              </Popover>
                            }>
                            <small>
                              <FontAwesomeIcon icon={'info-circle'} />
                            </small>
                          </OverlayTrigger>
                        </>
                      }
                      name="firstGenerationCollege"
                      value={values.firstGenerationCollege}
                      optionsArray={[
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                        { value: 'prefer not to say', label: 'Prefer not to say' }
                      ]}
                    />
                    <FieldRadioGroup
                      label="Did you grow up primarily speaking a language other than English in your home?"
                      name="nonEnglishAtHome"
                      description="This helps us improve our materials for students whose first language is not English."
                      value={values.nonEnglishAtHome}
                      optionsArray={[
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                        { value: 'prefer not to say', label: 'Prefer not to say' }
                      ]}
                    />
                    <FieldRadioGroup
                      label={
                        <>
                          <label htmlFor="livingWithDisability">
                            Do you identify as a person living with a disability?
                          </label>
                          {'   '}
                          <OverlayTrigger
                            trigger={['click']}
                            rootClose={true}
                            overlay={
                              <Popover id="disability-popover">
                                The National Center for Education Statistics defines students with
                                disabilities as those who reported that they had one or more of the following
                                conditions: blindness or visual impairment that cannot be corrected by wearing
                                glasses; hearing impairment (e.g., deaf or hard of hearing); orthopedic or
                                mobility impairment; speech or language impairment; learning, mental,
                                emotional, or psychiatric condition (e.g., serious learning disability,
                                depression, ADD, or ADHD); or other health impairment or problem.
                              </Popover>
                            }>
                            <small>
                              <FontAwesomeIcon icon={'info-circle'} />
                            </small>
                          </OverlayTrigger>
                        </>
                      }
                      name="livingWithDisability"
                      description="Your answer aids us in helping students with disabilities overcome challenges preparing for and taking standardized tests."
                      value={values.livingWithDisability}
                      optionsArray={[
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                        { value: 'prefer not to say', label: 'Prefer not to say' }
                      ]}
                      className="u-margin-B-n"
                    />
                    <div
                      className={cx(
                        'd-flex',
                        stepNumber != null ? 'justify-content-between' : 'justify-content-end'
                      )}>
                      {stepNumber != null && (
                        <a style={{ color: style.grayDark }} href={stepNumber === 2 ? paths.welcome() : paths.purchaseFeedback()}>
                          Back
                        </a>
                      )}
                      <SecondaryButton onClick={handleSubmit} disabled={isSubmitting}>
                        {stepNumber == null ? 'Submit' : stepNumber == stepCount ? 'Start Studying' : 'Next'}
                      </SecondaryButton>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Grid>
  );
};

export default DemographicSurvey;
