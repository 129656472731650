import * as React from 'react';
import { NavigationLink, ProfileDropdownLink } from '../types';
import NavBar from '../nav_bar';

export interface NavBarProps {
  navigationLinks: NavigationLink[];
  profileDropdownLinks: ProfileDropdownLink[];
}

export default class ExamSectionNavBar extends React.Component<NavBarProps, {}> {
  render() {
    return <NavBar {...this.props} />;
  }
}
