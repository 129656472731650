import * as React from 'react';
import capitalize from 'react-bootstrap/lib/utils/capitalize';
import { ErrorMessage, Field } from 'formik';
import style from '@magoosh/lib/formik_inputs/field_input/style.module.scss';
import cx from 'classnames';

interface FieldSelectProps {
  name: string;
  id?: string;
  label?: string;
  description?: string;
  objects?: any[];
  options?: object;
  valueAccessor?: string;
  keyAccessor?: string;
  placeholder?: string;
  className?: string;
  block?: boolean;
  disabled?: boolean;
  optional?: boolean;
  hideLabel?: boolean;
}

// FieldSelect can receive options for select in 2 formats:
// objects: an array of objects of any kind. If this is provided, then
// valueAccessor and keyAccessor are required to extract the required values
// from the objects.
// options: a simple object whose keys and values will be used for labels and values
// for the options in the select.

export class FieldSelect extends React.Component<FieldSelectProps> {
  render() {
    const props = this.props;

    return (
      <div className="u-margin-T-s">
        {!props.hideLabel && <label htmlFor={id(props)}>
          {capitalize(props.label || props.name)}{' '}
          {props.optional && (
            <span className={style.optional}>
              <small>(Optional)</small>
            </span>
          )}
        </label>}

        {props.description && (
          <p style={{ marginBottom: '10px' }}>
            {props.description}
          </p>
        )}
        <Field
          name={props.name}
          id={id(props)}
          component="select"
          className={cx(props.className, !props.block && 'u-margin-L-xs')}
          disabled={props.disabled}>
          {(props.placeholder || props.optional) && (
            <option className={style.placeholder} value="" disabled={!props.optional} selected>
              {props.placeholder}
            </option>
          )}
          {props.options ? this.renderOptions() : this.renderObjects()}
        </Field>
        <div className={style.errorMessage}>
          <ErrorMessage name={props.name} />
        </div>
      </div>
    );
  }

  renderObjects() {
    return (
      <React.Fragment>
        {this.props.objects.map((option) => (
          <option key={option[this.props.valueAccessor]} value={option[this.props.valueAccessor]}>
            {option[this.props.keyAccessor]}
          </option>
        ))}
      </React.Fragment>
    );
  }

  renderOptions() {
    return (
      <React.Fragment>
        {Object.keys(this.props.options).map((label) => (
          <option key={this.props.options[label]} value={this.props.options[label]}>
            {label}
          </option>
        ))}
      </React.Fragment>
    );
  }
}

function id(props: FieldSelectProps) {
  return props.id || `${props.name}-input`;
}
