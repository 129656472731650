import React from 'react';
import cx from 'classnames';

import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { pluralize } from 'utilities/pluralize';

import { Series } from '../types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props extends Series {
  active?: boolean;
  colorPalette?: {
    dark: string;
    light: string;
  };
  onSelected: () => void;
}

const SeriesCard: React.FC<Props> = (props) => {
  if (props.score) {
    return <SeriesCardWithScore {...props} />;
  } else {
    return <SeriesCardWithoutScore {...props} />
  }
};

const SeriesCardWithoutScore: React.FC<Props> = (props) => {
  const colorPalette = props.colorPalette || {
    'Overall': { dark: colors.brandSecondary, light: '#ffffff' },
    'Math': { dark: colors.dataLightPurple, light: '#ffffff' },
    'Verbal': { dark: colors.dataLightBlue, light: '#ffffff' }
  }[props.name];

  return (
    <div
      className={cx(style.seriesCard, 'd-flex flex-column gap-xxs')}
    >
      <div className="d-flex flex-lg-1 flex-row gap-s">
        <div
          className="text-bold text-white"
          style={{
            backgroundColor: colorPalette.dark,
            borderRadius: 20,
            color: colorPalette.light,
            padding: '0 10px'
          }}
        >
          {props.name}
        </div>

        {
          props.target && (
            <div className="align-items-center d-flex flex-lg-1 justify-content-center justify-content-lg-end small text-bold gap-xxs">
              <FontAwesomeIcon icon="bullseye" style={{ color: colorPalette.light }} />
              {' '}<span style={{ color: colorPalette.light }}>{props.target}</span>
            </div>
          )
        }
      </div>

      <div className="d-flex flex-1 flex-column gap-xxs" style={{ minWidth: 150 }}>
        <h2 className="text-bold text-secondary u-margin-A-n">
          N/A
        </h2>

        {
          props.answerCounts && (
            <div className="small text-gray-light" style={{ lineHeight: 1.25 }}>
              Answer {pluralize(props.answerCounts.thresholdForScoreEstimate - props.answerCounts.total, 'more question')}
              <br />for an estimated score
            </div>
          )
        }
      </div>
    </div>
  );
};

const SeriesCardWithScore: React.FC<Props> = (props) => {
  const colorPalette = props.colorPalette || {
    'Math': { dark: colors.dataLightPurple, light: '#ffffff' },
    'Verbal': { dark: colors.dataLightBlue, light: '#ffffff' },
    'Overall': { dark: colors.dataDarkPurple, light: '#ffffff' }
  }[props.name];

  return (
    <div>
      <div
        className={cx(style.seriesCard, 'align-items-center d-flex flex-row gap-s', { [style.active]: props.active })}
        onClick={props.onSelected}
        style={{
          backgroundColor: props.active ? colorPalette.dark : '#ffffff',
          borderRadius: 8,
          cursor: 'pointer',
          transition: 'background-color 250ms ease-in, color 250ms ease-in'
        }}
      >
        <div className="hidden-sm hidden-xs">
          {
            props.active ? (
              <div
                className="align-items-center d-flex justify-content-center"
                style={{
                  backgroundColor: '#ffffff',
                  borderRadius: 8,
                  height: 16,
                  width: 16
                }}
              >
                <FontAwesomeIcon
                  icon="check"
                  style={{ color: colorPalette.dark, fontSize: 12, WebkitTextStroke: '1px #ffffff' }}
                />
              </div>
            ) : (
              <div
                style={{
                  borderColor: '#BBB7C0',
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  height: 16,
                  width: 16
                }}
              />
            )
          }
        </div>

        <div className="d-flex flex-column gap-xs">
          <div className="d-flex flex-row gap-s">
            {
              props.active ? (
                <div className="text-bold text-white">
                  {props.name}
                </div>
              ): (
                <div
                  className="text-bold text-white"
                  style={{
                    backgroundColor: colorPalette.dark,
                    borderRadius: 20,
                    color: colorPalette.light,
                    padding: '0 10px'
                  }}
                >
                  {props.name}
                </div>
              )
            }

            {
              props.target && (
                <div className="align-items-center d-flex flex-lg-1 justify-content-center justify-content-lg-end small text-bold gap-xxs">
                  <FontAwesomeIcon icon="bullseye" style={{ color: props.active ? colorPalette.light : colorPalette.dark }} />
                  {' '}<span style={{ color: props.active ? colorPalette.light : colorPalette.dark }}>{props.target}</span>
                </div>
              )
            }
          </div>

          <h2
            className="text-bold u-margin-A-n"
            style={{
              color: props.active ? colorPalette.light : (props.colorPalette?.dark || colors.brandSecondary),
              minWidth: 150
            }}
          >
            {props.score.low} - {props.score.high}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default SeriesCard;
