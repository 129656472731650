import React from 'react';
import moment from 'moment';

import { DefaultButton } from '@magoosh/lib/button';
import Card from '@magoosh/lib/card';
import{ SquareIcon } from '@magoosh/lib/icons';
import { ColorPalette } from 'types/constants';

import { ApplicationCycleDeadline } from '@admissions/types';

import style from './style.module.scss';

interface Props {
  deadline: ApplicationCycleDeadline;
}

const DeadlineCard: React.FC<Props> = (props) => {
  const DEFAULT_COLOR_PALETTE = { dark: '#2774AE', light: '#DAEBFE' } as ColorPalette;

  return (
    <Card>
      <div className="d-flex">
        <div>
          <SquareIcon
            colorPalette={props.deadline.program?.institution?.colorPalette || DEFAULT_COLOR_PALETTE}
            iconType="multiple_stars"
            size={48}
          />
        </div>
      </div>

      <div>
        <p className="text-gray-light u-margin-A-n">
          {props.deadline.programName || 'Program Specific'}
        </p>
        <h3 className="u-margin-A-n">
          Application Deadline ({moment(props.deadline.dueAt).format('MMMM D')})
        </h3>
      </div>
    </Card>
  )
};

export default DeadlineCard;
