import React from 'react';
import Modal from '@magoosh/lib/modal';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { pluralize } from 'utilities/pluralize';
import style from './style.module.scss';

interface ArchiveClassSectionsModalProps {
  classSections: any[];
  reset: () => void;
  archiveClassSections: () => void;
  showErrorMessage: boolean;
}

export const ArchiveClassSectionsModal: React.FC<ArchiveClassSectionsModalProps> = (props) => {
  const { classSections, reset, archiveClassSections, showErrorMessage } = props;

  if (!classSections || classSections.length <= 0) {
    return null;
  }

  let message;
  let buttonText;

  if (classSections.length == 1) {
    const classSection = classSections[0];

    message = <>
      <p>
        Are you sure you want to archive this class?
      </p>
      <div style={{ marginLeft: '3rem' }}>
        <p>
          Name <br />
          <strong>{classSection.displayName}</strong>
        </p>
        {classSection.subject && (
          <p>
            Subject <br />
            <strong>{classSection.subject}</strong>
          </p>
        )}
        {classSection.sectionNumber && (
          <p>
            Section Number <br />
            <strong>{classSection.sectionNumber}</strong>
          </p>
        )}
      </div>
    </>;

    buttonText = 'Archive Class';
  } else {
    message = <p>
      Are you sure you want to archive the selected classes?
    </p>;

    buttonText = `Archive ${pluralize(classSections.length, 'Class', 'Classes')}`
  }

  return <Modal
    show={classSections && classSections.length > 0}
    onHide={reset}
  >
    {message}
    <Modal.Footer>
      {showErrorMessage &&
        <span className={style.error}>
          An error has occurred. Please try again.&nbsp;
        </span>
      }
      <DefaultButton onClick={reset}>
        Cancel
      </DefaultButton>
      <SecondaryButton onClick={() => {
        archiveClassSections();
      }}>
        {buttonText}
      </SecondaryButton>
    </Modal.Footer>
  </Modal>
}
