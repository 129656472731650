import * as React from 'react';

import { PrimaryButton } from '@magoosh/lib/button';

interface Props {
  examName: string;
  onComplete: (any) => void;
  onSkip: (any) => void;
}

export default ({ examName, onComplete, onSkip }: Props) => {
  return (
    <React.Fragment>
      <div>
        <h4 className="u-margin-V-xxs"><strong>Welcome to Magoosh {examName}!</strong></h4>
        <div className="h7">Estimated Time: 4 min</div>

        <div className="u-margin-T-s">
          <p>Get started by answering a couple of questions about where you are in your {examName} journey and then choose a study schedule.</p>
          <p>If you would rather prep without a schedule, we will recommend video lessons by subject here instead.</p>
      </div>
      </div>

      <div className="text-center">
        <div className="u-margin-V-xl">
          <PrimaryButton className={'btn-multi-line'} block onClick={onComplete}>Choose Your Study Schedule</PrimaryButton>
        </div>
        <a className="text-gray-light" onClick={onSkip}>No thanks, I'll study on my own instead</a>
      </div>
    </React.Fragment>
  );
}
