import React from 'react';
import { Helmet } from 'react-helmet';

import { PrimaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import { bugsnagNotify } from 'utilities/bugsnag';

export default class ErrorBoundary extends React.Component<{ examName?: string}> {
  state = {
    error: false
  };

  componentDidCatch(error, errorInfo) {
    bugsnagNotify(error);
    this.setState({ error: true });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="container-small container-app u-margin-V-l">
          <Helmet>
            <title>Server Error (500) - Magoosh {this.props.examName}</title>
          </Helmet>

          <h1>Server Error</h1>

          <p>Something on Magoosh's server went wrong and the page couldn't load.</p>
          <p>The Magoosh team has been alerted, and this issue will hopefully be fixed soon.</p>

          <h3>Contact Us</h3>

          <p>If need help with this, please send an email to <a href="mailto:help@magoosh.com">help@magoosh.com</a> and we'll get back to you as soon as we can.</p>

          <div className="form-actions">
            <PrimaryButton href={paths.dashboard()}>Go to Dashboard</PrimaryButton>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
