import React, { useCallback, useContext, useState } from 'react';
import { DataTable } from '@magoosh/lib/data_table';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Search } from '@magoosh/lib/data_table/search';
import { DateRangeFilter } from '@magoosh/lib/data_table/date_range_filter';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import styles from '../style.module.scss';
import colors from '@magoosh/b2b/app/assets/style.module.scss';
import { Icon } from '@magoosh/b2b/app/assets/icons';
import useReportingFilterOptions from '@magoosh/b2b/app/pages/reporting/reporting_filter_options';
import { dateFormatter } from '@magoosh/b2b/app/utils';
import { isEmpty } from 'lodash';
import { AppContext } from '@magoosh/b2b/app/app_context';

export const PracticeTests: React.FC<PracticeTestsProps> = (props) => {
  const appContext = useContext(AppContext);
  const [filterOptions, setFilterOptions] = useState({ schoolAndClassSections: [] });

  const { queryParameters, runQuery, dataQueryTableProps } = useDataQuery(
    paths.api.partner.mockTests,
    {
      sortColumn: 'testDate',
      filters: props.studentId ? { studentId: props.studentId } : {}
    }
  );

  const reportingFiltersDropdown = props.studentId ?
    null : useReportingFilterOptions(runQuery).reportingFiltersDropdown;

  const omitColumn = useCallback((columnName) => {
    return isEmpty(dataQueryTableProps.data) ||
      !(columnName in (dataQueryTableProps.data[0] as any));
  }, [dataQueryTableProps.data])

  return (
    <>
      <DataTable
        {...dataQueryTableProps}
        primaryAttribute={{ selector: 'fullName', name: 'Student', sortable: true }}
        linkBaseUrl="/students/"
        secondaryAttributeSelector="email"
        columns={[
          {
            selector: 'testDate',
            name: 'Date',
            sortable: true,
            format: (mockTest) => dateFormatter(mockTest.testDate)
          },
          { selector: 'sectionsCompleted', name: <>Sections<br />Finished</>, sortable: true },
          { selector: 'actScore', name: 'ACT Score', sortable: true, omit: omitColumn('actScore') },
          { selector: 'greScore', name: 'GRE Score', sortable: true, omit: omitColumn('greScore') },
          { selector: 'gmatScore', name: 'GMAT Score', sortable: true, omit: omitColumn('gmatScore') },
          { selector: 'lsatScore', name: <>LSAT<br /> Score</>, sortable: true, omit: omitColumn('lsatScore') },
          { selector: 'mcatScore', name: <>MCAT<br />Score</>, sortable: true, omit: omitColumn('mcatScore') },
          { selector: 'ieltsScore', name: 'IELTS Score', sortable: true, omit: omitColumn('ieltsScore') },
          { selector: 'ieltsGeneralTrainingScore', name: 'IELTS GT Score', sortable: true, omit: omitColumn('ieltsGeneralTrainingScore') },
          { selector: 'toeflScore', name: 'TOEFL Score', sortable: true, omit: omitColumn('toeflScore') },
          { selector: 'totalScore', name: 'SAT Score', sortable: true, omit: omitColumn('totalScore') },
          { selector: 'englishScore', name: 'English Score', sortable: true, omit: omitColumn('englishScore') },
          { selector: 'mathScore', name: 'Math Score', sortable: true, omit: omitColumn('mathScore') },
          { selector: 'readingScore', name: 'Reading Score', sortable: true, omit: omitColumn('readingScore') },
          { selector: 'scienceScore', name: 'Science Score', sortable: true, omit: omitColumn('scienceScore') },
          { selector: 'verbalScore', name: 'Verbal Score', sortable: true, omit: omitColumn('verbalScore') },
          { selector: 'listeningScore', name: 'Listening Score', sortable: true, omit: omitColumn('listeningScore') },
          { selector: 'logicGamesScore', name:<>Logic<br />Games</>, sortable: true, omit: omitColumn('logicGamesScore') },
          { selector: 'logicalReasoningScore', name: <>Logical<br />Reasoning</>, sortable: true, omit: omitColumn('logicalReasoningScore') },
          { selector: 'readingComprehensionScore', name: <>Reading<br />Comprehension</>, sortable: true, omit: omitColumn('readingComprehensionScore') },
          { selector: 'biologicalAndBiochemicalScore', name: <>Biological &amp;<br />Biochemical</>, sortable: true, omit: omitColumn('biologicalAndBiochemicalScore') },
          { selector: 'chemicalAndPhysicalScore', name:<>Chemical &amp;<br />Physical</>, sortable: true, omit: omitColumn('chemicalAndPhysicalScore') },
          { selector: 'psychologicalAndSocialScore', name: <>Psychological &amp;<br />Social</>, sortable: true, omit: omitColumn('psychologicalAndSocialScore') },
          { selector: 'carsVerbalScore', name: <>CARS Verbal</>, sortable: true, omit: omitColumn('carsVerbalScore') }
        ]}>
        <DataTable.Filters>
          <div className={styles.filters}>
            <Search runQuery={runQuery} />
            <DateRangeFilter runQuery={runQuery} />
            {reportingFiltersDropdown}
          </div>
        </DataTable.Filters>
        <DataTable.HeaderActions>
          <DownloadButton
            queryParameters={queryParameters}
            fetchUrl={paths.api.partner.mockTests}
            fileName={`${window.location.hostname.split('.')[0]}_practice_tests_data.csv`}
            disabled={appContext.trialAccess}
          />
        </DataTable.HeaderActions>
        <DataTable.ItemIcon>
          {(practiceTest) => <Icon iconType="PracticeTest" fill={colors.supplementaryDarkGreen} size="m" />}
        </DataTable.ItemIcon>
      </DataTable>
    </>
  );
};
