import * as React from 'react';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import cx from 'classnames';

interface WritingExampleListing {
  title: string;
  id: string | number;
  hasEvaluation: boolean;
  watchedByUser: boolean;
}
interface Props {
  writingExamples: {
    resumesAndCvs: {
      Resume: WritingExampleListing[];
      CV: WritingExampleListing[];
    };
    essays: {
      'Statement of Purpose': WritingExampleListing[];
      'Personal Statement': WritingExampleListing[];
      Other: WritingExampleListing[];
    };
  };
}

interface SubsectionProps {
  title: string;
  writingExamples: WritingExampleListing[];
}

const WritingExampleSubsection: React.FC<SubsectionProps> = (props) => {
  return (
    <div>
      <h4>{props.title}</h4>
      <ul className={cx("thin list-unstyled", style.writingExamplesListCondensed)}>
        {props.writingExamples.map((writingExample) => {
          return (
            <li className={style.writingExamplesItem} key={writingExample.id}>
              <a href={paths.writingExample(writingExample.id)}>
                <span className={cx(style.writingExamplesItemTitle, 'h3')}>{writingExample.title}</span>
                <FontAwesomeIcon className={cx(style.writingExamplesStatus, style.writingExamplesItemIcon)} title={'Watched'} icon={writingExample.watchedByUser ? 'eye' : 'none' } />
                {writingExample.hasEvaluation && (
                  <FontAwesomeIcon className="text-body u-margin-L-xs" title="Includes commentary" icon="comment" />
                )}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const WritingExampleIndex: React.FC<Props> = (props) => {
  const hasEssays = Object.values(props.writingExamples.essays).some((essay) => essay.length > 0);
  const hasResumesOrCvs = Object.values(props.writingExamples.resumesAndCvs).some(
    (resumeOrCv) => resumeOrCv.length > 0
  );

  return (
    <div className={style.content}>
      <h1>Writing Examples</h1>
      <p>
        This page contains <strong>real admissions essays and resumes/CVs</strong> from graduate program
        applicants. Writing examples with commentary icons (<FontAwesomeIcon icon={'comment'} />) are paired
        with <strong>detailed feedback</strong> from a Magoosh Admissions Expert on what works and what
        doesn't. We recommend reviewing multiple writing examples and their associated feedback as you prepare
        to write your own admissions essays and resume/CV.
      </p>
      {hasEssays && (
        <>
          <div className="section-header">
            <h3>Admissions Essays</h3>
          </div>
          {props.writingExamples.essays['Statement of Purpose']?.length > 0 && (
            <WritingExampleSubsection
              title="Statements of Purpose"
              writingExamples={props.writingExamples.essays['Statement of Purpose']}
            />
          )}
          {props.writingExamples.essays['Personal Statement']?.length > 0 && (
            <WritingExampleSubsection
              title="Personal Statements"
              writingExamples={props.writingExamples.essays['Personal Statement']}
            />
          )}
          {props.writingExamples.essays['Other']?.length > 0 && (
            <WritingExampleSubsection title="Other" writingExamples={props.writingExamples.essays['Other']} />
          )}
        </>
      )}
      {hasResumesOrCvs && (
        <>
          <div className="section-header">
            <h3>Resume/CVs</h3>
          </div>
          {props.writingExamples.resumesAndCvs['Resume']?.length > 0 && (
            <WritingExampleSubsection
              title={'Resumes'}
              writingExamples={props.writingExamples.resumesAndCvs['Resume']}
            />
          )}
          {props.writingExamples.resumesAndCvs['CV']?.length > 0 && (
            <WritingExampleSubsection
              title={'CVs'}
              writingExamples={props.writingExamples.resumesAndCvs['CV']}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WritingExampleIndex;
