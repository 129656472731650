import * as React from 'react';
import fetch from 'utilities/fetch';
import isEqual from 'lodash/isEqual';
import { find } from 'lodash';
import { ErrorMessage, Field, Form, Formik, FieldArray } from 'formik';
import generator from 'generate-password';
import { adminPaths, paths } from 'config/path_helpers';
import { DefaultButton, LinkButton, PrimaryButton, SecondaryButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import * as yup from 'yup';
import * as moment from 'moment';
import { Col, Grid, Row } from 'react-bootstrap';
import style from './style.module.scss';

import PasswordShowHide from './password_show_hide';
import AddSubscription from './add_subscription';
import AddClassEnrollment from './add_class_enrollment';
import { UserFormProps, UserFormValues } from '@magoosh/admin/users/form/types';
import { Subscription } from 'types/subscription';
import PopupBanner from '@magoosh/lib/popup_banner';
import cx from 'classnames';
import { ClassEnrollment } from 'types/class_enrollment';
import Tooltip from 'react/lib/custom_tooltip/custom_tooltip';

const NewUserSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string().email('Invalid email').required('Required'),
  password: yup.string().required('Required').min(6, 'Passwords must be at least 6 characters')
});

export default class UserForm extends React.Component<UserFormProps> {
  state = {
    showPassword: false,
    showCreateMessage: false,
    createAnother: false
  };

  render() {
    const initialValues: UserFormValues = {
      firstName: '',
      lastName: '',
      email: '',
      userGroupId: this.props.userGroups[0].id,
      password: this.props.initialPassword,
      sendPasswordResetEmail: false,
      sendWelcomeEmails: false,
      subscriptions: [] as Subscription[],
      classEnrollments: [] as ClassEnrollment[]
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={NewUserSchema}
        validateOnBlur={false}
        onSubmit={this.onSubmit}>
        {({ isSubmitting, values, submitForm, setFieldValue }) => {
          const sendEmailLabelClasses = [style.checkboxLabel];
          if (values.subscriptions.length <= 0) {
            sendEmailLabelClasses.push(style.disabled);
          }

          return (
            <Form className={style.userForm}>
              {this.state.showCreateMessage && this.renderCreateMessage()}
              <Grid>
                <Row>
                  <Col md={2} />
                  <Col md={4}>
                    <h1>New User</h1>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label htmlFor="email">Email</label>
                  </Col>
                  <Col md={4}>
                    <Field type="email" name="email" className="form-control" id="email" />
                  </Col>
                  <Col md={4}>
                    <ErrorMessage component="div" name="email" className={style.error} />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label htmlFor="firstName">First Name</label>
                  </Col>
                  <Col md={4}>
                    <Field name="firstName" className="form-control" id="firstName" />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label htmlFor="lastName">Last Name</label>
                  </Col>
                  <Col md={4}>
                    <Field name="lastName" className="form-control" id="lastName" />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Password</label>
                  </Col>
                  <Col md={4}>
                    <PasswordShowHide className={style.passwordShowHide} />
                  </Col>
                  <Col md={1}>
                    <Tooltip text="A randomly generated password is initially supplied here. You can override it with a different password." />
                  </Col>
                  <Col md={4}>
                    <ErrorMessage component="div" name="password" className={style.error} />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label htmlFor="userGroupId">User Group</label>
                  </Col>
                  <Col md={4}>
                    <Field name="userGroupId" component="select" id="userGroupId">
                      {this.props.userGroups.map((group) => (
                        <option key={group.id} value={group.id} className="option">
                          {group.name}
                        </option>
                      ))}
                    </Field>
                  </Col>
                  <Col md={4}>
                    <ErrorMessage component="div" name="userGroupId" className={style.error} />
                  </Col>
                </Row>
                <FieldArray
                  name="subscriptions"
                  render={(arrayHelpers) => (
                    <React.Fragment>
                      <Row>
                        <Col md={2}>
                          <label>Subscriptions</label>
                        </Col>
                        <Col md={4}>
                          <AddSubscription
                            availableSubscriptionPlans={this.props.availableSubscriptionPlans}
                            onAddSubscription={(subscription) => {
                              !this.alreadyInArray(values.subscriptions, subscription) &&
                                arrayHelpers.push(subscription);
                            }}
                          />
                        </Col>
                      </Row>
                      {values.subscriptions.length > 0 && (
                        <React.Fragment>
                          {values.subscriptions.map((subscription, index) => (
                            <Row key={index}>
                              <Col md={2}></Col>
                              <Col xs={1} md={1} className={style.removeButton}>
                                <button onClick={() => arrayHelpers.remove(index)} type="button">
                                  <FontAwesomeIcon icon={'minus-circle'} key={index} />
                                </button>
                              </Col>
                              <Col xs={10} md={6}>
                                {this.renderSubscription(subscription)}
                              </Col>
                            </Row>
                          ))}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                />
                <FieldArray
                  name="classEnrollments"
                  render={(arrayHelpers) => (
                    <React.Fragment>
                      <Row>
                        <Col md={2}>
                          <label>Class Enrollments</label>
                        </Col>
                        <Col md={4}>
                          <AddClassEnrollment
                            availableClassSections={this.props.availableClassSections}
                            availableRoles={this.props.availableRoles}
                            onAddClassEnrollment={(classEnrollment) => {
                              !this.alreadyInArray(values.classEnrollments, classEnrollment) &&
                                arrayHelpers.push(classEnrollment);
                            }}
                          />
                        </Col>
                      </Row>
                      {values.classEnrollments.length > 0 && (
                        <React.Fragment>
                          {values.classEnrollments.map((classEnrollment, index) => (
                            <Row key={index}>
                              <Col md={2}></Col>
                              <Col xs={1} md={1} className={style.removeButton}>
                                <button
                                  onClick={() => arrayHelpers.remove(index)}
                                  type="button"
                                  className={style.removeButton}>
                                  <FontAwesomeIcon icon={'minus-circle'} key={index} />
                                </button>
                              </Col>
                              <Col xs={10} md={6}>
                                {classEnrollment.sectionName} ({classEnrollment.role})
                              </Col>
                            </Row>
                          ))}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                />
                <Row>
                  <Col md={2} />
                  <Col md={4}>
                    <label className={cx(sendEmailLabelClasses)}>
                      <Field
                        name="sendPasswordResetEmail"
                        component="input"
                        type="checkbox"
                        disabled={values.subscriptions.length <= 0}
                        checked={values.subscriptions.length > 0 && values.sendPasswordResetEmail}
                      />
                      Send password reset email
                    </label>
                    &nbsp;
                    <Tooltip text="When checked, an email will be sent to the user with a link that will allow them to reset their password. A subscription must be given to the user to send this email." />
                  </Col>
                </Row>
                <Row>
                  <Col md={2} />
                  <Col md={3}>
                    <label className={cx(sendEmailLabelClasses)}>
                      <Field
                        name="sendWelcomeEmails"
                        component="input"
                        type="checkbox"
                        disabled={values.subscriptions.length <= 0}
                        checked={values.subscriptions.length > 0 && values.sendWelcomeEmails}
                      />
                      Send welcome emails
                    </label>
                    &nbsp;
                    <Tooltip text="A subscription must be given to the user to send this email." />
                  </Col>
                </Row>
                {this.state.showCreateMessage && this.renderCreateMessage()}
                <Row>
                  <Col md={2} />
                  <Col xs={4} md={1}>
                    <PrimaryButton
                      disabled={isSubmitting}
                      onClick={() => {
                        this.setState({ createAnother: false }, submitForm);
                      }}>
                      Save
                    </PrimaryButton>
                  </Col>
                  <Col xs={10} md={3}>
                    <PrimaryButton
                      disabled={isSubmitting}
                      className={style.saveAndCreateAnother}
                      onClick={() => {
                        this.setState({ createAnother: true }, submitForm);
                      }}>
                      Save and Create Another
                    </PrimaryButton>
                  </Col>
                  <Col xs={6} md={2}>
                    <DefaultButton
                      disabled={isSubmitting}
                      href={adminPaths.users()}
                      className={style.backToList}>
                      Back to List
                    </DefaultButton>
                  </Col>
                </Row>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
  alreadyInArray = (arr, val) => {
    // Using isEqual to perform a deep comparison between objects.
    const valFound = find(arr, function (el) {
      return isEqual(el, val);
    });

    if (valFound) {
      return true;
    }
    return false;
  };

  renderCreateMessage = () => {
    return (
      <PopupBanner
        bsStyle={'info'}
        onSelfDestruct={() => this.setState({ showCreateMessage: false })}
        className={style.popup}>
        User successfully created
      </PopupBanner>
    );
  };

  renderSubscription = (subscription) => {
    const expirationDate = moment(subscription.expirationDate).format('MM/DD/YYYY');
    return `${subscription.planName} (expires ${expirationDate})`;
  };

  createAnotherForm = (actions, prevValues) => {
    const newPassword = generator.generate({
      length: 22,
      numbers: true
    });

    // Only reset some values, keep the rest from previous form submission
    actions.setValues({
      ...prevValues,
      firstName: '',
      lastName: '',
      password: newPassword
    });

    // Set this one separately to remove validation triggering on page reload
    actions.setFieldValue('email', '', false);

    actions.setSubmitting(false);
  };

  onSubmit = (values: UserFormValues, actions) => {
    fetch(paths.api.users(), {
      method: 'POST',
      body: JSON.stringify({
        authenticityToken: this.props.authenticityToken,
        user: values
      })
    })
      .then(() => {
        if (this.state.createAnother) {
          this.setState({ showCreateMessage: true, createAnother: false }, () => {
            this.createAnotherForm(actions, values);
          });
        } else {
          window.location.assign(adminPaths.users());
        }
      })
      .catch((exception) => {
        actions.setSubmitting(false);
        actions.setErrors(exception.errors);
      });
  };
}
