import React from 'react';
import Modal from '@magoosh/lib/modal';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { pluralize } from 'utilities/pluralize';

interface UpdateEnrollmentsProps {
  students: any[];
  classSection;
  reset: () => void;
  unenroll: () => void;
  reenroll: () => void;
}

export const UpdateEnrollmentsModal: React.FC<UpdateEnrollmentsProps> = (props) => {
  const { students, classSection, reset, unenroll, reenroll } = props;

  if (!students || students.length <= 0 || !classSection) {
    return null;
  }
  let clickAction;
  let message;
  let buttonText;

  if (students.length == 1) {
    const student = students[0];
    clickAction = student.enrolled ? unenroll : reenroll;
    message = (
      <>
        <p>
          {student.enrolled ? (
            <>Are you sure you want to unenroll this student from this class?</>
          ) : (
            <>Are you sure you want to re-enroll this student in this class?</>
          )}
        </p>
        <div style={{ marginLeft: '3rem' }}>
          <p>
            Student <br />
            <strong>{student.fullName || student.email}</strong>
          </p>
          <p>
            Class <br />
            <strong>{classSection.displayName || classSection.name}</strong>
          </p>
        </div>
      </>
    );

    buttonText = student.enrolled ? 'Unenroll Student' : 'Re-enroll Student';
  } else {
    clickAction = unenroll;
    message = (
      <p>
        Are you sure you want to unenroll the selected students from{' '}
        <strong>{classSection.displayName || classSection.displayName}</strong>?
      </p>
    );

    buttonText = `Unenroll ${pluralize(props.students.length, 'Student')}`;
  }

  return (
    <Modal show={props.students && props.students.length > 0} onHide={props.reset}>
      {message}
      <Modal.Footer>
        <DefaultButton onClick={props.reset}>Cancel</DefaultButton>
        <SecondaryButton onClick={clickAction}>{buttonText}</SecondaryButton>
      </Modal.Footer>
    </Modal>
  );
};
