import React from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';
import { DateRangePicker } from 'react-date-range';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

export const formatCustomDateRange = (customDateValue: string) => {
  const dateRange = parseCustomDateRange(customDateValue);

  if (customDateValue === 'custom:today') {
    return 'Custom: Today';
  } else if (customDateValue === 'custom:yesterday') {
    return 'Custom: Yesterday';
  } else if (customDateValue === 'custom:this_week') {
    return 'Custom: This Week';
  } else if (customDateValue === 'custom:last_week') {
    return 'Custom: Last Week';
  } else if (customDateValue === 'custom:this_month') {
    return 'Custom: This Month';
  } else if (customDateValue === 'custom:last_month') {
    return 'Custom: Last Month';
  } else {
    return `Custom: ${dateRange.startDate?.format('MMM D')} - ${dateRange.endDate?.format('MMM D')}`
  }
};

export const parseCustomDateRange = (customDateValue: string) => {
  const dateValue = customDateValue.replace('custom:', '');

  if (dateValue === 'today') {
    return { startDate: moment().startOf('day'), endDate: moment().endOf('day') };
  } else if (dateValue === 'yesterday') {
    return { startDate: moment().subtract(1, 'days').startOf('day'), endDate: moment().subtract(1, 'days').endOf('day') };
  } else if (dateValue === 'this_week') {
    return { startDate: moment().startOf('week'), endDate: moment().endOf('week') };
  } else if (dateValue === 'last_week') {
    return { startDate: moment().subtract(1, 'weeks').startOf('week'), endDate: moment().subtract(1, 'weeks').endOf('week') };
  } else if (dateValue === 'this_month') {
    return { startDate: moment().startOf('month'), endDate: moment().endOf('month') };
  } else if (dateValue === 'last_month') {
    return { startDate: moment().subtract(1, 'months').startOf('month'), endDate: moment().subtract(1, 'months').endOf('month') };
  } else {
    const startDate = moment.utc(dateValue.split('|')[0]).local();
    const endDate = moment.utc(dateValue.split('|')[1]).local();

    return {
      startDate: startDate.isValid() ? startDate : null,
      endDate: endDate.isValid() ? endDate : null
    };
  }
};

interface Props {
  onChange: (value: string) => void;
  value: string;
}

const CustomDateRange: React.FC<Props> = (props) => {
  const handleCustomDateChanged = (range) => {
    const startDate = moment(range.selection.startDate).startOf('day');
    const endDate = moment(range.selection.endDate).endOf('day');

    props.onChange(`custom:${startDate.utc().format('YYYY-MM-DD hh:mm:ss')}|${endDate.utc().format('YYYY-MM-DD hh:mm:ss')}`);
  };

  const dateRange = parseCustomDateRange(props.value);

  return (
    <div className={cx(style.customDateRange, 'd-flex flex-column flex-lg-row gap-m')}>
      <div className="d-flex flex-row flex-lg-column flex-wrap gap-s">
        <div
          onClick={() => props.onChange('custom:today')}
          style={{
            backgroundColor: props.value === 'custom:today' ? colors.blue : null,
            borderRadius: props.value === 'custom:today' ? 20 : 0,
            color: props.value === 'custom:today' ? '#ffffff' : colors.grayLight,
            cursor: 'pointer',
            fontSize: 12,
            padding: '3px 10px'
          }}
        >
          <span className={props.value !== 'custom:today' ? 'text-underdash' : null}>
            Today
          </span>
        </div>

        <div
          onClick={() => props.onChange('custom:yesterday')}
          style={{
            backgroundColor: props.value === 'custom:yesterday' ? colors.blue : null,
            borderRadius: 20,
            color: props.value === 'custom:yesterday' ? '#ffffff' : colors.grayLight,
            cursor: 'pointer',
            fontSize: 12,
            padding: '3px 10px'
          }}
        >
          <span className={props.value !== 'custom:yesterday' ? 'text-underdash' : null}>
            Yesterday
          </span>
        </div>

        <div
          onClick={() => props.onChange('custom:this_week')}
          style={{
            backgroundColor: props.value === 'custom:this_week' ? colors.blue : null,
            borderRadius: 20,
            color: props.value === 'custom:this_week' ? '#ffffff' : colors.grayLight,
            cursor: 'pointer',
            fontSize: 12,
            padding: '3px 10px'
          }}
        >
          <span className={props.value !== 'custom:this_week' ? 'text-underdash' : null}>
            This Week
          </span>
        </div>

        <div
          onClick={() => props.onChange('custom:last_week')}
          style={{
            backgroundColor: props.value === 'custom:last_week' ? colors.blue : null,
            borderRadius: 20,
            color: props.value === 'custom:last_week' ? '#ffffff' : colors.grayLight,
            cursor: 'pointer',
            fontSize: 12,
            padding: '3px 10px'
          }}
        >
          <span className={props.value !== 'custom:last_week' ? 'text-underdash' : null}>
            Last Week
          </span>
        </div>

        <div
          onClick={() => props.onChange('custom:this_month')}
          style={{
            backgroundColor: props.value === 'custom:this_month' ? colors.blue : null,
            borderRadius: 20,
            color: props.value === 'custom:this_month' ? '#ffffff' : colors.grayLight,
            cursor: 'pointer',
            fontSize: 12,
            padding: '3px 10px'
          }}
        >
          <span className={props.value !== 'custom:this_month' ? 'text-underdash' : null}>
            This Month
          </span>
        </div>

        <div
          onClick={() => props.onChange('custom:last_month')}
          style={{
            backgroundColor: props.value === 'custom:last_month' ? colors.blue : null,
            borderRadius: 20,
            color: props.value === 'custom:last_month' ? '#ffffff' : colors.grayLight,
            cursor: 'pointer',
            fontSize: 12,
            padding: '3px 10px'
          }}
        >
          <span className={props.value !== 'custom:last_month' ? 'text-underdash' : null}>
            Last Month
          </span>
        </div>
      </div>

      <DateRangePicker
        rangeColors={[colors.blue]}
        dateDisplayFormat="P"
        inputRanges={[]}
        onChange={handleCustomDateChanged}
        ranges={[{
          startDate: dateRange.startDate?.toDate(),
          endDate: dateRange.endDate?.toDate(),
          key: 'selection'
        }]}
        showDateDisplay={false}
        staticRanges={[]}
      />
    </div>
  );
};

export default CustomDateRange;
