import React, { useState } from 'react';
import style from './style.module.scss';
import DismissibleBanner from '@magoosh/lib/dismissible_banner';
import CheckpointCard from '@magoosh/gre/organisms/checkpoint_card';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { DefaultButton, LinkButton, SecondaryButton } from '@magoosh/lib/button';
import cx from 'classnames';
import { PremiumBadge } from '@magoosh/lib/info_badges';
import { ModalProvider, useModalContext } from '@magoosh/context/modal_context';
import moment from 'moment';
import { paths } from 'config/path_helpers';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import { pluralize } from 'utilities/pluralize';

export const OfficialQuestions: React.FC<OfficialQuestionsProps> = (props) => {
  const { setModal } = useModalContext();
  const { examSections } = props;

  const handleSelectPracticeSection = (
    examSectionName: string,
    practiceSection: PracticeSection,
    verb: string
  ) => {
    setModal({
      header: `${examSectionName} ${practiceSection.name}`,
      body: <DialogBody practiceSection={practiceSection} verb={verb} onClose={() => setModal(null)} />
    });
  };

  return (
    <div className={style.officialQuestionsPage}>
      <div className={style.main}>
        <h2>
          <strong>Official Questions</strong>
        </h2>
        <p>
          These practice sections are composed of licensed GRE® test items from ETS and additional
          explanations and sample essays developed by Magoosh.
        </p>
        <p>
          Official questions are the gold standard for test-accuracy, so they’re most useful for testing your
          knowledge and skills, gauging your progress, and determining where you still have room to improve.
          When you finish each section you will get a report of your strengths and weaknesses, along with
          expert explanations for each question.
        </p>
        {props.locked && (
          <div className={cx(style.upgradeBanner, 'alert alert-banner')}>
            <div>
              <FontAwesomeIcon icon="info-circle" className="fa-2x" />
            </div>
            <div>
              <div>
                Upgrade to premium to access all official practice sections plus the full set of Magoosh
                lessons, questions, and practice tests.
              </div>
              <div>
                <DefaultButton href="/plans" className={style.upgradeNowButton}>
                  Upgrade Now
                </DefaultButton>
              </div>
            </div>
          </div>
        )}
        {!props.locked && props.showRecommendationBanner && (
          <DismissibleBanner bsStyle="info" slug="gre-official-questions-recommendation">
            We recommend taking a practice section from this page intermittently leading up to your test day
            to apply what you’ve learned with Magoosh. Practice sections are most useful to students who are
            midway through their studies or are approaching their test date.
          </DismissibleBanner>
        )}
        {examSections &&
          examSections.map((examSection) => {
            let primaryCTAIsAlreadyRendered = false;

            return (
              <div key={examSection.name}>
                <h3>
                  <strong>{examSection.name}</strong>
                </h3>
                {examSection.practiceSections &&
                  examSection.practiceSections.map((practiceSection) => {
                    let iconType;
                    let secondaryCTA = null;
                    let primaryCTA: any = {
                      isDisabled: practiceSection.locked
                    };
                    let sampleResponseLink = null;

                    const { mostRecentMockTestAttempt } = practiceSection;

                    if (mostRecentMockTestAttempt.id && !mostRecentMockTestAttempt.isRunning) {
                      iconType = 'MedalSuccess';
                      primaryCTA.variant = 'default';
                      primaryCTA.text = 'Review';
                      primaryCTA.href = paths.practiceTest(mostRecentMockTestAttempt.id);
                      secondaryCTA = practiceSection.locked ? null : (
                        <LinkButton
                          className="text-gray"
                          onClick={() =>
                            handleSelectPracticeSection(examSection.name, practiceSection, 'Retake')
                          }>
                          Retake Section
                        </LinkButton>
                      );
                    } else if (mostRecentMockTestAttempt.id && mostRecentMockTestAttempt.isRunning) {
                      iconType = 'Medal';
                      primaryCTA.variant = 'secondary';
                      primaryCTA.text = 'Continue';
                      primaryCTA.onClick = () =>
                        handleSelectPracticeSection(examSection.name, practiceSection, primaryCTA.text);
                    } else {
                      iconType = 'Medal';
                      primaryCTA.isDisabled = practiceSection.locked;
                      primaryCTA.text = 'Start';
                      primaryCTA.onClick = () =>
                        handleSelectPracticeSection(examSection.name, practiceSection, primaryCTA.text);

                      if (!primaryCTAIsAlreadyRendered && !practiceSection.locked) {
                        primaryCTAIsAlreadyRendered = true;
                        primaryCTA.variant = 'secondary';
                      } else {
                        primaryCTA.variant = 'default';
                      }
                    }

                    if (practiceSection.sampleResponses && practiceSection.mostRecentMockTestAttempt.id) {
                      sampleResponseLink = (
                        <LinkButton
                          className={'text-gray u-margin-L-xs'}
                          href={paths.sampleResponses(practiceSection.sampleResponses.promptId)}>
                          See{' '}
                          {pluralize(
                            practiceSection.sampleResponses.responseCount,
                            'sample essay',
                            'sample essays'
                          )}{' '}
                          with scores and commentary
                        </LinkButton>
                      );
                    }
                    const headline = practiceSection.difficulty
                      ? `${practiceSection.name}: ${practiceSection.difficulty} Difficulty`
                      : practiceSection.name;

                    return (
                      <div key={practiceSection.name} className={style.sectionCard}>
                        <CheckpointCard
                          iconType={iconType}
                          headline={headline}
                          estimatedTime={`${pluralize(
                            practiceSection.promptsCount,
                            'question',
                            'questions'
                          )}, ${moment
                            .duration(practiceSection.timeLimitSeconds, 'seconds')
                            .asMinutes()} minutes`}
                          primaryCTA={primaryCTA}
                          secondaryCTA={secondaryCTA}
                          supplementaryLink={sampleResponseLink}
                          locked={practiceSection.locked}
                          lockedBadge={<PremiumBadge className="u-margin-H-xs pull-right" />}
                        />
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
      <div className={style.sidebar}>
        <h4>More Official Practice</h4>
        <div className={style.section}>
          <div className={style.info}>
            <small>
              Additional official practice, including five full length practice tests, is available for free
              and for purchase from ETS.
            </small>
          </div>
          <div>
            <a
              href="https://ereg.ets.org/ereg/public/testPrep/viewtestPreparation?_p=GRI"
              target="_blank"
              rel="noopener noreferrer">
              View
            </a>
          </div>
        </div>
        <h4>Essay Topics and Sample Responses</h4>
        <div className={style.section}>
          <div className={style.info}>
            <div>Sample Issue Task Essays</div>
            <small>Sample essays at each score level, 1 through 6.</small>
          </div>
          <div>
            <a
              href="https://www.ets.org/content/dam/ets-org/pdfs/gre/sample-issue-task.pdf"
              target="_blank"
              rel="noopener noreferrer">
              View
            </a>
          </div>
        </div>
        <div className={style.section}>
          <div className={style.info}>
            <div>Issue Task Topic Pool</div>
            <small>Your essay prompt on the GRE will be one from this pool.</small>
          </div>
          <div>
            <a
              href="https://www.ets.org/content/dam/ets-org/pdfs/gre/issue-pool.pdf"
              target="_blank"
              rel="noopener noreferrer">
              View
            </a>
          </div>
        </div>
        <h4>Official Guide Explanations</h4>
        <div className={style.section}>
          <div className={style.info}>
            <small>
              Video explanations for all practice questions in ETS' Official Guide to the GRE (1st, 2nd, or
              3rd eds.)
            </small>
          </div>
          <div>
            <a href="https://gre.magoosh.com/gre-og-explanations" target="_blank" rel="noopener noreferrer">
              View
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const DialogBody: React.FC<DialogBodyProps> = (props) => {
  const authToken = useAuthenticityToken();
  const [submitting, setSubmitting] = useState(false);
  const { practiceSection, verb, onClose } = props;

  const onSubmit = () => {
    setSubmitting(true);
  };

  return (
    <form action={paths.practiceTests()} method="POST" onSubmit={onSubmit}>
      <input type="hidden" name="authenticity_token" value={authToken} />
      <input type="hidden" name="mock_test[template_id]" value={practiceSection.mockTestTemplateId} />
      <p>
        This section is timed, and includes&nbsp;
        <strong>
          {pluralize(practiceSection.promptsCount, 'question', 'questions')} to be answered within&nbsp;
          {moment.duration(practiceSection.timeLimitSeconds, 'seconds').asMinutes()} minutes
        </strong>
        .
      </p>
      <p>
        Just like with the real test, you should have a pen or pencil and scratch paper to write notes and
        work out problems. An on-screen calculator is provided for the practice sections where one is allowed.
      </p>
      <p>
        <strong>You cannot pause within a section</strong>. Once you start a section, you&apos;ll need to
        finish it because the timer will keep running. This will help you practice your pacing and build your
        test-taking stamina, which you&apos;ll need on test day.
      </p>
      <div className={style.modalButtons}>
        <SecondaryButton type="submit" submitting={submitting}>
          {verb} Section
        </SecondaryButton>
        <DefaultButton onClick={onClose}>Cancel</DefaultButton>
      </div>
    </form>
  );
};

const OfficialQuestionsWithProvider: React.FC<OfficialQuestionsProps> = (props) => {
  return (
    <ModalProvider>
      <OfficialQuestions {...props} />
    </ModalProvider>
  );
};

export default OfficialQuestionsWithProvider;
