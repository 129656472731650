import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { OverlayTrigger, Tooltip as ReactToolTip } from 'react-bootstrap';
import * as React from 'react';

interface TooltipProps {
  id?: string;
  text: string;
}

export default class CustomTooltip extends React.Component<TooltipProps> {
  render() {
    return (
      <OverlayTrigger
        trigger="click"
        placement="right"
        overlay={<ReactToolTip id={this.props.id}>{this.props.text}</ReactToolTip>}>
        <span>
          <FontAwesomeIcon icon="question-circle" />
        </span>
      </OverlayTrigger>
    );
  }
}
