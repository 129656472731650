import React from 'react';
import { BulkUploadFormTabProps, BulkUploadFormTabValues } from './types';
import style from '../../style.module.scss';
import cx from 'classnames';
import { DefaultButton } from '@magoosh/lib/button';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import FileUploadField from '@magoosh/b2b/app/lib/file_upload_field';
import { paths } from 'config/path_helpers';

const initialValues = {
  uploadedFiles: []
};

const validationSchema = yup.object({
  uploadedFiles: yup
    .array()
    .nullable()
    .required('You must add at least one valid file to add students into the enrollment list.')
});

export const BulkUploadFormTab: React.FC<BulkUploadFormTabProps> = (props) => {
  const { bulkStudentsUpload } = props;

  const handleOnSubmit = (
    values: BulkUploadFormTabValues,
    { resetForm }: FormikActions<BulkUploadFormTabValues>
  ): void => {
    bulkStudentsUpload(values.uploadedFiles);
    resetForm();
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
        {({ submitForm }) => (
          <>
            <p>
              <small>Upload a file (.txt or .csv) with a list of emails.</small>
            </p>
            <FileUploadField
              name="uploadedFiles"
              label="Drag a csv/text file with a list of your students."
              fileType=".csv, .txt"
              processFiles={bulkStudentsUpload}
            />
            <div className={cx(style.tabActions, style.spaced)}>
              <a style={{ color: style.grayDarker }} href={paths.api.partner.enrollStudentsUploadTemplate()}>
                <FontAwesomeIcon icon="download" /> <strong>Download File Template</strong>
              </a>
              <DefaultButton onClick={submitForm}>
                <FontAwesomeIcon icon="plus-square" /> Upload Students
              </DefaultButton>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default BulkUploadFormTab;
