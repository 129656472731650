import * as React from 'react';
import { WritingExample } from '@magoosh/admin/writing_examples/types';
import { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import { PrimaryButton } from '@magoosh/lib/button';
import style from './style.module.scss';
import Multiselect from 'react-widgets/lib/Multiselect';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { FieldDropdownList } from '@magoosh/lib/formik_inputs/field_dropdown_list';

interface FormProps {
  writingExample?: WritingExample;
  onSuccess?: () => void;
}

interface WritingExampleFormValues {
  prompt: string;
  applicantProfile: string;
  title: string;
  document: string;
  documentType: string;
  accessLevel?: number;
  lessonId?: number | string;
  brandingIds: number[];
}

const documentTypes = ['Statement of Purpose', 'Personal Statement', 'Resume', 'CV', 'Other'];

const AdminWritingExampleForm: React.FC<FormProps> = (props) => {
  const [brandingOptions, setBrandingOptions] = useState([]);
  const [subscriptionLevelOptions, setSubscriptionLevelOptions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(adminPaths.api.brandings()).then((response) => {
      setBrandingOptions(response);
    });
    fetch(adminPaths.api.subscriptionLevels()).then((response) => {
      setSubscriptionLevelOptions(response);
    });
  }, []);

  const newExample: () => WritingExample = () => {
    return {
      id: null,
      title: '',
      prompt: '',
      applicantProfile: '',
      document: '',
      documentType: 'Statement of Purpose',
      accessLevel: {
        level: 100,
        name: 'Draft'
      },
      brandings: []
    };
  };

  const [writingExample, setWritingExample] = useState(props.writingExample || newExample);
  const [showBrandingOptions, setShowBrandingOptions] = useState(false);
  const [chosenBrandings, setChosenBrandings] = useState(writingExample.brandings);

  const initialValues: WritingExampleFormValues = {
    prompt: writingExample.prompt || '',
    applicantProfile: writingExample.applicantProfile || '',
    title: writingExample.title,
    document: writingExample.document || '',
    documentType: writingExample.documentType || documentTypes[0],
    lessonId: writingExample.lesson?.id || '',
    accessLevel: writingExample.accessLevel.level,
    brandingIds: writingExample.brandings.map((branding) => branding.id)
  };

  const onSubmitForm = (
    values: WritingExampleFormValues,
    { setSubmitting, setErrors }: FormikHelpers<WritingExampleFormValues>
  ) => {
    setSubmitting(true);

    const formData = new FormData();
    const path = writingExample.id
      ? adminPaths.api.writingExample(writingExample.id)
      : adminPaths.api.writingExamples();

    fetch(path, {
      method: writingExample.id ? 'PATCH' : 'POST',
      body: JSON.stringify(values)
    })
      .then((response) => {
        if (props.onSuccess) {
          props.onSuccess();
        } else {
          window.location.assign(adminPaths.writingExample(response.id));
        }
      })
      .catch((response) => {
        if (response.errors) {
          setErrors(response.errors);
        } else if (response.error) {
          setError(response.error);
        } else {
          alert('Something went wrong! Try again, or check with an engineer.');
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={style.form}>
      <Formik initialValues={initialValues} onSubmit={onSubmitForm}>
        {({ errors, isSubmitting, submitForm, setFieldValue }) => (
          <Form>
            <div>
              <FieldInput name="title" type="input" />
              <FieldInput name="prompt" type="textarea" />
              <FieldInput name="applicantProfile" type="textarea" label="Applicant Profile" />
              <FieldInput name="document" type="textarea" />
              {writingExample.evaluation && (
                <p>
                  <strong>
                    Changing the document after annotations have been added will most likely corrupt highlight
                    placements. You will probably have to re-upload the annotation CSV.
                  </strong>
                </p>
              )}
              <FieldDropdownList
                name="documentType"
                label="Document Type"
                defaultValue={initialValues.documentType}
                data={documentTypes}
              />
              <FieldInput name="lessonId" type="input" label="Lesson ID" />
              <FieldDropdownList
                name="accessLevel"
                label="Access Level"
                defaultValue={subscriptionLevelOptions[0]}
                data={subscriptionLevelOptions}
                valueField={'level'}
                textField={'name'}
              />
              <label>Brandings</label>
              <Multiselect
                data={brandingOptions}
                filter="contains"
                valueField="id"
                textField="name"
                onChange={(value) => {
                  setChosenBrandings(value);
                  setFieldValue(
                    'brandingIds',
                    value.map((branding) => branding.id)
                  );
                }}
                onSelect={() => setShowBrandingOptions(true)}
                onToggle={() => setShowBrandingOptions(!showBrandingOptions)}
                open={showBrandingOptions}
                value={chosenBrandings}
              />

              {error && <p className={style.error}>{error}</p>}
              <PrimaryButton
                className="u-margin-T-m"
                type="submit"
                onClick={submitForm}
                disabled={isSubmitting}>
                Submit
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdminWritingExampleForm;
