import React from 'react';

interface Props {
  children: React.ReactNode;
  name?: string;
  navigable?: boolean;
}

const Step: React.FC<Props> = (props) => {
  return (
    <>{props.children}</>
  );
};

export default Step;
