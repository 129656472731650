import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { capitalize, without } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useModalContext } from '@magoosh/context/modal_context';
import { DangerButton, DefaultFilledButton, PrimaryButton } from '@magoosh/lib/button';
import Draggable from '@magoosh/lib/draggable';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import TextPreview from './text_preview';
import RequestEditsModal from './request_edits_modal';
import { DraftPrompt, PromptRequest } from './types';

interface Props {
  draftPrompt: DraftPrompt;
  onChange?: (draftPrompt: DraftPrompt) => void;
  promptRequest: PromptRequest;
  readOnly?: boolean;
}

const DraftPromptPreview: React.FC<Props> = (props) => {
  const { setModal } = useModalContext();
  const [isEditing, setIsEditing] = useState(false);

  const accept = () => {
    const path = adminPaths.api.promptRequestDraftPromptAccept(
      props.promptRequest.id,
      props.draftPrompt.id
    );

    fetch(path, {
      method: 'POST',
    }).then((response) => {
      props.onChange && props.onChange(response.draftPrompt);
    });
  };

  const answerChoiceGroups = () => {
    // Some older data if just a single top-level set of answer choices.
    // Convert to the nested array format.
    const answerChoices = props.draftPrompt.content.answerChoices;

    if (!answerChoices) {
      return [];
    } else if (Array.isArray(answerChoices[0])) {
      return answerChoices;
    } else {
      return [answerChoices];
    }
  };

  const handleAnswerChoiceAdded = (groupIndex: number, setFieldValue, values) => {
    const updatedAnswerChoiceGroup = [
      ...values.answerChoices[groupIndex],
      { correct: false, value: ''}
    ];

    const result = [...values.answerChoices];
    result[groupIndex] = updatedAnswerChoiceGroup;

    setFieldValue('answerChoices', result);
  };

  const handleAnswerChoiceOrderChanged = (groupIndex: number, fromIndex: number, toIndex: number, setFieldValue, values) => {
    const answerChoiceGroup = values.answerChoices[groupIndex];
    const answerChoice = answerChoiceGroup[fromIndex];
    const updatedAnswerChoices = without(answerChoiceGroup, answerChoice);
    const updatedAnswerChoiceGroup = [
      ...updatedAnswerChoices.slice(0, toIndex),
      answerChoice,
      ...updatedAnswerChoices.slice(toIndex)
    ];

    const result = [...values.answerChoices];
    result[groupIndex] = updatedAnswerChoiceGroup;

    setFieldValue('answerChoices', result);
  };

  const handleAnswerChoiceRemoved = (groupIndex: number, index: number, setFieldValue, values) => {
    const answerChoiceGroup = values.answerChoices[groupIndex];
    const answerChoice = answerChoiceGroup[index];
    const updatedAnswerChoices = without(answerChoiceGroup, answerChoice);

    const result = [...values.answerChoices];
    result[groupIndex] = updatedAnswerChoices;

    setFieldValue('answerChoices', result);
  };

  const handleSubmit = (values, { setErrors, setSubmitting }) => {
    setSubmitting(true)

    fetch(adminPaths.api.promptRequestContentRequestCreate(props.promptRequest.id), {
      method: 'POST',
      body: JSON.stringify({
        contentRequest: {
          content: values,
          contentType: 'manual_edit',
          draftPromptId: props.draftPrompt.id,
          status: 'finished'
        }
      })
    }).then((response) => {
      setIsEditing(false);
      setSubmitting(false);

      const updatedDraftPrompt = {
        ...props.draftPrompt,
        content: {
          ...props.draftPrompt.content,
          ...values
        }
      };

      props.onChange(updatedDraftPrompt);
    }).catch((error) => {
      setErrors(error.errors);
      setSubmitting(false);
    });
  };

  const isEditable = () => {
    return !props.draftPrompt.promptId && !props.readOnly && props.promptRequest.status === 'validating';
  };

  const maybe = () => {
    const path = adminPaths.api.promptRequestDraftPromptMaybe(
      props.promptRequest.id,
      props.draftPrompt.id
    );

    fetch(path, {
      method: 'POST',
    }).then((response) => {
      props.onChange && props.onChange(response.draftPrompt);
    });
  };

  const presentRequestEditsModal = () => {
    setModal({
      header: 'Request Edits',
      body: <RequestEditsModal
        draftPrompt={props.draftPrompt}
        onAccept={props.onChange}
        onClose={() => setModal(null)}
        promptRequest={props.promptRequest}
      />,
      size: 'lg'
    });
  };

  const promptContentPreview = () => {
    if (!props.draftPrompt.content.prompt) return null;

    if (props.draftPrompt.questionType === 'Math - Quantitative Comparison' || props.draftPrompt.content.prompt.match(/(Quantity|Column) A/)) {
      try {
        const promptMatch = props.draftPrompt.content.prompt.match(/([\s\S]+?)(Quantity|Column) A/);
        const promptContent = promptMatch ? promptMatch[1].trim() : '';
        const quantityA = props.draftPrompt.content.prompt.match(/(Quantity|Column) A([:\s]*)([\s\S]+?)(Quantity|Column) B/)[3].trim();
        const quantityB = props.draftPrompt.content.prompt.match(/(Quantity|Column) B([:\s]*)([\s\S]+)/)[3].trim();

        return (
          <div>
            <TextPreview text={promptContent} />

            <table className="table u-margin-T-s">
              <tbody>
                <tr>
                  <th>Quantity A</th>
                  <th>Quantity B</th>
                </tr>

                <tr>
                  <td><TextPreview text={quantityA} /></td>
                  <td><TextPreview text={quantityB} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } catch {
        return <TextPreview text={props.draftPrompt.content.prompt} />;
      }
    } else {
      return <TextPreview text={props.draftPrompt.content.prompt} />;
    }
  };

  const reject = () => {
    const path = adminPaths.api.promptRequestDraftPromptReject(
      props.promptRequest.id,
      props.draftPrompt.id
    );

    fetch(path, {
      method: 'POST',
    }).then((response) => {
      props.onChange && props.onChange(response.draftPrompt);
    });
  };

  return (
    <Formik enableReinitialize initialValues={{ ...props.draftPrompt.content, answerChoices: answerChoiceGroups() }} onSubmit={handleSubmit}>
      {({ dirty, errors, isSubmitting, resetForm, setFieldValue, submitForm , values}) => (
        <Form>
          <div className="d-flex flex-column gap-l well well-hollow">
            <div className="align-items-center d-flex gap-s">
              <div className="flex-1">
                {
                  props.draftPrompt.promptId ? (
                    <div>
                      <a href={props.draftPrompt.promptType === 'Prompt' ? adminPaths.promptEdit(props.draftPrompt.promptId) : adminPaths.aiPromptEdit(props.draftPrompt.promptId)}>
                        <strong>Prompt</strong> <small>({props.draftPrompt.id})</small>
                      </a>
                    </div>
                  ) : (
                    <div><strong>Prompt</strong> <small>({props.draftPrompt.id})</small></div>
                  )
                }
              </div>

              <div>
                <a className="text-gray-light" onClick={accept}>Accept</a>
                {' | '}
                <a className="text-gray-light" onClick={maybe}>Maybe</a>
                {' | '}
                <a className="text-gray-light" onClick={reject}>Reject</a>
              </div>

              <div className="text-right" style={{ minWidth: 100 }}>
                {(() => {
                  if (props.draftPrompt.status === 'accepted') {
                    return <div className="label label-success">Accepted</div>
                  } else if (props.draftPrompt.status === 'rejected') {
                    return <div className="label label-danger">Rejected</div>
                  } else {
                    return <div className="label bg-gray-light">{capitalize(props.draftPrompt.status)}</div>
                  }
                })()}
              </div>

              {
                props.draftPrompt.content.audit && (
                  <div>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${props.draftPrompt.id}`}>
                          {props.draftPrompt.content.audit.failureReasons.join('\n\n')}
                        </Tooltip>
                      }
                    >
                      <div className={`label label-${props.draftPrompt.content.audit.pass ? 'success' : 'danger'}`}>
                        {props.draftPrompt.content.audit.pass ? 'Pass' : 'Fail'}
                      </div>
                    </OverlayTrigger>
                  </div>
                )
              }

              {
                isEditable() && (
                  isEditing ? (
                    <>
                      <PrimaryButton onClick={submitForm} size="sm" submitting={isSubmitting}>
                        Save Edits
                      </PrimaryButton>
                      <DangerButton onClick={() => { resetForm(); setIsEditing(false); }} size="sm">
                        Discard Edits
                      </DangerButton>
                    </>

                  ) : (
                    <>
                      <DefaultFilledButton onClick={presentRequestEditsModal} size="sm">
                        Request Edits
                      </DefaultFilledButton>
                      <DefaultFilledButton onClick={() => setIsEditing(true)} size="sm">
                        Manual Edits
                      </DefaultFilledButton>
                    </>
                  )
                )
              }
            </div>

            {
              props.draftPrompt.content.context && (
                <div>
                  <strong>Context</strong>
                  <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {JSON.stringify(props.draftPrompt.content.context, null, 2)}
                  </pre>
                </div>
              )
            }

            <div className="d-flex gap-m">
              <div className="d-flex flex-1 flex-column gap-s">
                <div className="well well-highlight">
                  {
                    isEditing ? (
                      <FieldInput name="prompt" type="textarea" style={{ height: 200 }} />
                    ) : (
                      promptContentPreview()
                    )
                  }
                </div>
              </div>

              <div className="d-flex flex-1 flex-column gap-s">
                <div><strong>Answer Choices</strong></div>

                <div className="d-flex flex-1 gap-m">
                  {
                    values.answerChoices.map((answerChoiceGroup, groupIdx) => (
                      <div key={groupIdx} className="d-flex flex-1 flex-column gap-s">
                        <Draggable
                          onDrop={(fromIndex, toIndex) => {
                            handleAnswerChoiceOrderChanged(groupIdx, fromIndex, toIndex, setFieldValue, values)
                          }}
                        >
                          {
                            answerChoiceGroup.map((answerChoice, idx) => (
                              <div key={idx}>
                                <div className="align-items-center d-flex gap-xs well well-hollow">
                                  {isEditing && <FontAwesomeIcon className="text-gray-light" icon="bars" style={{ cursor: 'grab' }} />}

                                  <div className="align-items-center d-flex flex-1 gap-xs">
                                    {
                                      isEditing ? (
                                        <>
                                          <div style={{ minWidth: 10 }}>
                                            <FieldInput hideLabel name={`answerChoices[${groupIdx}][${idx}].correct`} type="checkbox" />
                                          </div>
                                          <div className="flex-1">
                                            <FieldInput hideLabel name={`answerChoices[${groupIdx}][${idx}].value`} type="text" />
                                          </div>
                                          <a onClick={() => handleAnswerChoiceRemoved(groupIdx, idx, setFieldValue, values)}>
                                            <FontAwesomeIcon className="text-danger" icon="minus-circle" />
                                          </a>
                                        </>
                                      ) : (
                                        <>
                                          <div style={{ minWidth: 10 }}>
                                            {answerChoice.correct && <FontAwesomeIcon className="text-primary" icon="check" />}
                                          </div>
                                          <TextPreview text={answerChoice.value} />
                                        </>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            ))
                          }

                          {
                            isEditing && (
                              <a onClick={() => handleAnswerChoiceAdded(groupIdx, setFieldValue, values)}>
                                <FontAwesomeIcon className="text-success" icon="plus-circle" /> Add
                              </a>
                            )
                          }
                        </Draggable>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            {
              props.draftPrompt.content.textExplanation && (
                <div className="d-flex flex-1 flex-column gap-s">
                  <div><strong>Text Explanation</strong></div>
                  {
                    isEditing ? (
                      <FieldInput name="textExplanation" type="textarea" style={{ height: 400 }} />
                    ) : (
                      <>
                        {
                          props.draftPrompt.content.textExplanation.indexOf('Key Takeaway') === -1 && props.draftPrompt.content.context?.concept?.description && (
                            <TextPreview text={`<p><u>Key Takeaway:</u> ${props.draftPrompt.content.context?.concept?.description}</p><hr class="u-margin-V-n" />`} />
                          )
                        }
                        <TextPreview text={props.draftPrompt.content.textExplanation}/>
                      </>
                    )
                  }
                </div>
              )
            }
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DraftPromptPreview;
