import * as React from 'react';
import { StudyScheduleData } from '@magoosh/organisms/study_schedule/types';
import { PrimaryButton } from '@magoosh/lib/button';
import cx from 'classnames';
import style from 'react/organisms/study_schedule/select_schedule/style.module.scss';

interface Props {
  examName: string;
  onSkip: () => void;
  onSelect: (id: number) => any;
  schedules: StudyScheduleData[];
}

const IntroduceLSATStudySchedule: React.FC<Props> = (props) => {
  return (
    <div className={style.stepContent}>
      <div>
        <h4 className={cx('u-margin-V-xxs')}>
          <strong>Welcome to Magoosh {props.examName}!</strong>
        </h4>
        <div className="u-margin-T-s">
          <p>
            We recommend following our 30-Hour LSAT Essentials study schedule. In this study plan, you’ll
            start with quick wins to add points fast, then focus on the concepts and question types that show
            up the most. It’s been designed to work well as a complete study plan for folks who are short on
            time, or as a starter plan for those who will be studying longer.
          </p>
          <p>
            Learn more details about 30-Hour LSAT Essentials in{' '}
            <a href="/lessons/5975" target="_blank">
              this video
            </a>
            , or see other LSAT study schedules{' '}
            <a href="/resources" target="_blank">
              here
            </a>
            .
          </p>
        </div>
      </div>
      <div className="text-center">
        <div className="u-margin-V-xl">
          <PrimaryButton
            className={'btn-multi-line'}
            block
            onClick={() => props.onSelect(props.schedules[0].id)}>
            Start Your Study Schedule
          </PrimaryButton>
        </div>
        <a className="text-gray-light" onClick={props.onSkip}>
          No thanks, I'll study on my own instead
        </a>
      </div>
    </div>
  );
};

export default IntroduceLSATStudySchedule;
