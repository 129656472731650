import React from 'react';
import { paths } from 'config/path_helpers';
import { CheckpointCardProps } from '@magoosh/gre/organisms/checkpoint_card/types';
import { Checkpoint } from './types';
import CheckpointCard from '@magoosh/gre/organisms/checkpoint_card';

interface Props {
  checkpoint: Checkpoint;
}

const PracticeTestCheckpoint: React.FC<Props> = ({ checkpoint }) => { 
  const checkpointProps: CheckpointCardProps = {
    children: 'Practice your pacing and stamina and get a snapshot of your strengths and weaknesses. Plan to take at least two practice tests, with the last one at least one week prior to your test date.',
    estimatedTime: '2 Hours (Scored)',
    headline: checkpoint.displayName,
    iconType: 'MultipleStars',
    locked: checkpoint.locked,
    primaryCTA: null,
  };

  const checkpointIsComplete = checkpoint.mockTestId && !checkpoint.running;
  const checkpointIsInProgress = checkpoint.running;
  const checkpointIsAccessLocked = checkpoint.locked;

  if (checkpointIsComplete) {
    checkpointProps.children = null;
    checkpointProps.primaryCTA = {
      text: 'Review',
      variant: 'default',
      href: paths.practiceTest(checkpoint.mockTestId)
    };
  } else if (checkpointIsInProgress) {
    checkpointProps.primaryCTA = {
      text: 'Continue',
      variant: 'default',
      href: paths.practiceTest(checkpoint.mockTestId)
    };
  } else if (checkpointIsAccessLocked) {
    checkpointProps.primaryCTA = {
      text: 'Preview',
      variant: 'default',
      href: paths.startPracticeTest()
    };
  } else {
    checkpointProps.primaryCTA = {
      text: 'Start',
      variant: 'default',
      href: paths.startPracticeTest()
    };
  }

  return (
    <CheckpointCard {...checkpointProps} />
  );
};

export default PracticeTestCheckpoint;
