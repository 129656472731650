import * as React from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { capitalize } from 'lodash';
import { CustomButton } from '@magoosh/lib/button';
import BaseButton from '@magoosh/lib/button/base';
import style from '@magoosh/lib/formik_inputs/field_input/style.module.scss';
import { FormFieldOption } from '@magoosh/lib/formik_inputs/types';
import cx from 'classnames';

export interface FieldButtonGroupProps {
  label?: string;
  name: string;
  options: FormFieldOption[];
  variant?: 'secondary' | 'dark';
  onChangeCondition?: (newValue: string | boolean | number) => void;
}

export const FieldButtonGroup: React.FC<FieldButtonGroupProps> = (props) => {
  return <Field component={ButtonGroup} {...props} />;
};

const ButtonGroup: React.FC<FieldButtonGroupProps & FieldProps> = ({
  field,
  form: { setFieldValue },
  ...props
}) => {
  return (
    <div>
      {props.label && (
        <>
          <label htmlFor={field.name}>{capitalize(props.label)}</label>
          <br />
        </>
      )}
      {props.options.map((option) => (
        <span key={option.value}>
          {field.value === option.value ? (
            <BaseButton
              disabled={props.variant === 'dark' ? true : false}
              bsClass={props.variant === 'dark' ? 'default' : 'secondary'}
              bsStyle={props.variant === 'dark' ? 'default' : 'secondary'}
              size="lg"
              className={cx(props.variant === 'dark' && style.borderlessDarkButtonGroup, 'u-margin-R-xs btn')}
              onClick={() => {
                props.onChangeCondition && props.onChangeCondition(option.value);
                setFieldValue(field.name, option.value);
              }}>
              {option.displayName}
            </BaseButton>
          ) : (
            <CustomButton
              className={cx(style.borderlessDarkButton, 'btn', 'u-margin-R-xxs')}
              bsClass={'default'}
              bsStyle={'default'}
              size="lg"
              onClick={() => {
                props.onChangeCondition && props.onChangeCondition(option.value);
                setFieldValue(field.name, option.value);
              }}>
              {option.displayName}
            </CustomButton>
          )}
        </span>
      ))}
      <div className={style.errorMessage}>
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};

export default FieldButtonGroup;
