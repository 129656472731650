import { Evaluation } from '@magoosh/pages/essay_review/types';
import * as React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { adminPaths } from 'config/path_helpers';
import Modal from '@magoosh/lib/modal';
import style from '@magoosh/admin/writing_examples/index/style.module.scss';
import { PrimaryButton } from '@magoosh/lib/button';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { FieldDropdownList } from '@magoosh/lib/formik_inputs/field_dropdown_list';

interface EvaluationModalProps {
  show: boolean;
  evaluation?: Evaluation;
  writingExampleId: number;
  onFormSuccess: () => void;
  onClose: () => void;
}

interface EvaluationFormValues {
  text: string;
  evaluableId: number;
  evaluableType: string;
  authorId?: number;
  csv: File;
}

const EvaluationModal: React.FC<EvaluationModalProps> = (props) => {
  const [error, setError] = React.useState<string | null>(null);
  const [authorOptions, setAuthorOptions] = React.useState([]);

  React.useEffect(() => {
    fetch(adminPaths.api.lessonAuthorships()).then((response) => {
      response.json().then((data) => {
        setAuthorOptions(data.lessonAuthorships);
      });
    });
  }, []);

  const initialValues = {
    text: props.evaluation ? props.evaluation.text : '',
    evaluableId: props.writingExampleId,
    evaluableType: 'WritingExample',
    csv: null
  };

  const onSubmit = (
    values: EvaluationFormValues,
    { setSubmitting, setErrors }: FormikHelpers<EvaluationFormValues>
  ) => {
    setSubmitting(true);

    const formData = new FormData();

    formData.append('evaluation[text]', values.text);
    formData.append('evaluation[evaluable_id]', values.evaluableId.toString());
    formData.append('evaluation[evaluable_type]', values.evaluableType);
    if (values.authorId) {
      formData.append('evaluation[author_id]', values.authorId.toString());
    }
    if (values.csv) {
      formData.append('evaluation[annotation_csv]', values.csv);
    }
    const url = props.evaluation
      ? adminPaths.api.evaluation(props.evaluation.id)
      : adminPaths.api.evaluations();
    const method = props.evaluation ? 'PATCH' : 'POST';

    fetch(url, {
      method: method,
      body: formData
    })
      .then((response) => {
        props.onFormSuccess();
      })
      .catch((response) => {
        if (response.errors) {
          setErrors(response.errors);
        } else if (response.error) {
          setError(response.error);
        } else {
          alert('Something went wrong! Try again, or check with an engineer.');
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      show={props.show}
      showCloseButton={true}
      onHide={props.onClose}
      title={`${props.evaluation ? 'Edit' : 'Create'} Evaluation`}>
      <div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
            <form className={style.form} onSubmit={handleSubmit}>
              <FieldInput name="text" label="Text" type="textarea" />
              <p>
                <small>Additional commentary displayed at the end of the passage.</small>
              </p>
              <FieldDropdownList
                name={'authorId'}
                label={'Author'}
                defaultValue={props.evaluation?.author?.id}
                data={authorOptions}
                valueField={'authorId'}
                textField={'authorName'}
              />

              <label>
                Annotation CSV
                <input
                  type={'file'}
                  name="csv"
                  onChange={(event) => {
                    setFieldValue('csv', event.currentTarget.files[0]);
                  }}
                />
              </label>
              <small>
                <p>This will replace any current annotations.</p>
                <p>
                  Use{' '}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1IwEvY7fl5XloiOBf5kVvNzlE2pEeij2uAKOF5KP7Nk8/edit?usp=sharing"
                    target="_blank">
                    this sample CSV
                  </a>{' '}
                  as a template.
                </p>
              </small>
              <PrimaryButton type="submit" disabled={isSubmitting} submitting={isSubmitting}>
                Submit
              </PrimaryButton>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default EvaluationModal;
