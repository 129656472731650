import React from 'react';
import cx from 'classnames';
import { uniq, without } from 'lodash';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  onClear: () => void;
  value: string;
}

const FocusFilter: React.FC<Props> = (props) => {
  return (
    <>
      <div
        className={cx(style.filterButton, 'align-items-center d-flex small text-white')}
        style={{
          backgroundColor: colors.blue,
          borderRadius: 4,
          gap: 5,
          minWidth: 0,
          whiteSpace: 'nowrap'
        }}>
        <FontAwesomeIcon
          className="hidden-sm hidden-xs"
          icon="check"
          style={{ WebkitTextStroke: `1px ${colors.blue}`, width: 14 }}
        />

        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          Focus: {props.value.split(':')[0]}
        </div>
      </div>

      <div
        className={cx(style.filterButton, 'small')}
        onClick={props.onClear}
        style={{
          backgroundColor: colors.blueLight,
          borderRadius: 4,
          color: colors.blue,
          cursor: 'pointer',
          gap: 5
        }}
      >
        Clear
      </div>
    </>
  );
};

export default FocusFilter;
