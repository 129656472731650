import React from 'react';
import cx from 'classnames';

interface Props {
  navigate?: (stepIdx: number) => void;
  steps?: {
    active: boolean;
    name: string;
    navigable: boolean
  }[];
}

const StepIndicators: React.FC<Props> = (props) => {
  const canNavigateToStep = (stepIdx) => {
    if (!props.navigate) return false;
    if (!props.steps[stepIdx].navigable) return false
    if (props.steps[stepIdx].active) return false;

    return true;
  }

  return (
    <div>
      {
        props.steps?.map((step, idx) => {
          if (step.active) {
            return (
              <React.Fragment key={idx}>
                {idx !== 0 && <>{' '}&gt;{' '}</>}
                <span  className="text-bold">{step.name}</span>
              </React.Fragment>
            );
          } else if (canNavigateToStep(idx)) {
            return (
              <React.Fragment key={idx}>
                {idx !== 0 && <>{' '}&gt;{' '}</>}
                <a onClick={() => props.navigate(idx + 1)}>{step.name}</a>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={idx}>
                {idx !== 0 && <>{' '}&gt;{' '}</>}
                <span >{step.name}</span>
              </React.Fragment>
            )
          }
        })
      }
    </div>
  );
};

export default StepIndicators;
