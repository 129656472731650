import React from 'react';
import ProgressBar from '@magoosh/lib/progress_bar';
import { PrimaryButton, SecondaryButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { Col, Row } from 'react-bootstrap';
import cx from 'classnames';
import { pluralize } from 'utilities/pluralize';
import { createMarking, goToNextUrl } from './utils';
import PracticeSessionPreviewModal from '@magoosh/organisms/study_schedule/practice_session_preview_modal/template';
import { StudyItemData } from '@magoosh/organisms/study_items/types';
import RatingModal from '@magoosh/organisms/rating_modal';
import { paths } from 'config/path_helpers';

export interface IntraModuleNavProps {
  authenticityToken: string;
  guidedActivity: {
    id: number;
    name: string;
    url: string;
    type: string;
    assignmentId?: number;
  };
  currentStudyItem: {
    name: string;
    lessonId?: number;
  };
  nextStudyItem: StudyItemData & {
    text?: 'none' | 'next' | 'remaining';
    questionCount?: number;
    questionType?: 'question' | 'task';
  };
  remainingItemCount: number;
  totalItemCount: number;
  allowNavigation: boolean;
}

export const IntraModuleNav: React.FC<IntraModuleNavProps> = (props) => {
  const [showRatingModal, setShowRatingModal] = React.useState(false);
  const [practicePreview, setPracticePreview] = React.useState(null);

  const handleContinueOrFinishClick = () => {
    let resultingAction;

    if (!props.nextStudyItem) {

      if (props.guidedActivity.type == 'ContentBlock::SectionedAssignmentBlock' ||
        props.guidedActivity.assignmentId) {
        fetch(paths.api.submitAssignment(props.guidedActivity.assignmentId), { method: 'put' })
          .then();
      }

      resultingAction = () => setShowRatingModal(true);
    } else {
      resultingAction = () => goToNextUrl(props, setPracticePreview);
    }

    if (props.currentStudyItem.lessonId) {
      createMarking(props.currentStudyItem.lessonId, props.authenticityToken).then(() => {
        resultingAction();
      });
    } else {
      resultingAction();
    }
  };

  const practicePreviewModal = () => {
    if (props.nextStudyItem.type === 'Practice') {
      return <PracticeSessionPreviewModal onHide={() => setPracticePreview(null)} {...practicePreview} />;
    }
  };

  return (
    <div>
      <div className="align-items-lg-center d-flex flex-column flex-lg-row gap-s">
        <div className="d-flex flex-column flex-1 gap-xs">
          <div className="align-items-center d-flex gap-s hidden-sm hidden-xs">
            <div>
              <a className="text-gray text-no-decoration" href={props.guidedActivity.url}>
                {props.guidedActivity.name}
              </a>
            </div>

            <div style={{ width: 75 }}>
              <ProgressBar
                backgroundColor="#cef0e3"
                color="#26B67C"
                percentage={((props.totalItemCount - props.remainingItemCount) / props.totalItemCount) * 100}
                size="sm"
              />
            </div>
          </div>

          <h3 className="u-margin-A-n"><strong>{props.currentStudyItem.name}</strong></h3>
        </div>

        <div className="align-items-center d-flex gap-s">
          {(
            <>
              {props.nextStudyItem && (
                <>
                  <div className="align-items-center d-flex gap-s">
                    <div>
                      {(() => {
                        if (props.nextStudyItem.text === 'next') {
                          return 'Up Next:';
                        } else if (props.nextStudyItem.text === 'remaining') {
                          return 'Remaining:';
                        } else if (props.nextStudyItem.text !== 'none') {
                          return `${props.nextStudyItem.text}:`;
                        }
                      })()}
                    </div>

                    <div className="align-items-center d-flex gap-xs u-margin-A-n">
                      <div>
                        {props.nextStudyItem.type === 'Practice' ? (
                          <FontAwesomeIcon icon="list-alt" />
                        ) : (
                          <FontAwesomeIcon icon="play-circle" />
                        )}
                      </div>

                      <div className="visible-sm visible-xs">
                        <a className="text-secondary" onClick={handleContinueOrFinishClick}>
                          <strong>
                            {props.nextStudyItem.name}{' '}
                            {props.nextStudyItem.questionCount &&
                              props.nextStudyItem.questionType &&
                              `(${pluralize(
                                props.nextStudyItem.questionCount,
                                props.nextStudyItem.questionType
                              )})`}
                            </strong>
                        </a>
                      </div>

                      <div className="hidden-sm hidden-xs">
                        <strong>
                          {props.nextStudyItem.name}{' '}
                          {props.nextStudyItem.questionCount &&
                            props.nextStudyItem.questionType &&
                            `(${pluralize(
                              props.nextStudyItem.questionCount,
                              props.nextStudyItem.questionType
                            )})`
                          }
                        </strong>
                      </div>
                    </div>

                    {props.nextStudyItem.text === 'remaining' && props.remainingItemCount > 1 && (
                      <a
                        className="hidden-sm hidden-xs text-secondary"
                        href={props.guidedActivity.url}>
                        <strong>{`+ ${props.remainingItemCount - 1} more`}</strong>
                      </a>
                    )}
                  </div>

                  {
                    props.allowNavigation && (
                      <SecondaryButton className="hidden-sm hidden-xs" submitting={false} onClick={handleContinueOrFinishClick}>
                      Continue
                    </SecondaryButton>
                    )
                  }
                </>
              )}

              {!props.nextStudyItem && props.allowNavigation && (
                <>
                  <strong>
                    <FontAwesomeIcon className="text-primary" icon="check-circle" />
                    {'  '}
                    {`${props.totalItemCount} of ${props.totalItemCount} Activities`}
                  </strong>

                  <SecondaryButton
                    className="hidden-xs hidden-sx"
                    onClick={handleContinueOrFinishClick}
                    submitting={false}
                  >
                    {props.guidedActivity.assignmentId ? 'Complete Assignment' : 'Finish'}
                  </SecondaryButton>

                  <a className="text-secondary visible-sm visible-xs" onClick={handleContinueOrFinishClick}>
                    <strong>{props.guidedActivity.assignmentId ? 'Complete Assignment' : 'Finish'}</strong>
                  </a>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <hr className="u-margin-B-n u-margin-T-xs" />

      {practicePreview && practicePreviewModal()}

      <RatingModal
        rateableId={props.guidedActivity.id}
        open={showRatingModal}
        onHide={() => setShowRatingModal(false)}
      />
    </div>
  );
};

export default IntraModuleNav;
