import React from 'react';
import { capitalize } from 'lodash';

interface Props {
  status: 'waiting' | 'queued' | 'processing' | 'validating' | 'finished' | 'error' | 'cancelled';
}

const StatusLabel: React.FC<Props> = (props) => {
  if (props.status === 'finished') {
    return (<div className="label label-success">Finished</div>);
  } else if (props.status === 'error') {
    return (<div className="label label-danger">Error</div>);
  } else if (props.status === 'cancelled') {
    return (<div className="label label-danger">Cancelled</div>);
  } else if (props.status === 'processing') {
    return (<div className="label label-info">Processing</div>);
  } else if (props.status === 'validating') {
    return (<div className="label label-warning">Validating</div>);
  } else {
    return (<div className="label bg-gray-light">{capitalize(props.status)}</div>);
  }
};

export default StatusLabel;
