import * as React from 'react';
import ProgressBar from '@magoosh/lib/progress_bar';

interface ModuleProgressProps {
  progresses: { examSectionName: string; difficulty?: string; percentCompleted: number }[];
}

export const ModuleProgressSummary = (props: ModuleProgressProps) => (
  <div className="u-margin-H-l">
    <h3>
      <strong>Progress Summary</strong>
    </h3>
    {props.progresses.map((progress) => (
      <div key={progress.examSectionName} className="u-margin-B-xs">
        <span>
          <strong>
            {progress.examSectionName}{' '}
            {progress.difficulty && progress.examSectionName != 'Writing' && `(${progress.difficulty})`}
          </strong>
        </span>
        <ProgressBar
          backgroundColor="#cef0e3"
          color="#26B67C"
          percentage={progress.percentCompleted}
          size="md"
          minLabel={progress.percentCompleted >= 15 && `${progress.percentCompleted.toString()}%`}
        />
      </div>
    ))}
  </div>
);

export default ModuleProgressSummary;
