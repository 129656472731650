import React, { useEffect, useState } from 'react';
import { EditInstructorFormValues } from './types';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import colors from '../../assets/style.module.scss';
import PageColumn from '../../lib/page_column';
import Icon from '../../assets/icons';
import fetch from 'utilities/fetch';
import { ErrorMessages } from '@magoosh/lib/formik_inputs/errors_messages';

const validationSchema = yup.object({
  email: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required()
});

export const EditInstructorForm: React.FC<{ id: number }> = ({ id }) => {
  const [instructor, setInstructor] = useState<EditInstructorFormValues>({
    email: '',
    firstName: '',
    lastName: ''
  });
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(paths.api.partner.instructor(id)).then((response) => {
      setInstructor(response.instructor);
    });
  }, [id]);

  const initialValues = {
    email: instructor.email,
    firstName: instructor.firstName,
    lastName: instructor.lastName
  };

  const handleOnSubmit = (
    values: EditInstructorFormValues,
    { setSubmitting }: FormikActions<EditInstructorFormValues>
  ): void => {
    setSubmitting(true);
    fetch(paths.api.partner.instructors(), {
      body: JSON.stringify(values),
      method: 'POST'
    }).then((response) => history.push(`/schools/${response.id}`));
  };

  return (
    <>
      <PageColumn>
        <div className={style.editInstructorForm}>
          <div className={style.formHeader}>
            <div className={style.headerText}>
              <h2 className="u-margin-T-n">Edit Instructor</h2>
              <h4>Add the following details about the existing instructor you want to edit.</h4>
            </div>
            <div className="u-margin-L-s">
              <Icon iconType="Instructor" fill={colors.supplementaryMagenta} size="l" />
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}>
            {({ isSubmitting, submitForm, errors }) => (
              <Form>
                <Row>
                  <Col md={12}>
                    <FieldInput name="email" type="email" label="Email" />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FieldInput name="firstName" type="text" label="First Name" />
                  </Col>
                  <Col md={6}>
                    <FieldInput name="lastName" type="text" label="Last Name" />
                  </Col>
                </Row>

                <br />

                <Row className="u-margin-T-s">
                  <Col xs={6}>
                    <SecondaryButton disabled={isSubmitting} onClick={submitForm} block>
                      Edit Instructor
                    </SecondaryButton>
                  </Col>

                  <Col xs={6}>
                    <DefaultButton onClick={() => history.push('/')} block>
                      Cancel
                    </DefaultButton>
                  </Col>
                </Row>

                <ErrorMessages errors={errors} />
              </Form>
            )}
          </Formik>
        </div>
      </PageColumn>
    </>
  );
};

export default EditInstructorForm;
