import React, { useContext, useState } from 'react';
import { BorderlessButton, SecondaryButton } from '@magoosh/lib/button';
import { DataTable } from '@magoosh/lib/data_table';
import { paths } from 'config/path_helpers';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Search } from '@magoosh/lib/data_table/search';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import fetch from 'utilities/fetch';
import { DateRangeFilter } from '@magoosh/lib/data_table/date_range_filter';
import { pluralize } from 'utilities/pluralize';
import { dateFormatter, durationFormatter } from '@magoosh/b2b/app/utils';
import colors from '../../assets/style.module.scss';
import Icon from '@magoosh/b2b/app/assets/icons';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@magoosh/b2b/app/app_context';
import { FilterToggle } from '@magoosh/lib/data_table/filter_toggle';
import { ArchiveClassSectionsModal } from '@magoosh/b2b/app/organisms/archive_class_sections_modal';
import { Badge } from '@magoosh/lib/data_table/badge';
import { PerformanceStatsBlock } from '@magoosh/b2b/app/organisms/performance_stats_block';

export const ClassSectionsPerformanceDataTable: React.FC<{ school: School }> = (props) => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const { queryParameters, runQuery, dataQueryTableProps } = useDataQuery(
    (queryParameters) => paths.api.partner.schoolClassSectionsPerformance(props.school.id, queryParameters),
    { sortColumn: 'displayName', filters: { 'schoolId': props.school.id } }
  );

  const booksIcon = <Icon iconType="Class" fill={colors.supplementaryRadRed} size="m" />;
  const grayBooksIcon = <Icon iconType="Class" fill={colors.grayLight} size="m" />;

  const [archivingClassSections, setArchivingClassSections] = useState(null);
  const [archiveClassSectionError, setArchiveClassSectionError] = useState(false);

  const resetArchivingClassSections = () => {
    setArchivingClassSections(null);
  };

  const archiveClassSections = (ids) => {
    return fetch(paths.api.partner.archiveClasses(ids), { method: 'DELETE' }).then(() => {
      runQuery();
    });
  };

  return (
    <>
      <DataTable
        {...dataQueryTableProps}
        primaryAttribute={{ selector: 'displayName', name: 'Class Name', sortable: true }}
        linkBaseUrl='/classes/'
        secondaryAttributeSelector="sectionNumber"
        selectableRows={true}
        columns={[
          {
            selector: 'lastActiveAt',
            name: 'Last Active',
            format: (classSection) => dateFormatter(classSection.lastActiveAt),
            sortable: true
          },
          {
            selector: 'weekTime',
            name: 'Week Time',
            format: (classSection) => durationFormatter(classSection.weekTime),
            sortable: true
          },
          { selector: 'answersCount', name: 'Answers', sortable: true },
          { selector: 'videosWatched', name: 'Videos', sortable: true },
          {
            selector: 'totalTime',
            name: 'Total Time',
            format: (student) => durationFormatter(student.totalTime),
            sortable: true
          },
          { selector: 'assignmentsCount', name: 'Assignments', sortable: true }
        ]}>
        <DataTable.Filters>
          <Search runQuery={runQuery} />
          <DateRangeFilter runQuery={runQuery} />
          <FilterToggle
            queryParameters={queryParameters}
            runQuery={runQuery}
            filterName={'archived'}
            filterLabel={'Show archived'}
          />
        </DataTable.Filters>
        <DataTable.HeaderActions>
          {!appContext.externalSourceUser && (
            <SecondaryButton
              onClick={() => history.push('/classes/create', { schoolId: props.school.id})}
              icon={'plus'}
              size={'xsmall'}
            >
              Create New Class
            </SecondaryButton>
          )}
          <DownloadButton
            queryParameters={queryParameters}
            fetchUrl={(params) => paths.api.partner.schoolClassSectionsPerformance(props.school.id, params)}
            fileName={'class_performance_data.csv'}
          />
        </DataTable.HeaderActions>
        <DataTable.StatsBlock>
          <PerformanceStatsBlock queryParameters={queryParameters} />
        </DataTable.StatsBlock>
        <DataTable.ItemIcon>
          {(classSection: ClassSection) => ((classSection.active && classSection.schoolActive) ? booksIcon : grayBooksIcon)}
        </DataTable.ItemIcon>
        <DataTable.PrimaryAttributeLabel>
          {(classSection: ClassSection) => (
            <>
              {classSection.active ? null : <Badge>Archived</Badge>}
              {classSection.schoolActive ? null : <Badge>Archived School</Badge>}
              {classSection.displayName}
            </>
          )}
        </DataTable.PrimaryAttributeLabel>
        <DataTable.ItemActions>
          {!appContext.externalSourceUser && ((classSection: ClassSection) => (
            <>
              <BorderlessButton
                title={'Edit class'}
                icon="edit"
                onClick={() => history.push(`/classes/${classSection.id}/edit`)}
              />
              {classSection.active && (
                <BorderlessButton
                  title={'Archive class'}
                  icon="trash"
                  onClick={() => {
                    setArchiveClassSectionError(false);
                    setArchivingClassSections([classSection]);
                  }}
                />
              )}
            </>
          ))}
        </DataTable.ItemActions>
        <DataTable.BulkActions>
          <SecondaryButton
            size={'xsmall'}
            icon="trash"
            onClick={() => {
              setArchivingClassSections(dataQueryTableProps.selectedRows);
            }}>
            Archive {pluralize(dataQueryTableProps.selectedRows.length, 'class', 'classes')}
          </SecondaryButton>
        </DataTable.BulkActions>
      </DataTable>
      <ArchiveClassSectionsModal
        classSections={archivingClassSections}
        reset={resetArchivingClassSections}
        showErrorMessage={archiveClassSectionError}
        archiveClassSections={() => {
          archiveClassSections(archivingClassSections.map((classSection) => classSection.id)).then(() =>
            setArchivingClassSections(null)
          ).catch(() => {
            setArchiveClassSectionError(true);
          });
        }}
      />
    </>
  );
};
