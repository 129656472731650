import React, { useLayoutEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { find, maxBy } from 'lodash';

import Bar from './bar';
import COLORS from '../colors';
import ChartContainer from '../container';
import Legend from '../legend'

import style from '../style.module.scss';

const fontFamily = '"Tondo", Arial, "Helvetica Neue", Helvetica, sans-serif';

interface Props {
  borderRadius?: number;
  chartProps?: any;
  data: { [key: string]: number | string; }[];
  formatValueFn?: (value: number) => string,
  hideLegend?: boolean;
  horizontal?: boolean
  indexBy: string;
  legendPosition?: 'bottom' | 'left' | 'right' | 'top';
}

const BarChart: React.FC<Props> = (props) => {
  const formattedData = (sourceData) => {
    let colorsIdx = 0;
    let keyToColorMapping = {};

    return sourceData.map((datum, idx) => {
      let resultDatum = {};

      Object.keys(datum).forEach((key, idx) => {
        if (key === props.indexBy) {
          // Don't need to assign colors to the key we are indexing by
          resultDatum[key] = datum[key];
        } else if (key.match(/.*Color$/)) {
          // There was a color manually specified in the data. Save it so we can apply it later
          //to any data for the same key that is missing a color definition.
          resultDatum[key] = datum[key];
          keyToColorMapping[key.slice(0, key.length - 5)] = datum[key];
        } else {
          resultDatum[key] = datum[key];

          // If the key doesn't have a corresponding color, assign it from either a
          // previous color we've seen in the data or the defaults.
          if (!datum[`${key}Color`]) {
            const color = keyToColorMapping[key] || COLORS[colorsIdx % COLORS.length];
            resultDatum[`${key}Color`] = color
            keyToColorMapping[key] = color;
            colorsIdx += 1;
          }
        }
      });

      return resultDatum;
    });
  };

  const legendGroups = (keys, sourceData) => {
    return keys.map((key) => (
      {
        // Every datum has the same color data so we can just look at the first one
        colors: [sourceData[0][`${key}Color`]],
        name: key
      }
    ));
  };

  const uniqueColors = (sourceData) => {
    const colorsObserved = new Set();

    sourceData.forEach((datum) => {
      Object.keys(datum).forEach((key) => {
        if (key.match(/.*Color$/)) {
          colorsObserved.add(datum[key]);
        }
      });
    });

    return Array.from(colorsObserved) as string[];
  };

  const uniqueKeys = (sourceData) => {
    const keysObserved = new Set();

    // Nivo wants to display the keys in the reverse order we specify them
    sourceData.reverse().forEach((datum) => {
      Object.keys(datum).forEach((key) => {
        if (key === props.indexBy) return;
        if (key.match(/.*Color$/)) return;

        keysObserved.add(key);
      });
    });

    return Array.from(keysObserved) as string[];
  };

  const uniqueSeries = (sourceData) => {
    const keysObserved = new Set();

    sourceData.forEach((datum) => {
      Object.keys(datum).forEach((key) => {
        if (key === props.indexBy) {
          keysObserved.add(datum[key]);
        }
      });
    });

    return Array.from(keysObserved) as string[];
  };

  const data = formattedData(props.data);
  const colorsInData = uniqueColors(data);
  const keysInData = uniqueKeys(data);
  const seriesInData = uniqueSeries(data);

  // Apply any border radius to only the start of the first element and end of the last element.
  // TODO - verify the implementation in bar.tsx handles a vertical bar chart.
  const borderRadius = (key, location) => {
    const targetKey = key.split('.')[0];
    const targetKeyIndex = keysInData.indexOf(targetKey);
    // Some target keys have been truncated with an ellipsis so we can't split by '.'.
    // Instead assume that everything after the target key is the series name.
    const targetDatum = find(data, (d) => d[props.indexBy] === key.replace(`${targetKey}.`, ''));

    // This shouldn't happen, but adding a safety check to make sure the chart doesn't break
    // if we do have unexpected data.
    if (!targetDatum) return 0;

    if (location === 'start') {
      const precedingKeys = keysInData.slice(0, targetKeyIndex);

      if (precedingKeys.find((key) => !!targetDatum[key])) {
        return 0;
      } else {
        return !!targetDatum[targetKey] ? props.borderRadius : 0;
      }
    } else if (location === 'end') {
      const followingKeys = keysInData.slice(targetKeyIndex + 1, keysInData.length);

      if (followingKeys.find((key) => !!targetDatum[key])) {
        return 0;
      } else {
        return !!targetDatum[targetKey] ? props.borderRadius : 0;
      }
    }
  }

  const [seriesLabelWidth, setSeriesLabelWidth] = useState(0);

  useLayoutEffect(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `14px ${fontFamily}`;

    const width = context.measureText(
      maxBy(seriesInData, (seriesName) => seriesName.length)
    ).width;

    setSeriesLabelWidth(width);
  }, [props.data]);

  return (
    <ChartContainer legendPosition={props.legendPosition}>
      <div
        className={style.chart}
        style={{ height: data.length * 25, position: 'relative', width: '100%' }}
      >
        <ResponsiveBar
          axisLeft={{
            tickPadding: 10,
            tickSize: 0
          }}
          barComponent={Bar}
          borderRadius={borderRadius}
          colors={colorsInData}
          data={data}
          enableLabel={false}
          indexBy={props.indexBy}
          innerPadding={2}
          layout={props.horizontal ? 'horizontal' : 'vertical'}
          keys={keysInData}
          margin={{ left: seriesLabelWidth }}
          padding={0.3}
          theme={{
            text: {
              fontFamily,
              fontSize: 14
            }
          }}
          valueFormat={props.formatValueFn}
          {...props.chartProps}
        />
      </div>

      {!props.hideLegend && <Legend groups={legendGroups(keysInData, data)} />}
    </ChartContainer>
  );
};

export default BarChart;
