import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import { map, sortBy, sum, uniq } from 'lodash';

import { ColorPalette } from 'types/constants';

import COLORS from '../colors';
import ChartContainer from '../container';
import Legend from '../legend';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from '../style.module.scss';

interface DonutProps {
  chartProps?: any;
  data: {
    color?: string;
    label: string;
    value: number;
  }[];
  formatValueFn?: (value: number) => string,
  hideLegend?: boolean;
  interior?: React.ReactNode | ((data: any) => React.ReactNode);
  legendGroupNameFn?: (label: string) => string;
  legendPosition?: 'bottom' | 'left' | 'right' | 'top';
  size: number;
  sort?: boolean;
}

const DonutChart: React.FC<DonutProps> = (props) => {
  const formattedData = () => {
    let data = map(props.data, (datum) => (
      {
        ...datum,
        id: datum.label
      }
    ));

    if (props.sort) {
      data = sortBy(data, (d) => d.value * -1);
    }

    return data.map((d, idx) => (
      { color: COLORS[idx % COLORS.length], ...d }
    ));
  };

  const legendGroups = (data) => {
    let groups = [];
    data.forEach((datum) => {
      if (datum.value === 0) return;

      const name = props.legendGroupNameFn ? props.legendGroupNameFn(datum.label) : datum.label;

      const existingGroupIndex = groups.findIndex((g) => g.name === name);
      const group = existingGroupIndex !== -1 ? groups[existingGroupIndex] : { name, colors: [] };
      group.colors.push(datum.color)

      if (existingGroupIndex !== -1) {
        groups[existingGroupIndex] = group;
      } else {
        groups.push(group);
      }
    });

    return groups;
  };

  const noData = () => {
    return sum(props.data.map((datum) => datum.value)) === 0;
  };

  const data = formattedData();

  return (
    <ChartContainer>
      <div className={style.chart} style={{ height: props.size, position: 'relative', width: props.size }}>
        {
          noData() ? (
            <ResponsivePie
              colors={[colors.dataDarkPurple, colors.dataMutedPurple]}
              data={[{ id: 'Placeholder', value: 1 }, { id: 'No Activity', value: 99 }]}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              fit
              innerRadius={0.7}
              isInteractive={false}
              valueFormat={props.formatValueFn}
              {...props.chartProps}
            />
          ) : (
            <ResponsivePie
              colors={uniq(data.map((d) => d.color))}
              data={data}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              fit
              innerRadius={0.7}
              valueFormat={props.formatValueFn}
              {...props.chartProps}
            />
          )
        }

        {
          props.interior && (
            <div
              className="align-items-center d-flex flex-1 justify-content-center"
              style={{
                height: props.size,
                left: 0,
                position: 'absolute',
                top: 0,
                width: props.size
              }}
            >
              {props.interior && typeof props.interior === 'function' ? props.interior(props.data): props.interior }
            </div>
          )
        }
      </div>

      {!props.hideLegend && <Legend groups={legendGroups(data)} />}
    </ChartContainer>
  );
};



export default DonutChart;
