import * as React from 'react';
import { Field } from 'formik';
import style from './style.module.scss';
import { EditorStatus, EditorTab } from '@magoosh/pages/writing_experience/types';
import { DefaultButton } from '@magoosh/lib/button';
import { capitalize } from 'lodash';
import cx from 'classnames';

interface Props {
  defaultWritingTab: EditorTab;
  submitForm?: () => void;
  setFieldValue?: (field: string, value: any) => void;
  status: EditorStatus;
  setEditorStatus: (status: EditorStatus) => void;
  fullScreen?: boolean;
}
const Editor: React.FC<Props> = (props) => {
  const [timeoutRef, setTimeoutRef] = React.useState(null);

  const [currentTab, setCurrentTab] = React.useState(props.defaultWritingTab);

  const createAutosave = (fieldName) => {
    return (event) => {
      if (!(props.status === 'serverError')) {
        props.setEditorStatus('savePending');
      }
      props.setFieldValue(fieldName, event.currentTarget.value);
      clearTimeout(timeoutRef);
      setTimeoutRef(setTimeout(props.submitForm, 2000));
    };
  };

  return (
    <div className={cx(style.editor, props.fullScreen ? style.fullScreen : null)}>
      <div className={style.flexBetween}>
        <ul className={style.tabButtons}>
          {['prompt', 'brainstorm', 'outline', 'draft'].map((tab: EditorTab) => {
            return (
              <li key={tab}>
                <DefaultButton
                  className={currentTab == tab ? style.active : null}
                  onClick={() => setCurrentTab(tab)}>
                  {capitalize(tab)}
                </DefaultButton>
              </li>
            );
          })}
        </ul>
        {props.status == 'saved' && <div className={style.editorStatus}>Saved!</div>}
      </div>
      {props.status == 'serverError' && (
        <div className={style.editorError}>
          An error has occurred. We'll keep trying, but you may want to copy the text into a text editor to
          avoid losing your work{' '}
        </div>
      )}

      <div className={cx(style.editorTab)}>
        {['prompt', 'brainstorm', 'outline', 'draft'].map((tab: EditorTab) => {
          return (
            <div className={currentTab == tab ? null : 'hidden'} key={tab}>
              <Field
                name={tab}
                component="textarea"
                rows={props.fullScreen ? 100 : 20}
                placeholder={`Enter your ${tab}...`}
                onChange={createAutosave(tab)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Editor;
