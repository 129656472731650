import React, { useContext, useMemo, useState } from 'react';
import Icon from '@magoosh/b2b/app/assets/icons';
import { BorderlessButton, SecondaryButton } from '@magoosh/lib/button';
import { DataTable, inactiveRowStyles } from '@magoosh/lib/data_table';
import { paths } from 'config/path_helpers';
import { dateFormatter } from '@magoosh/b2b/app/utils';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Search } from '@magoosh/lib/data_table/search';
import fetch from 'utilities/fetch';
import { pluralize } from 'utilities/pluralize';
import colors from '@magoosh/b2b/app/assets/style.module.scss';
import { ArchiveAssignmentsModal } from './archive_assignments_modal';
import { FilterToggle } from '@magoosh/lib/data_table/filter_toggle';
import { Badge } from '@magoosh/lib/data_table/badge';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import { useHistory } from 'react-router-dom';
import { AppContext } from '@magoosh/b2b/app/app_context';

interface AssignmentsDataTableProps {
  classSection?: ClassSection;
  school?: School;
}

export const AssignmentsDataTable: React.FC<AssignmentsDataTableProps> = (props) => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const defaultQueryParams = useMemo(() => {
    const defaultQueryParams = { sortColumn: 'title', filters: {} };
    if (props.classSection) {
      defaultQueryParams.filters['classSectionId'] = props.classSection.id;
    }

    if (props.school) {
      defaultQueryParams.filters['schoolId'] = props.school.id;
    }

    return defaultQueryParams;
  }, [props.classSection]);

  const { queryParameters, runQuery, dataQueryTableProps } = useDataQuery(
    paths.api.partner.assignments,
    defaultQueryParams
  );

  const assignmentIcon = <Icon iconType="Assignment" fill={colors.supplementaryAzure} size="m" />;
  const archivedAssignmentIcon = <Icon iconType="Assignment" fill={colors.grayLight} size="m" />;

  const [archivingAssignments, setArchivingAssignments] = useState(null);

  const resetArchivingAssignments = () => {
    setArchivingAssignments(null);
  };

  const archiveAssignments = (ids) => {
    fetch(paths.api.partner.archiveAssignments(ids), { method: 'DELETE' }).then(() => {
      runQuery();
      resetArchivingAssignments();
    });
  };

  return (
    <>
      <DataTable
        {...dataQueryTableProps}
        primaryAttribute={{ selector: 'title', name: 'Assignment', sortable: true }}
        secondaryAttributeSelector={props.classSection ? null : 'className'}
        selectableRows={true}
        linkBaseUrl="/assignments/"
        columns={[
          {
            selector: 'dueDate',
            name: 'Due Date',
            format: (assignment) => dateFormatter(assignment.dueDate),
            sortable: true
          },
          { selector: 'contentName', name: 'Content', sortable: true },
          { selector: 'studentsCount', name: 'Students', sortable: true },
          { selector: 'submissionsCount', name: 'Submissions', sortable: true }
        ]}
        conditionalRowStyles={[
          {
            when: (assignment) => assignment.deletedAt,
            style: inactiveRowStyles
          }
        ]}>
        <DataTable.Filters>
          <Search runQuery={runQuery} />
          <FilterToggle
            queryParameters={queryParameters}
            runQuery={runQuery}
            filterName={'archived'}
            filterLabel={'Show archived'}
          />
          <FilterToggle
            queryParameters={queryParameters}
            runQuery={runQuery}
            filterName={'createdByCurrentUser'}
            filterLabel={'Created by Me'}
          />
        </DataTable.Filters>
        <DataTable.HeaderActions>
          <SecondaryButton
            onClick={() => history.push('/assignments/create/select-classes', { classSection: props.classSection })}
            icon={'plus'}
            size={'xsmall'}
            disabled={appContext.trialAccess}
          >
            Create New Assignment
          </SecondaryButton>
          <DownloadButton
            queryParameters={queryParameters}
            fetchUrl={paths.api.partner.assignments}
            fileName={'assignments_data.csv'}
            disabled={appContext.trialAccess}
          />
        </DataTable.HeaderActions>
        <DataTable.ItemIcon>
          {(assignment) => {
            return assignment.deletedAt ? archivedAssignmentIcon : assignmentIcon;
          }}
        </DataTable.ItemIcon>
        <DataTable.PrimaryAttributeLabel>
          {(assignment: Assignment) => (
            <>
              {assignment.deletedAt ? <Badge>Archived</Badge> : null}
              {assignment.title}
            </>
          )}
        </DataTable.PrimaryAttributeLabel>
        <DataTable.ItemActions>
          {(assignment) => (
            <>
              <BorderlessButton
                title={'Edit assignment'}
                icon={'edit'}
                onClick={() => history.push(`/assignments/${assignment.id}/edit`)}
              />
              {!assignment.deletedAt && (
                <BorderlessButton
                  title={'Archive assignment'}
                  icon={'trash'}
                  onClick={() => setArchivingAssignments([assignment])}
                />
              )}
            </>
          )}
        </DataTable.ItemActions>
        <DataTable.BulkActions>
          <SecondaryButton
            size={'xsmall'}
            icon={'trash'}
            onClick={() => setArchivingAssignments(dataQueryTableProps.selectedRows)}>
            Archive {pluralize(dataQueryTableProps.selectedRows.length, 'Assignment')}
          </SecondaryButton>
        </DataTable.BulkActions>
      </DataTable>
      <ArchiveAssignmentsModal
        assignments={archivingAssignments}
        reset={resetArchivingAssignments}
        archiveAssignments={() => archiveAssignments(archivingAssignments.map((assignment) => assignment.id))}
      />
    </>
  );
};
