import React from 'react';
import cx from 'classnames'

import style from './style.module.scss';

interface Chapter {
  title: string;
  humanTimestamp: string;
  begin: string;
}

interface Props {
  summary: string;
  bullets: string[];
  chapters: Chapter[];
}

const SummaryContent: React.FC<Props> = (props) => {

  const handleClick = (timeStamp) => {
    const phraseClicked = new CustomEvent('phraseClicked', { detail: { time: timeStamp } } );
    document.dispatchEvent(phraseClicked)
  }

  return (
    <div className={style.summaryTabContainer}>
      <div className={style.summaryBlock}>
        <div className={style.blockTitle}>
          Summary
        </div>
        <div className={style.summaryContent}>

          {props.summary}
        </div>
        <div>
          <ul>
            {props.bullets.map((bullet, i) => (
              <li key={i} className="u-margin-T-xxs"> {bullet} </li>
            ))}
          </ul>
        </div>
      </div>
      {
        props.chapters &&
        <div className={style.summaryBlock}>
          <div className={style.blockTitle}>
            Chapters
          </div>
          <div className={style.chaptersContent}>
            {
              props.chapters.map((chapter, i) => {
                return(
                  <div key={i} className={style.chapterRow}>
                    <div className={cx(style.timeCode, 'chapter-timecode')}
                      data-begin={chapter.begin}
                      onClick={() => handleClick(chapter.begin)}>
                      {chapter.humanTimestamp}
                    </div>
                    <div>
                      {chapter.title}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
    </div>
  )
}

export default SummaryContent;
