import React from 'react';
import cx from 'classnames';
import markdownit from 'markdown-it'

import { renderLatexInRawHtml } from 'utilities/latex';

import { Interaction } from '@test_prep/app/ai_tutor/types';

import ErrorMessage from '../error_message';

import style from './style.module.scss';

interface Props {
  interaction: Interaction;
}

const Chat: React.FC<Props> = (props) => {
  // TODO - move to utilites/latex
  const textWithLatex = () => {
    let text = props.interaction.content.text;

    (text.match(/\\\((.+?)\\\)/gs) || []).forEach((match) => {
      const latexSource = match.replace('\\\(', '').replace('\\\)', '').replace('\n', '');
      text = text.replace(match, `<code class="redactor-katex" data-source="${latexSource}"></code>`);
    });

    (text.match(/\\\[(.+?)\\\]/gs) || []).forEach((match) => {
      const latexSource = match.replace('\\\[', '').replace('\\\]', '').replace(/\n/g, '');
      text = text.replace(match, `<code class="redactor-katex" data-source="${latexSource}"></code>`);
    });

    return renderLatexInRawHtml(text);
  }

  const html = (text) => {
    const md = markdownit({ html: true });
    return md.render(text);
  }

  if (props.interaction.status === 'error') {
    return <ErrorMessage message="Oops! Something went wrong. Please try asking your question again." />;
  } else if (props.interaction.content.text) {
    return (
      <div dangerouslySetInnerHTML={{ __html: html(textWithLatex()) }} />
    );
  } else {
    return (
      <div className={cx(style.animatedEllipsis, 'u-padding-V-xs')}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
};

export default Chat;
