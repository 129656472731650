import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';

import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { DefaultFilledButton, PrimaryButton, ResponsiveButtonGroup } from '@magoosh/lib/button';
import { FieldRadioGroup } from '@magoosh/lib/formik_inputs/field_radio_group';
import { Form } from '@magoosh/lib/forms';

import * as api from '@admin/app/api';

import { ContentAudit } from '../../types';

interface Props {
  aiPromptId: number;
  onClose: () => void;
  onSuccess: (audit: ContentAudit) => void;
}

const CreateContentAuditModal: React.FC<Props> = (props) => {
  const [availableAuditWorkflows, setAvailableAuditWorkflows] = useState(null);

  const initialValues = {
    workflowType: ''
  };

  useEffect(() => {
    api.fetchTestPrepAIContentAuditsAvailableWorkflows(props.aiPromptId, 'AIPrompt').then(
      (contentAuditWorkflows) => (
        setAvailableAuditWorkflows(contentAuditWorkflows)
      )
    );
  }, []);

  const handleOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const contentAudit = {
      contentId: props.aiPromptId,
      contentType: 'AIPrompt',
      ...values
    };

    api.createTestPrepAIContentAudit(contentAudit).then((audit) => {
      props.onSuccess(audit);
      props.onClose();
    });
  };

  if (availableAuditWorkflows) {
    return (
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <FieldRadioGroup
                className="u-margin-B-n"
                label="Audit Type"
                name="workflowType"
                optionsArray={
                  availableAuditWorkflows.map((availableWorkflow) => (
                    {
                      hint: availableWorkflow.description,
                      label: availableWorkflow.name,
                      value: availableWorkflow.klass
                    }
                  ))
                }
                value={values.workflowType}
              />

              <div className="u-margin-T-s">
                <ResponsiveButtonGroup>
                  <PrimaryButton submitting={isSubmitting} type="submit">
                    Request Audit
                  </PrimaryButton>

                  <DefaultFilledButton onClick={props.onClose}>
                    Cancel
                  </DefaultFilledButton>
                </ResponsiveButtonGroup>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  } else {
    return (
      <div className="d-flex justify-content-center u-margin-T-l">
        <LoadingIndicator />
      </div>
    );
  }
};

export default CreateContentAuditModal;
