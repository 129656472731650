import React, { memo, useEffect, useRef } from 'react';
import cx from 'classnames';
import { findIndex, isEqual } from 'lodash';

import { bugsnagNotify } from 'utilities/bugsnag';

import * as api from '@test_prep/app/api';
import { Interaction, TutoringSession } from '@test_prep/app/ai_tutor/types';

import ActivityOffer from './activity_offer';
import Chat from './chat';
import ChatHint from './chat_hint';
import PromptActivity from './prompt_activity';
import TimeAgo from '../time_ago';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  addOrUpdateUserInteractions: (interactions: Interaction[]) => void;
  interaction: Interaction;
  onOptionSelected: (text: string) => void;
  setCurrentActivity: (interaction: Interaction) => void;
}

const MAX_POLLS = 30;

const InteractionCard: React.FC<Props> = (props) => {
  const poller = useRef(null);
  const pollCountRef = useRef(0);
  const isUser = props.interaction.role === 'user';

  useEffect(() => {
    if (isDone(props.interaction)) return;

    poller.current = setTimeout(pollForUpdates, 1000);

    return () => {
      clearTimeout(poller.current);
    }
  }, []);

  const isDone = (interaction) => {
    return interaction.status === 'finished' || interaction.status === 'error';
  };

  const pollForUpdates = () => {
    if (props.interaction.provisional) return;

    pollCountRef.current += 1;

    api.fetchTutoringSessionInteraction(props.interaction.tutoringSessionId, props.interaction.id).then((interaction) => {
      props.addOrUpdateUserInteractions([interaction]);

      if (interaction.isActivity) {
        props.setCurrentActivity(interaction);
      }

      if (isDone(interaction)) {
        api.fetchNextTutoringSessionInteractions(props.interaction.tutoringSessionId, props.interaction.id).then((nextInteractions) => {
          props.addOrUpdateUserInteractions([interaction, ...nextInteractions]);
        });
      } else {
        if (pollCountRef.current < MAX_POLLS) {
          poller.current = setTimeout(pollForUpdates, 2000);
        } else {
          bugsnagNotify('Maximum number of polls reached');
          props.addOrUpdateUserInteractions([{ ...props.interaction, status: 'error' }]);
        }
      }
    }).catch((error) => {
      bugsnagNotify(error);
      props.addOrUpdateUserInteractions([{ ...props.interaction, status: 'error' }]);
    });
  };

  const renderActivity = () => {
    if (props.interaction.type === 'prompt_activity') {
      return (
        <PromptActivity
          interaction={props.interaction}
          onClick={props.setCurrentActivity}
        />
      );
    } else {
      return null;
    }
  };

  const renderInteraction = () => {
    if (props.interaction.type === 'activity_offer') {
      return (
        <ActivityOffer
          addOrUpdateUserInteractions={props.addOrUpdateUserInteractions}
          interaction={props.interaction}
        />
      );
    } else if (props.interaction.type === 'chat_hint') {
      return (
        <ChatHint
          interaction={props.interaction}
          onSelected={props.onOptionSelected}
        />
      );
    } else if (props.interaction.type === 'chat') {
      return <Chat interaction={props.interaction} />;
    } else {
      return null;
    }
  };

  if (props.interaction.isActivity) {
    return renderActivity();
  } else if (!isDone(props.interaction) && !props.interaction.content.text) {
    return (
      <div className={cx(style.animatedEllipsis, 'u-padding-V-xs')}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  } else {
    // Wrap non-activity interactions in a card
    return (
      <div
        className="d-flex flex-column gap-s u-padding-A-s"
        style={{
          backgroundColor: isUser ? 'none' : colors.grayLightest,
          border: isUser ? `1px solid ${colors.grayLightest}` : 'none',
          borderRadius: 8
        }}
      >
        <div className="align-items-center d-flex gap-s">
          {
            isUser ? (
              <div className="text-bold">You</div>
            ) : (
              <svg width="27" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.122 4.039c-1.847 0-3.515.715-4.825 1.846C13.642 2.61 10.723.107 7.207.107A7.195 7.195 0 0 0 .001 7.315a7.195 7.195 0 0 0 7.208 7.208c1.847 0 3.515-.715 4.825-1.847.655 3.277 3.574 5.779 7.089 5.779a7.195 7.195 0 0 0 7.208-7.208c0-3.932-3.217-7.208-7.208-7.208Zm-6.195 1.31-6.553 6.672c-.06.06-.179.06-.298 0l-4.408-4.23c-.06-.059-.06-.178 0-.297l1.191-1.251c.06-.06.18-.06.298 0l2.92 2.74c.059.06.118.06.118 0l5.123-5.063c.06-.06.179-.06.298 0l1.31 1.191c.06 0 .06.12 0 .238Zm5.36 10.604c-.059.06-.178.06-.297 0l-4.408-4.23c-.06-.06-.06-.178 0-.298l1.191-1.25c.06-.06.179-.06.298 0l2.92 2.74c.059.06.118.06.118 0l5.123-5.064c.06-.06.179-.06.298 0l1.31 1.192c.06.06.06.178 0 .298l-6.552 6.612Z" fill="#27B67C"/><path d="m13.106 5.111-6.553 6.672c-.06.06-.178.06-.297 0l-4.409-4.23c-.06-.06-.06-.178 0-.297L3.04 6.005c.06-.06.179-.06.298 0l2.919 2.74c.06.06.119.06.119 0l5.123-5.064c.06-.06.178-.06.298 0l1.31 1.192a.18.18 0 0 1 0 .238Zm5.361 10.604c-.06.06-.178.06-.297 0l-4.409-4.23c-.06-.06-.06-.179 0-.298l1.192-1.25c.06-.06.179-.06.298 0l2.919 2.74c.06.059.119.059.119 0l5.123-5.064c.06-.06.178-.06.297 0l1.311 1.191c.06.06.06.18 0 .298l-6.553 6.612Z" fill="#fff"/></svg>
            )
          }

          <div className="text-gray" style={{ fontSize: 12 }}>
            <TimeAgo time={props.interaction.finishedAt || props.interaction.createdAt} />
          </div>
        </div>

        {renderInteraction()}
      </div>
    );
  }
};

export default memo(InteractionCard, (oldProps, newProps) => {
  return isEqual(oldProps.interaction, newProps.interaction);
});
