import * as React from 'react';
import {
  GuidedExperienceContentStep,
  PageComponent,
  User,
  WrittenMaterial
} from '@magoosh/pages/writing_experience/types';
import { SecondaryButton } from '@magoosh/lib/button';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import style from './style.module.scss';
import cx from 'classnames';
import { paths } from 'config/path_helpers';
import { useEffect } from 'react';
import fetch from 'utilities/fetch';
import RatingForm from '@magoosh/organisms/rating';
import { RatingData } from 'types/rating';
import VideoPlayer from '@magoosh/organisms/video_player';
import { pluralize } from 'utilities/pluralize';

interface Props {
  step: GuidedExperienceContentStep;
  nextStep?: number;
  previousStep?: number;
  rootPath: string;
  onClickNext: (GuidedExperienceStep, completed) => void;
  user: User;
  writtenMaterial: WrittenMaterial;
}

const WritingExperiencePage: React.FC<Props> = (props) => {
  const history = useHistory();

  const onClickNext = () => {
    props.onClickNext(props.step, true);
    history.push(`${props.rootPath}/step/${props.nextStep}`);
    window.scrollTo(0, 0);
  };
  const onClickPrevious = () => {
    history.push(`${props.rootPath}/step/${props.previousStep}`);
    window.scrollTo(0, 0);
  };

  const [rating, setRating] = React.useState<RatingData>(null);

  useEffect(() => {
    fetch(`/api/web/ratings/ContentBlock::Base/${props.step.id}`).then((response) =>
      setRating(response.rating)
    );
  }, []);

  return (
    <div className={style.guidedExperienceStep}>
      {!props.previousStep && (
        <div className={style.intro}>
          <h1 className={style.stepHeader}>
            Hi{props.user.name ? ` ${props.user.name},` : ','} welcome to Magoosh's Guided Admissions Writing!
          </h1>
        </div>
      )}

      {props.nextStep ? (
        <h1 className={style.stepHeader}>{props.step.header}</h1>
      ) : (
        <div className={style.outro}>
          <h1 className={style.stepHeader}>Congrats! You completed your essay draft!</h1>
        </div>
      )}

      {props.step.content.map((pageComponent) => {
        switch (pageComponent.type) {
          case 'Lesson':
            if (props.step.embedLessons) {
              return (
                <div className={style.lessonEmbed}>
                  <VideoPlayer {...pageComponent.videoPlayerProps} />
                  <div className={style.lessonData}>
                    <h4>{pageComponent.title}</h4>
                    <span>{pageComponent.length}</span>{' '}
                    <a href={pageComponent.url} target="_blank">
                      View lesson with transcript
                    </a>{' '}
                    {pageComponent.watched && <FontAwesomeIcon title={'Watched'} icon={'eye'} />}
                  </div>
                </div>
              );
            } else {
              return <LessonCard key={pageComponent.id} {...pageComponent} />;
            }
          case 'WritingExample':
            return <WritingExampleCard key={pageComponent.id} {...pageComponent} />;
          case 'RichText':
            return <RichTextElement key={pageComponent.id} {...pageComponent} />;
        }
      })}

      {!props.nextStep && (
        <div>
          <p>Want an extra set of eyes? </p>
          <p>
            <a href={paths.writtenMaterialExport(props.writtenMaterial.id)}>Download a PDF</a> to share it
            with mentors for feedback.
          </p>
        </div>
      )}

      <div className={style.navButtons}>
        {props.previousStep && (
          <div className="btn-backward pull-left">
            <SecondaryButton className="backward" onClick={onClickPrevious}>
              Previous
            </SecondaryButton>
          </div>
        )}
        {props.nextStep && (
          <div className="btn-forward pull-right">
            <SecondaryButton className="forward" onClick={onClickNext}>
              Next
            </SecondaryButton>
          </div>
        )}
      </div>

      {rating && (
        <div className={cx('u-margin-V-m', style.ratingFormContainer)}>
          <RatingForm rating={rating} rateableName={'step'} centerButtons />
        </div>
      )}
    </div>
  );
};

const LessonCard: React.FC<PageComponent> = (props) => {
  return (
    <div className={style.pageComponent}>
      <a className={style.cardLink} href={props.url} target="_blank">
        <div className={cx(style.card, style.lesson)}>
          <img src={props.thumbnailUrl} />
          <div className={'u-margin-L-s'}>
            <h3>{props.title}</h3>
            <small>{props.length}</small>{' '}
            {props.watched && <FontAwesomeIcon title={'Watched'} icon={'eye'} />}
          </div>
        </div>
      </a>
    </div>
  );
};

const WritingExampleCard: React.FC<PageComponent> = (props: PageComponent) => {
  return (
    <div className={style.pageComponent}>
      <h4>{props.descriptor}</h4>
      <a className={style.cardLink} href={props.url} target="_blank">
        <div className={style.card}>
          <div className={style.header}>
            <span className={style.icon}>
              <FontAwesomeIcon icon="file-text" />
            </span>
            <h3>{props.name}</h3>
          </div>
          <div className={style.divider} />
          <p>{props.blurb}</p>
        </div>
      </a>
    </div>
  );
};

const RichTextElement: React.FC<PageComponent> = (props: PageComponent) => {
  return <div dangerouslySetInnerHTML={{ __html: props.richText }}></div>;
};

export default WritingExperiencePage;
