import React from 'react';
import { Checkpoint } from './types';

import PrioritizedAssessmentCheckpoint from './prioritized_assessment';
import PrioritizedPracticeTestCheckpoint from './prioritized_practice_test';
import PrioritizedTestDayCheckpoint from './prioritized_test_day';

interface Props {
  allowDefer: boolean;
  deferAssessment: () => void;
  dismissRefresh: () => void;
  prioritizedCheckpoint: Checkpoint;
  refreshPlan: () => void;
  subsectionsToPrioritizeOnRefresh?: string[];
  testDate?: string;
}

const PrioritizedCheckpoint: React.FC<Props> = (props) => {
  const {
    allowDefer,
    deferAssessment,
    dismissRefresh,
    prioritizedCheckpoint,
    refreshPlan,
    subsectionsToPrioritizeOnRefresh,
    testDate
  } = props;

  switch (prioritizedCheckpoint.type) {
    case 'Practice Test':
      return <PrioritizedPracticeTestCheckpoint checkpoint={prioritizedCheckpoint} />;
    case 'Test Day':
      return <PrioritizedTestDayCheckpoint testDate={testDate} />;
    case 'Assessment':
      return <PrioritizedAssessmentCheckpoint
        allowDefer={allowDefer}
        checkpoint={prioritizedCheckpoint}
        deferAssessment={deferAssessment}
      />;
  };
}

export default PrioritizedCheckpoint;
