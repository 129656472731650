import { isArray, startCase, toLower } from 'lodash';

export const titleize = (value: string) => {
  return startCase(toLower(value));
};

// Inputs
//  - items = an array of strings
//  - templateFunction (optional) = a formatting function that takes a single string as an input
//    and outputs a string with formatting applied. Useful for doing things like adding a
//    <strong> or <span> tag around each item but leaving the commas and 'and' untouched.
// Outputs: the items as a string, connected with commas and 'and' as appropraite based on the
// array size.
export const toSentence = (items, separator = 'and', templateFunction = (item) => item) => {
  if (!isArray(items)) {
    return templateFunction(items);
  } else if (items.length <= 2) {
    return items.map((item) => templateFunction(item)).join(` ${separator} `);
  } else {
    return [items.slice(0, -1).join(', ')].concat(items.slice(-1)).map((item) => templateFunction(item)).join(`, ${separator} `);
  }
};
