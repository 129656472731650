import * as React from 'react';
import style from './style.module.scss';
import StudySchedule from '@magoosh/organisms/study_schedule';
import { StudyScheduleStore } from '@magoosh/organisms/study_schedule/types';
import { CARSDailyQuestionState, ExamSection, SeriesRecommendationsData } from './types';
import DailyCARSQuestion from './dailyCARSQuestion';
import ExamSectionCard from './examSectionCard';
import SeriesRecommendations from './seriesRecommendations';

interface MCATDashboardProps {
  carsDailyQuestionState: CARSDailyQuestionState;
  carsDailyQuestionPracticeSessionId: number;
  carsDailyQuestionLocked: boolean;
  examSections: ExamSection[];
  studyScheduleProps: StudyScheduleStore;
  seriesRecommendations: SeriesRecommendationsData[];
}

const MCATDashboard: React.FC<MCATDashboardProps> = (props) => (
  <div className={style.mcatDashboard}>
    <h1 className={style.header}>Dashboard</h1>
    <div className={style.sidebar}>
      {props.studyScheduleProps ? (
        <StudySchedule {...props.studyScheduleProps}></StudySchedule>
      ) : (
        <SeriesRecommendations recommendations={props.seriesRecommendations} />
      )}
    </div>
    <DailyCARSQuestion
      questionState={props.carsDailyQuestionState}
      practiceSessionId={props.carsDailyQuestionPracticeSessionId}
      carsExamSectionId={props.examSections.find((examSection) => examSection.slug == 'cars-verbal').id}
      locked={props.carsDailyQuestionLocked}
    />
    <div className={style.examSectionCards}>
      {props.examSections.map((examSection) => (
        <ExamSectionCard key={examSection.id} examSection={examSection} />
      ))}
    </div>
  </div>
);

export default MCATDashboard;
