import React from 'react';
import { omit } from 'lodash';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Switch, Route } from 'react-router-dom';

import Router from '@magoosh/lib/router';
import ScrollToTop from '@magoosh/lib/scroll_to_top';

import { ModalProvider } from '@magoosh/context/modal_context';
import ErrorBoundary from '@magoosh/utils/error_boundary';

import Pageview from './components/pageview';
import { AppProvider } from './context';
import routes from './routes';

interface Props {
  initialData?: any;
  url: string;
}

const queryClient = new QueryClient();

const App: React.FC<Props> = (props) => {  
  if (props.initialData) {
    const pageData = omit(props.initialData, 'context');
    queryClient.setQueryData(['pages', props.url], pageData);
  }

  return (
    <ErrorBoundary examName={props.initialData?.context?.branding?.examName}>
      <AppProvider initialData={props.initialData?.context}>
        <QueryClientProvider client={queryClient}>
          <Router location={props.url}>

            <ModalProvider>
              <Pageview />
              <ScrollToTop />

              <Switch>
                {
                  routes.map((route, idx) => {
                    return <Route {...route} key={idx} />
                  })
                }
              </Switch>
            </ModalProvider>
          </Router>
        </QueryClientProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default App;
