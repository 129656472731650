import AnalyticsPage from './pages/analytics/index';
import AnalyticsDetailPage from './pages/analytics/detail';
import MockTestNewPage from './pages/mock_tests/new';
import MockTestShowPage from './pages/mock_tests/show';
import OnDemandClassesIndexPage from './pages/on_demand_classes/index';
// import NotFoundPage from './pages/not_found';

const routes = [
  {
    exact: true,
    path: '/analytics/:section/:unit',
    component: AnalyticsDetailPage
  },
  {
    exact: true,
    path: '/analytics',
    component: AnalyticsPage
  },
  {
    exact: true,
    path: '/on_demand_classes',
    component: OnDemandClassesIndexPage
  },
  {
    exact: true,
    path: '/practice_tests/share/:shareableId',
    component: MockTestShowPage
  },
  {
    exact: true,
    path: '/practice_tests/start',
    component: MockTestNewPage
  },
  {
    exact: true,
    path: '/practice_tests/:id',
    component: MockTestShowPage
  }
  // {
  //   path: '*',
  //   component: NotFoundPage
  // }
];

export default routes;
