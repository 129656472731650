import * as React from 'react';

import { PrimaryButton } from '@magoosh/lib/button';

import style from './style.module.scss';
import cx from 'classnames';

interface Props {
  examName: string;
  onComplete: (studyExperience: string) => void;
  studyExperience: string;
}

interface State {
  studyExperience: string;
}

export default class StudyExperienceStep extends React.Component<Props, State> {
  handleOnClick = (e) => {
    this.setState({ studyExperience: e.target.value });
  };

  handleOnComplete = () => {
    this.props.onComplete(this.state.studyExperience);
  };

  constructor(props) {
    super(props);
    this.state = {
      studyExperience: props.studyExperience
    }
  }

  render() {
    const { examName, onComplete } = this.props;

    return (
      <React.Fragment>
        <div style={{flexGrow: 1}}>
          <h3 className="text-center u-margin-A-n">How Familiar Are You<br />With The {examName}?</h3>

          <div className="u-margin-H-s u-margin-T-m">
            <label className={cx("u-margin-B-s", style.examSectionFocusOption)}>
              <input
                checked={this.state.studyExperience === 'beginner1'}
                name="studyExperience"
                onChange={this.handleOnClick}
                type="radio"
                value="beginner1" />
              <span className="font-weight-normal small u-margin-L-xs">
                I'm a complete beginner—teach me everything I need to know about the {examName}.
              </span>
            </label>

            <label className={cx("u-margin-B-s", style.examSectionFocusOption)}>
              <input
                checked={this.state.studyExperience === 'beginner2'}
                name="studyExperience"
                onChange={this.handleOnClick}
                type="radio"
                value="beginner2" />
              <span className="font-weight-normal small u-margin-L-xs">
                I know a little about the {examName}, but I'm still not confident in all of the content.
              </span>
            </label>

            <label className={cx("u-margin-B-s", style.examSectionFocusOption)}>
              <input
                checked={this.state.studyExperience === 'advanced1'}
                name="studyExperience"
                onChange={this.handleOnClick}
                type="radio"
                value="advanced1" />
              <span className="font-weight-normal small u-margin-L-xs">
                I know something about the {examName} and I'm confident in my basic knowledge, but I need to brush up on certain subjects.
              </span>
            </label>
          </div>
        </div>

        <div className="text-center">
          <PrimaryButton className={style.primaryButton} onClick={this.handleOnComplete}>
            Next
          </PrimaryButton>
        </div>
      </React.Fragment>
    );
  }
}
