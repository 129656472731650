import * as React from 'react';
import style from './style.module.scss';
import ProgressBar from '@magoosh/lib/progress_bar';
import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import { ExamSection } from './types';
import Card from '@magoosh/lib/card';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface ExamSectionCardProps {
  examSection: ExamSection;
}

const ExamSectionCard: React.FC<ExamSectionCardProps> = (props) => {
  const {
    name,
    id,
    lessonsReviewed,
    totalLessons,
    questionsAnswered,
    totalQuestions,
    estimatedScore,
    slug,
    answersNeededForEstimate
  } = props.examSection;

  return (
    <Card shadow className={style.examSectionCard}>
      <div className={style.examSectionHeader}>{name}</div>
      <div className={style.lessons}>
        <div className={style.progressBarLabel}>Lessons reviewed</div>
        <div className={style.progressBar}>
          <ProgressBar
            backgroundColor={style.grayColor}
            color={style.brandPrimaryColor}
            percentage={(lessonsReviewed / totalLessons) * 100}
            size={'md'}
            popoverText={`${lessonsReviewed} / ${totalLessons}`}
          />
        </div>
      </div>
      <div className={style.questions}>
        <div className={style.progressBarLabel}>Questions answered</div>
        <div className={style.progressBar}>
          <ProgressBar
            backgroundColor={style.grayColor}
            color={style.brandPrimaryColor}
            percentage={(questionsAnswered / totalQuestions) * 100}
            popoverText={`${questionsAnswered} / ${totalQuestions}`}
            size={'md'}
          />
        </div>
      </div>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={
          <Popover title="Score Estimate" id={`score-estimate-${slug}`}>
            We provide a score estimate after you answer {answersNeededForEstimate} questions, based on actual
            MCAT scores from previous Magoosh students. The more questions you answer, the more accurate your
            estimate will be. (Only your most recent answer for each question is used.)
          </Popover>
        }>
        <div className={style.score}>
          <div className={style.scoreLabel}>
            Estimated
            <br />
            score
          </div>
          {estimatedScore || '– –'}
        </div>
      </OverlayTrigger>
      <div className={style.practice}>
        <DefaultButton className={style.examSectionCardButton} href={paths.examSectionPracticeSession(id)}>
          Practice
        </DefaultButton>
      </div>
      <div className={style.learn}>
        <PrimaryButton className={style.examSectionCardButton} href={paths.mcatLessonGroups(slug)}>
          Learn
        </PrimaryButton>
      </div>
    </Card>
  );
};

export default ExamSectionCard;
