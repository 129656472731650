import * as React from 'react';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import { VideoPlayerOptions } from '@magoosh/organisms/video_player/types';
import { bugsnagNotify } from 'utilities/bugsnag';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import values from 'lodash/values';
import find from 'lodash/find';
import titleIconSrc from './assets/icon.svg';
import magooshLogo from './assets/magoosh.svg';
import { browserName } from 'react-device-detect';

export interface VideoPlayerProps {
  wrapperClasses: string | null;
  videoFileId: number;
  fallbackFile: string;
  explanationTranscript: string;
  options: VideoPlayerOptions;
  hasExternalExplanationTranscript?: boolean;
  onEnd?: () => void;
}

interface VideoPlayerState {
  curPosition: number;
  lastStartPosition: number;
  fallbackTried: boolean;
}

export default class VideoPlayer extends React.Component<VideoPlayerProps, VideoPlayerState> {
  state: VideoPlayerState = {
    curPosition: -1,
    lastStartPosition: -1,
    fallbackTried: false
  };

  // A unique ID suffix to allow multiple players on a single page
  private playerId: string = this.props.videoFileId.toString();

  private player: any = {};

  componentDidMount() {
    this.player = videojs(
      document.querySelector(`#video-player-${this.playerId}`),
      { ...this.props.options, sources: this.sourcesForBrowser() }
    );

    if (this.props.options.posterOverlay) {
      document.querySelectorAll('.vjs-poster-overlay').forEach(el => el.remove());

      const VideojsComponent = videojs.getComponent('Component');
      const poster = this.player.getChild('PosterImage');

      const PosterOverlay = videojs.extend(VideojsComponent, {
        constructor: function (player, options: VideoPlayerOptions['posterOverlay']) {
          // It is important to invoke the superclass before anything else,
          // to get all the features of components out of the box!
          VideojsComponent.apply(this, arguments);

          const title = document.createElement('div');
          title.classList.add('title');
          title.innerText = options.title;

          const subtitle = document.createElement('div');
          subtitle.classList.add('subtitle');
          subtitle.innerText = options.subtitle;

          const titleIcon = document.createElement('img');
          titleIcon.setAttribute('src', titleIconSrc);
          titleIcon.setAttribute('class', 'title-icon');

          const logo = document.createElement('img');
          logo.setAttribute('src', magooshLogo);
          logo.setAttribute('class', 'magoosh-logo');

          videojs.emptyEl(this.el());
          videojs.appendContent(this.el(), titleIcon);
          videojs.appendContent(this.el(), title);
          videojs.appendContent(this.el(), subtitle);
          videojs.appendContent(this.el(), logo);
        },

        // The `createEl` function of a component creates its DOM element.
        createEl: function () {
          return videojs.createEl('div', {
            className: 'vjs-poster-overlay'
          });
        }
      });

      // Register the component with Video.js, so it can be used in players.
      videojs.registerComponent('PosterOverlay', PosterOverlay);

      // Add the PosterOverlay as a child of the player
      poster.addChild('PosterOverlay', this.props.options.posterOverlay);
    }

    // Add the quality selector plugin to the player
    this.player.hlsQualitySelector({
      displayCurrentQuality: true
    });

    this.player.on('ready', () => {
      this.logTime(0.0);
      this.setRate();
      this.enableCaptionToggles();
      this.setCaptions();
    });

    this.player.on('ratechange', () => {
      document.cookie = `video_speed=${this.player.playbackRate()}; path=/`;
    });

    // HACK: overwrite the existing error message with our own
    this.player.on('error', () => {
      bugsnagNotify(`VideoJS: ${this.player.error()?.message}`);

      const errorMessage = document.querySelector('.vjs-modal-dialog-content');
      if (errorMessage) {
        errorMessage.innerHTML = this.getErrorMessage();
      }
      this.tryFallback();
    });

    this.player.on('timeupdate', () => {
      this.logTime(this.player.currentTime());

      // Send an event to the transcript box to highlight the right phrase
      const timeUpdate = new CustomEvent('timeUpdate', { detail: { time: this.player.currentTime() } });
      document.dispatchEvent(timeUpdate);
    });

    this.player.on('pause', () => {
      this.logStop();
    });

    this.player.on('ended', () => {
      if (this.state.lastStartPosition !== -1) this.logStop();
      if (this.props.onEnd) this.props.onEnd();
    });

    this.player.on('fullscreenchange', () => {
      this.player.focus();
    });

    if (this.props.explanationTranscript || this.props.hasExternalExplanationTranscript) {
      // Listen for a click on a transcript box phrase and move the player accordingly
      // This event is dispatched from any view that includes `javascript_pack_tag 'transcript_events'`
      // NOTE: If two video players with a transcript are shown on a page, both videos will respond
      // to the "phraseClicked" event. If this becomes an issue we may need to port the transcript
      // to React as a child component of this one.
      document.addEventListener('phraseClicked', (e: CustomEvent) => {
        this.player.currentTime(e.detail.time);
        this.player.play();
      });
    }

    document.addEventListener('keydown', (e: any) => {
      if (this.ignoreKeydown(e)) return;
      this.registerHotkeyDown(e.which, e);
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div className={this.props.wrapperClasses}>
        <div data-vjs-player>
          <video id={`video-player-${this.playerId}`} className="video-js vjs-default-skin" playsInline>
            <p className="vjs-no-js">To view this video please enable Javascript</p>
          </video>
        </div>
        {this.props.explanationTranscript && (
          <div dangerouslySetInnerHTML={{ __html: this.props.explanationTranscript }}></div>
        )}
      </div>
    );
  }

  getErrorMessage = () => {
    return `<p>
        This video could not be loaded. If video issues continue after switching web browsers,
        check out our <a href="https://gre.magoosh.com/video-errors-FAQ">Video FAQs</a> for more advice.
      </p>`;
  };

  tryFallback = () => {
    if (this.state.fallbackTried) return;

    bugsnagNotify('VideoJS: Trying fallback');
    this.setState({ fallbackTried: true });
    this.player.src(this.props.fallbackFile);
  };

  enableCaptionToggles = () => {
    const captionsTrack = find(this.player.textTracks(), (track) => track.kind === 'subtitles');
    const captionBanner = document.querySelector('.caption-toggle'); // 'Turn on captions' banner
    const captionButtonSelections = values(document.querySelectorAll('.vjs-subs-caps-button .vjs-menu-item')); // In-video captions buttons

    if (captionsTrack) {
      if (captionBanner) {
        captionBanner.addEventListener('click', () => {
          captionsTrack.mode = 'showing';
          // Adding a path here will create a different cookie than what was previously implemented,
          // but it's needed to get this to work correctly. Should be ok to have two cookies since we
          // grab the last one created in getCookieVal()
          document.cookie = `lesson_caption=${captionsTrack.mode}; path=/`;
        });
      }

      // No need to toggle the mode here since the player does it automatically
      if (captionButtonSelections.length > 0) {
        for (const selection of captionButtonSelections) {
          selection.addEventListener('click', () => {
            document.cookie = `lesson_caption=${captionsTrack.mode}; path=/`;
          });
        }
      }
    }
  };

  logTime = (position) => {
    // Submit view log if user actively moved player backwards or forwards
    // (assume it was manual if it went forward by at least 2 seconds)
    if (
      this.state.lastStartPosition >= 0 &&
      (position < this.state.curPosition || position >= this.state.curPosition + 2)
    ) {
      this.logStop();
    }

    this.setState({ curPosition: position });
    if (this.state.lastStartPosition < 0) {
      this.setState({ lastStartPosition: position });
    }
  };

  // Called when video is paused, video ends, or the user manually moved video
  logStop = () => {
    if (this.state.lastStartPosition < this.state.curPosition) {
      fetch(paths.api.videoViews(this.props.videoFileId), {
        method: 'POST',
        body: JSON.stringify({
          view: {
            start: this.state.lastStartPosition,
            stop: this.state.curPosition
          }
        })
      }).catch((error) => {
        bugsnagNotify(error);
      });
      this.setState({ lastStartPosition: -1 });
    }
  };

  // If they've changed playback rate, maintain that rate
  setRate = () => {
    // Keep at 100% speed when autoplay enabled and controls hidden
    if (this.props.options.autoplay) return;

    const videoSpeed = this.getCookieVal('video_speed') || 1;
    this.player.playbackRate(videoSpeed);
  };

  // If they've toggled captions on/off, maintain that setting
  setCaptions = () => {
    const captionMode = this.getCookieVal('lesson_caption') || 'disabled';
    const captionsTrack = find(this.player.textTracks(), (track) => track.kind === 'subtitles');

    if (captionsTrack) {
      captionsTrack.mode = captionMode;
    }
  };

  getCookieVal = (name) => {
    const parts = `; ${document.cookie}`.split(`; ${name}=`);
    if (parts.length >= 2) {
      return parts.pop().split(';').shift();
    }
  };

  // Ignore keydowns that are likely intended for other purposes than to control the video.
  // These include combos with a modifier key (e.g. cmd-f), keypresses in text areas or on
  // buttons, and up/down keys in non-fullscreen video mode
  ignoreKeydown = (e) => {
    const typesToIgnore = ['button', 'submit', 'textarea', 'number'];
    if (e.ctrlKey || e.altKey || e.metaKey || e.shiftKey) return true;
    else if (e.target instanceof HTMLInputElement) return true;
    else if (e.target && typesToIgnore.includes(e.target.type)) return true;
    else if (e.target.classList.contains('calculator')) return true;
    else if ((e.which === 38 || e.which === 40) && !this.player.isFullscreen()) return true;

    return false;
  };

  registerHotkeyDown = (key, event) => {
    const hotkeyHandlers = {
      // `space` key = toggle play/pause
      32: () => (this.player.paused() ? this.player.play() : this.player.pause()),
      // `f` key = toggle fullscreen
      70: () => (this.player.isFullscreen() ? this.player.exitFullscreen() : this.player.requestFullscreen()),
      // `m` key = toggle mute
      77: () => (this.player.muted() ? this.player.muted(false) : this.player.muted(true)),
      // `right arrow` key = skip forward 5 seconds
      39: () => this.player.currentTime(this.player.currentTime() + 5),
      // `left arrow` key = jump back 5 seconds
      37: () => this.player.currentTime(this.player.currentTime() - 5),
      // `up arrow` key = raise volume by 10% (in fullscreen mode only)
      38: () => this.player.volume(this.player.volume() + 0.1),
      // `down arrow` key = lower volume by 10% (in fullscreen mode only)
      40: () => this.player.volume(this.player.volume() - 0.1),
      // `c` key = toggle captions
      67: () => {
        const captionsTrack = find(this.player.textTracks(), (track) => track.kind === 'subtitles');
        if (captionsTrack) {
          captionsTrack.mode = captionsTrack.mode === 'showing' ? 'disabled' : 'showing';
          document.cookie = `lesson_caption=${captionsTrack.mode}; path=/`;
        }
      },
      // `1-9` keys = jump to that percentage x 10 in video. e.g. 8 = 80% of the video
      49: () => this.player.currentTime(this.player.duration() * 0.1),
      50: () => this.player.currentTime(this.player.duration() * 0.2),
      51: () => this.player.currentTime(this.player.duration() * 0.3),
      52: () => this.player.currentTime(this.player.duration() * 0.4),
      53: () => this.player.currentTime(this.player.duration() * 0.5),
      54: () => this.player.currentTime(this.player.duration() * 0.6),
      55: () => this.player.currentTime(this.player.duration() * 0.7),
      56: () => this.player.currentTime(this.player.duration() * 0.8),
      57: () => this.player.currentTime(this.player.duration() * 0.9)
    };

    if (hotkeyHandlers[key]) {
      this.player.focus();
      event.preventDefault();
      event.stopPropagation();

      hotkeyHandlers[key]();
    }
  };

  sourcesForBrowser = () => {
    return this.props.options.sources.filter((source) => {
      if (source.type === 'hls' && browserName === 'Safari') {
        return false;
      } else {
        return true;
      }
    });
  };
}
