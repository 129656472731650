// TODO - move into constants context
export const BrandingSlugs = {
  ACT: 'act',
  GMAT: 'gmat',
  GRAD_ADMISSIONS: 'gradadmissions',
  GRE: 'gre',
  IELTS: 'ielts',
  IELTS_GT: 'ieltsgt',
  LSAT: 'lsat',
  MBA: 'mba',
  MCAT: 'mcat',
  PRAXIS: 'praxis',
  SAT: 'sat',
  TOEFL: 'toefl'
}

export interface Branding {
  id: number;
  name: string;
  accountsAvailable?: number;
}
