import * as React from 'react';
import WritingExperiencePage from '@magoosh/pages/writing_experience/show/writing_experience_page';
import { Link, Switch, Route, useLocation } from "react-router-dom";
import Router from '@magoosh/lib/router';
import {
  EditorStatus,
  GuidedExperienceStep,
  User,
  WrittenMaterial
} from '@magoosh/pages/writing_experience/types';
import Editor from '@magoosh/pages/writing_experience/show/editor';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import EditStep from '@magoosh/pages/writing_experience/show/edit_step';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import style from '@magoosh/pages/writing_experience/show/style.module.scss';
import { LinkButton, PrimaryButton } from '@magoosh/lib/button';
import { Checkmark, FontAwesomeIcon } from '@magoosh/lib/icons';
import cx from 'classnames';
import ProgressBar from '@magoosh/lib/progress_bar';

interface Props {
  user: User;
  url: string;
  writtenMaterial: WrittenMaterial;
  content: {
    name: string;
    sections: {
      name: string;
      steps: GuidedExperienceStep[];
    }[];
  };
}

interface FormValues {
  title: string;
  prompt: string;
  brainstorm: string;
  outline: string;
  draft: string;
}

const Show: React.FC<Props> = (props) => {
  const rootPath = `/writing_experiences/${props.writtenMaterial.id}`;
  const location = useLocation();
  const flattenedSteps = props.content.sections
    .map((section) => {
      return section.steps;
    })
    .flat();

  const [writtenMaterial, setWrittenMaterial] = React.useState<WrittenMaterial>(props.writtenMaterial);
  const [editTitle, setEditTitle] = React.useState(false);
  const [showEditor, setShowEditor] = React.useState(false);
  const [editorStatus, setEditorStatus] = React.useState<EditorStatus>('savePending');

  const [progresses, setProgresses] = React.useState(
    flattenedSteps.map((step) => {
      return step.completed;
    })
  );

  const updateStepCompleted = (step: GuidedExperienceStep, completed: boolean) => {
    const index = flattenedSteps.indexOf(step);
    const newProgresses = [...progresses];
    newProgresses[index] = completed;
    setProgresses(newProgresses);

    fetch(paths.api.writtenMaterialProgress(writtenMaterial.id), {
      method: 'PUT',
      body: JSON.stringify({
        step_id: step.id,
        state: completed
      })
    });
  };

  const initialValues: FormValues = {
    title: writtenMaterial.title || '',
    prompt: writtenMaterial.prompt || '',
    brainstorm: writtenMaterial.brainstorm || '',
    outline: writtenMaterial.outline || '',
    draft: writtenMaterial.draft || ''
  };

  const onSubmit = (values, actions: FormikHelpers<FormValues>) => {
    fetch(paths.api.writtenMaterial(writtenMaterial.id), {
      method: 'PATCH',
      body: JSON.stringify(values)
    })
      .then((response) => {
        setEditorStatus('saved');
        setWrittenMaterial(response.writtenMaterial);
        setEditTitle(false);
      })
      .catch((error) => {
        setEditorStatus('serverError');
        setTimeout(actions.submitForm, 2000);
      });
  };

  const onClickWrite = () => {
    setShowEditor(!showEditor);
  };

  const onCompletedToggle = (step: GuidedExperienceStep) => {
    const index = flattenedSteps.indexOf(step);
    const newProgress = !progresses[index];
    updateStepCompleted(step, newProgress);
  };

  const renderNav = () => {
    const numDone = progresses.filter((progress) => {
      return progress;
    }).length;

    return (
      <div className={cx(style.sideNav, showEditor ? 'hidden' : null)}>
        <div className={style.progressBarContainer}>
          <ProgressBar
            backgroundColor={'#D8D8D8'}
            color={'#4D2079'}
            percentage={(numDone / progresses.length) * 100}
            size={'sm'}
          />
          <small className="text-gray">
            {numDone} of {progresses.length} steps completed
          </small>
        </div>
        <a href={paths.writingExperiences()} className="u-margin-L-s u-margin-T-s">
          Back to Essays List
        </a>
        <ul>
          {props.content.sections.map((section, sectionIndex) => {
            return (
              <div key={`section-${sectionIndex}`}>
                <h3>{section.name}</h3>
                <ul>
                  {section.steps.map((step, stepIndex) => {
                    const index = flattenedSteps.indexOf(step);
                    const path = `${rootPath}/step/${index + 1}`;
                    return (
                      <li
                        key={step.id}
                        className={cx(
                          style.stepLink,
                          path === location.pathname ? style.activeStepLink : null
                        )}>
                        <Link
                          to={path}
                          key={step.id}
                          className={cx(path === location.pathname ? style.activeStepLink : null)}>
                          <div
                            className={style.checkmark}
                            onClick={(event) => {
                              event.preventDefault();
                              onCompletedToggle(step);
                            }}>
                            <Checkmark completed={progresses[index]} />
                          </div>
                          {step.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className={style.pageContainer}>
      <div className={style.pageContent}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ submitForm, setFieldValue }) => {
            return (
              <Form>
                <div className={style.essayTitle}>
                  {editTitle ? <Field name="title" /> : <h2>{writtenMaterial.title}</h2>}
                  <div>
                    {editTitle ? (
                      <PrimaryButton type={'submit'} className={'btn-sm'} onClick={submitForm}>
                        Save
                      </PrimaryButton>
                    ) : (
                      <LinkButton onClick={() => setEditTitle(true)}>Rename</LinkButton>
                    )}

                    <PrimaryButton
                      className={cx(
                        'btn-primary-outline u-margin-L-m',
                        showEditor ? style.focusedButton : null
                      )}
                      onClick={onClickWrite}>
                      {showEditor ? (
                        <>
                          <FontAwesomeIcon icon={'angle-left'} /> Back
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={'pencil-square-o'} /> Write
                        </>
                      )}
                    </PrimaryButton>
                  </div>
                </div>
                <div className={style.main}>

                  {renderNav()}

                  <div className={cx(style.stepContainer, showEditor ? 'hidden' : null)}>
                    <Switch>
                      {flattenedSteps.map((step, index) => {
                        const path = `${rootPath}/step/${index + 1}`;
                        const previousStep = index > 0 ? index : null;
                        const nextStep = index < flattenedSteps.length - 1 ? index + 2 : null;

                        if (step.type === 'ContentBlock::GuidedExperienceStepBlock') {
                          return (
                            <Route
                              path={path}
                              key={step.id}
                            >
                              <WritingExperiencePage
                                step={step}
                                previousStep={previousStep}
                                nextStep={nextStep}
                                rootPath={rootPath}
                                onClickNext={updateStepCompleted}
                                user={props.user}
                                writtenMaterial={props.writtenMaterial}
                              />
                            </Route>
                          );
                        } else if (step.type === 'ContentBlock::GuidedExperienceWritingBlock') {
                          return (
                            <Route
                              path={path}
                              key={step.id}
                            >
                              <EditStep
                                step={step}
                                previousStep={previousStep}
                                nextStep={nextStep}
                                rootPath={rootPath}
                                submitForm={submitForm}
                                editorStatus={editorStatus}
                                setEditorStatus={setEditorStatus}
                                setFieldValue={setFieldValue}
                                onClickNext={updateStepCompleted}
                              />
                            </Route>
                          );
                        }
                      })}
                    </Switch>
                  </div>
                  {showEditor && (
                    <Editor
                      defaultWritingTab={'draft'}
                      submitForm={submitForm}
                      setFieldValue={setFieldValue}
                      status={editorStatus}
                      setEditorStatus={setEditorStatus}
                      fullScreen
                    />
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const ShowWithRouter: React.FC<Props> = (props) => {
  return (
    <Router location={props.url}>
      <Switch>
        <Route
          path={["/writing_experiences/:id", "/writing_experiences/:id/step/:stepId"]}
          render={(_) => <Show {...props} />}
        />
      </Switch>
    </Router>
  );
}

export default ShowWithRouter;
