const breakdownSections = [
  {
    name: 'Arithmetic and Fractions',
    percentCorrect: 67
  },
  {
    name: 'Data Interpretation',
    percentCorrect: 50
  },
  {
    name: 'Percents and Ratios',
    percentCorrect: 44
  },
  {
    name: 'Algebra',
    percentCorrect: 28
  },
  {
    name: 'Integer Properties',
    percentCorrect: 27
  }
];

const scoreSections = [
  {
    name: 'Math',
    sampleScore: 163
  },
  {
    name: 'Verbal',
    sampleScore: 156
  },
  {
    name: 'Total',
    sampleScore: 319
  }
];

export default {
  null: { breakdownSections, scoreSections }
};
