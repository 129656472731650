import React, { PropsWithChildren } from 'react';
import style from './style.module.scss';

const Header = (props) => <></>;
const Stats = (props) => <></>;

export const StatsBlock = (props: PropsWithChildren<{}>) => {
  let header = null;
  let stats = null;

  React.Children.forEach(props.children, (child) => {
    if (React.isValidElement(child)) {
      switch (child.type) {
        case Header:
          header = child.props.children;
          break;
        case Stats:
          stats = child.props.children;
          break;
      }
    }
  });

  return (
    <div className={style.statsBlock}>
      {header}
      <div className={style.stats}>
        {stats}
      </div>
    </div>
  );
};

StatsBlock.Header = Header;
StatsBlock.Stats = Stats;

export default StatsBlock;
