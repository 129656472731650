import React from 'react';
import cx from 'classnames';

import { useModalContext } from '@magoosh/context/modal_context';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { pluralize } from 'utilities/pluralize';

import StatRankingsTable from '@schools/app/components/stat_rankings_table';
import { EditorialStat, EditorialStatSet } from '../types';

import style from './style.module.scss';

const ContentfulEditorialStatSet: React.FC<EditorialStatSet & { className?: string; }> = (props) => {
  return (
    <div className={cx(props.className, 'd-flex flex-wrap')} style={{ gap: 5 }}>
      {
        props.stats.map((stat, idx) => (
          <Stat key={idx} {...stat} />
        ))
      }
    </div>
  );
};

const Stat: React.FC<EditorialStat> = (props) => {
  const { setModal } = useModalContext();

  const presentRankingsModal = () => {
    setModal({
      header: props.metric.name,
      body: <StatRankingsTable
        categorySlug={props.categorySlug}
        measurementId={props.id}
        metricSlug={props.metric.slug}
      />
    });
  };

  const rankingText = () => {
    if (props.type === 'schoolProfileTextMeasurement') {
      return `${props.ranking.rank} of ${pluralize(props.ranking.groupSize, 'school')}`;
    } else {
      return `#${props.ranking.rank} out of ${props.ranking.groupSize}`;
    }
  };

  return (
    <div className={cx(style.stat, 'bg-gray-lightest d-flex flex-column')}>
      <div className="align-items-center d-flex">
        <div className="small text-gray-light text-uppercase">
          <strong>
            {props.metric.name}{props.measurementType !== 'Value' ? ` (${props.measurementType})` : null}
          </strong>
        </div>

        <div className="d-flex flex-1 justify-content-end" style={{ height: 30 }}>
          {
            props.metric.icon && (
              <div
                className="align-items-center d-flex justify-content-center"
                style={{ backgroundColor: '#E5D6F4', borderRadius: 4, height: 30, width: 30 }}
              >
                <FontAwesomeIcon className="text-secondary" icon={props.metric.icon} />
              </div>
            )
          }
        </div>
      </div>

      <div className={cx(style.statValue, 'text-gray-darker')}>
        <strong>{props.value}</strong>
      </div>

      {
        props.ranking && (
          <div style={{ fontSize: 13 }}>
            <a onClick={presentRankingsModal} className="text-body text-no-decoration text-underdash">
              <strong>{rankingText()}</strong>
            </a>
          </div>
        )
      }

    </div>
  );
};

export default ContentfulEditorialStatSet;
