import * as React from 'react';
import cx from 'classnames';

import style from './label.module.scss';

export type LabelType = 'success' | 'alert' | 'danger' | 'default';
export type LabelProps = {
  children?: any;
  className?: string;
  onClick?: () => any;
  type?: LabelType;
};

export default class BaseLabel extends React.Component<LabelProps> {
  render() {
    return (
      <span
        className={cx(this.props.className, style.label, style[this.props.type || 'default'])}
        onClick={this.props.onClick}>
        {this.props.children}
      </span>
    )
  }
}
