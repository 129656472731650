import React from 'react';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { capitalize } from 'lodash';
import { Alert } from 'react-bootstrap';

import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import useAuthenticityToken from '@magoosh/utils/use_authenticity_token';
import { paths } from 'config/path_helpers';
import { bugsnagNotify } from 'utilities/bugsnag';
import { formatPrice } from 'utilities/money';

import * as api from '@payments/api';
import { genericErrorMessage, PaymentError } from '@payments/errors';
import { Offer } from '@payments/types';

interface ModalProps {
  handleClose: (any) => void;
  orderName: string;
  successMessage?: string;
}

const PurchaseConfirmation: React.FC<Offer & ModalProps> = (props) => {
  const { handleClose, nextPath, orderName, successMessage, successPath } = props;
  const { cardBrand, cardLast4, cardName, paymentMethod, stripePaymentMethodId, zipCode } = props.paymentDetails;

  const SUCCESS = 'success';
  const ERROR = 'error';

  const authenticityToken = useAuthenticityToken();
  const [purchaseAttemptResult, setPurchaseAttemptResult] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const stripe = useStripe();

  const taxAmount = props.order.pricing.tax;
  const totalAmount = props.order.pricing.total;

  const handlePurchase = async () => {
    setIsSubmitting(true);

    const values = {
      authenticityToken,
      cardName,
      cohortId: props.order.subscriptionPlan.cohortId,
      paymentMethod,
      planId: props.order.subscriptionPlan.id,
      pricing: props.order.pricing,
      stripePaymentMethodId,
      zipCode
    };

    try {
      const response = await api.createSubscription(stripe, values);
      if (response.nextUrl) {
        setPurchaseAttemptResult(SUCCESS);
        setIsSubmitting(false);
      } else {
        throw new PaymentError(
          genericErrorMessage,
          `Unexpectedly reached the end of handlePurchase in Confirmation with values ${JSON.stringify(values)} and response ${JSON.stringify(response)}`
        );
      }
    } catch(exception) {
      setErrorMessage(exception.errors._error || genericErrorMessage)
      bugsnagNotify(exception.debug);
      setPurchaseAttemptResult(ERROR);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {!purchaseAttemptResult && (
        <div className="d-flex flex-column gap-s u-margin-B-m">
          <div>
            Click "Purchase" below to confirm your purchase.
          </div>

          <div className="d-flex flex-column gap-xxs">
            <div>
              <strong>{props.order.subscriptionPlan.name}</strong>
            </div>

            {
              !!taxAmount && (
                <>
                  <div>
                    Plan: {formatPrice(totalAmount - taxAmount, 'usd', true)}
                  </div>

                  <div>
                    Sales Tax: {formatPrice(taxAmount, 'usd', true)}
                  </div>
                </>
              )
            }

            <div>
              <strong>Total: {formatPrice(totalAmount, 'usd', true)}</strong>
            </div>
          </div>

          <div>
            Payment Method{' '}
            <small>
              (
              <a
                className="text-gray"
                href={props.orderUrl}
                target="_blank">
                change
              </a>
              )
            </small>
            :{' '}
            <span className="text-underdash">
              {capitalize(cardBrand)} ending in {cardLast4}
            </span>
          </div>
        </div>
      )}

      {!purchaseAttemptResult && (
        <div className="d-flex full-width">
          <div style={{ flex: 1 }}>
            <SecondaryButton block onClick={handlePurchase} submitting={isSubmitting}>
              Purchase
            </SecondaryButton>
          </div>

          <div className="u-margin-L-s" style={{ flex: 1 }}>
            <DefaultButton block onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </DefaultButton>
          </div>
        </div>
      )}

      {purchaseAttemptResult === SUCCESS && (
        <div>
          <Alert bsStyle="success">
            <p>
              Success! You have been charged{' '}
              {formatPrice(totalAmount, 'usd', true)}
              {
                !!taxAmount && (
                  <small> ({formatPrice(totalAmount - taxAmount, 'usd', true)} + {formatPrice(taxAmount, 'usd', true)} sales tax)</small>
                )
              }.
            </p>

            {successMessage && <p>{successMessage}</p>}
          </Alert>

          <DefaultButton block href={successPath || nextPath || '/dashboard'}>
            {successPath || nextPath ? 'Continue' : 'Back to Dashboard'}
          </DefaultButton>
        </div>
      )}

      {purchaseAttemptResult === ERROR && (
        <div>
          <Alert bsStyle="danger">
            {errorMessage ? (
              <span dangerouslySetInnerHTML={{__html: errorMessage }} />
            ) : (
              <>
                Oops, something went wrong with this purchase! Try{' '}
                <a
                  className="text-gray"
                  href={paths.subscriptions(props.order.subscriptionPlan.planSlug)}
                  target="_blank">
                  changing your payment method
                </a>{' '}
                or continue on to your test prep dashboard.
              </>
            )}
          </Alert>

          <DefaultButton block href={nextPath || '/dashboard'}>
            Continue
          </DefaultButton>
        </div>
      )}
    </>
  );
};

const FormWrapper: React.FC<Offer & ModalProps> = (props) => {
  const stripePromise = loadStripe(props.order.stripeToken);
  return <Elements stripe={stripePromise}>{props.children}</Elements>;
};

export const WrappedConfirmation: React.FC<Offer & ModalProps> = (props) => {
  return (
    <FormWrapper {...props}>
      <PurchaseConfirmation {...props} />
    </FormWrapper>
  );
};

export default WrappedConfirmation;
