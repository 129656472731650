import { connect } from 'react-redux';
import { RootStore } from '@magoosh/store';
import Assignments from './template';

import {
  assignmentCreated,
  assignmentDeleted,
  hideAssignmentDialog,
  hideDeleteAssignmentDialog,
  showAssignmentDialog,
  showDeleteAssignmentDialog
} from '../actions';

const mapStateToProps = (state: RootStore) => {
  return {
    assignments: state.assignments.assignments,
    assignmentDialogOpen: state.assignments.assignmentDialogOpen,
    deleteAssignmentDialogOpen: state.assignments.deleteAssignmentDialogOpen,
    teacherMode: state.assignments.teacherMode
  };
};

export default connect(mapStateToProps, {
  assignmentCreated,
  assignmentDeleted,
  showAssignmentDialog,
  hideAssignmentDialog,
  showDeleteAssignmentDialog,
  hideDeleteAssignmentDialog
})(Assignments);
