import React from 'react';
import { useParams } from 'react-router-dom';

import CardLayout from '@magoosh/layouts/card';
import { paths } from 'config/path_helpers';

import { useQueries } from '@test_prep/app/queries';
import ContextualLink from '@test_prep/app/components/contextual_link';

import MockTestResults from './mock_test';
import OfficialQuestionSetResults from './official_question_set';
import { MockTest, ScoreReport } from './types';
import ViewingSharedResultsBanner from './viewing_shared_results_banner';

interface PageProps {
  mockTest: MockTest;
  scoreReport: ScoreReport;
  ui?: {
    saleMessage: string;
    showBasicsContentNextStep?: boolean;
  }
}

const ShowMockTest: React.FC<{}> = (props) => {
  const { id, shareableId } = useParams();
  const q = useQueries();

  const pagePath = shareableId ? paths.practiceTestsShare(shareableId) : paths.practiceTest(id);
  const page = q.pageQuery(pagePath).data as PageProps;

  const breadcrumbs = () => {
    if (!page) {
      return [
        { name: 'Home', path: paths.dashboard() }
      ];
    } else if (page.mockTest.isOfficialQuestions) {
      return [
        { name: 'Home', path: paths.dashboard() },
        { name: 'Official Questions', path: paths.officialQuestions() },
        { name: 'Results' }
      ];
    } else {
      return [
        { name: 'Home', path: paths.dashboard() },
        { name: 'Practice Tests', path: paths.practiceTests() },
        { name: 'Results' }
      ];
    }
  };

  return (
    <>
      {
        page && shareableId && (
          <ViewingSharedResultsBanner
            mockTestId={page.mockTest.id}
            userName={page.mockTest.user.name}
          />
        )
      }

      <CardLayout breadcrumbs={breadcrumbs()} linkComponent={ContextualLink} loading={!page}>
        {({ loading }) => {
          if (loading) return;

          if (page.mockTest.isOfficialQuestions) {
            return <OfficialQuestionSetResults mockTest={page.mockTest} />
          } else {
            return <MockTestResults {...page} />;
          }
        }}
      </CardLayout>
    </>
  );
};

export default ShowMockTest;
