import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone'
import { Pagination } from 'react-bootstrap'

import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { DangerBadge, DefaultBadge, SuccessBadge, WarningBadge } from '@magoosh/lib/badge';
import { adminPaths } from 'config/path_helpers';
import { pluralize } from 'utilities/pluralize';

import * as api from '@admin/app/api';
import ContextualLink from '@admin/app/components/contextual_link';

import style from './style.module.scss';

interface Props {
  period: {
    from: number;
    to: number;
  };
  rateable: {
    id: number;
    type: string;
  };
}

const Ratings: React.FC<Props> = (props) => {
  const [currentRatings, setCurrentRatings] = useState(null);
  const [isFetchingPage, setIsFetchingPage] = useState(false);
  const [page, setPage] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [ratingsPages, setRatingsPages] = useState([]);
  const [ratingsSummary, setRatingsSummary] = useState(null);

  const perPage = 10;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  useEffect(() => {
    if (!page) return;
    if (ratingsPages[page - 1]) {
      setCurrentRatings(ratingsPages[page - 1]);
      return;
    }

    setIsFetchingPage(true);
    fetchPage().then((ratings) => {
      setIsFetchingPage(false);
      setCurrentRatings(ratings);

      const updatedRatingsPages = ratingsPages.map((ratingsPage) => ratingsPage ? [...ratingsPage] : null);
      updatedRatingsPages[page - 1] = ratings;
      setRatingsPages(updatedRatingsPages);
    });
  }, [page]);

  useEffect(() => {
    if (!props.period) return;
    if (!props.rateable) return;
    if (ratingsSummary) return;

    fetchSummary().then((summary) => {
      setRatingsSummary(summary);
      const pageCount = Math.ceil(summary.count / perPage);
      setPageCount(pageCount);
      setRatingsPages(Array(pageCount).fill(null));
      setPage(1);
    });
  }, [props]);

  const fetchPage = () => {
    return api.fetchRatings(
      props.rateable.id,
      props.rateable.type,
      {
        page,
        per_page: perPage,
        period_end: props.period.to,
        period_start: props.period.from,
        with_feedback: 'true'
      }
    );
  };

  const fetchSummary = () => {
    return api.fetchRatingsSummary(
      props.rateable.id,
      props.rateable.type,
      {
        period_end: props.period.to,
        period_start: props.period.from
      }
    );
  };

  if (ratingsSummary) {
    return (
      <div className="d-flex flex-column gap-s">
        <div className="d-flex gap-xs">
          This version has received a like rate of
          {(() => {
            const percentage = Math.round(ratingsSummary.rating * 100);

            if (ratingsSummary.count === 0 || ratingsSummary.rating === 1.0) {
              return <DefaultBadge>{percentage}%</DefaultBadge>
            } else if (ratingsSummary.upperConfidenceBound80 <= 0.8) {
              return <DangerBadge>{percentage}%</DangerBadge>
            } else if (ratingsSummary.upperConfidenceBound80 <= 0.9) {
              return <WarningBadge>{percentage}%</WarningBadge>
            } else {
              return <SuccessBadge>{percentage}%</SuccessBadge>
            }
          })()}
          from {pluralize(ratingsSummary.count, 'rating')}.
        </div>

        {
          currentRatings && currentRatings.length > 0 && (
            <div className="d-flex flex-column gap-s" style={{ position: 'relative' }}>
              {
                isFetchingPage && (
                  <div
                    style={{
                      left: '50%',
                      position: 'absolute',
                      top: 10,
                      transform: 'translateX(-50%)',
                      zIndex: 10
                    }}
                  >
                    <LoadingIndicator />
                  </div>
                )
              }

              <div><small>Ratings with feedback</small></div>

              <table className="table">
                <thead>
                  <th>Student</th>
                  <th>Rating</th>
                  <th>Feedback</th>
                  <th>Date</th>
                </thead>

                <tbody>
                  {
                    currentRatings.map((rating, idx) => (
                      <tr key={idx}>
                        <td width="30%">
                          <ContextualLink href={adminPaths.user(rating.user.id)}>
                            {rating.user.email || `Student #${rating.user.id}`}
                          </ContextualLink>
                        </td>

                        <td>
                          {
                            rating.rating === 1 ? (
                              <SuccessBadge>Liked</SuccessBadge>
                            ) : (
                              <DangerBadge>Disliked</DangerBadge>
                            )
                          }
                        </td>

                        <td width="40%">
                          <em>{rating.text}</em>
                        </td>

                        <td>
                          {moment(rating.createdAt * 1000).tz(timeZone).format('LLL')}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

              <div>
                <Pagination>
                  <Pagination.Item disabled={page === 1} onClick={() => setPage(1)}>
                    « First
                  </Pagination.Item>
                  <Pagination.Item disabled={page === 1} onClick={() => setPage(page - 1)}>
                    ‹ Prev
                  </Pagination.Item>
                  <Pagination.Ellipsis disabled />

                  {
                    page - 4 > 0 && (
                      <Pagination.Item className={style.item} onClick={() => setPage(page - 4)}>
                        {page - 4}
                      </Pagination.Item>
                    )
                  }
                  {
                    page - 3 > 0 && (
                      <Pagination.Item className={style.item} onClick={() => setPage(page - 3)}>
                        {page - 3}
                      </Pagination.Item>
                    )
                  }
                  {
                    page - 2 > 0 && (
                      <Pagination.Item className={style.item} onClick={() => setPage(page - 2)}>
                        {page - 2}
                      </Pagination.Item>
                    )
                  }
                  {
                    page - 1 > 0 && (
                      <Pagination.Item className={style.item} onClick={() => setPage(page - 1)}>
                        {page - 1}
                      </Pagination.Item>
                    )
                  }
                  <Pagination.Item active className={style.item}>{page}</Pagination.Item>
                  {
                    page + 1 <= pageCount && (
                      <Pagination.Item className={style.item} onClick={() => setPage(page + 1)}>
                        {page + 1}
                      </Pagination.Item>
                    )
                  }
                  {
                    page + 2 <= pageCount && (
                      <Pagination.Item className={style.item} onClick={() => setPage(page + 2)}>
                        {page + 2}
                      </Pagination.Item>
                    )
                  }
                  {
                    page + 3 <= pageCount && (
                      <Pagination.Item className={style.item} onClick={() => setPage(page + 3)}>
                        {page + 3}
                      </Pagination.Item>
                    )
                  }
                  {
                    page + 4 <= pageCount && (
                      <Pagination.Item className={style.item} onClick={() => setPage(page + 4)}>
                        {page + 4}
                      </Pagination.Item>
                    )
                  }

                  <Pagination.Ellipsis disabled />
                  <Pagination.Item disabled={page === pageCount} onClick={() => setPage(page + 1)}>
                    Next ›
                  </Pagination.Item>
                  <Pagination.Item disabled={page === pageCount} onClick={() => setPage(pageCount)}>
                    Last »
                    </Pagination.Item>
                </Pagination>
              </div>
            </div>
          )
        }
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center u-margin-T-l">
        <LoadingIndicator />
      </div>
    );
  }
};

export default Ratings;
