import React from 'react';
import { ResponsiveLine } from '@nivo/line'
import { uniq } from 'lodash';

import { ColorPalette } from 'types/constants';

import COLORS from '../colors';
import ChartContainer from '../container';
import Legend from '../legend'

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from '../style.module.scss';

interface Props {
  chartProps?: any;
  data: {
    color?: string;
    data: {
      fuzzyRange?: {
        high: number;
        low: number;
      };
      x: number | string;
      y: number;
    }[];
    id: string;
  }[];
  height: number;
  hideLegend?: boolean;
  legendPosition?: 'bottom' | 'left' | 'right' | 'top';
}

const LineChart: React.FC<Props> = (props) => {
  const formattedData = (sourceData) => {
    return sourceData.map((d, idx) => (
      { color: COLORS[idx % COLORS.length], ...d }
    ));
  };

  const legendGroups = (sourceData) => {
    return sourceData.map((datum) => (
      {
        // Every datum has the same color data so we can just look at the first one
        colors: [datum.color],
        name: datum.id
      }
    ));
  };

  const data = formattedData(props.data);

  return (
    <ChartContainer>
      <div
        className={style.chart}
        style={{ height: props.height, position: 'relative', width: '100%' }}
      >
        <ResponsiveLine
          colors={uniq(data.map((d) => d.color))}
          curve="monotoneX"
          data={data}
          enableGridY={false}
          lineWidth={6}
          margin={{ bottom: 30, left: 60, right: 20, top: 20 }}
          pointSize={15}
          {...props.chartProps}
        />
      </div>

      {!props.hideLegend && <Legend groups={legendGroups(data)} />}
    </ChartContainer>
  );
};

export default LineChart;
