import * as React from 'react';
import { AnswerPart, PromptData } from './types';
import cx from 'classnames';
import style from './style.module.scss';

interface RadioProps {
  prompt: PromptData;
  review?: boolean;
}

export default function RadioInput(props: RadioProps) {
  const prompt = props.prompt;
  const answer = props.prompt.userAnswers && props.prompt.userAnswers[0];
  return (
    <div className="answer_radio answer-inputs">
      {prompt.errors && <p className={style.error}>Please select an answer.</p>}
      <fieldset className="radio choices controls">
        {prompt.answerChoiceGroups.map((choiceGroup) => {
          return choiceGroup.answerChoices.map((choice) => {
            const part = answer && answer.parts.find((part) => part.answerChoiceId === choice.id);

            return (
              <div className="radio" key={choice.id}>
                <label className="choice answer" key={choice.id}>
                  {renderGrade(part, props.review, choice.isCorrect)}
                  <input
                    type="radio"
                    name={`prompts[${prompt.id}][${choice.answerChoiceGroupId}][choice_id][]`}
                    defaultChecked={part !== undefined}
                    value={choice.id}
                    disabled={props.review}
                  />
                  <span dangerouslySetInnerHTML={{ __html: choice.text }} />
                </label>
              </div>
            );
          });
        })}
      </fieldset>
    </div>
  );
}

// Render an Incorrect icon if this choice was selected and is incorrect.
// Render a Correct icon if this choice is correct.
// Return null if this answer is not being reviewed.
function renderGrade(part: AnswerPart, review: boolean, isCorrect?: boolean) {
  if (!review || (!part && !isCorrect)) {
    return null;
  }

  return <i className={cx('choice-grade', isCorrect ? 'correct' : 'incorrect')}></i>;
}
