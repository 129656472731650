import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from '@magoosh/lib/data_table';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Search } from '@magoosh/lib/data_table/search';
import { DateRangeFilter } from '@magoosh/lib/data_table/date_range_filter';
import { DownloadButton } from '@magoosh/lib/data_table/download_button';
import styles from '../style.module.scss';
import colors from '@magoosh/b2b/app/assets/style.module.scss';
import { Icon } from '@magoosh/b2b/app/assets/icons';
import useReportingFilterOptions from '@magoosh/b2b/app/pages/reporting/reporting_filter_options';
import { AppContext } from '@magoosh/b2b/app/app_context';

export const Subjects: React.FC<SubjectsProps> = (props) => {
  const appContext = useContext(AppContext);
  const [filterOptions, setFilterOptions] = useState({ schoolAndClassSections: [] });

  const { queryParameters, runQuery, dataQueryTableProps } = useDataQuery(
    paths.api.partner.subjects,
    {
      sortColumn: 'name',
      filters: props.studentId ? { studentId: props.studentId } : {}
    }
  );

  const { reportingFiltersDropdown } = useReportingFilterOptions(runQuery);

  return (
    <>
      <DataTable
        {...dataQueryTableProps}
        primaryAttribute={{ selector: 'name', name: 'Subject', sortable: true }}
        columns={[
          { selector: 'answersCount', name: 'Questions Answered', sortable: true },
          {
            selector: 'percentCorrect',
            name: 'Percent Correct',
            sortable: true,
            format: (subject) => {
              if (subject.percentCorrect) {
                return `${Math.round(subject.percentCorrect * 100)}%`;
              }
            }},
        ]}>
        <DataTable.Filters>
          <div className={styles.filters}>
            <Search runQuery={runQuery} />
            <DateRangeFilter runQuery={runQuery} />
            {!props.studentId && reportingFiltersDropdown}
          </div>
        </DataTable.Filters>
        <DataTable.HeaderActions>
          <DownloadButton
            queryParameters={queryParameters}
            fetchUrl={paths.api.partner.subjects}
            fileName={'subject_data.csv'}
            disabled={appContext.trialAccess}
          />
        </DataTable.HeaderActions>
        <DataTable.ItemIcon>
          {(subject) => <Icon iconType="Subject" fill={colors.supplementaryBayOfMany} size="m" />}
        </DataTable.ItemIcon>
      </DataTable>
    </>
  );
};
