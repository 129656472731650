import React from 'react';
import { NamePlateProps } from './types';
import style from './style.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';

export const NamePlate: React.FC<NamePlateProps> = (props) => {
  const { icon, headline, subheadline, linkUrl } = props;
  return (
    <div className={style.namePlate}>
      <div className={cx(style.icon, 'u-margin-R-xxs')}>{icon}</div>
      <div className={style.info}>
        <div className={style.headline}>{linkUrl ? <Link to={linkUrl}>{headline}</Link> : headline}</div>
        <div>{subheadline}</div>
      </div>
    </div>
  );
};

export default NamePlate;
