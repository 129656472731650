import React, { memo } from 'react';
import { isEqual } from 'lodash';

import { Interaction } from '@test_prep/app/ai_tutor/types';

import ActivityOffer from './activity_offer';
import Chat from './chat';
import ChatHint from './chat_hint';
import PromptActivity from './prompt_activity';
import TimeAgo from '../time_ago';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  interaction: Interaction;
}

// This component is used to show a read only view of a tutoring session in admin
const ReadOnlyInteractionCard: React.FC<Props> = (props) => {
  const isUser = props.interaction.role === 'user';

  const renderInteraction = () => {
    if (props.interaction.type === 'chat_hint') {
      return <ChatHint onSelected={() => {}} {...props} />;
    } else if (props.interaction.type === 'chat') {
      return <Chat {...props} />;
    } else if (props.interaction.type === 'activity_offer') {
      return <ActivityOffer addOrUpdateUserInteractions={() => {}} {...props} />;
    } else if (props.interaction.type === 'prompt_activity') {
      return (
        <div className="d-flex flex-column gap-s">
          <a href={`/admin/ai_prompts/${props.interaction.content.aiPromptId}`}>
            AIPrompt #{props.interaction.content.aiPromptId}
          </a>

          {
            props.interaction.activityContent.aiPrompt.rating && (
              <>
                <div>Rating: {props.interaction.activityContent.aiPrompt.rating.rating === 1 ? 'Like' : 'Dislike'}</div>
                <div>Text: {props.interaction.activityContent.aiPrompt.rating.text}</div>
              </>
            )
          }

          <PromptActivity onClick={() => {}} {...props} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className="d-flex flex-column gap-s u-padding-A-s"
      style={{
        backgroundColor: isUser ? 'none' : colors.grayLightest,
        border: isUser ? `1px solid ${colors.grayLightest}` : 'none',
        borderRadius: 8
      }}
    >
      <div className="align-items-center d-flex gap-s">
        {
          isUser ? (
            <div className="text-bold">You</div>
          ) : (
            <svg width="27" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.122 4.039c-1.847 0-3.515.715-4.825 1.846C13.642 2.61 10.723.107 7.207.107A7.195 7.195 0 0 0 .001 7.315a7.195 7.195 0 0 0 7.208 7.208c1.847 0 3.515-.715 4.825-1.847.655 3.277 3.574 5.779 7.089 5.779a7.195 7.195 0 0 0 7.208-7.208c0-3.932-3.217-7.208-7.208-7.208Zm-6.195 1.31-6.553 6.672c-.06.06-.179.06-.298 0l-4.408-4.23c-.06-.059-.06-.178 0-.297l1.191-1.251c.06-.06.18-.06.298 0l2.92 2.74c.059.06.118.06.118 0l5.123-5.063c.06-.06.179-.06.298 0l1.31 1.191c.06 0 .06.12 0 .238Zm5.36 10.604c-.059.06-.178.06-.297 0l-4.408-4.23c-.06-.06-.06-.178 0-.298l1.191-1.25c.06-.06.179-.06.298 0l2.92 2.74c.059.06.118.06.118 0l5.123-5.064c.06-.06.179-.06.298 0l1.31 1.192c.06.06.06.178 0 .298l-6.552 6.612Z" fill="#27B67C"/><path d="m13.106 5.111-6.553 6.672c-.06.06-.178.06-.297 0l-4.409-4.23c-.06-.06-.06-.178 0-.297L3.04 6.005c.06-.06.179-.06.298 0l2.919 2.74c.06.06.119.06.119 0l5.123-5.064c.06-.06.178-.06.298 0l1.31 1.192a.18.18 0 0 1 0 .238Zm5.361 10.604c-.06.06-.178.06-.297 0l-4.409-4.23c-.06-.06-.06-.179 0-.298l1.192-1.25c.06-.06.179-.06.298 0l2.919 2.74c.06.059.119.059.119 0l5.123-5.064c.06-.06.178-.06.297 0l1.311 1.191c.06.06.06.18 0 .298l-6.553 6.612Z" fill="#fff"/></svg>
          )
        }

        <div className="text-gray" style={{ fontSize: 12 }}>
          <TimeAgo time={props.interaction.finishedAt || props.interaction.createdAt} />
        </div>
      </div>

      {renderInteraction()}
    </div>
  );
};

export default memo(ReadOnlyInteractionCard, (oldProps, newProps) => {
  return isEqual(oldProps.interaction, newProps.interaction)
});
