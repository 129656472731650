import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';

// School profiles are proxied from magoosh.com/schools. This means that for any API calls we need
// to specify the host so that the requests go to our Rails app. In the current setup, host will be
// the schoolprofiles.magoosh.com subdomain.
export const fetchSchoolProfile = (host: string, slug: string, categorySlug: string, preview: boolean) => {
  return fetch(`//${host}${paths.api.admissions.schoolProfile(slug, categorySlug, preview)}`).then((response) => {
    return response.profile;
  });
};

export const fetchSchoolProfileCategory = (host: string, slug: string, preview: boolean) => {
  return fetch(`//${host}${paths.api.admissions.schoolProfileCategory(slug, preview)}`).then((response) => {
    return response.category;
  });
};

export const fetchSchoolProfileMetric = (host: string, slug: string, categorySlug: string) => {
  return fetch(`//${host}${paths.api.admissions.schoolProfileMetric(slug, categorySlug)}`).then((response) => {
    return response.metric;
  });
};
