import React from 'react';

const ACTDetailsContent: React.FC = (props) => {
  return (
    <>
      <h4>Preparation</h4>
      <p>Just like with the real test, you should have a <strong>pencil</strong>, a <strong>calculator</strong> and <strong>scratch paper</strong> to write notes and work out problems.</p>
      <p>You should also set aside <strong>between 2 hours - 3 hours and 45 minutes</strong> depending on the version of the test you are taking. Let your family and friends know not to interrupt you during this time. :)</p>
      <p>We recommend taking the entire test in one sitting to practice your pacing and build your test-taking stamina, which you'll need on test day. Good luck!</p>
    </>
  );
};

export default ACTDetailsContent;
