import * as React from 'react';
import cx from 'classnames';

import Label, { LabelType } from '../label';
import ProgressBar from '@magoosh/lib/progress_bar';

import style from './style.module.scss';
import Trackable from '@magoosh/utils/trackable';
import { ExamSectionData } from '../types';
import { paths } from '../../../../../config/path_helpers';
import { SecondaryButton } from '@magoosh/lib/button';

interface ExamSectionCardProps {
  examSection: ExamSectionData;
  wide?: boolean;
}

export default function ExamSectionCard(props: ExamSectionCardProps) {
  const examSection = props.examSection;
  const promptCountLimit = props.examSection.practiceGroupType === 'prompt' ? 10 : 1;
  const labelType: LabelType = (examSection.scoreStatus.type || 'default') as LabelType;
  const percentage = Math.round((examSection.questionCounts.answered / examSection.questionCounts.total) * 100);

  let estimatedScoreString;
  if (typeof examSection.estimatedScore === 'object' && examSection.estimatedScore !== null) {
    estimatedScoreString = `${examSection.estimatedScore.scoreLow} - ${examSection.estimatedScore.scoreHigh}`;
  } else {
    estimatedScoreString = examSection.estimatedScore;
  }

  return (
    <div className="d-flex flex-column">
      <a className={style.examSectionCardLink} href={`/dashboard/${examSection.slug}`}>
        <Trackable name="section_card" properties={{ label: examSection.slug, value: examSection.scoreStatus.label }}>
          <div className={cx(style.examSectionCard, 'text-center', props.wide ? style.wide : null)}>
            <div className={style.title}>{examSection.displayName}</div>
            <div className={style.subtitle}>{examSection.subtitle}</div>

            <ProgressBar
              backgroundColor="#cef0e3"
              color="#26B67C"
              percentage={Math.max(percentage, 10)}
              size="sm"
            />
            <small>Questions Answered</small>

            {examSection.estimatedScore && (
              <div>
                <h3 className="u-margin-B-n u-margin-T-s">
                  <strong>{estimatedScoreString}</strong>
                </h3>
                <small>Estimated Score</small>
              </div>
            )}

            <div className="text-gray-light u-margin-V-xxs">
              {examSection.previousScore && <small>Previous {examSection.previousScore}</small>}
            </div>

            <Label type={labelType}>{examSection.scoreStatus.label || 'Status'}</Label>
          </div>
        </Trackable>
      </a>
      <form id='start_practice_form' className='d-flex flex-column' action={paths.examSectionPracticeSession(examSection.id)}>
        <input type='hidden' name='practice_session[prompt_count_limit]' value={promptCountLimit}/>
        <SecondaryButton type='submit'>
          Practice {examSection.displayName}
        </SecondaryButton>
      </form>
    </div>
  );
}
