import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import { EditorialText } from '../types';

const ContentfulEditorialText: React.FC<EditorialText & { className?: string; }> = (props) => {
  return (
    <div className={props.className} dangerouslySetInnerHTML={{ __html: documentToHtmlString(props.content) }} />
  );
};

export default ContentfulEditorialText;
