import * as React from 'react';
import { LessonGroupsData, LessonSeriesData, LessonSubsectionData, PieData } from './types';
import ProgressBar from '@magoosh/lib/progress_bar';
import Collapsible from '@magoosh/high_school/pages/assignments/assignment_dialog/collapsible';
import style from './style.module.scss';
import cx from 'classnames';
import { ResponsivePie } from '@nivo/pie';
import NavLink from '@magoosh/organisms/navbars/nav_link';
import { paths } from 'config/path_helpers';
import { pluralize } from 'utilities/pluralize';
import needsWorkBadge from './assets/needs_work.png';
import notStartedBadge2 from './assets/not_started2.png';
import passedBadge from './assets/passed.png';
import Card from '@magoosh/lib/card';
import nodoc from './assets/nodoc.svg';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

export default class LessonGroup extends React.Component<LessonGroupsData> {
  render() {
    const currentSection = this.props.sections.find((section) => section.selected);
    const showQuizPerformance = !!this.props.stats.quizPerformance;

    return (
      <div>
        <h1>Lessons</h1>
        {this.renderNav()}

        <Card shadow className={style.dataBar}>
          <h3>{currentSection.name} Stats</h3>
          <div className={style.dataBarData}>
            <div className={style.pie1}>
              <h4 className={cx(style.pieTitle, 'text-center')}>Series Progress</h4>
              <div className={style.pie}>{this.renderPie(this.props.stats.seriesProgress)}</div>
            </div>
            {showQuizPerformance ? this.renderQuizPie() : this.renderNoPie()}
            <div className={cx(style.notesSummary, 'text-center')}>
              <h4>Notes</h4>
              <div className={cx(style.notesText, 'u-margin-T-xl')}>
                <p>
                  {this.props.notes.total > 0 ? (
                    <React.Fragment>
                      You've created{' '}
                      <a href={paths.examSectionNotes(currentSection.slug)}>
                        {pluralize(this.props.notes.total, 'note', 'notes')}
                      </a>{' '}
                      on this topic. Keep up the good work!
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      You haven't taken any notes on lesson videos in this topic yet.
                    </React.Fragment>
                  )}
                  <a
                    className={cx(style.notesButton, 'btn btn-default')}
                    href={paths.examSectionNotes(currentSection.slug)}>
                    View All Notes
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Card>

        {this.props.subsections.map((subsection) => {
          const startOpen = !subsection.series.every((subsection) => subsection.status === 'completed');
          return (
            <div className="mcatLessonGroup" key={subsection.name}>
              <Collapsible
                header={this.subsectionHeader(subsection)}
                startOpen={startOpen}
                key={subsection.name}>
                <div>{subsection.series.map(this.renderSeries)}</div>
              </Collapsible>
            </div>
          );
        })}
      </div>
    );
  }

  renderSeries = (series: LessonSeriesData) => {
    const percentage = ((series.totalTime - series.timeRemaining) * 100) / series.totalTime;
    return (
      <a href={paths.lessonSeries(series.slug)} className={style.seriesLink}>
        <Card
          key={series.name}
          shadow
          className={cx(
            style.moduleCard,
            series.timeRemaining == 0 ? style.completed : style.incomplete,
            series.locked ? style.locked : ''
          )}>
          <div className={style.shieldContainer}>{this.renderShield(series)}</div>
          <h4>{series.name}</h4>
          <div className={style.lock}>
            {series.locked && (
              <FontAwesomeIcon icon={'lock'} />
            )}
          </div>
          <div className={style.timeEstimate}>
            {series.status == 'not_started' ? (
              <div>{series.totalTime} min</div>
            ) : (
              <ProgressBar
                backgroundColor={'lightgray'}
                color={'green'}
                percentage={percentage}
                size={'sm'}
              />
            )}
          </div>
        </Card>
      </a>
    );
  };

  renderShield = (series: LessonSeriesData) => {
    switch (series.quizStatus) {
      case 'not_started':
        return <img src={notStartedBadge2} />;
      case 'needs_work':
        return <img src={needsWorkBadge} />;
      case 'passed':
        return <img src={passedBadge} />;
      case 'none':
        return null;
    }
  };

  subsectionHeader = (subsection: LessonSubsectionData) => {
    const completed = subsection.series.filter((series) => {
      return series.status === 'completed';
    }).length;

    return (
      <div className={style.subsectionHeader}>
        <a className={style.subsectionAnchor} id={subsection.slug}></a>
        <h3>{subsection.name}</h3>
        <div className={style.subsectionProgress}>
          Series completed {completed}/{subsection.series.length}
        </div>
      </div>
    );
  };

  renderNav = () => {
    return (
      <div className={style.navLinks}>
        {this.props.sections.map((section) => {
          return (
            <NavLink
              key={section.slug}
              name={section.name}
              url={paths.mcatLessonGroups(section.slug)}
              selected={section.selected}
            />
          );
        })}
      </div>
    );
  };

  renderPie = (data: PieData[], textColor = 'black') => {
    return (
      <ResponsivePie
        data={data}
        colors={data.map((datum) => datum.color)}
        fit={true}
        margin={{ top: 80, bottom: 80, left: 80, right: 80 }}
        arcLabelsSkipAngle={15}
        arcLinkLabelsSkipAngle={20}
        arcLabelsTextColor={textColor}
        borderColor="white"
        borderWidth={2}
        cornerRadius={3}
        padAngle={1}
        arcLinkLabelsDiagonalLength={4}
        arcLinkLabelsStraightLength={8}
        arcLinkLabel={(datum) => `${datum.value} ${datum.label}`}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            itemWidth: 64,
            itemHeight: 16,
            itemsSpacing: 24,
            itemDirection: 'top-to-bottom',
            // symbolSize: 16,
            translateY: 40,
            symbolShape: 'circle'
          }
        ]}
      />
    );
  };

  renderQuizPie = () => {
    return (
      <div className={cx(style.pie, style.pie2)}>
        <h4 className={cx('text-center', style.pieTitle)}>Quiz Progress</h4>
        {this.renderPie(this.props.stats.quizPerformance, 'white')}
      </div>
    );
  };

  renderNoPie = () => {
    return (
      <div className="text-center">
        <h4>Quiz Progress</h4>
        <img className={style.nodoc} src={nodoc} />
        <p className="u-margin-T-xl">No quizzes available in this section</p>
      </div>
    );
  };
}
