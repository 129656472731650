import * as moment from 'moment-timezone';
import { pluralize } from 'utilities/pluralize';

export function secondsFormattedAsMinutes(seconds, truncateWhenNoSeconds = true) {
  const minutes = Math.floor(seconds / 60);
  const secondsRemaining = Math.floor(seconds) - (minutes * 60);
  if (secondsRemaining === 0 && truncateWhenNoSeconds) return `${minutes}`;

  const zeroPadding = secondsRemaining < 10 ? '0' : '';
  return `${minutes}:${zeroPadding}${secondsRemaining}`;
}

export function secondsFormattedAsHoursAndMinutes(seconds) {
  if (seconds >= (60 * 60)) {
    return moment().startOf('day')
      .seconds(seconds)
      .format('H:mm:ss');
  }

  return secondsFormattedAsMinutes(seconds);
}

export function secondsFormattedAsMinutesString(seconds) {
  const minutes = Math.floor(seconds / 60);
  const secondsRemaining = Math.floor(seconds) - (minutes * 60);
  if (secondsRemaining === 0) return pluralize(minutes, 'minute');

  `${pluralize(minutes, 'minute')} and ${pluralize(secondsRemaining, 'seconds')}`;
};

export function minutesFormattedasHoursAndMinutesString(minutes) {
  const hoursRemaining = Math.floor(minutes / 60);
  const minutesRemaining = Math.floor(minutes) - hoursRemaining * 60;

  if (hoursRemaining > 0 && minutesRemaining > 0) {
    return `${pluralize(hoursRemaining, 'hour', 'hours')} and ${pluralize(minutesRemaining, 'minute', 'minutes')}`;
  } else if (hoursRemaining > 0) {
    return `${pluralize(hoursRemaining, 'hour', 'hours')}`;
  } else {
    return `${pluralize(minutesRemaining, 'minute', 'minutes')}`;
  }
};

export function minutesFormattedAsShortString(minutes) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = Math.floor(minutes) - hours * 60;

  if (hours > 0 && minutesLeft > 30) {
    return `${hours + 1}h`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutesLeft > 0) {
    return `${minutesLeft}m`;
  } else {
    return '0m';
  }
};

// Returns a range like "5pm-7pm" or "5:30pm-7pm"
// If a momemnt formatting prefix is given, it will append it. For example: "Mon. 5pm-7pm" if the
// extra prefix 'ddd' is given.
export const formatTimeRange = (startTime, endTime, formatPrefix = '') => {
  const formattedStartTime = startTime.minute() !== 0 ? startTime.format(`${formatPrefix}h:mma`) : startTime.format(`${formatPrefix}ha`);
  const formattedEndTime = endTime.minute() !== 0 ? endTime.format('h:mma') : endTime.format('ha');

  return `${formattedStartTime}-${formattedEndTime}`;
};

export const longTimeZoneName = (date) => {
  var abbrs = {
    EST : 'Eastern Time',
    EDT : 'Eastern Time',
    CST : 'Central Time',
    CDT : 'Central Time',
    MST : 'Mountain Time',
    MDT : 'Mountain Time',
    PST : 'Pacific Time',
    PDT : 'Pacific Time'
  };

  moment.fn.zoneName = function () {
      var abbr = this.zoneAbbr();
      return abbrs[abbr] || abbr;
  };

  return date.format('zz');
}
