/**
 * Calculates a percent and can return a formatted string.
 * Returns formatted 0 if denominator is invalid or 0
 */
export function percent(numerator: number, denominator: number): number;
export function percent(numerator: number, denominator: number, format: "n%" | "n.n%"): string;
export function percent(numerator: number, denominator: number, format?: "n%" | "n.n%") {
  let n = numerator || 0;
  let d = denominator || 0;

  // in case of invalid denominator, force the calculation to 0
  if (d == 0) {
    console.warn('divide by 0');
    n = 0;
    d = 100;
  }

  const p = n / d * 100;

  if (!format) {
    return p;
  } else if (format === 'n%') {
    return `${Math.round(p)}%`;
  } else if (format === 'n.n%') {
    return `${p.toFixed(1)}%`;
  }
}
