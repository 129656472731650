import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import { DefaultFilledButton } from '@magoosh/lib/button';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

import { PromptRequest } from './types';

interface Props {
  promptRequest: PromptRequest;
}

const EditPromptRequest: React.FC<Props> = (props) => {
  const [saveResultStatus, setSaveResultStatus] = useState(null);

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);

    fetch(adminPaths.api.promptRequest(props.promptRequest.id), {
      method: 'PUT',
      body: JSON.stringify({
        promptRequest: values
      })
    }).then((response) => {
      setSubmitting(false);

      setSaveResultStatus('success');
      setTimeout(() => setSaveResultStatus(null), 3000);
    }).catch((error) => {
      setErrors(error.errors);
      setSubmitting(false);

      setSaveResultStatus('danger');
      setTimeout(() => setSaveResultStatus(null), 3000);
    });
  };

  const initialValues = {
    name: props.promptRequest.name
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ dirty, errors, isSubmitting }) => (
        <Form>
          <div className="d-flex flex-column">
            <div>
              <FieldInput name="name" type="input" />
            </div>

            <div className="align-items-center d-flex gap-s">
              <div>
                <DefaultFilledButton type="submit" submitting={isSubmitting}>
                  Update
                </DefaultFilledButton>
              </div>

              {
                !!saveResultStatus && (
                  <div className={`text-${saveResultStatus}`}>
                    {saveResultStatus === 'success' ? 'Saved!' : errors._error}
                  </div>
                )
              }
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditPromptRequest;
