import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Modal, Row } from 'react-bootstrap';

import MultiStep from '@magoosh/lib/modal/multistep';
import { RootStore } from '@magoosh/store';
import { recommendedHoursPerWeek } from 'services/study_hours_calculator';
import { saveStudyPreferences } from 'services/study_preferences_saver';
import { StudyPreferences } from 'types/study_preferences';

import DaysStep from './days_step';
import WelcomeStep from './welcome_step';
import StudyPreferencesSetupModalFooter from './footer';
import { studyPreferenceDidSave } from '../actions';
import { selectedStudyDays, calculatedHoursPerWeek } from '../selectors';

import style from './style.module.scss';

interface Props {
  brandName: string;
  calculatedHoursPerWeek: number;
  daysUntilTest?: number;
  firstName?: string;
  onClose: () => void;
  onSaved: () => void;
  savedPreferences: StudyPreferences;
  show: boolean;
  startStep?: number;
  studyDays: string[];
  unsavedPreferences: StudyPreferences;
}

const StudyPreferencesSetupModalContainer: React.FC<Props> = (props) => {
  const {
    brandName,
    calculatedHoursPerWeek,
    daysUntilTest,
    firstName,
    onClose,
    onSaved,
    savedPreferences,
    show,
    startStep,
    studyDays,
    unsavedPreferences
  } = props;

  const [currentStep, setCurrentStep] = React.useState(startStep || 1);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    setCurrentStep(startStep);
  }, [startStep]);

  const handleClose = () => {
    // When we first present this modal to students, we auto display it by specifying
    // `?interaction=study_preferences` in the path. Remove that url param so the modal won't
    // auto display on a page reload or navigation away from the page and then back.
    window.history.replaceState(null, null, '/dashboard');
    onClose();
  };

  const onRequestNextStep = () => {
   if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    } else {
      onSubmit();
    }
  };

  const onRequestPreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      handleClose();
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    
    saveStudyPreferences(unsavedPreferences, savedPreferences)
      .then(() => {
        setIsSubmitting(false);
        onSaved();
        handleClose();
      })
      .catch((e) => {
        setIsSubmitting(false);
        alert('Sorry, an error occurred. Please try again and contact us if the problem persists.');
      });
  };

  return (
    <Modal show={show} onHide={handleClose} bsSize="lg">
      <Modal.Header className="visible-xs" closeButton />
      <Modal.Body>
        <MultiStep
          stepIndex={currentStep - 1}
          footer={
            <StudyPreferencesSetupModalFooter
              currentStep={currentStep}
              onNextStep={onRequestNextStep}
              onPrevStep={onRequestPreviousStep}
              totalSteps={2}
              isLoading={isSubmitting}
            />
          }
        >
          <WelcomeStep
            firstName={firstName}
            brandName={brandName}
          />
          <DaysStep
            daysUntilTest={daysUntilTest}
            calculatedHoursPerWeek={calculatedHoursPerWeek}
            recommendedHoursPerWeek={recommendedHoursPerWeek(daysUntilTest)}
          />
        </MultiStep>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(store: RootStore) {
  return {
    calculatedHoursPerWeek: calculatedHoursPerWeek(store.studyPreferences.studyPreferencesUnsaved),
    studyDays: selectedStudyDays(store.studyPreferences.studyPreferencesUnsaved),
    savedPreferences: store.studyPreferences.studyPreferencesSaved,
    unsavedPreferences: store.studyPreferences.studyPreferencesUnsaved
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSaved: () => {
      dispatch(studyPreferenceDidSave());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudyPreferencesSetupModalContainer);
