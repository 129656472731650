import { SuccessBadge } from '@magoosh/lib/badge';
import moment from 'moment';
import { DangerBadge, WarningBadge } from '@magoosh/lib/badge/template';
import * as React from 'react';

const StatusBadge: React.FC<{submitted: boolean; dueDate: Date}> = (props) => {
  if (props.submitted) { return <SuccessBadge>Completed</SuccessBadge>; }

  if (moment(props.dueDate).isBefore(moment())) {
    return <DangerBadge>Overdue</DangerBadge>
  }

  if (moment(props.dueDate).isBefore(moment().add(1, 'week'))) {
    return <WarningBadge>Due Soon</WarningBadge>
  }

  return null;
}

export default StatusBadge;
