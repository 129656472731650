import React, { useEffect, useState } from 'react';
import { CreateEditSchoolFormValues } from './types';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import colors from '../../assets/style.module.scss';
import PageColumn from '../../lib/page_column';
import Icon from '../../assets/icons';
import fetch from 'utilities/fetch';
import { ErrorMessages } from '@magoosh/lib/formik_inputs/errors_messages';

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string()
});

export const CreateEditSchoolForm: React.FC<{ id?: number }> = ({ id }) => {
  const [school, setSchool] = useState<CreateEditSchoolFormValues>({
    name: '',
    description: ''
  });
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(paths.api.partner.school(id)).then((response) => {
      setSchool(response.school);
    });
  }, [id]);

  const initialValues = {
    name: school.name,
    description: school.description
  };

  const handleOnSubmit = (
    values: CreateEditSchoolFormValues,
    { setSubmitting }: FormikActions<CreateEditSchoolFormValues>
  ): void => {
    setSubmitting(true);
    fetch(paths.api.partner.schools(), {
      body: JSON.stringify(values),
      method: 'POST'
    }).then((response) => history.push(`/schools/${response.id}`));
  };

  return (
    <>
      <PageColumn>
        <div className={style.createEditSchoolForm}>
          <div className={style.formHeader}>
            <div className={style.headerText}>
              <h2 className="u-margin-T-n">{id ? 'Edit School' : 'Create New School'}</h2>
              <h4>
                {id
                  ? 'Add the following details about the existing school you want to edit.'
                  : 'Add the following details about the new school you want to create.'}
              </h4>
            </div>
            <div className="u-margin-L-s">
              <Icon iconType="School" fill={colors.supplementaryCarrot} size="l" />
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}>
            {({ isSubmitting, submitForm, errors }) => (
              <Form>
                <FieldInput name="name" type="input" label="School Name" />
                <FieldInput name="description" type="textarea" label="Description" optional />

                <br />
                <Row className="u-margin-T-s">
                  <Col xs={6}>
                    <SecondaryButton disabled={isSubmitting} onClick={submitForm} block>
                      {id ? 'Edit School' : 'Create New School'}
                    </SecondaryButton>
                  </Col>

                  <Col xs={6}>
                    <DefaultButton onClick={() => history.push('/')} block>
                      Cancel
                    </DefaultButton>
                  </Col>
                </Row>
                <ErrorMessages errors={errors} />
              </Form>
            )}
          </Formik>
        </div>
      </PageColumn>
    </>
  );
};

export default CreateEditSchoolForm;
