import React from 'react';

import BaseButton, { ButtonProps } from '@magoosh/lib/button/base';

import brandColors from './colors';

interface Props extends ButtonProps {
  brand: string;
  children: any;
}

const BrandButton: React.FC<Props> = (props) => {
  const colors = brandColors(props.brand);

  return (
    <BaseButton
      {...props}
      style={{
        backgroundColor: colors.button.background,
        borderColor: colors.button.background,
        color: colors.button.color
      }}
    />
  );
};

export default BrandButton;
