import { SubmissionError } from 'redux-form';
import { paths } from 'config/path_helpers';

const handleErrors = (response, returnFullResponse) => {
  if (response.ok) {
    if (returnFullResponse) {
      return response;
    } else {
      return extractData(response);
    }
  } else if (response.status == 401) {
    // If the session has expired, redirect to login page.
    // TODO: Is it possible to set the return_to_url here?
    window.location.assign(paths.login());
  } else {
    return extractData(response).then((err) => {
      throw new SubmissionError(err);
    });
  }
};

const extractData = (response) => {
  let contentType = response.headers.get('content-type');

  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  } else {
    return response.text();
  }
}

// TODO: Typedef for default func
export default (path, config: RequestInit | any = {}) => {
  let headers = {
    'Content-Type': 'application/json',
    'X-Key-Inflection': config.inflection || 'camel'
  };

  if (config.headers) {
    headers = { ...headers, ...config.headers };
  }

  config = { ...config, credentials: 'same-origin', headers };

  return fetch(path, config).then(response => handleErrors(response, config.returnFullResponse));
};
