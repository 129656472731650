import React from 'react';
import Modal from '@magoosh/lib/modal';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

interface ResetSubmissionModalProps {
  assignment: Assignment;
  student: Person;
  userAssignmentId: number;
  onCancel: () => void;
  onResetAssignmentComplete: () => void;
}

export const ResetSubmissionModal: React.FC<ResetSubmissionModalProps> = (props) => {
  const { assignment, student, userAssignmentId, onCancel, onResetAssignmentComplete } = props;

  if (!assignment || !student) {
    return null;
  }

  return <Modal
    show={assignment != null && student != null}
    onHide={onCancel}
  >
    <p>
      Are you sure you want to reset this student{"'"}s submission?
    </p>
    <p>
      Student: <strong>{student.fullName || student.email}</strong>
      <br/>
      Assignment: <strong>{assignment.title}</strong>
    </p>
    <Modal.Footer>
      <DefaultButton onClick={onCancel}>
        Cancel
      </DefaultButton>
      <SecondaryButton onClick={() => {
        fetch(
          paths.api.resetAssignmentSubmission(userAssignmentId),
          { method: 'PATCH' }
        ).then(() => onResetAssignmentComplete());
      }}>
        Reset Submission
      </SecondaryButton>
    </Modal.Footer>
  </Modal>
}
