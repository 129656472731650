import * as React from 'react';

interface Props {
  onAddFile: (any) => void;
}

export default class TextFileUpload extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        <label className='u-margin-T-xs'>Choose a file with email addresses:</label>
        <input
          className='u-margin-T-xs'
          type='file'
          id='emails'
          name='emails'
          accept='.txt, .csv'
          onChange={e => this.props.onAddFile(e.target.files[0])} />
        <p className='u-margin-T-xs'>
          Supported file types are .txt and .csv.
        </p>
      </React.Fragment>
    );
  }
}
