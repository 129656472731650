import React from 'react';
import * as moment from 'moment';

import { ModalProvider, useModalContext } from '@magoosh/context/modal_context';
import { SecondaryButton } from '@magoosh/lib/button';
import PurchaseConfirmation from '@payments/components/purchase_confirmation';
import { Offer } from '@payments/types';
import { paths } from 'config/path_helpers';
import { upsellPoints } from 'utilities/exam_info';
import { formatPrice } from 'utilities/money';
import { pluralize } from 'utilities/pluralize';

import TrialProfileIllustration from '@magoosh/assets/trial_profile_illustration.svg';
import style from './style.module.scss';

interface ExtendProps {
  currentPlanName: string;
  daysRemaining: number;
  examName: string;
  expirationDate: string;
  extensionType: string;
  offers: Offer[];
  promocode: Promocode;
}

interface Promocode {
  couponCode: string;
  daysRemaining: number;
  discountPercentage: number;
  isSiteWideSaleCoupon: boolean;
  siteWideSaleDescription: string;
}

export const Extend: React.FC<ExtendProps> = (props) => {
  const { setModal } = useModalContext();

  const examKey = props.examName.trim().toLowerCase().replace(/ /g, '_');
  const expired = props.extensionType === 'expired_trial' || props.extensionType === 'expired_paid';
  const trial = props.extensionType === 'active_trial' || props.extensionType === 'expired_trial';

  const handlePurchaseClicked = (button, header) => {
    if (button.paymentDetails) {
      setModal({
        header: header,
        body: (
          <PurchaseConfirmation
            orderName={button.order.subscriptionPlan.name}
            handleClose={() => setModal(null)}
            {...button}
          />
        )
      });
    } else {
      window.location.href = paths.subscriptions(
        button.order.subscriptionPlan.planSlug,
        button.order.pricing.couponCode
      );
    }
  };

  const Description = () => {
    const description = {
      active_paid: `You have access to ${props.currentPlanName} for another ${pluralize(props.daysRemaining, 'day')}.`,
      active_trial: `You're currently using Magoosh with our ${props.examName} trial. For full access upgrade your plan and start moving towards your ${props.examName} goals.`,
      expired_trial: `Your ${props.currentPlanName} subscription expired, but you're in the right place to accomplish your ${props.examName} goals.`,
      expired_paid: `Your ${props.currentPlanName} subscription expired, but we've saved your hard work so you can continue studying right where you left off.`
    };

    return (
      <p>
        {description[props.extensionType]}
        {
          !trial && props.promocode && props.promocode.daysRemaining > 0 && (
            <>
              {' '}Extend your plan and get a {props.promocode.discountPercentage}% discount.
            </>
          )
        }
      </p>
    );
  };

  const IconContainer = () => {
    return (
      <div className={style.iconContainer}>
        <img src={TrialProfileIllustration} alt="Student wearing glasses and a bunny hat" />
        {
          props.expirationDate && (
            <p className="text-center">
              {expired ? `${trial ? 'Trial' : 'Plan'} expired on` : 'Plan expires on'}
              <br />
              <strong>{moment(props.expirationDate).format('MMMM D, YYYY')}</strong>
            </p>
          )
        }
      </div>
    );
  };

  const Title = () => {
    const extendText = `Extend your ${props.examName} study time and hit your target score!`;
    const upgradeText = `Your target ${props.examName} score is waiting!`;

    return(
      <h2>
        <strong>
          {props.extensionType === 'active' ? extendText : upgradeText}
        </strong>
      </h2>
    );
  };

  return (
    <>
      <div className={style.expirationContainer}>
        <div className={style.cardHeader}>
          <div className={style.descriptionSection}>
            <Title />
            <Description />
          </div>

          <IconContainer />
        </div>

        <div>
          <div className={style.purpleList}>
            <h3 className="u-padding-L-xs">
              <strong>
                {trial ? 'Upgrade your plan now to receive:' : 'Extend your plan to maintain your access:'}
              </strong>
            </h3>

            <ul>
              {
                props.promocode && (
                  <li>
                    {
                      props.promocode.isSiteWideSaleCoupon ? (
                        <>
                          {props.promocode.siteWideSaleDescription}
                        </>
                      ) : (
                        <>
                          A special <strong>{props.promocode.discountPercentage}% discount</strong> for returning students. Valid only for the next {pluralize(props.promocode.daysRemaining, 'day')}!
                        </>
                      )
                    }
                  </li>
                )
              }

              {
                upsellPoints.long[examKey].map((bullet, i) => {
                  return(
                    <li key={i} dangerouslySetInnerHTML={{__html: bullet}} />
                  )
                })
              }
            </ul>

            <div className={style.btnRow}>
              {
                props.offers.map((offer, i) => {
                  const verb = trial ? 'Study' : 'Extend';
                  const monthsDuration = pluralize(offer.order.subscriptionPlan.durationMonths, 'month');
                  // internal: here hardcoded value for 31 days plan. Pay attention that
                  //           any plan with durationMonths === 0 will be rendered with `1 month` text
                  const term = offer.order.subscriptionPlan.durationMonths !== 0 ? monthsDuration : '1 month';
                  const btnText = `${verb} for ${term} (${formatPrice(offer.order.pricing.totalExcludingTax, 'usd', true)})`;

                  return (
                    <div key={i}>
                      <SecondaryButton onClick={() => handlePurchaseClicked(offer, `${verb} for ${term}`)}>
                        {btnText}
                      </SecondaryButton>
                    </div>
                  );
                })
              }
            </div>

            {
              props.promocode?.discountPercentage && (
                <div className="u-margin-T-xxs">
                  <small>* Prices above already include {props.promocode.discountPercentage}% discount</small>
                </div>
              )
            }

            <div className={style.commentSection}>
              <a href={paths.plans()} className={style.allPlansLink}>See all plan options</a>
              {
                props.promocode && (
                  <div>
                    Use code "{props.promocode.couponCode}" when checking out!
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ExtendPage: React.FC<ExtendProps> = (props) => {
  return (
    <ModalProvider>
      <Extend {...props} />
    </ModalProvider>
  );
};

export default ExtendPage;
