import React from 'react';

import CardLayout from '@magoosh/layouts/card';
import { WarningAlert } from '@magoosh/lib/alerts';
import { PrimaryButton } from '@magoosh/lib/button';
import { HandsHoldingPlantIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';

import UpsellContent from './upsell_content';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  examName: string;
  saleMessage: string;
}

const UpsellCard: React.FC<Props> = (props) => {
  return (
    <CardLayout.Card>
      <div className="d-flex flex-column" style={{ gap: 10 }}>
        <div className="d-flex">
          <div>
            <HandsHoldingPlantIcon fill={colors.brandPrimary} height={70} />
          </div>

          <div>
            <div>
              <small className="text-gray-light"><strong>GET PREMIUM</strong></small>
            </div>

            <div className="u-margin-V-xxs">
              <strong>Hit Your {props.examName} Goal With Magoosh!</strong>
            </div>
          </div>
        </div>

        <div className="small text-gray-light">
          <UpsellContent examName={props.examName} />
        </div>

        <div>
          {
            props.saleMessage && (
              <WarningAlert className="small u-margin-B-s">
                <strong>Sale!</strong>{' '}
                <span
                  dangerouslySetInnerHTML={{ __html: props.saleMessage }}
                />
              </WarningAlert>
            )
          }
          <PrimaryButton block href={paths.plans()}>Get Premium</PrimaryButton>
        </div>
      </div>
    </CardLayout.Card>
  );
};

export default UpsellCard;
