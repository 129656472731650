import * as React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import FieldMultiselect from '@magoosh/lib/formik_inputs/field_multiselect';
import { paths } from 'config/path_helpers';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import fetch from 'utilities/fetch';
import { ApplicantProfileCardProps } from '@magoosh/pages/applicant_profiles/types';
import ApplicantProfileCard from '@magoosh/pages/applicant_profiles/profile_card';
import { Col, Row } from 'react-bootstrap';
import { pluralize } from 'utilities/pluralize';
import { FieldDropdownList } from '@magoosh/lib/formik_inputs/field_dropdown_list';

interface Props {
  filters?: {
    programs?: number[];
    tags?: string[];
  };
  programs: {
    id: number;
    name: string;
  }[];
  tags: string[];
}

interface SearchFormValues {
  withTags: string[];
  admittedToPrograms: number[];
}

const ApplicantProfilesIndex: React.FC<Props> = (props) => {
  const [profiles, setProfiles] = React.useState([]);

  const initialValues: SearchFormValues = {
    withTags: props.filters?.tags || [],
    admittedToPrograms: props.filters?.programs || []
  };

  const onSubmit = (values: SearchFormValues, { setSubmitting }: FormikHelpers<SearchFormValues>) => {
    setSubmitting(true);

    // Surface the tag and program selections to the URL.
    // URLSearchParams can accept array type args if an array of tuples is passed in.
    // For example, new URLSearchParams([['tags[]', 'tag1'], ['tags[]', 'tag2']]).
    const tagPairs = values.withTags.map((tag) => ['tags[]', tag]);
    const programPairs = values.admittedToPrograms.map((programId) => {
      const programObject = props.programs.find((program) => program.id === programId);
      return ['programs[]', programObject.name];
    });
    const paramPairs = tagPairs.concat(programPairs);
    if (paramPairs.length) {
      const searchParams = new URLSearchParams(paramPairs);
      window.history.replaceState(null, null, `?${searchParams.toString()}`);
    } else {
      window.history.replaceState(null, null, window.location.pathname);
    }

    fetch(paths.api.applicantProfileSearch(), { method: 'POST', body: JSON.stringify(values) }).then(
      (response) => {
        setProfiles(response.applicantProfiles);
        setSubmitting(false);
      }
    );
  };

  return (
    <div>
      <h1>Applicant Profiles</h1>
      <p>
        Search <strong>real applicant profiles</strong> from Magoosh students like you. Each profile includes
        detailed stats on the admitted student, along with key application elements such as college
        transcripts, resumes/CVs, and essays, as well as information about their letters of rec and interview
        questions.
      </p>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleSubmit, isSubmitting, resetForm, setFieldValue, submitCount, submitForm }) => {
          React.useEffect(() => {
            if (submitCount === 0) {
              submitForm();
            }
          }, []);
          return (
            <Form>
              <Row>
                <Col md={6}>
                  <FieldMultiselect
                    name={'withTags'}
                    label="Applicant Characteristics"
                    defaultValue={values.withTags}
                    data={props.tags}
                  />
                </Col>
                <Col md={6}>
                  <FieldMultiselect
                    name={'admittedToPrograms'}
                    label="Programs Admitted To"
                    defaultValue={values.admittedToPrograms}
                    valueField={'id'}
                    textField={'name'}
                    data={props.programs}
                    searchEndpoint={paths.api.admissions.programsSearch}
                    filter={() => true}
                  />
                </Col>
              </Row>
              <SecondaryButton className={'u-margin-T-m'} type={'submit'} submitting={isSubmitting}>
                Show Results
              </SecondaryButton>
              <DefaultButton
                className={'u-margin-T-m u-margin-L-m'}
                onClick={() => {
                  setFieldValue('withTags', []);
                  setFieldValue('admittedToPrograms', []);
                }}
              >
                Reset
              </DefaultButton>
              {profiles.length > 0 && (!!initialValues.withTags.length || !!initialValues.admittedToPrograms.length || submitCount > 1) && (
                <p>
                  <small>{pluralize(profiles.length, 'profile', 'profiles')} match your search terms.</small>
                </p>
              )}
              {profiles.length === 0 && submitCount > 1 && (
                <div className={'text-center'}>No profiles match your search terms.</div>
              )}
            </Form>
          );
        }}
      </Formik>

      <div className={'u-margin-T-m'}>
        {profiles.map((profile: ApplicantProfileCardProps) => (
          <ApplicantProfileCard {...profile} key={profile.id} />
        ))}
      </div>
    </div>
  );
};

export default ApplicantProfilesIndex;
