import * as React from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';

interface Props {
  data: any[];
  groupBy?: string;
  inputName: string;
  selectedValue: string;
  textField: string;
  valueField: string;
}

export default class DropdownListWithInput extends React.Component<Props> {
  state = {
    value: this.props.selectedValue
  };

  render() {
    return (
      <>
        <DropdownList
          data={this.props.data}
          defaultValue={this.props.selectedValue}
          filter='contains'
          valueField={this.props.valueField}
          textField={this.props.textField}
          groupBy={this.props.groupBy}
          onChange={value => this.setState({ value: value.id })}
        />
        <input name={this.props.inputName} value={this.state.value} type='hidden' />
      </>
    );
  }
}
