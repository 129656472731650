import React from 'react';

import ContentfulEditorialSection from './editorial_section';
import { EditorialSection } from './types';

interface Props {
  contents: EditorialSection[];
}

const ContentfulContents: React.FC<Props> = (props) => {
  if (props.contents) {
    return (
      <>
        {
          props.contents.map((section, idx) => (
            <ContentfulEditorialSection key={idx} {...section} />
          ))
        }
      </>
    )
  } else {
    return null;
  }
};

export default ContentfulContents;
