import React from 'react';
import { paths } from 'config/path_helpers';
import { CheckpointCardProps } from '@magoosh/gre/organisms/checkpoint_card/types';
import { Checkpoint } from './types';
import { minutesFormattedasHoursAndMinutesString } from 'utilities/time';
import { pluralize } from 'utilities/pluralize';
import CheckpointCard from '@magoosh/gre/organisms/checkpoint_card';
import useAuthToken from '@magoosh/utils/use_authenticity_token';

interface Props {
  checkpoint: Checkpoint;
}

const AssessmentCheckpoint: React.FC<Props> = ({ checkpoint }) => {  
  const authToken = useAuthToken();

  const checkpointProps: CheckpointCardProps = {
    estimatedTime: '65 minutes',
    headline: checkpoint.displayName,
    iconType: 'SingleStar',
    locked: checkpoint.locked,
    primaryCTA: null,
    scored: checkpoint.number === 1 ? '' : checkpoint.notScored ? '(Not Scored)' : '(Scored)'
  };

  const checkpointIsComplete = checkpoint.mockTestId && !checkpoint.running;
  const checkpointIsInProgress = checkpoint.running;
  const checkpointIsTimeLocked = checkpoint.minutesLeftToUnlock >= 0;
  const checkpointIsExpired = checkpoint.minutesLeftToTake < 0;

  const timeLockedCheckpointDescription = () => {
    if (checkpoint.minutesLeftToUnlock <= 5) {
      return 'Study a few more minutes to unlock this checkpoint.';
    } else if (checkpoint.minutesLeftToUnlock <= 60) {
      return `Study approximately ${checkpoint.minutesLeftToUnlock} more minutes to unlock this checkpoint.`;
    } else {
      const hoursLeftToUnlock = Math.round(checkpoint.minutesLeftToUnlock / 60);
       return `Study approximately ${pluralize(hoursLeftToUnlock, 'more hour')} to unlock this checkpoint.`;
    }
  };

  if (checkpointIsComplete) {
    checkpointProps.primaryCTA = {
      text: 'Review',
      variant: 'default',
      href: paths.practiceTest(checkpoint.mockTestId)
    };
  } else if (checkpointIsInProgress) {
    checkpointProps.primaryCTA = {
      text: 'Continue',
      variant: 'default',
      href: paths.practiceTest(checkpoint.mockTestId)
    };
  } else if (checkpointIsTimeLocked) {
    checkpointProps.children = timeLockedCheckpointDescription();
  } else if (checkpointIsExpired) {
    checkpointProps.children = 'This checkpoint expired before you completed it.';
  } else {
    checkpointProps.children = (
      <>
        <strong>
          Available for the next {minutesFormattedasHoursAndMinutesString(checkpoint.minutesLeftToTake)} of tracked study time
        </strong>
        {' '}(this includes time you spend answering practice questions and watching lesson videos and answer explanation videos).
      </>
    );
    checkpointProps.primaryCTA = { text: 'Start', variant: 'secondary', type: 'submit' };
  }

  return (
    <form action={paths.practiceTests()} method="POST">
      <input
        type="hidden"
        name="mock_test[template_id]"
        value={checkpoint.mockTestTemplateId}
      />
      <input type="hidden" name="authenticity_token" value={authToken} />
      <CheckpointCard {...checkpointProps} />
    </form>
  );
};

export default AssessmentCheckpoint;
