import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import * as React from 'react';

import ProgressBar from '@magoosh/lib/progress_bar';

import style from './style.module.scss';

interface Props {
  onPrevStep: () => void;
  onNextStep: () => void;
  currentStep: number;
  totalSteps: number;
  isLoading: boolean;
}

const StudyPreferencesSetupModalFooter = ({
  onPrevStep,
  onNextStep,
  currentStep,
  totalSteps,
  isLoading
}: Props) => {
  const backButton = (
    <DefaultButton onClick={onPrevStep} disabled={isLoading}>
      Back
    </DefaultButton>
  );

  let nextButtonLabel = 'Next';
  if (currentStep === totalSteps) {
    nextButtonLabel = 'Go To Dashboard';
  }

  const nextButton = (
    <PrimaryButton onClick={onNextStep} submitting={isLoading}>
      {nextButtonLabel}
    </PrimaryButton>
  );

  const scheduleLaterLink = (
    <a onClick={onPrevStep} className={style.setScheduleLaterLink}>
      Set my schedule later
    </a>
  );

  return (
    <div className="text-center">
      <div className={style.nextButton}>{nextButton}</div>

      <div className={style.prevButton}>
        {currentStep === 1 ? scheduleLaterLink : backButton}
      </div>

      <div className={style.stepProgressBar}>
        <ProgressBar
          backgroundColor="#d8d8d8"
          color="#26B67C"
          percentage={(currentStep / totalSteps) * 100}
          size="sm"
        />
      </div>
    </div>
  );
};

export default StudyPreferencesSetupModalFooter;
