import * as React from 'react';

export const TrophyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="100"
      viewBox="0 0 66 69"
      fill="none"
      {...props}>
      <path
        d="M40.3 26.1H35.9L34.5 21.9C34.4 21.6 34.1 21.4 33.8 21.4 33.4 21.4 33.1 21.6 33 21.9L31.7 26.1H27.2C26.9 26.1 26.6 26.3 26.5 26.6 26.4 27 26.5 27.3 26.8 27.5L30.4 30.1 29 34.3C28.9 34.6 29 35 29.3 35.2 29.5 35.4 29.9 35.4 30.2 35.2L33.8 32.6 37.4 35.2C37.6 35.4 38 35.4 38.3 35.2 38.5 35 38.6 34.6 38.5 34.3L37.2 30.1 40.8 27.5C41 27.3 41.1 27 41 26.6 40.9 26.3 40.6 26.1 40.3 26.1ZM35.8 29.2C35.5 29.4 35.4 29.7 35.5 30.1L36.4 32.6 34.2 31C34 30.8 33.6 30.8 33.3 31L31.2 32.6 32 30.1C32.1 29.7 32 29.4 31.7 29.2L29.6 27.6H32.2C32.6 27.6 32.9 27.4 33 27.1L33.8 24.6 34.6 27.1C34.7 27.4 35 27.6 35.3 27.6H37.9L35.8 29.2Z"
        fill={props.fill}
      />
      <path
        d="M43.8 10.5H23.8C23.3 10.5 23 10.9 23 11.3 23 11.7 23.3 12.1 23.8 12.1H43.8C44.2 12.1 44.5 11.7 44.5 11.3 44.5 10.9 44.2 10.5 43.8 10.5Z"
        fill={props.fill}
      />
      <path
        d="M42.2 13.6H25.3C24.9 13.6 24.5 14 24.5 14.4 24.5 14.8 24.9 15.2 25.3 15.2H42.2C42.7 15.2 43 14.8 43 14.4 43 14 42.7 13.6 42.2 13.6Z"
        fill={props.fill}
      />
      <path
        d="M40.7 16.7H26.8C26.4 16.7 26.1 17 26.1 17.5 26.1 17.9 26.4 18.2 26.8 18.2H40.7C41.1 18.2 41.5 17.9 41.5 17.5 41.5 17 41.1 16.7 40.7 16.7Z"
        fill={props.fill}
      />
      <path
        d="M36.8 51.3C37.3 51.3 37.6 51 37.6 50.5 37.6 50.1 37.3 49.8 36.8 49.8 36.4 49.8 36.1 50.1 36.1 50.5 36.1 51 36.4 51.3 36.8 51.3Z"
        fill={props.fill}
      />
      <path
        d="M7.2 59.8C7.9 59.8 8.4 59.3 8.4 58.6 8.4 58 7.9 57.5 7.2 57.5 6.6 57.5 6.1 58 6.1 58.6 6.1 59.3 6.6 59.8 7.2 59.8Z"
        fill={props.fill}
      />
      <path
        d="M60.3 59C60.9 59 61.5 58.5 61.5 57.8 61.5 57.2 60.9 56.7 60.3 56.7 59.7 56.7 59.2 57.2 59.2 57.8 59.2 58.5 59.7 59 60.3 59Z"
        fill={props.fill}
      />
      <path
        d="M50.3 45.2C50.9 45.2 51.5 44.6 51.5 44 51.5 43.4 50.9 42.8 50.3 42.8 49.7 42.8 49.2 43.4 49.2 44 49.2 44.6 49.7 45.2 50.3 45.2Z"
        fill={props.fill}
      />
      <path
        d="M61.8 19C62.5 19 63 18.5 63 17.8 63 17.2 62.5 16.7 61.8 16.7 61.2 16.7 60.7 17.2 60.7 17.8 60.7 18.5 61.2 19 61.8 19Z"
        fill={props.fill}
      />
      <path
        d="M53.4 2.8C54 2.8 54.5 2.3 54.5 1.7 54.5 1.1 54 0.5 53.4 0.5 52.7 0.5 52.2 1.1 52.2 1.7 52.2 2.3 52.7 2.8 53.4 2.8Z"
        fill={props.fill}
      />
      <path
        d="M10.3 3.6C10.9 3.6 11.5 3.1 11.5 2.5 11.5 1.8 10.9 1.3 10.3 1.3 9.7 1.3 9.2 1.8 9.2 2.5 9.2 3.1 9.7 3.6 10.3 3.6Z"
        fill={props.fill}
      />
      <path
        d="M2.6 18.2C3.3 18.2 3.8 17.7 3.8 17.1 3.8 16.4 3.3 15.9 2.6 15.9 2 15.9 1.5 16.4 1.5 17.1 1.5 17.7 2 18.2 2.6 18.2Z"
        fill={props.fill}
      />
      <path
        d="M12.6 29.8C13.3 29.8 13.8 29.3 13.8 28.6 13.8 28 13.3 27.5 12.6 27.5 12 27.5 11.5 28 11.5 28.6 11.5 29.3 12 29.8 12.6 29.8Z"
        fill={props.fill}
      />
      <path
        d="M55.3 9.8H48.4V5.9C48.4 5.5 48 5.2 47.6 5.2H43V2.8C43 2.4 42.7 2.1 42.2 2.1H25.3C24.9 2.1 24.5 2.4 24.5 2.8V5.2H19.9C19.5 5.2 19.2 5.5 19.2 5.9V9.8H12.2C11.8 9.8 11.5 10.1 11.5 10.5V18.2C11.5 22.6 14.8 26.3 19.2 26.7V27.6C19.2 32.3 21.5 36.8 25.3 39.5 27.6 41.2 28.6 44.1 27.7 46.8H24.5C24.1 46.8 23.8 47.2 23.8 47.6V52.8C21.6 52.8 18.7 52.8 16.1 52.8 15.7 52.8 15.3 53.2 15.3 53.6 15.3 54 15.7 54.4 16.1 54.4H17.6V66.7H16.1C15.7 66.7 15.3 67 15.3 67.5 15.3 67.9 15.7 68.2 16.1 68.2H18.4C19.7 68.2 50.3 68.2 51.5 68.2 51.9 68.2 52.2 67.9 52.2 67.5 52.2 67 51.9 66.7 51.5 66.7H49.9V54.4H51.5C51.9 54.4 52.2 54 52.2 53.6 52.2 53.2 51.9 52.8 51.5 52.8 49.1 52.8 46.3 52.8 43.8 52.8V47.6C43.8 47.2 43.4 46.8 43 46.8H40.3C39.3 44.1 40.2 41.1 42.5 39.3 46.3 36.5 48.4 32.3 48.4 27.6V26.7C52.7 26.3 56.1 22.6 56.1 18.2V10.5C56.1 10.1 55.7 9.8 55.3 9.8ZM54.5 11.3V12.8H48.4V11.3H54.5ZM26.1 3.6H41.5V5.2H26.1V3.6ZM19.2 11.3V12.8H13V11.3H19.2ZM13 18.2V14.4H19.2V25.1C15.7 24.7 13 21.8 13 18.2ZM19.2 66.7V59H48.4V66.7H19.2ZM48.4 57.5H19.2V54.4C22.6 54.4 43.6 54.4 48.4 54.4V57.5ZM42.2 52.8H25.3V51.3H34.5C35 51.3 35.3 51 35.3 50.5 35.3 50.1 35 49.8 34.5 49.8H25.3V48.4C30.3 48.4 36.7 48.4 42.2 48.4V52.8ZM46.8 27.6C46.8 31.8 44.9 35.6 41.6 38.1 38.8 40.2 37.7 43.6 38.7 46.8H29.3C30.1 43.6 28.9 40.2 26.2 38.3 22.8 35.8 20.7 31.8 20.7 27.6 20.7 25 20.7 9.4 20.7 6.7 26.9 6.7 41.4 6.7 46.8 6.7 46.8 11.2 46.8 23.2 46.8 27.6ZM48.4 25.1V14.4H54.5V18.2C54.5 21.8 51.8 24.7 48.4 25.1Z"
        fill={props.fill}
      />
      <path
        d="M6.8 38.2C3.5 38.2 0.7 41 0.7 44.4 0.7 47.8 3.5 50.5 6.8 50.5 10.2 50.5 13 47.8 13 44.4 13 41 10.2 38.2 6.8 38.2ZM6.8 49C4.3 49 2.2 46.9 2.2 44.4 2.2 41.8 4.3 39.8 6.8 39.8 9.4 39.8 11.5 41.8 11.5 44.4 11.5 46.9 9.4 49 6.8 49Z"
        fill={props.fill}
      />
      <path
        d="M61.1 27.5C58.7 27.5 56.8 29.4 56.8 31.7 56.8 34 58.7 35.9 61.1 35.9 63.4 35.9 65.3 34 65.3 31.7 65.3 29.4 63.4 27.5 61.1 27.5ZM61.1 34.4C59.6 34.4 58.4 33.2 58.4 31.7 58.4 30.2 59.6 29 61.1 29 62.6 29 63.8 30.2 63.8 31.7 63.8 33.2 62.6 34.4 61.1 34.4Z"
        fill={props.fill}
      />
    </svg>
  );
};

export const UpNextIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M18.9 0.2H7.8C6.4 0.2 5.2 1.2 4.9 2.5 3.8 2.8 2.8 3.7 2.6 4.9 1.3 5.1 0.2 6.3 0.2 7.8V18.9C0.2 20.5 1.5 21.8 3.1 21.8H14.2C15.7 21.8 16.9 20.7 17.1 19.4 18.3 19.2 19.2 18.2 19.5 17.1 20.8 16.8 21.8 15.6 21.8 14.2V3.1C21.8 1.5 20.5 0.2 18.9 0.2ZM15.7 18.9C15.7 19.7 15 20.3 14.2 20.3H3.1C2.3 20.3 1.7 19.7 1.7 18.9V7.8C1.7 7 2.3 6.3 3.1 6.3H3.3 3.4 14.2C15 6.3 15.7 7 15.7 7.8V18.6 18.7 18.9ZM18.1 16.5C18.1 17.1 17.7 17.6 17.1 17.8V7.8C17.1 6.2 15.8 4.9 14.2 4.9H4.2C4.4 4.3 4.9 3.9 5.5 3.9H16.6C17.4 3.9 18.1 4.6 18.1 5.4V16.5H18.1ZM20.3 14.2C20.3 14.8 20 15.3 19.5 15.5V5.4C19.5 3.8 18.2 2.5 16.6 2.5H6.5C6.7 2 7.2 1.7 7.8 1.7H18.9C19.7 1.7 20.3 2.3 20.3 3.1V14.2ZM8.7 9.6C6.6 9.6 5 11.3 5 13.3 5 15.4 6.6 17 8.7 17 10.7 17 12.4 15.4 12.4 13.3 12.4 11.3 10.7 9.6 8.7 9.6ZM10.4 13.7L9.7 14.1C9.4 14.3 8.8 14.6 8.5 14.8L7.8 15.2C7.4 15.4 7.1 15.3 7.1 14.9V14.1C7.1 13.7 7.1 13 7.1 12.6V11.8C7.1 11.4 7.4 11.2 7.8 11.4L8.5 11.8C8.8 12 9.4 12.4 9.7 12.6L10.4 13C10.8 13.2 10.8 13.5 10.4 13.7Z"
        fill="#222123"
      />
    </svg>
  );
};
