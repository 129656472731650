import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { adminPaths } from 'config/path_helpers';
import { DataTable } from '@magoosh/lib/data_table';
import { DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import { useState } from 'react';
import { Search } from '@magoosh/lib/data_table/search';
import Modal from '@magoosh/lib/modal';
import AdminProgramForm from './form';
import { Program } from '../types';

interface Props {
  brandingOptions: {
    name: string;
    id: number;
  }[];
}

const ProgramAdminIndex: React.FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [currentProgram, setCurrentProgram] = useState(null);

  const onClickCreate = () => {
    setShowModal(true);
    setCurrentProgram(null);
  };

  const onClickEdit = (program: Program) => {
    setShowModal(true);
    setCurrentProgram(program);
  };

  const closeEditModal = () => {
    setShowModal(false);
    setCurrentProgram(null);
  };

  const onFormSuccess = () => {
    setShowModal(false);
    setCurrentProgram(null);
    dataQueryTableProps.refresh();
  };

  const defaultQueryParams = React.useMemo(() => {
    const thoseParams = { sortColumn: 'id', filters: {} };
    return thoseParams;
  }, []);

  const { runQuery, dataQueryTableProps } = useDataQuery(adminPaths.api.admissions.programs, defaultQueryParams);

  return (
    <div>
      <h1>Programs</h1>

      <DataTable
        {...dataQueryTableProps}
        columns={[
          {
            name: 'ID',
            selector: 'id'
          },
          {
            name: 'Name',
            selector: 'name'
          }
        ]}>
        <DataTable.Filters>
          <Search runQuery={runQuery} />
        </DataTable.Filters>

        <DataTable.HeaderActions>
          <PrimaryButton onClick={onClickCreate} icon="plus">
            Create
          </PrimaryButton>
        </DataTable.HeaderActions>
        <DataTable.ItemActions>
          {(row: Program) => {
            return (
              <>
                <DefaultButton
                  onClick={() => {
                    onClickEdit(row);
                  }}
                  icon="edit">
                  Edit
                </DefaultButton>
              </>
            );
          }}
        </DataTable.ItemActions>
      </DataTable>
      <Modal
        show={showModal}
        onHide={closeEditModal}
        title={currentProgram ? 'Edit Program' : 'Create Program'}>
        <AdminProgramForm
          program={currentProgram}
          brandingOptions={props.brandingOptions}
          onSuccess={onFormSuccess}
        />
      </Modal>
    </div>
  );
};

export default (props: Props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ProgramAdminIndex {...props} />
    </QueryClientProvider>
  );
};
