import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { pick } from 'lodash';

import ActiveFilters from './active_filters';
import MultipleSelectFilter from './multiple_select_filter';
import SingleSelectFilter from './single_select_filter';
import { FilterConfig } from './types';

interface Props {
  collapseBy?: number;
  filters: { [key: string]: string };
  filtersConfig: FilterConfig[];
  focused?: boolean;
  onChange: (updatedFilters: { [key: string]: string }) => void;
  onShowCollapsed?: () => void;
}

const ButtonFilters: React.FC<Props> = (props) => {
  const collapseByPerCollapsible = () => {
    if (!props.collapseBy) return 0;

    const collapsibleFilterCount = props.filtersConfig.filter((config) => config.collapsible).length;
    if (!collapsibleFilterCount) return 0;

    return props.collapseBy / collapsibleFilterCount;
  };

  const handleFilterChanged = (name: string, value: string) => {
    props.onChange({ ...props.filters, [name]: value });
  };

  return (
    <div className="align-items-start d-flex" style={{ gap: 20 }}>
      {
        props.filtersConfig.map((filterConfig, idx) => {
          if (filterConfig.multiple) {
            return (
              <div key={idx} className={cx(filterConfig.className, 'd-flex')} style={{ gap: 10 }}>
                <MultipleSelectFilter
                  collapseBy={filterConfig.collapsible ? collapseByPerCollapsible() : 0}
                  disabled={props.focused && !filterConfig.allowWhileFocused}
                  filterConfig={filterConfig}
                  onChange={handleFilterChanged}
                  onShowCollapsed={props.onShowCollapsed}
                  value={props.filters[filterConfig.key]}
                />
              </div>
            );
          } else {
            return (
               <div key={idx} className={cx(filterConfig.className, 'd-flex')} style={{ gap: 10 }}>
                <SingleSelectFilter
                  disabled={props.focused && !filterConfig.allowWhileFocused}
                  filterConfig={filterConfig}
                  onChange={handleFilterChanged}
                  value={props.filters[filterConfig.key]}
                />
              </div>
            );
          }
        })
      }
    </div>
  );
};

export default ButtonFilters;
