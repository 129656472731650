import React from 'react';

import Tabs from '@magoosh/lib/tabs';

import AdminContent from './admin_content';
import FaqsContent from './faqs_content';
import SummaryContent from './summary_content';

import style from './style.module.scss';

export interface LessonSummarySectionProps {
  summary?: string;
  bullets?: string[];
  chapters?: Chapter[];
  transcript: string;
  faqs?: string;
  related?: string;
  adminTab?: boolean;
  adminVideoNotes?: string;
  adminLessonNotes?: string;
  lessonId: number;
}

interface Chapter {
  title: string;
  humanTimestamp: string;
  begin: string;
}

export const LessonSummarySection: React.FC<LessonSummarySectionProps> = (props) => {
  const hasFaqs = !!props.faqs || !!props.related;

  // Show FAQs on whatever the first tab is
  const data = [
    {
      id: 'summary_and_chapters',
      title: `Summary${hasFaqs ? ' and FAQs' : ''}`,
      show: !!props.summary
    },
    {
      id: 'transcript',
      title: `Transcript${!props.summary && hasFaqs ? ' and FAQs' : ''}`,
      show: !!props.transcript
    },
    {
      id: 'admin',
      title: 'Admin',
      show: props.adminTab
    }
  ]

  const visibleTabs = data.filter((tab) => tab.show)

  // internal: 'setTimeout' is nesessary here because transcript_events.js uses 'querySelector',
  //            so it neded time to render corresponding layout to find transcripts
  const changeTab = () => {
    const tabChangedEvent = new CustomEvent("tabChanged");

    setTimeout(() => {
      document.dispatchEvent(tabChangedEvent);
    }, 100);
  }

  if (!visibleTabs.length) {
    return null;
  } else {
    return (
      <>
        <Tabs defaultTab={visibleTabs[0].id}>
          {
            ...visibleTabs.map((tab, i) => (
              <Tabs.Tab key={i} addToHandleClick={() => changeTab()} tab={tab.id}>{tab.title}</Tabs.Tab>
            ))
          }

          <Tabs.Panel tab="summary_and_chapters">
            <SummaryContent
              bullets={props.bullets}
              chapters={props.chapters}
              summary={props.summary}
            />

            {
              hasFaqs && (
                <>
                  <hr />
                  <FaqsContent faqs={props.faqs} related={props.related} />
                </>
              )
            }
          </Tabs.Panel>

          <Tabs.Panel tab="transcript">
            <div className={style.chaptersContent} dangerouslySetInnerHTML={{__html: props.transcript}} />

            {
              hasFaqs && !props.summary && (
                <>
                  <hr />
                  <FaqsContent faqs={props.faqs} related={props.related} />
                </>
              )
            }
          </Tabs.Panel>

          {
            props.adminTab && (
              <Tabs.Panel tab="admin">
                <AdminContent
                  adminLessonNotes={props.adminLessonNotes}
                  adminVideoNotes={props.adminVideoNotes}
                  lessonId={props.lessonId}
                />
              </Tabs.Panel>
            )
          }
        </Tabs>
      </>
    )
  }
}

export default LessonSummarySection;
