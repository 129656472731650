import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import cx from 'classnames';

import { StudyScheduleDayData } from '../types';
import style from './style.module.scss';
import capitalize from 'react-bootstrap/lib/utils/capitalize';

interface Props {
  currentDayId: number;
  days: StudyScheduleDayData[];
  onSelect: (number) => void;
  learningSessionsPerSet: number;
  learningSessionSetName: string;
  learningSessionName: string;
}

export default (props: Props) => {
  const setLength = props.learningSessionsPerSet;

  return (
    <DropdownButton
      bsStyle="link"
      className={style.dropdownButton}
      id="select-day-dropdown"
      title={`All ${props.learningSessionName}s`}>
      {props.days.map((day, idx) => {
        let weekHeader = null;
        if (idx % setLength === 0) {
          weekHeader = (
            <MenuItem className={style.menuItemHeader} header>
              {capitalize(props.learningSessionSetName)} {idx / setLength + 1}
            </MenuItem>
          );
        }

        return [
          weekHeader,
          <MenuItem
            active={day.id === props.currentDayId}
            className={cx(style.menuItem, { [style.active]: day.id === props.currentDayId })}
            eventKey={day.id}
            onSelect={props.onSelect}>
            {capitalize(props.learningSessionName)} {idx + 1}
            {day.name ? ': ' : ''}
            {day.name}
          </MenuItem>
        ];
      })}
    </DropdownButton>
  );
};
