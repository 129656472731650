import React from 'react';

const TOEFLDescriptionContent: React.FC = (props) => {
  return (
    <>
      <p>This practice test simulates the experience you will have taking the TOEFL on test day.</p>
      <p>When you finish the test, we will give you <strong>score estimates</strong> for Reading and Listening, which tell you how well you would have done on test day. You can estimate scores for your speaking and writing using the material <a href="/grading-your-answers">on this page of guidelines</a> and with <a href="https://magoosh.com/toefl/estimating-your-toefl-score">this conversion chart</a>. Also, you can also use our Writing Assessment service to request a grade of your essays by following <a href="/how-to-submit-essays">the instructions in this guide</a>.</p>
    </>
  );
};

export default TOEFLDescriptionContent;
