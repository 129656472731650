import * as React from 'react';
import { useState } from 'react';
import style from './style.module.scss';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import { Checkmark } from '@magoosh/lib/icons';

interface LessonCheckMarkProps {
  authenticityToken: string;
  lesson: Lesson;
  incrementLessonCount: () => void;
  decrementLessonCount: () => void;
}

export const LessonCheckMark: React.FC<LessonCheckMarkProps> = (props) => {
  const { lesson } = props;
  const [markedFinished, setMarkedFinished] = useState(lesson.markedFinished);

  return (
    <div className={style.checkmark}>
      <a
        onClick={(e) => {
          e.stopPropagation();
          const newState = !markedFinished;
          let method;
          let callBack;

          if (newState) {
            method = 'POST';
            callBack = props.incrementLessonCount;
          } else {
            method = 'DELETE';
            callBack = props.decrementLessonCount;
          }

          fetch(paths.lessonMarking(lesson.id), {
            method: method,
            body: JSON.stringify({
              authenticityToken: props.authenticityToken,
              marking: {
                name: 'completed'
              }
            })
          }).then(() => {
            setMarkedFinished(newState);
            callBack();
          });
        }}>
        <Checkmark completed={markedFinished} />
      </a>
    </div>
  );
};
