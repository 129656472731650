import React from 'react';

import { CircleIcon, FontAwesomeIcon } from '@magoosh/lib/icons';
import { ColorPalette } from 'types/constants';
import { CheckableContentBlocksType } from 'types/content_blocks';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  icon: string;
  iconColorPalette?: ColorPalette;
  onClick?: (task: CheckableContentBlocksType) => void;
  task: CheckableContentBlocksType;
  taskClassName?: string;
}

const TaskWithIcon: React.FC<Props> = (props) => {
  let defaultColorPalette = { dark: colors.gray85, light: '#fff' } as ColorPalette;
  if (props.task.isCompleted) {
    defaultColorPalette = { dark: colors.green, light: colors.greenLight } as ColorPalette;
  }

  const colorPalette = props.iconColorPalette || defaultColorPalette;

  const handleClick = () => {
    if (!props.onClick) return;

    props.onClick(props.task);
  };

  return (
    <div className="d-flex" style={{ gap: 10 }}>
      <div>
        <CircleIcon
          bordered={!props.task.isCompleted}
          colorPalette={colorPalette}
          onClick={handleClick}
          size={30}
          style={{ cursor: props.onClick ? 'pointer' : 'default', WebkitTextStroke: `1px ${colorPalette.light}` }}
        >
          <FontAwesomeIcon icon={props.icon} />
        </CircleIcon>
      </div>

      <div style={{ flex: 1, marginTop: 3 }}>
        <Task className={props.taskClassName} {...props.task} />
      </div>
    </div>
  )
};

const Task: React.FC<CheckableContentBlocksType & { className?: string}> = (props) => {
  if (props.type === 'Lesson') {
    return (
      <div className={props.className}>
        Watch <a href={props.url}>{props.name}</a>
      </div>
    );
  } else if (props.type === 'RichText') {
    return (
      <div className={props.className} dangerouslySetInnerHTML={{__html: props.richText}} />
    );
  }
};

export default TaskWithIcon;
