import * as React from 'react';
import {
  LiveClass,
  LiveClassCohort,
  LiveClassSession
} from '@magoosh/admin/live_class_sessions/types';
import { useMemo, useState } from 'react';
import * as moment from 'moment-timezone';
import DropdownList from 'react-widgets/lib/DropdownList';
import fetch from 'utilities/fetch';
import { adminPaths } from 'config/path_helpers';
import { Col, Row } from 'react-bootstrap';
import { DefaultButton, LinkButton, PrimaryButton, SecondaryButton, TabButton } from '@magoosh/lib/button';
import { DataTable } from '@magoosh/lib/data_table';
import useDataQuery from '@magoosh/lib/data_table/use_data_query';
import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import { Search } from '@magoosh/lib/data_table/search';
import Modal from '@magoosh/lib/modal';
import LiveClassSessionForm from '@magoosh/admin/live_class_sessions/index/form';
import { formatTime } from '@magoosh/admin/utils';
import { QueryClient, QueryClientProvider } from 'react-query';

interface Props {
  cohortOptions: LiveClassCohort[];
  liveClasses: LiveClass[];
}

const LiveClassSessionsIndex: React.FC<Props> = (props) => {
  const [currentSession, setCurrentSession] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const openCreateModal = () => {
    setCurrentSession(null);
    setShowEditModal(true);
  };

  const openEditModal = (session: LiveClassSession) => {
    setCurrentSession(session);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const defaultQueryParams = useMemo(() => {
    const thoseParams = { sortColumn: 'startTime', filters: {} };
    return thoseParams;
  }, []);

  const { runQuery, dataQueryTableProps } = useDataQuery(
    adminPaths.api.liveClassSessions,
    defaultQueryParams
  );

  const timeZone = moment.tz.guess();

  return (
    <div>
      <div className="text-center">
        <TabButton disabled>Sessions</TabButton>
        <TabButton href={adminPaths.liveClassCohorts()}>Cohorts</TabButton>
      </div>
      <div>
        <h1>Sessions</h1>
        <p>Times are displayed in your local time zone: {timeZone}</p>
      </div>
      <DataTable
        {...dataQueryTableProps}
        columns={[
          {
            name: 'Name',
            selector: 'name',
            center: false,
            cell: (row) => (
              <div>
                <div>{row.name}</div>
                {row.liveClass && <small>{row.liveClass.name}</small>}
              </div>
            ),
            grow: 2
          },
          {
            name: 'Cohort',
            cell: (row) => <div>{row.cohort ? row.cohort.name : ''}</div>,
            selector: (row) => (row.cohort ? row.cohort.name : '')
          },
          {
            name: 'Start Time',
            selector: 'startTime',
            sortable: true,
            cell: (row) => {
              return <div>{row.startTime ? formatTime(row.startTime, timeZone) : ''}</div>;
            }
          }
        ]}>
        <DataTable.HeaderActions>
          <PrimaryButton onClick={openCreateModal} icon="plus">
            Create
          </PrimaryButton>
        </DataTable.HeaderActions>
        <DataTable.Filters>
          <div className="filters" style={{ width: '800px' }}>
            <Row>
              <Col sm={4}>
                <Search runQuery={runQuery} placeholder={'Search Name...'} />
              </Col>
              <Col sm={5}>
                <DropdownList
                  filter="contains"
                  data={props.cohortOptions}
                  onChange={(selection) => {
                    runQuery({ cohortId: selection.id });
                  }}
                  placeholder="All Cohorts"
                  textField="name"
                  valueField="id"
                  dataKey="id"
                  groupBy="attachableType"
                />
              </Col>
            </Row>
          </div>
        </DataTable.Filters>
        <DataTable.ItemActions>
          {(item) => {
            if (!item.cohort || item.cohort.onDemand) {
              return (
                <DefaultButton icon="edit" onClick={() => openEditModal(item)}>
                  Edit
                </DefaultButton>
              );
            } else {
              return null;
            }
          }}
        </DataTable.ItemActions>
      </DataTable>
      <Modal
        onHide={closeEditModal}
        show={showEditModal}
        title={currentSession !== null ? `Edit ${currentSession.name}` : 'New Session'}
        showCloseButton={true}>
        <div>
          <LiveClassSessionForm
            cohortOptions={props.cohortOptions}
            liveClassSession={currentSession}
            runQuery={runQuery}
            closeModal={closeEditModal}
          />
        </div>
      </Modal>
    </div>
  );
};

export default (props: Props) => {
  const queryClient = new QueryClient();

  return <QueryClientProvider client={queryClient}>
    <LiveClassSessionsIndex {...props} />
  </QueryClientProvider>;
};
