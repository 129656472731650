import * as React from 'react';
import { Field, FieldArray, Form, Formik, FormikHelpers as FormikActions } from 'formik';
import FieldRadio from '@magoosh/lib/formik_inputs/field_radio';
import { DangerButton, PrimaryButton } from '@magoosh/lib/button';
import DropdownList from 'react-widgets/lib/DropdownList';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import { LiveClassCohort } from '@magoosh/admin/live_class_sessions/types';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { Col, Row } from 'react-bootstrap';
import FieldCheckbox from '@magoosh/lib/field_checkbox';
import styles from './style.module.scss';
import { paths } from 'config/path_helpers';

interface FormProps {
  subscriptionPlanOptions: {
    id: number;
    name: string;
  }[];
  cohort?: LiveClassCohort;
  runQuery: any;
  closeModal: () => void;
}

interface CohortFormValues {
  name: string;
  attachableType: 'Branding' | 'SubscriptionPlan';
  subscriptionPlans: { id: number; name: string }[];
  notLoggedInDescription?: string;
  loggedInDescription?: string;
  active: boolean;
  onDemand: boolean;
  createClassSection: boolean;
}

const CohortForm: React.FC<FormProps> = (props) => {
  const initialValues: CohortFormValues = {
    name: '',
    attachableType: 'Branding',
    subscriptionPlans: [],
    notLoggedInDescription: '',
    loggedInDescription: '',
    active: false,
    onDemand: false,
    createClassSection: true
  };

  if (props.cohort) {
    initialValues.name = props.cohort.name;
    initialValues.attachableType = 'SubscriptionPlan';
    initialValues.notLoggedInDescription = props.cohort.notLoggedInDescription;
    initialValues.loggedInDescription = props.cohort.loggedInDescription;
    initialValues.active = props.cohort.active;
    initialValues.subscriptionPlans = props.cohort.subscriptionPlans;
    initialValues.onDemand = true;
    initialValues.createClassSection = false;
  }

  const onSubmitForm = (values: CohortFormValues, { setSubmitting }: FormikActions<CohortFormValues>) => {
    setSubmitting(true);

    fetch(
      props.cohort ? adminPaths.api.liveClassCohort(props.cohort.id) : adminPaths.api.liveClassCohorts(),
      {
        method: props.cohort ? 'PATCH' : 'POST',
        body: JSON.stringify(values)
      }
    )
      .then((response) => {
        props.closeModal();
        props.runQuery();
      })
      .catch((response) => alert(response.errors._error))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
      {({ values, isSubmitting, submitForm, setFieldValue }) => (
        <Form>
          <div>
            <label htmlFor="cohort-name">Name</label>
            <Field name="name" className="form-control u-margin-B-s" id="cohort-name" label="Name" />
            <FieldCheckbox name="active" id="cohort-active" checked={String(values.active) === 'true'}>
              Active
            </FieldCheckbox>

            {renderSubscriptionPlanInputs(values, props.subscriptionPlanOptions, setFieldValue)}
            <FieldInput label="Description HTML" type="textarea" name="loggedInDescription" />

            <hr />

            {renderClassSectionSection(props.cohort, values)}
            <div>
              <PrimaryButton className="u-margin-T-s" submitting={isSubmitting} onClick={submitForm}>
                Submit
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

function renderSubscriptionPlanInputs(values, subscriptionPlanOptions, setFieldValue) {
  if (!(values.attachableType === 'SubscriptionPlan')) {
    return null;
  }

  return (
    <>
      <FieldArray
        name="subscriptionPlans"
        render={(arrayHelpers) => {
          const plans = values.subscriptionPlans;
          return (
            <div>
              {plans.map((plan, index) => {
                return (
                  <Row>
                    <Col xs={10}>
                      <DropdownList
                        key={`subscriptionPlans[${index}].id`}
                        name={`subscriptionPlans[${index}].id`}
                        className="u-margin-B-xs"
                        data={subscriptionPlanOptions}
                        filter="contains"
                        placeholder="Select a Subscription Plan"
                        textField="name"
                        valueField="id"
                        dataKey="id"
                        value={plan.id}
                        onChange={(item) => setFieldValue(`subscriptionPlans[${index}].id`, item.id)}
                      />
                    </Col>
                    <Col xs={1}>
                      <DangerButton onClick={() => arrayHelpers.remove(index)}>
                        <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                      </DangerButton>
                    </Col>
                  </Row>
                );
              })}
              <PrimaryButton className={'u-margin-T-xs'} onClick={() => arrayHelpers.push({ id: null })}>
                Add Subscription Plan
              </PrimaryButton>
            </div>
          );
        }}
      />
    </>
  );
}

function renderClassSectionSection(liveClassCohort: LiveClassCohort, values: CohortFormValues) {
  if (liveClassCohort?.classSectionId) {
    return (
      <div className={styles.classSection}>
        <a href={paths.partner.class(liveClassCohort.classSectionId)} target={'educator-portal'}>
          Class section
        </a>
      </div>
    );
  } else {
    return (
      <div className={styles.classSection}>
        <FieldCheckbox
          name="createClassSection"
          id="cohort-create-class-section"
          checked={String(values.createClassSection) === 'true'}>
          Create a class section for this cohort
        </FieldCheckbox>
      </div>
    );
  }
}

export default CohortForm;
