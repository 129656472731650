import * as React from 'react';
import style from './style.module.scss';
import { BorderlessButton, DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { secondsFormattedAsMinutes } from 'utilities/time';
import Card from '@magoosh/lib/card';
import { paths } from 'config/path_helpers';
import { useState } from 'react';
import ProgressBar from '@magoosh/lib/progress_bar';
import { LessonCheckMark } from './lesson_check_mark';
import { DrillCard } from './drill_card';
import SimpleStudySchedule, {
  SimpleStudyScheduleProps
} from '@magoosh/organisms/study_schedule/simple_study_schedule';

interface LessonSeriesProps {
  authenticityToken: string;
  id: number;
  name: string;
  section: string;
  sectionSlug: string;
  subsection: string;
  subsectionSlug: string;
  image: string;
  blurb: string;
  lessons: Lesson[];
  drills: Drill[];
  nextLessonSeries: {
    name: string;
    slug: string;
    totalTimeInMinutes: number;
    locked: boolean;
  };
  nextStudyItem: SimpleStudyScheduleProps;
}

const LessonSeries: React.FC<LessonSeriesProps> = (props) => {
  const {
    name,
    id,
    section,
    sectionSlug,
    subsection,
    subsectionSlug,
    blurb,
    image,
    lessons,
    drills,
    nextLessonSeries,
    nextStudyItem
  } = props;

  const initialFinishedCount = lessons.filter((lesson) => lesson.markedFinished).length;
  const [finishedCountState, setFinishedCountState] = useState(initialFinishedCount);

  const totalTimeInMinutes = Math.round(
    lessons.reduce((previousValue, currentValue) => previousValue + currentValue.lengthSeconds, 0) / 60
  );

  const goToLesson = (lesson: Lesson) => {
    window.location.href = `${paths.lesson(lesson.slug)}?study_item=${id}`;
  };

  const incrementLessonCount = () => {
    setFinishedCountState(finishedCountState + 1);
  };
  const decrementLessonCount = () => {
    setFinishedCountState(finishedCountState - 1);
  };

  const renderImage = (image) => {
    if (!image) {
      return null;
    }
    let imageAsset = require(`./assets/${image}`); // Don't really understand how require works....
    if (!imageAsset || !imageAsset.default) {
      return null;
    }

    return (
      <div className={style.image}>
        <img src={imageAsset.default} />
      </div>
    );
  };

  const renderPdfLink = (lesson) => {
    if (lesson.pdfUrl) {
      return <BorderlessButton href={lesson.pdfUrl} icon={'file-pdf-o'} className={style.pdfIcon} />;
    }

    return null;
  };

  const renderNextLessonSeries = () => {
    if (finishedCountState < lessons.length) {
      return null;
    } else if (nextStudyItem) {
      return (
        <>
          <div>Next on Your Study Plan</div>
          <SimpleStudySchedule {...props.nextStudyItem} />
        </>
      );
    } else if (nextLessonSeries) {
      const ButtonTag = nextLessonSeries.locked ? DefaultButton : PrimaryButton;

      return (
        <ButtonTag href={paths.lessonSeries(nextLessonSeries.slug)}>
          Next Series
          {nextLessonSeries.locked ? (
            <>
              {' '}<FontAwesomeIcon icon={'lock'} />
            </>
          ) : null}
        </ButtonTag>
      );
    }

    return null;
  };

  return (
    <div className={style.lessonSeries}>
      <div className={style.section}>
        <div className={style.sectionPart}>
          <a href={paths.mcatLessonGroups(sectionSlug)}>{section}</a>
          {' > '}
        </div>
        <div className={style.sectionPart}>
          <a href={paths.mcatLessonGroups(sectionSlug, subsectionSlug)}>{subsection}</a>
        </div>
      </div>
      <div className={style.name}>{name}</div>
      <div className={style.blurb}>{blurb}</div>
      {renderImage(image)}
      <div className={style.sidebar}>{renderNextLessonSeries()}</div>
      <Card shadow className={style.lessons}>
        <div className={style.cardTitle}>
          <h4>
            <strong>Learn this topic</strong>
          </h4>
        </div>
        <div className={style.totalTime}>
          <span className={style.clockIcon}>
            <FontAwesomeIcon icon={'clock-o'} />
          </span>
          {totalTimeInMinutes} minutes
        </div>
        <div className={style.instructions}>
          Watch the following lessons where we break down the concepts within this subject.
        </div>
        <div className={style.progressBar}>
          <ProgressBar
            backgroundColor="#cef0e3"
            color="#26B67C"
            percentage={(finishedCountState / lessons.length) * 100}
            size={'md'}
          />
          <hr />
        </div>
        <div className={style.lessonsList}>
          {lessons.map((lesson) => {
            const { id, title, lengthSeconds } = lesson;

            return (
              <div key={id} className={style.lesson}>
                <div
                  className={style.icon}
                  onClick={() => {
                    goToLesson(lesson);
                  }}>
                  <FontAwesomeIcon icon={'play-circle-o'} />
                </div>
                <div className={style.itemName}>
                  <a
                    role={'link'}
                    tabIndex={0}
                    onClick={() => {
                      goToLesson(lesson);
                    }}>
                    {title}
                  </a>
                  {renderPdfLink(lesson)}
                </div>
                <div className={style.time}>{secondsFormattedAsMinutes(lengthSeconds)} minutes</div>
                <LessonCheckMark
                  authenticityToken={props.authenticityToken}
                  lesson={lesson}
                  incrementLessonCount={incrementLessonCount}
                  decrementLessonCount={decrementLessonCount}
                />
              </div>
            );
          })}
        </div>
      </Card>
      <div className={style.drills}>
        {drills.map((drill, index) => (
          <DrillCard
            drill={drill}
            index={drills.length > 1 ? index : null}
            key={drill.id}
          />
        ))}
      </div>
    </div>
  );
};

export default LessonSeries;
