import { DashboardData } from './types';

const tempData: DashboardData = {
  brandName: 'ACT',
  examSectionGroups: [
    {
      displayName: null,
      estimatedScore: null,
      previousScore: null,
      examSections: [
        {
          displayName: 'English',
          estimatedScore: '18 - 20',
          estimatedScoreTooltip: 'Answer X more questions for score',
          id: 99999,
          previousScore: 18,
          questionCounts: {
            answered: 12,
            total: 249,
            minForEstimatedScore: 49
          },
          slug: 'english',
          scoreStatus: {
            label: 'Needs work',
            type: 'alert'
          },
          subtitle: null
        }
      ]
    }
  ],
  testDate: '2018-10-27',
  points: {
    max: 800,
    today: 400
  },
  overallScore: {
    previous: 22,
    target: 28,
    scoreStatus: {
      label: 'On track',
      type: 'success'
    }
  },
  studyActivityThisWeek: {
    monday: true,
    wednesday: false
  },
  studyHours: {
    recommended: 30,
    userTotal: 4.5
  },
  studyPreferences: {
    days: {
      monday: true,
      tuesday: false,
      wednesday: true,
      thursday: false,
      friday: true,
      saturday: false,
      sunday: true
    },
    hoursPerDay: 1.5
  },
  diagnosticStatus: null,
  shouldSeeDiagnostic: false
};

export default tempData;
