import * as React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Header } from '@magoosh/b2b/app/organisms/header';
import { Class } from '@magoosh/b2b/app/pages/class';
import { NavBar, NavLink } from '@magoosh/b2b/app/organisms/nav_bar';
import { Assignment } from '@magoosh/b2b/app/pages/assignment';
import { Classes } from '@magoosh/b2b/app/pages/classes';
import { Student } from '@magoosh/b2b/app/pages/student';
import { Students } from '@magoosh/b2b/app/pages/students';
import { AssignmentsDataTable } from '@magoosh/b2b/app/organisms/assignments_data_table';
import { useContext, useEffect, useState } from 'react';
import EnrollStudentsForm from './pages/enroll_students_form';
import CreateEditSchoolForm from './pages/create_edit_school_form';
import CreateEditClassForm from './pages/create_edit_class_form';
import CreateEditAssignmentForm from './pages/create_edit_assignment_form';
import AssignInstructorsForm from './pages/assign_instructors_form';
import StudentForm from './pages/edit_student_form'
import style from './style.module.scss';
import { AppContext } from './app_context';
import { Instructors } from '@magoosh/b2b/app/pages/instructors';
import { Schools } from '@magoosh/b2b/app/pages/schools';
import { School } from '@magoosh/b2b/app/pages/school';
import { Reporting } from '@magoosh/b2b/app/pages/reporting';
import { QueryClient, QueryClientProvider } from 'react-query';

export const RootPath = '/partner/portal';

interface AppProps {
  admin: boolean;
  externalSourceUser: boolean;
  supervisor: boolean;
  currentUserEmail: string;
  showAssignments: boolean;
  showModulesReport: boolean;
  trialAccess: boolean;
}

export const App: React.FC<AppProps> = (props) => {
  const [lastTab, setLastTab] = useState(null);
  const [appContextState] = useState({
    admin: props.admin,
    externalSourceUser: props.externalSourceUser,
    supervisor: props.supervisor,
    currentUserEmail: props.currentUserEmail,
    setLastTab: setLastTab,
    showModulesReport: props.showModulesReport,
    trialAccess: props.trialAccess
  });

  const queryClient = new QueryClient();

  useEffect(() => {
    document.body.classList.add(style.partnerPortalBody);
  }, []);

  return (
    <AppContext.Provider value={appContextState}>
      <QueryClientProvider client={queryClient}>
        <Router basename={RootPath}>
          <Header />
          <Switch>
            <Route exact path={'/'}>
              <Redirect to={lastTab || '/classes'} />
            </Route>
            { !appContextState.trialAccess &&
              <Route path='/assignments/create' component={CreateEditAssignmentForm} />
            }
            <Route path='/assignments/:assignmentId/edit' component={CreateEditAssignmentForm} />
            <Route path='/assignments/:assignmentId' component={Assignment} />
            <Route path='/classes/create' component={CreateEditClassForm} />
            <Route path='/classes/:classSectionId/edit' component={CreateEditClassForm} />
            <Route path='/classes/:classSectionId' component={Class} />
            <Route path='/enroll' component={EnrollStudentsForm} />
            <Route path='/schools/create' component={CreateEditSchoolForm} />
            <Route path='/schools/:schoolId' component={School} />
            <Route path='/instructors/assign' component={AssignInstructorsForm} />
            <Route path='/students/:studentId/edit' component={StudentForm} />
            <Route path='/students/:studentId' component={Student} />
            <>
              <NavBar>
                {props.supervisor && <NavLink to='/schools' text='Schools' />}
                <NavLink to='/classes' text='Classes' />
                <NavLink to='/students' text='Students' />
                {props.supervisor && <NavLink to='/instructors' text='Instructors' />}
                {props.showAssignments && <NavLink to='/assignments' text='Assignments' />}
                <NavLink to='/reporting' text='Reporting' />
              </NavBar>
              <Route exact path='/assignments'>
                <SetAppLastTab tabName={'/assignments'} />
                <AssignmentsDataTable />
              </Route>
              <Route exact path='/classes'>
                <SetAppLastTab tabName={'/classes'} />
                <Classes />
              </Route>
              <Route exact path='/instructors'>
                <SetAppLastTab tabName={'/instructors'} />
                <Instructors />
              </Route>
              <Route exact path='/schools'>
                <SetAppLastTab tabName={'/schools'} />
                <Schools />
              </Route>
              <Route exact path='/students'>
                <SetAppLastTab tabName={'/students'} />
                <Students />
              </Route>
              <Route path='/reporting'>
                <SetAppLastTab tabName={'/reporting'} />
                <Reporting />
              </Route>
            </>
          </Switch>
        </Router>
      </QueryClientProvider>
    </AppContext.Provider>
  );
};

const SetAppLastTab: React.FC<{ tabName: string }> = (props) => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.setLastTab(props.tabName)
  }, [])

  return null;
}

export default App;
