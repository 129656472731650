import * as React from 'react';
import { Provider } from 'react-redux';
import configureStore from '@magoosh/store';
import AssignmentsContainer from './container';
import { setAssignments, setTeacherMode } from '../actions';

export default class extends React.Component {
  store = configureStore();

  constructor(props) {
    super(props);

    this.store.dispatch(setTeacherMode(props.teacherMode));

    this.store.dispatch(setAssignments(props.assignments));
  }

  render() {
    return (
      <Provider store={this.store}>
        <AssignmentsContainer />
      </Provider>
    );
  }
}
