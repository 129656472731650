import React from 'react';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

interface Props {
  applicationCycle: string;
}

const EditableApplicationCycle: React.FC<Props> = (props) => {
  return null;

  // TODO
  // return (
  //    <div className="d-flex" style={{ gap: 1 }}>
  //      <div className="bg-gray-lighter text-gray-light" style={{ fontSize: 12, padding: '4px 8px' }}>
  //       {
  //         props.applicationCycle && (
  //           `Application Cycle: ${props.applicationCycle}`
  //         )
  //       }
  //     </div>
  //   </div>
  // );
};

export default EditableApplicationCycle;
