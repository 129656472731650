import * as React from 'react';
import { TeacherDashboardData } from '../types';
import style from './style.module.scss';
import ReactTable from 'react-table';
import * as moment from 'moment';
import { paths } from 'config/path_helpers';
import { find } from 'lodash';
export interface ClassTableData extends TeacherDashboardData {
  loading: boolean;
  noDataText: string;
  showingRange: boolean;
}

export default class ClassTableTemplate extends React.Component<ClassTableData> {
  render() {
    return (
      <ReactTable
        noDataText={this.props.noDataText}
        loading={this.props.loading}
        className={style.classSectionTable}
        data={this.pickStudents()}
        columns={[
          {
            Header: 'Student',
            columns: [
              {
                Header: 'Last, First',
                accessor: 'name',
                Cell: (row) => (
                  <a target="_blank" href={paths.studentReport(row.original.id)}>
                    {row.value}
                  </a>
                )
              },
              {
                Header: 'Email',
                accessor: 'email',
                Cell: (row) => (
                  <a target="_blank" href={paths.studentReport(row.original.id)}>
                    {row.value}
                  </a>
                )
              }
            ]
          },
          {
            Header: 'Last Active',
            columns: [
              {
                Header: 'Last Active',
                Cell: (row) => <div>{this.formatDate(row.value)}</div>,
                accessor: 'lastActive',
                maxWidth: 110
              }
            ]
          },
          {
            Header: this.props.showingRange ? 'Usage within Date Range' : 'All Time Usage',
            columns: [
              {
                Header: (
                  <div>
                    Time
                    <br />
                    Spent
                  </div>
                ),
                accessor: 'timeSpent',
                maxWidth: 80,
                Cell: (row) => <div>{this.formatDuration(row.value)}</div>
              },
              {
                Header: (
                  <div>
                    Questions
                    <br />
                    Answered
                  </div>
                ),
                accessor: 'questionsAnswered',
                maxWidth: 90
              },
              {
                Header: (
                  <div>
                    Videos
                    <br />
                    Watched
                  </div>
                ),
                accessor: 'videosWatched',
                maxWidth: 80
              }
            ]
          },
          {
            Header: 'Last 7 Days',
            columns: [
              {
                Header: 'Time Spent',
                accessor: 'timeSpentLastWeek',
                Cell: (row) => <div>{this.formatDuration(row.value)}</div>
              }
            ]
          },
          {
            Header: 'Performance',
            columns: [
              {
                Header: 'Estimated Scores',
                accessor: 'estimatedScores',
                Cell: (row) => (
                  <div>
                    {Object.keys(row.value).map((examName) => {
                      const scoreRange = row.value[examName];

                      if (!scoreRange) {
                        return;
                      }

                      return (
                        <div className={style.scoreRange} key={examName}>
                          {examName.toLocaleUpperCase()}: {this.formatRange(scoreRange)}
                        </div>
                      );
                    })}
                  </div>
                ),
                sortMethod: (a: ScoreRange[], b: ScoreRange[], descending: boolean) => {
                  const aScoreLow = this.extractLowScore(a, descending);
                  const bScoreLow = this.extractLowScore(b, descending);

                  if (aScoreLow > bScoreLow) {
                    return 1;
                  }
                  if (aScoreLow < bScoreLow) {
                    return -1;
                  }

                  return 0;
                }
              }
            ]
          }
        ]}
        getLoadingProps={() => {
          return {
            className: style.loading
          };
        }}
        getNoDataProps={() => {
          return {
            className: style.noData
          };
        }}
      />
    );
  }

  // Extract the low score out of an array of score ranges for sorting purposes. Finds the range for the current
  // branding, and returns the low score for it. If a range for the current branding is not found, returns
  // infinity (negative infinity for descending sort) so that those records get sorted to the bottom. For ranges that
  // don't have enough data, return 1 million (negative 1 million for descending sort) so that those records appear
  // below valid ranges, but above students who don't have a subscription for the current branding.
  extractLowScore = (ranges: ScoreRange[], descending: boolean) => {
    const currentBrandingRange = find(ranges, (range) => range && range.isCurrentBranding);
    if (currentBrandingRange === null || currentBrandingRange === undefined) {
      return descending ? -Infinity : Infinity;
    }

    if (currentBrandingRange.notEnoughData) {
      return (descending ? -1 : 1) * 1000000;
    }

    return currentBrandingRange.scoreLow;
  };

  formatDate = (date: number) => {
    if (!date) {
      return null;
    }

    return moment.unix(date).format('MM/DD/YYYY');
  };

  formatDuration = (seconds: number) => `${Math.floor(seconds / 3600)}h ${Math.floor((seconds / 60) % 60)}m`;

  formatRange = (range: ScoreRange) => {
    if (range.notEnoughData) {
      return 'N/A';
    }

    return range.scoreLow + ' - ' + range.scoreHigh;
  };

  pickStudents = () => {
    if (this.props.currentClassSectionFilter) {
      return this.props.students.filter(
        (student) => student.classSectionIds.indexOf(this.props.currentClassSectionFilter) >= 0
      );
    } else {
      return this.props.students;
    }
  };
}
