import React from 'react';

import HandsRaisedIcon from '../assets/hands_raised.svg';
import MapIcon from '../assets/map.svg';

const infoSections = [
  {
    icon: <img src={HandsRaisedIcon} alt="Hands raised in the air" />,
    name: 'Get the IELTS Band Score You Need, Guaranteed!',
    text: 'Magoosh has graded writing and speaking assignments, full-length practice tests, expert video lessons, custom study schedules and more.'
  },
  {
    icon: <img src={MapIcon} alt="Map showing a path to follow" />,
    name: 'Plus! Magoosh Experts Answer an Unlimited Amount of Questions',
    text: 'Quick email and chat support from our test prep experts when you get stuck or just want some extra help.'
  }
];

export default { infoSections };
