import * as React from 'react';

import { ConstantsProvider } from '@magoosh/context/constants_context';

import ParentBlock from './parent_block/template';
import { ContentBlockEditorData } from './types';

const ContentBlockEditor: React.FC<ContentBlockEditorData> = (props) => {
  return (
    // We use the ConstantsProvider for access to the available colorPalettes and icons
    <ConstantsProvider initialData={props.context.constants}>
      <ParentBlock {...props} />
    </ConstantsProvider>
  );
};

export default ContentBlockEditor;
