import React from 'react';

const ACTDescriptionContent: React.FC = (props) => {
  return (
    <>
      <p>This practice test simulates the experience you will have taking the ACT on test day.</p>
      <p>When you finish the test, we will give you a <strong>score estimate</strong> which will help you predict how well you would do on the actual test. You'll also be able to <strong>review your answers</strong> and see the video explanations.</p>
      <p>The online practice test prioritizes questions you haven’t seen in your online practice to generate <strong>up to 4 new tests</strong>.</p>
    </>
  );
};

export default ACTDescriptionContent;
