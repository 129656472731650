import { merge, omit } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAppContext } from '@test_prep/app/context';

import * as api from './api';
// import {

// } from './types';

// These queries and mutations use React hooks, which can only be called from within a functional
// component. Because of that we need to wrap them in functions like below. To access them in a
// functional component:
//   import { useQueries } from './queries';
//   const q = useQueries();
export const useQueries = () => {
  return {
    pageQuery,
    statisticQuery
  };
};

export const pageQuery = (path, options = {}) => {
  const { data, setData } = useAppContext();

  return useQuery(['pages', path], () => api.fetchPage(path), {
    retry: false,
    cacheTime: Infinity,
    select: (pageData) => {
      if (pageData.context) {
        setData(merge(pageData.context, data));
        return omit(pageData, 'context');
      } else {
        return pageData;
      }
    },
    staleTime: Infinity,
    ...options
  });
};

export const statisticQuery = (name, filters, options = {}) => {
  return useQuery(['statistics', name, filters], () => api.fetchStatistic(name, filters), {
    retry: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    ...options
  });
};
