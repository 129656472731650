import React from 'react';
import cx from 'classnames';
import { omit, snakeCase } from 'lodash';
import { useHistory } from 'react-router-dom';

import Card from '@magoosh/lib/card';
import Filters, { filtersURLSearchParams } from '@magoosh/organisms/filters';
import { FilterConfig, FilterValues } from '@magoosh/organisms/filters/types';
import useSearchQuery from '@magoosh/utils/use_search_query';
import { paths } from 'config/path_helpers';

import ContentCard from '@admissions/components/content_card';
import EditableApplicationCycle from '@admissions/components/editable_application_cycle';
import TextTabButtons from '@admissions/components/text_tab_buttons';
import { useCurrentProfileContext } from '@magoosh/context/current_profile_context';
import ApplicationCycleChunks from '@admissions/organisms/application_cycle_chunks';
import HighlightedContentCardSet from '@admissions/organisms/highlighted_content_card_set';
import HighlightedContentDropdown from '@admissions/organisms/highlighted_content_dropdown';
import StatusMessage from '@admissions/organisms/status_message';
import SectionsOverview from '@admissions/organisms/sections_overview';
import { useQueries } from '@admissions/queries';
import {
  ApplicationCycleChunk,
  AdmissionsHighlightedContent,
  ApplicationCycleFilters,
  ApplicationCyclePlan,
  ApplicationCycleSection
} from '@admissions/types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  highlightedContent: AdmissionsHighlightedContent[];
  notesHref?: string;
  plan: ApplicationCyclePlan;
  sections: ApplicationCycleSection[];
}

const VIEW_APPLICATION_TRACKER = 'application_tracker';
const VIEW_OVERVIEW = 'overview';

const GradAdmissionsDashboard: React.FC<Props> = (props) => {
  const { currentProfile } = useCurrentProfileContext();
  const history = useHistory();
  const q = useQueries();

  // Filters
  const filters = {
    focus: useSearchQuery()['filters[focus]'] || null,
    sections: useSearchQuery()['filters[sections]'] || 'all',
    status: useSearchQuery()['filters[status]'] || 'to_do'
  } as ApplicationCycleFilters;
  const view = useSearchQuery().view as string || VIEW_APPLICATION_TRACKER;

  const filtersConfig = [
    {
      all: true,
      className: 'flex-1',
      multiple: true,
      key: 'sections',
      name: 'Section',
      options: props.sections.map((s) => ({ name: s.name, value: snakeCase(s.name) }))
    },
    {
      key: 'status',
      name: 'Status',
      options: [
        { name: 'To Do', value: 'to_do' },
        { name: 'Completed', value: 'completed' },
      ]
    }
  ] as FilterConfig[];

  const applicationCycleChunksQuery = q.applicationCycleChunksQuery(() => {
    return { cycle: currentProfile.applicationCycle, filters, startingAt: 0 };
  });

  const chunks = () => {
    return applicationCycleChunksQuery.data;
  };

  const handleFiltersChanged = (updatedFilters: ApplicationCycleFilters) => {
    updateDashboardPathWithParams({ filters: updatedFilters });
  };

  const handleFocusOnSingleSection = (sectionName: string) => {
    const updatedFilters = {
      ...filters,
      sections: snakeCase(sectionName)
    };
    updateDashboardPathWithParams({ filters: updatedFilters, view: VIEW_APPLICATION_TRACKER });
  };

  const handleFocusOnSingleSegment = (segmentName: string) => {
    const updatedFilters = {
      ...filters,
      focus: segmentName
    };
    updateDashboardPathWithParams({ filters: updatedFilters, view: VIEW_APPLICATION_TRACKER });
  };

  const handleViewChanged = (view: string) => {
    updateDashboardPathWithParams({ view });
  };

  const isLoadedAndHasChunks = () => {
    return chunks() && chunks().length > 0;
  };

  const isLoadedButHasNoChunks = () => {
    return chunks() && chunks().length === 0;
  };

  const isLoadingInitialChunks = () => {
    return !chunks();
  };

  const updateDashboardPathWithParams = (params) => {
    const queryParams = filtersURLSearchParams(params.filters || filters);
    queryParams.append('view', params.view || view);
    history.replace(`${paths.dashboard()}?${queryParams.toString()}`);
  };

  return (
    <div>
      {/*Header with content card*/}
      <div className="bg-white u-border-B-s">
        {/*On sm/xs, display content card above the title and full width*/}
        <div className="visible-sm visible-xs">
          {/*TODO*/}
          {/*<ContentCard className="u-padding-V-xs" />*/}
        </div>

        <div className="container">
          <div className="align-items-center d-flex u-padding-V-xs">
            <div style={{flexGrow: 1}}>
              <h3 className="u-margin-A-n">
                <strong>Grad Admissions Dashboard</strong>
              </h3>

              {/*On sm/xs, display highlighted content as a select dropdown*/}
              <div className="visible-sm visible-xs u-margin-T-s">
                <div><small>Quick Links</small></div>
                <HighlightedContentDropdown
                  highlightedContent={props.highlightedContent}
                  notesHref={props.notesHref}
                />
              </div>
            </div>

            <div className="hidden-sm hidden-xs">
              {/*TODO*/}
              {/*<ContentCard />*/}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/*Highlighted content cards*/}
        <div className="hidden-sm hidden-xs u-margin-V-m">
          <HighlightedContentCardSet
            highlightedContent={props.highlightedContent}
            notesHref={props.notesHref}
          />
          <hr />
        </div>

        {/*On sm/xs, tabs to switch between study schedule and application tracker*/}
        <div className="visible-sm visible-xs">
          <div className="u-margin-B-n u-margin-T-m">
            <TextTabButtons currentTab={view} onChange={handleViewChanged}>
              <TextTabButtons.Tab name={VIEW_OVERVIEW}>Overview</TextTabButtons.Tab>
              <TextTabButtons.Tab name={VIEW_APPLICATION_TRACKER}>Application Tracker</TextTabButtons.Tab>
            </TextTabButtons>

            <hr className="u-margin-V-xs" />
          </div>
        </div>

        {/*Column titles*/}
        <div className="hidden-sm hidden-xs">
          <div className="d-flex" style={{ gap: 40 }}>
            <div className={style.constrainedWidth}>
              <h3 className="u-margin-A-n">Overview</h3>
              <hr className="u-margin-V-s" />
            </div>

            <div className="flex-1">
              <div className="align-items-center d-flex">
                <div className="flex-1">
                  <h3 className="u-margin-A-n">Application Tracker</h3>
                </div>

                <EditableApplicationCycle applicationCycle={props.plan?.applicationCycle} />
              </div>

              <hr className="u-margin-V-s" />
            </div>
          </div>
        </div>

        <div className="d-flex" style={{ gap: 40 }}>
          <div className={cx(style.constrainedWidth, { 'hidden-sm hidden-xs': view !== VIEW_OVERVIEW })}>
            <Card>
              <SectionsOverview
                onSectionFocus={handleFocusOnSingleSection}
                onSegmentFocus={handleFocusOnSingleSegment}
                sections={props.sections}
              />
            </Card>
          </div>

          <div
            className={cx('d-flex flex-1 flex-column', { 'hidden-sm hidden-xs': view !== VIEW_APPLICATION_TRACKER })}
            style={{ maxWidth: 730, width: '100%' }}
          >
            <Filters
              filters={filters as FilterValues}
              filtersConfig={filtersConfig}
              key={JSON.stringify(filtersConfig)}
              onChange={handleFiltersChanged}
            />

            <div className="u-margin-T-m">
              {
                isLoadingInitialChunks() && (
                  <StatusMessage headline="Loading Your Tasks" text={null} isColored />
                )
              }
              {
                isLoadedButHasNoChunks() && (
                  <StatusMessage
                    headline="No Tasks to Display"
                    text="Adjust your filters to see more of your tasks."
                  />
                )
              }
              {/*TODO - empty and complete*/}
              {
                isLoadedAndHasChunks() && (
                  <ApplicationCycleChunks
                    applicationCycle={currentProfile.applicationCycle}
                    chunks={chunks()}
                    hideHeader={!!filters.focus}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GradAdmissionsDashboard;
