import React, { useState } from 'react';
import cx from 'classnames';
import { Link as SectionLink } from 'react-scroll';

import { EditorialSection } from '../types';

import style from './style.module.scss';

interface Props {
  height: number;
  offsetY: number;
  sections: EditorialSection[];
}

const SectionNav: React.FC<Props> = (props) => {
  const [activeSectionSlug, setActiveSectionSlug] = useState<string>(props.sections[0]?.slug);
  // Scroll to the section with an offset so it appears below all the sticky elements
  const scrollOffset = (props.offsetY + props.height) * -1;

  const handleSetActive = (slug) => {
    setActiveSectionSlug(slug)
  };

  return (
    <div className="align-items-center d-flex" style={{ gap: 5, height: props.height }}>
      {
        props.sections.map((section, idx) => (
          <SectionLink
            key={idx}
            className={cx(
              style.sectionPill,
              'small text-no-decoration u-padding-H-xs u-padding-V-xxs',
              { [style.active]: section.slug === activeSectionSlug }
            )}
            to={section.slug}
            spy={true}
            hashSpy={true}
            smooth={true}
            offset={scrollOffset}
            duration={500}
            spyThrottle={500}
            onSetActive={handleSetActive}
          >
            <div
              onClick={() => handleSetActive(section.slug)}
              style={{
                // Typescript is not recognizing this property which is why we cast as 'any'
                textWrap: 'nowrap'
              } as any}
            >
              <strong>
                {section.name}
              </strong>
            </div>
          </SectionLink>
        ))
      }
    </div>
  );
};

export default SectionNav;
