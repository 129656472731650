import React, { useEffect, useState } from 'react';
import { compact, difference, find, isNaN } from 'lodash';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import { useModalContext } from '@magoosh/context/modal_context';
import CardLayout from '@magoosh/layouts/card';
import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { DefaultButton, TabButton } from '@magoosh/lib/button';
import { Select } from '@magoosh/lib/forms';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import useSearchQuery from '@magoosh/utils/use_search_query';
import { adminPaths } from 'config/path_helpers';
import { toSentence } from 'utilities/formatting';

import { useQueries } from '@admin/app/queries';
import ContextualLink from '@admin/app/components/contextual_link';

import ContentAudit from './components/content_audit';
import ContentFix from './components/content_fix';
import ContentFixActions from './components/content_fix_actions';
import CreateContentAuditModal from './components/create_content_audit_modal';
import CreateContentFixModal from './components/create_content_fix_modal';
import DiffableAnswerChoices from './components/diffable_answer_choices';
import DiffableContent from './components/diffable_content';
import EditDraftPromptModal from './components/edit_draft_prompt_modal';
import Ratings from './components/ratings';
import StatusBadge from './components/status_badge';
import { Version } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {}

const AIPromptVersionHistory: React.FC<Props> = (props) => {
  const history = useHistory();
  const { setModal } = useModalContext();
  const id = parseInt(useParams().id);
  const versionId = useParams().versionId ? parseInt(useParams().versionId) : null;
  const q = useQueries();
  const contentDisplayType = useSearchQuery()['content_display_type'] || 'rendered';
  const showContentDiffs = useSearchQuery()['show_content_diffs'] === 'true';
  const [specificVersion, setSpecificVersion] = useState(null);

  let proposedFixId = parseInt(useSearchQuery()['proposed_fix'] as string);
  if (isNaN(proposedFixId)) proposedFixId = null;

  const pageQuery = q.pageQuery(adminPaths.aiPromptVersions(id));
  const page = pageQuery.data;
  const versionDetailsQuery = q.aiPromptVersionDetailsQuery(id, versionId, proposedFixId);
  const versionDetails = versionDetailsQuery.data;

  const breadcrumbs = [
    { name: 'Admin', path: adminPaths.dashboard() },
    { name: `AI Prompt #${id}`, path: adminPaths.aiPrompt(id) },
    { name: 'Version History' }
  ];

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';

  useEffect(() => {
    setSpecificVersion(versionDetails);
  }, [versionDetails])

  const isProposedFix = () => {
    return !!proposedFixId;
  };

  const liveVersion = () => {
    if (page.versions.length === 0) return;

    return find(page.versions, (version) => version.isLive);
  };

  const presentAuditRequestModal = () => {
    setModal({
      header: 'Request an Audit',
      body: (
        <CreateContentAuditModal
          aiPromptId={id}
          onClose={() => setModal(null)}
          onSuccess={(audit) => (
            setSpecificVersion({
              ...specificVersion,
              audits: [audit, ...specificVersion.audits]
            })
          )}
        />
      )
    });
  };

  const presentEditDraftPromptModal = (fieldName: 'answerChoiceGroups' | 'explanation' | 'prompt') => {
    setModal({
      header: 'Edit Content',
      body: (
        <EditDraftPromptModal
          draftPromptId={specificVersion.content.id}
          fieldName={fieldName}
          onClose={() => setModal(null)}
          onSuccess={() => versionDetailsQuery.refetch()}
        />
      )
    });
  };

  const presentFixRequestModal = () => {
    setModal({
      header: 'Request a Fix',
      body: (
        <CreateContentFixModal
          aiPromptId={id}
          onClose={() => setModal(null)}
          onSuccess={(fix) => (
            setSpecificVersion({
              ...specificVersion,
              fixes: [fix, ...specificVersion.fixes]
            })
          )}
        />
      )
    });
  };

  const previousVersion = () => {
    if (!specificVersion) return;

    if (isProposedFix() && !specificVersion.previousVersionId) {
      return page.versions[0];
    }  else if (!specificVersion.previousVersionId) {
      return;
    }

    return find(page.versions, (version) => version.id === specificVersion.previousVersionId);
  };

  const reviewContentFix = (id) => {
    const params = new URLSearchParams(window.location.search);
    params.set('proposed_fix', `${id}`);
    history.replace(`${window.location.pathname}?${params.toString()}`);
  };

  const setContentDisplayType = (type) => {
    const params = new URLSearchParams(window.location.search);
    params.set('content_display_type', type);
    history.replace(`${window.location.pathname}?${params.toString()}`);
  };

  const setShowContentDiffs = (show) => {
    const params = new URLSearchParams(window.location.search);
    params.set('show_content_diffs', `${show}`);
    history.replace(`${window.location.pathname}?${params.toString()}`);
  };

  const switchToVersionId = (newVersionId) => {
    const params = new URLSearchParams(window.location.search);
    params.delete('proposed_fix');
    history.replace(`${adminPaths.aiPromptVersions(id, newVersionId)}?${params.toString()}`);
  };

  return (
    <>
      <Helmet>
        <title>AI Prompt {id.toString()} - Version {versionId ? versionId.toString() : ''}</title>
      </Helmet>

      <CardLayout breadcrumbs={breadcrumbs} linkComponent={ContextualLink} loading={!page}>
        {({ loading }) => {
          return (
            <div className="d-flex flex-column gap-m">
              <div className="align-items-center d-flex gap-m">
                <h1 className="text-body u-margin-A-n"><strong>AI Prompt {id}</strong></h1>
                <h3 className="text-gray-light u-margin-A-n"><strong>Version History</strong></h3>
              </div>

              <div>
                <div className="align-items-center d-flex">
                  {
                    page.versions.slice(0, 6).map((version) => (
                      <TabButton
                        key={version.id}
                        disabled={versionId === version.id}
                        onClick={() => switchToVersionId(version.id)}
                      >
                        <div className="align-items-center d-flex gap-xs">
                          <StatusBadge status={version.auditStatus} />

                          <div>
                            {version.isLive && 'Current: '}
                            {version.name}
                          </div>
                        </div>
                      </TabButton>
                    ))
                  }

                  <div className="d-flex flex-1 justify-content-end">
                    <Select
                      onChange={(e) => {
                        if (!e.target.value) return;
                        switchToVersionId(e.target.value);
                      }}
                      value={versionId}
                    >
                      <option value={null}>Older Versions</option>

                      {
                        page.versions.slice(6).map((version: Version) => (
                          <option key={version.id} value={version.id}>
                            {version.name}
                          </option>
                        ))
                      }
                    </Select>
                  </div>
                </div>

                <hr className="u-margin-A-n" />
              </div>

              {
                specificVersion ? (
                  <>
                    {
                      isProposedFix() && (
                        <div className="align-items-center d-flex gap-s">
                          <h4 className="u-margin-A-n">
                            <a onClick={() => switchToVersionId(versionId)}>
                              <FontAwesomeIcon icon="arrow-left" />
                            </a>
                          </h4>

                          <div>
                            <StatusBadge status={specificVersion.status} />
                          </div>

                          <h4 className="u-margin-A-n">
                            <strong>
                              Proposed Fix, {moment(specificVersion.createdAt * 1000).format('MMMM D, YYYY')}
                            </strong>
                          </h4>

                          {
                            specificVersion.status === 'pending' && (
                              <div className="d-flex flex-1 gap-s justify-content-end">
                                <ContentFixActions
                                  contentFixId={proposedFixId}
                                  onAccept={() => {
                                    pageQuery.refetch();
                                    versionDetailsQuery.refetch();
                                  }}
                                  onReject={() => {
                                    versionDetailsQuery.refetch();
                                  }}
                                />
                              </div>
                            )
                          }
                        </div>
                      )
                    }

                    <div className="d-flex flex-column gap-l">
                      <CardLayout.Card className="d-flex flex-column gap-s">
                        {
                          isProposedFix() && (
                            <>
                              <div className="d-flex flex-column gap-s">
                                <div className="d-flex flex-column gap-xxs">
                                  <div><strong>Reason for Fix</strong></div>
                                  <div>
                                    This fix is in response to {toSentence(specificVersion.metadata.fixInstructionsInputsTypes)}.
                                  </div>
                                </div>

                                <div className="d-flex flex-column gap-xxs">
                                  <div><strong>Instructions</strong></div>

                                  <div>
                                    {specificVersion.metadata.fixInstructions}
                                  </div>
                                </div>
                              </div>

                              <hr className="u-margin-V-xs" />
                            </>
                          )
                        }
                        <div>Created At: {moment(specificVersion.createdAt * 1000).tz(timeZone).format('LLL')}</div>
                        <div>Created By: {specificVersion.createdBy}</div>
                        <div>
                          {isProposedFix() ? 'Based On:' :  'Previous Version:'}{' '}
                          {
                            previousVersion() ? (
                              <a onClick={() => switchToVersionId(previousVersion().id)}>
                                {previousVersion().name}
                              </a>
                            ) : (
                              'None'
                            )
                          }
                        </div>
                      </CardLayout.Card>

                      <div className="d-flex flex-column gap-s">
                        <h3 className="u-margin-A-n"><strong>Audits</strong></h3>

                        {
                          specificVersion.audits.map((audit) => (
                            <CardLayout.Card key={audit.id} className="d-flex flex-column gap-s">
                              <ContentAudit audit={audit} />
                            </CardLayout.Card>
                          ))
                        }

                        {
                          specificVersion.isLive && (
                            <div>
                              <DefaultButton onClick={presentAuditRequestModal}>
                                Request an Audit
                              </DefaultButton>
                            </div>
                          )
                        }
                      </div>

                      {
                        !isProposedFix() && (
                          <div className="d-flex flex-column gap-s">
                            <h3 className="u-margin-A-n"><strong>Fixes</strong></h3>

                            {
                              specificVersion.fixes.map((fix) => (
                                <CardLayout.Card key={fix.id} className="d-flex flex-column gap-s">
                                  <ContentFix fix={fix} onReview={() => reviewContentFix(fix.id)} />
                                </CardLayout.Card>
                              ))
                            }

                            {
                              specificVersion.isLive && (
                                <div>
                                  <DefaultButton onClick={presentFixRequestModal}>
                                    Request a Fix
                                  </DefaultButton>
                                </div>
                              )
                            }
                          </div>
                        )
                      }

                      <div className="d-flex flex-column gap-s">
                        <div className="d-flex gap-s">
                          <h3 className="u-margin-A-n"><strong>Content</strong></h3>

                          <div className="d-flex gap-xs">
                            <div
                              onClick={() => setContentDisplayType('rendered')}
                              style={{
                                backgroundColor: contentDisplayType === 'rendered' ? colors.grayDark : '#fff',
                                border: `1px solid ${contentDisplayType === 'rendered' ? colors.grayDark : colors.gray}`,
                                borderRadius: 2,
                                color: contentDisplayType === 'rendered' ? '#fff' : colors.textGray,
                                cursor: contentDisplayType === 'rendered' ? 'default' : 'pointer',
                                fontSize: 12,
                                padding: '2px 5px'
                              }}
                            >
                              Rendered
                            </div>
                            <div
                              onClick={() => setContentDisplayType('text')}
                              style={{
                                backgroundColor: contentDisplayType === 'text' ? colors.grayDark : '#fff',
                                border: `1px solid ${contentDisplayType === 'text' ? colors.grayDark : colors.gray}`,
                                borderRadius: 2,
                                color: contentDisplayType === 'text' ? '#fff' : colors.textGray,
                                cursor: contentDisplayType === 'text' ? 'default' : 'pointer',
                                fontSize: 12,
                                padding: '2px 5px'
                              }}
                            >
                              Text
                            </div>
                          </div>

                          <div className="align-items-center d-flex flex-1 gap-s justify-content-end">
                            <div className="align-items-center d-flex gap-xs hidden-xs">
                              <input
                                className={style.checkbox}
                                defaultChecked={showContentDiffs}
                                id="showContentDiffs"
                                onChange={(e) => setShowContentDiffs(e.target.checked)}
                                type="checkbox"
                              />
                              <label
                                className="small u-margin-A-n"
                                htmlFor="showContentDiffs"
                                style={{ fontWeight: 400 }}
                              >
                                Show content diffs
                              </label>
                            </div>
                          </div>
                        </div>

                        <CardLayout.Card className="d-flex flex-column gap-s">
                          <div className="d-flex">
                            <h4 className="u-margin-A-n"><strong>Prompt</strong></h4>

                            {
                              isProposedFix() && specificVersion.status === 'pending' && (
                                <div className="d-flex flex-1 justify-content-end">
                                  <DefaultButton
                                    onClick={() => presentEditDraftPromptModal('prompt')}
                                  >
                                    Edit
                                  </DefaultButton>
                                </div>
                              )
                            }
                          </div>

                          <DiffableContent
                            currentVersion={{
                              content: specificVersion.content.prompt.current,
                              name: 'This Version'
                            }}
                            previousVersion={{
                              content: specificVersion.content.prompt.previous,
                              name: previousVersion()?.name
                            }}
                            showDiff={showContentDiffs}
                            showVersionNames
                            type={contentDisplayType as 'rendered' | 'text'}
                          />
                        </CardLayout.Card>

                        <CardLayout.Card className="d-flex flex-column gap-s">
                          <div className="d-flex">
                            <h4 className="u-margin-A-n"><strong>Answer Choices</strong></h4>

                            {
                              isProposedFix() && specificVersion.status === 'pending' && (
                                <div className="d-flex flex-1 justify-content-end">
                                  <DefaultButton
                                    onClick={() => presentEditDraftPromptModal('answerChoiceGroups')}
                                  >
                                    Edit
                                  </DefaultButton>
                                </div>
                              )
                            }
                          </div>

                          <DiffableAnswerChoices
                            answerChoiceGroups={specificVersion.content.answerChoiceGroups}
                            previousVersion={previousVersion()}
                            showDiff={showContentDiffs}
                            type={contentDisplayType as 'rendered' | 'text'}
                          />
                        </CardLayout.Card>

                        <CardLayout.Card className="d-flex flex-column gap-s">
                          <div className="d-flex">
                            <h4 className="u-margin-A-n"><strong>Explanation</strong></h4>

                            {
                              isProposedFix() && specificVersion.status === 'pending' && (
                                <div className="d-flex flex-1 justify-content-end">
                                  <DefaultButton
                                    onClick={() => presentEditDraftPromptModal('explanation')}
                                  >
                                    Edit
                                  </DefaultButton>
                                </div>
                              )
                            }
                          </div>

                          <DiffableContent
                            currentVersion={{
                              content: specificVersion.content.explanation.current,
                              name: 'This Version'
                            }}
                            previousVersion={{
                              content: specificVersion.content.explanation.previous,
                              name: previousVersion()?.name
                            }}
                            showDiff={showContentDiffs}
                            showVersionNames
                            type={contentDisplayType as 'rendered' | 'text'}
                          />
                        </CardLayout.Card>
                      </div>

                      {
                        !isProposedFix() && (
                          <div className="d-flex flex-column gap-s">
                            <h3 className="u-margin-A-n"><strong>Ratings</strong></h3>

                            <CardLayout.Card className="d-flex flex-column gap-s">
                              <Ratings
                                period={specificVersion.periodLive}
                                rateable={{ id, type: 'AIPrompt' }}
                              />
                            </CardLayout.Card>
                          </div>
                        )
                      }
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-center u-margin-T-l">
                    <LoadingIndicator />
                  </div>
                )
              }
            </div>
          );
        }}
      </CardLayout>
    </>
  );
};

export default AIPromptVersionHistory;
