import * as React from 'react';
import ReactTable from 'react-table';
import style from '../style.module.scss';
import { Fragment } from 'react';

export interface ClassActivityProps {
  data: ClassActivityData[];
}

export interface ClassActivityData {
  displayName: string;
  teacherNames: string[];
  studentsEnrolled: number;
  weekActiveStudents: number;
  allTimeActiveStudents: number;
}

export default class ClassActivity extends React.Component<ClassActivityProps> {
  render() {
    return (
      <Fragment>
        <div className={style.sectionHeader}>Class Activity</div>
        <ReactTable
          defaultPageSize={10}
          noDataText="No classes to display"
          data={this.props.data}
          defaultFilterMethod={(filter, row) => {
            const id = filter.pivotId || filter.id;

            if (row[id] !== undefined) {
              return String(row[id]).toLocaleLowerCase().includes(filter.value.toLowerCase());
            }

            return true;
          }}
          columns={[
            {
              Header: 'Class Details',
              maxWidth: 850,
              columns: [
                {
                  Header: 'Class',
                  accessor: 'ensuredName',
                  maxWidth: 375
                },
                {
                  Header: 'Instructors',
                  accessor: 'teacherNames',
                  maxWidth: 300,
                  Cell: (row) => {
                    if (!row.value) {
                      return null;
                    }
                    return row.value.join(', ');
                  }
                },
                {
                  Header: 'Students Enrolled',
                  accessor: 'studentsEnrolled',
                  maxWidth: 175
                }
              ]
            },
            {
              Header: 'Active Students',
              maxWidth: 220,
              columns: [
                {
                  Header: 'Last 7 Days',
                  accessor: 'weekActiveStudents',
                  maxWidth: 110
                },
                {
                  Header: 'All Time',
                  accessor: 'allTimeActiveStudents',
                  maxWidth: 110
                }
              ]
            }
          ]}
        />
      </Fragment>
    );
  }
}
