import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { find, without } from 'lodash';
import moment from 'moment';

import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { DangerBadge } from '@magoosh/lib/badge';
import { DefaultFilledButton, PrimaryButton, ResponsiveButtonGroup } from '@magoosh/lib/button';
import { FieldInput } from '@magoosh/lib/formik_inputs/field_input/template';
import { FieldSelect } from '@magoosh/lib/formik_inputs/field_select/template';
import { Form, Input } from '@magoosh/lib/forms';
import Tabs from '@magoosh/lib/tabs';

import * as api from '@admin/app/api';

import { ContentFix } from '../../types';

interface Props {
  aiPromptId: number;
  onClose: () => void;
  onSuccess: (fix: ContentFix) => void;
}

const CreateContentFixModal: React.FC<Props> = (props) => {
  const [availableEvaluators, setAvailableEvaluators] = useState(null);
  const [referenceableAudits, setReferenceableAudits] = useState(null);
  const [referenceableRatings, setReferenceableRatings] = useState(null);

  const [auditsForInstructions, setAuditsForInstructions] = useState([]);
  const [manualInstructions, setManualInstructions] = useState('');

  const initialValues = {
    attemptLimit: 1,
    autoApply: false,
    evaluator: ''
  };

  useEffect(() => {
    api.fetchTestPrepAIContentFixesAvailableEvaluators(props.aiPromptId, 'AIPrompt').then(
      (evaluators) => (
        setAvailableEvaluators(evaluators)
      )
    );

    api.fetchTestPrepAIContentFixesReferenceableContent(props.aiPromptId, 'AIPrompt').then(
      (referenceableContent) => {
        setReferenceableAudits(referenceableContent.audits);
        setReferenceableRatings(referenceableContent.ratings);
      }
    );
  }, []);

  const composeInstructionsInputs = () => {
    let inputs = [];

    auditsForInstructions.forEach((audit) => (
      inputs.push({ id: audit.id, type: 'TestPrep::AI::ContentAudits::ContentAudit' })
    ));

    if (manualInstructions !== '') {
      inputs.push(manualInstructions)
    }

    return inputs;
  };

  const defaultTabName = () => {
    if (referenceableAudits?.length) {
      return 'audits';
    } else if (referenceableRatings?.length) {
      return 'ratings';
    } else {
      return 'instructions';
    }
  };

  const handleAuditForInstructionsToggled = (audit, newCheckedValue) => {
    if (newCheckedValue) {
      setAuditsForInstructions([
        ...auditsForInstructions,
        audit
      ]);
    } else {
      setAuditsForInstructions(without(auditsForInstructions, audit));
    }
  };

  const handleManualInstructionsChanged = (event) => {
    setManualInstructions(event.target.value);
  };

  const handleOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const contentFix = {
      contentId: props.aiPromptId,
      contentType: 'AIPrompt',
      context: {
        instructionsInputs: composeInstructionsInputs()
      },
      ...values
    };

    api.createTestPrepAIContentFix(contentFix).then((fix) => {
      props.onSuccess(fix);
      props.onClose();
    });
  };

  const isLoaded = () => {
    return !!availableEvaluators && !!referenceableAudits && !!referenceableRatings;
  };

  if (isLoaded()) {
    return (
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <FieldInput
                label="Attempt Limit"
                name="attemptLimit"
                type="number"
              />

              <FieldSelect
                className="form-control u-margin-L-n"
                keyAccessor="name"
                label="Quality Evaluator"
                name="evaluator"
                objects={[{ name: '', klass: null }, ...availableEvaluators]}
                valueAccessor="klass"
              />

              <FieldSelect
                className="form-control u-margin-L-n"
                label="When Complete"
                name="autoApply"
                options={{ 'Auto Apply': true, 'Hold For Review': false }}
              />

              <div className="form-group u-margin-T-s">
                <label>Content to Reference</label>

                <Tabs defaultTab={defaultTabName()}>
                  <Tabs.Tab tab="audits">Audits</Tabs.Tab>
                  <Tabs.Tab tab="ratings">Ratings</Tabs.Tab>
                  <Tabs.Tab tab="instructions">Instructions</Tabs.Tab>

                  <Tabs.Panel tab="audits">
                    <div className="d-flex flex-column gap-s">
                      {
                        referenceableAudits.map((audit) => {
                          const checked = !!find(auditsForInstructions, (a) => a.id === audit.id);

                          return (
                            <React.Fragment key={audit.id}>
                              <div className="d-flex gap-m">
                                <input
                                  checked={checked}
                                  id={`auditCheckbox-${audit.id}`}
                                  onChange={() => handleAuditForInstructionsToggled(audit, !checked)}
                                  type="checkbox"
                                />

                                <div
                                  className="d-flex flex-column gap-xs"
                                  onClick={() => handleAuditForInstructionsToggled(audit, !checked)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="align-items-center d-flex gap-xs">
                                    <DangerBadge>Fail</DangerBadge>

                                    <strong>
                                      {audit.name}, {moment(audit.createdAt * 1000).format('MMMM D, YYYY')}
                                    </strong>
                                  </div>

                                  <div>
                                    {audit.result.reasoning}
                                  </div>
                                </div>
                              </div>

                              <hr className="u-margin-A-n" />
                            </React.Fragment>
                          );
                        })
                      }
                    </div>
                  </Tabs.Panel>

                  <Tabs.Panel tab="ratings">
                    TODO
                  </Tabs.Panel>

                  <Tabs.Panel tab="instructions">
                    <textarea rows={10} onChange={handleManualInstructionsChanged}>
                      {manualInstructions}
                    </textarea>
                  </Tabs.Panel>
                </Tabs>
              </div>

              <div className="u-margin-T-s">
                <ResponsiveButtonGroup>
                  <PrimaryButton submitting={isSubmitting} type="submit">
                    Request Fix
                  </PrimaryButton>

                  <DefaultFilledButton onClick={props.onClose}>
                    Cancel
                  </DefaultFilledButton>
                </ResponsiveButtonGroup>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  } else {
    return (
      <div className="d-flex justify-content-center u-margin-T-l">
        <LoadingIndicator />
      </div>
    );
  }
};

export default CreateContentFixModal;
