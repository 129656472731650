import React from 'react';
import { find, sumBy } from 'lodash';

import { DonutChart } from '@magoosh/lib/charts';
import { paths } from 'config/path_helpers';
import { titleize, toSentence } from 'utilities/formatting';
import { pluralize } from 'utilities/pluralize';
import { minutesFormattedAsShortString } from 'utilities/time';

import StatisticData from '@test_prep/app/pages/analytics/components/statistic_data';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  filters: any;
}

const FlashcardsMasteredChart: React.FC<Props> = (props) => {
  const loadingPlaceholder = (
    <DonutChart
      chartProps={{ animate: false }}
      // TODO - Make placeholder labels below exam agnostic
      data={[
        { color: colors.gray85, label: 'Math Mastered', value: 40 },
        { color: colors.grayLighter, label: 'Vocab Mastered', value: 30 }
      ]}
      legendPosition="bottom"
      size={150}
    />
  );

  const formatStatisticData = (statisticData) => {
    const predeterminedColors = {
      'Math': colors.dataLightPurple,
      'Vocab': colors.dataLightBlue
    };

    return statisticData.map((datum) => {
      const formattedData = {
        label: `${datum.name} Mastered`,
        slug: datum.slug,
        value: datum.value
      } as any;

      if (predeterminedColors[datum.name]) {
        formattedData.color = predeterminedColors[datum.name];
      }

      return formattedData;
    });
  };

  if (!props.filters) {
    return loadingPlaceholder;
  } else {
    return (
      <StatisticData
        filters={props.filters}
        formatDataFn={formatStatisticData}
        name="flashcards_mastered"
      >
        <StatisticData.Loading>
          {loadingPlaceholder}
        </StatisticData.Loading>

        {/* @ts-ignore */}
        <FlashcardsMasteredContent color={colors.dataDarkPurple} />
      </StatisticData>
    );
  }
};

interface FlashcardsMasteredContentProps {
  color: string;
  data: {
    label: string;
    slug: string;
    value: number;
  }[];
}

const FlashcardsMasteredContent: React.FC<FlashcardsMasteredContentProps> = (props) => {
  const deckSetLink = (slug) => {
    const name = titleize(slug).replace('Vocabulary', 'Vocab');
    return `<a class="text-gray-light" href="${paths.flashcards(slug)}">${name}</a>`;
  };

  const formatValue = (value) => {
    return pluralize(value, 'card');
  };

  return (
    <div className="align-items-center d-flex flex-column gap-xs">
      <DonutChart
        chartProps={{ animate: false }}
        data={props.data}
        interior={(data) => (
          <strong>
            <span style={{ color: props.color, fontSize: 150 * 0.25 }}>
              {sumBy(data, 'value')}
            </span>
          </strong>
        )}
        formatValueFn={formatValue}
        legendPosition="bottom"
        size={150}
      />

      <div>
        <small className="text-gray-light">
          Study
          {' '}
          <span
            dangerouslySetInnerHTML={{
              __html: toSentence(props.data.map((d) => d.slug), 'or', (slug) => deckSetLink(slug))
            }}
          />
          {' '}
          flashcards
        </small>
      </div>
    </div>
  );
};
export default FlashcardsMasteredChart;
