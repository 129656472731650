import * as React from 'react';
import { findIndex } from 'lodash';

import trackEvent from 'utilities/track_event';

import * as api from './api';
import { StudyScheduleDayData, StudyScheduleStore } from './types';

import ChangePlan from './change_plan';
import CurrentSchedule from './current_schedule';
import SelectSchedule from './select_schedule';
import IntroduceLSATStudySchedule from './introduce_lsat_study_schedule/template';
import SuggestedLessons from './suggested_lessons';
import IeltsFeatureList from './ielts_feature_list';

interface Props extends StudyScheduleStore {
  hidePlanOptions: () => void;
  setItemCompleted: (number, boolean) => void;
  setPlan: (StudyScheduleDetailData) => void;
  showPlanOptions: () => void;
  updateUserProfile: (any) => void;
  optOut: () => void;
  setSuggestedLessons: (any) => void;
}

export default class Template extends React.Component<Props> {
  handleChangePlan = (id: number) => {
    return api.setStudySchedule(id).then((plan) => {
      this.props.setPlan(plan);
      this.props.hidePlanOptions();
    });
  };

  handleReloadPlan = () => {
    return api.getStudySchedule(this.props.currentStudySchedule.id).then((resp) => {
      this.props.setPlan((resp as any).studySchedule);
    });
  };

  handleChangeDay = (dayId: number) => {
    const planId = this.props.currentStudySchedule.id;

    return api.setStudyScheduleDay(planId, dayId).then((plan) => {
      this.props.setPlan(plan);

      // Track in Mixpanel
      const currentDay = (plan as any).currentDay;
      const dayIndex = findIndex((plan as any).days, (day: StudyScheduleDayData) => {
        return day.id === dayId;
      });
      trackEvent('Study Schedule > Set Day', {
        study_schedule_day_id: currentDay.id,
        study_schedule_day_name: `Day ${dayIndex + 1}: ${currentDay.name}`
      });
    });
  };

  handleChangeUserProfile = (userProfile: any) => {
    return api.updateUserProfile(userProfile).then(() => {
      return this.props.updateUserProfile(userProfile);
    });
  };

  handleOptOut = () => {
    trackEvent('Study Schedule > Opt Out');
    return api.setNoStudySchedule()
      .then(() => api.getSuggestedLessons())
      .then((response) => this.props.setSuggestedLessons(response.suggestedLessons))
      .then(() => this.props.optOut())
      .then(() => this.props.hidePlanOptions());
  };

  render() {
    const {
      currentStudySchedule,
      hidePlanOptions,
      showPlanOptions,
      studySchedules,
      suggestedLessons,
      showExamSectionFocusStep,
      showStudyExperienceStep,
      ui: { presentPlanOptions },
      userProfile: {
        examName,
        studyExperience,
        testDate,
        examSectionFocus,
        hasCompletedSyllabusOnboarding,
        studySchedulesFeatureReleased,
        isPremium,
        upsellFeatures,
        includeAdmissionsItemsInStudySchedule
      }
    } = this.props;

    // Do not show StudySchedules to new GMAT users
    const showStudySchedules = (examName == 'GMAT') ? isPremium : true;

    if (studySchedulesFeatureReleased && studySchedules.length && presentPlanOptions) {
      return (
        <div className="bg-gray-lightest border-radius-top border-radius-bottom u-margin-B-l u-padding-A-m" id="changePlan">
          <ChangePlan
            currentPlan={currentStudySchedule}
            onCancel={hidePlanOptions}
            onOptOut={this.handleOptOut}
            onComplete={this.handleChangePlan}
            onReloadPlan={this.handleReloadPlan}
            onUpdateUserProfile={this.handleChangeUserProfile}
            plans={studySchedules}
            userProfile={this.props.userProfile}
          />
        </div>
      );
    } else if (studySchedulesFeatureReleased && currentStudySchedule) {
      return (
        <div className="bg-gray-lightest border-radius-top border-radius-bottom u-margin-B-l u-padding-A-m" id="currentPlan">
          <CurrentSchedule
            onChangePlan={studySchedules.length ? showPlanOptions : null}
            onSetCurrentDay={this.handleChangeDay}
            examName={examName}
            {...currentStudySchedule}
          />
        </div>
      );
    } else if ((!studySchedulesFeatureReleased || !showStudySchedules || hasCompletedSyllabusOnboarding) && (suggestedLessons.length || upsellFeatures)) {
      if (upsellFeatures) {
        return (
          <div className="bg-gray-lightest border-radius-top border-radius-bottom u-margin-B-l u-padding-A-m" id="ieltsFeatureList">
            <IeltsFeatureList examName={examName}/>
          </div>
        )
      }

      return (
        <div className="bg-gray-lightest border-radius-top border-radius-bottom u-margin-B-l u-padding-A-m" id="suggestedLessons">
          <SuggestedLessons
            suggestedLessons={suggestedLessons}
            onChangePlan={studySchedules.length && showStudySchedules ? showPlanOptions : null}
          />
        </div>
      );
    } else if (studySchedulesFeatureReleased && showStudySchedules) {
      if (examName === 'LSAT') {
        return (
          <IntroduceLSATStudySchedule
            examName={examName}
            onSkip={this.handleOptOut}
            schedules={studySchedules}
            onSelect={this.handleChangePlan}
          />
        );
      } else {
        return (
          <div id="selectPlan">
            <SelectSchedule
              examName={examName}
              onSelect={this.handleChangePlan}
              onSkip={this.handleOptOut}
              onUpdateUserProfile={this.handleChangeUserProfile}
              schedules={studySchedules}
              studyExperience={studyExperience}
              testDate={testDate}
              showExamSectionFocusStep={showExamSectionFocusStep}
              showStudyExperienceStep={showStudyExperienceStep}
              examSectionFocus={examSectionFocus}
            />
          </div>
        );
      }
    }
  }
}
