import React from 'react';
import cx from 'classnames';
import markdownit from 'markdown-it'

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import { Interaction } from '@test_prep/app/ai_tutor/types';

import ErrorMessage from '../error_message';

import style from './style.module.scss';
import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  interaction: Interaction;
  onClick: (interaction: Interaction) => void;
}

const PromptActivity: React.FC<Props> = (props) => {
  const statusText = () => {
    if (props.interaction.status !== 'finished') {
      return 'Generating';
    }

    const answer = props.interaction.activityContent.answer;

    if (!answer && props.interaction.isCurrentActivity) {
      return 'Answering';
    } else if (!answer) {
      return 'Unanswered';
    } else if (answer.isCorrect) {
      return 'Correct!';
    } else {
      return 'Incorrect';
    }
  };

  if (props.interaction.status === 'error') {
    return <ErrorMessage message="Oops! Something went wrong generating a similar question." />;
  } else {
    return (
      <div className="u-padding-A-s"
        style={{
          backgroundColor: props.interaction.isCurrentActivity ? colors.alertInfoBg : '#ffffff',
          borderRadius: 8,
          transition: 'background-color 0.2s ease-in'
        }}
      >
        <div className={style.textDivider}>
          <div
            className="align-items-center d-flex flex-column gap-xxs u-padding-H-s"
            onClick={(_) => props.onClick(props.interaction)}
            style={{
              cursor: 'pointer'
            }}
          >
            <div className="align-items-center d-flex gap-xs">
              {
                props.interaction.status === 'finished' ? (
                  <FontAwesomeIcon
                    icon="bolt"
                    style={{
                      background: '-webkit-linear-gradient(135deg, #26B67C, #9B51E0)',
                      fontSize: 20,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  />
                ) : (
                  <div className={cx(style.animatedEllipsis, 'u-padding-V-xs')}>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )
              }

              <div style={{ fontSize: 12 }}>
                <strong>{statusText()}</strong>
              </div>
            </div>

            <div>
              <strong>{props.interaction.name}</strong>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PromptActivity;
