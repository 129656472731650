import React, { useLayoutEffect, useRef, useState } from 'react';
import Diff from 'diff';
import { escapeRegExp, uniq } from 'lodash';

import { renderLatexInRawHtml} from 'utilities/latex';

interface Props {
  currentVersion: {
    content: string;
    name: string;
  };
  previousVersion: {
    content: string;
    name: string;
  };
  showDiff: boolean;
  showVersionNames: boolean;
  type: 'rendered' | 'text';
}

const DiffableContent: React.FC<Props> = (props) => {
  const [diff, setDiff] = useState(null);
  const latexSymbolMappings = useRef({});

  useLayoutEffect(() => {
    if (!props.showDiff) return;

    setDiff(runDiff());
  }, [props]);

  const content = (html) => {
    if (!html) return;

    html = contentWithLatexWhitespaceRemoved(html);

    // Much of the content we display has been prepared to present in a Bootstrap grid along with
    // other content. Because we are showing just the single piece of content in isolation,
    // remove the row/col classes.
    html = html.replace(/col-/g, '').replace(/row/g, '')

    if (props.type === 'rendered') {
      return html;
    } else {
      return contentWithLatexExtractedFromCodeTags(html);
    }
  };

  const contentDiff = (type: 'added' | 'removed') => {
    if (!diff) return;

    let typeOpposite = type === 'added' ? 'removed' : 'added';
    let html = '';

    const wrapContent = (content) => {
      const paragraphMatches = content.match(/<p>(.*)<\/p>/);

      if (paragraphMatches) {
        if (type === 'added') {
          return content.replace(/<p>(.*?)<\/p>/gs, '<p><ins style="background:#AFEFA1; text-decoration:none;">$1</ins></p>');
        } else {
          return content.replace(/<p>(.*?)<\/p>/gs, '<p><del style="background:#FFC7C7; text-decoration:none;">$1</del></p>');
        }
      } else if (type === 'added') {
        return `<ins style="background:#AFEFA1; text-decoration:none;">${content}</ins>`;
      } else {
        return `<del style="background:#FFC7C7; text-decoration:none;">${content}</del>`;
      }
    };

    diff.forEach((change) => {
      if (change[typeOpposite]) return;

      if (change[type]) {
        html = html + wrapContent(change.value);
      } else {
        html = html + change.value;
      }
    });

    if (props.type === 'rendered') {
      return renderLatexInRawHtml(html);
    } else {
      return html;
    }
  };

  const contentNoDiff = (html) => {
    const contentHtml = content(html);

    if (props.type === 'rendered') {
      return renderLatexInRawHtml(contentHtml);
    } else {
      return contentHtml;
    }
  };

  const contentWithLatexExtractedFromCodeTags = (html) => {
    html = html.replace(/<code class="redactor-katex" data-source="/g, '');
    html = html.replace(/"><\/code>/g, '');

    return html;
  };

  const contentWithLatexWhitespaceRemoved = (html) => {
    // Remove whitespace in LaTeX to maximize chance of matching the same formula between old and
    // new content.
    const latexMatches = html.match(
      /<code class="redactor-katex" data-source="(.+?)"><\/code>/gs
    ) || [];
    latexMatches.forEach((codeTag) => {
      const latex = codeTag.replace('<code class="redactor-katex" data-source="', '').replace('"><\/code>', '');
      html = html.replace(latex, latex.replace(/ /g, ''));
    });

    return html;
  };

  const runDiff = () => {
    // Step 1: Remove LaTeX whitespace to better match equal syntax
    let originalContent = content(props.previousVersion.content) || '';
    let newContent = content(props.currentVersion.content);

    // Step 2: Substitute placeholders for LaTeX <code> blocks
    // Substitute placeholders in for each instance of LaTex so they are treated as a single,
    // unique item for the diff. LaTeX in the content is replaced with _<char> then the unique
    // character is subbed back out for the original latex later. If this substitution is not done
    // we end up with broken HTML as the diff with have hanging < and > characters from the
    // LaTeX <code> tags.
    const substitutionSymbolMappings = {};

    // Substitutions must have no chance of diff overlap. For example, _1 and _11 could
    // potentially have a partial diff overlap. They also need to be considered "words" by the
    // differ. If we have more than 52 substitutions this will break.
    const substitutionSymbols = [
      '_A', '_B', '_C', '_D', '_E', '_F', '_G', '_H', '_I', '_J', '_K', '_L', '_M',
      '_N', '_O', '_P', '_Q', '_R', '_S', '_T', '_U', '_V', '_W', '_X', '_Y', '_Z',
      '_a', '_b', '_c', '_d', '_e', '_f', '_g', '_h', '_i', '_j', '_k', '_l', '_m',
      '_n', '_o', '_p', '_q', '_r', '_s', '_t', '_u', '_v', '_w', '_x', '_y', '_z'
    ];
    let currentSubstitutionSymbolIndex = 0;

    const latexMatches = (originalContent + newContent).match(
      /<code class="redactor-katex" data-source="(.+?)"><\/code>/gs
    ) || [];
    latexMatches.forEach((match) => {
      const escapedMatchRegExp = new RegExp(escapeRegExp(match), 'g');
      const symbol = substitutionSymbols[currentSubstitutionSymbolIndex];
      originalContent = originalContent.replace(escapedMatchRegExp, symbol);
      newContent = newContent.replace(escapedMatchRegExp, symbol);
      substitutionSymbolMappings[symbol] = match;
      currentSubstitutionSymbolIndex += 1;
    });

    // Step 3: Strip out all HTML tags except for <p>.
    // To do that, we first replace <p> tags with newlines, then strip all html, then sub
    // the <p> tags back in.
    originalContent = originalContent.replace(/<p>(.*?)<\/p>/gs, '$1\n');
    newContent = newContent.replace(/<p>(.*?)<\/p>/gs, '$1\n');

    var div = document.createElement('div');
    div.innerHTML = originalContent;
    originalContent = div.textContent || div.innerText || '';
    div.innerHTML = newContent;
    newContent = div.textContent || div.innerText || '';

    // Step 4: Substitute < and > chars so they can't be interpretted as a diff vs <p> tags
    substitutionSymbolMappings['_1'] = '<';
    substitutionSymbolMappings['_2'] = '>';
    originalContent = originalContent.replace(/</g, '_1').replace(/>/g, '_2');
    newContent = newContent.replace(/</g, '_1').replace(/>/g, '_2');

    originalContent = originalContent.replace(/(.*?)\n+/gs, '<p>$1</p>');
    newContent = newContent.replace(/(.*?)\n+/gs, '<p>$1</p>');

    // Step 5: Run the diff
    const contentDiff = Diff.diffWords(originalContent, newContent);

    // Step 6: Substitute the LaTeX and <, > chars back into the diff from our symbol mappings
    contentDiff.forEach((change) => {
      (change.value.match(/_[A-Za-z]{1}/g) || []).forEach((match) => {
        const originalValue = substitutionSymbolMappings[match];
        change.value = change.value.replace(match, originalValue);
      });
    });

    return contentDiff;
  };

  return (
    <div className="d-flex flex-column flex-lg-row gap-m">
      <div className="d-flex flex-1 flex-column gap-xs">
        {
          props.showVersionNames && (
            <div className="text-underline">{props.previousVersion.name}</div>
          )
        }

        <div
          dangerouslySetInnerHTML={{
            __html: props.showDiff ? contentDiff('removed') : contentNoDiff(props.previousVersion.content)
          }}
        />
      </div>

      <div className="d-flex flex-1 flex-column gap-xs">
        {
          props.showVersionNames && (
            <div className="text-underline">{props.currentVersion.name}</div>
          )
        }

        <div
          dangerouslySetInnerHTML={{
            __html: props.showDiff ? contentDiff('added') : contentNoDiff(props.currentVersion.content)
          }}
        />
      </div>
    </div>
  );
};

export default DiffableContent;
