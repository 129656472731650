import React from 'react';

import { ColorPalette } from 'types/constants';

interface Props {
  bordered?: boolean
  children: React.ReactNode;
  colorPalette: ColorPalette;
  onClick?: () => void;
  size: number;
  style?: any;
}

const CircleIcon = (props) => {
  const handleOnClick = () => {
    if (!props.onClick) return;

    props.onClick();
  };

  return (
    <div
      onClick={handleOnClick}
      style={{
        backgroundColor: props.colorPalette.light,
        border: 2,
        borderColor: props.bordered ? props.colorPalette.dark : props.colorPalette.light,
        borderStyle: 'solid',
        borderRadius: '50%',
        color: props.colorPalette.dark,
        height: props.size,
        lineHeight: `${props.size - 4}px`,
        textAlign: 'center',
        width: props.size,
        ...props.style
      }}
    >
      {
        typeof props.children === 'string' ? (
          <span dangerouslySetInnerHTML={{__html: props.children }} />
        ) : props.children
      }
    </div>
  );
};

export default CircleIcon;
