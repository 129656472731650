import * as React from 'react';
import { AnswerChoice, AnswerPart, PromptData } from './types';
import { flatten } from 'lodash';
import cx from 'classnames';
import style from './style.module.scss';

interface CheckProps {
  prompt: PromptData;
  review: boolean;
}

interface CheckState {
  numberChecked: number;
}

export default class CheckInput extends React.Component<CheckProps> {
  state = {
    selected: flatten(this.props.prompt.userAnswers.map((answer) => answer.parts)).map(
      (part) => part.answerChoiceId
    )
  };

  render() {
    const prompt = this.props.prompt;
    const answers = this.props.prompt.userAnswers;
    const answerParts = flatten(answers.map((answer) => answer.parts));

    return (
      <div className="answer_checkbox answer-inputs">
        <fieldset className="checkbox choices controls">
          {prompt.errors && (
            <p className={style.error}>
              {prompt.numberOfAnswers > 1
                ? `Please select ${prompt.numberOfAnswers} answers.`
                : 'Please select at least 1 answer.'}
            </p>
          )}
          {prompt.answerChoiceGroups.map((choiceGroup) => {
            return choiceGroup.answerChoices.map((choice) => {
              const answerPart = answerParts.find((part) => part.answerChoiceId === choice.id);

              return (
                <div className="checkbox" key={choice.id}>
                  <label className="choice answer" key={choice.id}>
                    {this.renderGrade(answerPart, this.props.review, choice.isCorrect)}
                    <input
                      type="checkbox"
                      name={`prompts[${prompt.id}][${choice.answerChoiceGroupId}][choice_id][]`}
                      value={choice.id}
                      defaultChecked={answerPart !== undefined}
                      disabled={this.shouldDisableCheckbox(choice)}
                      onChange={this.onClickChoice}
                    />
                    <span dangerouslySetInnerHTML={{ __html: choice.text }} />
                  </label>
                </div>
              );
            });
          })}
        </fieldset>
      </div>
    );
  }

  // Disable the checkbox if this is a review screen
  // or we have reached the answer limit and this check box is not checked
  shouldDisableCheckbox = (choice: AnswerChoice) => {
    if (this.props.review) {
      return true;
    }
    if (this.props.prompt.numberOfAnswers < 2) {
      return false;
    } else {
      return (
        this.state.selected.length >= this.props.prompt.numberOfAnswers &&
        this.state.selected.indexOf(choice.id) < 0
      );
    }
  };

  // Track the answers that have been chosen so we know whether to disable each checkbox
  onClickChoice = (event) => {
    const choiceId = parseInt(event.currentTarget.value);
    if (event.currentTarget.checked) {
      this.setState({ selected: this.state.selected.concat(choiceId) });
    } else {
      this.setState({ selected: this.state.selected.filter((choice) => choice !== choiceId) });
    }
  };

  // Render an Incorrect icon if this choice was selected and is incorrect.
  // Render a Correct icon if this choice is correct.
  // Return null if this answer is not being reviewed.
  renderGrade = (part: AnswerPart, review: boolean, isCorrect?: boolean) => {
    if (!review || (!part && !isCorrect)) {
      return null;
    }

    return <i className={cx('choice-grade', isCorrect ? 'correct' : 'incorrect')}></i>;
  };
}
