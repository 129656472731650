import React from 'react';
import {
  SectionedAssignmentsDashboardCardProps,
  Assignment
} from './types';
import CheckpointCard from '@magoosh/gre/organisms/checkpoint_card';
import moment from 'moment-timezone';
import style from './style.module.scss';
import { paths } from 'config/path_helpers';
import Desk from '@magoosh/assets/desk.svg';
import StatusBadge from '@magoosh/pages/sectioned_assignments/organisms/status_badge';

const SectionedAssignmentsDashboardCard: React.FC<SectionedAssignmentsDashboardCardProps> = (props) => {

  const { assignments } = props;
  const timeZone = moment.tz.guess();

  const headline = () => {
    if (props.onDemand && assignments.length > 0) {
      return 'You have assignments to complete!';
    }

    if (assignments.some(assignment => {
      return moment(assignment.dueDate).tz(timeZone).isBefore(moment());
    })) {
      return 'You have overdue assignments!';
    }

    const oneWeekInFuture = moment().add(1, 'week');

    if (assignments.some(assignment => {
      return moment(assignment.dueDate).tz(timeZone).isBefore(oneWeekInFuture);
    })) {
      return 'You have assignments due soon!';
    }

    if (assignments.length <= 0)
    {
      return 'You’re all caught up on assignments!';
    }

    return 'You’re all caught up on assignments! Here’s a peek at what’s coming up next.';
  };

  return <CheckpointCard>
    <div className={style.assignmentsCard}>
      <div>
        <div>
          <img alt="Desk" src={Desk} />
        </div>
        <div className={style.title}>Assignments</div>
        <div>
          <small>
            <p>
                You’ll see reminders about your upcoming assignments here.
            </p>
            <p>
              <a href={paths.assignments()} className={'text-no-decoration'}>See all Assignments</a>
            </p>
          </small>
        </div>
      </div>
      <div>
        <p className={style.headline}>{headline()}</p>
        <p>
          Plan time to complete your assignments each week to make sure Magoosh is helping you get
          the most points possible.
        </p>
        {props.assignments.length <= 0 &&
          <p>
            <a href={paths.assignments()}>Review or retake past assignments</a>
          </p>
        }
        <div>
          {assignments.map((assignment: Assignment) => {
            return <div key={assignment.title} className={style.assignment}>
              <div>
                <a href={paths.contentBlockAssignment(assignment)}>{assignment.title}</a>
                {assignment.dueDate && <>
                  , {moment(assignment.dueDate).tz(timeZone).isBefore() && 'was'} due on {moment(assignment.dueDate).tz(timeZone).format('MMMM D')}
                </>}
              </div>
              <div>
                <StatusBadge submitted={false} dueDate={assignment.dueDate} />
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  </CheckpointCard>
}

export default SectionedAssignmentsDashboardCard;
