import style from "./style.module.scss";
import {paths} from "config/path_helpers";
import * as React from "react";
import {ExamSectionData} from "types/exam_section";
import {SubjectBlockData} from "types/content_blocks";

interface ContentBlockBreadCrumbsProps {
  contentBlock: SubjectBlockData;
  examSection: ExamSectionData;
}

export default class ContentBlockBreadCrumbs extends React.Component<ContentBlockBreadCrumbsProps> {

  render() {
    const { contentBlock, examSection } = this.props;

    return (
      <div className={style.breadcrumbs}>
        <a href={paths.subjectDashboard(examSection.slug)}>
          {examSection.branding.displayName} {examSection.displayName}
        </a> {`>`} <strong>{contentBlock.name}</strong>
      </div>
    )
  }
}
