import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { studyPreferencesReducer, StudyPreferencesStore } from '@magoosh/high_school/pages/calypso/reducers';
import { teacherDashboardReducer } from '@magoosh/b2b/pages/teacher_dashboard/reducers/teacher_dashboard_reducers';
import { TeacherDashboardData } from '@magoosh/b2b/pages/teacher_dashboard/types';
import { reducer as formReducer } from 'redux-form';
import { assignmentsReducer } from '@magoosh/high_school/pages/assignments/reducers';
import { AssignmentsStore } from '@magoosh/high_school/pages/assignments/types';
import { studentPerformanceReducer } from '@magoosh/b2b/pages/school_report/student_performance/reducers';
import { StudentPerformanceStore } from '@magoosh/b2b/pages/school_report/types';
import { studyScheduleReducer } from '@magoosh/organisms/study_schedule/reducers';
import { StudyScheduleStore } from '@magoosh/organisms/study_schedule/types';
import { LessonModuleData } from '@magoosh/pages/lesson_modules/types';
import { lessonModuleReducer } from '@magoosh/pages/lesson_modules/reducers';

/**
 * Use to create a redux store
 * Does not accept initial state but that can be added when necessary
 */
export default function configureStore() {
  let devtools: any =
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    typeof (window as any).__REDUX_DEVTOOLS_EXTENSION__ === 'function' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__();

  if (!devtools) {
    devtools = (f: any) => f;
  }

  return createStore(
    combineReducers({
      assignments: assignmentsReducer,
      lessonModuleData: lessonModuleReducer,
      studentPerformance: studentPerformanceReducer,
      studyPreferences: studyPreferencesReducer,
      studySchedule: studyScheduleReducer,
      teacherDashboard: teacherDashboardReducer,
      form: formReducer
    }),
    compose(applyMiddleware(thunk), devtools)
  );
}

export interface RootStore {
  assignments: AssignmentsStore;
  studentPerformance: StudentPerformanceStore;
  studyPreferences: StudyPreferencesStore;
  studySchedule: StudyScheduleStore;
  teacherDashboard: TeacherDashboardData;
  lessonModuleData: LessonModuleData;
  form: any;
}
