import React from 'react';
import cx from 'classnames';
import { compact, find, flatten } from 'lodash';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import { formatCustomDateRange } from './custom_date_range';
import { FilterConfig } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  filters: { [key: string]: string };
  filtersConfig: FilterConfig[];
  focused?: boolean;
  onShowCollapsed: () => void;
}

const ActiveFilters: React.FC<Props> = (props) => {
  const activeFilters = compact(flatten(props.filtersConfig.map((filterConfig) => {
    if (props.focused && !filterConfig.allowWhileFocused) return;

    if (filterConfig.multiple) {
      if (props.filters[filterConfig.key] === 'all') return 'All';

      const selectedOptions = filterConfig.options.filter((option) => (
        (props.filters[filterConfig.key] || '').split(',').indexOf(option.value) !== -1
      ));
      return selectedOptions.map((o) => o.name);
    } else {
      const filterValue = props.filters[filterConfig.key];

      if (filterConfig.key === 'period' && filterValue.startsWith('custom')) {
        return formatCustomDateRange(filterValue).replace('Custom: ', '');
      } else {
        const selectedOption = find(filterConfig.options, (option) => (
          option.value === filterValue
        ));
        return selectedOption?.name;
      }
    }
  })));

  return (
    <div className="d-flex flex-wrap" style={{ gap: 5 }}>
      {
        activeFilters.map((filterName, idx) => (
          <div
            key={idx}
            className={cx(style.filterButton, 'align-items-center d-flex small text-white')}
            style={{
              backgroundColor: colors.blue,
              borderRadius: 4,
              gap: 5
            }}
          >
            <FontAwesomeIcon
              className="hidden-sm hidden-xs"
              icon="check"
              style={{ WebkitTextStroke: `1px ${colors.blue}`, width: 14 }}
            />

            {filterName}
          </div>
        ))
      }

      <div
        className="align-self-start"
        onClick={props.onShowCollapsed}
        style={{
          backgroundColor: colors.blueLight,
          borderRadius: 4,
          color: colors.blue,
          cursor: 'pointer',
          padding: '3px 10px'
        }}
      >
        <FontAwesomeIcon icon="filter" />
      </div>
    </div>
  );
};

export default ActiveFilters;
