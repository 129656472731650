import * as React from 'react';
import { Fragment } from 'react';
import * as moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import style from '../style.module.scss';
import { Col, Grid, Row } from 'react-bootstrap';
import { AnswerCount, ExamSection } from '../types';

moment.locale('en');
momentLocalizer(moment);

interface PerformanceDataProps {
  answerCountsByExamSectionId?: NumberHash<AnswerCount>;
  averageLessonsSeenByExamSectionId?: NumberHash<number>;
  averageTimesSpentByExamSectionId?: NumberHash<number>;
  examSections?: ExamSection[];
  loadingMode?: boolean;
  studentCount: number;
}

export default class PerformanceData extends React.Component<PerformanceDataProps> {
  render() {
    return (
      <Grid className={this.props.loadingMode ? style.performanceDataLoading : null}>
        <Row className={style.headerRow}>
          <Col md={2}></Col>
          <Col md={2}>Correct Answers</Col>
          <Col md={3}>Avg Questions Answered</Col>
          <Col md={2}>Avg Lessons Seen</Col>
          <Col md={2}>Avg Time Spent</Col>
        </Row>
        {this.renderPerformanceData()}
      </Grid>
    );
  }

  renderPerformanceData() {
    return (
      <Fragment>
        {this.props.examSections.map((examSection) => {
          let examSectionDisplayName = examSection.displayName;

          if (examSection.displayName === 'ACT' || examSection.displayName === 'SAT') {
            examSectionDisplayName = `General ${examSectionDisplayName}`;
          }

          return (
            <Row className={style.reportRow} key={examSection.id}>
              <Col md={2}>{examSectionDisplayName}</Col>
              {this.renderCorrectAnswers(examSection)}
              <Col md={2}>{this.props.averageLessonsSeenByExamSectionId[examSection.id] || 0}</Col>
              <Col md={2}>
                {this.renderTimeSpent(this.props.averageTimesSpentByExamSectionId[examSection.id])}
              </Col>
            </Row>
          );
        })}
      </Fragment>
    );
  }

  renderCorrectAnswers(examSection) {
    const { answerCountsByExamSectionId, studentCount } = this.props;
    const answerCounts = answerCountsByExamSectionId[examSection.id];
    const notApplicable = (
      <Fragment>
        <Col md={1}>--</Col>
        <Col md={1}>--</Col>
        <Col md={3}>0</Col>
      </Fragment>
    );

    if (!answerCounts) {
      return notApplicable;
    }

    const correctAnswers = answerCounts.correct || 0;
    const incorrectAnswers = answerCounts.incorrect || 0;
    const totalAnswers = correctAnswers + incorrectAnswers;

    if (!(totalAnswers > 0)) {
      return notApplicable;
    }

    const percent = Math.round((correctAnswers / totalAnswers) * 100);
    const averageAnswered = Math.round(totalAnswers / studentCount);

    return (
      <Fragment>
        <Col md={1}>{percent}%</Col>
        <Col md={1}>
          ({correctAnswers}/{totalAnswers})
        </Col>
        <Col md={3}>{averageAnswered}</Col>
      </Fragment>
    );
  }

  renderTimeSpent(timeSpent) {
    const duration = moment.duration(timeSpent, 'minutes');
    let formattedSpan = '';

    if (duration.asHours() > 1) {
      formattedSpan += Math.floor(duration.hours()) + ' hour';
      if (duration.hours() > 1) {
        formattedSpan += 's';
      }
      formattedSpan += ', ';
    }

    formattedSpan += duration.minutes() + ' minute';
    if (duration.minutes() !== 1) {
      formattedSpan += 's';
    }
    return formattedSpan;
  }
}
