import * as React from 'react';
import { Subscription } from './types';

import style from './style.module.scss';
import { Col, Row } from 'react-bootstrap';
import { capitalize } from 'lodash';

export class StudentPerformanceSummary extends React.Component<Subscription> {

  render() {
    const subscription = this.props;
    const performanceSummary = subscription.performanceSummary;
    const performanceSummaryTotal = performanceSummary.total || performanceSummary.Total || null;
    let totalScore = 'N/A';

    if (performanceSummaryTotal != null) {
      totalScore = performanceSummaryTotal.scoreLow + ' - ' + performanceSummaryTotal.scoreHigh;
    }

    return (
      <div>
        <Row>
          <Col md={12} className={style.sectionHeader}>
            Performance Summary
          </Col>
        </Row>
        <Row className={style.headerRow}>
          <Col md={2}>&nbsp;</Col>
          <Col md={2}>Total</Col>
          {Object.keys(performanceSummary).map(examSectionName => {
            if (examSectionName.toLowerCase() === 'total') {
              return;
            }
            return (<Col key={examSectionName} md={2}>{capitalize(examSectionName)}</Col>)
          })}
        </Row>
        <Row className={style.performanceSummary}>
          <Col md={2}>
            Estimated Score
          </Col>
          <Col md={2}>
            <div className={style.scoreRange}>
              {totalScore}
            </div>
          </Col>
          {Object.keys(performanceSummary).map(examSectionName => {
            if (examSectionName.toLowerCase() === 'total') {
              return;
            }

            const examSectionSummary = performanceSummary[examSectionName];
            let content;

            if (examSectionSummary != null &&
              examSectionSummary.scoreLow != null &&
              examSectionSummary.scoreHigh != null) {
              content = (
                <div className={style.scoreRange}>
                  {examSectionSummary.scoreLow} - {examSectionSummary.scoreHigh}
                </div>
              );
            } else {
              content = (
                <div className={style.notEnoughData}>
                  Not enough data
                </div>
              );
            }

            return (
              <Col md={2} key={examSectionName}>
                {content}
              </Col>
            );
          }
          )}
        </Row>
      </div>
    )
  }
}
