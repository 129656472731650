import * as React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import style from './style.module.scss';
import { Subscription } from './types';
import { StudentPerformanceSummary } from './performance_summary'
import { StudentPracticeScores } from './practice_scores'
import { StudentStrengthWeaknesses } from './strength_weaknesses'
import { StudentUsageSummary } from './usage_summary'

export const StudentReport: React.FC<{ subscriptions: StringHash<Subscription> }> = (props) => {
  let selectedExam;
  Object.keys(props.subscriptions).forEach((name) => {
    if (props.subscriptions[name].isCurrentBranding) {
      selectedExam = name;
    }
  });

  return (
    <Tabs id='student-report-subscription-tabs' bsStyle='pills' className={style.brandingTabs} defaultActiveKey={selectedExam}>
      {Object.keys(props.subscriptions).map((name, index) => {
        const subscription = props.subscriptions[name];
        const displayName = subscription.displayName;
        let performanceSummary = null;

        if (['GMAT', 'IELTS', 'TOEFL'].indexOf(displayName) === -1) {
          performanceSummary = (<StudentPerformanceSummary {...subscription} />);
        }

        return (
          <Tab
            eventKey={name}
            className={style.brandingTab}
            title={displayName}
            key={displayName}
          >
            {performanceSummary}
            <StudentPracticeScores {...subscription} />
            <StudentStrengthWeaknesses {...subscription} />
            <StudentUsageSummary {...subscription} />
          </Tab>
        )
      })}
    </Tabs>
  )
}
