import * as React from 'react';
import BaseLabel, { LabelProps } from "./base";
import type { LabelType } from './base';
export default BaseLabel
export { LabelType }

export const AlertLabel = (props: LabelProps) => {
  return <BaseLabel {...props} type='alert'/>
};
export const DangerLabel = (props: LabelProps) => {
  return <BaseLabel {...props} type='danger'/>
};
export const DefaultLabel = (props: LabelProps) => {
  return <BaseLabel {...props} type='default'/>
};
export const SuccessLabel = (props: LabelProps) => {
  return <BaseLabel {...props} type='success'/>
};
