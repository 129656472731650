import { StudyItemData } from '../study_items/types';

export interface UserProfile {
  examName?: string;
  examSectionFocus?: ExamSectionFocus;
  hasCompletedSyllabusOnboarding?: boolean;
  includeAdmissionsItemsInStudySchedule?: boolean;
  isPremium?: boolean;
  studyExperience?: string;
  studySchedulesFeatureReleased?: boolean;
  testDate?: string;
  upsellFeatures?: boolean;
};

export interface StudyScheduleStore {
  currentStudySchedule: StudyScheduleDetailData;
  studySchedules: StudyScheduleData[];
  suggestedLessons: SuggestedLessonsSectionData[];
  showExamSectionFocusStep: boolean;
  showStudyExperienceStep: boolean;
  ui: {
    presentPlanOptions?: boolean;
  };
  userProfile: UserProfile;
}

export interface StudyScheduleData {
  accessLevel: number;
  experienceLevels: string;
  id: number;
  minDaysUntilTest: number;
  name: string;
  examSectionFocus: ExamSectionFocus;
  description?: string;
}

export interface StudyScheduleDetailData {
  currentDay: StudyScheduleDayDetailData;
  description?: string;
  days: StudyScheduleDayData[];
  id: number;
  name: string;
  learningSessionsPerSet: number;
  learningSessionSetName: string;
  learningSessionName: string;
}

export interface StudyScheduleDayData {
  id: number;
  name: string;
}

export interface StudyScheduleDayDetailData {
  id: number;
  items: StudyItemData[];
  name: string;
}

export interface PracticePreviewData {
  blockId: number;
  estimatedTimeMinutes: number;
  exam: string;
  examSection?: string;
  includeOldPrompts: boolean;
  isTestLike: boolean;
  questionCount: number;
  questionType: string;
  quizMode: boolean;
  subjectTags: string[];
  timeLimitSeconds: number;
}

export enum ExamSectionFocus {
  MathAndVerbal = 'math_and_verbal',
  Math = 'math',
  Verbal = 'verbal'
}

export interface SuggestedLessonsSectionData {
  section: {
    id: number;
    name: string;
    host?: string;
  }
  lessons: Lesson[]
}

export interface Lesson {
  id: number;
  accessLevel: number;
  lengthSeconds: number;
  slug: string;
  title: string;
  viewed: boolean;
  markedFinished: boolean;
}
