import React from 'react';
import { Form as FormikForm, useFormikContext } from 'formik';

import { DangerAlert } from '@magoosh/lib/alerts';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  className?: string;
}

const Form: React.FC<Props> = (props) => {
  const { errors, isValid } = useFormikContext();

  return (
    <FormikForm
      className={props.className}
      {...props}
    >
      {
        !isValid  && (
          <DangerAlert className="form-errors small u-margin-V-s">
            {(errors as any)._error || <><strong>Oops!</strong> We caught something that needs your attention.</>}
          </DangerAlert>
        )
      }

      {props.children}
    </FormikForm>
  );
};

export default Form;
