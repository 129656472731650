import * as React from 'react';
import cx from 'classnames';

import style from './progress_bar.module.scss';
import { Popover, OverlayTrigger } from 'react-bootstrap';

type ProgressBarSizes = 'sm' | 'md' | 'lg';
export interface ProgressBarProps {
  backgroundColor: string;
  color: string;
  minLabel?: string | React.ReactNode;
  minLabelStyle?: React.CSSProperties;
  maxLabel?: string | React.ReactNode;
  maxLabelStyle?: React.CSSProperties;
  percentage: number;
  size: ProgressBarSizes;
  popoverText?: string;
}

export default class ProgressBar extends React.PureComponent<ProgressBarProps, {}> {
  render() {
    const displayPercentage = Math.max(this.props.percentage, 5);

    const progressBar = (
      <div
        className={cx(style.bar, style[this.props.size])}
        style={{ backgroundColor: this.props.backgroundColor }}>
        <div
          className={style.progress}
          style={{ backgroundColor: this.props.color, width: displayPercentage + '%' }}>
          {
            this.props.minLabel &&
            <span className={style.minLabel} style={this.props.minLabelStyle}>{this.props.minLabel}</span>
           }
        </div>
        {
          this.props.maxLabel && this.props.percentage < 85 &&
          <span className={style.maxLabel} style={this.props.maxLabelStyle}>{this.props.maxLabel}</span>
        }
      </div>
    );

    if (this.props.popoverText) {
      return (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          rootClose={true}
          overlay={<Popover id="total-study-hours">{this.props.popoverText}</Popover>}>
          {progressBar}
        </OverlayTrigger>
      );
    } else {
      return progressBar;
    }
  }
}
