import { paths } from "config/path_helpers";
import fetch from 'utilities/fetch';

export enum StudentPerformanceActionTypes {
  RESET_FILTERS = 'STUDENT_PERFORMANCE/RESET_FILTERS',
  SET_CLASS_SECTIONS_BY_GRADE = 'STUDENT_PERFORMANCE/SET_CLASS_SECTIONS_BY_GRADE',
  SET_DATA = 'STUDENT_PERFORMANCE/SET_DATA',
  SET_FILTER = 'STUDENT_PERFORMANCE/SET_FILTER',
  SET_LOADING_MODE = 'STUDENT_PERFORMANCE/SET_LOADING_MODE'
}

export const getData = () => {
  return (dispatch, getState) => {
    dispatch(setLoadingMode(true));

    const { selectedClassSectionId, selectedGrade, startDate, endDate } = getState().studentPerformance;

    fetch(paths.api.schoolReportPerformanceSummary(selectedGrade, null, selectedClassSectionId, startDate, endDate))
      .then(response => {
        dispatch(setData(response));
        dispatch(setLoadingMode(false));
      });
  }
};

export const resetFilters = () => {
  return dispatch => {
    dispatch(setFilter({
      endDate: null,
      selectedClassSectionId: null,
      selectedGrade: null,
      startDate: null
    }));
    dispatch(getData());
  }
};

export const setData = (data) => {
  return {
    type: StudentPerformanceActionTypes.SET_DATA,
    data
  }
};

export const setFilter = (filter) => {
  return {
    type: StudentPerformanceActionTypes.SET_FILTER,
    filter
  }
};

export const setClassSectionsByGrade = (classSectionsByGrade) => {
  return {
    type: StudentPerformanceActionTypes.SET_CLASS_SECTIONS_BY_GRADE,
    classSectionsByGrade
  }
};

export const setLoadingMode = (loadingMode) => {
  return {
    type: StudentPerformanceActionTypes.SET_LOADING_MODE,
    loadingMode
  }
};

export const updateFilter = (filter) => {
  return dispatch => {
    dispatch(setFilter(filter));
    dispatch(getData());
  }
};
