import * as React from 'react';
import { Program } from '../types';
import { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import FieldMultiselect from '@magoosh/lib/formik_inputs/field_multiselect';
import { PrimaryButton } from '@magoosh/lib/button';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';

interface FormProps {
  program?: Program;
  brandingOptions: {
    name: string;
    id: number;
  }[];
  onSuccess?: () => void;
}

interface ProgramFormValues {
  aliases: string[];
  brandingIds: number[];
  name: string;
  shortName: string;
}

const AdminProgramForm: React.FC<FormProps> = (props) => {
  const newProgram: () => Program = () => {
    return {
      aliases: [],
      brandingIds: [],
      id: null,
      name: '',
      shortName: ''
    };
  };

  const [program, setProgram] = useState(props.program || newProgram);

  const initialValues: ProgramFormValues = {
    aliases: program.aliases,
    brandingIds: program.brandingIds,
    name: program.name,
    shortName: program.shortName
  };

  const onSubmit = (
    values: ProgramFormValues,
    { setSubmitting, setErrors }: FormikHelpers<ProgramFormValues>
  ) => {
    setSubmitting(true);
    const path = program.id ? adminPaths.api.admissions.program(program.id) : adminPaths.api.admissions.programs();
    const method = program.id ? 'PATCH' : 'POST';

    fetch(path, { method: method, body: JSON.stringify(values) })
      .then((response) => {
        props.onSuccess();
        setSubmitting(false);
      })
      .catch((error) => {
        setErrors(error);
        setSubmitting(false);
      });
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, errors, isSubmitting }) => (
          <Form>
            <div>
              <FieldInput name="name" type="input" />
              <FieldInput name="shortName" type="input" />
              <FieldMultiselect
                name="aliases"
                defaultValue={values.aliases}
                data={values.aliases}
                allowCreate={true}
              />
              <FieldMultiselect
                name="brandingIds"
                label="Brandings"
                defaultValue={values.brandingIds}
                data={props.brandingOptions}
                valueField="id"
                textField="name"
              />
              <PrimaryButton className="u-margin-T-m" type="submit" submitting={isSubmitting}>
                Submit
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdminProgramForm;
