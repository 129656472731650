import * as React from 'react';
import cx from 'classnames';
import style from '../style.module.scss';

interface Props {
  onAddEmails: (any) => void;
}

export default class ClipboardUpload extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        <label className='u-margin-T-xs'>
          Paste student email addresses into the text box below. Each email should be on its own line.
        </label>
        <textarea
          className={cx(style.textBox, 'u-margin-T-xs')}
          onChange={e => this.props.onAddEmails(e.target.value)}></textarea>
      </React.Fragment>
    );
  }
}
