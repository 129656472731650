import React from 'react';

import { Breadcrumb } from '@magoosh/layouts/types';

import style from './style.module.scss';

interface Props {
  breadcrumbs?: (Breadcrumb | React.ReactNode)[];
  linkComponent?: React.ComponentType<any>;
}

const Breadcrumbs: React.FC<Props> = (props) => {
  return (
    <div className={style.breadcrumbs}>
      {
        props.breadcrumbs.map((breadcrumb, idx) => {
          if (React.isValidElement(breadcrumb)) {
            return (
              <React.Fragment key={idx}>
                {idx !== 0 && <span className="u-margin-H-xxs">{'>'}</span>}
                {breadcrumb}
              </React.Fragment>
            );
          } else {
            const breadcrumbObj = breadcrumb as Breadcrumb;
            if (breadcrumbObj.path) {
              return (
                <React.Fragment key={idx}>
                  { idx !== 0 && <span className="u-margin-H-xxs">{'>'}</span>}

                  {
                    props.linkComponent ? (
                      <props.linkComponent href={breadcrumbObj.path}>
                        <strong>{breadcrumbObj.name}</strong>
                      </props.linkComponent>
                    ) : (
                      <a href={breadcrumbObj.path}>
                        <strong>{breadcrumbObj.name}</strong>
                      </a>
                    )
                  }

                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={idx}>
                  { idx !== 0 && <span className="u-margin-H-xxs">{'>'}</span>}
                  <span>{breadcrumbObj.name}</span>
                </React.Fragment>
              );
            }
          }
        })
      }
    </div>
  );
};

export default Breadcrumbs;
