const breakdownSections = [
  {
    name: 'General Chemistry',
    percentCorrect: 67
  },
  {
    name: 'Organic Chemistry',
    percentCorrect: 50
  },
  {
    name: 'Newtonian Dynamics',
    percentCorrect: 44
  },
  {
    name: 'Kinematics',
    percentCorrect: 28
  }
];

const scoreSections = [
  {
    name: 'Chemical',
    sampleScore: 128,
    scoreRange: 'Score range: 118-132'
  },
  {
    name: 'Bio',
    sampleScore: 126,
    scoreRange: 'Score range: 118-132'
  },
  {
    name: 'Psych',
    sampleScore: 129,
    scoreRange: 'Score range: 118-132'
  },
  {
    name: 'CARS',
    sampleScore: 127,
    scoreRange: 'Score range: 118-132'
  },
  {
    name: 'Total',
    sampleScore: 510,
    scoreRange: 'Score range: 472–528'
  }
];

export default {
  null: { breakdownSections, scoreSections }
};
