import React from 'react';

import { adminPaths, paths } from 'config/path_helpers';

import { PromptRequest } from './types';

interface Props {
  promptRequest: PromptRequest;
}

const PromptRequestDescription: React.FC<Props> = (props) => {
  if (props.promptRequest.type === 'PromptRequests::LessonDrills') {
    return (
      <div className="small text-gray-light">
        Creates drill questions for the {props.promptRequest.resource.name} lesson
        {' '}
        <a href={adminPaths.lesson(props.promptRequest.resource.id)}>
           Admin
        </a>
        {' | '}
        <a href={paths.lesson(props.promptRequest.resource.id)}>
          Product
        </a>
      </div>
    );
  } else {
    return null;
  }
};

export default PromptRequestDescription;
