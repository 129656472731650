const breakdownSections = [
  {
    name: 'Arithmetic and Fractions',
    percentCorrect: 67
  },
  {
    name: 'Powers and Roots',
    percentCorrect: 50
  },
  {
    name: 'Percents and Ratios',
    percentCorrect: 44
  },
  {
    name: 'Algebra',
    percentCorrect: 28
  },
  {
    name: 'Integer Properties',
    percentCorrect: 27
  },
  {
    name: 'Word Problems',
    percentCorrect: 22
  }
];

const scoreSections = [
  {
    name: 'English',
    sampleScore: 26,
    scoreRange: 'Score range: 1-36'
  },
  {
    name: 'Reading',
    sampleScore: 29,
    scoreRange: 'Score range: 1-36'
  },
  {
    name: 'Science',
    sampleScore: 24,
    scoreRange: 'Score range: 1-36'
  },
  {
    name: 'Math',
    sampleScore: 25,
    scoreRange: 'Score range: 1-36'
  },
  {
    name: 'Total',
    sampleScore: 26,
    scoreRange: 'Score range: 1-36'
  }
];

export default {
  null: { breakdownSections, scoreSections }
};
