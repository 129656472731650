import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

interface Props {
  actions: {
    onClick: () => void;
    title: string;
  }[];
  style?: any;
  title?: string;
}

const AdditionalActionsButton: React.FC<Props> = (props) => {
  return (
    <Dropdown pullRight id="additionActionsButton">
      <Dropdown.Toggle className="bg-gray-lightest u-border-A-s u-padding-A-xxxs" noCaret style={props.style}>
        <FontAwesomeIcon icon="ellipsis-h" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          props.title && (
            <h4 className="u-margin-T-n">
              <strong>{props.title}</strong>
            </h4>
          )
        }

        {
          props.actions.map((action, idx) => (
            <MenuItem key={idx} onClick={action.onClick}>
              {action.title}
            </MenuItem>
          ))
        }
      </Dropdown.Menu>
     </Dropdown>
  );
};

export default AdditionalActionsButton;
