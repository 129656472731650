import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function Pageview() {
  const { pathname } = useLocation();
  // The Rails app and Google Tag Manager handle initial setup of GA. Continue to use
  // it for the initial pageview and library setup and only record future SPA pageviews here.
  const [initialPageLoaded, setInitialPageLoaded] = useState(false);

  useEffect(() => {
    let observer;
    if (initialPageLoaded) {
      observer = new MutationObserver(() => {
        // Google Analytics
        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({ event: 'page_view' });
        }

        // Mixpanel
        if ((window as any).mixpanel) {
          const eventProperties = {
            host: window.location.hostname,
            path: window.location.pathname
          };
          (window as any).mixpanel.track('Page Viewed', eventProperties);
        }
      })

      observer.observe(document.querySelector('title'), { childList: true });
    }

    setInitialPageLoaded(true);
    return () => observer?.disconnect();
  }, [pathname]);

  return null;
};
