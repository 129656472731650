import React from 'react';
import { capitalize, find } from 'lodash';

import { SquareIcon } from '@magoosh/lib/icons';
import { titleize } from 'utilities/formatting';

import { SubjectData } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  data: SubjectData[];
  section: string;
}

const SubjectHeader: React.FC<Props> = (props) => {
  const iconForSection = (section) => {
    const colorPalette = { dark: colors.purple, light: colors.purpleLight };

    if (section === 'math') {
      return (
        <SquareIcon
          colorPalette={colorPalette}
          iconType="math_operations"
          size={48}
        />
      );
    } else if (section === 'verbal') {
      return (
        <SquareIcon
          colorPalette={colorPalette}
          iconType="notebook"
          size={48}
        />
      );
    } else {
      return (
        <SquareIcon
          colorPalette={colorPalette}
          iconContents={capitalize(section[0])}
          iconType="letter"
          size={48} />
      );
    }
  };

  const overallDataPoint = find(props.data, (datum) => datum.name === 'Overall');

  return (
    <div className="align-items-center d-flex gap-s">
      {iconForSection(props.section)}
      <h3 className="u-margin-A-n">{titleize(props.section)}</h3>

      {
        props.data && (
          <div style={{ fontSize: 18}}>
            ({overallDataPoint.answers.total ? `${overallDataPoint.answers.percentCorrect}% correct` : 'No activity'})
          </div>
      )
      }
    </div>
  );
};

export default SubjectHeader;
