import { LessonModuleData, Module, ModuleTrack } from './types';
import { Reducer } from 'redux';
import { ActionTypes } from './actions';
import { StudyItemData } from '@magoosh/organisms/study_items/types';

const initialState: LessonModuleData = {
  studyModuleTracks: []
};

export const lessonModuleReducer: Reducer<LessonModuleData> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INITIALIZE:
      return { ...state, ...action.data };
    case ActionTypes.SET_ITEM_COMPLETED:
      return {
        ...state,
        studyModuleTracks: state.studyModuleTracks.map((track: ModuleTrack) => {
          return {
            ...track,
            modules: track.modules.map((module: Module) => {
              return {
                ...module,
                studyItems: module.studyItems.map((studyItem: StudyItemData) => {
                  if (studyItem.id == action.id) {
                    return {
                      ...studyItem,
                      completed: action.completed
                    };
                  } else {
                    return {
                      ...studyItem
                    };
                  }
                })
              };
            })
          };
        })
      };
    default:
      return state;
  }
};
