import * as React from 'react';
import capitalize from 'react-bootstrap/lib/utils/capitalize';
import { ErrorMessage, Field } from 'formik';
import style from '@magoosh/lib/formik_inputs/field_input/style.module.scss';
import DropdownList from 'react-widgets/lib/DropdownList';
import fetch from 'utilities/fetch';
import debounce from 'lodash/debounce';

interface FieldDropdownListProps {
  name: string;
  id?: string;
  data?: any[];
  valueField?: string;
  textField?: string | ((dataItem?) => string);
  defaultValue: any;
  label?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  filter?: string | ((any) => boolean);
  groupBy?: string | ((any) => any);
  searchEndpoint?: (string) => string;
  allowCreate?: boolean;
}

export class FieldDropdownList extends React.Component<FieldDropdownListProps> {
  state = {
    data: this.props.data || []
  };
  render() {
    const props = this.props;

    return (
      <div className="u-margin-T-s">
        <label htmlFor={id(props)}>{capitalize(props.label || props.name)}</label>

        <Field name={this.props.name}>
          {({ field, form, meta }) => (
            <DropdownList
              {...props}
              data={props.searchEndpoint ? this.state.data : props.data} // To allow rewriting via props if search isn't needed
              value={field.value}
              onChange={(value) =>
                form.setFieldValue(props.name, props.valueField ? value[props.valueField] : value)
              }
              onCreate={
                this.props.allowCreate
                  ? (value) => {
                      this.setState({ data: [...this.state.data, value] }, () => {
                        form.setFieldValue(props.name, value);
                      });
                    }
                  : null
              }
              onSearch={
                props.searchEndpoint
                  ? debounce((value) => {
                      fetch(props.searchEndpoint(value), { method: 'GET' }).then((response) => {
                        const newData = [
                          this.state.data.find((d) => d[props.valueField] === field.value),
                          ...response
                        ].filter((d) => d);
                        this.setState({ data: newData });
                      });
                    }, 500)
                  : null
              }
            />
          )}
        </Field>
        <div className={style.errorMessage}>
          <ErrorMessage name={props.name} />
        </div>
      </div>
    );
  }
}

function id(props: FieldDropdownListProps) {
  return props.id || `${props.name}-input`;
}
