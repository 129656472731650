import React, { createRef, useLayoutEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { compact, find, isEqual, take, uniq, without } from 'lodash';

import { FontAwesomeIcon } from '@magoosh/lib/icons';

import { FilterConfig } from './types';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from './style.module.scss';

interface Props {
  collapseBy?: number;
  disabled: boolean;
  filterConfig: FilterConfig;
  onChange: (name: string, value: string) => void;
  onShowCollapsed?: () => void;
  value: string;
}

const MultipleSelectFilter: React.FC<Props> = (props) => {
  // Setup options with an 'all' option at the front if asked for
  let options = props.filterConfig.options;
  if (props.filterConfig.all) {
    options = [{ name: 'All', value: 'all' }, ...options];
  }
  const [collapsedCount, setCollapsedCount] = useState(0);
  const [pastCollapsedBy, setPastCollapsedBy] = useState(null);

  // Setup refs for each option so we can check element width later if we need to collapse
  const optionRefs = useRef([]);
  optionRefs.current = options.map((element, i) => optionRefs.current[i] ?? createRef());

  // If we need to collapse, iterate over the options refs and figure out how many need to collapse
  useLayoutEffect(() => {
    if (!props.collapseBy) return;
    if (pastCollapsedBy === props.collapseBy) return;

    let collapseAchieved = 0;
    let optionsToCollapseCount = 0;
    optionRefs.current.reverse().forEach((ref) => {
      // Hardcoding 40 + 10 gap as the width of the button to show collapsed
      if (collapseAchieved >= (props.collapseBy + 50)) return;
      if (!ref.current) return;

      optionsToCollapseCount += 1;
      collapseAchieved += ref.current.offsetWidth;
    })

    setCollapsedCount(optionsToCollapseCount);
    setPastCollapsedBy(props.collapseBy);
  }, [props.collapseBy])

  const handleFilterSelected = (selectedValue: string) => {
    if (selectedValue === 'all') return props.onChange(props.filterConfig.key, 'all');
    if (props.value === 'all') return props.onChange(props.filterConfig.key, selectedValue);

    const currentSelectedValues = props.value.split(',');
    props.onChange(props.filterConfig.key, uniq([...currentSelectedValues, selectedValue]).join(','));
  };

  const handleFilterUnselected = (unselectedValue: string) => {
    if (unselectedValue === 'all') return;
    if (props.value === unselectedValue) return props.onChange(props.filterConfig.key, 'all');

    const currentSelectedValues = props.value.split(',');
    props.onChange(props.filterConfig.key, without(currentSelectedValues, unselectedValue).join(','));
  };

  const isCollapsedItemSelected = () => {
    if (!collapsedCount) return false;

    const collapsedOptions = options.slice(options.length - collapsedCount);
    return !!find(collapsedOptions, (o) => props.value.split(',').indexOf(o.value) !== -1);
  };

  const renderDisabled = (name: string, value: string) => {
    return (
      <div
        className={cx(style.filterButton, 'align-items-center d-flex small')}
        style={{
          backgroundColor: colors.grayLighter,
          borderRadius: 4,
          color: colors.grayLight,
          whiteSpace: 'nowrap',
          gap: 5
        }}
      >
        <FontAwesomeIcon icon="circle-o" style={{ fontSize: 12, width: 14 }} />
        {name}
      </div>
    );
  };

  const renderSelected = (name: string, value: string) => {
    return (
     <div
        className={cx(style.filterButton, 'align-items-center d-flex small text-white')}
        onClick={() => handleFilterUnselected(value)}
        style={{
          backgroundColor: colors.blue,
          borderRadius: 4,
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          gap: 5
        }}
      >
        <FontAwesomeIcon icon="check" style={{ WebkitTextStroke: `1px ${colors.blue}`, width: 14 }} />
        {name}
      </div>
    );
  };

  const renderUnselected = (name: string, value: string) => {
    return (
      <div
        className={cx(style.filterButton, 'align-items-center d-flex small')}
        onClick={() => handleFilterSelected(value)}
        style={{
          backgroundColor: colors.blueLight,
          borderRadius: 4,
          color: colors.blue,
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          gap: 5
        }}
      >
        <FontAwesomeIcon icon="circle-o" style={{ fontSize: 12, width: 14 }} />
        {name}
      </div>
    );
  };

  return (
    <>
      {
        take(options, options.length - collapsedCount).map((option, idx) => {
          if (props.disabled) {
            return (
              <div key={idx} ref={optionRefs.current[idx]}>
                {renderDisabled(option.name, option.value)}
              </div>
            );
          } else if ((props.value || '').split(',').indexOf(option.value) !== -1) {
            return (
              <div key={idx} ref={optionRefs.current[idx]}>
                {renderSelected(option.name, option.value)}
              </div>
            );
          } else {
            return (
              <div key={idx} ref={optionRefs.current[idx]}>
                {renderUnselected(option.name, option.value)}
              </div>
            );
          }
        })
      }

      {
        !!collapsedCount && (
          <div
            className="align-self-start"
            onClick={props.onShowCollapsed}
            style={{
              backgroundColor: isCollapsedItemSelected() ? colors.blue : colors.blueLight,
              borderRadius: 4,
              color: isCollapsedItemSelected() ? colors.blueLight : colors.blue,
              cursor: 'pointer',
              fontSize: 18,
              padding: '5px 10px'
            }}
          >
            <FontAwesomeIcon icon="ellipsis-h" />
          </div>
        )
      }
    </>
  );
};

export default MultipleSelectFilter;
