import { paths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import { StudyScheduleDetailData } from './types';

export const getStudySchedule = (id: number) => {
  return fetch(paths.api.studySchedule(id));
};

export const recordContentBlockProgress = (id: number, isCompleted: boolean) => {
  return fetch(
    paths.api.contentBlockRecordProgress(id),
    {
      body: JSON.stringify({ contentBlockProgress: { state: isCompleted ? 'completed' : null } }),
      method: 'POST'
    }
  );
};

export const setNoStudySchedule = () => {
  const userProfile = {
    hasCompletedSyllabusOnboarding: true,
    studyScheduleContentBlockId: null
  } as any;

  return updateUserProfile(userProfile);
};

export const setStudySchedule = (id: number) => {
  const userProfile = {
    hasCompletedSyllabusOnboarding: true,
    studyScheduleContentBlockId: id
  } as any;
  
  return updateUserProfile(userProfile).then(() => {
    return getStudySchedule(id);
  }).then((resp) => {
    return (resp as any).studySchedule as StudyScheduleDetailData;
  });
};

export const setStudyScheduleDay = (planId: number, dayId: number) => {
  const userProfile = {
    studyScheduleDayContentBlockId: dayId
  } as any;
  
  return updateUserProfile(userProfile).then(() => {
    return getStudySchedule(planId);
  }).then((resp) => {
    return (resp as any).studySchedule as StudyScheduleDetailData;
  });
};

export const updateUserProfile = (userProfile) => {
  return fetch(
    paths.api.userProfile(),
    {
      body: JSON.stringify({ userProfile }),
      method: 'PUT'
    }
  ); 
};

export const getSuggestedLessons = () => {
  return fetch(paths.api.suggestedLessons());
}
