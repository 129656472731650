import * as React from 'react';
import StudyDayLabel from '../study_day_label';
import { WeekDayBooleans, WeekDayOptionalBooleans } from 'types/week_day_booleans';
import {weekDayIndex} from "utilities/date_functions";

export interface StudyWeekViewProps {
  scheduled: WeekDayBooleans;
  completed: WeekDayOptionalBooleans;
}

const CHECK_MARK = '✔︎';

export default class StudyWeekView extends React.Component<StudyWeekViewProps> {

  render() {
    const weekDay = weekDayIndex();

    return (
      <div>
        <StudyDayLabel
          backgroundColor={this.backgroundForDay('monday')}
          underline={weekDay === 1}>
          {this.props.completed.monday ? CHECK_MARK : 'M'}
        </StudyDayLabel>
        <StudyDayLabel
          backgroundColor={this.backgroundForDay('tuesday')}
          underline={weekDay === 2}>
          {this.props.completed.tuesday ? CHECK_MARK : 'Tu'}
        </StudyDayLabel>
        <StudyDayLabel
          backgroundColor={this.backgroundForDay('wednesday')}
          underline={weekDay === 3}>
          {this.props.completed.wednesday ? CHECK_MARK : 'W'}
        </StudyDayLabel>
        <StudyDayLabel
          backgroundColor={this.backgroundForDay('thursday')}
          underline={weekDay === 4}>
          {this.props.completed.thursday ? CHECK_MARK : 'Th'}
        </StudyDayLabel>
        <StudyDayLabel
          backgroundColor={this.backgroundForDay('friday')}
          underline={weekDay === 5}>
          {this.props.completed.friday ? CHECK_MARK : 'F'}
        </StudyDayLabel>
        <StudyDayLabel
          backgroundColor={this.backgroundForDay('saturday')}
          underline={weekDay === 6}>
          {this.props.completed.saturday ? CHECK_MARK : 'Sa'}
        </StudyDayLabel>
        <StudyDayLabel
          backgroundColor={this.backgroundForDay('sunday')}
          underline={weekDay === 0}>
          {this.props.completed.sunday ? CHECK_MARK : 'Su'}
        </StudyDayLabel>
      </div>
    );
  }

  backgroundForDay(day: string) {
    if (this.props.completed[day]) {
      return 'green';
    } else if (this.props.scheduled[day]) {
      return 'lightgreen';
    } else {
      return 'gray';
    }
  }
}
