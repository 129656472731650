import React, { useState, useEffect } from 'react';
import { Module } from '../types';
import style from './style.module.scss';
import cx from 'classnames';
import moment from 'moment';
import ProgressBar from '@magoosh/lib/progress_bar';
import { LinkButton } from '@magoosh/lib/button';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';
import { Collapse, Col, Clearfix } from 'react-bootstrap';
import { percent } from 'utilities/math';

interface ModuleGoalProps {
  moduleGoals: Module[];
  numCompletedModules: number;
  showPracticeTestLink?: boolean;
  practiceTestCompleted?: boolean;
  mobileFixedItems?: number;
  desktopFixedItems?: number;
  columns?: boolean;
  onModuleClick?: (examSection, moduleId) => void;
}

const progressBarPercentage = (props) => {
  if (props.moduleGoals.length == 0) {
    return 100;
  }
  return percent(props.numCompletedModules, props.moduleGoals.length);
};

const ModuleItem = (props) => (
  <Col sm={12} md={props.columns ? 6 : 12} className="u-margin-B-xs u-padding-H-n">
    <p className="u-margin-A-n h7">
      <small>
        <strong>{props.examSection.toUpperCase()}</strong>
      </small>
    </p>
    <LinkButton
      onClick={() => {
        props.onModuleClick
          ? props.onModuleClick(props.examSection, props.id)
          : window.location.assign(paths.lessons(props.examSection, props.id));
      }}>
      {props.completed && <FontAwesomeIcon icon={'check'} />}
      {props.name}
    </LinkButton>
  </Col>
);

export const ModuleGoals = (props: ModuleGoalProps) => {
  const calculateFixedItems = () => {
    if (window.innerWidth <= 992) {
      return props.mobileFixedItems || 3;
    } else if (window.innerWidth > 992) {
      return props.desktopFixedItems || props.mobileFixedItems || 5;
    }
  };

  const [expandedModules, setExpandedModules] = useState(false);
  const [fixedItems, setFixedItems] = useState(3);

  useEffect(() => {
    setFixedItems(calculateFixedItems);
    window.addEventListener('resize', () => {
      setFixedItems(calculateFixedItems);
    });
  }, []);

  return (
    <div className={cx(style.moduleGoals, 'u-padding-A-l u-margin-B-l')}>
      <p>
        {moment().day(1).format('MMM DD')} - {moment().day(7).format('MMM DD')}
      </p>
      <h3 className="u-margin-T-xs u-margin-B-m">
        <strong>Goals for the Week</strong>
      </h3>
      <span>
        <strong>{`Complete ${props.moduleGoals.length} modules`}</strong>
      </span>
      <hr className="u-margin-V-xs" />
      <ProgressBar
        backgroundColor="#cef0e3"
        color="#26B67C"
        percentage={progressBarPercentage(props)}
        size="md"
        maxLabel={props.moduleGoals.length.toString()}
      />

      <div className="u-margin-T-m u-margin-B-l">
        <div>
          {props.moduleGoals.slice(0, fixedItems).map((module, index) => (
            <div key={module.name}>
              <ModuleItem {...module} columns={props.columns} onModuleClick={props.onModuleClick} />
              {index % 2 !== 0 && props.columns && <Clearfix visibleMdBlock />}
            </div>
          ))}
        </div>
        <Collapse in={expandedModules}>
          <div>
            {props.moduleGoals.slice(fixedItems).map((module, index) => (
              <div key={module.name}>
                <ModuleItem {...module} columns={props.columns} onModuleClick={props.onModuleClick} />
                {index % 2 !== 0 && props.columns && <Clearfix visibleMdBlock />}
              </div>
            ))}
          </div>
        </Collapse>
        <>
          <Clearfix />
          {props.moduleGoals.length > (props.desktopFixedItems || props.mobileFixedItems || 5) && (
            <LinkButton
              className={style.btnGrayLink}
              onClick={() => {
                setExpandedModules(!expandedModules);
              }}>
              <small>{expandedModules ? 'show less' : `show all ${props.moduleGoals.length} modules`}</small>
            </LinkButton>
          )}
        </>
      </div>

      {props.moduleGoals.length == 0 && (
        <div className="u-margin-B-l">Nice job, you've completed all the modules!</div>
      )}

      {props.showPracticeTestLink && (
        <div>
          <p className="u-margin-A-n">
            <strong>Take a practice test</strong>
          </p>
          <hr className="u-margin-V-xs" />
          <LinkButton
            onClick={() => {
              window.location.assign(paths.startPracticeTest());
            }}>
            {props.practiceTestCompleted && <FontAwesomeIcon icon={'check'} />}
            Start your practice test
          </LinkButton>
        </div>
      )}
    </div>
  );
};

export default ModuleGoals;
