import { isNil, mapKeys, omitBy } from 'lodash';
import moment from 'moment';

import { Assignment } from '@magoosh/high_school/pages/assignments/types';
import { buildDataTableQueryParams, DataTableQueryParameters } from 'utilities/data_table_queries';

export const paths = {
  api: {
    admissions: {
      applicationCycleChunks(cycle: string, startingAt: number, numberOfChunks: number, filters: any) {
        const filtersWithNullsRemoved = omitBy(filters, isNil);
        const filtersForUrl = mapKeys(filtersWithNullsRemoved, (value, key) => `filters[${key}]`);

        if (numberOfChunks) {
          const params = new URLSearchParams({
            ...filtersForUrl,
            num_chunks: numberOfChunks.toString(),
            starting_at: startingAt.toString()
          });
          return `/api/web/admissions/application_cycles/${cycle}/chunks?${params.toString()}`;
        } else {
          const params = new URLSearchParams({
            ...filtersForUrl,
            starting_at: startingAt.toString()
          });
          return `/api/web/admissions/application_cycles/${cycle}/chunks?${params.toString()}`;
        }
      },
      applicationCycleDeadline(cycle: string, deadlineId: number) {
        if (deadlineId) {
          return `/api/web/admissions/application_cycles/${cycle}/deadlines/${deadlineId}`;
        } else {
          return `/api/web/admissions/application_cycles/${cycle}/deadlines`;
        }
      },
      applicationCyclePlan(cycle: string) {
        return `/api/web/admissions/application_cycles/${cycle}/plan`;
      },
      applicationCyclePlanItem(cycle: string, itemId: number) {
        return `/api/web/admissions/application_cycles/${cycle}/items/${itemId}`;
      },
      applicationCyclePlanItemTask(cycle: string, itemId: number, taskId: number) {
        return `/api/web/admissions/application_cycles/${cycle}/items/${itemId}/tasks/${taskId}`;
      },
      applicationCycleSections(cycle: string) {
        return `/api/web/admissions/application_cycles/${cycle}/sections`;
      },
      highlightedContent() {
        return '/api/web/admissions/highlighted_content';
      },
      programs() {
        return '/api/web/admissions/programs';
      },
      programsSearch(searchQuery: string) {
        return `/api/web/admissions/programs/search?search_term=${encodeURIComponent(searchQuery)}`;
      },
      schoolProfile(slug: string, categorySlug: string, preview: boolean) {
        if (categorySlug) {
          return `/api/web/admissions/school_profiles/categories/${categorySlug}/profiles/${slug}?preview=${preview}`;
        } else {
          return `/api/web/admissions/school_profiles/profiles/${slug}?preview=${preview}`;
        }
      },
      schoolProfileCategory(slug: string, preview: boolean) {
        return `/api/web/admissions/school_profiles/categories/${slug}?preview=${preview}`;
      },
      schoolProfileMetric(slug: string, categorySlug: string) {
        return `/api/web/admissions/school_profiles/categories/${categorySlug}/metrics/${slug}`;
      }
    },
    partner: {
      root: '/api/web/partner',
      archiveAssignments(ids) {
        return `${paths.api.partner.root}/assignments/archive?ids=${ids}`;
      },
      archiveClasses(ids) {
        return `${paths.api.partner.root}/classes/archive?ids=${ids}`;
      },
      assignInstructors() {
        return `${this.instructors()}/assign`;
      },
      assignInstructorsForm() {
        return `${this.instructors()}/new`;
      },
      assignment(id) {
        return `${paths.api.partner.root}/assignments/${id}`;
      },
      assignmentStudents(id, options) {
        const paramsString = buildDataTableQueryParams(options);
        return `${paths.api.partner.root}/assignments/${id}/students${paramsString}`;
      },
      assignments(options?) {
        const paramsString = options ? buildDataTableQueryParams(options) : '';
        return `${paths.api.partner.root}/assignments${paramsString}`;
      },
      class(id) {
        return `${this.classes()}/${id}`;
      },
      classes(options?: DataTableQueryParameters) {
        const paramsString = options ? buildDataTableQueryParams(options) : '';
        return `${paths.api.partner.root}/classes${paramsString}`;
      },
      instructor(id) {
        return `${paths.api.partner.root}/instructors/${id}`;
      },
      instructors(options?: DataTableQueryParameters) {
        const paramsString = options ? buildDataTableQueryParams(options) : '';
        return `${paths.api.partner.root}/instructors${paramsString}`;
      },
      mockTests(options?: DataTableQueryParameters) {
        const paramsString = options ? buildDataTableQueryParams(options) : '';
        return `${paths.api.partner.root}/reporting/mock_tests${paramsString}`;
      },
      modules() {
        return `${paths.api.partner.root}/reporting/modules`;
      },
      modulesStudents(options?: DataTableQueryParameters) {
        const paramsString = options ? buildDataTableQueryParams(options) : '';
        return `${paths.api.partner.root}/reporting/modules_students${paramsString}`;
      },
      reenrollStudent(classSectionId, studentId) {
        return `${paths.api.partner.root}/class_enrollments/reenroll_student?class_section_id=${classSectionId}&student_id=${studentId}`;
      },
      reportingFilterOptions() {
        return `${paths.api.partner.root}/reporting/filter_options`;
      },
      school(id) {
        return `${paths.api.partner.root}/schools/${id}`;
      },
      schoolClassSectionsPerformance(id, options) {
        const paramsString = buildDataTableQueryParams(options);
        return `${paths.api.partner.root}/schools/${id}/class_sections_performance${paramsString}`;
      },
      schools(options?: DataTableQueryParameters) {
        const paramsString = options ? buildDataTableQueryParams(options) : '';
        return `${paths.api.partner.root}/schools${paramsString}`;
      },
      student(id) {
        return `${paths.api.partner.root}/students/${id}`;
      },
      studentAssignments(id, options) {
        const paramsString = buildDataTableQueryParams(options);
        return `${paths.api.partner.root}/students/${id}/assignments${paramsString}`;
      },
      students(options) {
        const paramsString = buildDataTableQueryParams(options);
        return `${paths.api.partner.root}/students${paramsString}`;
      },
      subjects(options?: DataTableQueryParameters) {
        const paramsString = options ? buildDataTableQueryParams(options) : '';
        return `${paths.api.partner.root}/reporting/subjects${paramsString}`;
      },
      syncClass(id) {
        return `${this.classes()}/${id}/sync`;
      },
      unenrollStudents(classSectionId, studentIds) {
        return `${paths.api.partner.root}/class_enrollments/unenroll_students?class_section_id=${classSectionId}&student_ids=${studentIds}`;
      },
      enrollStudentsForm() {
        return `${this.classEnrollments()}/new`;
      },
      enrollStudentsUploadTemplate() {
        return `${this.classEnrollments()}/template`;
      },
      classEnrollments() {
        return `${paths.api.partner.root}/class_enrollments`;
      },
      classForm() {
        return `${this.classes()}/new`;
      },
      assignmentForm() {
        return `${this.assignments()}/new`;
      },
      contentDetails(id) {
        return `${paths.api.partner.root}/content_details/${id}`;
      }
    },
    aiPromptAnswers() {
      return '/api/web/test_prep/ai_prompts/answers';
    },
    applicantProfileSearch() {
      return '/api/web/applicant_profiles/search';
    },
    assignment(id) {
      return `/api/web/assignments/${id}`;
    },
    assignmentOptions() {
      return '/api/web/assignments/options';
    },
    assignmentPreview(id) {
      return `/api/web/assignments/${id}/preview`;
    },
    changePaymentMethodPath(subscriptionId: number) {
      return `/api/web/subscriptions/${subscriptionId}/change_payment_method`;
    },
    checkShowRatingModal(contentBlockId: number) {
      return `/api/web/content_blocks/${contentBlockId}/check_show_modal`;
    },
    classCheckout(items = [], nonprofit = false) {
      let base = '/class/checkout';
      if (items.length) {
        base += '?';
        items.forEach((item, index) => {
          base += `${item.slug}=${item.purchasableCount}`;
          if (index < items.length - 1) {
            base += '&';
          }
        });

        if (nonprofit) {
          base += '&nonprofit=true';
        }
      }
      return base;
    },
    contentBlockRecordProgress(id: number) {
      return `/api/web/content_blocks/${id}/progress`;
    },
    dismissBanner(slug: string) {
      return `/api/web/banners/${slug}/dismiss`;
    },
    flagAnswer: '/flag.js',
    classSectionsProvision() {
      return `/api/web/class_sections/provision`;
    },
    gradeAssignment(userAssignmentId: number) {
      return `/api/web/assignments/grade/${userAssignmentId}`;
    },
    gradingRequestStatus(answerId: number) {
      return `/api/web/answers/${answerId}/grading_request_status`;
    },
    issueReports() {
      return '/api/web/issue_reports';
    },
    job(id: string) {
      return `/api/web/jobs/${id}`;
    } ,
    liveClassSessions(params?) {
      return params
        ? `/api/web/live_class_sessions/?${serializeUrlParams(params)}`
        : '/api/web/live_class_sessions/';
    },
    liveClassRegistrations() {
      return '/api/web/live_class_registrations';
    },
    notes(examSectionName: string) {
      return `/api/web/exam_sections/${examSectionName}/notes`;
    },
    orders() {
      return '/api/web/orders';
    },
    queueCartAbandonFollowup() {
      return '/api/web/subscriptions/queue_cart_abandon_followup';
    },
    rating(rateableId: number | string) {
      return `/api/web/ratings/${rateableId}`;
    },
    resetAssignmentSubmission(userAssignmentId: number) {
      return `/api/web/assignments/reset_submission/${userAssignmentId}`;
    },
    saveNewAssignment() {
      return '/api/web/assignments';
    },
    saveNote(notableId: number, notableType: string) {
      return `/api/web/${notableType}/${notableId}/note`;
    },
    schoolReportPerformanceSummary(grade, schoolId, classSectionId, startDate, endDate, searchTerm?) {
      const params = [];

      if (grade) {
        params.push(`grade=${grade}`);
      }

      if (schoolId) {
        params.push(`school_id=${schoolId}`);
      }

      if (classSectionId) {
        params.push(`class_section_id=${classSectionId}`);
      }

      if (startDate) {
        params.push(`start_date=${moment(startDate).format('L')}`);
      }

      if (endDate) {
        params.push(`end_date=${moment(endDate).format('L')}`);
      }

      if (searchTerm) {
        params.push(`search_term=${encodeURIComponent(searchTerm)}`);
      }

      return `/api/web/school_report/performance_summary?${params.join('&')}`;
    },
    setModuleTrack() {
      return '/api/web/lesson_modules/set_module_track';
    },
    statistic(slug, filters) {
      // TODO - This is repeated code from admissions chunks. Pull out to shared.
      const filtersWithNullsRemoved = omitBy(filters, isNil);
      const filtersForUrl = mapKeys(filtersWithNullsRemoved, (value, key) => `filters[${key}]`);

      const params = new URLSearchParams(filtersForUrl)
      return `/api/web/test_prep/statistics/${slug}?${params.toString()}`;
    },
    statisticRecentFilters() {
      return '/api/web/test_prep/statistics/recent_filters';
    },
    studentRow(page: number, startDate?: string, endDate?: string) {
      let url = `/api/web/class_sections?page=${page}`;
      if (startDate) {
        url += `&startDate=${startDate}`;
      }
      if (endDate) {
        url += `&endDate=${endDate}`;
      }

      return url;
    },
    studySchedule(id: number) {
      return `/api/web/study_schedules/${id}`;
    },
    studyScheduleCurrent() {
      return '/api/web/study_schedules/current';
    },
    studySchedulePractice(id: number) {
      return `/api/web/content_blocks/${id}/study_schedule_practice`;
    },
    submitAssignment(id) {
      return `${paths.api.assignment(id)}/submit`;
    },
    subscriptions() {
      return '/api/web/subscriptions';
    },
    subscriptionAddOnOffer(contentId?: number, contentType?: 'content_block') {
      if (contentId && contentType) {
        return `/api/web/subscriptions/add_on_offer?content_id=${contentId}&content_type=${contentType}`;
      } else {
        return '/api/web/subscriptions/add_on_offer';
      }
    },
    subscriptionPayRemainingBalance(subscriptionId: string | number) {
      return `/api/web/subscriptions/${subscriptionId}/pay_remaining_balance`;
    },
    subscriptionsPricing(subscriptionPlanId: number, couponCode: string, paymentMethod: string, zipCode: string) {
      const params = new URLSearchParams({
        coupon_code: couponCode,
        payment_method: paymentMethod,
        plan_id: subscriptionPlanId.toString(),
        zip_code: zipCode
      });
      return `/api/web/subscriptions/pricing?${params.toString()}`;
    },
    suggestedLessons() {
      return `/api/web/suggested_lessons`;
    },
    tutoringSessions() {
      return '/api/web/test_prep/tutoring_sessions';
    },
    tutoringSessionsAvailableSkills(path: string) {
      return `/api/web/test_prep/tutoring_sessions/available_skills?${serializeUrlParams({ path })}`;
    },
    tutoringSessionInteractions(id: number) {
      return `/api/web/test_prep/tutoring_sessions/${id}/interactions`;
    },
    tutoringSessionInteraction(tutoringSessionId: number, id: number) {
      return `/api/web/test_prep/tutoring_sessions/${tutoringSessionId}/interactions/${id}`;
    },
    tutoringSessionNextInteractions(tutoringSessionId: number, id: number) {
      return `/api/web/test_prep/tutoring_sessions/${tutoringSessionId}/interactions/${id}/next`;
    },
    userContentBlockProgressMarkComplete(contentBlockId: number) {
      return `/api/web/user_content_block_progresses/${contentBlockId}/mark_complete`;
    },
    userContentBlockProgressMarkIncomplete(contentBlockId: number) {
      return `/api/web/user_content_block_progresses/${contentBlockId}/mark_incomplete`;
    },
    users() {
      return '/api/web/admin/users';
    },
    userInfo() {
      return '/api/web/user_profiles/user_info';
    },
    userDemographics() {
      return 'api/web/user_demographics';
    },
    userProfile() {
      return '/api/web/user_profiles';
    },
    validateCoupon(id: number, code: string, installments: boolean) {
      return `/api/web/coupons/validate?subscription_plan_id=${id}&code=${code}&installments=${installments}`;
    },
    videoViews(id: number) {
      return `/api/web/videos/${id}/views`;
    },
    writtenMaterial(id: number) {
      return `/api/web/written_materials/${id}`;
    },
    writtenMaterialProgress(id: number) {
      return `/api/web/written_materials/${id}/progress`;
    },
    writingExperiences() {
      return '/api/web/writing_experiences';
    },
    writingExperienceClone(writtenMaterialId: number) {
      return `/api/web/writing_experiences/${writtenMaterialId}/clone`;
    }
  },
  admissions: {
    schoolProfile(slug: string, categorySlug: string, preview?: boolean) {
      const previewParams = preview ? '?preview=true' : '';

      if (categorySlug && !preview) {
        return `/schools/${categorySlug}/${slug}`;
      } else {
        return `/schools/${slug}${previewParams}`;
      }
    },
    schoolProfileCategory(categorySlug: string, preview?: boolean) {
      const previewParams = preview ? '?preview=true' : '';
      return `/schools/${categorySlug}${previewParams}`;
    },
    schoolProfileCategoryMetric(slug: string, categorySlug: string, preview?: boolean) {
      const previewParams = preview ? '?preview=true' : '';
      return `/schools/${categorySlug}/metrics/${slug}${previewParams}`;
    }
  },
  partner: {
    root: '/partner/portal',
    class(id) {
      return `${this.classes()}/${id}`;
    },
    classes() {
      return `${paths.partner.root}/classes`;
    }
  },
  analytics(filters?: { [key: string]: string }) {
    if (filters) {
      const filtersForUrl = mapKeys(filters, (value, key) => `filters[${key}]`);

      return `/analytics?${serializeUrlParams(filtersForUrl)}`;
    } else {
      return '/analytics';
    }
  },
  analyticsDetail(section: string, unit: string, filters?: { [key: string]: string }) {
    if (filters) {
      const filtersForUrl = mapKeys(filters, (value, key) => `filters[${key}]`);

      return `/analytics/${section}/${unit}?${serializeUrlParams(filtersForUrl)}`;
    } else {
      return `/analytics/${section}/${unit}`;
    }
  },
  answer(id: number, params?) {
    let path = `/answers/${id}`;

    if (params) {
      path = `${path}?${serializeUrlParams(params)}`;
    }

    return path;
  },
  answerRequestGrading(id: number) {
    return `/answers/${id}/request_grading`;
  },
   answerScoreReport(id: number) {
    return `/answers/${id}/score_report`;
  },
  answers(params?) {
    if (params) {
      return `/answers?${serializeUrlParams(params)}`;
    }
    return '/answers';
  },
  applicantProfile(id: number) {
    return `/applicant_profiles/${id}`;
  },
  assignments() {
    return '/assignments';
  },
  assignment(id) {
    return `/assignments/${id}`;
  },
  blog(brandingName: string) {
    return `https://www.magoosh.com/${brandingName}`;
  },
  classes() {
    return '/classes';
  },
  contentBlock(slugOrId: string | number, context: string) {
    return `/content/${slugOrId}/${context}`;
  },
  contentBlockAssignment(assignment) {
    // A safety check to deal with assignments that might not be associated to a valid content block.
    if (!assignment.contentBlock) {
      return '#';
    }

    return paths.contentBlock(assignment.contentBlock.slug, `assignment/${assignment.id}`);
  },
  contentBlockPractice(id: number, params: Record<string, string>) {
    return `/content/${id}/practice?${serializeUrlParams(params)}`;
  },
  dashboard(filter?: string) {
    if (filter) {
      return `/dashboard/${filter}`;
    } else {
      return '/dashboard';
    }
  },
  dashboardDailyQuestion() {
    return '/dashboard/daily_question';
  },
  diagnosticAnswers() {
    return '/diagnostic_answers';
  },
  diagnosticStart() {
    return '/diagnostic_start';
  },
  examSectionNotes(slug: string) {
    return `/notes/${slug}`;
  },
  examSectionPracticeSession(id: number) {
    return `/exam_sections/${id}/practice_sessions/start`;
  },
  examSectionReview(id: number, options: {}) {
    return `/answers?review[exam_section_ids][]=${id}&${serializeUrlParams(options)}`;
  },
  flashcards(type: string) {
    return `/flashcards/${type}/decks`;
  },
  guestAccess() {
    return '/guest_access';
  },
  lesson(slug: string | number, assignment?: Assignment) {
    return `/lessons/${slug}${assignment ? `?assignment_id=${assignment.id}` : ''}`;
  },
  lessons(selectedTab?: string, selectedModuleId?: number) {
    const operator = selectedTab ? '&' : '?';
    return `/lessons${selectedTab ? `?tab=${selectedTab}` : ''}${
      selectedModuleId ? `${operator}selected_module_id=${selectedModuleId}` : ''
    }`;
  },
  lessonSeries(slug: string) {
    return `/lesson_series/${slug}`;
  },
  lessonTour(slug: string, tourStep: number) {
    return `/lessons/${slug}?tour=${tourStep}`;
  },
  lessonMarking(id: number) {
    return `/lessons/${id}/marking`;
  },
  logout() {
    return '/logout';
  },
  onDemandClasses() {
    return '/on_demand_classes';
  },
  passwordEdit() {
    return '/password/edit';
  },
  profile() {
    return '/profile';
  },
  profileEdit() {
    return '/profile/edit';
  },
  prompt(id) {
    return `/questions/${id}`;
  },
  purchaseFeedback() {
    return '/purchase_feedback';
  },
  login(returnTo = false) {
    if (returnTo && typeof window != 'undefined') {
      return '/login?return_to=' + window.location.pathname;
    } else {
      return '/login';
    }
  },
  mcatLessonGroups(sectionSlug: string, subsectionSlug?: string) {
    return `/lessons?section=${sectionSlug}#${subsectionSlug || ''}`;
  },
  mcatUpgrade() {
    return '/mcat-upgrade';
  },
  officialQuestions() {
    return '/official_questions';
  },
  plans() {
    return '/plans';
  },
  practiceSession(id: number) {
    return `/practices/${id}`;
  },
  practiceTest(id: number) {
    return `/practice_tests/${id}`;
  },
  practiceTests() {
    return '/practice_tests';
  },
  practiceTestsFree() {
    return '/practice_tests/free';
  },
  practiceTestsQuickStart(templateId, sectionId = null) {
    let path = `/practice_tests/quick_start?mock_test[template_id]=${templateId}`;
    if (sectionId) {
      path += `&mock_test[mock_test_section_id]=${sectionId}`;
    }
    return path;
  },
  practiceTestStartSection(id: number) {
    return `/practice_tests/${id}/sections/start`;
  },
  practiceTestsShare(shareableId:  string) {
    return `/practice_tests/share/${shareableId}`;
  },
  practiceTestsStart() {
    return '/practice_tests/start';
  },
  privacy() {
    return '/privacy';
  },
  publicAnswer(promptId: number, answerId: number, shareableId: string) {
    return `/questions/${promptId}/a/${answerId}?shareable_id=${shareableId}`;
  },
  resources() {
    return '/resources';
  },
  sampleResponses(promptId: number) {
    return `/questions/${promptId}/sample_responses`;
  },
  schoolReport() {
    return '/classes/school-report';
  },
  startPractice(params?) {
    if (params) {
      return `/practices/start?${serializeUrlParams(params)}`;
    } else {
      return '/practices/start';
    }
  },
  startPracticeTest() {
    return '/practice_tests/start';
  },
  studentReport(id: number) {
    return `/students/${id}/report`;
  },
  subjectDashboard(slug: string) {
    return `/dashboard/${slug}`;
  },
  subscriptions(slug: string, coupon?: string, conversionUrl?: string) {
    let path = `/register/${slug}`;
    path = appendSearchParam(path, 'coupon', coupon);
    if (conversionUrl) {
      path = appendSearchParam(path, 'conversion_url', encodeURI(conversionUrl));
    }
    return path;
  },
  terms() {
    return '/terms';
  },
  tips() {
    return 'https://magoosh.com/gre/last-minute-gre-tips/';
  },
  topicOverview(section?: string) {
    if (section) {
      return `/topics/${section}`;
    } else {
      return '/topics';
    }
  },
  tourPrompt(id: number, tourStep: number) {
    return `/questions/${id}?tour=${tourStep}`;
  },
  videoViews(id: number) {
    return `/videos/${id}/views`;
  },
  vocabularyFlashcards: '/flashcards/vocabulary',
  welcome(paid?: string) {
    return `/welcome?${paid ? `paid=${paid}` : ''}`;
  },
  writtenMaterial(id: number) {
    return `/written_materials/${id}`;
  },
  writtenMaterialExport(id: number) {
    return `/written_materials/${id}/export`;
  },
  writingExample(id: number | string) {
    return `/writing_examples/${id}`;
  },
  writingExamples() {
    return '/writing_examples';
  },
  writingExperience(writtenMaterialId: number, step: number) {
    return `/writing_experiences/${writtenMaterialId}/step/${step}`;
  },
  writingExperiences() {
    return '/writing_experiences';
  }
};

export const adminPaths = {
  aiPrompt(id) {
    return `/admin/ai_prompts/${id}`;
  },
  aiPromptEdit(id) {
    return `/admin/ai_prompts/${id}/edit`;
  },
  aiPromptVersions(id, versionId = null) {
    if (versionId) {
      return `/admin/ai_prompts/${id}/versions/${versionId}`;
    } else {
       return `/admin/ai_prompts/${id}/versions`;
    }
  },
  answer(answerId: string | number) {
    return `/admin/answers/${answerId}`;
  },
  applicantProfiles() {
    return '/admin/applicant_profiles';
  },
  applicantProfile(id: number) {
    return `/admin/applicant_profiles/${id}`;
  },
  applicantProfileEdit(id: number) {
    return `/admin/applicant_profiles/${id}/edit`;
  },
  applicantProfileNew() {
    return '/admin/applicant_profiles/new';
  },
  applicantProfileRatings(id: number) {
    return `/admin/applicant_profiles/${id}/ratings`;
  },
  dashboard() {
    return '/admin';
  },
  contentBlocks: '/admin/content_blocks',
  editContentBlock(id: string | number) {
    return `${this.contentBlocks}/${id}/edit`;
  },
  gradingRequest(id: string | number) {
    return `/admin/grading_requests/${id}`;
  },
  newGradingRequest() {
    return '/admin/grading_requests/new';
  },
  promptEdit(id) {
    return `/admin/prompts/${id}/edit`;
  },
  promptRequests() {
    return '/admin/prompt_requests';
  },
  users() {
    return '/admin/users';
  },
  user(id: number) {
    return `/admin/users/${id}`;
  },
  lesson(id: string | number) {
    return `/admin/lessons/${id}`;
  },
  lessonEdit(id: string | number) {
    return `/admin/lessons/${id}/edit`;
  },
  liveClassCohorts() {
    return '/admin/live_class_cohorts';
  },
  liveClassSessions() {
    return '/admin/live_class_sessions';
  },
  writingExample(id: string | number) {
    return `/admin/writing_examples/${id}`;
  },
  writingExampleRatings(id: string | number) {
    return `/admin/writing_examples/${id}/ratings`;
  },
  writingExamples() {
    return '/admin/writing_examples';
  },
  moduleTrackTemplates() {
    return '/admin/module_track_templates';
  },
  api: {
    admissions: {
      program(id) {
        return `/api/web/admin/admissions/programs/${id}`;
      },
      programs(params?) {
        const pathBase = '/api/web/admin/admissions/programs';
        if (params) {
          return `${pathBase}/?${serializeUrlParams(params)}`;
        } else {
          return pathBase;
        }
      }
    },
    testPrep: {
      aiContentAudit(id: number) {
        return `/api/web/admin/test_prep/ai/content_audits/${id}`;
      },
      aiContentAudits() {
        return '/api/web/admin/test_prep/ai/content_audits';
      },
      aiContentAuditsAvailableWorkflows(contentId: number, contentType: string) {
        const params = { contentId, contentType };
        return `/api/web/admin/test_prep/ai/content_audits/workflows?${serializeUrlParams(params)}`;
      },
      aiContentFix(id: number) {
        return `/api/web/admin/test_prep/ai/content_fixes/${id}`;
      },
      aiContentFixAccept(id: number) {
        return `/api/web/admin/test_prep/ai/content_fixes/${id}/accept`;
      },
      aiContentFixReject(id: number) {
        return `/api/web/admin/test_prep/ai/content_fixes/${id}/reject`;
      },
      aiContentFixes() {
        return '/api/web/admin/test_prep/ai/content_fixes';
      },
      aiContentFixesAvailableEvaluators(contentId: number, contentType: string) {
        const params = { contentId, contentType };
        return `/api/web/admin/test_prep/ai/content_fixes/evaluators?${serializeUrlParams(params)}`;
      },
      aiContentFixesReferenceableContent(contentId: number, contentType: string) {
        const params = { contentId, contentType };
        return `/api/web/admin/test_prep/ai/content_fixes/referenceable_content?${serializeUrlParams(params)}`;
      },
      aiDraftPrompt(id: number) {
        return `/api/web/admin/test_prep/ai/draft_prompts/${id}`;
      }
    },
    aiPromptVersion(id: number, versionId: number, proposedFixId: number) {
      if (proposedFixId) {
        return `/api/web/admin/ai_prompts/${id}/versions/${versionId || 'current'}/fixes/${proposedFixId}`;
      } else {
        return `/api/web/admin/ai_prompts/${id}/versions/${versionId || 'current'}`;
      }
    },
    applicantProfile(id: number) {
      return `/api/web/admin/applicant_profiles/${id}`;
    },
    applicantProfileAssets(id: number) {
      return `/api/web/admin/applicant_profiles/${id}/assets`;
    },
    applicantProfiles(params?) {
      if (params) {
        return `/api/web/admin/applicant_profiles?${serializeUrlParams(params)}`;
      } else {
        return '/api/web/admin/applicant_profiles';
      }
    },
    applicantProfileDeleteAsset(profileId: number, assetId: number) {
      return `/api/web/admin/applicant_profiles/${profileId}/asset/${assetId}`;
    },
    brandings() {
      return '/api/web/admin/brandings';
    },
    evaluation(id) {
      return `/api/web/admin/evaluations/${id}`;
    },
    evaluations() {
      return '/api/web/admin/evaluations';
    },
    gradingRequests(params?) {
      const pathBase = '/api/web/admin/grading_requests';
      if (params) {
        return pathBase + `?${serializeUrlParams(params)}`;
      } else {
        return pathBase;
      }
    },
    lessons(searchQuery: string) {
      return `/api/web/admin/lessons/search?query=${encodeURIComponent(searchQuery)}`;
    },
    lessonAuthorships() {
      return '/api/web/admin/lesson_authorships';
    },
    liveClassCohort(id) {
      return `/api/web/admin/live_class_cohorts/${id}`;
    },
    liveClassCohortAssignments(id) {
      return `/api/web/admin/live_class_cohorts/${id}/assignments`;
    },
    liveClassCohorts(params?) {
      const pathBase = '/api/web/admin/live_class_cohorts';
      if (params) {
        return `${pathBase}/?${serializeUrlParams(params)}`;
      } else {
        return pathBase;
      }
    },
    liveClass(id) {
      return `/api/web/admin/live_classes/${id}`;
    },
    liveClasses(params?) {
      const pathBase = '/api/web/admin/live_classes';
      if (params) {
        return `${pathBase}/?${serializeUrlParams(params)}`;
      } else {
        return pathBase;
      }
    },
    liveClassSession(id: number) {
      return `/api/web/admin/live_class_sessions/${id}`;
    },
    liveClassSessions(params?) {
      const rootPath = '/api/web/admin/live_class_sessions';
      if (params) {
        return `${rootPath}/?${serializeUrlParams(params)}`;
      } else {
        return rootPath;
      }
    },
    liveClassRegistrations(params) {
      return `/api/web/admin/live_class_registrations/?${serializeUrlParams(params)}`;
    },
    promptRequest(id) {
      return `/api/web/admin/prompt_requests/${id}`;
    },
    promptRequestContentRequest(promptRequestId, id) {
      return `/api/web/admin/prompt_requests/${promptRequestId}/content_requests/${id}`;
    },
    promptRequestContentRequestAccept(promptRequestId, id) {
      return `/api/web/admin/prompt_requests/${promptRequestId}/content_requests/${id}/accept`;
    },
    promptRequestContentRequestCreate(id) {
      return `/api/web/admin/prompt_requests/${id}/content_requests`;
    },
    promptRequestDraftPromptAccept(promptRequestId, id) {
      return `/api/web/admin/prompt_requests/${promptRequestId}/draft_prompts/${id}/accept`;
    },
    promptRequestDraftPromptMaybe(promptRequestId, id) {
      return `/api/web/admin/prompt_requests/${promptRequestId}/draft_prompts/${id}/maybe`;
    },
    promptRequestDraftPromptReject(promptRequestId, id) {
      return `/api/web/admin/prompt_requests/${promptRequestId}/draft_prompts/${id}/reject`;
    },
    promptRequestCancel(id) {
      return `/api/web/admin/prompt_requests/${id}/cancel`;
    },
    promptRequestPersist(id) {
      return `/api/web/admin/prompt_requests/${id}/persist`;
    },
    promptRequestRetry(id) {
      return `/api/web/admin/prompt_requests/${id}/retry`;
    },
    showContentBlock(id: number) {
      return `/api/web/admin/content_blocks/${id}`;
    },
    subscriptionLevels() {
      return '/api/web/admin/subscription_levels';
    },
    updateContentBlock(id: number) {
      return `/api/web/admin/content_blocks/${id}`;
    },
    createContentBlock() {
      return '/api/web/admin/content_blocks';
    },
    moveContentBlock(id: number) {
      return `/api/web/admin/content_blocks/${id}/move`;
    },
    destroyContentBlock(id: number) {
      return `/api/web/admin/content_blocks/${id}`;
    },
    copyContentBlock(id: number) {
      return `/api/web/admin/content_blocks/${id}/copy`;
    },
    previewContentBlock(id: number) {
      return `/api/web/admin/content_blocks/${id}/preview`;
    },
    checkContentBlockSlug(params) {
      return `/api/web/admin/content_blocks/check_slug?${serializeUrlParams(params)}`;
    },
    moduleTrackTemplates(params?) {
      const pathBase = '/api/web/admin/module_track_templates';

      if (params) {
        return `${pathBase}/?${serializeUrlParams(params)}`;
      } else {
        return pathBase;
      }
    },
    copyModuleTrackTemplate(id: number) {
      return `/api/web/admin/module_track_templates/${id}/copy`;
    },
    previewModuleTrackTemplate(id: number) {
      return `/api/web/admin/module_track_templates/${id}/preview`;
    },
    ratings(rateableId: number, rateableType: string, options = {}) {
      options = {
        rateable_id: rateableId,
        rateable_type: rateableType,
        ...options
      };
      return `/api/web/admin/ratings?${serializeUrlParams(options)}`;
    },
    ratingsSummary(rateableId: number, rateableType: string, options = {}) {
      options = {
        rateable_id: rateableId,
        rateable_type: rateableType,
        ...options
      };
      return `/api/web/admin/ratings/summary?${serializeUrlParams(options)}`;
    },
    resetModuleTrackTemplate(id: number) {
      return `/api/web/admin/module_track_templates/${id}/reset`;
    },
    activateModuleTrackTemplate(id: number) {
      return `/api/web/admin/module_track_templates/${id}/activate`;
    },
    uploadStudyScheduleBlock(parentId: number) {
      return `/api/web/admin/content_blocks/${parentId}/study_schedule_upload`;
    },
    writingExample(id: number): string {
      return `/api/web/admin/writing_examples/${id}`;
    },
    writingExampleDeletePdf(id: number): string {
      return `/api/web/admin/writing_examples/${id}/delete_pdf`;
    },
    writingExamples(params?) {
      const rootPath = '/api/web/admin/writing_examples';
      if (params) {
        return `${rootPath}/?${serializeUrlParams(params)}`;
      } else {
        return rootPath;
      }
    },
    writingExampleSearch(searchQuery: string) {
      return `/api/web/admin/writing_examples/search?query=${encodeURIComponent(searchQuery)}`;
    }
  }
};

function serializeUrlParams(params) {
  return Object.keys(params)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
}

export function appendSearchParam(location: Location | URL | string, paramName: string, paramValue: string) {
  // Don't interpolate "null" into a search param
  if (!paramValue) {
    return `${location}`;
  }

  let path: string;
  if (typeof location === 'string') {
    path = location;
  } else {
    path = location.toString();
  }

  let joiner = path.indexOf('?') > -1 ? '&' : '?';
  return `${path}${joiner}${paramName}=${paramValue}`;
}
