import React from 'react';
import { DashboardPieProps } from './types';
import { Pie } from '@nivo/pie';
import style from './style.module.scss';
import cx from 'classnames';

export const DashboardPie: React.FC<DashboardPieProps> = (props) => {
  const { data } = props;

  const total = data.reduce((acc, item) => acc + (item[1] as number), 0);

  return (
    <div className={cx(style.dashboardPie, 'text-center u-margin-A-s u-margin-T-m')}>
      <Pie
        width={156}
        height={156}
        data={data.map((item) => ({
          id: item[0],
          label: item[0],
          value: item[1]
        }))}
        colors={['#CEF0E3', '#26B67C']}
        arcLabel={(d) => `${d.label} ${Math.round((d.value / total) * 100)}%`}
        tooltip={(e) => (
          <div className={style.tooltip}>
            <span className={style.colorBox} style={{ backgroundColor: e.datum.color }}></span>
            <span>
              {e.datum.label}: <strong>{Math.round(e.datum.value)}</strong>
            </span>
          </div>
        )}
        arcLabelsTextColor="#000"
        arcLabelsSkipAngle={15}
        borderColor="#fff"
        borderWidth={2}
        cornerRadius={3}
        padAngle={1}
      />
    </div>
  );
};

export default DashboardPie;
