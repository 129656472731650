import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { find } from 'lodash';

import { InfoButton } from '@magoosh/lib/button';
import { FontAwesomeIcon, MagooshAIIcon } from '@magoosh/lib/icons';
import RatingForm from '@magoosh/organisms/rating';
import { renderLatexInDocument } from 'utilities/latex';
import { secondsFormattedAsMinutes } from 'utilities/time';

import * as api from '@test_prep/app/api';
import { Interaction } from '@test_prep/app/ai_tutor/types';

interface Props {
  activity: Interaction;
  addOrUpdateUserInteractions: (interactions: Interaction[]) => void;
}

const PromptActivity: React.FC<Props> = (props) => {
  const timerRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeValue, setTimeValue] = useState(0);

  // TODO transfer display needs to go to ai prompt
  // We treat the calculator as a singleton on the page. It may already exist in the page content.
  const existingCalculator = useMemo(() => {
    return document.getElementsByClassName('calculator')[0];
  }, []);

  useLayoutEffect(() => {
    if (props.activity.status !== 'finished') return;
    if (props.activity.activityContent.answer) return;

    // Initialize function is made available in javascripts/application/answer_form.js
    // Handles form button enable/disable based on answer choice selection
    (window as any).initializeAnswerForm();

    // Initialize function is made available in javascripts/application/practice_sessions.js.eb
    // Handles making long passages scrollable
    (window as any).initializePracticeSession();

    if (!calculatorAlreadyPresentOnPage()) {
      // Initialize function is made available in javascripts/application/calculator.js
      (window as any).initializeCalculator();
    }

    const ticker = attachTicker(timerRef.current);

    return () => clearInterval(ticker);
  }, [props.activity.status, props.activity.id]);

  // We've noticed that katex.renderToString and katex.render behave differently when parsing
  // unicode characters like &gt;. The former throws a ParseError while the latter works.
  // Use `katex.render` under the hood to be more permissive in rendering.
  useLayoutEffect(() => {
    renderLatexInDocument(`similar prompt ${props.activity.content.aiPromptId}`);
  }, [props.activity.activityContent.aiPrompt]);

  useEffect(() => {
    if (props.activity.activityContent.answer) {
      setTimeValue(props.activity.activityContent.answer.time);
      clearInterval(timerRef.current.dataset.ticker);

      // Initialize function is made available in javascripts/application/vocabulary.js
      // Handles making words clicakble to get a definition
      (window as any).initializeVocabularyWrapper();
    }
  }, [props.activity.activityContent.answer]);

  const attachTicker = (element) => {
    setTimeValue(props.activity.activityContent.answer?.time || 0);
    element.value = props.activity.activityContent.answer?.time || 0;

    const tickFunction = () => {
      const newTime = parseInt(element.value) + 1;
      element.value = newTime;
      setTimeValue(newTime);
    };

    element.dataset.ticker = setInterval(tickFunction, 1000);
    return element.dataset.ticker;
  };

  const calculatorAlreadyPresentOnPage = () => {
    return !!existingCalculator;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(e.target);

    api.createAIPromptAnswer(formData).then((answer) => {
      api.fetchTutoringSessionInteraction(props.activity.tutoringSessionId, props.activity.id).then((activity) => {
        setIsSubmitting(false);
        props.addOrUpdateUserInteractions([activity]);

        api.fetchNextTutoringSessionInteractions(props.activity.tutoringSessionId, props.activity.id).then((interactions) => {
          props.addOrUpdateUserInteractions(interactions);
        });
      });
    });
  };

  return (
    <div>
      <form className="wordnik-vocabulary-wrapper wordnik-vocabulary-passage-wrapper answer" onSubmit={handleSubmit}>
        <input type="hidden" name="answer[prompt_id]" value={props.activity.content.aiPromptId} />
        <input type="hidden" name="answer[activity_id]" value={props.activity.id} />
        <input type="hidden" name="answer[activity_type]" value="TestPrep::TutoringSessions::Interactions::Base" />

        <div className="align-items-center d-flex u-margin-B-s">
          <div className="flex-1">
            <strong>{props.activity.name}</strong>
          </div>

          <div className="d-flex gap-s">
            {
              props.activity.activityContent.aiPrompt.calculatorHtml && (
                <div style={{ color: '#363437' }}>
                  {
                    calculatorAlreadyPresentOnPage() ? (
                      <div onClick={() => (window as any).toggleCalculator(existingCalculator)} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon="calculator" /> Calculator
                      </div>
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: props.activity.activityContent.aiPrompt.calculatorHtml }} />
                    )
                  }
                </div>
              )
            }

            <div style={{ color: '#363437' }}>
              <FontAwesomeIcon icon="clock-o" />

              <input
                autoComplete="off"
                ref={timerRef}
                name="answer[time]"
                type="hidden"
              />

              <label style={{ marginLeft: 4 }}>
                {secondsFormattedAsMinutes(timeValue, false)}
              </label>
            </div>
          </div>
        </div>

        <div>
          <div className="alert-message answer-instructions block-message">
            <div dangerouslySetInnerHTML={{ __html: props.activity.activityContent.aiPrompt.instructionsHtml }} />
          </div>
        </div>

        {/* Match the padding in .container */}
        <div className="bg-white" style={{ padding: '15px 15px 0 15px' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: props.activity.activityContent.aiPrompt.contentNodesHtml
            }}
          />
        </div>

        {
          !props.activity.activityContent.answer ? (
            <div
              className="form-actions inline-actions d-flex flex-1 gap-s justify-content-end u-margin-A-n"
              style={{ backgroundColor: '#dad9db' }}
            >
              {/* The class and disabled state of this button is controlled by code in answer_form.js */}
              <button
                className="btn disabled btn-default d-flex flex-1 flex-md-none justify-content-center"
                data-original-class="btn-primary"
                disabled={isSubmitting || !!props.activity.activityContent.answer}
                type="submit"
              >
                {
                  isSubmitting && (
                    <span>
                      <FontAwesomeIcon icon="spinner" className="fa-pulse" />
                      {'\u00a0'}
                    </span>
                  )
                }
                Submit Answer
              </button>
            </div>
          ) : (
            props.activity.activityContent.answer.isCorrect ? (
              <div className="alert alert-success text-center u-margin-A-n" style={{ borderRadius: 0, padding: '23px 15px' }}>
                Correct!
              </div>
            ) : (
              <div className="alert alert-danger text-center u-margin-A-n" style={{ borderRadius: 0, padding: '23px 15px' }}>
                Read the explanation and use the tutor to see where your solution went wrong.
              </div>
            )
          )
        }
      </form>

      {
        !props.activity.activityContent.answer && (
          <div className="align-items-center d-flex flex-1 gap-s u-margin-V-s">
            <MagooshAIIcon className="text-gray-light" />

            <div className="text-gray-light">
              <small>This question was created by the AI Tutor.</small>
            </div>
          </div>
        )
      }

      {
        !props.activity.activityContent.answer && !props.activity.activityContent.aiPrompt.rating && (
          <div className="hidden-xs">
            <RatingForm
              feedbackClassName="align-items-center d-flex flex-row gap-s text-gray-light"
              rating={{
                rateableId: props.activity.content.aiPromptId,
                rateableType: 'AIPrompt',
                rating: props.activity.activityContent.aiPrompt.rating?.rating,
                text: props.activity.activityContent.aiPrompt.rating?.text
              }}
              rateableName="similar question"
            />
          </div>
        )
      }

      {
        !!props.activity.activityContent.answer && (
          <div className="bg-white d-flex flex-column gap-s u-padding-A-s" style={{ paddingBottom: props.activity.activityContent.aiPrompt.rating ? 20 : 80 }}>
            <h3 className="u-margin-B-n u-margin-T-s">Explanation</h3>

            <div
              dangerouslySetInnerHTML={{
                __html: props.activity.activityContent.aiPrompt.textExplanationHtml
              }}
            />

            <hr />

            <RatingForm
              feedbackClassName="align-items-md-center d-flex flex-column flex-md-row gap-s"
              rating={{
                rateableId: props.activity.content.aiPromptId,
                rateableType: 'AIPrompt',
                rating: props.activity.activityContent.aiPrompt.rating?.rating,
                text: props.activity.activityContent.aiPrompt.rating?.text
              }}
              rateableName="similar question"
            />
          </div>
        )
      }
    </div>
  );
};

export default PromptActivity;
