import * as React from 'react';
import cx from 'classnames';

import style from './style.module.scss';

interface IconProps {
  color: string;
  size: number;
}

export const InProgressCheck = ({ color, size }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 64 64" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
      <defs>
        <circle id="path-1" cx="32" cy="32" r="32"></circle>
        <mask
          id="mask-2"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="64"
          height="64"
          fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
      </defs>
      <g id="in-progress_64" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="in-progress">
          <use
            id="Oval"
            stroke={color}
            mask="url(#mask-2)"
            strokeWidth="12"
            strokeDasharray="4,4"
            fillRule="nonzero"
            xlinkHref="#path-1"/>
          <path
            d="M35,60.8467088 C49.6066696,59.3455215 61,47.0032882 61,32 C61,16.9967118 49.6066696,4.6544785 35,3.15329117"
            id="Path"
            stroke={color}
            strokeWidth="6"
            fill="nonzero"/>
          <path
            d="M23.1166153,43.5482681 L12.3202854,32.9885895 C12.1433966,32.8155781 12.1333906,32.5308182 12.31196,32.337368 L16.675053,27.6106839 C16.8473437,27.4240356 17.1325194,27.4074554 17.323222,27.5840319 L28.4302338,37.8683021 L46.1063303,20.3124512 C46.2800742,20.1398891 46.5701484,20.1376252 46.7532105,20.306455 L51.6677111,24.8388759 C51.8512292,25.0081263 51.8603171,25.2859542 51.6817477,25.4657257 L31.2583446,46.0266254 L29.0685848,48.7378938 C28.9201385,48.9216938 28.6692376,48.9465608 28.4942038,48.8052394 C28.3175139,48.9469562 28.0594329,48.9376251 27.8899865,48.7701841 L25.5676177,46.4753005 L23.2037575,43.6980801 C23.1652453,43.6528335 23.1361914,43.6019342 23.1166153,43.5482681 Z"
            id="Check"
            fill={color}/>
        </g>
      </g>
    </svg>
  );
}

export const Checkmark = (props: { completed?: boolean }) => {
  return (
    <span className={cx(style.checkmark, { [style.checkmarkCompleted]: props.completed })} />
  );
}

interface FontAwesomeIconProps {
  className?: string;
  icon: string;
  style?: any;
  title?: string;
}

export class FontAwesomeIcon extends React.Component<FontAwesomeIconProps> {
  render() {
    return (
      <i
        aria-hidden='true'
        className={cx("fa", `fa-${this.props.icon}`, this.props.className)}
        style={this.props.style}
        title={this.props.title}
      />
    );
  }
}

interface LoadingBarsProps {
  height: number;
  width: number;
}

export const LoadingBars = ({ height, width }: LoadingBarsProps) => {
  return (
    <svg width={`${width}px`}  height={`${height}px`}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{background: 'none'}}>
      <rect y="30" height="40" fill="#e90c59" x="15" width="10">
        <animate attributeName="opacity" calcMode="spline" values="1;0.2;1" keyTimes="0;0.5;1" dur="1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.6s" repeatCount="indefinite" />
      </rect>
      <rect y="30" height="40" fill="#fe718d" x="35" width="10">
        <animate attributeName="opacity" calcMode="spline" values="1;0.2;1" keyTimes="0;0.5;1" dur="1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.4s" repeatCount="indefinite" />
      </rect>
      <rect y="30" height="40" fill="#ffe6f5" x="55" width="10">
        <animate attributeName="opacity" calcMode="spline" values="1;0.2;1" keyTimes="0;0.5;1" dur="1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" begin="-0.2s" repeatCount="indefinite" />
      </rect>
      <rect y="30" height="40" fill="#c2dfd7" x="75" width="10">
        <animate attributeName="opacity" calcMode="spline" values="1;0.2;1" keyTimes="0;0.5;1" dur="1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" begin="0s" repeatCount="indefinite" />
      </rect>
    </svg>
  );
}

interface TagIconProps {
  height: number;
  width: number;
}

export const TagIcon = ({ height, width }: TagIconProps) => {
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" style={{verticalAlign: 'middle'}}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-922.000000, -724.000000)" fill="#FFFFFF" stroke="#817E85">
          <g transform="translate(914.000000, 717.000000)">
            <path d="M11.9743304,10.9815848 C12.158111,10.7978041 12.25,10.5777543 12.25,10.3214286 C12.25,10.0651029 12.1593201,9.84626207 11.9779576,9.66489955 C11.7965951,9.48353704 11.5777543,9.39285714 11.3214286,9.39285714 C11.0651029,9.39285714 10.8462621,9.48353704 10.6648996,9.66489955 C10.483537,9.84626207 10.3928571,10.0651029 10.3928571,10.3214286 C10.3928571,10.5777543 10.483537,10.7965951 10.6648996,10.9779576 C10.8462621,11.1593201 11.0651029,11.25 11.3214286,11.25 C11.5777543,11.25 11.795386,11.1605292 11.9743304,10.9815848 Z M19.9905134,14.5 C19.9905134,14.7563257 19.9010426,14.9739574 19.7220982,15.1529018 L16.1601562,18.7220982 C15.9715392,18.9010426 15.7514894,18.9905134 15.5,18.9905134 C15.2436743,18.9905134 15.0260426,18.9010426 14.8470982,18.7220982 L9.66015625,13.5279018 C9.47637557,13.3489574 9.32040615,13.1047262 9.1922433,12.7952009 C9.06408046,12.4856755 9,12.2027543 9,11.9464286 L9,8.92857143 C9,8.67708208 9.09188896,8.45945032 9.27566964,8.27566964 C9.45945032,8.09188896 9.67708208,8 9.92857143,8 L12.9464286,8 C13.2027543,8 13.4856755,8.06408046 13.7952009,8.1922433 C14.1047262,8.32040615 14.3513756,8.47637557 14.5351562,8.66015625 L19.7220982,13.8398438 C19.9010426,14.0284608 19.9905134,14.2485106 19.9905134,14.5 Z" id=""></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
