import React, { useState } from 'react';
import { SelectInstructorsFormTabProps, SelectInstructorsFormTabValues } from './types';
import style from './style.module.scss';
import colors from '../../assets/style.module.scss';
import cx from 'classnames';
import { DefaultButton } from '@magoosh/lib/button';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import ListSelectorField from '@magoosh/b2b/app/lib/list_selector_field';
import NamePlate from '@magoosh/b2b/app/lib/name_plate';
import { Icon } from '@magoosh/b2b/app/assets/icons';
import { compact } from 'lodash';

const initialValues = {
  selectedInstructors: []
};

const validationSchema = yup.object({
  selectedInstructors: yup
    .array()
    .nullable()
    .required('You must select at least one instructor to add into the enrollment list.')
});

export const SelectInstructorsFormTab: React.FC<SelectInstructorsFormTabProps> = (props) => {
  const handleOnSubmit = (
    values: SelectInstructorsFormTabValues,
    { resetForm }: FormikActions<SelectInstructorsFormTabValues>
  ): void => {
    props.addSelectedInstructors(values.selectedInstructors);
    resetForm({ values: initialValues });
  };

  const filterFunction = (values, item) => {
    return values.some(
      (value) =>
        (!item.email || !item.email.toLocaleLowerCase().includes(value)) &&
        (!item.firstName || !item.firstName.toLocaleLowerCase().includes(value)) &&
        (!item.lastName || !item.lastName.toLocaleLowerCase().includes(value))
    );
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
        {({ submitForm }) => (
          <>
            <ListSelectorField
              name="selectedInstructors"
              list={props.instructorsList}
              emptyMessage="There are no instructors listed in your school. Please add or upload instructors through the other tabs."
              filterFunction={filterFunction}>
              {(item: Person) => (
                <NamePlate
                  icon={<Icon iconType="Instructor" fill={colors.supplementaryMagenta} size="s" />}
                  headline={item.fullName || compact([item.lastName, item.firstName]).join(', ')}
                  subheadline={item.email}
                />
              )}
            </ListSelectorField>
            <div className={cx(style.tabActions, style.end)}>
              <DefaultButton onClick={submitForm}>
                <FontAwesomeIcon icon="plus-square" /> Add Selected Instructors
              </DefaultButton>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default SelectInstructorsFormTab;
