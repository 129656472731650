import * as React from 'react';
import cx from 'classnames';

import BaseButton, { ButtonProps } from '@magoosh/lib/button/base';
import ResponsiveButtonGroup from './responsive_button_group';

import style from './style.module.scss';

export const PrimaryButton = (props: ButtonProps) => {
  return <BaseButton {...props} bsStyle="primary" />;
};
export const SecondaryButton = (props: ButtonProps) => {
  return <BaseButton {...props} bsStyle="secondary" />;
};
export const InfoButton = (props: ButtonProps) => {
  return <BaseButton {...props} bsStyle="info" />;
};
export const DefaultButton = (props: ButtonProps) => {
  return <BaseButton {...props} bsStyle="default" />;
};
export const DangerButton = (props: ButtonProps) => {
  return <BaseButton {...props} bsStyle="danger" />;
};
export const BorderlessButton = (props: ButtonProps) => {
  return <BaseButton {...props} className={cx(style.borderlessButton, props.className)} bsStyle="default" />;
};

export const DefaultFilledButton = (props: ButtonProps) => {
  return (
    <BorderlessButton
      {...props}
      className={cx(props.className, style.defaultFilledButton, 'btn')}
      bsStyle="default"
    >
      {props.children}
    </BorderlessButton>
  );
};

/**
 * TabButton (dark gray) is used to provide affordance as to what content they are navigated to.
 */
export const TabButton = (props: ButtonProps) => {
  return (
    <BorderlessButton
      className={cx(style.tabButton, 'btn')}
      bsClass="default"
      bsStyle="default"
      size="lg"
      {...props}>
      {props.children}
    </BorderlessButton>
  );
};

/**
 * SelectButton (purple) is used to denote that a user choice has consequences for their experience.
 */
export const SelectButton = (props: ButtonProps) => {
  return (
    <BorderlessButton
      className={cx(style.selectButton, 'btn')}
      bsClass="default"
      bsStyle="default"
      size="lg"
      {...props}>
      {props.children}
    </BorderlessButton>
  );
};

export const LinkButton = (props: ButtonProps) => {
  return (
    <BaseButton {...props} className={cx(style.linkButton, props.className, 'btn-multi-line btn-link')} />
  );
};

/**
 * Don't use this
 */
export const CustomButton = (props: ButtonProps) => {
  return (
    <BaseButton
      {...props}
      bsClass="default"
      className={cx(props.className, 'btn')}
    />
  );
};

export { ResponsiveButtonGroup };
