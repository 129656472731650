import * as React from 'react';
import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import { PrimaryButton } from '@magoosh/lib/button';
import { adminPaths, } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import * as yup from 'yup';
import { ContentBlock } from '@magoosh/admin/content_block_editor/types';
import { useEffect, useState } from 'react';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';

interface FormProps {
  block: ContentBlock;
  showConfirm: () => void;
}

interface BlockCopyFormValues {
  name: string;
  slug: string;
}

const BlockCopyForm: React.FC<FormProps> = (props) => {
  const initialValues: BlockCopyFormValues = {
    name: props.block.name,
    slug: `${props.block.slug}_${new Date().toLocaleDateString().replace(/\//g, '_')}`
  };

  const [hasErrors, setHasErrors] = useState(false);

  const validationSchema = yup.object({
    name: yup.string().required(),
    slug: yup.string().required()
  });

  const validateSlugUniqueness = async (slug: string) => {
    return await fetch(adminPaths.api.checkContentBlockSlug({slug}));
  };

  const onSubmitForm = (
    values: BlockCopyFormValues,
    { setSubmitting }: FormikActions<BlockCopyFormValues>
  ) => {
    setSubmitting(true);

    fetch(adminPaths.api.copyContentBlock(props.block.id), {
      method: 'POST',
      body: JSON.stringify(values)
    })
      .then((response) => {
        props.showConfirm();
      })
      .catch((response) => alert(response.errors._error))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting, submitForm  }) => {
        useEffect(() => {
          if(values.slug) {
            const delayDebounceFn = setTimeout(() => {
              validateSlugUniqueness(values.slug)
                .then((res) => {
                  setHasErrors(!res.available)
                });
            }, 500);

            return () => clearTimeout(delayDebounceFn)
          }
        }, [values.slug])

        return (
          <Form>
            <div>
              <FieldInput name="name" className="form-control u-margin-B-s" id="content-block-name" label="Name" type='string' />
              <FieldInput name="slug" className="form-control u-margin-B-s" id="content-block-slug" label="Slug" type='string' />
              { hasErrors && (
                <div className="text-danger">
                  Slug has already been taken
                </div>
              )}
              <PrimaryButton
                className="u-margin-T-s"
                disabled={hasErrors}
                submitting={isSubmitting}
                onClick={submitForm}>
                Create copy
              </PrimaryButton>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
};

export default BlockCopyForm;
