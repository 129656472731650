import React, { useState, useEffect } from 'react';
import { Form, ControlLabel, Alert, Modal, Col } from 'react-bootstrap';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { SecondaryButton, DefaultButton, PrimaryButton } from '@magoosh/lib/button';
import style from './style.module.scss';
import cx from 'classnames';
import { RatingModalProps, RatingModalState, RatingModalFormValues } from './types';
import Tag from '@magoosh/lib/tag';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { checkShowModal, saveModalFeedback } from './utils';
import { TrophyIcon, UpNextIcon } from './icons';

const NEGATIVE_RATING = -1;
const POSITIVE_RATING = 1;

export const RatingModal: React.FC<RatingModalProps> = (props) => {
  const [state, setState] = useState<RatingModalState>(null);

  useEffect(() => {
    if (!props.open) return;

    setState(null);
    getModalData();
  }, [props.open]);

  const initialValues: RatingModalFormValues = {
    rating: null,
    text: ''
  };

  const handleOnSubmit = (
    values: RatingModalFormValues,
    { setSubmitting }: FormikActions<RatingModalFormValues>
  ): void => {
    setSubmitting(true);
    saveModalFeedback(props.rateableId, values.rating, values.text);
    setSubmitting(false);
  };

  const getModalData = () => {
    checkShowModal(props.rateableId).then((response) => {
      setState(response as RatingModalState);
    });
  };

  if (state) {
    return (
      <Modal show={props.open} onHide={() => props.onHide && props.onHide()}>
        <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
          {({ values, isSubmitting, submitForm, setFieldValue, submitCount }) => (
            <Form>
              <div className={style.trophyIcon}>
                <TrophyIcon fill={style.brandPrimary} />
                <div className={style.content}>
                  <Modal.Header className={style.modalHeader} closeButton>
                    <h3>
                      <strong>Way to go!</strong>
                    </h3>
                    <p>You completed {state.currentActivity.name}.</p>
                  </Modal.Header>
                  <Modal.Body className={style.modalBody}>
                    {submitCount === 0 && (
                      <>
                        <ControlLabel>Did you like this {state.currentActivity.type}?</ControlLabel>
                        <div>
                          <Tag
                            displayName={'Yes, I Liked It'}
                            onClickCallback={(e) => {
                              e.preventDefault();

                              const selectedRating = POSITIVE_RATING;
                              setFieldValue('rating', selectedRating);
                              saveModalFeedback(state.currentActivity.id, selectedRating, values.text);
                            }}
                            isSelected={values.rating === POSITIVE_RATING}
                            canBeSelected={true}
                          />
                          <Tag
                            displayName={"No, I Didn't Like It"}
                            onClickCallback={(e) => {
                              e.preventDefault();

                              const selectedRating = NEGATIVE_RATING;
                              setFieldValue('rating', NEGATIVE_RATING);
                              saveModalFeedback(state.currentActivity.id, NEGATIVE_RATING, values.text);
                            }}
                            isSelected={values.rating === NEGATIVE_RATING}
                            canBeSelected={true}
                            negative={true}
                          />
                        </div>

                        {values.rating && (
                          <>
                            <FieldInput
                              name="text"
                              type="textarea"
                              label={
                                values.rating > 0
                                  ? `What did you like about this ${state.currentActivity.type}`
                                  : 'How can we improve?'
                              }
                            />
                            <PrimaryButton
                              className="u-margin-T-xs"
                              disabled={isSubmitting}
                              onClick={submitForm}>
                              Submit Feedback
                            </PrimaryButton>
                          </>
                        )}
                      </>
                    )}

                    {submitCount > 0 && (
                      <Alert bsStyle={values.rating > 0 ? 'primary' : 'default'}>
                        <strong>Thanks for letting us know!</strong> <br />
                        We may not be able to respond to all of your feedback, but we promise to read and
                        consider all of it :)
                      </Alert>
                    )}
                  </Modal.Body>
                </div>
              </div>
              <Modal.Footer className={style.modalFooter}>
                {state.nextActivityName && (
                  <p className={style.nextContentText}>
                    <strong>
                      Up Next:
                      <UpNextIcon className="u-margin-L-xs" /> {state.nextActivityName}
                    </strong>{' '}
                    <span className={cx(style.nextContentMinutes)}>({state.nextActivityMinutes} mins)</span>
                  </p>
                )}
                <div className={style.buttonGroup}>
                  {state.secondaryCta ? (
                    <DefaultButton href={state.secondaryCta.url} className={style.button}>
                      {state.secondaryCta.text}
                    </DefaultButton>
                  ) : null}
                  {state.primaryCta ? (
                    <SecondaryButton href={state.primaryCta.url} className={style.button}>
                      {state.primaryCta.text}
                    </SecondaryButton>
                  ) : null}
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  } else {
    return null;
  }
};

export default RatingModal;
