import * as React from 'react';
import { AnswerChoiceData, GuidedQuizData } from './types';
import GuidedQuizStep from './prompt';
import { renderLatexInRawHtml } from 'utilities/latex';
import { PrimaryButton } from '@magoosh/lib/button';
import cx from 'classnames';
import style from './style.module.scss';
import trackEvent from 'utilities/track_event';

interface GuidedQuizState {
  questionIndex: number;
  currentChoice?: AnswerChoiceData;
}

export default class GuidedQuiz extends React.Component<GuidedQuizData> {
  state: GuidedQuizState = {
    questionIndex: -1,
    currentChoice: undefined
  };

  // Let the parent know about component updates so the iframe can be adjusted accordingly
  postUpdateMessage() {
    window.parent.postMessage(
      {
        event: 'scaffold.reload',
        height: `${window.document.body.scrollHeight}px`
      },
      '*'
    );
  }

  componentDidUpdate() {
    this.postUpdateMessage();
  }

  componentDidMount() {
    this.postUpdateMessage();
  }

  render() {
    return (
      <div className={style.guidedQuiz}>
        <div className={style.questionBox}>{this.renderQuestion()}</div>
        <div className={style.answerCell}>
          {this.renderAnswerCell()}
          {this.state.currentChoice && this.state.currentChoice.correct && this.renderNextButton()}
        </div>
      </div>
    );
  }

  renderQuestion = () => {
    // Pick the current step or default to the first one

    let step;

    if (this.state.questionIndex < 0) {
      step = this.props.steps[0];
    } else if (this.state.questionIndex >= this.props.steps.length) {
      step = this.props.steps[this.props.steps.length - 1];
    } else {
      step = this.props.steps[this.state.questionIndex];
    }

    return (
      <div className={style.questionBody}>
        {this.state.questionIndex === -1 && <h3 className={style.headerBlue}>{this.props.questionHeader}</h3>}
        <div dangerouslySetInnerHTML={{ __html: renderLatexInRawHtml(step.questionBody) }} />
      </div>
    );
  };

  renderAnswerCell = () => {
    if (this.state.questionIndex === -1) {
      return (
        <div className={style.startCta}>
          <h3 className={style.headerBlue}>{this.props.cta}</h3>
          <PrimaryButton size={'large'} onClick={this.nextStep}>
            See Step 1
          </PrimaryButton>
        </div>
      );
    } else if (this.state.questionIndex >= this.props.steps.length) {
      return (
        <div className="text-center">
          <h2>Well done!</h2>
          <a href={this.props.referralLink} target="_parent" className="btn btn-primary btn-lg">
            Keep Learning
          </a>
        </div>
      );
    } else {
      return (
        <GuidedQuizStep
          onClick={this.onPickChoice}
          selected={this.state.currentChoice}
          {...this.props.steps[this.state.questionIndex]}
        />
      );
    }
  };

  renderNextButton = () => (
    <div
      className={cx(style.nextButton, 'pull-right fa fa-chevron-right')}
      title="Next step"
      onClick={this.nextStep}></div>
  );

  nextStep = () => {
    trackEvent('scaffoldingProgress', { stepFinished: this.state.questionIndex + 1 });
    this.setState({
      questionIndex: this.state.questionIndex + 1,
      currentChoice: null
    });
  };

  onPickChoice = (choice: AnswerChoiceData) => {
    this.setState({ currentChoice: choice });
  };
}
