const breakdownSections = [
  {
    name: 'Arithmetic and Fractions',
    percentCorrect: 67
  },
  {
    name: 'Powers and Roots',
    percentCorrect: 50
  },
  {
    name: 'Percents and Ratios',
    percentCorrect: 44
  },
  {
    name: 'Algebra',
    percentCorrect: 28
  },
  {
    name: 'Integer Properties',
    percentCorrect: 27
  }
];

const scoreSections = [
  {
    name: 'Quant',
    sampleScore: 84,
    scoreRange: 'Score range: 60-90'
  },
  {
    name: 'Verbal',
    sampleScore: 83,
    scoreRange: 'Score range: 60-90'
  },
  {
    name: 'Total',
    sampleScore: 635,
    scoreRange: 'Score range: 205-805'
  }
];

const scoreSectionsFocus = [
  {
    name: 'Quant',
    sampleScore: 84,
    scoreRange: 'Score range: 60-90'
  },
  {
    name: 'Verbal',
    sampleScore: 83,
    scoreRange: 'Score range: 60-90'
  },
  {
    name: 'DI',
    sampleScore: 79,
    scoreRange: 'Score range: 60-90'
  },
  {
    name: 'Total',
    sampleScore: 635,
    scoreRange: 'Score range: 205-805'
  }
];

export default {
  null:  { breakdownSections, scoreSections },
  focus: { breakdownSections, scoreSections: scoreSectionsFocus }
};
