import {Subscription} from "./types";
import {Col, Row} from "react-bootstrap";
import style from "./style.module.scss";
import * as React from "react";
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import Card from '@magoosh/lib/card';
import ProgressBar from '@magoosh/lib/progress_bar';
import readingIcon from './reading.svg'
import mathIcon from './math.svg'
import mathNoCalcIcon from './math_no_calc.svg'
import writingIcon from './writing.svg'
import scienceIcon from './science.svg'
import { capitalize } from 'lodash';

export class StudentStrengthWeaknesses extends React.Component<Subscription> {

  render() {
    const subscription = this.props;
    const sectionStats = subscription.sectionStats;

    return (
      <div>
        <Row>
          <Col md={7} className={style.sectionHeader}>
                        Strengths & Weaknesses
          </Col>
          <Col md={5} className={style.sectionHeader}>
            <a href={subscription.answersReviewUrl}>
              <FontAwesomeIcon icon='list-alt'></FontAwesomeIcon>&nbsp;
              Review all {subscription.displayName} answers
            </a>
          </Col>
        </Row>
        {Object.keys(sectionStats).map(examSectionName => {
          const examSectionStats = sectionStats[examSectionName];
          const displayName = examSectionName.replace(/\w+/g, capitalize);

          if (!examSectionStats) { return; }

          const correctAnswers = examSectionStats.correctAnswers;
          const totalAnswers = examSectionStats.totalAnswers;
          const videosWatched = examSectionStats.explanationVideosWatched;

          let icon;

          if (displayName == 'Reading') {
            icon = readingIcon;
          } else if (displayName == 'Math' || displayName == 'Math (with calculator)') {
            icon = mathIcon;
          } else if (displayName == 'Math (no calculator)') {
            icon = mathNoCalcIcon;
          } else if (displayName == 'English' || displayName == 'Writing' || displayName == 'Reading & Writing') {
            icon = writingIcon;
          } else if (displayName == 'Science') {
            icon = scienceIcon;
          }

          return (
            <Card shadow key={examSectionName} className={style.strengthWeaknessesGrid}>
              <div className={style.icon}>
                <div><img src={icon} /></div>
                <div>{displayName}</div>
              </div>
              <div className={style.percentCorrectLabel}>% Correct</div>
              <div className={style.percentCorrectGraph}>
                <ProgressBar
                  backgroundColor={'#E6D7F4'}
                  color={'#4D2079'}
                  percentage={totalAnswers > 0 ? (correctAnswers / totalAnswers) * 100 : 0}
                  size={'md'}
                  minLabel={this.renderPercentage(correctAnswers, totalAnswers)}
                  minLabelStyle={{paddingLeft: '1rem', paddingRight: '1rem'}}
                />
              </div>
              <div className={style.explanationsWatchedLabel}>Explanations Watched</div>
              <div className={style.explanationsWatchedGraph}>
                <ProgressBar
                  backgroundColor={'#E6D7F4'}
                  color={'#9B51E0'}
                  percentage={videosWatched > 0 ? (videosWatched / totalAnswers) * 100 : 0}
                  size={'md'}
                  minLabel={this.renderPercentage(videosWatched, totalAnswers)}
                  minLabelStyle={{paddingLeft: '1rem', paddingRight: '1rem'}}
                />
              </div>
              <div className={style.averagePaceLabel}>Average Pace</div>
              <div className={style.averagePaceValue}>{examSectionStats.averagePace}</div>
            </Card>
          )
        })}
      </div>
    )
  }

  renderPercentage = (numerator, denominator) => {
    if (numerator === -1) { return 'N/A'; }
    const percent = denominator > 0 ? Math.round((numerator / denominator) * 100) : 0;
    return (<span className={style.graphLabel}>{percent}%&nbsp;&nbsp;<span className={style.ratio}>({numerator}/{denominator})</span></span>);
  };
}
