import { paths } from 'config/path_helpers';
import { RatingModalProps } from './types';
import fetch from 'utilities/fetch';

export const saveModalFeedback = (id, rating, text): void => {
  fetch(paths.api.rating(id), {
    method: 'PUT',
    body: JSON.stringify({
      rateableType: 'ContentBlock::Base',
      rating: {
        rating: rating,
        text: text
      }
    })
  });
};

export const checkShowModal = (id): Promise<Partial<RatingModalProps>> => {
  return fetch(paths.api.checkShowRatingModal(id)).then((response) => {
    return response as Promise<RatingModalProps>;
  });
};
