import * as React from 'react';
import { Button } from 'react-bootstrap';
import { isArray } from 'lodash';
import { FontAwesomeIcon } from '@magoosh/lib/icons/template';
import cx from 'classnames';
export type Sizes = 'xs' | 'xsmall' | 'sm' | 'small' | 'md' | 'medium' | 'lg' | 'large';
export type Styles = 'primary' | 'secondary' | 'default' | 'danger' | 'link' | 'info';

export interface ButtonProps {
  block?: boolean;
  bsClass?: string;
  bsStyle?: Styles;
  className?: string;
  disabled?: boolean;
  data?: any;
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
  onClick?: (any) => void;
  size?: Sizes;
  style?: any;
  children?: any;
  submitting?: boolean;
  icon?: string;
  type?: 'submit' | 'reset' | 'button';
  title?: string;
}

export default class BaseButton extends React.Component<ButtonProps> {
  render() {
    // A non-blanking space to put after the icons, if something is rendered after them.
    const nbsp = '\u00a0';
    const children = isArray(this.props.children) ? this.props.children : [this.props.children];

    let disabled = this.props.disabled;
    let icon;
    let submittingIcon;
    let bsStyle;
    let className = this.props.className;

    if (this.props.submitting) {
      submittingIcon = (
        <span>
          <FontAwesomeIcon icon="spinner" className="fa-pulse" />
          {this.props.children || this.props.icon ? nbsp : null}
        </span>
      );
      disabled = true;
    }

    if (this.props.icon) {
      icon = (
        <span>
          <FontAwesomeIcon icon={this.props.icon} />
          {this.props.children ? nbsp : null}
        </span>
      );
    }

    if (this.props.bsStyle) {
      if (this.props.bsStyle != 'secondary') {
        bsStyle = this.props.bsStyle;
      } else {
        bsStyle = null;
        className = cx(className, 'btn-secondary');
      }
    } else {
      bsStyle = 'default';
    }

    const buttonProps: any = {
      bsSize: this.props.size,
      bsStyle: bsStyle,
      block: this.props.block || false,
      className: className,
      disabled,
      href: this.props.href,
      target: this.props.target,
      type: this.props.type,
      onClick: this.handleClick,
      style: this.props.style,
      title: this.props.title
    };

    if (this.props.bsClass) {
      buttonProps.bsClass = this.props.bsClass;
    }

    return (
      <Button {...buttonProps}>
        {icon}
        {submittingIcon}
        {children}
      </Button>
    );
  }

  handleClick = (event) => {
    if (this.props.onClick) {
      event.preventDefault();
      this.props.onClick(this.props.data);
    }
  };
}
