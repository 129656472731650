export interface DataTableQueryParameters {
  sortColumn: string;
  sortDirection: 'asc' | 'desc';
  searchTerm: string;
  startDate?: Date;
  endDate?: Date;
  filters: {};
  page: number;
  perPage: number;
  download?: boolean;
  refresh?: boolean;
}

export function buildDataTableQueryParams(options: DataTableQueryParameters) {
  let params = [];
  let paramsString = ''

  if (options.sortColumn) { params.push(`sortColumn=${encodeURIComponent(options.sortColumn)}`); }
  if (options.sortDirection) { params.push(`sortDirection=${encodeURIComponent(options.sortDirection)}`); }
  if (options.searchTerm) { params.push(`searchTerm=${encodeURIComponent(options.searchTerm)}`); }
  if (options.startDate) { params.push(`startDate=${encodeURIComponent(options.startDate.toISOString())}`); }
  if (options.endDate) { params.push(`endDate=${encodeURIComponent(options.endDate.toISOString())}`); }

  if (options.download) {
    params.push('download');

    if (options.refresh) {
      params.push('refresh');
    }
  } else {
    if (options.page) { params.push(`page=${options.page}`); }
    if (options.perPage) { params.push(`perPage=${options.perPage}`); }
  }

  Object.keys(options.filters).forEach((key) => {
    params.push(`${key}=${options.filters[key]}`);
  });

  if (options.refresh) { params.push('refresh'); }
  if (params.length > 0) { paramsString = `?${params.join('&')}`; }

  return paramsString;
}
