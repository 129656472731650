import * as React from 'react';
import * as moment from 'moment';
import { paths } from 'config/path_helpers';

import { BorderlessButton } from '@magoosh/lib/button';
import DeleteAssignmentDialog from '../delete_assignment_dialog';
import { Assignment, UserAssignmentState } from '../types';
import style from '../style.module.scss';
import { sumBy, capitalize, some } from 'lodash';
import { Col, Grid, Row } from 'react-bootstrap';
import { User } from 'types/user';
import fetch from 'utilities/fetch';

export interface AssignmentDetailProps {
  assignment: Assignment;
  deleteAssignmentDialogOpen: boolean;
  showDeleteAssignmentDialog: () => void;
  hideDeleteAssignmentDialog: () => void;
}

interface StudentData {
  name: string;
  promptsAnswered: number;
  promptsAnswerCorrectly: number;
  quizState: string;
  state: string;
  userAssignmentId: number;
  videosViewed: number;
}

interface AssignmentDetailState {
  deleteConfirmModalOpen: boolean;
  studentDataById: NumberHash<StudentData>;
  totalPercentCorrect: number;
}

export default class AssignmentDetail extends React.Component<AssignmentDetailProps, AssignmentDetailState> {
  constructor(props: AssignmentDetailProps) {
    super(props);
    const studentDataById = {};
    let totalPromptsAnswered = 0;
    let totalPromptsAnsweredCorrectly = 0;

    // Loop over the student data to generate aggregate data per student, as well as total accuracy
    // for the whole assignment. Stores the results in state.
    props.assignment.students.forEach((student: User) => {
      // The only userAssignment record that gets loaded for this is the one for the current
      // assignment.
      const state = student.userAssignments.length ? student.userAssignments[0].state : 'Not started';
      const userAssignmentId = student.userAssignments.length ? student.userAssignments[0].id : null;
      const name = `${student.firstName} ${student.lastName}`.trim();
      const promptsAnswered = new Set();
      const promptsAnswerCorrectly = new Set();
      const videosViewed = new Set();
      let quizState;

      if (!props.assignment.hasQuiz) {
        quizState = 'N/A';
      } else if (some(student.markings, (marking) => marking.name === 'first_mastered')) {
        quizState = 'Passed';
      } else if (some(student.markings, (marking) => marking.name === 'first_attempted')) {
        quizState = 'Not passed';
      } else {
        quizState = 'Not taken';
      }

      student.answers.forEach((answer) => {
        promptsAnswered.add(answer.promptId);

        if (answer.isCorrect) {
          promptsAnswerCorrectly.add(answer.promptId);
        }
      });

      student.videoViews.forEach((videoView) => {
        videosViewed.add(videoView.videoViewableId);
      });

      studentDataById[student.id] = {
        name: name || student.email || `User #${student.id}`,
        promptsAnswered: promptsAnswered.size,
        promptsAnswerCorrectly: promptsAnswerCorrectly.size,
        quizState,
        state,
        userAssignmentId,
        videosViewed: videosViewed.size
      };

      totalPromptsAnswered += promptsAnswered.size;
      totalPromptsAnsweredCorrectly += promptsAnswerCorrectly.size;
    });

    this.state = {
      deleteConfirmModalOpen: false,
      studentDataById,
      totalPercentCorrect: this.formatPercentage(totalPromptsAnsweredCorrectly, totalPromptsAnswered)
    };
  }

  render() {
    const { assignment } = this.props;
    const { estimatedTime, hasQuiz, lessonCount, questionPromptsCount, students } = assignment;

    return (
      <div className={style.assignment}>
        <a href={paths.assignments()} className={style.backLink}>
          Back to Assignments
        </a>
        <h2>
          Assignment details
          <BorderlessButton
            icon="trash"
            className={style.deleteAssignmentButton}
            onClick={this.props.showDeleteAssignmentDialog}
          />
        </h2>
        <h4>{assignment.title}</h4>
        <h4>{assignment.classSection.ensuredName}</h4>
        {this.renderDueDate()}
        <h4>Content: {assignment.contentBlock.name}</h4>
        <ul className={style.assignmentData}>
          <li>Video lessons: {lessonCount}</li>
          <li>Estimated time: {estimatedTime} minutes</li>
          <li>Has quiz: {hasQuiz ? 'Yes' : 'No'}</li>
          <li>Practice questions: {questionPromptsCount}</li>
        </ul>
        <h2>Class progress</h2>
        {this.renderMetric(
          sumBy(assignment.students, (student: User) => {
            return student.userAssignments.length &&
              student.userAssignments[0].state === UserAssignmentState.submitted
              ? 1
              : 0;
          }),
          'Submitted'
        )}
        {this.renderMetric(students ? students.length : 0, 'Assigned')}
        {this.renderMetric(`${this.state.totalPercentCorrect}%`, 'Accuracy')}
        <Grid className={style.progressGrid}>
          <Row className={style.progressHeaderRow}>
            <Col md={3} xs={3}>
              Student
            </Col>
            <Col md={2} xs={2}>
              Videos watched
            </Col>
            <Col md={2} xs={2}>
              Quiz
            </Col>
            <Col md={2} xs={2}>
              Practice questions
            </Col>
            <Col md={1} xs={1}>
              Accuracy
            </Col>
            <Col md={2} xs={2}>
              Status
            </Col>
          </Row>
          {this.renderStudentProgressRows()}
        </Grid>
        <DeleteAssignmentDialog
          show={this.props.deleteAssignmentDialogOpen}
          assignment={assignment}
          onHide={this.props.hideDeleteAssignmentDialog}
          onSuccess={this.onDeleteSuccess}
        />
      </div>
    );
  }

  formatPercentage = (numerator, denominator) => {
    return Math.round((numerator / denominator) * 100) || 0;
  };

  onDeleteSuccess = () => {
    window.location.assign(paths.assignments());
  };

  renderDueDate = () => {
    const { assignment } = this.props;
    let dueDate;

    if (assignment.dueDate) {
      dueDate = moment(assignment.dueDate).format('L LT');
    } else {
      dueDate = 'No due date specified';
    }

    return <h4>Due: {dueDate}</h4>;
  };

  renderMetric = (value: any, label: string) => {
    return (
      <div className={style.metric}>
        <div className={style.metricValue}>{value}</div>
        <span>{label}</span>
      </div>
    );
  };

  renderStudentProgressRows = () => {
    const { questionPromptsCount, lessonCount, students } = this.props.assignment;

    return students.map((student: User) => {
      const studentData = this.state.studentDataById[student.id];
      let practiceQuestions;
      let resetLink = null;

      if (questionPromptsCount > 0) {
        practiceQuestions = `${studentData.promptsAnswered}/${questionPromptsCount}`;
      } else {
        practiceQuestions = 'N/A';
      }

      if (studentData.state === 'submitted') {
        resetLink = this.renderResetLink(studentData);
      }

      return (
        <Row key={student.id}>
          <Col md={3} xs={3}>
            {studentData.name}
          </Col>
          <Col md={2} xs={2}>
            {studentData.videosViewed}/{lessonCount}
          </Col>
          <Col md={2} xs={2}>
            {studentData.quizState}
          </Col>
          <Col md={2} xs={2}>
            {practiceQuestions}
          </Col>
          <Col md={1} xs={1}>
            {this.formatPercentage(studentData.promptsAnswerCorrectly, studentData.promptsAnswered)}%
          </Col>
          <Col md={2} xs={2}>
            {capitalize(studentData.state)} {resetLink}
          </Col>
        </Row>
      );
    });
  };

  renderResetLink = (studentData: StudentData) => {
    return (
      <>
        (<a onClick={() => this.onClickReset(studentData)}>reset</a>)
      </>
    );
  };

  onClickReset = (studentData: StudentData) => {
    const message =
      `Are you sure you want to reset the submission from ${studentData.name}?\n\n` +
      'This will allow them to work on the assignment and submit it again.';

    if (window.confirm(message)) {
      fetch(paths.api.resetAssignmentSubmission(studentData.userAssignmentId), { method: 'PATCH' }).then(() =>
        window.location.reload()
      );
    }
  };
}
