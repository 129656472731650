import { connect } from 'react-redux';
import StudyLengthHoursPicker from './template';
import { changeStudyLengthHours } from '../actions';
import { RootStore } from '@magoosh/store';

const mapStateToProps = (state: RootStore) => {
  return {
    hoursSelected: state.studyPreferences.studyPreferencesUnsaved.hoursPerDay
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeHoursPerDay: (hours: number) => {
      dispatch(changeStudyLengthHours(hours));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudyLengthHoursPicker);
