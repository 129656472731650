import * as React from 'react';
import { Field, FieldArray, Form, Formik, FormikHelpers as FormikActions } from 'formik';
import { DangerButton, DefaultButton, InfoButton, PrimaryButton } from '@magoosh/lib/button';
import { adminPaths } from 'config/path_helpers';
import fetch from 'utilities/fetch';
import { LiveClassCohort, LiveClassSession } from '@magoosh/admin/live_class_sessions/types';
import capitalize from 'react-bootstrap/lib/utils/capitalize';
import { useEffect, useState } from 'react';
import { formatTime } from '@magoosh/admin/utils';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import style from './style.module.scss';
import moment from 'moment';
import FieldDateTime from '@magoosh/lib/formik_inputs/field_datetime';
import { FieldSelect } from '@magoosh/lib/formik_inputs/field_select/template';
import { FieldDropdownList } from '@magoosh/lib/formik_inputs/field_dropdown_list';

interface FormProps {
  liveClassSession?: LiveClassSession;
  cohortOptions: LiveClassCohort[];
  runQuery: any;
  closeModal: () => void;
}

interface LiveClassSessionFormValues {
  recordingLink: string;
  recordingUnavailable: boolean;
  name?: string;
  description?: string;
  startTime?: Date;
  endTime?: Date;
  platform_id?: string;
  platform?: string;
  resourceLinks: {
    name: string;
    url: string;
  }[];
  assignmentIds: number[];
  cohortId?: number;
}

const LiveClassSessionForm: React.FC<FormProps> = (props) => {
  const [cohortAssignments, setCohortAssignments] = useState([])

  const newSession: () => LiveClassSession = () => {
    const defaultStart = moment().subtract(1, 'month').startOf('hour');
    return {
      id: null,
      name: '',
      description: '',
      recordingUnavailable: false,
      resourceLinks: [],
      platform: 'Custom',
      sessionType: 'webinar',
      registrations: [],
      startTime: defaultStart.unix(),
      endTime: defaultStart.add(1, 'hour').unix(),
      assignmentIds: []
    };
  };

  useEffect(() => {
    if (!props.liveClassSession?.cohort?.id) { return; }

    fetch(adminPaths.api.liveClassCohortAssignments(props.liveClassSession.cohort.id))
      .then(response => {
        setCohortAssignments(response);
      });
  }, [])

  const [session, setSession] = useState(props.liveClassSession || newSession());

  const initialValues: LiveClassSessionFormValues = {
    recordingLink: session.recordingLink || '',
    recordingUnavailable: session.recordingUnavailable,
    resourceLinks: session.resourceLinks || [],
    description: session.description,
    startTime: session.startTime ? moment.unix(session.startTime).toDate() : null,
    endTime: session.endTime ? moment.unix(session.endTime).toDate() : null,
    name: session.name,
    assignmentIds: session.assignmentIds || [],
    cohortId: session.cohort?.id
  };

  const onSubmitForm = (
    values: LiveClassSessionFormValues,
    { setSubmitting }: FormikActions<LiveClassSessionFormValues>
  ) => {
    setSubmitting(true);
    const path = session.id
      ? adminPaths.api.liveClassSession(session.id)
      : adminPaths.api.liveClassSessions();

    fetch(path, {
      method: session.id ? 'PATCH' : 'POST',
      body: JSON.stringify(values)
    })
      .then((response) => {
        props.closeModal();
        props.runQuery();
      })
      .catch((response) => alert(response.errors._error))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik onSubmit={onSubmitForm} initialValues={initialValues}>
      {({ values, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <div>
            <Field name="platform" type="hidden" id="live-class-session-platform" />
            <Field name="platformId" type="hidden" id="live-class-session-platformId" />

            <label htmlFor="live-class-session-name">Name</label>
            <Field
              name="name"
              className="form-control"
              id="live-class-session-name"
              label="Session Name"
            />

            <label htmlFor={'live-class-session-description'}>Description</label>
            <Field
              name="description"
              component="textarea"
              className="form-control"
              id="live-class-session-description"
              label="Description"
            />

            <label htmlFor="live-class-session-recording-link">Recording URL</label>
            <Field
              name="recordingLink"
              className="form-control u-margin-B-s"
              id="live-class-session-recording-link"
              label="Recording URL"
              disabled={values.recordingUnavailable}
            />
            <label htmlFor="session-recording-unavailable">
              <Field
                type="checkbox"
                name="recordingUnavailable"
                id="session-recording-unavailable"
                checked={values.recordingUnavailable}
              />
              <span className="u-margin-L-s">Recording Unavailable</span>
            </label>

            <FieldDropdownList
              name="cohortId"
              data={props.cohortOptions.slice(0, -1)}
              defaultValue={props.cohortOptions[0].id}
              filter="contains"
              placeholder="Select a Cohort"
              textField="name"
              valueField="id"
            />

            <FieldArray
              name="resourceLinks"
              render={(arrayHelpers) => {
                return (
                  <div className="u-margin-T-xs">
                    <label>Resource Links</label>
                    {values.resourceLinks.map((resourceLink, index) => {
                      return (
                        <div key={`resource-link-${index}`} className={style.resourceLink}>
                          <FieldInput
                            name={`resourceLinks[${index}].name`}
                            label="name"
                            type="string"
                            id={`resource-link-${index}`}
                          />
                          <FieldInput
                            name={`resourceLinks[${index}].url`}
                            label={'URL'}
                            type="string"
                            id={`resource-link-${index}`}
                          />
                          <DangerButton
                            title="Delete link"
                            className="u-margin-T-s"
                            onClick={() => arrayHelpers.remove(index)}>
                            <FontAwesomeIcon icon={'trash-o'} />
                          </DangerButton>
                        </div>
                      );
                    })}
                    <div>
                      <DefaultButton onClick={() => arrayHelpers.push({ name: '', url: '' })}>
                        Add Resource Link
                      </DefaultButton>
                    </div>
                  </div>
                );
              }}
            />
            <FieldArray
              name="assignmentIds"
              render={(arrayHelpers) => {
                return (
                  <div className="u-margin-T-xs">
                    <label>Assignments</label>
                    {values.assignmentIds.map((assignment, index) => {
                      return (
                        <div key={`assignment-${index}`} className={style.assignmentInput}>
                          <FieldSelect
                            name={`assignmentIds[${index}]`}
                            label="Assignment"
                            objects={cohortAssignments}
                            valueAccessor="id"
                            keyAccessor="title"
                          />
                          <DangerButton
                            title="Remove assignment"
                            className="u-margin-T-s"
                            onClick={() => arrayHelpers.remove(index)}>
                            <FontAwesomeIcon icon={'trash-o'} />
                          </DangerButton>
                        </div>
                      );
                    })}
                    <div>
                      {cohortAssignments.length > 0 ?
                        <DefaultButton onClick={() => arrayHelpers.push(cohortAssignments[0].id)}>
                          Add Assignment
                        </DefaultButton>
                        :
                        <>No assignments available from the cohort&apos;s class section.</>
                      }
                    </div>
                  </div>
                );
              }}
            />
            <div>
              <PrimaryButton
                className="u-margin-T-s"
                disabled={isSubmitting}
                submitting={isSubmitting}
                onClick={submitForm}>
                Submit
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LiveClassSessionForm;
