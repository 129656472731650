import React from 'react';
import { Badge } from 'react-bootstrap';
import cx from 'classnames';
import style from './style.module.scss';
import { pluralize } from 'utilities/pluralize';

interface BadgeProps {
  style?: {};
  className?: string;
}

interface TrialDaysBadgeProps {
  daysLeft: number;
}

interface GenericBadgeProps {
  className?: string;
  text: string;
}

export const GenericBadge: React.FC<BadgeProps & GenericBadgeProps> = (props) => {
  return (
    <Badge className={cx('u-margin-H-xs u-padding-A-xxs', props.className)} style={props.style}>
      <span>{props.text}</span>
    </Badge>
  );
};

export const PremiumBadge: React.FC<BadgeProps> = (props) => {
  return (
    <Badge className={cx(style.premiumBadge, props.className)} style={props.style}>
      <i className="fa fa-lock"></i>
      {'  '}
      <span>PREMIUM</span>
    </Badge>
  );
};

export const UpgradeBadge: React.FC<BadgeProps> = (props) => {
  return (
    <Badge className={cx(style.premiumBadge, props.className)} style={props.style}>
      <i className="fa fa-lock"></i>
      {'  '}
      <span>UPGRADE TO ACCESS</span>
    </Badge>
  );
};

export const TrialDaysBadge: React.FC<BadgeProps & TrialDaysBadgeProps> = (props) => {
  return (
    <Badge
      className={cx(
        'u-margin-H-xs u-padding-A-xxs',
        props.daysLeft > 3 ? style.greenTrialDaysBadge : style.yellowTrialDaysBadge
      )}
      style={props.style}>
      {pluralize(props.daysLeft, 'Day', 'Days')} Left
    </Badge>
  );
};

export default PremiumBadge;
