import * as React from 'react';
import { filter, find, intersection, maxBy, without } from 'lodash';
import moment from 'moment';

import MultiStep from '@magoosh/lib/modal/multistep';
import trackEvent from 'utilities/track_event';
import { ExamSectionFocus, StudyScheduleData } from '../types';

import RecommendPlanStep from './recommend_plan_step';
import Step from './step';
import StudyExperienceStep from './study_experience_step';
import TestDateStep from './test_date_step';
import WelcomeStep from './welcome_step';
import ExamSectionFocusStep from '@magoosh/organisms/study_schedule/select_schedule/exam_section_focus_step';

interface Props {
  examName: string;
  onSelect: (id: number) => any;
  onSkip: () => void;
  onUpdateUserProfile: (any) => void;
  schedules: StudyScheduleData[];
  studyExperience: string;
  testDate: string;
  showExamSectionFocusStep: boolean;
  showStudyExperienceStep: boolean;
  examSectionFocus: ExamSectionFocus;
}

interface State {
  step: number;
}

export default class SelectSchedule extends React.Component<Props, State> {
  state = { step: 0 };

  advanceStep = () => {
    const newStep = this.state.step + 1;
    trackEvent(`Study Schedule > Onboarding Step ${newStep + 1}`);
    this.setState({ step: newStep });
  };

  handleExamSectionFocusStepComplete = (examSectionFocus: ExamSectionFocus) => {
    this.advanceStep();
    this.props.onUpdateUserProfile({ examSectionFocus });
  };

  handleRecommendPlanStepComplete = (id: number) => {
    const schedule = find(this.props.schedules, (schedule: StudyScheduleData) => schedule.id === id);
    trackEvent('Study Schedule > Set Plan', {
      study_schedule_id: id,
      study_schedule_name: schedule.name
    });
    this.props.onSelect(id);
  };

  handleStudyExperienceStepComplete = (studyExperience: string) => {
    this.advanceStep();
    this.props.onUpdateUserProfile({ studyExperience });
  };

  handleTestDateStepComplete = (testDate: string) => {
    this.advanceStep();
    this.props.onUpdateUserProfile({ testDate });
  };

  componentDidMount() {
    trackEvent('Study Schedule > Onboarding Step 1');
  }

  render() {
    const steps = [
      <WelcomeStep
        key={'WelcomeStep'}
        examName={this.props.examName}
        onComplete={this.advanceStep}
        onSkip={this.props.onSkip}
      />,
      <TestDateStep
        key={'TestDateStep'}
        examName={this.props.examName}
        onComplete={this.handleTestDateStepComplete}
        testDate={this.props.testDate}
      />
    ];
    this.props.showStudyExperienceStep &&
      steps.push(
        <StudyExperienceStep
          key={'StudyExperienceStep'}
          examName={this.props.examName}
          onComplete={this.handleStudyExperienceStepComplete}
          studyExperience={this.props.studyExperience}
        />
      );
    if (this.props.showExamSectionFocusStep) {
      steps.push(
        <ExamSectionFocusStep
          key={'ExamSectionFocusStep'}
          onComplete={this.handleExamSectionFocusStepComplete}
          examSectionFocus={this.props.examSectionFocus}
        />
      );
    }
    steps.push(
      <RecommendPlanStep
        key={'RecommendPlanStep'}
        alternativePlans={this.alternativePlans()}
        onComplete={this.handleRecommendPlanStepComplete}
        recommendedPlan={this.recommendedPlan()}
      />
    );

    return (
      <MultiStep stepIndex={this.state.step}>
        {steps.map((step, idx) => (
          <Step key={idx} step={idx}>
            {step}
          </Step>
        ))}
      </MultiStep>
    );
  }

  alternativePlans() {
    return without(this.props.schedules, this.recommendedPlan());
  }

  // This method tries to match the recommendation logic from our old Syllabus system from
  // the `SyllabusPlanRecommender` Ruby class
  recommendedPlan() {
    // Experience levels are strings like `beginner1` or `advanced2`. Find all plans that match
    // the user's `studyExperience` or default to a beginner plan.
    const plansFilteredByExperience = this.props.showStudyExperienceStep ? [] : this.props.schedules;
    if (this.props.showStudyExperienceStep) {
      this.props.schedules.forEach((schedule) => {
        const scheduleExperienceLevels = schedule.experienceLevels || '';
        if (scheduleExperienceLevels.indexOf(this.props.studyExperience || 'beginner') != -1) {
          plansFilteredByExperience.push(schedule);
        }
      });
    }

    // Find all plans that the user has enough time to complete. If the user has not set a test
    // date, default to a test date one year from now.
    const plansFilteredByLength = filter(this.props.schedules, (schedule) => {
      let daysUntilTest;
      if (moment(this.props.testDate, 'YYYY-MM-DD', true).isValid()) {
        // If the test date has passed, treat it as if there are 0 days until the test date
        daysUntilTest = Math.max(moment(this.props.testDate).diff(moment(), 'days'), 0);
      } else {
        daysUntilTest = 365;
      }

      return daysUntilTest >= (schedule.minDaysUntilTest || 0);
    });

    const plansFilteredByFocus = this.props.showExamSectionFocusStep ? [] : this.props.schedules;
    if (this.props.showExamSectionFocusStep) {
      plansFilteredByFocus.push(
        ...filter(this.props.schedules, (schedule) => {
          return schedule.examSectionFocus == this.props.examSectionFocus;
        })
      );
    }

    const matchingPlans = intersection(
      plansFilteredByExperience,
      plansFilteredByLength,
      plansFilteredByFocus
    );
    if (matchingPlans.length) {
      return maxBy(matchingPlans, (plan) => plan.minDaysUntilTest);
    } else {
      return (
        plansFilteredByExperience[0] ||
        plansFilteredByLength[0] ||
        plansFilteredByFocus[0] ||
        this.props.schedules[0]
      );
    }
  }
}
