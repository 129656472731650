import React from 'react';

import { paths } from 'config/path_helpers';

import Card from '@magoosh/lib/card';
import { DarkCheckIcon, MedalIcon } from '@magoosh/gre/assets/icons';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface ExamSectionQuestionSets {
  name: string;
  questionSets: {
    available: number;
    completed: number;
  }
}

interface Props {
  examSectionQuestionSets: ExamSectionQuestionSets[];
}

const OfficialQuestionsCheckpoint: React.FC<Props> = (props) => {
  return (
    <Card>
      <div className="d-flex flex-row">
        <div className="hidden-xs">
          <MedalIcon fill={colors.brandPrimary} />
        </div>

        <div className="u-margin-L-m">
          <h3 className="u-margin-T-n u-margin-B-xxs"><strong>Official Question Sets</strong></h3>
          <p>
            Only at Magoosh! These are the gold standard for test-accuracy, so they're most useful
            for testing your knowledge and skills, and gauging your progress.
          </p>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-around">
        {
          props.examSectionQuestionSets.map((examSection, idx) => (
            <ExamSectionQuestionSetsProgress key={idx} {...examSection} />
          ))
        }
      </div>
    </Card>
  );
};

const ExamSectionQuestionSetsProgress: React.FC<ExamSectionQuestionSets> = ({ name, questionSets }) => {
  return (
    <div className="text-center">
      <div>
        <strong>
          <a href={paths.officialQuestions()} style={{color: colors.brandSecondary}}>{name}</a>
        </strong>
      </div>

      <div>
        <small>{questionSets.completed}/{questionSets.available}</small>
      </div>

      <div className="d-flex flex-row hidden-xs">
        {
          new Array(questionSets.available).fill(null).map((_, idx) => (
            <div key={idx} style={{marginRight: 3}}>
              {
                idx < questionSets.completed
                  ? <DarkCheckIcon fill={colors.brandSecondaryLight} height={28} width={28} />
                  : <div className="bg-gray-lighter border-radius-all" style={{height: 28, width: 28}} />
              }
            </div>
          ))
        }
      </div>

    </div>
  );
};

export default OfficialQuestionsCheckpoint;
