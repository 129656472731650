import React from 'react';

import { DefaultFilledButton, SecondaryButton } from '@magoosh/lib/button'
import Card from '@magoosh/lib/card';

import NoteIcon from '@admissions/components/icons/note_icon';
import { AdmissionsHighlightedContent } from '@admissions/types';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  highlightedContent: AdmissionsHighlightedContent[];
  notesHref?: string;
}

const HighlightedContentCardSet: React.FC<Props> = (props) => {
  const width = props.highlightedContent.length > 3 ? 195 : 225

  return (
    <div className="d-flex" style={{ gap: 10 }}>
      {
        props.highlightedContent.map((content, idx) => (
          <Card key={idx} className="d-flex flex-column">
            <div style={{ width }}>
              <h4 className="u-margin-A-n">
                <strong>{content.title}</strong>
              </h4>
              <div>{content.subtitle}</div>

              <div className="u-margin-T-s">
                <SecondaryButton block href={content.cta.href}>
                  {content.cta.title}
                </SecondaryButton>
              </div>
            </div>
          </Card>
        ))
      }

      {
        props.notesHref && (
          <Card>
            <div className="align-items-center d-flex flex-column" style={{ height: '100%' }}>
              <div style={{ flex: 1}}>
                <NoteIcon color={colors.grayLight} size={45} />
              </div>

              <div>
                <DefaultFilledButton block href={props.notesHref}>
                  My Notes
                </DefaultFilledButton>
              </div>
            </div>
          </Card>
        )
      }
    </div>
  );
};

export default HighlightedContentCardSet;
