import React from 'react';

import ACT from './exams/act';
import GMAT from './exams/gmat';
import GRE from './exams/gre';
import IELTS from './exams/ielts';
import IELTS_GT from './exams/ielts_general_training';
import LSAT from './exams/lsat';
import MCAT from './exams/mcat';
import SAT from './exams/sat';
import TOEFL from './exams/toefl';

interface Props {
  examName: string;
}

const DetailsContent = (props) => {
  const slug = props.examName.trim().toLowerCase().replace(/ /g, '_');

  const DefaultDetailsContent = () => (
    <>
      <h4>Preparation</h4>
      <p>Just like with the real test, you should have a <strong>pen or pencil</strong> and <strong>scratch paper</strong> to write notes and work out problems.</p>
      <p>We recommend taking the entire test in one sitting to practice your pacing and build your test-taking stamina, which you'll need on test day. Let your family and friends know not to interrupt you during this time. Good luck!</p>
    </>
  );

  const ExamDetailsContent = {
    act: ACT,
    gmat: GMAT,
    gre: GRE,
    ielts: IELTS,
    ielts_general_training: IELTS_GT,
    lsat: LSAT,
    mcat: MCAT,
    sat: SAT,
    toefl: TOEFL
  }[slug] || DefaultDetailsContent;

  return <ExamDetailsContent />;
};

export default DetailsContent;
