import { connect } from 'react-redux';

import { RootStore } from '@magoosh/store';

import {
  hidePlanOptions,
  setItemCompleted,
  setPlan,
  showPlanOptions,
  updateUserProfile,
  optOut,
  setSuggestedLessons
} from './actions';
import Template from './template';

function mapStateToProps(state: RootStore, ownProps) {
  return {...state.studySchedule, ...ownProps};
}

const mapDispatchToProps = {
  hidePlanOptions,
  setItemCompleted,
  setPlan,
  showPlanOptions,
  updateUserProfile,
  optOut,
  setSuggestedLessons
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Template);
