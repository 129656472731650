import React from 'react';

const GMATDetailsContent: React.FC = (props) => {
  return (
    <>
      <h4>Preparation</h4>
      <p>Just like with the real test, you can use the <strong>online whiteboard</strong> or a <strong>physical whiteboard</strong> to write notes and work out problems. An <strong>on-screen calculator</strong> is provided for the section where one is allowed.</p>
      <p>You should also set aside about <strong>2 hours and 15 minutes</strong> for this test. Let your family and friends know not to interrupt you during this time. :)</p>
      <p>You can pause between sections if you need to, but not within a section. Once you start a section, you'll need to finish it because the timer will keep running. We recommend taking the entire test in one sitting to practice your pacing and build your test-taking stamina, which you'll need on test day.</p>
      <p>Above is an option to select the order of the sections that will appear on your exam. You're also allowed to take one 10-minute break at the end of either your first or second section. Good luck!</p>
    </>
  );
};

export default GMATDetailsContent;
