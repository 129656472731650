import React from 'react';

import Page from './editorial_page';

import colors from '@magoosh/lib/styles/colors.module.scss';

interface Props {
  editorialPage: any;
  offsetY?: number;
  stickyCTAOnMobile?: boolean;
}

const EditorialPagePreview: React.FC<Props> = (props) => {
  return (
    <>
      <div className="u-padding-A-xs" style={{ backgroundColor: colors.purpleLight, color: colors.purple }}>
        <div className="container align-items-center d-flex" style={{ gap: 20 }}>
          <div>
            <strong>Preview Mode</strong>
          </div>
        </div>
      </div>

      <Page preview {...props} />
    </>
  )
};

export default EditorialPagePreview;
