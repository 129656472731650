import React, { useState } from 'react';
import cx from 'classnames';
import { intersection, omitBy, pickBy } from 'lodash';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import CardLayout from '@magoosh/layouts/card';
import LoadingIndicator from '@magoosh/layouts/lib/loading_indicator';
import { FilterConfig } from '@magoosh/organisms/filters/types';
import RatingForm from '@magoosh/organisms/rating';
import { paths } from 'config/path_helpers';
import { titleize } from 'utilities/formatting';

import { useAppContext } from '@test_prep/app/context';
import { useQueries } from '@test_prep/app/queries';
import ContextualLink from '@test_prep/app/components/contextual_link';

import EstimatedScore from './components/estimated_score';
import FilterManager from '../components/filter_manager';
import PaceStat from '../components/pace_stat';
import PerformanceByDifficultyChart from '../components/performance_by_difficulty_chart';
import PerformanceByTypeChart from '../components/performance_by_type_chart';
import PercentCorrectChart from '../components/percent_correct_chart';
import QuestionsStat from '../components/questions_stat';
import StudyTimeChart from '../components/study_time_chart';

import FlashcardsMasteredChart from './components/flashcards_mastered_chart';
import SectionSubjects from './components/section_subjects';

import colors from '@magoosh/lib/styles/colors.module.scss';
import style from '../style.module.scss';

interface Props {}

const Analytics: React.FC<Props> = (props) => {
  const { branding, features, filters } = useAppContext().data;
  const history = useHistory();
  const q = useQueries();
  const [hideSubjectsWithNoActivity, setHideSubjectsWithNoActivity] = useState(false);

  const page = q.pageQuery(paths.analytics()).data;

  const breadcrumbs = [
    { name: 'Home', path: paths.dashboard() },
    { name: 'Analytics' }
  ];

  const filtersConfig = [
    {
      all: true,
      allowWhileFocused: true,
      className: 'flex-1',
      default: 'all',
      multiple: true,
      key: 'sections',
      name: 'Section',
      options: (page?.sections || []).map((section) => (
        { name: titleize(section), value: section }
      ))
    },
    {
      default: 'all_time',
      key: 'period',
      name: 'Period',
      options: [
        { name: 'All Time', value: 'all_time' },
        { name: 'Custom', value: 'custom' },
        { name: 'Since Last Practice Test', value: 'since_last_practice_test' }
      ]
    }
  ] as FilterConfig[];

  const extractRelevantFilters = () => {
    if (!filters) return null;

    const relevantKeys = filtersConfig.map((filterConfig) => filterConfig.key).concat('focus');
    return pickBy(filters, (_, key) => relevantKeys.indexOf(key) !== -1);
  };

  const handleFiltersChanged = (updatedFilters) => {
    history.replace(paths.analytics(omitBy(updatedFilters, (value) => !value)));
  };

  const sectionsToDisplay = () => {
    if (!page || !relevantFilters?.sections) return [];

    if (relevantFilters.sections === 'all') {
      return page.sections;
    } else {
      return intersection(page.sections, (relevantFilters.sections as string).split(','));
    }
  };

  const relevantFilters = extractRelevantFilters();

  return (
    <>
      <Helmet>
        <title>Analytics - Magoosh {branding.examName}</title>
      </Helmet>

      <CardLayout breadcrumbs={breadcrumbs} linkComponent={ContextualLink} loading={!filters}>
        {({ loading }) => {
          if (loading) return;

          return (
            <div className="d-flex flex-column gap-m">
              <div className="d-flex flex-column gap-s gap-lg-m">
                <FilterManager
                  filtersConfig={filtersConfig}
                  onChange={handleFiltersChanged}
                />

                <hr className="u-margin-A-n" />
              </div>

              <CardLayout.Card className="d-flex flex-column flex-lg-row gap-s gap-lg-l">
                <div className={cx('d-flex flex-column flex-1 flex-lg-row gap-s', { 'align-items-lg-center': !!page?.rating })}>
                  <div>
                    <span
                      style={{
                        backgroundColor: colors.purpleLight,
                        borderRadius: 20,
                        color: colors.purple,
                        fontSize: 12,
                        padding: '2px 10px'
                      }}
                    >
                      <strong>BETA</strong>
                    </span>
                  </div>

                  <div className="small">
                    Analytics shows how you're performing on specific subjects and how you've improved over time.
                  </div>
                </div>

                {
                  page && !page.rating && (
                    <>
                      <div className="align-items-stretch d-flex" style={{ backgroundColor: colors.grayLighter, width: 1 }} />
                      <div className="flex-1">
                        <RatingForm
                          rating={{ rating: null, feature: 'analytics', text: null }}
                          rateableName="feature"
                        />
                      </div>
                    </>
                  )
                }
              </CardLayout.Card>

              {
                features.estimatedScore && !filters.focus && (
                  <div className="d-flex flex-column flex-lg-row gap-m">
                    <CardLayout.Card className="d-flex flex-1 flex-column gap-m">
                      <EstimatedScore filters={relevantFilters} />
                    </CardLayout.Card>
                  </div>
                )
              }

              <div className="d-flex flex-column gap-m">
                <div className="d-flex flex-column gap-m">
                  {
                    !filters.focus && (
                      <div className="d-flex flex-column gap-s gap-lg-m">
                        <div className="align-items-end d-flex">
                          <h3 className="flex-1 u-margin-A-n u-margin-T-s"><strong>Performance and Study Activity</strong></h3>
                        </div>

                        <hr className="u-margin-A-n" />
                      </div>
                    )
                  }

                  <div className="d-flex flex-column flex-lg-row gap-m">
                    <div className="d-flex flex-1 flex-column flex-lg-row gap-m">
                      <div className="d-flex flex-column gap-m">
                        <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                          <h3 className="u-margin-A-n">Questions</h3>

                          <QuestionsStat filters={relevantFilters} />
                        </CardLayout.Card>

                         <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                          <h3 className="u-margin-A-n">Pace</h3>

                          <PaceStat filters={relevantFilters} />
                        </CardLayout.Card>
                      </div>

                      <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                        <h3 className="u-margin-A-n">Percent Correct</h3>
                        <PercentCorrectChart filters={relevantFilters} />
                      </CardLayout.Card>

                      <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                        <h3 className="u-margin-A-n">Study Time</h3>
                        <StudyTimeChart filters={relevantFilters} />
                      </CardLayout.Card>

                      {
                        !filters.focus && (
                          <CardLayout.Card className={cx(style.card4Col, 'align-items-center d-flex flex-1 flex-column gap-m')}>
                            <h3 className="u-margin-A-n">Flashcards</h3>
                            <FlashcardsMasteredChart filters={relevantFilters} />
                          </CardLayout.Card>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column flex-lg-row gap-m">
                  <CardLayout.Card className={cx(style.card2Col, 'd-flex flex-1 flex-column gap-m')}>
                    <h3 className="u-margin-A-n">Performance by Question Difficulty</h3>

                    <PerformanceByDifficultyChart filters={relevantFilters} />
                  </CardLayout.Card>

                  <CardLayout.Card className={cx(style.card2Col, 'd-flex flex-1 flex-column gap-m')}>
                    <h3 className="u-margin-A-n u-margin">Performance by Question Type</h3>

                    <PerformanceByTypeChart filters={relevantFilters} sections={relevantFilters.sections} />
                  </CardLayout.Card>
                </div>
              </div>

              <div className="d-flex flex-column gap-m">
                <div className="d-flex flex-column gap-s gap-lg-m">
                  <div className="align-items-end d-flex">
                    <h3 className="flex-1 u-margin-A-n u-margin-T-s"><strong>Subjects</strong></h3>

                    <div className="align-items-center d-flex gap-xs hidden-xs">
                      <input
                        className={style.checkbox}
                        defaultChecked={hideSubjectsWithNoActivity}
                        id="hideSubjectsWithNoActivity"
                        onChange={(e) => setHideSubjectsWithNoActivity(e.target.checked)}
                        type="checkbox"
                      />
                      <label className="small u-margin-A-n" htmlFor="hideSubjectsWithNoActivity" style={{ fontWeight: 400 }}>
                        Hide subjects with no activity
                      </label>
                    </div>
                  </div>

                  <hr className="u-margin-A-n" />
                </div>

                <div className="d-flex flex-column gap-l" style={{ minHeight: 100, position: 'relative' }}>
                  {
                    page ? (
                      sectionsToDisplay().map((section) => (
                        <SectionSubjects
                          key={section.id}
                          filters={{ ...relevantFilters, sections: section }}
                          hideSubjectsWithNoActivity={hideSubjectsWithNoActivity}
                        />
                      ))
                    ) : (
                      <div className="d-flex justify-content-center">
                        <LoadingIndicator />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          );
        }}
      </CardLayout>
    </>
  );
};

export default Analytics;
