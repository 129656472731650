import React from 'react';
import { Checkbox } from 'react-bootstrap';
import { DataTableQueryParameters } from 'utilities/data_table_queries';

interface FilterToggleProps {
  queryParameters: DataTableQueryParameters;
  runQuery: (newQueryOptions) => any;
  filterName: string;
  filterLabel: string;
}

export const FilterToggle: React.FC<FilterToggleProps> = (props) => {

  const { queryParameters, runQuery, filterName, filterLabel } = props;

  return  <Checkbox
    checked={queryParameters.filters[props.filterName]}
    onChange={(e) => {
      const newFilters = { ...queryParameters.filters };

      if (e.target['checked']) {
        newFilters[props.filterName] = true;
      } else {
        delete newFilters[props.filterName];
      }

      runQuery({ filters: newFilters });
    }}
  >
    {filterLabel}
  </Checkbox>
}
