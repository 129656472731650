import React from 'react';
import { CheckpointCardProps, CheckpointCardWrapperProps } from './types';
import style from './style.module.scss';
import colors from '@magoosh/lib/styles/colors.module.scss';
import cx from 'classnames';
import BaseButton from '@magoosh/lib/button/base';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { Row, Col } from 'react-bootstrap';
import Icon from '../../assets/icons';
import PremiumBadge from '@magoosh/lib/info_badges';
import ConditionalWrapper from '@magoosh/gre/utils/conditional_wrapper';
import moment from 'moment';

export const CheckpointCardWrapper: React.FC<CheckpointCardWrapperProps> = (props) => {
  const { illustration, children } = props;

  return (
    <div>
      <Row>
        <Col lg={5} xsHidden smHidden mdHidden>
          <div className={style.illustration}>{illustration}</div>
        </Col>
        <Col lg={7}>
          <div className={style.children}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export const CheckpointCard: React.FC<CheckpointCardProps> = (props) => {
  const {
    iconType,
    illustration,
    headline,
    estimatedTime,
    running,
    locked,
    lockedBadge,
    scored,
    date,
    includesInfoIcon,
    percentageCompleted,
    primaryCTA,
    secondaryCTA,
    supplementaryLink,
    footer,
    isWrapped,
    children
  } = props;

  return (
    <div className={cx(style.checkpointCard, isWrapped && style.checkpointCardWrapper)}>
      <ConditionalWrapper
        condition={isWrapped}
        wrapper={(children) => (
          <CheckpointCardWrapper illustration={illustration}>{children}</CheckpointCardWrapper>
        )}>
        {iconType && <Icon iconType={iconType} fill={colors.brandPrimary} />}
        {locked && (
          <span>
            {lockedBadge || <PremiumBadge style={{ float: 'right' }} className={'u-margin-H-xs'} />}
          </span>
        )}
        {headline && <h3 className="u-margin-V-xs">{isWrapped ? <strong>{headline}</strong> : headline}</h3>}
        <div style={{ color: colors.gray }}>
          {estimatedTime && (
            <span className="u-margin-R-xs">
              <FontAwesomeIcon icon={'clock-o'} /> {estimatedTime} {scored && <span>{scored}</span>}
            </span>
          )}

          {running && (
            <span className="u-margin-R-xs">
              <FontAwesomeIcon icon={'spinner'} /> Incomplete
            </span>
          )}

          {date && (
            <span className="u-margin-R-xs">
              <FontAwesomeIcon icon={'calendar-check-o'} /> {moment(date).format('L')}{' '}
              {includesInfoIcon && <FontAwesomeIcon icon={'info-circle'} />}
            </span>
          )}

          {percentageCompleted && (
            <span className="u-margin-R-xs">
              <FontAwesomeIcon icon={'check'} /> percentageCompleted
            </span>
          )}
        </div>

        {children && <div className="u-margin-T-xs">{children}</div>}

        {(primaryCTA || secondaryCTA) && (
          <div className="u-margin-T-xs">
            {primaryCTA && primaryCTA.type === 'submit' ? (
                <BaseButton
                  className="u-margin-R-xs"
                  bsStyle={primaryCTA.variant}
                  disabled={primaryCTA.isDisabled}
                  {...primaryCTA}>
                  {primaryCTA.text}
                </BaseButton>
              ) : (
              <a
                className={cx(`btn btn-${primaryCTA.variant} u-margin-R-xs`, { disabled: primaryCTA.isDisabled })}
                {...primaryCTA}
              >
                {primaryCTA.text}
              </a>
            )}
            {secondaryCTA} {supplementaryLink}
          </div>
        )}
        
        {footer && (
          <p className={style.footer}>
            <small>{footer}</small>
          </p>
        )}
      </ConditionalWrapper>
    </div>
  );
};

export default CheckpointCard;
