import * as React from 'react';
import cx from 'classnames';

import { FadeIn } from './fade_in';

import style from './style.module.scss';

interface Props {
  children: any;
  step: number;
}

export default ({ children, step }: Props) => {
  // No animation on the first step
  const fadeInProps = step === 0 ? { duration: 0 } : {};

  return (
    <FadeIn {...fadeInProps}>
      <div className={cx(style.stepContent, 'd-flex flex-column')}>
        {children}
      </div>
    </FadeIn>
  );
};
