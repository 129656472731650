import React from 'react';

import FieldRadioGroup from '@magoosh/lib/formik_inputs/field_radio_group';

import { formatCustomDateRange } from './custom_date_range';
import { FilterConfig } from './types';

interface Props {
  filterConfig: FilterConfig;
  onChange?: (key: string, value: string) => void;
  value: string;
}

// For use within a Formik form
const RadioFilter: React.FC<Props> = (props) => {
  const formatOptionLabel = (option) => {
    if (props.filterConfig.key === 'period' && option.value === 'custom' && props.value.startsWith('custom')) {
      return formatCustomDateRange(props.value);
    } else {
      return option.name;
    }
  };

  return (
    <>
      <FieldRadioGroup
        name={props.filterConfig.key}
        onChangeCondition={(value: string) => props.onChange(props.filterConfig.key, value) }
        value={props.value}
        optionsArray={
          props.filterConfig.options.map((option) => (
            {
              label: formatOptionLabel(option),
              value: props.filterConfig.key === 'period' && option.value === 'custom' && props.value.startsWith('custom') ? props.value : option.value
            }
          ))
        }
      />
    </>
  );
};

export default RadioFilter;
