import { connect } from 'react-redux';
import { RootStore } from '@magoosh/store';
import { setItemCompleted } from '../actions';
import StudyItemList from './template';
import { StudyItemData } from '../../study_items/types';

interface OwnProps {
  studyItems: StudyItemData[];
  itemLimit?: number;
  incompleteItemsOnly?: boolean;
  examName: string;
  popoverPlacement?: string;
}

function mapStateToProps(state: RootStore, ownProps: OwnProps) {
  return { ...state.studySchedule.currentStudySchedule.currentDay.items, ...ownProps };
}

const mapDispatchToProps = {
  setItemCompleted
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyItemList);
