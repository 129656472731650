import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CreateAssignmentFormValues } from './types';
import { Switch, Route, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { NavBar, NavLink } from '../../organisms/nav_bar';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import FieldInput from '@magoosh/lib/formik_inputs/field_input';
import { FieldSelect } from '@magoosh/lib/formik_inputs/field_select/template';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { paths } from 'config/path_helpers';
import style from './style.module.scss';
import colors from '../../assets/style.module.scss';
import PageColumn from '../../lib/page_column';
import Icon from '../../assets/icons';
import PreviewListField from '../../lib/preview_list_field';
import SelectStudentsFormTab from '../../organisms/select_students_form_tab';
import fetch from 'utilities/fetch';
import { uniqWith, compact, uniq } from 'lodash';
import { ErrorMessages } from '@magoosh/lib/formik_inputs/errors_messages';
import { FieldDateTime } from '@magoosh/lib/formik_inputs/field_datetime';
import { FieldDropdownList } from '@magoosh/lib/formik_inputs/field_dropdown_list';
import Collapsible from '@magoosh/high_school/pages/assignments/assignment_dialog/collapsible';
import { pluralize } from 'utilities/pluralize';
import Card from '@magoosh/lib/card';
import { pick } from 'lodash';
import SelectClassesFormTab from '@magoosh/b2b/app/organisms/select_classes_form_tab';

const validationSchema = yup
  .object({
    id: yup.number().nullable(),
    title: yup.string().required('Title is required.'),
    contentBlockId: yup.number().typeError('Content is required.').required('Content is required.'),
    dueDate: yup.date().nullable(),
    classSections: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(true),
        displayName: yup.string().nullable(true)
      })
    ),
    students: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(true),
        email: yup.string().required(),
        firstName: yup.string(),
        lastName: yup.string()
      })
    )
  })
  .test('classes-or-students', 'Please add classes or students to the assignment.', (assignment) => {
    return assignment.id || assignment.classSections.length > 0 || assignment.students.length > 0;
  });

export const CreateEditAssignmentForm: React.FC = () => {
  const location = useLocation();
  const { assignmentId } = useParams();
  const [contentBlockId, setContentBlockId] = useState(null);
  const [contents, setContents] = useState([]);
  const [contentPreview, setContentPreview] = useState(null);
  const [existingClasses, setExistingClasses] = useState([]);
  const [existingStudents, setExistingStudents] = useState([]);
  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingAssignment, setLoadingAssignment] = useState(assignmentId != null);
  const [gradingCategories, setGradingCategories] = useState(null);
  const [maxPointsVisible, setMaxPointsVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: assignmentId,
    title: '',
    contentBlockId: null,
    dueDate: null,
    classSections: [],
    students: [],
    gradingCategoryId: null,
    maxPoints: null
  });
  const magooshGradingCategoryName = 'Magoosh assignment';
  const defaultMaxPoints = 100;

  const history = useHistory();
  const { url, path } = useRouteMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (assignmentId) {
      fetch(paths.api.partner.assignment(assignmentId)).then((response) => {
        setInitialValues({
          ...initialValues,
          ...response,
          dueDate: response.dueDate ? new Date(response.dueDate) : null
        });
        setContentBlockId(response.contentBlockId);
        setLoadingAssignment(false);
      });
    }
  }, [assignmentId]);

  useEffect(() => {
    if (contentBlockId) {
      fetch(paths.api.assignmentPreview(contentBlockId)).then((response) => {
        setContentPreview(response);
      });
    }
  }, [contentBlockId]);

  const prepareGradingCategories = (classSections, hasStudents = false) => {
    const allSectionsAreSchoology =
      classSections.length && classSections.every((section) => section.externalSource == 'schoology');
    if (!allSectionsAreSchoology) {
      setGradingCategories(null);
      return null;
    }

    let resultCategories, categoryId;
    if (hasStudents) {
      resultCategories = [];
      categoryId = 0;
    } else {
      // Find categories present for all class sections
      const allCategoriesGrouped = classSections.map((section) => JSON.parse(section.gradingCategories || '[]'));
      const uniqCategories = uniqWith(allCategoriesGrouped.flat(), equalId);
      resultCategories = uniqCategories.filter((category) =>
        allCategoriesGrouped.every((group) =>
          group.some((groupCategory) => groupCategory.id == category.id)
        )
      )
      // Remove magoosh category from the list
      const magooshCategoryIdx = resultCategories.findIndex((category) => category.title == magooshGradingCategoryName);
      if (magooshCategoryIdx != -1) resultCategories.splice(magooshCategoryIdx, 1);
      // Find same last used category id (or rollback to Ungraded if it is not the same for all sections)
      const uniqUsedCategoryIds = uniq(classSections.map((section) => section.lastUsedGradingCategoryId));
      categoryId = (uniqUsedCategoryIds.length == 1) ? uniqUsedCategoryIds[0] || 0 : 0;
    }

    resultCategories.unshift({ id: 0, title: '(Ungraded)' });

    setGradingCategories(resultCategories);
    return categoryId;
  }

  const prepareMaxPoints = (classSections) => {
    if (!maxPointsVisible) {
      const schoologySectionPresent =
        classSections.length && classSections.some((section) => section.externalSource == 'schoology');
      if (schoologySectionPresent) {
        setMaxPointsVisible(true);
      }
    }

    const allSectionsAreSchoology =
      classSections.length && classSections.every((section) => section.externalSource == 'schoology');
    if (!allSectionsAreSchoology) {
      return defaultMaxPoints;
    }

    // Find same last used value (or rollback to default if it is not the same for all sections)
    const uniqUsedMaxPoints = compact(uniq(classSections.map((section) => section.lastUsedMaxPoints)));
    return (uniqUsedMaxPoints.length == 1) ? uniqUsedMaxPoints[0] : defaultMaxPoints;
  }

  useEffect(() => {
    fetch(paths.api.partner.assignmentForm()).then((response) => {
      setContents(response.contents);
      let initialClassSection, urlClassSectionId;
      if (location?.search) {
        urlClassSectionId = new URLSearchParams(location.search).get('class-section-id');
        urlClassSectionId = parseInt(urlClassSectionId);
      }
      setExistingClasses(
        response.classSections.map((classSection) => {
          if (classSection.linkedSectionNames && classSection.linkedSectionNames.length) {
            const allSectionNames = [classSection.displayName].concat(classSection.linkedSectionNames);
            classSection.displayName = allSectionNames.join(', ');
          }
          // This might be passed in from assignments_data_table/index.tsx or from query param
          if (location?.state?.classSection?.id == classSection.id || urlClassSectionId == classSection.id) {
            initialClassSection = classSection;
          }
          return { ...classSection, isHidden: false, isSelected: false };
        })
      );
      if (initialClassSection) {
        setInitialValues({
          ...initialValues,
          classSections: [initialClassSection],
          gradingCategoryId: prepareGradingCategories([initialClassSection]),
          maxPoints: prepareMaxPoints([initialClassSection])
        });
        if (initialClassSection.externalSource == 'schoology') {
          setMaxPointsVisible(true);
        }
      }

      setExistingStudents(
        response.students.map((student) => {
          return { ...student, isHidden: false, isSelected: false };
        })
      );
      setLoadingForm(false);
    });
  }, []);

  const handleOnSubmit = (
    values: CreateAssignmentFormValues,
    { setSubmitting, setStatus }: FormikActions<CreateAssignmentFormValues>
  ): void => {
    setStatus(null);
    setSubmitting(true);

    const postValues = {
      ...pick(values, 'title', 'dueDate', 'contentBlockId'),
      classSectionIds: values.classSections.map((classSection) => classSection.id),
      userIds: values.students.map((student) => student.id)
    };
    if (values.gradingCategoryId != null) {
      postValues['gradingCategoryId'] = values.gradingCategoryId;
    }
    if (values.maxPoints != null && maxPointsVisible) {
      postValues['maxPoints'] = values.maxPoints;
    }

    const [url, method] = assignmentId
      ? [paths.api.partner.assignment(assignmentId), 'PUT']
      : [paths.api.partner.assignments(), 'POST'];

    fetch(url, {
      body: JSON.stringify(postValues),
      method: method
    }).then((response) => {
      if (response && response.schoologyForm) {
        const formContainer = document.createElement('div');
        formContainer.innerHTML = response.schoologyForm;
        document.querySelector('body').append(formContainer);
        document.forms['schoologyRedirectForm'].submit();
      } else {
        history.push(`/assignments${assignmentId ? `/${assignmentId}` : ''}`);
      }
    }).catch((response) => {
      setStatus(response.errors);
      setSubmitting(false);
    });;
  };

  const equalId = (a, b) => {
    return a.id == b.id;
  };

  const addSelectedClasses = (classes: ClassSection[], setFieldValue, values) => {
    const resultClasses = uniqWith([...values.classSections, ...classes], equalId);
    setFieldValue('classSections', resultClasses);
    setFieldValue('gradingCategoryId', prepareGradingCategories(resultClasses, values.students.length > 0));
    setFieldValue('maxPoints', prepareMaxPoints(resultClasses));
  };

  const addSelectedStudents = (students: Person[], setFieldValue, values) => {
    const resultStudents = uniqWith([...values.students, ...students], equalId);
    setFieldValue('students', resultStudents);
    setFieldValue('gradingCategoryId', prepareGradingCategories(values.classSections, resultStudents.length > 0));
  };

  const removeClasses = (deletedClass, setFieldValue, values) => {
    const resultClasses = values.classSections.filter((classSection) => deletedClass.id !== classSection.id);
    setFieldValue('classSections', resultClasses);
    setFieldValue('gradingCategoryId', prepareGradingCategories(resultClasses, values.students.length > 0));
    setFieldValue('maxPoints', prepareMaxPoints(resultClasses));
  };

  const removeStudents = (deletedStudent, setFieldValue, values) => {
    const resultStudents = values.students.filter((student) => deletedStudent.id !== student.id);
    setFieldValue('students', resultStudents);
    setFieldValue('gradingCategoryId', prepareGradingCategories(values.classSections, resultStudents.length > 0));
  };

  return (
    <>
      <PageColumn>
        <div className={style.createAssignmentForm}>
          <div className={style.formHeader}>
            <div className={style.headerText}>
              <h2 className="u-margin-T-n">{assignmentId ? 'Edit' : 'Create New'} Assignment</h2>
              {!assignmentId && (
                <h4>Add the following details about the new assignment you want to create.</h4>
              )}
            </div>
            <div className="u-margin-L-s">
              <Icon iconType="Assignment" fill={colors.supplementaryAzure} size="l" />
            </div>
          </div>

          {loadingForm || loadingAssignment ? (
            <div className="text-center u-margin-T-l">
              <FontAwesomeIcon icon="spinner" className="fa-pulse fa-5x" />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}>
              {({ isSubmitting, submitForm, setFieldValue, values, errors, status }) => {
                setContentBlockId(values.contentBlockId);
                return (
                  <Form>
                    <FieldInput
                      name="title"
                      type="input"
                      label="Assignment Title"
                      placeholder="Enter the assignment title."
                    />
                    <FieldDropdownList
                      name="contentBlockId"
                      data={contents}
                      valueField="id"
                      textField="name"
                      label="Content"
                      placeholder={'Content'}
                      filter="contains"
                      defaultValue={contents.find((content) => content.id == initialValues.contentBlockId)}
                      groupBy={(content) => `- ${content.examSectionDisplayName} -`}
                    />
                    {contentPreview && renderContentPreview(contentPreview)}
                    <FieldDateTime
                      label="Due Date"
                      name="dueDate"
                      optional={true}
                      placeholder="Select the due date for the assignment."
                      time={false}
                    />
                    {!assignmentId && (
                      <>
                        <NavBar>
                          <NavLink to={`${url}/select-classes`} text="Select Classes" />
                          <NavLink to={`${url}/select-students`} text="Select Students" />
                        </NavBar>
                        <Switch>
                          <Route exact path={`${path}/`}>
                            <Redirect to={`${url}/select-classes`} />
                          </Route>
                          <Route path={`${path}/select-classes`}>
                            <SelectClassesFormTab
                              classesList={existingClasses}
                              addSelectedClasses={(classes) =>
                                addSelectedClasses(classes, setFieldValue, values)
                              }
                            />
                          </Route>
                          <Route path={`${path}/select-students`}>
                            <SelectStudentsFormTab
                              studentsList={existingStudents}
                              addSelectedStudents={(students) =>
                                addSelectedStudents(students, setFieldValue, values)
                              }
                            />
                          </Route>
                        </Switch>
                        <hr />

                        <PreviewListField
                          label="Classes to Assign"
                          emptyMessage="Please add classes to the list."
                          name="classSections"
                          removeItem={(deletedClassSection) =>
                            removeClasses(deletedClassSection, setFieldValue, values)
                          }>
                          {(item: ClassSection) => (
                            <>
                              <strong>{item.displayName}</strong> {item.subject}{' '}
                            </>
                          )}
                        </PreviewListField>

                        <PreviewListField
                          label="Individual Students to Assign"
                          emptyMessage="Please add students to the list."
                          name="students"
                          removeItem={(deletedStudent) =>
                            removeStudents(deletedStudent, setFieldValue, values)
                          }>
                          {(item: Person) => (
                            <>
                              <strong>
                                {item.fullName || compact([item.lastName, item.firstName]).join(', ')}
                              </strong>{' '}
                              {item.email}{' '}
                            </>
                          )}
                        </PreviewListField>

                        {gradingCategories && (
                          <FieldSelect
                            className="form-control u-margin-B-l"
                            name="gradingCategoryId"
                            label="Grading Category"
                            objects={gradingCategories}
                            keyAccessor="title"
                            valueAccessor="id"
                            block={true}
                          />
                        )}

                        {maxPointsVisible && (
                          <FieldInput
                            className="form-control u-margin-B-l"
                            name="maxPoints"
                            type="number"
                            label="Max Points"
                          />
                        )}
                      </>
                    )}

                    <Row className="u-margin-T-s">
                      <Col xs={6}>
                        <SecondaryButton disabled={isSubmitting} onClick={submitForm} block>
                          {assignmentId ? 'Save' : 'Create New'} Assignment
                        </SecondaryButton>
                      </Col>

                      <Col xs={6}>
                        <DefaultButton onClick={() => history.goBack()} block>
                          Cancel
                        </DefaultButton>
                      </Col>
                    </Row>

                    <ErrorMessages errors={errors} />
                    <ErrorMessages errors={status || []} />

                    {!assignmentId && (
                      <>
                        <p className="u-margin-T-s">
                          <small>
                            <FontAwesomeIcon icon={'info-circle'} /> An assignment will be created for each
                            selected classes, also, an additional assignment will be created for the
                            individual students selected. Emails will be sent to the students with the
                            assignment details.
                          </small>
                        </p>
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </PageColumn>
    </>
  );
};

const renderContentPreview = (contentPreview) => {
  return (
    <>
      <br />
      <Card shadow>
        <h4>Estimated time: {contentPreview.estimatedTime} minutes</h4>
        <Collapsible header={pluralize(contentPreview.lessons.length, 'Lesson')} startOpen={true}>
          <ol>
            {contentPreview.lessons.map((lesson) => {
              return (
                <li key={lesson.id}>
                  <a className={style.visitable} href={paths.lesson(String(lesson.id))} target="_blank">
                    {lesson.name}
                  </a>
                </li>
              );
            })}
          </ol>
        </Collapsible>
        {renderQuestionList(contentPreview.quizQuestionIds, 'Quiz Question')}
        {renderQuestionList(contentPreview.practiceQuestionIds, 'Practice Question')}
      </Card>
    </>
  );
};

const renderQuestionList = (questions, label) => {
  return (
    <Collapsible header={pluralize(questions.length, label)}>
      <ul>
        {questions.map((questionId, idx) => {
          return (
            <li key={questionId}>
              <a className={style.visitable} href={paths.prompt(questionId)} target="_blank">
                Question {idx + 1}
              </a>
            </li>
          );
        })}
      </ul>
    </Collapsible>
  );
};

export default CreateEditAssignmentForm;
