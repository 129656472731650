import { useQuery } from 'react-query';

import * as api from './api';
import { useSchoolsAppContext } from './context';

// These queries and mutations use React hooks, which can only be called from within a functional
// component. Because of that we need to wrap them in functions like below. To access them in a
// functional component:
//   import { useQueries } from './queries';
//   const q = useQueries();
export const useQueries = () => {
  return {
    schoolProfileQuery,
    schoolProfileCategoryQuery,
    schoolProfileMetricQuery
  };
};

// There are no states on these pages so use a very long cache and stale time to minimize queries
const oneDayinMilliseconds = 24 * 60 * 60 * 1000;

export const schoolProfileQuery = (slug: string, categorySlug: string, preview: boolean = false, options = {}) => {
  const { apiHost } = useSchoolsAppContext();

  return useQuery(['profiles', categorySlug, slug], () => api.fetchSchoolProfile(apiHost, slug, categorySlug, preview), {
    retry: false,
    cacheTime: oneDayinMilliseconds,
    staleTime: oneDayinMilliseconds,
    ...options
  });
};

export const schoolProfileCategoryQuery = (slug: string, preview: boolean = false, options = {}) => {
  const { apiHost } = useSchoolsAppContext();

  return useQuery(['categories', slug], () => api.fetchSchoolProfileCategory(apiHost, slug, preview), {
    retry: false,
    cacheTime: oneDayinMilliseconds,
    staleTime: oneDayinMilliseconds,
    ...options
  });
};

export const schoolProfileMetricQuery = (slug: string, categorySlug: string, options = {}) => {
  const { apiHost } = useSchoolsAppContext();

  return useQuery(['metrics', categorySlug, slug], () => api.fetchSchoolProfileMetric(apiHost, slug, categorySlug), {
    retry: false,
    cacheTime: oneDayinMilliseconds,
    staleTime: oneDayinMilliseconds,
    ...options
  });
};
