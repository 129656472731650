import React, { useCallback, useMemo, useState } from 'react';
import throttle from 'lodash/debounce';
import { FormControl, FormGroup } from 'react-bootstrap';
import style from '@magoosh/lib/data_table/style.module.scss';
import { FontAwesomeIcon } from '@magoosh/lib/icons';

interface SearchProps {
  runQuery: (newQueryOptions) => any;
  placeholder?: string;
}

export const Search: React.FC<SearchProps> = ({ placeholder, runQuery }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const throttledRunQuery = useMemo(() => {
    return throttle(runQuery, 500, { trailing: true });
  }, []);

  const handleSearchChange = useCallback((e) => {
    e.persist();
    const newSearchTerm = e.target['value'];
    setSearchTerm(newSearchTerm);
    throttledRunQuery({ searchTerm: newSearchTerm });
  }, []);

  return (
    <FormGroup className={style.formGroup}>
      <FormControl
        type="text"
        placeholder={placeholder || 'Search'}
        onChange={handleSearchChange}
        value={searchTerm}
        bsSize={'small'}
      />
      <FormControl.Feedback>
        <FontAwesomeIcon icon="search" />
      </FormControl.Feedback>
    </FormGroup>
  );
};
