import React from 'react';

import style from './style.module.scss';

interface Props {
  faqs: string;
  related: string;
}

const FaqsContent: React.FC<Props> = (props) => {
  return (
    <div>
      <div dangerouslySetInnerHTML={{__html: props.faqs}} />

      {props.related && props.faqs && <hr />}

      {
        props.related && (
          <div>
            <div className={style.blockTitle}>Related Blog Posts</div>
            <div dangerouslySetInnerHTML={{__html: props.related}}></div>
          </div>
        )
      }
    </div>
  )
}

export default FaqsContent;
