import * as React from 'react';
import { Formik, Form } from 'formik';
import { PracticePreviewData } from '../types';
import fetch from 'utilities/fetch';
import { paths } from 'config/path_helpers';
import CustomModal from './custom_modal';
import { pluralize } from 'utilities/pluralize';
import { secondsFormattedAsMinutesString } from 'utilities/time';
import { toSentence } from 'utilities/formatting';
import style from './style.module.scss';
import cx from 'classnames';
import { PrimaryButton } from '@magoosh/lib/button';
import { FieldRadio } from '@magoosh/lib/formik_inputs/field_radio/template';
import { FieldCheckbox } from '@magoosh/lib/field_checkbox/template';
import PopupBanner from '@magoosh/lib/popup_banner';
import { InfoAlert } from '@magoosh/lib/alerts';

interface State {
  show: boolean;
  errorMessage?: string;
}

interface PracticeSessionPreviewModalProps extends PracticePreviewData {
  onHide: () => any;
}

export default class PracticeSessionPreviewModal extends React.Component<
  PracticeSessionPreviewModalProps,
  State
> {
  state = {
    show: true,
    errorMessage: null
  };

  handleHide = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <CustomModal backdrop={true} onHide={this.props.onHide} show={this.state.show} showCloseButton>
        {this.state.errorMessage && this.renderErrorMessage()}
        <Formik
          initialValues={{
            quizMode: this.props.quizMode,
            skipConfirmation: false,
            saveDefault: false
          }}
          onSubmit={this.onSubmit.bind(this)}>
          {(bag) => {
            return (
              <React.Fragment>
                <div className={style.largeForm}>{this.renderLarge(bag)}</div>
                <div className={style.smallForm}>{this.renderSmall(bag)}</div>
              </React.Fragment>
            );
          }}
        </Formik>
      </CustomModal>
    );
  }

  renderLarge(bag) {
    return (
      <Form>
        <div className={style.largeForm}>
          <div className={style.leftColumn}>
            <h4 className={'u-margin-T-xl'}>Session Settings:</h4>

            <FieldRadio
              name={'quizMode'}
              value={'false'}
              id={'quiz-mode-f'}
              checked={String(bag.values.quizMode) === 'false'}>
              Practice Mode
              <br />
              <small>Show explanations after each question</small>
            </FieldRadio>
            <FieldRadio
              name={'quizMode'}
              value={'true'}
              id={'quiz-mode-t'}
              checked={String(bag.values.quizMode) === 'true'}>
              Quiz Mode
              <br />
              <small>Hide explanations until the end</small>
            </FieldRadio>

            <FieldCheckbox
              name={'saveDefault'}
              id={'saveDefault'}
              checked={String(bag.values.saveDefault) === 'true'}>
              <label htmlFor={'saveDefault'}>
                <small>Save as my default</small>
              </label>
            </FieldCheckbox>

            <FieldCheckbox
              className={style.skipConfirmation}
              name={'skipConfirmation'}
              id={'skipConfirmation'}
              checked={String(bag.values.skipConfirmation) === 'true'}>
              <small>Don't show this confirmation again</small>
              <br />
              <small className={'text-gray-light'}>
                You can always bring it back later from your profile
              </small>
            </FieldCheckbox>
          </div>

          <div className={style.rightColumn}>
            {this.renderDescription()}
            <div className={'text-center u-margin-V-l'}>
              <PrimaryButton type={'submit'} submitting={bag.isSubmitting}>
                Start the Session
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  renderSmall(bag) {
    return (
      <div className={style.smallForm}>
        <Form>
          {this.renderDescription()}

          <div className={'u-border-T-s u-margin-V-m u-padding-V-m'}>
            <FieldRadio
              name={'quizMode'}
              value={'false'}
              id={'quiz-mode-sm-f'}
              checked={String(bag.values.quizMode) === 'false'}>
              Practice Mode
              <br />
              <small>Show explanations after each question</small>
            </FieldRadio>
            <FieldRadio
              name={'quizMode'}
              value={'true'}
              id={'quiz-mode-sm-t'}
              checked={String(bag.values.quizMode) === 'true'}>
              Quiz Mode
              <br />
              <small>Hide explanations until the end</small>
            </FieldRadio>
          </div>

          <div className={'text-center u-margin-V-l'}>
            <PrimaryButton block={true} type={'submit'} submitting={bag.isSubmitting}>
              Start the Session
            </PrimaryButton>
          </div>
        </Form>
      </div>
    );
  }

  renderDescription() {
    let timeSummary;
    if (this.props.timeLimitSeconds) {
      timeSummary = (
        <React.Fragment>
          {' '}
          to answer within <strong>{secondsFormattedAsMinutesString(this.props.timeLimitSeconds)}</strong>
        </React.Fragment>
      );
    } else if (this.props.estimatedTimeMinutes) {
      timeSummary = (
        <React.Fragment>
          {' '}
          and take around <strong>{pluralize(this.props.estimatedTimeMinutes, 'minute')}</strong>
        </React.Fragment>
      );
    }

    let conceptsDescription;
    if (!this.props.isTestLike) {
      conceptsDescription = (
        <p>
          These questions are basic and designed to build your understanding of core concepts.
          They are not representative of what you will see on the actual {this.props.exam}.
        </p>
      );
    } else if (this.props.subjectTags.length > 0) {
      conceptsDescription = <p>These questions will cover concepts from <span dangerouslySetInnerHTML={{ __html: toSentence(this.props.subjectTags, 'and', (item) => `<strong>${item}</strong>`) }} />.</p>;
    } else {
      conceptsDescription = (
        <p>
          These questions may cover some concepts that you have not seen yet. We realize this may be
          frustrating, but we believe that this type of mixed practice will accelerate your learning and help
          you prepare for test day.
        </p>
      );
    }

    return (
      <React.Fragment>
        {this.renderDescriptionHeader()}

        {this.props.includeOldPrompts && (
          <div className={cx('u-margin-V-m', style.noteBanner)}>{this.renderBanner()}</div>
        )}

        {(this.props.questionCount > 0) && (
          <p>
            Your practice session will include{' '}
            <strong>{pluralize(this.props.questionCount, this.props.questionType)}</strong>
            {timeSummary}.
          </p>
        )}

        {conceptsDescription}
      </React.Fragment>
    );
  }

  renderDescriptionHeader() {
    if (this.props.examSection) {
      return (
        <h3 className={'u-margin-B-l'}>
          {this.props.exam} Practice {`>`} <strong>{this.props.examSection}</strong>
        </h3>
      );
    } else {
      return <h3 className={'u-margin-B-l'}>{this.props.exam} Practice</h3>;
    }
  }

  renderBanner() {
    return (
      <InfoAlert>
        You don't have {pluralize(this.props.questionCount, this.props.questionType)} available, so this
        session will include some questions that you've seen before.
      </InfoAlert>
    );
  }

  onSubmit(values, actions) {
    fetch(paths.api.studySchedulePractice(this.props.blockId), {
      method: 'POST',
      body: JSON.stringify({
        ...values,
        startSession: true
      })
    })
      .then((response) => this.handleSubmitSuccess(response, actions))
      .catch((response) => this.handleSubmitError(response, actions));
  }

  handleSubmitSuccess(response, actions) {
    if (response.redirect) {
      window.location.assign(response.redirect);
    } else {
      actions.setSubmitting(false);
      this.setState({
        errorMessage: 'Something went wrong. Please reach out to Student Help for assistance.'
      });
    }
  }

  handleSubmitError(response, actions) {
    actions.setSubmitting(false);
    this.setState({ errorMessage: response.errors._error });
  }

  renderErrorMessage() {
    console.log(this.state.errorMessage);
    return (
      <PopupBanner bsStyle={'danger'} onSelfDestruct={() => this.setState({ errorMessage: null })}>
        {this.state.errorMessage}
      </PopupBanner>
    );
  }
}
