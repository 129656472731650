import * as React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import logoImage from '@magoosh/organisms/navbars/nav_bar/assets/Magoosh-logo-purple-400x90.png';
import style from './style.module.scss';
import cx from 'classnames';
import { AppContext } from '@magoosh/b2b/app/app_context';
import { useContext, useMemo } from 'react';

export const Header: React.FC = (props) => {
  const appContext = useContext(AppContext);
  const resourcesUrl = useMemo(() => {
    switch(window.location.hostname.split('.')[0].toLocaleLowerCase()) {
      case 'act':
      case 'sat':
        return 'https://schools.magoosh.com/resource_hub';
      default:
        return 'https://schools.magoosh.com/partners/magoosh-partner-resources';
    }
  }, [])

  return (
    <>
      <div className={style.header}>
        <div className={style.logo}>
          <a href="/">
            <img alt="Magoosh" className={style.logo} src={logoImage} />
          </a>
        </div>
        <div className={style.headerLink}>
          <Link to='/'><strong>Portal Home</strong></Link>
        </div>
        <div className={style.headerLink}>
          <a href={resourcesUrl} target='_blank' rel='noreferrer'>
            <strong>Resources</strong>
          </a>
        </div>
        <div className={cx(style.headerLink, style.studentExperienceLink)}>
          <a href='/dashboard'>Switch to Student Experience</a>
        </div>
        <div className={style.headerLink}>
          <OverlayTrigger
            overlay={<Popover id='profile-dropdown' className={style.accountDropdownLinks}>
              <div>
                <a href='/profile'>Profile</a>
              </div>
              {appContext.admin && <div>
                <a href='/admin'>Admin</a>
              </div>}
              <div>
                <a href='/logout'>Logout</a>
              </div>
            </Popover>}
            placement="bottom"
            rootClose
            trigger="click">
            <div className={style.accountDropdownLink}>
              Account&nbsp;
              <span className={cx(style.profileCaret, 'fa fa-caret-down')} />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
}
