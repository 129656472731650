import React from 'react';

import { ConstantsContextData, ConstantsContextInterface } from './types';

const ConstantsContext = React.createContext<ConstantsContextInterface | null>(null);

export const useConstantsContext = () => {
  const context = React.useContext(ConstantsContext) as ConstantsContextInterface;
  if (context === undefined) {
    throw new Error('You must wrap your page with <ConstantsProvider /> in order to useConstantsContext().');
  }
  return context;
};

interface Props {
  initialData?: ConstantsContextData;
}

export const ConstantsProvider: React.FC<Props> = ({ children, initialData }) => {
  const defaultData = {
    applicationCycles: [],
    colorPalettes: {},
    icons: []
  } as ConstantsContextData;

  const [data, setData] = React.useState(initialData || defaultData);

  const getColorPalette = (name: string) => data.colorPalettes[name] || data.colorPalettes.default;

  return <ConstantsContext.Provider value={{ ...data, getColorPalette }}>{children}</ConstantsContext.Provider>;
};
