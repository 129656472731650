import * as React from 'react';
import { Subscription } from './types';

import style from './style.module.scss';
import { Col, Row } from 'react-bootstrap';
import * as moment from 'moment';

export class StudentPracticeScores extends React.Component<Subscription> {
  render() {
    const subscription = this.props;
    const examSections = subscription.examSections;
    const practiceTests = subscription.practiceTests;
    let content;

    if (practiceTests == null) {
      content = (
        <Row>
          <Col md={2} className={style.noPracticeTests}>No practice tests</Col>
        </Row>
      );
    } else {
      let sections;

      if (subscription.displayName === 'SAT') {
        sections = ['Math', 'Verbal'];
      } else {
        sections = Object.keys(examSections);
      }

      content = [<Row className={style.headerRow} key='header'>
        <Col md={2}>&nbsp;</Col>
        <Col md={2}>Total</Col>
        {sections.map(section => {
          if (subscription.displayName !== 'SAT') {
            section = examSections[section]
          }

          return (<Col key={section} md={2}>{section}</Col>)
        })}
      </Row>];

      practiceTests.forEach(practiceTest => {
        const total = practiceTest.total ||
          (practiceTest.scores['Total'] && practiceTest.scores['Total'].score) ||
          (practiceTest.scores['total'] && practiceTest.scores['total'].score) ||
          'N/A';

        content.push(
          <Row key={practiceTest.id} className={style.practiceTestScores}>
            <Col md={2}>{moment(practiceTest.createdAt).format('l')}</Col>
            <Col md={2}>{total}</Col>
            {sections.map(section => {
              let examScore;
              let sectionName;

              if (subscription.displayName !== 'SAT') {
                sectionName = examSections[section].toLowerCase();
              } else {
                sectionName = section.toLowerCase();
              }

              if (sectionName != 'speaking' && sectionName != 'writing') {
                examScore = practiceTest.scores[section] || practiceTest.scores[`${section}`.toLowerCase()];
              }

              return (<Col key={section} md={2}>{examScore ? examScore.score : 'N/A'}</Col>)
            })}
          </Row>
        );
      })
    }

    return (
      <div>
        <Row>
          <Col md={12} className={style.sectionHeader}>
            Practice Test Scores
          </Col>
        </Row>
        {content}
      </div>
    )
  }
}
